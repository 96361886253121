// legacy code do not delete
!(function (t, e, i, s) {
  function n(e, i) {
    (this.settings = null),
      (this.options = t.extend({}, n.Defaults, i)),
      (this.$element = t(e)),
      (this._handlers = {}),
      (this._plugins = {}),
      (this._supress = {}),
      (this._current = null),
      (this._speed = null),
      (this._coordinates = []),
      (this._breakpoint = null),
      (this._width = null),
      (this._items = []),
      (this._clones = []),
      (this._mergers = []),
      (this._widths = []),
      (this._invalidated = {}),
      (this._pipe = []),
      (this._drag = {
        time: null,
        target: null,
        pointer: null,
        stage: { start: null, current: null },
        direction: null,
      }),
      (this._states = {
        current: {},
        tags: {
          initializing: ['busy'],
          animating: ['busy'],
          dragging: ['interacting'],
        },
      }),
      t.each(
        ['onResize', 'onThrottledResize'],
        t.proxy(function (e, i) {
          this._handlers[i] = t.proxy(this[i], this);
        }, this)
      ),
      t.each(
        n.Plugins,
        t.proxy(function (t, e) {
          this._plugins[t.charAt(0).toLowerCase() + t.slice(1)] = new e(this);
        }, this)
      ),
      t.each(
        n.Workers,
        t.proxy(function (e, i) {
          this._pipe.push({ filter: i.filter, run: t.proxy(i.run, this) });
        }, this)
      ),
      this.setup(),
      this.initialize();
  }
  (n.Defaults = {
    items: 3,
    loop: !1,
    center: !1,
    rewind: !1,
    checkVisibility: !0,
    mouseDrag: !0,
    touchDrag: !0,
    pullDrag: !0,
    freeDrag: !1,
    margin: 0,
    stagePadding: 0,
    merge: !1,
    mergeFit: !0,
    autoWidth: !1,
    startPosition: 0,
    rtl: !1,
    smartSpeed: 250,
    fluidSpeed: !1,
    dragEndSpeed: !1,
    responsive: {},
    responsiveRefreshRate: 200,
    responsiveBaseElement: e,
    fallbackEasing: 'swing',
    slideTransition: '',
    info: !1,
    nestedItemSelector: !1,
    itemElement: 'div',
    stageElement: 'div',
    refreshClass: 'owl-refresh',
    loadedClass: 'owl-loaded',
    loadingClass: 'owl-loading',
    rtlClass: 'owl-rtl',
    responsiveClass: 'owl-responsive',
    dragClass: 'owl-drag',
    itemClass: 'owl-item',
    stageClass: 'owl-stage',
    stageOuterClass: 'owl-stage-outer',
    grabClass: 'owl-grab',
  }),
    (n.Width = { Default: 'default', Inner: 'inner', Outer: 'outer' }),
    (n.Type = { Event: 'event', State: 'state' }),
    (n.Plugins = {}),
    (n.Workers = [
      {
        filter: ['width', 'settings'],
        run: function () {
          this._width = this.$element.width();
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function (t) {
          t.current = this._items && this._items[this.relative(this._current)];
        },
      },
      {
        filter: ['items', 'settings'],
        run: function () {
          this.$stage.children('.cloned').remove();
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function (t) {
          var e = this.settings.margin || '',
            i = !this.settings.autoWidth,
            s = this.settings.rtl,
            n = {
              width: 'auto',
              'margin-left': s ? e : '',
              'margin-right': s ? '' : e,
            };
          !i && this.$stage.children().css(n), (t.css = n);
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function (t) {
          var e =
              (this.width() / this.settings.items).toFixed(3) -
              this.settings.margin,
            i = null,
            s = this._items.length,
            n = !this.settings.autoWidth,
            o = [];
          for (t.items = { merge: !1, width: e }; s--; )
            (i = this._mergers[s]),
              (i =
                (this.settings.mergeFit && Math.min(i, this.settings.items)) ||
                i),
              (t.items.merge = i > 1 || t.items.merge),
              (o[s] = n ? e * i : this._items[s].width());
          this._widths = o;
        },
      },
      {
        filter: ['items', 'settings'],
        run: function () {
          var e = [],
            i = this._items,
            s = this.settings,
            n = Math.max(2 * s.items, 4),
            o = 2 * Math.ceil(i.length / 2),
            r = s.loop && i.length ? (s.rewind ? n : Math.max(n, o)) : 0,
            a = '',
            h = '';
          for (r /= 2; r > 0; )
            e.push(this.normalize(e.length / 2, !0)),
              (a += i[e[e.length - 1]][0].outerHTML),
              e.push(this.normalize(i.length - 1 - (e.length - 1) / 2, !0)),
              (h = i[e[e.length - 1]][0].outerHTML + h),
              (r -= 1);
          (this._clones = e),
            t(a).addClass('cloned').appendTo(this.$stage),
            t(h).addClass('cloned').prependTo(this.$stage);
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function () {
          for (
            var t = this.settings.rtl ? 1 : -1,
              e = this._clones.length + this._items.length,
              i = -1,
              s = 0,
              n = 0,
              o = [];
            ++i < e;

          )
            (s = o[i - 1] || 0),
              (n = this._widths[this.relative(i)] + this.settings.margin),
              o.push(s + n * t);
          this._coordinates = o;
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function () {
          var t = this.settings.stagePadding,
            e = this._coordinates,
            i = {
              width: Math.ceil(Math.abs(e[e.length - 1])) + 2 * t,
              'padding-left': t || '',
              'padding-right': t || '',
            };
          this.$stage.css(i);
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function (t) {
          var e = this._coordinates.length,
            i = !this.settings.autoWidth,
            s = this.$stage.children();
          if (i && t.items.merge)
            for (; e--; )
              (t.css.width = this._widths[this.relative(e)]),
                s.eq(e).css(t.css);
          else i && ((t.css.width = t.items.width), s.css(t.css));
        },
      },
      {
        filter: ['items'],
        run: function () {
          this._coordinates.length < 1 && this.$stage.removeAttr('style');
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function (t) {
          (t.current = t.current ? this.$stage.children().index(t.current) : 0),
            (t.current = Math.max(
              this.minimum(),
              Math.min(this.maximum(), t.current)
            )),
            this.reset(t.current);
        },
      },
      {
        filter: ['position'],
        run: function () {
          this.animate(this.coordinates(this._current));
        },
      },
      {
        filter: ['width', 'position', 'items', 'settings'],
        run: function () {
          var t,
            e,
            i,
            s,
            n = this.settings.rtl ? 1 : -1,
            o = 2 * this.settings.stagePadding,
            r = this.coordinates(this.current()) + o,
            a = r + this.width() * n,
            h = [];
          for (i = 0, s = this._coordinates.length; i < s; i++)
            (t = this._coordinates[i - 1] || 0),
              (e = Math.abs(this._coordinates[i]) + o * n),
              ((this.op(t, '<=', r) && this.op(t, '>', a)) ||
                (this.op(e, '<', r) && this.op(e, '>', a))) &&
                h.push(i);
          this.$stage.children('.active').removeClass('active'),
            this.$stage
              .children(':eq(' + h.join('), :eq(') + ')')
              .addClass('active'),
            this.$stage.children('.center').removeClass('center'),
            this.settings.center &&
              this.$stage.children().eq(this.current()).addClass('center');
        },
      },
    ]),
    (n.prototype.initializeStage = function () {
      (this.$stage = this.$element.find('.' + this.settings.stageClass)),
        this.$stage.length ||
          (this.$element.addClass(this.options.loadingClass),
          (this.$stage = t('<' + this.settings.stageElement + '>', {
            class: this.settings.stageClass,
          }).wrap(t('<div/>', { class: this.settings.stageOuterClass }))),
          this.$element.append(this.$stage.parent()));
    }),
    (n.prototype.initializeItems = function () {
      var e = this.$element.find('.owl-item');
      return e.length
        ? ((this._items = e.get().map(function (e) {
            return t(e);
          })),
          (this._mergers = this._items.map(function () {
            return 1;
          })),
          void this.refresh())
        : (this.replace(this.$element.children().not(this.$stage.parent())),
          this.isVisible() ? this.refresh() : this.invalidate('width'),
          void this.$element
            .removeClass(this.options.loadingClass)
            .addClass(this.options.loadedClass));
    }),
    (n.prototype.initialize = function () {
      if (
        (this.enter('initializing'),
        this.trigger('initialize'),
        this.$element.toggleClass(this.settings.rtlClass, this.settings.rtl),
        this.settings.autoWidth && !this.is('pre-loading'))
      ) {
        var t, e, i;
        (t = this.$element.find('img')),
          (e = this.settings.nestedItemSelector
            ? '.' + this.settings.nestedItemSelector
            : s),
          (i = this.$element.children(e).width()),
          t.length && i <= 0 && this.preloadAutoWidthImages(t);
      }
      this.initializeStage(),
        this.initializeItems(),
        this.registerEventHandlers(),
        this.leave('initializing'),
        this.trigger('initialized');
    }),
    (n.prototype.isVisible = function () {
      return !this.settings.checkVisibility || this.$element.is(':visible');
    }),
    (n.prototype.setup = function () {
      var e = this.viewport(),
        i = this.options.responsive,
        s = -1,
        n = null;
      i
        ? (t.each(i, function (t) {
            t <= e && t > s && (s = Number(t));
          }),
          (n = t.extend({}, this.options, i[s])),
          'function' == typeof n.stagePadding &&
            (n.stagePadding = n.stagePadding()),
          delete n.responsive,
          n.responsiveClass &&
            this.$element.attr(
              'class',
              this.$element
                .attr('class')
                .replace(
                  new RegExp(
                    '(' + this.options.responsiveClass + '-)\\S+\\s',
                    'g'
                  ),
                  '$1' + s
                )
            ))
        : (n = t.extend({}, this.options)),
        this.trigger('change', { property: { name: 'settings', value: n } }),
        (this._breakpoint = s),
        (this.settings = n),
        this.invalidate('settings'),
        this.trigger('changed', {
          property: { name: 'settings', value: this.settings },
        });
    }),
    (n.prototype.optionsLogic = function () {
      this.settings.autoWidth &&
        ((this.settings.stagePadding = !1), (this.settings.merge = !1));
    }),
    (n.prototype.prepare = function (e) {
      var i = this.trigger('prepare', { content: e });
      return (
        i.data ||
          (i.data = t('<' + this.settings.itemElement + '/>')
            .addClass(this.options.itemClass)
            .append(e)),
        this.trigger('prepared', { content: i.data }),
        i.data
      );
    }),
    (n.prototype.update = function () {
      for (
        var e = 0,
          i = this._pipe.length,
          s = t.proxy(function (t) {
            return this[t];
          }, this._invalidated),
          n = {};
        e < i;

      )
        (this._invalidated.all || t.grep(this._pipe[e].filter, s).length > 0) &&
          this._pipe[e].run(n),
          e++;
      (this._invalidated = {}), !this.is('valid') && this.enter('valid');
    }),
    (n.prototype.width = function (t) {
      switch ((t = t || n.Width.Default)) {
        case n.Width.Inner:
        case n.Width.Outer:
          return this._width;
        default:
          return (
            this._width - 2 * this.settings.stagePadding + this.settings.margin
          );
      }
    }),
    (n.prototype.refresh = function () {
      this.enter('refreshing'),
        this.trigger('refresh'),
        this.setup(),
        this.optionsLogic(),
        this.$element.addClass(this.options.refreshClass),
        this.update(),
        this.$element.removeClass(this.options.refreshClass),
        this.leave('refreshing'),
        this.trigger('refreshed');
    }),
    (n.prototype.onThrottledResize = function () {
      e.clearTimeout(this.resizeTimer),
        (this.resizeTimer = e.setTimeout(
          this._handlers.onResize,
          this.settings.responsiveRefreshRate
        ));
    }),
    (n.prototype.onResize = function () {
      return (
        !!this._items.length &&
        this._width !== this.$element.width() &&
        !!this.isVisible() &&
        (this.enter('resizing'),
        this.trigger('resize').isDefaultPrevented()
          ? (this.leave('resizing'), !1)
          : (this.invalidate('width'),
            this.refresh(),
            this.leave('resizing'),
            void this.trigger('resized')))
      );
    }),
    (n.prototype.registerEventHandlers = function () {
      t.support.transition &&
        this.$stage.on(
          t.support.transition.end + '.owl.core',
          t.proxy(this.onTransitionEnd, this)
        ),
        this.settings.responsive !== !1 &&
          this.on(e, 'resize', this._handlers.onThrottledResize),
        this.settings.mouseDrag &&
          (this.$element.addClass(this.options.dragClass),
          this.$stage.on('mousedown.owl.core', t.proxy(this.onDragStart, this)),
          this.$stage.on(
            'dragstart.owl.core selectstart.owl.core',
            function () {
              return !1;
            }
          )),
        this.settings.touchDrag &&
          (this.$stage.on(
            'touchstart.owl.core',
            t.proxy(this.onDragStart, this)
          ),
          this.$stage.on(
            'touchcancel.owl.core',
            t.proxy(this.onDragEnd, this)
          ));
    }),
    (n.prototype.onDragStart = function (e) {
      var s = null;
      3 !== e.which &&
        (t.support.transform
          ? ((s = this.$stage
              .css('transform')
              .replace(/.*\(|\)| /g, '')
              .split(',')),
            (s = {
              x: s[16 === s.length ? 12 : 4],
              y: s[16 === s.length ? 13 : 5],
            }))
          : ((s = this.$stage.position()),
            (s = {
              x: this.settings.rtl
                ? s.left +
                  this.$stage.width() -
                  this.width() +
                  this.settings.margin
                : s.left,
              y: s.top,
            })),
        this.is('animating') &&
          (t.support.transform ? this.animate(s.x) : this.$stage.stop(),
          this.invalidate('position')),
        this.$element.toggleClass(
          this.options.grabClass,
          'mousedown' === e.type
        ),
        this.speed(0),
        (this._drag.time = new Date().getTime()),
        (this._drag.target = t(e.target)),
        (this._drag.stage.start = s),
        (this._drag.stage.current = s),
        (this._drag.pointer = this.pointer(e)),
        t(i).on(
          'mouseup.owl.core touchend.owl.core',
          t.proxy(this.onDragEnd, this)
        ),
        t(i).one(
          'mousemove.owl.core touchmove.owl.core',
          t.proxy(function (e) {
            var s = this.difference(this._drag.pointer, this.pointer(e));
            t(i).on(
              'mousemove.owl.core touchmove.owl.core',
              t.proxy(this.onDragMove, this)
            ),
              (Math.abs(s.x) < Math.abs(s.y) && this.is('valid')) ||
                (e.preventDefault(),
                this.enter('dragging'),
                this.trigger('drag'));
          }, this)
        ));
    }),
    (n.prototype.onDragMove = function (t) {
      var e = null,
        i = null,
        s = null,
        n = this.difference(this._drag.pointer, this.pointer(t)),
        o = this.difference(this._drag.stage.start, n);
      this.is('dragging') &&
        (t.preventDefault(),
        this.settings.loop
          ? ((e = this.coordinates(this.minimum())),
            (i = this.coordinates(this.maximum() + 1) - e),
            (o.x = ((((o.x - e) % i) + i) % i) + e))
          : ((e = this.settings.rtl
              ? this.coordinates(this.maximum())
              : this.coordinates(this.minimum())),
            (i = this.settings.rtl
              ? this.coordinates(this.minimum())
              : this.coordinates(this.maximum())),
            (s = this.settings.pullDrag ? (-1 * n.x) / 5 : 0),
            (o.x = Math.max(Math.min(o.x, e + s), i + s))),
        (this._drag.stage.current = o),
        this.animate(o.x));
    }),
    (n.prototype.onDragEnd = function (e) {
      var s = this.difference(this._drag.pointer, this.pointer(e)),
        n = this._drag.stage.current,
        o = (s.x > 0) ^ this.settings.rtl ? 'left' : 'right';
      t(i).off('.owl.core'),
        this.$element.removeClass(this.options.grabClass),
        ((0 !== s.x && this.is('dragging')) || !this.is('valid')) &&
          (this.speed(this.settings.dragEndSpeed || this.settings.smartSpeed),
          this.current(this.closest(n.x, 0 !== s.x ? o : this._drag.direction)),
          this.invalidate('position'),
          this.update(),
          (this._drag.direction = o),
          (Math.abs(s.x) > 3 || new Date().getTime() - this._drag.time > 300) &&
            this._drag.target.one('click.owl.core', function () {
              return !1;
            })),
        this.is('dragging') &&
          (this.leave('dragging'), this.trigger('dragged'));
    }),
    (n.prototype.closest = function (e, i) {
      var n = -1,
        o = 30,
        r = this.width(),
        a = this.coordinates();
      return (
        this.settings.freeDrag ||
          t.each(
            a,
            t.proxy(function (t, h) {
              return (
                'left' === i && e > h - o && e < h + o
                  ? (n = t)
                  : 'right' === i && e > h - r - o && e < h - r + o
                  ? (n = t + 1)
                  : this.op(e, '<', h) &&
                    this.op(e, '>', a[t + 1] !== s ? a[t + 1] : h - r) &&
                    (n = 'left' === i ? t + 1 : t),
                n === -1
              );
            }, this)
          ),
        this.settings.loop ||
          (this.op(e, '>', a[this.minimum()])
            ? (n = e = this.minimum())
            : this.op(e, '<', a[this.maximum()]) && (n = e = this.maximum())),
        n
      );
    }),
    (n.prototype.animate = function (e) {
      var i = this.speed() > 0;
      this.is('animating') && this.onTransitionEnd(),
        i && (this.enter('animating'), this.trigger('translate')),
        t.support.transform3d && t.support.transition
          ? this.$stage.css({
              transform: 'translate3d(' + e + 'px,0px,0px)',
              transition:
                this.speed() / 1e3 +
                's' +
                (this.settings.slideTransition
                  ? ' ' + this.settings.slideTransition
                  : ''),
            })
          : i
          ? this.$stage.animate(
              { left: e + 'px' },
              this.speed(),
              this.settings.fallbackEasing,
              t.proxy(this.onTransitionEnd, this)
            )
          : this.$stage.css({ left: e + 'px' });
    }),
    (n.prototype.is = function (t) {
      return this._states.current[t] && this._states.current[t] > 0;
    }),
    (n.prototype.current = function (t) {
      if (t === s) return this._current;
      if (0 === this._items.length) return s;
      if (((t = this.normalize(t)), this._current !== t)) {
        var e = this.trigger('change', {
          property: { name: 'position', value: t },
        });
        e.data !== s && (t = this.normalize(e.data)),
          (this._current = t),
          this.invalidate('position'),
          this.trigger('changed', {
            property: { name: 'position', value: this._current },
          });
      }
      return this._current;
    }),
    (n.prototype.invalidate = function (e) {
      return (
        'string' === t.type(e) &&
          ((this._invalidated[e] = !0),
          this.is('valid') && this.leave('valid')),
        t.map(this._invalidated, function (t, e) {
          return e;
        })
      );
    }),
    (n.prototype.reset = function (t) {
      (t = this.normalize(t)),
        t !== s &&
          ((this._speed = 0),
          (this._current = t),
          this.suppress(['translate', 'translated']),
          this.animate(this.coordinates(t)),
          this.release(['translate', 'translated']));
    }),
    (n.prototype.normalize = function (t, e) {
      var i = this._items.length,
        n = e ? 0 : this._clones.length;
      return (
        !this.isNumeric(t) || i < 1
          ? (t = s)
          : (t < 0 || t >= i + n) &&
            (t = ((((t - n / 2) % i) + i) % i) + n / 2),
        t
      );
    }),
    (n.prototype.relative = function (t) {
      return (t -= this._clones.length / 2), this.normalize(t, !0);
    }),
    (n.prototype.maximum = function (t) {
      var e,
        i,
        s,
        n = this.settings,
        o = this._coordinates.length;
      if (n.loop) o = this._clones.length / 2 + this._items.length - 1;
      else if (n.autoWidth || n.merge) {
        if ((e = this._items.length))
          for (
            i = this._items[--e].width(), s = this.$element.width();
            e-- &&
            ((i += this._items[e].width() + this.settings.margin), !(i > s));

          );
        o = e + 1;
      } else
        o = n.center ? this._items.length - 1 : this._items.length - n.items;
      return t && (o -= this._clones.length / 2), Math.max(o, 0);
    }),
    (n.prototype.minimum = function (t) {
      return t ? 0 : this._clones.length / 2;
    }),
    (n.prototype.items = function (t) {
      return t === s
        ? this._items.slice()
        : ((t = this.normalize(t, !0)), this._items[t]);
    }),
    (n.prototype.mergers = function (t) {
      return t === s
        ? this._mergers.slice()
        : ((t = this.normalize(t, !0)), this._mergers[t]);
    }),
    (n.prototype.clones = function (e) {
      var i = this._clones.length / 2,
        n = i + this._items.length,
        o = function (t) {
          return t % 2 === 0 ? n + t / 2 : i - (t + 1) / 2;
        };
      return e === s
        ? t.map(this._clones, function (t, e) {
            return o(e);
          })
        : t.map(this._clones, function (t, i) {
            return t === e ? o(i) : null;
          });
    }),
    (n.prototype.speed = function (t) {
      return t !== s && (this._speed = t), this._speed;
    }),
    (n.prototype.coordinates = function (e) {
      var i,
        n = 1,
        o = e - 1;
      return e === s
        ? t.map(
            this._coordinates,
            t.proxy(function (t, e) {
              return this.coordinates(e);
            }, this)
          )
        : (this.settings.center
            ? (this.settings.rtl && ((n = -1), (o = e + 1)),
              (i = this._coordinates[e]),
              (i += ((this.width() - i + (this._coordinates[o] || 0)) / 2) * n))
            : (i = this._coordinates[o] || 0),
          (i = Math.ceil(i)));
    }),
    (n.prototype.duration = function (t, e, i) {
      return 0 === i
        ? 0
        : Math.min(Math.max(Math.abs(e - t), 1), 6) *
            Math.abs(i || this.settings.smartSpeed);
    }),
    (n.prototype.to = function (t, e) {
      var i = this.current(),
        s = null,
        n = t - this.relative(i),
        o = (n > 0) - (n < 0),
        r = this._items.length,
        a = this.minimum(),
        h = this.maximum();
      this.settings.loop
        ? (!this.settings.rewind && Math.abs(n) > r / 2 && (n += o * -1 * r),
          (t = i + n),
          (s = ((((t - a) % r) + r) % r) + a),
          s !== t &&
            s - n <= h &&
            s - n > 0 &&
            ((i = s - n), (t = s), this.reset(i)))
        : this.settings.rewind
        ? ((h += 1), (t = ((t % h) + h) % h))
        : (t = Math.max(a, Math.min(h, t))),
        this.speed(this.duration(i, t, e)),
        this.current(t),
        this.isVisible() && this.update();
    }),
    (n.prototype.next = function (t) {
      (t = t || !1), this.to(this.relative(this.current()) + 1, t);
    }),
    (n.prototype.prev = function (t) {
      (t = t || !1), this.to(this.relative(this.current()) - 1, t);
    }),
    (n.prototype.onTransitionEnd = function (t) {
      return (
        (t === s ||
          (t.stopPropagation(),
          (t.target || t.srcElement || t.originalTarget) ===
            this.$stage.get(0))) &&
        (this.leave('animating'), void this.trigger('translated'))
      );
    }),
    (n.prototype.viewport = function () {
      var s;
      return (
        this.options.responsiveBaseElement !== e
          ? (s = t(this.options.responsiveBaseElement).width())
          : e.innerWidth
          ? (s = e.innerWidth)
          : i.documentElement && i.documentElement.clientWidth
          ? (s = i.documentElement.clientWidth)
          : console.warn('Can not detect viewport width.'),
        s
      );
    }),
    (n.prototype.replace = function (e) {
      this.$stage.empty(),
        (this._items = []),
        e && (e = e instanceof jQuery ? e : t(e)),
        this.settings.nestedItemSelector &&
          (e = e.find('.' + this.settings.nestedItemSelector)),
        e
          .filter(function () {
            return 1 === this.nodeType;
          })
          .each(
            t.proxy(function (t, e) {
              (e = this.prepare(e)),
                this.$stage.append(e),
                this._items.push(e),
                this._mergers.push(
                  1 *
                    e
                      .find('[data-merge]')
                      .addBack('[data-merge]')
                      .attr('data-merge') || 1
                );
            }, this)
          ),
        this.reset(
          this.isNumeric(this.settings.startPosition)
            ? this.settings.startPosition
            : 0
        ),
        this.invalidate('items');
    }),
    (n.prototype.add = function (e, i) {
      var n = this.relative(this._current);
      (i = i === s ? this._items.length : this.normalize(i, !0)),
        (e = e instanceof jQuery ? e : t(e)),
        this.trigger('add', { content: e, position: i }),
        (e = this.prepare(e)),
        0 === this._items.length || i === this._items.length
          ? (0 === this._items.length && this.$stage.append(e),
            0 !== this._items.length && this._items[i - 1].after(e),
            this._items.push(e),
            this._mergers.push(
              1 *
                e
                  .find('[data-merge]')
                  .addBack('[data-merge]')
                  .attr('data-merge') || 1
            ))
          : (this._items[i].before(e),
            this._items.splice(i, 0, e),
            this._mergers.splice(
              i,
              0,
              1 *
                e
                  .find('[data-merge]')
                  .addBack('[data-merge]')
                  .attr('data-merge') || 1
            )),
        this._items[n] && this.reset(this._items[n].index()),
        this.invalidate('items'),
        this.trigger('added', { content: e, position: i });
    }),
    (n.prototype.remove = function (t) {
      (t = this.normalize(t, !0)),
        t !== s &&
          (this.trigger('remove', { content: this._items[t], position: t }),
          this._items[t].remove(),
          this._items.splice(t, 1),
          this._mergers.splice(t, 1),
          this.invalidate('items'),
          this.trigger('removed', { content: null, position: t }));
    }),
    (n.prototype.preloadAutoWidthImages = function (e) {
      e.each(
        t.proxy(function (e, i) {
          this.enter('pre-loading'),
            (i = t(i)),
            t(new Image())
              .one(
                'load',
                t.proxy(function (t) {
                  i.attr('src', t.target.src),
                    i.css('opacity', 1),
                    this.leave('pre-loading'),
                    !this.is('pre-loading') &&
                      !this.is('initializing') &&
                      this.refresh();
                }, this)
              )
              .attr(
                'src',
                i.attr('src') || i.attr('data-src') || i.attr('data-src-retina')
              );
        }, this)
      );
    }),
    (n.prototype.destroy = function () {
      this.$element.off('.owl.core'),
        this.$stage.off('.owl.core'),
        t(i).off('.owl.core'),
        this.settings.responsive !== !1 &&
          (e.clearTimeout(this.resizeTimer),
          this.off(e, 'resize', this._handlers.onThrottledResize));
      for (var s in this._plugins) this._plugins[s].destroy();
      this.$stage.children('.cloned').remove(),
        this.$stage.unwrap(),
        this.$stage.children().contents().unwrap(),
        this.$stage.children().unwrap(),
        this.$stage.remove(),
        this.$element
          .removeClass(this.options.refreshClass)
          .removeClass(this.options.loadingClass)
          .removeClass(this.options.loadedClass)
          .removeClass(this.options.rtlClass)
          .removeClass(this.options.dragClass)
          .removeClass(this.options.grabClass)
          .attr(
            'class',
            this.$element
              .attr('class')
              .replace(
                new RegExp(this.options.responsiveClass + '-\\S+\\s', 'g'),
                ''
              )
          )
          .removeData('owl.carousel');
    }),
    (n.prototype.op = function (t, e, i) {
      var s = this.settings.rtl;
      switch (e) {
        case '<':
          return s ? t > i : t < i;
        case '>':
          return s ? t < i : t > i;
        case '>=':
          return s ? t <= i : t >= i;
        case '<=':
          return s ? t >= i : t <= i;
      }
    }),
    (n.prototype.on = function (t, e, i, s) {
      t.addEventListener
        ? t.addEventListener(e, i, s)
        : t.attachEvent && t.attachEvent('on' + e, i);
    }),
    (n.prototype.off = function (t, e, i, s) {
      t.removeEventListener
        ? t.removeEventListener(e, i, s)
        : t.detachEvent && t.detachEvent('on' + e, i);
    }),
    (n.prototype.trigger = function (e, i, s, o, r) {
      var a = { item: { count: this._items.length, index: this.current() } },
        h = t.camelCase(
          t
            .grep(['on', e, s], function (t) {
              return t;
            })
            .join('-')
            .toLowerCase()
        ),
        l = t.Event(
          [e, 'owl', s || 'carousel'].join('.').toLowerCase(),
          t.extend({ relatedTarget: this }, a, i)
        );
      return (
        this._supress[e] ||
          (t.each(this._plugins, function (t, e) {
            e.onTrigger && e.onTrigger(l);
          }),
          this.register({ type: n.Type.Event, name: e }),
          this.$element.trigger(l),
          this.settings &&
            'function' == typeof this.settings[h] &&
            this.settings[h].call(this, l)),
        l
      );
    }),
    (n.prototype.enter = function (e) {
      t.each(
        [e].concat(this._states.tags[e] || []),
        t.proxy(function (t, e) {
          this._states.current[e] === s && (this._states.current[e] = 0),
            this._states.current[e]++;
        }, this)
      );
    }),
    (n.prototype.leave = function (e) {
      t.each(
        [e].concat(this._states.tags[e] || []),
        t.proxy(function (t, e) {
          this._states.current[e]--;
        }, this)
      );
    }),
    (n.prototype.register = function (e) {
      if (e.type === n.Type.Event) {
        if (
          (t.event.special[e.name] || (t.event.special[e.name] = {}),
          !t.event.special[e.name].owl)
        ) {
          var i = t.event.special[e.name]._default;
          (t.event.special[e.name]._default = function (t) {
            return !i ||
              !i.apply ||
              (t.namespace && t.namespace.indexOf('owl') !== -1)
              ? t.namespace && t.namespace.indexOf('owl') > -1
              : i.apply(this, arguments);
          }),
            (t.event.special[e.name].owl = !0);
        }
      } else
        e.type === n.Type.State &&
          (this._states.tags[e.name]
            ? (this._states.tags[e.name] = this._states.tags[e.name].concat(
                e.tags
              ))
            : (this._states.tags[e.name] = e.tags),
          (this._states.tags[e.name] = t.grep(
            this._states.tags[e.name],
            t.proxy(function (i, s) {
              return t.inArray(i, this._states.tags[e.name]) === s;
            }, this)
          )));
    }),
    (n.prototype.suppress = function (e) {
      t.each(
        e,
        t.proxy(function (t, e) {
          this._supress[e] = !0;
        }, this)
      );
    }),
    (n.prototype.release = function (e) {
      t.each(
        e,
        t.proxy(function (t, e) {
          delete this._supress[e];
        }, this)
      );
    }),
    (n.prototype.pointer = function (t) {
      var i = { x: null, y: null };
      return (
        (t = t.originalEvent || t || e.event),
        (t =
          t.touches && t.touches.length
            ? t.touches[0]
            : t.changedTouches && t.changedTouches.length
            ? t.changedTouches[0]
            : t),
        t.pageX
          ? ((i.x = t.pageX), (i.y = t.pageY))
          : ((i.x = t.clientX), (i.y = t.clientY)),
        i
      );
    }),
    (n.prototype.isNumeric = function (t) {
      return !isNaN(parseFloat(t));
    }),
    (n.prototype.difference = function (t, e) {
      return { x: t.x - e.x, y: t.y - e.y };
    }),
    (t.fn.owlCarousel = function (e) {
      var i = Array.prototype.slice.call(arguments, 1);
      return this.each(function () {
        var s = t(this),
          o = s.data('owl.carousel');
        o ||
          ((o = new n(this, 'object' == typeof e && e)),
          s.data('owl.carousel', o),
          t.each(
            [
              'next',
              'prev',
              'to',
              'destroy',
              'refresh',
              'replace',
              'add',
              'remove',
            ],
            function (e, i) {
              o.register({ type: n.Type.Event, name: i }),
                o.$element.on(
                  i + '.owl.carousel.core',
                  t.proxy(function (t) {
                    t.namespace &&
                      t.relatedTarget !== this &&
                      (this.suppress([i]),
                      o[i].apply(this, [].slice.call(arguments, 1)),
                      this.release([i]));
                  }, o)
                );
            }
          )),
          'string' == typeof e && '_' !== e.charAt(0) && o[e].apply(o, i);
      });
    }),
    (t.fn.owlCarousel.Constructor = n);
})(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (e) {
      (this._core = e),
        (this._interval = null),
        (this._visible = null),
        (this._handlers = {
          'initialized.owl.carousel': t.proxy(function (t) {
            t.namespace && this._core.settings.autoRefresh && this.watch();
          }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this._core.$element.on(this._handlers);
    };
    (n.Defaults = { autoRefresh: !0, autoRefreshInterval: 500 }),
      (n.prototype.watch = function () {
        this._interval ||
          ((this._visible = this._core.isVisible()),
          (this._interval = e.setInterval(
            t.proxy(this.refresh, this),
            this._core.settings.autoRefreshInterval
          )));
      }),
      (n.prototype.refresh = function () {
        this._core.isVisible() !== this._visible &&
          ((this._visible = !this._visible),
          this._core.$element.toggleClass('owl-hidden', !this._visible),
          this._visible &&
            this._core.invalidate('width') &&
            this._core.refresh());
      }),
      (n.prototype.destroy = function () {
        var t, i;
        e.clearInterval(this._interval);
        for (t in this._handlers) this._core.$element.off(t, this._handlers[t]);
        for (i in Object.getOwnPropertyNames(this))
          'function' != typeof this[i] && (this[i] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.AutoRefresh = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (e) {
      (this._core = e),
        (this._loaded = []),
        (this._handlers = {
          'initialized.owl.carousel change.owl.carousel resized.owl.carousel':
            t.proxy(function (e) {
              if (
                e.namespace &&
                this._core.settings &&
                this._core.settings.lazyLoad &&
                ((e.property && 'position' == e.property.name) ||
                  'initialized' == e.type)
              ) {
                var i = this._core.settings,
                  n = (i.center && Math.ceil(i.items / 2)) || i.items,
                  o = (i.center && n * -1) || 0,
                  r =
                    (e.property && e.property.value !== s
                      ? e.property.value
                      : this._core.current()) + o,
                  a = this._core.clones().length,
                  h = t.proxy(function (t, e) {
                    this.load(e);
                  }, this);
                for (
                  i.lazyLoadEager > 0 &&
                  ((n += i.lazyLoadEager),
                  i.loop && ((r -= i.lazyLoadEager), n++));
                  o++ < n;

                )
                  this.load(a / 2 + this._core.relative(r)),
                    a && t.each(this._core.clones(this._core.relative(r)), h),
                    r++;
              }
            }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this._core.$element.on(this._handlers);
    };
    (n.Defaults = { lazyLoad: !1, lazyLoadEager: 0 }),
      (n.prototype.load = function (i) {
        var s = this._core.$stage.children().eq(i),
          n = s && s.find('.owl-lazy');
        !n ||
          t.inArray(s.get(0), this._loaded) > -1 ||
          (n.each(
            t.proxy(function (i, s) {
              var n,
                o = t(s),
                r =
                  (e.devicePixelRatio > 1 && o.attr('data-src-retina')) ||
                  o.attr('data-src') ||
                  o.attr('data-srcset');
              this._core.trigger('load', { element: o, url: r }, 'lazy'),
                o.is('img')
                  ? o
                      .one(
                        'load.owl.lazy',
                        t.proxy(function () {
                          o.css('opacity', 1),
                            this._core.trigger(
                              'loaded',
                              { element: o, url: r },
                              'lazy'
                            );
                        }, this)
                      )
                      .attr('src', r)
                  : o.is('source')
                  ? o
                      .one(
                        'load.owl.lazy',
                        t.proxy(function () {
                          this._core.trigger(
                            'loaded',
                            { element: o, url: r },
                            'lazy'
                          );
                        }, this)
                      )
                      .attr('srcset', r)
                  : ((n = new Image()),
                    (n.onload = t.proxy(function () {
                      o.css({
                        'background-image': 'url("' + r + '")',
                        opacity: '1',
                      }),
                        this._core.trigger(
                          'loaded',
                          { element: o, url: r },
                          'lazy'
                        );
                    }, this)),
                    (n.src = r));
            }, this)
          ),
          this._loaded.push(s.get(0)));
      }),
      (n.prototype.destroy = function () {
        var t, e;
        for (t in this.handlers) this._core.$element.off(t, this.handlers[t]);
        for (e in Object.getOwnPropertyNames(this))
          'function' != typeof this[e] && (this[e] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.Lazy = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (i) {
      (this._core = i),
        (this._previousHeight = null),
        (this._handlers = {
          'initialized.owl.carousel refreshed.owl.carousel': t.proxy(function (
            t
          ) {
            t.namespace && this._core.settings.autoHeight && this.update();
          },
          this),
          'changed.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.settings.autoHeight &&
              'position' === t.property.name &&
              this.update();
          }, this),
          'loaded.owl.lazy': t.proxy(function (t) {
            t.namespace &&
              this._core.settings.autoHeight &&
              t.element.closest('.' + this._core.settings.itemClass).index() ===
                this._core.current() &&
              this.update();
          }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this._core.$element.on(this._handlers),
        (this._intervalId = null);
      var s = this;
      t(e).on('load', function () {
        s._core.settings.autoHeight && s.update();
      }),
        t(e).resize(function () {
          s._core.settings.autoHeight &&
            (null != s._intervalId && clearTimeout(s._intervalId),
            (s._intervalId = setTimeout(function () {
              s.update();
            }, 250)));
        });
    };
    (n.Defaults = { autoHeight: !1, autoHeightClass: 'owl-height' }),
      (n.prototype.update = function () {
        var e = this._core._current,
          i = e + this._core.settings.items,
          s = this._core.settings.lazyLoad,
          n = this._core.$stage.children().toArray().slice(e, i),
          o = [],
          r = 0;
        t.each(n, function (e, i) {
          o.push(t(i).height());
        }),
          (r = Math.max.apply(null, o)),
          r <= 1 && s && this._previousHeight && (r = this._previousHeight),
          (this._previousHeight = r),
          this._core.$stage
            .parent()
            .height(r)
            .addClass(this._core.settings.autoHeightClass);
      }),
      (n.prototype.destroy = function () {
        var t, e;
        for (t in this._handlers) this._core.$element.off(t, this._handlers[t]);
        for (e in Object.getOwnPropertyNames(this))
          'function' != typeof this[e] && (this[e] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.AutoHeight = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (e) {
      (this._core = e),
        (this._videos = {}),
        (this._playing = null),
        (this._handlers = {
          'initialized.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.register({
                type: 'state',
                name: 'playing',
                tags: ['interacting'],
              });
          }, this),
          'resize.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.settings.video &&
              this.isInFullScreen() &&
              t.preventDefault();
          }, this),
          'refreshed.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.is('resizing') &&
              this._core.$stage.find('.cloned .owl-video-frame').remove();
          }, this),
          'changed.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              'position' === t.property.name &&
              this._playing &&
              this.stop();
          }, this),
          'prepared.owl.carousel': t.proxy(function (e) {
            if (e.namespace) {
              var i = t(e.content).find('.owl-video');
              i.length &&
                (i.css('display', 'none'), this.fetch(i, t(e.content)));
            }
          }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this._core.$element.on(this._handlers),
        this._core.$element.on(
          'click.owl.video',
          '.owl-video-play-icon',
          t.proxy(function (t) {
            this.play(t);
          }, this)
        );
    };
    (n.Defaults = { video: !1, videoHeight: !1, videoWidth: !1 }),
      (n.prototype.fetch = function (t, e) {
        var i = (function () {
            return t.attr('data-vimeo-id')
              ? 'vimeo'
              : t.attr('data-vzaar-id')
              ? 'vzaar'
              : 'youtube';
          })(),
          s =
            t.attr('data-vimeo-id') ||
            t.attr('data-youtube-id') ||
            t.attr('data-vzaar-id'),
          n = t.attr('data-width') || this._core.settings.videoWidth,
          o = t.attr('data-height') || this._core.settings.videoHeight,
          r = t.attr('href');
        if (!r) throw new Error('Missing video URL.');
        if (
          ((s = r.match(
            /(http:|https:|)\/\/(player.|www.|app.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com|be\-nocookie\.com)|vzaar\.com)\/(video\/|videos\/|embed\/|channels\/.+\/|groups\/.+\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
          )),
          s[3].indexOf('youtu') > -1)
        )
          i = 'youtube';
        else if (s[3].indexOf('vimeo') > -1) i = 'vimeo';
        else {
          if (!(s[3].indexOf('vzaar') > -1))
            throw new Error('Video URL not supported.');
          i = 'vzaar';
        }
        (s = s[6]),
          (this._videos[r] = { type: i, id: s, width: n, height: o }),
          e.attr('data-video', r),
          this.thumbnail(t, this._videos[r]);
      }),
      (n.prototype.thumbnail = function (e, i) {
        var s,
          n,
          o,
          r =
            i.width && i.height
              ? 'width:' + i.width + 'px;height:' + i.height + 'px;'
              : '',
          a = e.find('img'),
          h = 'src',
          l = '',
          c = this._core.settings,
          p = function (i) {
            (n = '<div class="owl-video-play-icon"></div>'),
              (s = c.lazyLoad
                ? t('<div/>', { class: 'owl-video-tn ' + l, srcType: i })
                : t('<div/>', {
                    class: 'owl-video-tn',
                    style: 'opacity:1;background-image:url(' + i + ')',
                  })),
              e.after(s),
              e.after(n);
          };
        return (
          e.wrap(t('<div/>', { class: 'owl-video-wrapper', style: r })),
          this._core.settings.lazyLoad && ((h = 'data-src'), (l = 'owl-lazy')),
          a.length
            ? (p(a.attr(h)), a.remove(), !1)
            : void ('youtube' === i.type
                ? ((o = '//img.youtube.com/vi/' + i.id + '/hqdefault.jpg'),
                  p(o))
                : 'vimeo' === i.type
                ? t.ajax({
                    type: 'GET',
                    url: '//vimeo.com/api/v2/video/' + i.id + '.json',
                    jsonp: 'callback',
                    dataType: 'jsonp',
                    success: function (t) {
                      (o = t[0].thumbnail_large), p(o);
                    },
                  })
                : 'vzaar' === i.type &&
                  t.ajax({
                    type: 'GET',
                    url: '//vzaar.com/api/videos/' + i.id + '.json',
                    jsonp: 'callback',
                    dataType: 'jsonp',
                    success: function (t) {
                      (o = t.framegrab_url), p(o);
                    },
                  }))
        );
      }),
      (n.prototype.stop = function () {
        this._core.trigger('stop', null, 'video'),
          this._playing.find('.owl-video-frame').remove(),
          this._playing.removeClass('owl-video-playing'),
          (this._playing = null),
          this._core.leave('playing'),
          this._core.trigger('stopped', null, 'video');
      }),
      (n.prototype.play = function (e) {
        var i,
          s,
          n = t(e.target),
          o = n.closest('.' + this._core.settings.itemClass),
          r = this._videos[o.attr('data-video')],
          a = r.width || '100%',
          h = r.height || this._core.$stage.height();
        this._playing ||
          (this._core.enter('playing'),
          this._core.trigger('play', null, 'video'),
          (o = this._core.items(this._core.relative(o.index()))),
          this._core.reset(o.index()),
          (i = t(
            '<iframe frameborder="0" allowfullscreen mozallowfullscreen webkitAllowFullScreen ></iframe>'
          )),
          i.attr('height', h),
          i.attr('width', a),
          'youtube' === r.type
            ? i.attr(
                'src',
                '//www.youtube.com/embed/' +
                  r.id +
                  '?autoplay=1&rel=0&v=' +
                  r.id
              )
            : 'vimeo' === r.type
            ? i.attr('src', '//player.vimeo.com/video/' + r.id + '?autoplay=1')
            : 'vzaar' === r.type &&
              i.attr(
                'src',
                '//view.vzaar.com/' + r.id + '/player?autoplay=true'
              ),
          (s = t(i)
            .wrap('<div class="owl-video-frame" />')
            .insertAfter(o.find('.owl-video'))),
          (this._playing = o.addClass('owl-video-playing')));
      }),
      (n.prototype.isInFullScreen = function () {
        var e =
          i.fullscreenElement ||
          i.mozFullScreenElement ||
          i.webkitFullscreenElement;
        return e && t(e).parent().hasClass('owl-video-frame');
      }),
      (n.prototype.destroy = function () {
        var t, e;
        this._core.$element.off('click.owl.video');
        for (t in this._handlers) this._core.$element.off(t, this._handlers[t]);
        for (e in Object.getOwnPropertyNames(this))
          'function' != typeof this[e] && (this[e] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.Video = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (e) {
      (this.core = e),
        (this.core.options = t.extend({}, n.Defaults, this.core.options)),
        (this.swapping = !0),
        (this.previous = s),
        (this.next = s),
        (this.handlers = {
          'change.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              'position' == t.property.name &&
              ((this.previous = this.core.current()),
              (this.next = t.property.value));
          }, this),
          'drag.owl.carousel dragged.owl.carousel translated.owl.carousel':
            t.proxy(function (t) {
              t.namespace && (this.swapping = 'translated' == t.type);
            }, this),
          'translate.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this.swapping &&
              (this.core.options.animateOut || this.core.options.animateIn) &&
              this.swap();
          }, this),
        }),
        this.core.$element.on(this.handlers);
    };
    (n.Defaults = { animateOut: !1, animateIn: !1 }),
      (n.prototype.swap = function () {
        if (
          1 === this.core.settings.items &&
          t.support.animation &&
          t.support.transition
        ) {
          this.core.speed(0);
          var e,
            i = t.proxy(this.clear, this),
            s = this.core.$stage.children().eq(this.previous),
            n = this.core.$stage.children().eq(this.next),
            o = this.core.settings.animateIn,
            r = this.core.settings.animateOut;
          this.core.current() !== this.previous &&
            (r &&
              ((e =
                this.core.coordinates(this.previous) -
                this.core.coordinates(this.next)),
              s
                .one(t.support.animation.end, i)
                .css({ left: e + 'px' })
                .addClass('animated owl-animated-out')
                .addClass(r)),
            o &&
              n
                .one(t.support.animation.end, i)
                .addClass('animated owl-animated-in')
                .addClass(o));
        }
      }),
      (n.prototype.clear = function (e) {
        t(e.target)
          .css({ left: '' })
          .removeClass('animated owl-animated-out owl-animated-in')
          .removeClass(this.core.settings.animateIn)
          .removeClass(this.core.settings.animateOut),
          this.core.onTransitionEnd();
      }),
      (n.prototype.destroy = function () {
        var t, e;
        for (t in this.handlers) this.core.$element.off(t, this.handlers[t]);
        for (e in Object.getOwnPropertyNames(this))
          'function' != typeof this[e] && (this[e] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.Animate = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (e) {
      (this._core = e),
        (this._call = null),
        (this._time = 0),
        (this._timeout = 0),
        (this._paused = !0),
        (this._handlers = {
          'changed.owl.carousel': t.proxy(function (t) {
            t.namespace && 'settings' === t.property.name
              ? this._core.settings.autoplay
                ? this.play()
                : this.stop()
              : t.namespace &&
                'position' === t.property.name &&
                this._paused &&
                (this._time = 0);
          }, this),
          'initialized.owl.carousel': t.proxy(function (t) {
            t.namespace && this._core.settings.autoplay && this.play();
          }, this),
          'play.owl.autoplay': t.proxy(function (t, e, i) {
            t.namespace && this.play(e, i);
          }, this),
          'stop.owl.autoplay': t.proxy(function (t) {
            t.namespace && this.stop();
          }, this),
          'mouseover.owl.autoplay': t.proxy(function () {
            this._core.settings.autoplayHoverPause &&
              this._core.is('rotating') &&
              this.pause();
          }, this),
          'mouseleave.owl.autoplay': t.proxy(function () {
            this._core.settings.autoplayHoverPause &&
              this._core.is('rotating') &&
              this.play();
          }, this),
          'touchstart.owl.core': t.proxy(function () {
            this._core.settings.autoplayHoverPause &&
              this._core.is('rotating') &&
              this.pause();
          }, this),
          'touchend.owl.core': t.proxy(function () {
            this._core.settings.autoplayHoverPause && this.play();
          }, this),
        }),
        this._core.$element.on(this._handlers),
        (this._core.options = t.extend({}, n.Defaults, this._core.options));
    };
    (n.Defaults = {
      autoplay: !1,
      autoplayTimeout: 5e3,
      autoplayHoverPause: !1,
      autoplaySpeed: !1,
    }),
      (n.prototype._next = function (s) {
        (this._call = e.setTimeout(
          t.proxy(this._next, this, s),
          this._timeout * (Math.round(this.read() / this._timeout) + 1) -
            this.read()
        )),
          this._core.is('interacting') ||
            i.hidden ||
            this._core.next(s || this._core.settings.autoplaySpeed);
      }),
      (n.prototype.read = function () {
        return new Date().getTime() - this._time;
      }),
      (n.prototype.play = function (i, s) {
        var n;
        this._core.is('rotating') || this._core.enter('rotating'),
          (i = i || this._core.settings.autoplayTimeout),
          (n = Math.min(this._time % (this._timeout || i), i)),
          this._paused
            ? ((this._time = this.read()), (this._paused = !1))
            : e.clearTimeout(this._call),
          (this._time += (this.read() % i) - n),
          (this._timeout = i),
          (this._call = e.setTimeout(t.proxy(this._next, this, s), i - n));
      }),
      (n.prototype.stop = function () {
        this._core.is('rotating') &&
          ((this._time = 0),
          (this._paused = !0),
          e.clearTimeout(this._call),
          this._core.leave('rotating'));
      }),
      (n.prototype.pause = function () {
        this._core.is('rotating') &&
          !this._paused &&
          ((this._time = this.read()),
          (this._paused = !0),
          e.clearTimeout(this._call));
      }),
      (n.prototype.destroy = function () {
        var t, e;
        this.stop();
        for (t in this._handlers) this._core.$element.off(t, this._handlers[t]);
        for (e in Object.getOwnPropertyNames(this))
          'function' != typeof this[e] && (this[e] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.autoplay = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    'use strict';
    var n = function (e) {
      (this._core = e),
        (this._initialized = !1),
        (this._pages = []),
        (this._controls = {}),
        (this._templates = []),
        (this.$element = this._core.$element),
        (this._overrides = {
          next: this._core.next,
          prev: this._core.prev,
          to: this._core.to,
        }),
        (this._handlers = {
          'prepared.owl.carousel': t.proxy(function (e) {
            e.namespace &&
              this._core.settings.dotsData &&
              this._templates.push(
                '<div class="' +
                  this._core.settings.dotClass +
                  '">' +
                  t(e.content)
                    .find('[data-dot]')
                    .addBack('[data-dot]')
                    .attr('data-dot') +
                  '</div>'
              );
          }, this),
          'added.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.settings.dotsData &&
              this._templates.splice(t.position, 0, this._templates.pop());
          }, this),
          'remove.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.settings.dotsData &&
              this._templates.splice(t.position, 1);
          }, this),
          'changed.owl.carousel': t.proxy(function (t) {
            t.namespace && 'position' == t.property.name && this.draw();
          }, this),
          'initialized.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              !this._initialized &&
              (this._core.trigger('initialize', null, 'navigation'),
              this.initialize(),
              this.update(),
              this.draw(),
              (this._initialized = !0),
              this._core.trigger('initialized', null, 'navigation'));
          }, this),
          'refreshed.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._initialized &&
              (this._core.trigger('refresh', null, 'navigation'),
              this.update(),
              this.draw(),
              this._core.trigger('refreshed', null, 'navigation'));
          }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this.$element.on(this._handlers);
    };
    (n.Defaults = {
      nav: !1,
      navText: [
        '<span aria-label="Previous">&#x2039;</span>',
        '<span aria-label="Next">&#x203a;</span>',
      ],
      navSpeed: !1,
      navElement: 'button type="button" role="presentation"',
      navContainer: !1,
      navContainerClass: 'owl-nav',
      navClass: ['owl-prev', 'owl-next'],
      slideBy: 1,
      dotClass: 'owl-dot',
      dotsClass: 'owl-dots',
      dots: !0,
      dotsEach: !1,
      dotsData: !1,
      dotsSpeed: !1,
      dotsContainer: !1,
    }),
      (n.prototype.initialize = function () {
        var e,
          i = this._core.settings;
        (this._controls.$relative = (
          i.navContainer
            ? t(i.navContainer)
            : t('<div>').addClass(i.navContainerClass).appendTo(this.$element)
        ).addClass('disabled')),
          (this._controls.$previous = t('<' + i.navElement + '>')
            .addClass(i.navClass[0])
            .html(i.navText[0])
            .prependTo(this._controls.$relative)
            .on(
              'click',
              t.proxy(function (t) {
                this.prev(i.navSpeed);
              }, this)
            )),
          (this._controls.$next = t('<' + i.navElement + '>')
            .addClass(i.navClass[1])
            .html(i.navText[1])
            .appendTo(this._controls.$relative)
            .on(
              'click',
              t.proxy(function (t) {
                this.next(i.navSpeed);
              }, this)
            )),
          i.dotsData ||
            (this._templates = [
              t('<button role="button">')
                .addClass(i.dotClass)
                .append(t('<span>'))
                .prop('outerHTML'),
            ]),
          (this._controls.$absolute = (
            i.dotsContainer
              ? t(i.dotsContainer)
              : t('<div>').addClass(i.dotsClass).appendTo(this.$element)
          ).addClass('disabled')),
          this._controls.$absolute.on(
            'click',
            'button',
            t.proxy(function (e) {
              var s = t(e.target).parent().is(this._controls.$absolute)
                ? t(e.target).index()
                : t(e.target).parent().index();
              e.preventDefault(), this.to(s, i.dotsSpeed);
            }, this)
          );
        for (e in this._overrides) this._core[e] = t.proxy(this[e], this);
      }),
      (n.prototype.destroy = function () {
        var t, e, i, s, n;
        n = this._core.settings;
        for (t in this._handlers) this.$element.off(t, this._handlers[t]);
        for (e in this._controls)
          '$relative' === e && n.navContainer
            ? this._controls[e].html('')
            : this._controls[e].remove();
        for (s in this.overides) this._core[s] = this._overrides[s];
        for (i in Object.getOwnPropertyNames(this))
          'function' != typeof this[i] && (this[i] = null);
      }),
      (n.prototype.update = function () {
        var t,
          e,
          i,
          s = this._core.clones().length / 2,
          n = s + this._core.items().length,
          o = this._core.maximum(!0),
          r = this._core.settings,
          a = r.center || r.autoWidth || r.dotsData ? 1 : r.dotsEach || r.items;
        if (
          ('page' !== r.slideBy && (r.slideBy = Math.min(r.slideBy, r.items)),
          r.dots || 'page' == r.slideBy)
        )
          for (this._pages = [], t = s, e = 0, i = 0; t < n; t++) {
            if (e >= a || 0 === e) {
              if (
                (this._pages.push({
                  start: Math.min(o, t - s),
                  end: t - s + a - 1,
                }),
                Math.min(o, t - s) === o)
              )
                break;
              (e = 0), ++i;
            }
            e += this._core.mergers(this._core.relative(t));
          }
      }),
      (n.prototype.draw = function () {
        var e,
          i = this._core.settings,
          s = this._core.items().length <= i.items,
          n = this._core.relative(this._core.current()),
          o = i.loop || i.rewind;
        this._controls.$relative.toggleClass('disabled', !i.nav || s),
          i.nav &&
            (this._controls.$previous.toggleClass(
              'disabled',
              !o && n <= this._core.minimum(!0)
            ),
            this._controls.$next.toggleClass(
              'disabled',
              !o && n >= this._core.maximum(!0)
            )),
          this._controls.$absolute.toggleClass('disabled', !i.dots || s),
          i.dots &&
            ((e =
              this._pages.length - this._controls.$absolute.children().length),
            i.dotsData && 0 !== e
              ? this._controls.$absolute.html(this._templates.join(''))
              : e > 0
              ? this._controls.$absolute.append(
                  new Array(e + 1).join(this._templates[0])
                )
              : e < 0 && this._controls.$absolute.children().slice(e).remove(),
            this._controls.$absolute.find('.active').removeClass('active'),
            this._controls.$absolute
              .children()
              .eq(t.inArray(this.current(), this._pages))
              .addClass('active'));
      }),
      (n.prototype.onTrigger = function (e) {
        var i = this._core.settings;
        e.page = {
          index: t.inArray(this.current(), this._pages),
          count: this._pages.length,
          size:
            i &&
            (i.center || i.autoWidth || i.dotsData ? 1 : i.dotsEach || i.items),
        };
      }),
      (n.prototype.current = function () {
        var e = this._core.relative(this._core.current());
        return t
          .grep(
            this._pages,
            t.proxy(function (t, i) {
              return t.start <= e && t.end >= e;
            }, this)
          )
          .pop();
      }),
      (n.prototype.getPosition = function (e) {
        var i,
          s,
          n = this._core.settings;
        return (
          'page' == n.slideBy
            ? ((i = t.inArray(this.current(), this._pages)),
              (s = this._pages.length),
              e ? ++i : --i,
              (i = this._pages[((i % s) + s) % s].start))
            : ((i = this._core.relative(this._core.current())),
              (s = this._core.items().length),
              e ? (i += n.slideBy) : (i -= n.slideBy)),
          i
        );
      }),
      (n.prototype.next = function (e) {
        t.proxy(this._overrides.to, this._core)(this.getPosition(!0), e);
      }),
      (n.prototype.prev = function (e) {
        t.proxy(this._overrides.to, this._core)(this.getPosition(!1), e);
      }),
      (n.prototype.to = function (e, i, s) {
        var n;
        !s && this._pages.length
          ? ((n = this._pages.length),
            t.proxy(this._overrides.to, this._core)(
              this._pages[((e % n) + n) % n].start,
              i
            ))
          : t.proxy(this._overrides.to, this._core)(e, i);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.Navigation = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    'use strict';
    var n = function (i) {
      (this._core = i),
        (this._hashes = {}),
        (this.$element = this._core.$element),
        (this._handlers = {
          'initialized.owl.carousel': t.proxy(function (i) {
            i.namespace &&
              'URLHash' === this._core.settings.startPosition &&
              t(e).trigger('hashchange.owl.navigation');
          }, this),
          'prepared.owl.carousel': t.proxy(function (e) {
            if (e.namespace) {
              var i = t(e.content)
                .find('[data-hash]')
                .addBack('[data-hash]')
                .attr('data-hash');
              if (!i) return;
              this._hashes[i] = e.content;
            }
          }, this),
          'changed.owl.carousel': t.proxy(function (i) {
            if (i.namespace && 'position' === i.property.name) {
              var s = this._core.items(
                  this._core.relative(this._core.current())
                ),
                n = t
                  .map(this._hashes, function (t, e) {
                    return t === s ? e : null;
                  })
                  .join();
              if (!n || e.location.hash.slice(1) === n) return;
              e.location.hash = n;
            }
          }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this.$element.on(this._handlers),
        t(e).on(
          'hashchange.owl.navigation',
          t.proxy(function (t) {
            var i = e.location.hash.substring(1),
              n = this._core.$stage.children(),
              o = this._hashes[i] && n.index(this._hashes[i]);
            o !== s &&
              o !== this._core.current() &&
              this._core.to(this._core.relative(o), !1, !0);
          }, this)
        );
    };
    (n.Defaults = { URLhashListener: !1 }),
      (n.prototype.destroy = function () {
        var i, s;
        t(e).off('hashchange.owl.navigation');
        for (i in this._handlers) this._core.$element.off(i, this._handlers[i]);
        for (s in Object.getOwnPropertyNames(this))
          'function' != typeof this[s] && (this[s] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.Hash = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    function n(e, i) {
      var n = !1,
        o = e.charAt(0).toUpperCase() + e.slice(1);
      return (
        t.each((e + ' ' + a.join(o + ' ') + o).split(' '), function (t, e) {
          if (r[e] !== s) return (n = !i || e), !1;
        }),
        n
      );
    }
    function o(t) {
      return n(t, !0);
    }
    var r = t('<support>').get(0).style,
      a = 'Webkit Moz O ms'.split(' '),
      h = {
        transition: {
          end: {
            WebkitTransition: 'webkitTransitionEnd',
            MozTransition: 'transitionend',
            OTransition: 'oTransitionEnd',
            transition: 'transitionend',
          },
        },
        animation: {
          end: {
            WebkitAnimation: 'webkitAnimationEnd',
            MozAnimation: 'animationend',
            OAnimation: 'oAnimationEnd',
            animation: 'animationend',
          },
        },
      },
      l = {
        csstransforms: function () {
          return !!n('transform');
        },
        csstransforms3d: function () {
          return !!n('perspective');
        },
        csstransitions: function () {
          return !!n('transition');
        },
        cssanimations: function () {
          return !!n('animation');
        },
      };
    l.csstransitions() &&
      ((t.support.transition = new String(o('transition'))),
      (t.support.transition.end = h.transition.end[t.support.transition])),
      l.cssanimations() &&
        ((t.support.animation = new String(o('animation'))),
        (t.support.animation.end = h.animation.end[t.support.animation])),
      l.csstransforms() &&
        ((t.support.transform = new String(o('transform'))),
        (t.support.transform3d = l.csstransforms3d()));
  })(window.Zepto || window.jQuery, window, document);
!(function t(e, n, r) {
  function o(i, s) {
    if (!n[i]) {
      if (!e[i]) {
        var a = 'function' == typeof require && require;
        if (!s && a) return a(i, !0);
        if (l) return l(i, !0);
        var c = new Error("Cannot find module '" + i + "'");
        throw ((c.code = 'MODULE_NOT_FOUND'), c);
      }
      var u = (n[i] = { exports: {} });
      e[i][0].call(
        u.exports,
        function (t) {
          var n = e[i][1][t];
          return o(n ? n : t);
        },
        u,
        u.exports,
        t,
        e,
        n,
        r
      );
    }
    return n[i].exports;
  }
  for (
    var l = 'function' == typeof require && require, i = 0;
    i < r.length;
    i++
  )
    o(r[i]);
  return o;
})(
  {
    1: [
      function (t, e, n) {
        'use strict';
        function r(t) {
          t.fn.perfectScrollbar = function (t) {
            return this.each(function () {
              if ('object' == typeof t || 'undefined' == typeof t) {
                var e = t;
                l.get(this) || o.initialize(this, e);
              } else {
                var n = t;
                'update' === n
                  ? o.update(this)
                  : 'destroy' === n && o.destroy(this);
              }
            });
          };
        }
        var o = t('../main'),
          l = t('../plugin/instances');
        if ('function' == typeof define && define.amd) define(['jquery'], r);
        else {
          var i = window.jQuery ? window.jQuery : window.$;
          'undefined' != typeof i && r(i);
        }
        e.exports = r;
      },
      { '../main': 6, '../plugin/instances': 17 },
    ],
    2: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          return window.getComputedStyle(t)[e];
        }
        function o(t, e, n) {
          return (
            'number' == typeof n && (n = n.toString() + 'px'),
            (t.style[e] = n),
            t
          );
        }
        function l(t, e) {
          for (var n in e) {
            var r = e[n];
            'number' == typeof r && (r = r.toString() + 'px'), (t.style[n] = r);
          }
          return t;
        }
        var i = {};
        (i.create = function (t, e) {
          var n = document.createElement(t);
          return (n.className = e), n;
        }),
          (i.appendTo = function (t, e) {
            return e.appendChild(t), t;
          }),
          (i.css = function (t, e, n) {
            return 'object' == typeof e
              ? l(t, e)
              : 'undefined' == typeof n
              ? r(t, e)
              : o(t, e, n);
          }),
          (i.matches = function (t, e) {
            return 'undefined' != typeof t.matches
              ? t.matches(e)
              : t.msMatchesSelector(e);
          }),
          (i.remove = function (t) {
            'undefined' != typeof t.remove
              ? t.remove()
              : t.parentNode && t.parentNode.removeChild(t);
          }),
          (i.queryChildren = function (t, e) {
            return Array.prototype.filter.call(t.childNodes, function (t) {
              return i.matches(t, e);
            });
          }),
          (e.exports = i);
      },
      {},
    ],
    3: [
      function (t, e, n) {
        'use strict';
        var r = function (t) {
          (this.element = t), (this.events = {});
        };
        (r.prototype.bind = function (t, e) {
          'undefined' == typeof this.events[t] && (this.events[t] = []),
            this.events[t].push(e),
            this.element.addEventListener(t, e, !1);
        }),
          (r.prototype.unbind = function (t, e) {
            var n = 'undefined' != typeof e;
            this.events[t] = this.events[t].filter(function (r) {
              return (
                !(!n || r === e) ||
                (this.element.removeEventListener(t, r, !1), !1)
              );
            }, this);
          }),
          (r.prototype.unbindAll = function () {
            for (var t in this.events) this.unbind(t);
          });
        var o = function () {
          this.eventElements = [];
        };
        (o.prototype.eventElement = function (t) {
          var e = this.eventElements.filter(function (e) {
            return e.element === t;
          })[0];
          return (
            'undefined' == typeof e &&
              ((e = new r(t)), this.eventElements.push(e)),
            e
          );
        }),
          (o.prototype.bind = function (t, e, n) {
            this.eventElement(t).bind(e, n);
          }),
          (o.prototype.unbind = function (t, e, n) {
            this.eventElement(t).unbind(e, n);
          }),
          (o.prototype.unbindAll = function () {
            for (var t = 0; t < this.eventElements.length; t++)
              this.eventElements[t].unbindAll();
          }),
          (o.prototype.once = function (t, e, n) {
            var r = this.eventElement(t),
              o = function (t) {
                r.unbind(e, o), n(t);
              };
            r.bind(e, o);
          }),
          (e.exports = o);
      },
      {},
    ],
    4: [
      function (t, e, n) {
        'use strict';
        e.exports = (function () {
          function t() {
            return Math.floor(65536 * (1 + Math.random()))
              .toString(16)
              .substring(1);
          }
          return function () {
            return (
              t() +
              t() +
              '-' +
              t() +
              '-' +
              t() +
              '-' +
              t() +
              '-' +
              t() +
              t() +
              t()
            );
          };
        })();
      },
      {},
    ],
    5: [
      function (t, e, n) {
        'use strict';
        function r(t) {
          var e,
            n = ['ps--in-scrolling'];
          return (
            (e = 'undefined' == typeof t ? ['ps--x', 'ps--y'] : ['ps--' + t]),
            n.concat(e)
          );
        }
        var o = t('./dom'),
          l = (n.toInt = function (t) {
            return parseInt(t, 10) || 0;
          });
        (n.isEditable = function (t) {
          return (
            o.matches(t, 'input,[contenteditable]') ||
            o.matches(t, 'select,[contenteditable]') ||
            o.matches(t, 'textarea,[contenteditable]') ||
            o.matches(t, 'button,[contenteditable]')
          );
        }),
          (n.removePsClasses = function (t) {
            for (var e = 0; e < t.classList.length; e++) {
              var n = t.classList[e];
              0 === n.indexOf('ps-') && t.classList.remove(n);
            }
          }),
          (n.outerWidth = function (t) {
            return (
              l(o.css(t, 'width')) +
              l(o.css(t, 'paddingLeft')) +
              l(o.css(t, 'paddingRight')) +
              l(o.css(t, 'borderLeftWidth')) +
              l(o.css(t, 'borderRightWidth'))
            );
          }),
          (n.startScrolling = function (t, e) {
            for (var n = r(e), o = 0; o < n.length; o++) t.classList.add(n[o]);
          }),
          (n.stopScrolling = function (t, e) {
            for (var n = r(e), o = 0; o < n.length; o++)
              t.classList.remove(n[o]);
          }),
          (n.env = {
            isWebKit:
              'undefined' != typeof document &&
              'WebkitAppearance' in document.documentElement.style,
            supportsTouch:
              'undefined' != typeof window &&
              ('ontouchstart' in window ||
                (window.DocumentTouch &&
                  document instanceof window.DocumentTouch)),
            supportsIePointer:
              'undefined' != typeof window &&
              null !== window.navigator.msMaxTouchPoints,
          });
      },
      { './dom': 2 },
    ],
    6: [
      function (t, e, n) {
        'use strict';
        var r = t('./plugin/destroy'),
          o = t('./plugin/initialize'),
          l = t('./plugin/update');
        e.exports = { initialize: o, update: l, destroy: r };
      },
      {
        './plugin/destroy': 8,
        './plugin/initialize': 16,
        './plugin/update': 20,
      },
    ],
    7: [
      function (t, e, n) {
        'use strict';
        e.exports = function () {
          return {
            handlers: [
              'click-rail',
              'drag-scrollbar',
              'keyboard',
              'wheel',
              'touch',
            ],
            maxScrollbarLength: null,
            minScrollbarLength: null,
            scrollXMarginOffset: 0,
            scrollYMarginOffset: 0,
            suppressScrollX: !1,
            suppressScrollY: !1,
            swipePropagation: !0,
            swipeEasing: !0,
            useBothWheelAxes: !1,
            wheelPropagation: !1,
            wheelSpeed: 1,
            theme: 'default',
          };
        };
      },
      {},
    ],
    8: [
      function (t, e, n) {
        'use strict';
        var r = t('../lib/helper'),
          o = t('../lib/dom'),
          l = t('./instances');
        e.exports = function (t) {
          var e = l.get(t);
          e &&
            (e.event.unbindAll(),
            o.remove(e.scrollbarX),
            o.remove(e.scrollbarY),
            o.remove(e.scrollbarXRail),
            o.remove(e.scrollbarYRail),
            r.removePsClasses(t),
            l.remove(t));
        };
      },
      { '../lib/dom': 2, '../lib/helper': 5, './instances': 17 },
    ],
    9: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n(t) {
            return t.getBoundingClientRect();
          }
          var r = function (t) {
            t.stopPropagation();
          };
          e.event.bind(e.scrollbarY, 'click', r),
            e.event.bind(e.scrollbarYRail, 'click', function (r) {
              var o = r.pageY - window.pageYOffset - n(e.scrollbarYRail).top,
                s = o > e.scrollbarYTop ? 1 : -1;
              i(t, 'top', t.scrollTop + s * e.containerHeight),
                l(t),
                r.stopPropagation();
            }),
            e.event.bind(e.scrollbarX, 'click', r),
            e.event.bind(e.scrollbarXRail, 'click', function (r) {
              var o = r.pageX - window.pageXOffset - n(e.scrollbarXRail).left,
                s = o > e.scrollbarXLeft ? 1 : -1;
              i(t, 'left', t.scrollLeft + s * e.containerWidth),
                l(t),
                r.stopPropagation();
            });
        }
        var o = t('../instances'),
          l = t('../update-geometry'),
          i = t('../update-scroll');
        e.exports = function (t) {
          var e = o.get(t);
          r(t, e);
        };
      },
      { '../instances': 17, '../update-geometry': 18, '../update-scroll': 19 },
    ],
    10: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n(n) {
            var o = r + n * e.railXRatio,
              i =
                Math.max(0, e.scrollbarXRail.getBoundingClientRect().left) +
                e.railXRatio * (e.railXWidth - e.scrollbarXWidth);
            o < 0
              ? (e.scrollbarXLeft = 0)
              : o > i
              ? (e.scrollbarXLeft = i)
              : (e.scrollbarXLeft = o);
            var s =
              l.toInt(
                (e.scrollbarXLeft * (e.contentWidth - e.containerWidth)) /
                  (e.containerWidth - e.railXRatio * e.scrollbarXWidth)
              ) - e.negativeScrollAdjustment;
            c(t, 'left', s);
          }
          var r = null,
            o = null,
            s = function (e) {
              n(e.pageX - o), a(t), e.stopPropagation(), e.preventDefault();
            },
            u = function () {
              l.stopScrolling(t, 'x'),
                e.event.unbind(e.ownerDocument, 'mousemove', s);
            };
          e.event.bind(e.scrollbarX, 'mousedown', function (n) {
            (o = n.pageX),
              (r = l.toInt(i.css(e.scrollbarX, 'left')) * e.railXRatio),
              l.startScrolling(t, 'x'),
              e.event.bind(e.ownerDocument, 'mousemove', s),
              e.event.once(e.ownerDocument, 'mouseup', u),
              n.stopPropagation(),
              n.preventDefault();
          });
        }
        function o(t, e) {
          function n(n) {
            var o = r + n * e.railYRatio,
              i =
                Math.max(0, e.scrollbarYRail.getBoundingClientRect().top) +
                e.railYRatio * (e.railYHeight - e.scrollbarYHeight);
            o < 0
              ? (e.scrollbarYTop = 0)
              : o > i
              ? (e.scrollbarYTop = i)
              : (e.scrollbarYTop = o);
            var s = l.toInt(
              (e.scrollbarYTop * (e.contentHeight - e.containerHeight)) /
                (e.containerHeight - e.railYRatio * e.scrollbarYHeight)
            );
            c(t, 'top', s);
          }
          var r = null,
            o = null,
            s = function (e) {
              n(e.pageY - o), a(t), e.stopPropagation(), e.preventDefault();
            },
            u = function () {
              l.stopScrolling(t, 'y'),
                e.event.unbind(e.ownerDocument, 'mousemove', s);
            };
          e.event.bind(e.scrollbarY, 'mousedown', function (n) {
            (o = n.pageY),
              (r = l.toInt(i.css(e.scrollbarY, 'top')) * e.railYRatio),
              l.startScrolling(t, 'y'),
              e.event.bind(e.ownerDocument, 'mousemove', s),
              e.event.once(e.ownerDocument, 'mouseup', u),
              n.stopPropagation(),
              n.preventDefault();
          });
        }
        var l = t('../../lib/helper'),
          i = t('../../lib/dom'),
          s = t('../instances'),
          a = t('../update-geometry'),
          c = t('../update-scroll');
        e.exports = function (t) {
          var e = s.get(t);
          r(t, e), o(t, e);
        };
      },
      {
        '../../lib/dom': 2,
        '../../lib/helper': 5,
        '../instances': 17,
        '../update-geometry': 18,
        '../update-scroll': 19,
      },
    ],
    11: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n(n, r) {
            var o = t.scrollTop;
            if (0 === n) {
              if (!e.scrollbarYActive) return !1;
              if (
                (0 === o && r > 0) ||
                (o >= e.contentHeight - e.containerHeight && r < 0)
              )
                return !e.settings.wheelPropagation;
            }
            var l = t.scrollLeft;
            if (0 === r) {
              if (!e.scrollbarXActive) return !1;
              if (
                (0 === l && n < 0) ||
                (l >= e.contentWidth - e.containerWidth && n > 0)
              )
                return !e.settings.wheelPropagation;
            }
            return !0;
          }
          var r = !1;
          e.event.bind(t, 'mouseenter', function () {
            r = !0;
          }),
            e.event.bind(t, 'mouseleave', function () {
              r = !1;
            });
          var i = !1;
          e.event.bind(e.ownerDocument, 'keydown', function (c) {
            if (
              !(
                (c.isDefaultPrevented && c.isDefaultPrevented()) ||
                c.defaultPrevented
              )
            ) {
              var u =
                l.matches(e.scrollbarX, ':focus') ||
                l.matches(e.scrollbarY, ':focus');
              if (r || u) {
                var d = document.activeElement
                  ? document.activeElement
                  : e.ownerDocument.activeElement;
                if (d) {
                  if ('IFRAME' === d.tagName)
                    d = d.contentDocument.activeElement;
                  else for (; d.shadowRoot; ) d = d.shadowRoot.activeElement;
                  if (o.isEditable(d)) return;
                }
                var p = 0,
                  f = 0;
                switch (c.which) {
                  case 37:
                    p = c.metaKey
                      ? -e.contentWidth
                      : c.altKey
                      ? -e.containerWidth
                      : -30;
                    break;
                  case 38:
                    f = c.metaKey
                      ? e.contentHeight
                      : c.altKey
                      ? e.containerHeight
                      : 30;
                    break;
                  case 39:
                    p = c.metaKey
                      ? e.contentWidth
                      : c.altKey
                      ? e.containerWidth
                      : 30;
                    break;
                  case 40:
                    f = c.metaKey
                      ? -e.contentHeight
                      : c.altKey
                      ? -e.containerHeight
                      : -30;
                    break;
                  case 33:
                    f = 90;
                    break;
                  case 32:
                    f = c.shiftKey ? 90 : -90;
                    break;
                  case 34:
                    f = -90;
                    break;
                  case 35:
                    f = c.ctrlKey ? -e.contentHeight : -e.containerHeight;
                    break;
                  case 36:
                    f = c.ctrlKey ? t.scrollTop : e.containerHeight;
                    break;
                  default:
                    return;
                }
                a(t, 'top', t.scrollTop - f),
                  a(t, 'left', t.scrollLeft + p),
                  s(t),
                  (i = n(p, f)),
                  i && c.preventDefault();
              }
            }
          });
        }
        var o = t('../../lib/helper'),
          l = t('../../lib/dom'),
          i = t('../instances'),
          s = t('../update-geometry'),
          a = t('../update-scroll');
        e.exports = function (t) {
          var e = i.get(t);
          r(t, e);
        };
      },
      {
        '../../lib/dom': 2,
        '../../lib/helper': 5,
        '../instances': 17,
        '../update-geometry': 18,
        '../update-scroll': 19,
      },
    ],
    12: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n(n, r) {
            var o = t.scrollTop;
            if (0 === n) {
              if (!e.scrollbarYActive) return !1;
              if (
                (0 === o && r > 0) ||
                (o >= e.contentHeight - e.containerHeight && r < 0)
              )
                return !e.settings.wheelPropagation;
            }
            var l = t.scrollLeft;
            if (0 === r) {
              if (!e.scrollbarXActive) return !1;
              if (
                (0 === l && n < 0) ||
                (l >= e.contentWidth - e.containerWidth && n > 0)
              )
                return !e.settings.wheelPropagation;
            }
            return !0;
          }
          function r(t) {
            var e = t.deltaX,
              n = -1 * t.deltaY;
            return (
              ('undefined' != typeof e && 'undefined' != typeof n) ||
                ((e = (-1 * t.wheelDeltaX) / 6), (n = t.wheelDeltaY / 6)),
              t.deltaMode && 1 === t.deltaMode && ((e *= 10), (n *= 10)),
              e !== e && n !== n && ((e = 0), (n = t.wheelDelta)),
              t.shiftKey ? [-n, -e] : [e, n]
            );
          }
          function o(e, n) {
            var r = t.querySelector(
              'textarea:hover, select[multiple]:hover, .ps-child:hover'
            );
            if (r) {
              var o = window.getComputedStyle(r),
                l = [o.overflow, o.overflowX, o.overflowY].join('');
              if (!l.match(/(scroll|auto)/)) return !1;
              var i = r.scrollHeight - r.clientHeight;
              if (
                i > 0 &&
                !((0 === r.scrollTop && n > 0) || (r.scrollTop === i && n < 0))
              )
                return !0;
              var s = r.scrollLeft - r.clientWidth;
              if (
                s > 0 &&
                !(
                  (0 === r.scrollLeft && e < 0) ||
                  (r.scrollLeft === s && e > 0)
                )
              )
                return !0;
            }
            return !1;
          }
          function s(s) {
            var c = r(s),
              u = c[0],
              d = c[1];
            o(u, d) ||
              ((a = !1),
              e.settings.useBothWheelAxes
                ? e.scrollbarYActive && !e.scrollbarXActive
                  ? (d
                      ? i(t, 'top', t.scrollTop - d * e.settings.wheelSpeed)
                      : i(t, 'top', t.scrollTop + u * e.settings.wheelSpeed),
                    (a = !0))
                  : e.scrollbarXActive &&
                    !e.scrollbarYActive &&
                    (u
                      ? i(t, 'left', t.scrollLeft + u * e.settings.wheelSpeed)
                      : i(t, 'left', t.scrollLeft - d * e.settings.wheelSpeed),
                    (a = !0))
                : (i(t, 'top', t.scrollTop - d * e.settings.wheelSpeed),
                  i(t, 'left', t.scrollLeft + u * e.settings.wheelSpeed)),
              l(t),
              (a = a || n(u, d)),
              a && (s.stopPropagation(), s.preventDefault()));
          }
          var a = !1;
          'undefined' != typeof window.onwheel
            ? e.event.bind(t, 'wheel', s)
            : 'undefined' != typeof window.onmousewheel &&
              e.event.bind(t, 'mousewheel', s);
        }
        var o = t('../instances'),
          l = t('../update-geometry'),
          i = t('../update-scroll');
        e.exports = function (t) {
          var e = o.get(t);
          r(t, e);
        };
      },
      { '../instances': 17, '../update-geometry': 18, '../update-scroll': 19 },
    ],
    13: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          e.event.bind(t, 'scroll', function () {
            l(t);
          });
        }
        var o = t('../instances'),
          l = t('../update-geometry');
        e.exports = function (t) {
          var e = o.get(t);
          r(t, e);
        };
      },
      { '../instances': 17, '../update-geometry': 18 },
    ],
    14: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n() {
            var t = window.getSelection
              ? window.getSelection()
              : document.getSelection
              ? document.getSelection()
              : '';
            return 0 === t.toString().length
              ? null
              : t.getRangeAt(0).commonAncestorContainer;
          }
          function r() {
            c ||
              (c = setInterval(function () {
                return l.get(t)
                  ? (s(t, 'top', t.scrollTop + u.top),
                    s(t, 'left', t.scrollLeft + u.left),
                    void i(t))
                  : void clearInterval(c);
              }, 50));
          }
          function a() {
            c && (clearInterval(c), (c = null)), o.stopScrolling(t);
          }
          var c = null,
            u = { top: 0, left: 0 },
            d = !1;
          e.event.bind(e.ownerDocument, 'selectionchange', function () {
            t.contains(n()) ? (d = !0) : ((d = !1), a());
          }),
            e.event.bind(window, 'mouseup', function () {
              d && ((d = !1), a());
            }),
            e.event.bind(window, 'keyup', function () {
              d && ((d = !1), a());
            }),
            e.event.bind(window, 'mousemove', function (e) {
              if (d) {
                var n = { x: e.pageX, y: e.pageY },
                  l = {
                    left: t.offsetLeft,
                    right: t.offsetLeft + t.offsetWidth,
                    top: t.offsetTop,
                    bottom: t.offsetTop + t.offsetHeight,
                  };
                n.x < l.left + 3
                  ? ((u.left = -5), o.startScrolling(t, 'x'))
                  : n.x > l.right - 3
                  ? ((u.left = 5), o.startScrolling(t, 'x'))
                  : (u.left = 0),
                  n.y < l.top + 3
                    ? (l.top + 3 - n.y < 5 ? (u.top = -5) : (u.top = -20),
                      o.startScrolling(t, 'y'))
                    : n.y > l.bottom - 3
                    ? (n.y - l.bottom + 3 < 5 ? (u.top = 5) : (u.top = 20),
                      o.startScrolling(t, 'y'))
                    : (u.top = 0),
                  0 === u.top && 0 === u.left ? a() : r();
              }
            });
        }
        var o = t('../../lib/helper'),
          l = t('../instances'),
          i = t('../update-geometry'),
          s = t('../update-scroll');
        e.exports = function (t) {
          var e = l.get(t);
          r(t, e);
        };
      },
      {
        '../../lib/helper': 5,
        '../instances': 17,
        '../update-geometry': 18,
        '../update-scroll': 19,
      },
    ],
    15: [
      function (t, e, n) {
        'use strict';
        function r(t, e, n, r) {
          function o(n, r) {
            var o = t.scrollTop,
              l = t.scrollLeft,
              i = Math.abs(n),
              s = Math.abs(r);
            if (s > i) {
              if (
                (r < 0 && o === e.contentHeight - e.containerHeight) ||
                (r > 0 && 0 === o)
              )
                return !e.settings.swipePropagation;
            } else if (
              i > s &&
              ((n < 0 && l === e.contentWidth - e.containerWidth) ||
                (n > 0 && 0 === l))
            )
              return !e.settings.swipePropagation;
            return !0;
          }
          function a(e, n) {
            s(t, 'top', t.scrollTop - n), s(t, 'left', t.scrollLeft - e), i(t);
          }
          function c() {
            w = !0;
          }
          function u() {
            w = !1;
          }
          function d(t) {
            return t.targetTouches ? t.targetTouches[0] : t;
          }
          function p(t) {
            return !(
              (t.pointerType && 'pen' === t.pointerType && 0 === t.buttons) ||
              ((!t.targetTouches || 1 !== t.targetTouches.length) &&
                (!t.pointerType ||
                  'mouse' === t.pointerType ||
                  t.pointerType === t.MSPOINTER_TYPE_MOUSE))
            );
          }
          function f(t) {
            if (p(t)) {
              Y = !0;
              var e = d(t);
              (g.pageX = e.pageX),
                (g.pageY = e.pageY),
                (v = new Date().getTime()),
                null !== y && clearInterval(y),
                t.stopPropagation();
            }
          }
          function h(t) {
            if ((!Y && e.settings.swipePropagation && f(t), !w && Y && p(t))) {
              var n = d(t),
                r = { pageX: n.pageX, pageY: n.pageY },
                l = r.pageX - g.pageX,
                i = r.pageY - g.pageY;
              a(l, i), (g = r);
              var s = new Date().getTime(),
                c = s - v;
              c > 0 && ((m.x = l / c), (m.y = i / c), (v = s)),
                o(l, i) && (t.stopPropagation(), t.preventDefault());
            }
          }
          function b() {
            !w &&
              Y &&
              ((Y = !1),
              e.settings.swipeEasing &&
                (clearInterval(y),
                (y = setInterval(function () {
                  return l.get(t) && (m.x || m.y)
                    ? Math.abs(m.x) < 0.01 && Math.abs(m.y) < 0.01
                      ? void clearInterval(y)
                      : (a(30 * m.x, 30 * m.y), (m.x *= 0.8), void (m.y *= 0.8))
                    : void clearInterval(y);
                }, 10))));
          }
          var g = {},
            v = 0,
            m = {},
            y = null,
            w = !1,
            Y = !1;
          n
            ? (e.event.bind(window, 'touchstart', c),
              e.event.bind(window, 'touchend', u),
              e.event.bind(t, 'touchstart', f),
              e.event.bind(t, 'touchmove', h),
              e.event.bind(t, 'touchend', b))
            : r &&
              (window.PointerEvent
                ? (e.event.bind(window, 'pointerdown', c),
                  e.event.bind(window, 'pointerup', u),
                  e.event.bind(t, 'pointerdown', f),
                  e.event.bind(t, 'pointermove', h),
                  e.event.bind(t, 'pointerup', b))
                : window.MSPointerEvent &&
                  (e.event.bind(window, 'MSPointerDown', c),
                  e.event.bind(window, 'MSPointerUp', u),
                  e.event.bind(t, 'MSPointerDown', f),
                  e.event.bind(t, 'MSPointerMove', h),
                  e.event.bind(t, 'MSPointerUp', b)));
        }
        var o = t('../../lib/helper'),
          l = t('../instances'),
          i = t('../update-geometry'),
          s = t('../update-scroll');
        e.exports = function (t) {
          if (o.env.supportsTouch || o.env.supportsIePointer) {
            var e = l.get(t);
            r(t, e, o.env.supportsTouch, o.env.supportsIePointer);
          }
        };
      },
      {
        '../../lib/helper': 5,
        '../instances': 17,
        '../update-geometry': 18,
        '../update-scroll': 19,
      },
    ],
    16: [
      function (t, e, n) {
        'use strict';
        var r = t('./instances'),
          o = t('./update-geometry'),
          l = {
            'click-rail': t('./handler/click-rail'),
            'drag-scrollbar': t('./handler/drag-scrollbar'),
            keyboard: t('./handler/keyboard'),
            wheel: t('./handler/mouse-wheel'),
            touch: t('./handler/touch'),
            selection: t('./handler/selection'),
          },
          i = t('./handler/native-scroll');
        e.exports = function (t, e) {
          t.classList.add('ps');
          var n = r.add(t, 'object' == typeof e ? e : {});
          t.classList.add('ps--theme_' + n.settings.theme),
            n.settings.handlers.forEach(function (e) {
              l[e](t);
            }),
            i(t),
            o(t);
        };
      },
      {
        './handler/click-rail': 9,
        './handler/drag-scrollbar': 10,
        './handler/keyboard': 11,
        './handler/mouse-wheel': 12,
        './handler/native-scroll': 13,
        './handler/selection': 14,
        './handler/touch': 15,
        './instances': 17,
        './update-geometry': 18,
      },
    ],
    17: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n() {
            t.classList.add('ps--focus');
          }
          function r() {
            t.classList.remove('ps--focus');
          }
          var o = this;
          o.settings = a();
          for (var l in e) o.settings[l] = e[l];
          (o.containerWidth = null),
            (o.containerHeight = null),
            (o.contentWidth = null),
            (o.contentHeight = null),
            (o.isRtl = 'rtl' === c.css(t, 'direction')),
            (o.isNegativeScroll = (function () {
              var e = t.scrollLeft,
                n = null;
              return (
                (t.scrollLeft = -1),
                (n = t.scrollLeft < 0),
                (t.scrollLeft = e),
                n
              );
            })()),
            (o.negativeScrollAdjustment = o.isNegativeScroll
              ? t.scrollWidth - t.clientWidth
              : 0),
            (o.event = new u()),
            (o.ownerDocument = t.ownerDocument || document),
            (o.scrollbarXRail = c.appendTo(
              c.create('div', 'ps__scrollbar-x-rail'),
              t
            )),
            (o.scrollbarX = c.appendTo(
              c.create('div', 'ps__scrollbar-x'),
              o.scrollbarXRail
            )),
            o.scrollbarX.setAttribute('tabindex', 0),
            o.event.bind(o.scrollbarX, 'focus', n),
            o.event.bind(o.scrollbarX, 'blur', r),
            (o.scrollbarXActive = null),
            (o.scrollbarXWidth = null),
            (o.scrollbarXLeft = null),
            (o.scrollbarXBottom = s.toInt(c.css(o.scrollbarXRail, 'bottom'))),
            (o.isScrollbarXUsingBottom =
              o.scrollbarXBottom === o.scrollbarXBottom),
            (o.scrollbarXTop = o.isScrollbarXUsingBottom
              ? null
              : s.toInt(c.css(o.scrollbarXRail, 'top'))),
            (o.railBorderXWidth =
              s.toInt(c.css(o.scrollbarXRail, 'borderLeftWidth')) +
              s.toInt(c.css(o.scrollbarXRail, 'borderRightWidth'))),
            c.css(o.scrollbarXRail, 'display', 'block'),
            (o.railXMarginWidth =
              s.toInt(c.css(o.scrollbarXRail, 'marginLeft')) +
              s.toInt(c.css(o.scrollbarXRail, 'marginRight'))),
            c.css(o.scrollbarXRail, 'display', ''),
            (o.railXWidth = null),
            (o.railXRatio = null),
            (o.scrollbarYRail = c.appendTo(
              c.create('div', 'ps__scrollbar-y-rail'),
              t
            )),
            (o.scrollbarY = c.appendTo(
              c.create('div', 'ps__scrollbar-y'),
              o.scrollbarYRail
            )),
            o.scrollbarY.setAttribute('tabindex', 0),
            o.event.bind(o.scrollbarY, 'focus', n),
            o.event.bind(o.scrollbarY, 'blur', r),
            (o.scrollbarYActive = null),
            (o.scrollbarYHeight = null),
            (o.scrollbarYTop = null),
            (o.scrollbarYRight = s.toInt(c.css(o.scrollbarYRail, 'right'))),
            (o.isScrollbarYUsingRight =
              o.scrollbarYRight === o.scrollbarYRight),
            (o.scrollbarYLeft = o.isScrollbarYUsingRight
              ? null
              : s.toInt(c.css(o.scrollbarYRail, 'left'))),
            (o.scrollbarYOuterWidth = o.isRtl
              ? s.outerWidth(o.scrollbarY)
              : null),
            (o.railBorderYWidth =
              s.toInt(c.css(o.scrollbarYRail, 'borderTopWidth')) +
              s.toInt(c.css(o.scrollbarYRail, 'borderBottomWidth'))),
            c.css(o.scrollbarYRail, 'display', 'block'),
            (o.railYMarginHeight =
              s.toInt(c.css(o.scrollbarYRail, 'marginTop')) +
              s.toInt(c.css(o.scrollbarYRail, 'marginBottom'))),
            c.css(o.scrollbarYRail, 'display', ''),
            (o.railYHeight = null),
            (o.railYRatio = null);
        }
        function o(t) {
          return t.getAttribute('data-ps-id');
        }
        function l(t, e) {
          t.setAttribute('data-ps-id', e);
        }
        function i(t) {
          t.removeAttribute('data-ps-id');
        }
        var s = t('../lib/helper'),
          a = t('./default-setting'),
          c = t('../lib/dom'),
          u = t('../lib/event-manager'),
          d = t('../lib/guid'),
          p = {};
        (n.add = function (t, e) {
          var n = d();
          return l(t, n), (p[n] = new r(t, e)), p[n];
        }),
          (n.remove = function (t) {
            delete p[o(t)], i(t);
          }),
          (n.get = function (t) {
            return p[o(t)];
          });
      },
      {
        '../lib/dom': 2,
        '../lib/event-manager': 3,
        '../lib/guid': 4,
        '../lib/helper': 5,
        './default-setting': 7,
      },
    ],
    18: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          return (
            t.settings.minScrollbarLength &&
              (e = Math.max(e, t.settings.minScrollbarLength)),
            t.settings.maxScrollbarLength &&
              (e = Math.min(e, t.settings.maxScrollbarLength)),
            e
          );
        }
        function o(t, e) {
          var n = { width: e.railXWidth };
          e.isRtl
            ? (n.left =
                e.negativeScrollAdjustment +
                t.scrollLeft +
                e.containerWidth -
                e.contentWidth)
            : (n.left = t.scrollLeft),
            e.isScrollbarXUsingBottom
              ? (n.bottom = e.scrollbarXBottom - t.scrollTop)
              : (n.top = e.scrollbarXTop + t.scrollTop),
            i.css(e.scrollbarXRail, n);
          var r = { top: t.scrollTop, height: e.railYHeight };
          e.isScrollbarYUsingRight
            ? e.isRtl
              ? (r.right =
                  e.contentWidth -
                  (e.negativeScrollAdjustment + t.scrollLeft) -
                  e.scrollbarYRight -
                  e.scrollbarYOuterWidth)
              : (r.right = e.scrollbarYRight - t.scrollLeft)
            : e.isRtl
            ? (r.left =
                e.negativeScrollAdjustment +
                t.scrollLeft +
                2 * e.containerWidth -
                e.contentWidth -
                e.scrollbarYLeft -
                e.scrollbarYOuterWidth)
            : (r.left = e.scrollbarYLeft + t.scrollLeft),
            i.css(e.scrollbarYRail, r),
            i.css(e.scrollbarX, {
              left: e.scrollbarXLeft,
              width: e.scrollbarXWidth - e.railBorderXWidth,
            }),
            i.css(e.scrollbarY, {
              top: e.scrollbarYTop,
              height: e.scrollbarYHeight - e.railBorderYWidth,
            });
        }
        var l = t('../lib/helper'),
          i = t('../lib/dom'),
          s = t('./instances'),
          a = t('./update-scroll');
        e.exports = function (t) {
          var e = s.get(t);
          (e.containerWidth = t.clientWidth),
            (e.containerHeight = t.clientHeight),
            (e.contentWidth = t.scrollWidth),
            (e.contentHeight = t.scrollHeight);
          var n;
          t.contains(e.scrollbarXRail) ||
            ((n = i.queryChildren(t, '.ps__scrollbar-x-rail')),
            n.length > 0 &&
              n.forEach(function (t) {
                i.remove(t);
              }),
            i.appendTo(e.scrollbarXRail, t)),
            t.contains(e.scrollbarYRail) ||
              ((n = i.queryChildren(t, '.ps__scrollbar-y-rail')),
              n.length > 0 &&
                n.forEach(function (t) {
                  i.remove(t);
                }),
              i.appendTo(e.scrollbarYRail, t)),
            !e.settings.suppressScrollX &&
            e.containerWidth + e.settings.scrollXMarginOffset < e.contentWidth
              ? ((e.scrollbarXActive = !0),
                (e.railXWidth = e.containerWidth - e.railXMarginWidth),
                (e.railXRatio = e.containerWidth / e.railXWidth),
                (e.scrollbarXWidth = r(
                  e,
                  l.toInt((e.railXWidth * e.containerWidth) / e.contentWidth)
                )),
                (e.scrollbarXLeft = l.toInt(
                  ((e.negativeScrollAdjustment + t.scrollLeft) *
                    (e.railXWidth - e.scrollbarXWidth)) /
                    (e.contentWidth - e.containerWidth)
                )))
              : (e.scrollbarXActive = !1),
            !e.settings.suppressScrollY &&
            e.containerHeight + e.settings.scrollYMarginOffset < e.contentHeight
              ? ((e.scrollbarYActive = !0),
                (e.railYHeight = e.containerHeight - e.railYMarginHeight),
                (e.railYRatio = e.containerHeight / e.railYHeight),
                (e.scrollbarYHeight = r(
                  e,
                  l.toInt((e.railYHeight * e.containerHeight) / e.contentHeight)
                )),
                (e.scrollbarYTop = l.toInt(
                  (t.scrollTop * (e.railYHeight - e.scrollbarYHeight)) /
                    (e.contentHeight - e.containerHeight)
                )))
              : (e.scrollbarYActive = !1),
            e.scrollbarXLeft >= e.railXWidth - e.scrollbarXWidth &&
              (e.scrollbarXLeft = e.railXWidth - e.scrollbarXWidth),
            e.scrollbarYTop >= e.railYHeight - e.scrollbarYHeight &&
              (e.scrollbarYTop = e.railYHeight - e.scrollbarYHeight),
            o(t, e),
            e.scrollbarXActive
              ? t.classList.add('ps--active-x')
              : (t.classList.remove('ps--active-x'),
                (e.scrollbarXWidth = 0),
                (e.scrollbarXLeft = 0),
                a(t, 'left', 0)),
            e.scrollbarYActive
              ? t.classList.add('ps--active-y')
              : (t.classList.remove('ps--active-y'),
                (e.scrollbarYHeight = 0),
                (e.scrollbarYTop = 0),
                a(t, 'top', 0));
        };
      },
      {
        '../lib/dom': 2,
        '../lib/helper': 5,
        './instances': 17,
        './update-scroll': 19,
      },
    ],
    19: [
      function (t, e, n) {
        'use strict';
        var r = t('./instances'),
          o = function (t) {
            var e = document.createEvent('Event');
            return e.initEvent(t, !0, !0), e;
          };
        e.exports = function (t, e, n) {
          if ('undefined' == typeof t)
            throw 'You must provide an element to the update-scroll function';
          if ('undefined' == typeof e)
            throw 'You must provide an axis to the update-scroll function';
          if ('undefined' == typeof n)
            throw 'You must provide a value to the update-scroll function';
          'top' === e &&
            n <= 0 &&
            ((t.scrollTop = n = 0), t.dispatchEvent(o('ps-y-reach-start'))),
            'left' === e &&
              n <= 0 &&
              ((t.scrollLeft = n = 0), t.dispatchEvent(o('ps-x-reach-start')));
          var l = r.get(t);
          'top' === e &&
            n >= l.contentHeight - l.containerHeight &&
            ((n = l.contentHeight - l.containerHeight),
            n - t.scrollTop <= 2 ? (n = t.scrollTop) : (t.scrollTop = n),
            t.dispatchEvent(o('ps-y-reach-end'))),
            'left' === e &&
              n >= l.contentWidth - l.containerWidth &&
              ((n = l.contentWidth - l.containerWidth),
              n - t.scrollLeft <= 2 ? (n = t.scrollLeft) : (t.scrollLeft = n),
              t.dispatchEvent(o('ps-x-reach-end'))),
            void 0 === l.lastTop && (l.lastTop = t.scrollTop),
            void 0 === l.lastLeft && (l.lastLeft = t.scrollLeft),
            'top' === e && n < l.lastTop && t.dispatchEvent(o('ps-scroll-up')),
            'top' === e &&
              n > l.lastTop &&
              t.dispatchEvent(o('ps-scroll-down')),
            'left' === e &&
              n < l.lastLeft &&
              t.dispatchEvent(o('ps-scroll-left')),
            'left' === e &&
              n > l.lastLeft &&
              t.dispatchEvent(o('ps-scroll-right')),
            'top' === e &&
              n !== l.lastTop &&
              ((t.scrollTop = l.lastTop = n),
              t.dispatchEvent(o('ps-scroll-y'))),
            'left' === e &&
              n !== l.lastLeft &&
              ((t.scrollLeft = l.lastLeft = n),
              t.dispatchEvent(o('ps-scroll-x')));
        };
      },
      { './instances': 17 },
    ],
    20: [
      function (t, e, n) {
        'use strict';
        var r = t('../lib/helper'),
          o = t('../lib/dom'),
          l = t('./instances'),
          i = t('./update-geometry'),
          s = t('./update-scroll');
        e.exports = function (t) {
          var e = l.get(t);
          e &&
            ((e.negativeScrollAdjustment = e.isNegativeScroll
              ? t.scrollWidth - t.clientWidth
              : 0),
            o.css(e.scrollbarXRail, 'display', 'block'),
            o.css(e.scrollbarYRail, 'display', 'block'),
            (e.railXMarginWidth =
              r.toInt(o.css(e.scrollbarXRail, 'marginLeft')) +
              r.toInt(o.css(e.scrollbarXRail, 'marginRight'))),
            (e.railYMarginHeight =
              r.toInt(o.css(e.scrollbarYRail, 'marginTop')) +
              r.toInt(o.css(e.scrollbarYRail, 'marginBottom'))),
            o.css(e.scrollbarXRail, 'display', 'none'),
            o.css(e.scrollbarYRail, 'display', 'none'),
            i(t),
            s(t, 'top', t.scrollTop),
            s(t, 'left', t.scrollLeft),
            o.css(e.scrollbarXRail, 'display', ''),
            o.css(e.scrollbarYRail, 'display', ''));
        };
      },
      {
        '../lib/dom': 2,
        '../lib/helper': 5,
        './instances': 17,
        './update-geometry': 18,
        './update-scroll': 19,
      },
    ],
  },
  {},
  [1]
);
!(function (e, t, r) {
  'use strict';
  function n(r) {
    if (
      ((o = t.documentElement),
      (a = t.body),
      Y(),
      (le = this),
      (r = r || {}),
      (me = r.constants || {}),
      r.easing)
    )
      for (var n in r.easing) X[n] = r.easing[n];
    (Te = r.edgeStrategy || 'set'),
      (fe = {
        beforerender: r.beforerender,
        render: r.render,
        keyframe: r.keyframe,
      }),
      (ue = r.forceHeight !== !1),
      ue && (ze = r.scale || 1),
      (pe = r.mobileDeceleration || x),
      (ge = r.smoothScrolling !== !1),
      (ve = r.smoothScrollingDuration || A),
      (he = { targetTop: le.getScrollTop() }),
      (Ke = (
        r.mobileCheck ||
        function () {
          return /Android|iPhone|iPad|iPod|BlackBerry/i.test(
            navigator.userAgent || navigator.vendor || e.opera
          );
        }
      )()),
      Ke
        ? ((ce = t.getElementById(r.skrollrBody || E)),
          ce && ie(),
          j(),
          De(o, [y, S], [T]))
        : De(o, [y, b], [T]),
      le.refresh(),
      ke(e, 'resize orientationchange', function () {
        var e = o.clientWidth,
          t = o.clientHeight;
        (t !== Be || e !== $e) && ((Be = t), ($e = e), (_e = !0));
      });
    var i = R();
    return (
      (function l() {
        J(), (Se = i(l));
      })(),
      le
    );
  }
  var o,
    a,
    i = {
      get: function () {
        return le;
      },
      init: function (e) {
        return le || new n(e);
      },
      VERSION: '0.6.29',
    },
    l = Object.prototype.hasOwnProperty,
    s = e.Math,
    c = e.getComputedStyle,
    f = 'touchstart',
    u = 'touchmove',
    m = 'touchcancel',
    p = 'touchend',
    d = 'skrollable',
    g = d + '-before',
    v = d + '-between',
    h = d + '-after',
    y = 'skrollr',
    T = 'no-' + y,
    b = y + '-desktop',
    S = y + '-mobile',
    k = 'linear',
    w = 1e3,
    x = 0.004,
    E = 'skrollr-body',
    A = 200,
    F = 'start',
    C = 'end',
    H = 'center',
    D = 'bottom',
    I = '___skrollable_id',
    P = /^(?:input|textarea|button|select)$/i,
    N = /^\s+|\s+$/g,
    O =
      /^data(?:-(_\w+))?(?:-?(-?\d*\.?\d+p?))?(?:-?(start|end|top|center|bottom))?(?:-?(top|center|bottom))?$/,
    V = /\s*(@?[\w\-\[\]]+)\s*:\s*(.+?)\s*(?:;|$)/gi,
    z = /^(@?[a-z\-]+)\[(\w+)\]$/,
    q = /-([a-z0-9_])/g,
    L = function (e, t) {
      return t.toUpperCase();
    },
    M = /[\-+]?[\d]*\.?[\d]+/g,
    $ = /\{\?\}/g,
    B = /rgba?\(\s*-?\d+\s*,\s*-?\d+\s*,\s*-?\d+/g,
    _ = /[a-z\-]+-gradient/g,
    G = '',
    K = '',
    Y = function () {
      var e = /^(?:O|Moz|webkit|ms)|(?:-(?:o|moz|webkit|ms)-)/;
      if (c) {
        var t = c(a, null);
        for (var r in t)
          if ((G = r.match(e) || (+r == r && t[r].match(e)))) break;
        if (!G) return void (G = K = '');
        (G = G[0]),
          '-' === G.slice(0, 1)
            ? ((K = G),
              (G = {
                '-webkit-': 'webkit',
                '-moz-': 'Moz',
                '-ms-': 'ms',
                '-o-': 'O',
              }[G]))
            : (K = '-' + G.toLowerCase() + '-');
      }
    },
    R = function () {
      var t =
          e.requestAnimationFrame ||
          e[G.toLowerCase() + 'RequestAnimationFrame'],
        r = Ne();
      return (
        (Ke || !t) &&
          (t = function (t) {
            var n = Ne() - r,
              o = s.max(0, 1e3 / 60 - n);
            return e.setTimeout(function () {
              (r = Ne()), t();
            }, o);
          }),
        t
      );
    },
    U = function () {
      var t =
        e.cancelAnimationFrame || e[G.toLowerCase() + 'CancelAnimationFrame'];
      return (
        (Ke || !t) &&
          (t = function (t) {
            return e.clearTimeout(t);
          }),
        t
      );
    },
    X = {
      begin: function () {
        return 0;
      },
      end: function () {
        return 1;
      },
      linear: function (e) {
        return e;
      },
      quadratic: function (e) {
        return e * e;
      },
      cubic: function (e) {
        return e * e * e;
      },
      swing: function (e) {
        return -s.cos(e * s.PI) / 2 + 0.5;
      },
      sqrt: function (e) {
        return s.sqrt(e);
      },
      outCubic: function (e) {
        return s.pow(e - 1, 3) + 1;
      },
      bounce: function (e) {
        var t;
        if (0.5083 >= e) t = 3;
        else if (0.8489 >= e) t = 9;
        else if (0.96208 >= e) t = 27;
        else {
          if (!(0.99981 >= e)) return 1;
          t = 91;
        }
        return 1 - s.abs((3 * s.cos(e * t * 1.028)) / t);
      },
    };
  (n.prototype.refresh = function (e) {
    var n,
      o,
      a = !1;
    for (
      e === r
        ? ((a = !0), (se = []), (Ge = 0), (e = t.getElementsByTagName('*')))
        : e.length === r && (e = [e]),
        n = 0,
        o = e.length;
      o > n;
      n++
    ) {
      var i = e[n],
        l = i,
        s = [],
        c = ge,
        f = Te,
        u = !1;
      if ((a && I in i && delete i[I], i.attributes)) {
        for (var m = 0, p = i.attributes.length; p > m; m++) {
          var g = i.attributes[m];
          if ('data-anchor-target' !== g.name)
            if ('data-smooth-scrolling' !== g.name)
              if ('data-edge-strategy' !== g.name)
                if ('data-emit-events' !== g.name) {
                  var v = g.name.match(O);
                  if (null !== v) {
                    var h = {
                      props: g.value,
                      element: i,
                      eventType: g.name.replace(q, L),
                    };
                    s.push(h);
                    var y = v[1];
                    y && (h.constant = y.substr(1));
                    var T = v[2];
                    /p$/.test(T)
                      ? ((h.isPercentage = !0),
                        (h.offset = (0 | T.slice(0, -1)) / 100))
                      : (h.offset = 0 | T);
                    var b = v[3],
                      S = v[4] || b;
                    b && b !== F && b !== C
                      ? ((h.mode = 'relative'), (h.anchors = [b, S]))
                      : ((h.mode = 'absolute'),
                        b === C
                          ? (h.isEnd = !0)
                          : h.isPercentage || (h.offset = h.offset * ze));
                  }
                } else u = !0;
              else f = g.value;
            else c = 'off' !== g.value;
          else if (((l = t.querySelector(g.value)), null === l))
            throw 'Unable to find anchor target "' + g.value + '"';
        }
        if (s.length) {
          var k, w, x;
          !a && I in i
            ? ((x = i[I]), (k = se[x].styleAttr), (w = se[x].classAttr))
            : ((x = i[I] = Ge++), (k = i.style.cssText), (w = He(i))),
            (se[x] = {
              element: i,
              styleAttr: k,
              classAttr: w,
              anchorTarget: l,
              keyFrames: s,
              smoothScrolling: c,
              edgeStrategy: f,
              emitEvents: u,
              lastFrameIndex: -1,
            }),
            De(i, [d], []);
        }
      }
    }
    for (Ae(), n = 0, o = e.length; o > n; n++) {
      var E = se[e[n][I]];
      E !== r && (Q(E), te(E));
    }
    return le;
  }),
    (n.prototype.relativeToAbsolute = function (e, t, r) {
      var n = o.clientHeight,
        a = e.getBoundingClientRect(),
        i = a.top,
        l = a.bottom - a.top;
      return (
        t === D ? (i -= n) : t === H && (i -= n / 2),
        r === D ? (i += l) : r === H && (i += l / 2),
        (i += le.getScrollTop()),
        (i + 0.5) | 0
      );
    }),
    (n.prototype.animateTo = function (e, t) {
      t = t || {};
      var n = Ne(),
        o = le.getScrollTop(),
        a = t.duration === r ? w : t.duration;
      return (
        (de = {
          startTop: o,
          topDiff: e - o,
          targetTop: e,
          duration: a,
          startTime: n,
          endTime: n + a,
          easing: X[t.easing || k],
          done: t.done,
        }),
        de.topDiff || (de.done && de.done.call(le, !1), (de = r)),
        le
      );
    }),
    (n.prototype.stopAnimateTo = function () {
      de && de.done && de.done.call(le, !0), (de = r);
    }),
    (n.prototype.isAnimatingTo = function () {
      return !!de;
    }),
    (n.prototype.isMobile = function () {
      return Ke;
    }),
    (n.prototype.setScrollTop = function (t, r) {
      return (
        (ye = r === !0),
        Ke ? (Ye = s.min(s.max(t, 0), Ve)) : e.scrollTo(0, t),
        le
      );
    }),
    (n.prototype.getScrollTop = function () {
      return Ke ? Ye : e.pageYOffset || o.scrollTop || a.scrollTop || 0;
    }),
    (n.prototype.getMaxScrollTop = function () {
      return Ve;
    }),
    (n.prototype.on = function (e, t) {
      return (fe[e] = t), le;
    }),
    (n.prototype.off = function (e) {
      return delete fe[e], le;
    }),
    (n.prototype.destroy = function () {
      var e = U();
      e(Se), xe(), De(o, [T], [y, b, S]);
      for (var t = 0, n = se.length; n > t; t++) ae(se[t].element);
      (o.style.overflow = a.style.overflow = ''),
        (o.style.height = a.style.height = ''),
        ce && i.setStyle(ce, 'transform', 'none'),
        (le = r),
        (ce = r),
        (fe = r),
        (ue = r),
        (Ve = 0),
        (ze = 1),
        (me = r),
        (pe = r),
        (qe = 'down'),
        (Le = -1),
        ($e = 0),
        (Be = 0),
        (_e = !1),
        (de = r),
        (ge = r),
        (ve = r),
        (he = r),
        (ye = r),
        (Ge = 0),
        (Te = r),
        (Ke = !1),
        (Ye = 0),
        (be = r);
    });
  var j = function () {
      var n, i, l, c, d, g, v, h, y, T, b, S;
      ke(o, [f, u, m, p].join(' '), function (e) {
        var o = e.changedTouches[0];
        for (c = e.target; 3 === c.nodeType; ) c = c.parentNode;
        switch (
          ((d = o.clientY),
          (g = o.clientX),
          (T = e.timeStamp),
          P.test(c.tagName) || e.preventDefault(),
          e.type)
        ) {
          case f:
            n && n.blur(),
              le.stopAnimateTo(),
              (n = c),
              (i = v = d),
              (l = g),
              (y = T);
            break;
          case u:
            P.test(c.tagName) && t.activeElement !== c && e.preventDefault(),
              (h = d - v),
              (S = T - b),
              le.setScrollTop(Ye - h, !0),
              (v = d),
              (b = T);
            break;
          default:
          case m:
          case p:
            var a = i - d,
              k = l - g,
              w = k * k + a * a;
            if (49 > w) {
              if (!P.test(n.tagName)) {
                n.focus();
                var x = t.createEvent('MouseEvents');
                x.initMouseEvent(
                  'click',
                  !0,
                  !0,
                  e.view,
                  1,
                  o.screenX,
                  o.screenY,
                  o.clientX,
                  o.clientY,
                  e.ctrlKey,
                  e.altKey,
                  e.shiftKey,
                  e.metaKey,
                  0,
                  null
                ),
                  n.dispatchEvent(x);
              }
              return;
            }
            n = r;
            var E = h / S;
            E = s.max(s.min(E, 3), -3);
            var A = s.abs(E / pe),
              F = E * A + 0.5 * pe * A * A,
              C = le.getScrollTop() - F,
              H = 0;
            C > Ve
              ? ((H = (Ve - C) / F), (C = Ve))
              : 0 > C && ((H = -C / F), (C = 0)),
              (A *= 1 - H),
              le.animateTo((C + 0.5) | 0, { easing: 'outCubic', duration: A });
        }
      }),
        e.scrollTo(0, 0),
        (o.style.overflow = a.style.overflow = 'hidden');
    },
    W = function () {
      var e,
        t,
        r,
        n,
        a,
        i,
        l,
        c,
        f,
        u,
        m,
        p = o.clientHeight,
        d = Fe();
      for (c = 0, f = se.length; f > c; c++)
        for (
          e = se[c],
            t = e.element,
            r = e.anchorTarget,
            n = e.keyFrames,
            a = 0,
            i = n.length;
          i > a;
          a++
        )
          (l = n[a]),
            (u = l.offset),
            (m = d[l.constant] || 0),
            (l.frame = u),
            l.isPercentage && ((u *= p), (l.frame = u)),
            'relative' === l.mode &&
              (ae(t),
              (l.frame =
                le.relativeToAbsolute(r, l.anchors[0], l.anchors[1]) - u),
              ae(t, !0)),
            (l.frame += m),
            ue && !l.isEnd && l.frame > Ve && (Ve = l.frame);
      for (Ve = s.max(Ve, Ce()), c = 0, f = se.length; f > c; c++) {
        for (e = se[c], n = e.keyFrames, a = 0, i = n.length; i > a; a++)
          (l = n[a]),
            (m = d[l.constant] || 0),
            l.isEnd && (l.frame = Ve - l.offset + m);
        e.keyFrames.sort(Oe);
      }
    },
    Z = function (e, t) {
      for (var r = 0, n = se.length; n > r; r++) {
        var o,
          a,
          s = se[r],
          c = s.element,
          f = s.smoothScrolling ? e : t,
          u = s.keyFrames,
          m = u.length,
          p = u[0],
          y = u[u.length - 1],
          T = f < p.frame,
          b = f > y.frame,
          S = T ? p : y,
          k = s.emitEvents,
          w = s.lastFrameIndex;
        if (T || b) {
          if ((T && -1 === s.edge) || (b && 1 === s.edge)) continue;
          switch (
            (T
              ? (De(c, [g], [h, v]),
                k &&
                  w > -1 &&
                  (Ee(c, p.eventType, qe), (s.lastFrameIndex = -1)))
              : (De(c, [h], [g, v]),
                k && m > w && (Ee(c, y.eventType, qe), (s.lastFrameIndex = m))),
            (s.edge = T ? -1 : 1),
            s.edgeStrategy)
          ) {
            case 'reset':
              ae(c);
              continue;
            case 'ease':
              f = S.frame;
              break;
            default:
            case 'set':
              var x = S.props;
              for (o in x)
                l.call(x, o) &&
                  ((a = oe(x[o].value)),
                  0 === o.indexOf('@')
                    ? c.setAttribute(o.substr(1), a)
                    : i.setStyle(c, o, a));
              continue;
          }
        } else 0 !== s.edge && (De(c, [d, v], [g, h]), (s.edge = 0));
        for (var E = 0; m - 1 > E; E++)
          if (f >= u[E].frame && f <= u[E + 1].frame) {
            var A = u[E],
              F = u[E + 1];
            for (o in A.props)
              if (l.call(A.props, o)) {
                var C = (f - A.frame) / (F.frame - A.frame);
                (C = A.props[o].easing(C)),
                  (a = ne(A.props[o].value, F.props[o].value, C)),
                  (a = oe(a)),
                  0 === o.indexOf('@')
                    ? c.setAttribute(o.substr(1), a)
                    : i.setStyle(c, o, a);
              }
            k &&
              w !== E &&
              ('down' === qe ? Ee(c, A.eventType, qe) : Ee(c, F.eventType, qe),
              (s.lastFrameIndex = E));
            break;
          }
      }
    },
    J = function () {
      _e && ((_e = !1), Ae());
      var e,
        t,
        n = le.getScrollTop(),
        o = Ne();
      if (de)
        o >= de.endTime
          ? ((n = de.targetTop), (e = de.done), (de = r))
          : ((t = de.easing((o - de.startTime) / de.duration)),
            (n = (de.startTop + t * de.topDiff) | 0)),
          le.setScrollTop(n, !0);
      else if (!ye) {
        var a = he.targetTop - n;
        a &&
          (he = {
            startTop: Le,
            topDiff: n - Le,
            targetTop: n,
            startTime: Me,
            endTime: Me + ve,
          }),
          o <= he.endTime &&
            ((t = X.sqrt((o - he.startTime) / ve)),
            (n = (he.startTop + t * he.topDiff) | 0));
      }
      if (ye || Le !== n) {
        (qe = n > Le ? 'down' : Le > n ? 'up' : qe), (ye = !1);
        var l = { curTop: n, lastTop: Le, maxTop: Ve, direction: qe },
          s = fe.beforerender && fe.beforerender.call(le, l);
        s !== !1 &&
          (Z(n, le.getScrollTop()),
          Ke &&
            ce &&
            i.setStyle(ce, 'transform', 'translate(0, ' + -Ye + 'px) ' + be),
          (Le = n),
          fe.render && fe.render.call(le, l)),
          e && e.call(le, !1);
      }
      Me = o;
    },
    Q = function (e) {
      for (var t = 0, r = e.keyFrames.length; r > t; t++) {
        for (
          var n, o, a, i, l = e.keyFrames[t], s = {};
          null !== (i = V.exec(l.props));

        )
          (a = i[1]),
            (o = i[2]),
            (n = a.match(z)),
            null !== n ? ((a = n[1]), (n = n[2])) : (n = k),
            (o = o.indexOf('!') ? ee(o) : [o.slice(1)]),
            (s[a] = { value: o, easing: X[n] });
        l.props = s;
      }
    },
    ee = function (e) {
      var t = [];
      return (
        (B.lastIndex = 0),
        (e = e.replace(B, function (e) {
          return e.replace(M, function (e) {
            return (e / 255) * 100 + '%';
          });
        })),
        K &&
          ((_.lastIndex = 0),
          (e = e.replace(_, function (e) {
            return K + e;
          }))),
        (e = e.replace(M, function (e) {
          return t.push(+e), '{?}';
        })),
        t.unshift(e),
        t
      );
    },
    te = function (e) {
      var t,
        r,
        n = {};
      for (t = 0, r = e.keyFrames.length; r > t; t++) re(e.keyFrames[t], n);
      for (n = {}, t = e.keyFrames.length - 1; t >= 0; t--)
        re(e.keyFrames[t], n);
    },
    re = function (e, t) {
      var r;
      for (r in t) l.call(e.props, r) || (e.props[r] = t[r]);
      for (r in e.props) t[r] = e.props[r];
    },
    ne = function (e, t, r) {
      var n,
        o = e.length;
      if (o !== t.length)
        throw 'Can\'t interpolate between "' + e[0] + '" and "' + t[0] + '"';
      var a = [e[0]];
      for (n = 1; o > n; n++) a[n] = e[n] + (t[n] - e[n]) * r;
      return a;
    },
    oe = function (e) {
      var t = 1;
      return (
        ($.lastIndex = 0),
        e[0].replace($, function () {
          return e[t++];
        })
      );
    },
    ae = function (e, t) {
      e = [].concat(e);
      for (var r, n, o = 0, a = e.length; a > o; o++)
        (n = e[o]),
          (r = se[n[I]]),
          r &&
            (t
              ? ((n.style.cssText = r.dirtyStyleAttr), De(n, r.dirtyClassAttr))
              : ((r.dirtyStyleAttr = n.style.cssText),
                (r.dirtyClassAttr = He(n)),
                (n.style.cssText = r.styleAttr),
                De(n, r.classAttr)));
    },
    ie = function () {
      (be = 'translateZ(0)'), i.setStyle(ce, 'transform', be);
      var e = c(ce),
        t = e.getPropertyValue('transform'),
        r = e.getPropertyValue(K + 'transform'),
        n = (t && 'none' !== t) || (r && 'none' !== r);
      n || (be = '');
    };
  i.setStyle = function (e, t, r) {
    var n = e.style;
    if (((t = t.replace(q, L).replace('-', '')), 'zIndex' === t))
      isNaN(r) ? (n[t] = r) : (n[t] = '' + (0 | r));
    else if ('float' === t) n.styleFloat = n.cssFloat = r;
    else
      try {
        G && (n[G + t.slice(0, 1).toUpperCase() + t.slice(1)] = r), (n[t] = r);
      } catch (o) {}
  };
  var le,
    se,
    ce,
    fe,
    ue,
    me,
    pe,
    de,
    ge,
    ve,
    he,
    ye,
    Te,
    be,
    Se,
    ke = (i.addEvent = function (t, r, n) {
      var o = function (t) {
        return (
          (t = t || e.event),
          t.target || (t.target = t.srcElement),
          t.preventDefault ||
            (t.preventDefault = function () {
              (t.returnValue = !1), (t.defaultPrevented = !0);
            }),
          n.call(this, t)
        );
      };
      r = r.split(' ');
      for (var a, i = 0, l = r.length; l > i; i++)
        (a = r[i]),
          t.addEventListener
            ? t.addEventListener(a, n, !1)
            : t.attachEvent('on' + a, o),
          Re.push({ element: t, name: a, listener: n });
    }),
    we = (i.removeEvent = function (e, t, r) {
      t = t.split(' ');
      for (var n = 0, o = t.length; o > n; n++)
        e.removeEventListener
          ? e.removeEventListener(t[n], r, !1)
          : e.detachEvent('on' + t[n], r);
    }),
    xe = function () {
      for (var e, t = 0, r = Re.length; r > t; t++)
        (e = Re[t]), we(e.element, e.name, e.listener);
      Re = [];
    },
    Ee = function (e, t, r) {
      fe.keyframe && fe.keyframe.call(le, e, t, r);
    },
    Ae = function () {
      var e = le.getScrollTop();
      (Ve = 0),
        ue && !Ke && (a.style.height = ''),
        W(),
        ue && !Ke && (a.style.height = Ve + o.clientHeight + 'px'),
        Ke
          ? le.setScrollTop(s.min(le.getScrollTop(), Ve))
          : le.setScrollTop(e, !0),
        (ye = !0);
    },
    Fe = function () {
      var e,
        t,
        r = o.clientHeight,
        n = {};
      for (e in me)
        (t = me[e]),
          'function' == typeof t
            ? (t = t.call(le))
            : /p$/.test(t) && (t = (t.slice(0, -1) / 100) * r),
          (n[e] = t);
      return n;
    },
    Ce = function () {
      var e,
        t = 0;
      return (
        ce && (t = s.max(ce.offsetHeight, ce.scrollHeight)),
        (e = s.max(
          t,
          a.scrollHeight,
          a.offsetHeight,
          o.scrollHeight,
          o.offsetHeight,
          o.clientHeight
        )),
        e - o.clientHeight
      );
    },
    He = function (t) {
      var r = 'className';
      return (
        e.SVGElement &&
          t instanceof e.SVGElement &&
          ((t = t[r]), (r = 'baseVal')),
        t[r]
      );
    },
    De = function (t, n, o) {
      var a = 'className';
      if (
        (e.SVGElement &&
          t instanceof e.SVGElement &&
          ((t = t[a]), (a = 'baseVal')),
        o === r)
      )
        return void (t[a] = n);
      for (var i = t[a], l = 0, s = o.length; s > l; l++)
        i = Pe(i).replace(Pe(o[l]), ' ');
      i = Ie(i);
      for (var c = 0, f = n.length; f > c; c++)
        -1 === Pe(i).indexOf(Pe(n[c])) && (i += ' ' + n[c]);
      t[a] = Ie(i);
    },
    Ie = function (e) {
      return e.replace(N, '');
    },
    Pe = function (e) {
      return ' ' + e + ' ';
    },
    Ne =
      Date.now ||
      function () {
        return +new Date();
      },
    Oe = function (e, t) {
      return e.frame - t.frame;
    },
    Ve = 0,
    ze = 1,
    qe = 'down',
    Le = -1,
    Me = Ne(),
    $e = 0,
    Be = 0,
    _e = !1,
    Ge = 0,
    Ke = !1,
    Ye = 0,
    Re = [];
  'function' == typeof define && define.amd
    ? define([], function () {
        return i;
      })
    : 'undefined' != typeof module && module.exports
    ? (module.exports = i)
    : (e.skrollr = i);
})(window, document);
!(function (t, e) {
  'function' == typeof define && define.amd
    ? define('jquery-bridget/jquery-bridget', ['jquery'], function (i) {
        return e(t, i);
      })
    : 'object' == typeof module && module.exports
    ? (module.exports = e(t, require('jquery')))
    : (t.jQueryBridget = e(t, t.jQuery));
})(window, function (t, e) {
  'use strict';
  function i(i, s, a) {
    function u(t, e, o) {
      var n,
        s = '$().' + i + '("' + e + '")';
      return (
        t.each(function (t, u) {
          var h = a.data(u, i);
          if (!h)
            return void r(
              i + ' not initialized. Cannot call methods, i.e. ' + s
            );
          var d = h[e];
          if (!d || '_' == e.charAt(0))
            return void r(s + ' is not a valid method');
          var l = d.apply(h, o);
          n = void 0 === n ? l : n;
        }),
        void 0 !== n ? n : t
      );
    }
    function h(t, e) {
      t.each(function (t, o) {
        var n = a.data(o, i);
        n ? (n.option(e), n._init()) : ((n = new s(o, e)), a.data(o, i, n));
      });
    }
    (a = a || e || t.jQuery),
      a &&
        (s.prototype.option ||
          (s.prototype.option = function (t) {
            a.isPlainObject(t) &&
              (this.options = a.extend(!0, this.options, t));
          }),
        (a.fn[i] = function (t) {
          if ('string' == typeof t) {
            var e = n.call(arguments, 1);
            return u(this, t, e);
          }
          return h(this, t), this;
        }),
        o(a));
  }
  function o(t) {
    !t || (t && t.bridget) || (t.bridget = i);
  }
  var n = Array.prototype.slice,
    s = t.console,
    r =
      'undefined' == typeof s
        ? function () {}
        : function (t) {
            s.error(t);
          };
  return o(e || t.jQuery), i;
}),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('ev-emitter/ev-emitter', e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e())
      : (t.EvEmitter = e());
  })('undefined' != typeof window ? window : this, function () {
    function t() {}
    var e = t.prototype;
    return (
      (e.on = function (t, e) {
        if (t && e) {
          var i = (this._events = this._events || {}),
            o = (i[t] = i[t] || []);
          return o.indexOf(e) == -1 && o.push(e), this;
        }
      }),
      (e.once = function (t, e) {
        if (t && e) {
          this.on(t, e);
          var i = (this._onceEvents = this._onceEvents || {}),
            o = (i[t] = i[t] || {});
          return (o[e] = !0), this;
        }
      }),
      (e.off = function (t, e) {
        var i = this._events && this._events[t];
        if (i && i.length) {
          var o = i.indexOf(e);
          return o != -1 && i.splice(o, 1), this;
        }
      }),
      (e.emitEvent = function (t, e) {
        var i = this._events && this._events[t];
        if (i && i.length) {
          (i = i.slice(0)), (e = e || []);
          for (
            var o = this._onceEvents && this._onceEvents[t], n = 0;
            n < i.length;
            n++
          ) {
            var s = i[n],
              r = o && o[s];
            r && (this.off(t, s), delete o[s]), s.apply(this, e);
          }
          return this;
        }
      }),
      (e.allOff = function () {
        delete this._events, delete this._onceEvents;
      }),
      t
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('get-size/get-size', e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e())
      : (t.getSize = e());
  })(window, function () {
    'use strict';
    function t(t) {
      var e = parseFloat(t),
        i = t.indexOf('%') == -1 && !isNaN(e);
      return i && e;
    }
    function e() {}
    function i() {
      for (
        var t = {
            width: 0,
            height: 0,
            innerWidth: 0,
            innerHeight: 0,
            outerWidth: 0,
            outerHeight: 0,
          },
          e = 0;
        e < h;
        e++
      ) {
        var i = u[e];
        t[i] = 0;
      }
      return t;
    }
    function o(t) {
      var e = getComputedStyle(t);
      return (
        e ||
          a(
            'Style returned ' +
              e +
              '. Are you running this code in a hidden iframe on Firefox? See https://bit.ly/getsizebug1'
          ),
        e
      );
    }
    function n() {
      if (!d) {
        d = !0;
        var e = document.createElement('div');
        (e.style.width = '200px'),
          (e.style.padding = '1px 2px 3px 4px'),
          (e.style.borderStyle = 'solid'),
          (e.style.borderWidth = '1px 2px 3px 4px'),
          (e.style.boxSizing = 'border-box');
        var i = document.body || document.documentElement;
        i.appendChild(e);
        var n = o(e);
        (r = 200 == Math.round(t(n.width))),
          (s.isBoxSizeOuter = r),
          i.removeChild(e);
      }
    }
    function s(e) {
      if (
        (n(),
        'string' == typeof e && (e = document.querySelector(e)),
        e && 'object' == typeof e && e.nodeType)
      ) {
        var s = o(e);
        if ('none' == s.display) return i();
        var a = {};
        (a.width = e.offsetWidth), (a.height = e.offsetHeight);
        for (
          var d = (a.isBorderBox = 'border-box' == s.boxSizing), l = 0;
          l < h;
          l++
        ) {
          var f = u[l],
            c = s[f],
            m = parseFloat(c);
          a[f] = isNaN(m) ? 0 : m;
        }
        var p = a.paddingLeft + a.paddingRight,
          y = a.paddingTop + a.paddingBottom,
          g = a.marginLeft + a.marginRight,
          v = a.marginTop + a.marginBottom,
          _ = a.borderLeftWidth + a.borderRightWidth,
          z = a.borderTopWidth + a.borderBottomWidth,
          I = d && r,
          x = t(s.width);
        x !== !1 && (a.width = x + (I ? 0 : p + _));
        var S = t(s.height);
        return (
          S !== !1 && (a.height = S + (I ? 0 : y + z)),
          (a.innerWidth = a.width - (p + _)),
          (a.innerHeight = a.height - (y + z)),
          (a.outerWidth = a.width + g),
          (a.outerHeight = a.height + v),
          a
        );
      }
    }
    var r,
      a =
        'undefined' == typeof console
          ? e
          : function (t) {
              console.error(t);
            },
      u = [
        'paddingLeft',
        'paddingRight',
        'paddingTop',
        'paddingBottom',
        'marginLeft',
        'marginRight',
        'marginTop',
        'marginBottom',
        'borderLeftWidth',
        'borderRightWidth',
        'borderTopWidth',
        'borderBottomWidth',
      ],
      h = u.length,
      d = !1;
    return s;
  }),
  (function (t, e) {
    'use strict';
    'function' == typeof define && define.amd
      ? define('desandro-matches-selector/matches-selector', e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e())
      : (t.matchesSelector = e());
  })(window, function () {
    'use strict';
    var t = (function () {
      var t = window.Element.prototype;
      if (t.matches) return 'matches';
      if (t.matchesSelector) return 'matchesSelector';
      for (var e = ['webkit', 'moz', 'ms', 'o'], i = 0; i < e.length; i++) {
        var o = e[i],
          n = o + 'MatchesSelector';
        if (t[n]) return n;
      }
    })();
    return function (e, i) {
      return e[t](i);
    };
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'fizzy-ui-utils/utils',
          ['desandro-matches-selector/matches-selector'],
          function (i) {
            return e(t, i);
          }
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(t, require('desandro-matches-selector')))
      : (t.fizzyUIUtils = e(t, t.matchesSelector));
  })(window, function (t, e) {
    var i = {};
    (i.extend = function (t, e) {
      for (var i in e) t[i] = e[i];
      return t;
    }),
      (i.modulo = function (t, e) {
        return ((t % e) + e) % e;
      });
    var o = Array.prototype.slice;
    (i.makeArray = function (t) {
      if (Array.isArray(t)) return t;
      if (null === t || void 0 === t) return [];
      var e = 'object' == typeof t && 'number' == typeof t.length;
      return e ? o.call(t) : [t];
    }),
      (i.removeFrom = function (t, e) {
        var i = t.indexOf(e);
        i != -1 && t.splice(i, 1);
      }),
      (i.getParent = function (t, i) {
        for (; t.parentNode && t != document.body; )
          if (((t = t.parentNode), e(t, i))) return t;
      }),
      (i.getQueryElement = function (t) {
        return 'string' == typeof t ? document.querySelector(t) : t;
      }),
      (i.handleEvent = function (t) {
        var e = 'on' + t.type;
        this[e] && this[e](t);
      }),
      (i.filterFindElements = function (t, o) {
        t = i.makeArray(t);
        var n = [];
        return (
          t.forEach(function (t) {
            if (t instanceof HTMLElement) {
              if (!o) return void n.push(t);
              e(t, o) && n.push(t);
              for (var i = t.querySelectorAll(o), s = 0; s < i.length; s++)
                n.push(i[s]);
            }
          }),
          n
        );
      }),
      (i.debounceMethod = function (t, e, i) {
        i = i || 100;
        var o = t.prototype[e],
          n = e + 'Timeout';
        t.prototype[e] = function () {
          var t = this[n];
          clearTimeout(t);
          var e = arguments,
            s = this;
          this[n] = setTimeout(function () {
            o.apply(s, e), delete s[n];
          }, i);
        };
      }),
      (i.docReady = function (t) {
        var e = document.readyState;
        'complete' == e || 'interactive' == e
          ? setTimeout(t)
          : document.addEventListener('DOMContentLoaded', t);
      }),
      (i.toDashed = function (t) {
        return t
          .replace(/(.)([A-Z])/g, function (t, e, i) {
            return e + '-' + i;
          })
          .toLowerCase();
      });
    var n = t.console;
    return (
      (i.htmlInit = function (e, o) {
        i.docReady(function () {
          var s = i.toDashed(o),
            r = 'data-' + s,
            a = document.querySelectorAll('[' + r + ']'),
            u = document.querySelectorAll('.js-' + s),
            h = i.makeArray(a).concat(i.makeArray(u)),
            d = r + '-options',
            l = t.jQuery;
          h.forEach(function (t) {
            var i,
              s = t.getAttribute(r) || t.getAttribute(d);
            try {
              i = s && JSON.parse(s);
            } catch (a) {
              return void (
                n &&
                n.error('Error parsing ' + r + ' on ' + t.className + ': ' + a)
              );
            }
            var u = new e(t, i);
            l && l.data(t, o, u);
          });
        });
      }),
      i
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'outlayer/item',
          ['ev-emitter/ev-emitter', 'get-size/get-size'],
          e
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('ev-emitter'), require('get-size')))
      : ((t.Outlayer = {}), (t.Outlayer.Item = e(t.EvEmitter, t.getSize)));
  })(window, function (t, e) {
    'use strict';
    function i(t) {
      for (var e in t) return !1;
      return (e = null), !0;
    }
    function o(t, e) {
      t &&
        ((this.element = t),
        (this.layout = e),
        (this.position = { x: 0, y: 0 }),
        this._create());
    }
    function n(t) {
      return t.replace(/([A-Z])/g, function (t) {
        return '-' + t.toLowerCase();
      });
    }
    var s = document.documentElement.style,
      r = 'string' == typeof s.transition ? 'transition' : 'WebkitTransition',
      a = 'string' == typeof s.transform ? 'transform' : 'WebkitTransform',
      u = {
        WebkitTransition: 'webkitTransitionEnd',
        transition: 'transitionend',
      }[r],
      h = {
        transform: a,
        transition: r,
        transitionDuration: r + 'Duration',
        transitionProperty: r + 'Property',
        transitionDelay: r + 'Delay',
      },
      d = (o.prototype = Object.create(t.prototype));
    (d.constructor = o),
      (d._create = function () {
        (this._transn = { ingProperties: {}, clean: {}, onEnd: {} }),
          this.css({ position: 'absolute' });
      }),
      (d.handleEvent = function (t) {
        var e = 'on' + t.type;
        this[e] && this[e](t);
      }),
      (d.getSize = function () {
        this.size = e(this.element);
      }),
      (d.css = function (t) {
        var e = this.element.style;
        for (var i in t) {
          var o = h[i] || i;
          e[o] = t[i];
        }
      }),
      (d.getPosition = function () {
        var t = getComputedStyle(this.element),
          e = this.layout._getOption('originLeft'),
          i = this.layout._getOption('originTop'),
          o = t[e ? 'left' : 'right'],
          n = t[i ? 'top' : 'bottom'],
          s = parseFloat(o),
          r = parseFloat(n),
          a = this.layout.size;
        o.indexOf('%') != -1 && (s = (s / 100) * a.width),
          n.indexOf('%') != -1 && (r = (r / 100) * a.height),
          (s = isNaN(s) ? 0 : s),
          (r = isNaN(r) ? 0 : r),
          (s -= e ? a.paddingLeft : a.paddingRight),
          (r -= i ? a.paddingTop : a.paddingBottom),
          (this.position.x = s),
          (this.position.y = r);
      }),
      (d.layoutPosition = function () {
        var t = this.layout.size,
          e = {},
          i = this.layout._getOption('originLeft'),
          o = this.layout._getOption('originTop'),
          n = i ? 'paddingLeft' : 'paddingRight',
          s = i ? 'left' : 'right',
          r = i ? 'right' : 'left',
          a = this.position.x + t[n];
        (e[s] = this.getXValue(a)), (e[r] = '');
        var u = o ? 'paddingTop' : 'paddingBottom',
          h = o ? 'top' : 'bottom',
          d = o ? 'bottom' : 'top',
          l = this.position.y + t[u];
        (e[h] = this.getYValue(l)),
          (e[d] = ''),
          this.css(e),
          this.emitEvent('layout', [this]);
      }),
      (d.getXValue = function (t) {
        var e = this.layout._getOption('horizontal');
        return this.layout.options.percentPosition && !e
          ? (t / this.layout.size.width) * 100 + '%'
          : t + 'px';
      }),
      (d.getYValue = function (t) {
        var e = this.layout._getOption('horizontal');
        return this.layout.options.percentPosition && e
          ? (t / this.layout.size.height) * 100 + '%'
          : t + 'px';
      }),
      (d._transitionTo = function (t, e) {
        this.getPosition();
        var i = this.position.x,
          o = this.position.y,
          n = t == this.position.x && e == this.position.y;
        if ((this.setPosition(t, e), n && !this.isTransitioning))
          return void this.layoutPosition();
        var s = t - i,
          r = e - o,
          a = {};
        (a.transform = this.getTranslate(s, r)),
          this.transition({
            to: a,
            onTransitionEnd: { transform: this.layoutPosition },
            isCleaning: !0,
          });
      }),
      (d.getTranslate = function (t, e) {
        var i = this.layout._getOption('originLeft'),
          o = this.layout._getOption('originTop');
        return (
          (t = i ? t : -t),
          (e = o ? e : -e),
          'translate3d(' + t + 'px, ' + e + 'px, 0)'
        );
      }),
      (d.goTo = function (t, e) {
        this.setPosition(t, e), this.layoutPosition();
      }),
      (d.moveTo = d._transitionTo),
      (d.setPosition = function (t, e) {
        (this.position.x = parseFloat(t)), (this.position.y = parseFloat(e));
      }),
      (d._nonTransition = function (t) {
        this.css(t.to), t.isCleaning && this._removeStyles(t.to);
        for (var e in t.onTransitionEnd) t.onTransitionEnd[e].call(this);
      }),
      (d.transition = function (t) {
        if (!parseFloat(this.layout.options.transitionDuration))
          return void this._nonTransition(t);
        var e = this._transn;
        for (var i in t.onTransitionEnd) e.onEnd[i] = t.onTransitionEnd[i];
        for (i in t.to)
          (e.ingProperties[i] = !0), t.isCleaning && (e.clean[i] = !0);
        if (t.from) {
          this.css(t.from);
          var o = this.element.offsetHeight;
          o = null;
        }
        this.enableTransition(t.to),
          this.css(t.to),
          (this.isTransitioning = !0);
      });
    var l = 'opacity,' + n(a);
    (d.enableTransition = function () {
      if (!this.isTransitioning) {
        var t = this.layout.options.transitionDuration;
        (t = 'number' == typeof t ? t + 'ms' : t),
          this.css({
            transitionProperty: l,
            transitionDuration: t,
            transitionDelay: this.staggerDelay || 0,
          }),
          this.element.addEventListener(u, this, !1);
      }
    }),
      (d.onwebkitTransitionEnd = function (t) {
        this.ontransitionend(t);
      }),
      (d.onotransitionend = function (t) {
        this.ontransitionend(t);
      });
    var f = { '-webkit-transform': 'transform' };
    (d.ontransitionend = function (t) {
      if (t.target === this.element) {
        var e = this._transn,
          o = f[t.propertyName] || t.propertyName;
        if (
          (delete e.ingProperties[o],
          i(e.ingProperties) && this.disableTransition(),
          o in e.clean &&
            ((this.element.style[t.propertyName] = ''), delete e.clean[o]),
          o in e.onEnd)
        ) {
          var n = e.onEnd[o];
          n.call(this), delete e.onEnd[o];
        }
        this.emitEvent('transitionEnd', [this]);
      }
    }),
      (d.disableTransition = function () {
        this.removeTransitionStyles(),
          this.element.removeEventListener(u, this, !1),
          (this.isTransitioning = !1);
      }),
      (d._removeStyles = function (t) {
        var e = {};
        for (var i in t) e[i] = '';
        this.css(e);
      });
    var c = {
      transitionProperty: '',
      transitionDuration: '',
      transitionDelay: '',
    };
    return (
      (d.removeTransitionStyles = function () {
        this.css(c);
      }),
      (d.stagger = function (t) {
        (t = isNaN(t) ? 0 : t), (this.staggerDelay = t + 'ms');
      }),
      (d.removeElem = function () {
        this.element.parentNode.removeChild(this.element),
          this.css({ display: '' }),
          this.emitEvent('remove', [this]);
      }),
      (d.remove = function () {
        return r && parseFloat(this.layout.options.transitionDuration)
          ? (this.once('transitionEnd', function () {
              this.removeElem();
            }),
            void this.hide())
          : void this.removeElem();
      }),
      (d.reveal = function () {
        delete this.isHidden, this.css({ display: '' });
        var t = this.layout.options,
          e = {},
          i = this.getHideRevealTransitionEndProperty('visibleStyle');
        (e[i] = this.onRevealTransitionEnd),
          this.transition({
            from: t.hiddenStyle,
            to: t.visibleStyle,
            isCleaning: !0,
            onTransitionEnd: e,
          });
      }),
      (d.onRevealTransitionEnd = function () {
        this.isHidden || this.emitEvent('reveal');
      }),
      (d.getHideRevealTransitionEndProperty = function (t) {
        var e = this.layout.options[t];
        if (e.opacity) return 'opacity';
        for (var i in e) return i;
      }),
      (d.hide = function () {
        (this.isHidden = !0), this.css({ display: '' });
        var t = this.layout.options,
          e = {},
          i = this.getHideRevealTransitionEndProperty('hiddenStyle');
        (e[i] = this.onHideTransitionEnd),
          this.transition({
            from: t.visibleStyle,
            to: t.hiddenStyle,
            isCleaning: !0,
            onTransitionEnd: e,
          });
      }),
      (d.onHideTransitionEnd = function () {
        this.isHidden &&
          (this.css({ display: 'none' }), this.emitEvent('hide'));
      }),
      (d.destroy = function () {
        this.css({
          position: '',
          left: '',
          right: '',
          top: '',
          bottom: '',
          transition: '',
          transform: '',
        });
      }),
      o
    );
  }),
  (function (t, e) {
    'use strict';
    'function' == typeof define && define.amd
      ? define(
          'outlayer/outlayer',
          [
            'ev-emitter/ev-emitter',
            'get-size/get-size',
            'fizzy-ui-utils/utils',
            './item',
          ],
          function (i, o, n, s) {
            return e(t, i, o, n, s);
          }
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(
          t,
          require('ev-emitter'),
          require('get-size'),
          require('fizzy-ui-utils'),
          require('./item')
        ))
      : (t.Outlayer = e(
          t,
          t.EvEmitter,
          t.getSize,
          t.fizzyUIUtils,
          t.Outlayer.Item
        ));
  })(window, function (t, e, i, o, n) {
    'use strict';
    function s(t, e) {
      var i = o.getQueryElement(t);
      if (!i)
        return void (
          u &&
          u.error(
            'Bad element for ' + this.constructor.namespace + ': ' + (i || t)
          )
        );
      (this.element = i),
        h && (this.$element = h(this.element)),
        (this.options = o.extend({}, this.constructor.defaults)),
        this.option(e);
      var n = ++l;
      (this.element.outlayerGUID = n), (f[n] = this), this._create();
      var s = this._getOption('initLayout');
      s && this.layout();
    }
    function r(t) {
      function e() {
        t.apply(this, arguments);
      }
      return (
        (e.prototype = Object.create(t.prototype)),
        (e.prototype.constructor = e),
        e
      );
    }
    function a(t) {
      if ('number' == typeof t) return t;
      var e = t.match(/(^\d*\.?\d*)(\w*)/),
        i = e && e[1],
        o = e && e[2];
      if (!i.length) return 0;
      i = parseFloat(i);
      var n = m[o] || 1;
      return i * n;
    }
    var u = t.console,
      h = t.jQuery,
      d = function () {},
      l = 0,
      f = {};
    (s.namespace = 'outlayer'),
      (s.Item = n),
      (s.defaults = {
        containerStyle: { position: 'relative' },
        initLayout: !0,
        originLeft: !0,
        originTop: !0,
        resize: !0,
        resizeContainer: !0,
        transitionDuration: '0.4s',
        hiddenStyle: { opacity: 0, transform: 'scale(0.001)' },
        visibleStyle: { opacity: 1, transform: 'scale(1)' },
      });
    var c = s.prototype;
    o.extend(c, e.prototype),
      (c.option = function (t) {
        o.extend(this.options, t);
      }),
      (c._getOption = function (t) {
        var e = this.constructor.compatOptions[t];
        return e && void 0 !== this.options[e]
          ? this.options[e]
          : this.options[t];
      }),
      (s.compatOptions = {
        initLayout: 'isInitLayout',
        horizontal: 'isHorizontal',
        layoutInstant: 'isLayoutInstant',
        originLeft: 'isOriginLeft',
        originTop: 'isOriginTop',
        resize: 'isResizeBound',
        resizeContainer: 'isResizingContainer',
      }),
      (c._create = function () {
        this.reloadItems(),
          (this.stamps = []),
          this.stamp(this.options.stamp),
          o.extend(this.element.style, this.options.containerStyle);
        var t = this._getOption('resize');
        t && this.bindResize();
      }),
      (c.reloadItems = function () {
        this.items = this._itemize(this.element.children);
      }),
      (c._itemize = function (t) {
        for (
          var e = this._filterFindItemElements(t),
            i = this.constructor.Item,
            o = [],
            n = 0;
          n < e.length;
          n++
        ) {
          var s = e[n],
            r = new i(s, this);
          o.push(r);
        }
        return o;
      }),
      (c._filterFindItemElements = function (t) {
        return o.filterFindElements(t, this.options.itemSelector);
      }),
      (c.getItemElements = function () {
        return this.items.map(function (t) {
          return t.element;
        });
      }),
      (c.layout = function () {
        this._resetLayout(), this._manageStamps();
        var t = this._getOption('layoutInstant'),
          e = void 0 !== t ? t : !this._isLayoutInited;
        this.layoutItems(this.items, e), (this._isLayoutInited = !0);
      }),
      (c._init = c.layout),
      (c._resetLayout = function () {
        this.getSize();
      }),
      (c.getSize = function () {
        this.size = i(this.element);
      }),
      (c._getMeasurement = function (t, e) {
        var o,
          n = this.options[t];
        n
          ? ('string' == typeof n
              ? (o = this.element.querySelector(n))
              : n instanceof HTMLElement && (o = n),
            (this[t] = o ? i(o)[e] : n))
          : (this[t] = 0);
      }),
      (c.layoutItems = function (t, e) {
        (t = this._getItemsForLayout(t)),
          this._layoutItems(t, e),
          this._postLayout();
      }),
      (c._getItemsForLayout = function (t) {
        return t.filter(function (t) {
          return !t.isIgnored;
        });
      }),
      (c._layoutItems = function (t, e) {
        if ((this._emitCompleteOnItems('layout', t), t && t.length)) {
          var i = [];
          t.forEach(function (t) {
            var o = this._getItemLayoutPosition(t);
            (o.item = t), (o.isInstant = e || t.isLayoutInstant), i.push(o);
          }, this),
            this._processLayoutQueue(i);
        }
      }),
      (c._getItemLayoutPosition = function () {
        return { x: 0, y: 0 };
      }),
      (c._processLayoutQueue = function (t) {
        this.updateStagger(),
          t.forEach(function (t, e) {
            this._positionItem(t.item, t.x, t.y, t.isInstant, e);
          }, this);
      }),
      (c.updateStagger = function () {
        var t = this.options.stagger;
        return null === t || void 0 === t
          ? void (this.stagger = 0)
          : ((this.stagger = a(t)), this.stagger);
      }),
      (c._positionItem = function (t, e, i, o, n) {
        o ? t.goTo(e, i) : (t.stagger(n * this.stagger), t.moveTo(e, i));
      }),
      (c._postLayout = function () {
        this.resizeContainer();
      }),
      (c.resizeContainer = function () {
        var t = this._getOption('resizeContainer');
        if (t) {
          var e = this._getContainerSize();
          e &&
            (this._setContainerMeasure(e.width, !0),
            this._setContainerMeasure(e.height, !1));
        }
      }),
      (c._getContainerSize = d),
      (c._setContainerMeasure = function (t, e) {
        if (void 0 !== t) {
          var i = this.size;
          i.isBorderBox &&
            (t += e
              ? i.paddingLeft +
                i.paddingRight +
                i.borderLeftWidth +
                i.borderRightWidth
              : i.paddingBottom +
                i.paddingTop +
                i.borderTopWidth +
                i.borderBottomWidth),
            (t = Math.max(t, 0)),
            (this.element.style[e ? 'width' : 'height'] = t + 'px');
        }
      }),
      (c._emitCompleteOnItems = function (t, e) {
        function i() {
          n.dispatchEvent(t + 'Complete', null, [e]);
        }
        function o() {
          r++, r == s && i();
        }
        var n = this,
          s = e.length;
        if (!e || !s) return void i();
        var r = 0;
        e.forEach(function (e) {
          e.once(t, o);
        });
      }),
      (c.dispatchEvent = function (t, e, i) {
        var o = e ? [e].concat(i) : i;
        if ((this.emitEvent(t, o), h))
          if (((this.$element = this.$element || h(this.element)), e)) {
            var n = h.Event(e);
            (n.type = t), this.$element.trigger(n, i);
          } else this.$element.trigger(t, i);
      }),
      (c.ignore = function (t) {
        var e = this.getItem(t);
        e && (e.isIgnored = !0);
      }),
      (c.unignore = function (t) {
        var e = this.getItem(t);
        e && delete e.isIgnored;
      }),
      (c.stamp = function (t) {
        (t = this._find(t)),
          t &&
            ((this.stamps = this.stamps.concat(t)),
            t.forEach(this.ignore, this));
      }),
      (c.unstamp = function (t) {
        (t = this._find(t)),
          t &&
            t.forEach(function (t) {
              o.removeFrom(this.stamps, t), this.unignore(t);
            }, this);
      }),
      (c._find = function (t) {
        if (t)
          return (
            'string' == typeof t && (t = this.element.querySelectorAll(t)),
            (t = o.makeArray(t))
          );
      }),
      (c._manageStamps = function () {
        this.stamps &&
          this.stamps.length &&
          (this._getBoundingRect(),
          this.stamps.forEach(this._manageStamp, this));
      }),
      (c._getBoundingRect = function () {
        var t = this.element.getBoundingClientRect(),
          e = this.size;
        this._boundingRect = {
          left: t.left + e.paddingLeft + e.borderLeftWidth,
          top: t.top + e.paddingTop + e.borderTopWidth,
          right: t.right - (e.paddingRight + e.borderRightWidth),
          bottom: t.bottom - (e.paddingBottom + e.borderBottomWidth),
        };
      }),
      (c._manageStamp = d),
      (c._getElementOffset = function (t) {
        var e = t.getBoundingClientRect(),
          o = this._boundingRect,
          n = i(t),
          s = {
            left: e.left - o.left - n.marginLeft,
            top: e.top - o.top - n.marginTop,
            right: o.right - e.right - n.marginRight,
            bottom: o.bottom - e.bottom - n.marginBottom,
          };
        return s;
      }),
      (c.handleEvent = o.handleEvent),
      (c.bindResize = function () {
        t.addEventListener('resize', this), (this.isResizeBound = !0);
      }),
      (c.unbindResize = function () {
        t.removeEventListener('resize', this), (this.isResizeBound = !1);
      }),
      (c.onresize = function () {
        this.resize();
      }),
      o.debounceMethod(s, 'onresize', 100),
      (c.resize = function () {
        this.isResizeBound && this.needsResizeLayout() && this.layout();
      }),
      (c.needsResizeLayout = function () {
        var t = i(this.element),
          e = this.size && t;
        return e && t.innerWidth !== this.size.innerWidth;
      }),
      (c.addItems = function (t) {
        var e = this._itemize(t);
        return e.length && (this.items = this.items.concat(e)), e;
      }),
      (c.appended = function (t) {
        var e = this.addItems(t);
        e.length && (this.layoutItems(e, !0), this.reveal(e));
      }),
      (c.prepended = function (t) {
        var e = this._itemize(t);
        if (e.length) {
          var i = this.items.slice(0);
          (this.items = e.concat(i)),
            this._resetLayout(),
            this._manageStamps(),
            this.layoutItems(e, !0),
            this.reveal(e),
            this.layoutItems(i);
        }
      }),
      (c.reveal = function (t) {
        if ((this._emitCompleteOnItems('reveal', t), t && t.length)) {
          var e = this.updateStagger();
          t.forEach(function (t, i) {
            t.stagger(i * e), t.reveal();
          });
        }
      }),
      (c.hide = function (t) {
        if ((this._emitCompleteOnItems('hide', t), t && t.length)) {
          var e = this.updateStagger();
          t.forEach(function (t, i) {
            t.stagger(i * e), t.hide();
          });
        }
      }),
      (c.revealItemElements = function (t) {
        var e = this.getItems(t);
        this.reveal(e);
      }),
      (c.hideItemElements = function (t) {
        var e = this.getItems(t);
        this.hide(e);
      }),
      (c.getItem = function (t) {
        for (var e = 0; e < this.items.length; e++) {
          var i = this.items[e];
          if (i.element == t) return i;
        }
      }),
      (c.getItems = function (t) {
        t = o.makeArray(t);
        var e = [];
        return (
          t.forEach(function (t) {
            var i = this.getItem(t);
            i && e.push(i);
          }, this),
          e
        );
      }),
      (c.remove = function (t) {
        var e = this.getItems(t);
        this._emitCompleteOnItems('remove', e),
          e &&
            e.length &&
            e.forEach(function (t) {
              t.remove(), o.removeFrom(this.items, t);
            }, this);
      }),
      (c.destroy = function () {
        var t = this.element.style;
        (t.height = ''),
          (t.position = ''),
          (t.width = ''),
          this.items.forEach(function (t) {
            t.destroy();
          }),
          this.unbindResize();
        var e = this.element.outlayerGUID;
        delete f[e],
          delete this.element.outlayerGUID,
          h && h.removeData(this.element, this.constructor.namespace);
      }),
      (s.data = function (t) {
        t = o.getQueryElement(t);
        var e = t && t.outlayerGUID;
        return e && f[e];
      }),
      (s.create = function (t, e) {
        var i = r(s);
        return (
          (i.defaults = o.extend({}, s.defaults)),
          o.extend(i.defaults, e),
          (i.compatOptions = o.extend({}, s.compatOptions)),
          (i.namespace = t),
          (i.data = s.data),
          (i.Item = r(n)),
          o.htmlInit(i, t),
          h && h.bridget && h.bridget(t, i),
          i
        );
      });
    var m = { ms: 1, s: 1e3 };
    return (s.Item = n), s;
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('isotope-layout/js/item', ['outlayer/outlayer'], e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('outlayer')))
      : ((t.Isotope = t.Isotope || {}), (t.Isotope.Item = e(t.Outlayer)));
  })(window, function (t) {
    'use strict';
    function e() {
      t.Item.apply(this, arguments);
    }
    var i = (e.prototype = Object.create(t.Item.prototype)),
      o = i._create;
    (i._create = function () {
      (this.id = this.layout.itemGUID++), o.call(this), (this.sortData = {});
    }),
      (i.updateSortData = function () {
        if (!this.isIgnored) {
          (this.sortData.id = this.id),
            (this.sortData['original-order'] = this.id),
            (this.sortData.random = Math.random());
          var t = this.layout.options.getSortData,
            e = this.layout._sorters;
          for (var i in t) {
            var o = e[i];
            this.sortData[i] = o(this.element, this);
          }
        }
      });
    var n = i.destroy;
    return (
      (i.destroy = function () {
        n.apply(this, arguments), this.css({ display: '' });
      }),
      e
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'isotope-layout/js/layout-mode',
          ['get-size/get-size', 'outlayer/outlayer'],
          e
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('get-size'), require('outlayer')))
      : ((t.Isotope = t.Isotope || {}),
        (t.Isotope.LayoutMode = e(t.getSize, t.Outlayer)));
  })(window, function (t, e) {
    'use strict';
    function i(t) {
      (this.isotope = t),
        t &&
          ((this.options = t.options[this.namespace]),
          (this.element = t.element),
          (this.items = t.filteredItems),
          (this.size = t.size));
    }
    var o = i.prototype,
      n = [
        '_resetLayout',
        '_getItemLayoutPosition',
        '_manageStamp',
        '_getContainerSize',
        '_getElementOffset',
        'needsResizeLayout',
        '_getOption',
      ];
    return (
      n.forEach(function (t) {
        o[t] = function () {
          return e.prototype[t].apply(this.isotope, arguments);
        };
      }),
      (o.needsVerticalResizeLayout = function () {
        var e = t(this.isotope.element),
          i = this.isotope.size && e;
        return i && e.innerHeight != this.isotope.size.innerHeight;
      }),
      (o._getMeasurement = function () {
        this.isotope._getMeasurement.apply(this, arguments);
      }),
      (o.getColumnWidth = function () {
        this.getSegmentSize('column', 'Width');
      }),
      (o.getRowHeight = function () {
        this.getSegmentSize('row', 'Height');
      }),
      (o.getSegmentSize = function (t, e) {
        var i = t + e,
          o = 'outer' + e;
        if ((this._getMeasurement(i, o), !this[i])) {
          var n = this.getFirstItemSize();
          this[i] = (n && n[o]) || this.isotope.size['inner' + e];
        }
      }),
      (o.getFirstItemSize = function () {
        var e = this.isotope.filteredItems[0];
        return e && e.element && t(e.element);
      }),
      (o.layout = function () {
        this.isotope.layout.apply(this.isotope, arguments);
      }),
      (o.getSize = function () {
        this.isotope.getSize(), (this.size = this.isotope.size);
      }),
      (i.modes = {}),
      (i.create = function (t, e) {
        function n() {
          i.apply(this, arguments);
        }
        return (
          (n.prototype = Object.create(o)),
          (n.prototype.constructor = n),
          e && (n.options = e),
          (n.prototype.namespace = t),
          (i.modes[t] = n),
          n
        );
      }),
      i
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'masonry-layout/masonry',
          ['outlayer/outlayer', 'get-size/get-size'],
          e
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('outlayer'), require('get-size')))
      : (t.Masonry = e(t.Outlayer, t.getSize));
  })(window, function (t, e) {
    var i = t.create('masonry');
    i.compatOptions.fitWidth = 'isFitWidth';
    var o = i.prototype;
    return (
      (o._resetLayout = function () {
        this.getSize(),
          this._getMeasurement('columnWidth', 'outerWidth'),
          this._getMeasurement('gutter', 'outerWidth'),
          this.measureColumns(),
          (this.colYs = []);
        for (var t = 0; t < this.cols; t++) this.colYs.push(0);
        (this.maxY = 0), (this.horizontalColIndex = 0);
      }),
      (o.measureColumns = function () {
        if ((this.getContainerWidth(), !this.columnWidth)) {
          var t = this.items[0],
            i = t && t.element;
          this.columnWidth = (i && e(i).outerWidth) || this.containerWidth;
        }
        var o = (this.columnWidth += this.gutter),
          n = this.containerWidth + this.gutter,
          s = n / o,
          r = o - (n % o),
          a = r && r < 1 ? 'round' : 'floor';
        (s = Math[a](s)), (this.cols = Math.max(s, 1));
      }),
      (o.getContainerWidth = function () {
        var t = this._getOption('fitWidth'),
          i = t ? this.element.parentNode : this.element,
          o = e(i);
        this.containerWidth = o && o.innerWidth;
      }),
      (o._getItemLayoutPosition = function (t) {
        t.getSize();
        var e = t.size.outerWidth % this.columnWidth,
          i = e && e < 1 ? 'round' : 'ceil',
          o = Math[i](t.size.outerWidth / this.columnWidth);
        o = Math.min(o, this.cols);
        for (
          var n = this.options.horizontalOrder
              ? '_getHorizontalColPosition'
              : '_getTopColPosition',
            s = this[n](o, t),
            r = { x: this.columnWidth * s.col, y: s.y },
            a = s.y + t.size.outerHeight,
            u = o + s.col,
            h = s.col;
          h < u;
          h++
        )
          this.colYs[h] = a;
        return r;
      }),
      (o._getTopColPosition = function (t) {
        var e = this._getTopColGroup(t),
          i = Math.min.apply(Math, e);
        return { col: e.indexOf(i), y: i };
      }),
      (o._getTopColGroup = function (t) {
        if (t < 2) return this.colYs;
        for (var e = [], i = this.cols + 1 - t, o = 0; o < i; o++)
          e[o] = this._getColGroupY(o, t);
        return e;
      }),
      (o._getColGroupY = function (t, e) {
        if (e < 2) return this.colYs[t];
        var i = this.colYs.slice(t, t + e);
        return Math.max.apply(Math, i);
      }),
      (o._getHorizontalColPosition = function (t, e) {
        var i = this.horizontalColIndex % this.cols,
          o = t > 1 && i + t > this.cols;
        i = o ? 0 : i;
        var n = e.size.outerWidth && e.size.outerHeight;
        return (
          (this.horizontalColIndex = n ? i + t : this.horizontalColIndex),
          { col: i, y: this._getColGroupY(i, t) }
        );
      }),
      (o._manageStamp = function (t) {
        var i = e(t),
          o = this._getElementOffset(t),
          n = this._getOption('originLeft'),
          s = n ? o.left : o.right,
          r = s + i.outerWidth,
          a = Math.floor(s / this.columnWidth);
        a = Math.max(0, a);
        var u = Math.floor(r / this.columnWidth);
        (u -= r % this.columnWidth ? 0 : 1), (u = Math.min(this.cols - 1, u));
        for (
          var h = this._getOption('originTop'),
            d = (h ? o.top : o.bottom) + i.outerHeight,
            l = a;
          l <= u;
          l++
        )
          this.colYs[l] = Math.max(d, this.colYs[l]);
      }),
      (o._getContainerSize = function () {
        this.maxY = Math.max.apply(Math, this.colYs);
        var t = { height: this.maxY };
        return (
          this._getOption('fitWidth') &&
            (t.width = this._getContainerFitWidth()),
          t
        );
      }),
      (o._getContainerFitWidth = function () {
        for (var t = 0, e = this.cols; --e && 0 === this.colYs[e]; ) t++;
        return (this.cols - t) * this.columnWidth - this.gutter;
      }),
      (o.needsResizeLayout = function () {
        var t = this.containerWidth;
        return this.getContainerWidth(), t != this.containerWidth;
      }),
      i
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'isotope-layout/js/layout-modes/masonry',
          ['../layout-mode', 'masonry-layout/masonry'],
          e
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(
          require('../layout-mode'),
          require('masonry-layout')
        ))
      : e(t.Isotope.LayoutMode, t.Masonry);
  })(window, function (t, e) {
    'use strict';
    var i = t.create('masonry'),
      o = i.prototype,
      n = { _getElementOffset: !0, layout: !0, _getMeasurement: !0 };
    for (var s in e.prototype) n[s] || (o[s] = e.prototype[s]);
    var r = o.measureColumns;
    o.measureColumns = function () {
      (this.items = this.isotope.filteredItems), r.call(this);
    };
    var a = o._getOption;
    return (
      (o._getOption = function (t) {
        return 'fitWidth' == t
          ? void 0 !== this.options.isFitWidth
            ? this.options.isFitWidth
            : this.options.fitWidth
          : a.apply(this.isotope, arguments);
      }),
      i
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('isotope-layout/js/layout-modes/fit-rows', ['../layout-mode'], e)
      : 'object' == typeof exports
      ? (module.exports = e(require('../layout-mode')))
      : e(t.Isotope.LayoutMode);
  })(window, function (t) {
    'use strict';
    var e = t.create('fitRows'),
      i = e.prototype;
    return (
      (i._resetLayout = function () {
        (this.x = 0),
          (this.y = 0),
          (this.maxY = 0),
          this._getMeasurement('gutter', 'outerWidth');
      }),
      (i._getItemLayoutPosition = function (t) {
        t.getSize();
        var e = t.size.outerWidth + this.gutter,
          i = this.isotope.size.innerWidth + this.gutter;
        0 !== this.x && e + this.x > i && ((this.x = 0), (this.y = this.maxY));
        var o = { x: this.x, y: this.y };
        return (
          (this.maxY = Math.max(this.maxY, this.y + t.size.outerHeight)),
          (this.x += e),
          o
        );
      }),
      (i._getContainerSize = function () {
        return { height: this.maxY };
      }),
      e
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('isotope-layout/js/layout-modes/vertical', ['../layout-mode'], e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('../layout-mode')))
      : e(t.Isotope.LayoutMode);
  })(window, function (t) {
    'use strict';
    var e = t.create('vertical', { horizontalAlignment: 0 }),
      i = e.prototype;
    return (
      (i._resetLayout = function () {
        this.y = 0;
      }),
      (i._getItemLayoutPosition = function (t) {
        t.getSize();
        var e =
            (this.isotope.size.innerWidth - t.size.outerWidth) *
            this.options.horizontalAlignment,
          i = this.y;
        return (this.y += t.size.outerHeight), { x: e, y: i };
      }),
      (i._getContainerSize = function () {
        return { height: this.y };
      }),
      e
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          [
            'outlayer/outlayer',
            'get-size/get-size',
            'desandro-matches-selector/matches-selector',
            'fizzy-ui-utils/utils',
            'isotope-layout/js/item',
            'isotope-layout/js/layout-mode',
            'isotope-layout/js/layout-modes/masonry',
            'isotope-layout/js/layout-modes/fit-rows',
            'isotope-layout/js/layout-modes/vertical',
          ],
          function (i, o, n, s, r, a) {
            return e(t, i, o, n, s, r, a);
          }
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(
          t,
          require('outlayer'),
          require('get-size'),
          require('desandro-matches-selector'),
          require('fizzy-ui-utils'),
          require('isotope-layout/js/item'),
          require('isotope-layout/js/layout-mode'),
          require('isotope-layout/js/layout-modes/masonry'),
          require('isotope-layout/js/layout-modes/fit-rows'),
          require('isotope-layout/js/layout-modes/vertical')
        ))
      : (t.Isotope = e(
          t,
          t.Outlayer,
          t.getSize,
          t.matchesSelector,
          t.fizzyUIUtils,
          t.Isotope.Item,
          t.Isotope.LayoutMode
        ));
  })(window, function (t, e, i, o, n, s, r) {
    function a(t, e) {
      return function (i, o) {
        for (var n = 0; n < t.length; n++) {
          var s = t[n],
            r = i.sortData[s],
            a = o.sortData[s];
          if (r > a || r < a) {
            var u = void 0 !== e[s] ? e[s] : e,
              h = u ? 1 : -1;
            return (r > a ? 1 : -1) * h;
          }
        }
        return 0;
      };
    }
    var u = t.jQuery,
      h = String.prototype.trim
        ? function (t) {
            return t.trim();
          }
        : function (t) {
            return t.replace(/^\s+|\s+$/g, '');
          },
      d = e.create('isotope', {
        layoutMode: 'masonry',
        isJQueryFiltering: !0,
        sortAscending: !0,
      });
    (d.Item = s), (d.LayoutMode = r);
    var l = d.prototype;
    (l._create = function () {
      (this.itemGUID = 0),
        (this._sorters = {}),
        this._getSorters(),
        e.prototype._create.call(this),
        (this.modes = {}),
        (this.filteredItems = this.items),
        (this.sortHistory = ['original-order']);
      for (var t in r.modes) this._initLayoutMode(t);
    }),
      (l.reloadItems = function () {
        (this.itemGUID = 0), e.prototype.reloadItems.call(this);
      }),
      (l._itemize = function () {
        for (
          var t = e.prototype._itemize.apply(this, arguments), i = 0;
          i < t.length;
          i++
        ) {
          var o = t[i];
          o.id = this.itemGUID++;
        }
        return this._updateItemsSortData(t), t;
      }),
      (l._initLayoutMode = function (t) {
        var e = r.modes[t],
          i = this.options[t] || {};
        (this.options[t] = e.options ? n.extend(e.options, i) : i),
          (this.modes[t] = new e(this));
      }),
      (l.layout = function () {
        return !this._isLayoutInited && this._getOption('initLayout')
          ? void this.arrange()
          : void this._layout();
      }),
      (l._layout = function () {
        var t = this._getIsInstant();
        this._resetLayout(),
          this._manageStamps(),
          this.layoutItems(this.filteredItems, t),
          (this._isLayoutInited = !0);
      }),
      (l.arrange = function (t) {
        this.option(t), this._getIsInstant();
        var e = this._filter(this.items);
        (this.filteredItems = e.matches),
          this._bindArrangeComplete(),
          this._isInstant
            ? this._noTransition(this._hideReveal, [e])
            : this._hideReveal(e),
          this._sort(),
          this._layout();
      }),
      (l._init = l.arrange),
      (l._hideReveal = function (t) {
        this.reveal(t.needReveal), this.hide(t.needHide);
      }),
      (l._getIsInstant = function () {
        var t = this._getOption('layoutInstant'),
          e = void 0 !== t ? t : !this._isLayoutInited;
        return (this._isInstant = e), e;
      }),
      (l._bindArrangeComplete = function () {
        function t() {
          e &&
            i &&
            o &&
            n.dispatchEvent('arrangeComplete', null, [n.filteredItems]);
        }
        var e,
          i,
          o,
          n = this;
        this.once('layoutComplete', function () {
          (e = !0), t();
        }),
          this.once('hideComplete', function () {
            (i = !0), t();
          }),
          this.once('revealComplete', function () {
            (o = !0), t();
          });
      }),
      (l._filter = function (t) {
        var e = this.options.filter;
        e = e || '*';
        for (
          var i = [], o = [], n = [], s = this._getFilterTest(e), r = 0;
          r < t.length;
          r++
        ) {
          var a = t[r];
          if (!a.isIgnored) {
            var u = s(a);
            u && i.push(a),
              u && a.isHidden ? o.push(a) : u || a.isHidden || n.push(a);
          }
        }
        return { matches: i, needReveal: o, needHide: n };
      }),
      (l._getFilterTest = function (t) {
        return u && this.options.isJQueryFiltering
          ? function (e) {
              return u(e.element).is(t);
            }
          : 'function' == typeof t
          ? function (e) {
              return t(e.element);
            }
          : function (e) {
              return o(e.element, t);
            };
      }),
      (l.updateSortData = function (t) {
        var e;
        t ? ((t = n.makeArray(t)), (e = this.getItems(t))) : (e = this.items),
          this._getSorters(),
          this._updateItemsSortData(e);
      }),
      (l._getSorters = function () {
        var t = this.options.getSortData;
        for (var e in t) {
          var i = t[e];
          this._sorters[e] = f(i);
        }
      }),
      (l._updateItemsSortData = function (t) {
        for (var e = t && t.length, i = 0; e && i < e; i++) {
          var o = t[i];
          o.updateSortData();
        }
      });
    var f = (function () {
      function t(t) {
        if ('string' != typeof t) return t;
        var i = h(t).split(' '),
          o = i[0],
          n = o.match(/^\[(.+)\]$/),
          s = n && n[1],
          r = e(s, o),
          a = d.sortDataParsers[i[1]];
        return (t = a
          ? function (t) {
              return t && a(r(t));
            }
          : function (t) {
              return t && r(t);
            });
      }
      function e(t, e) {
        return t
          ? function (e) {
              return e.getAttribute(t);
            }
          : function (t) {
              var i = t.querySelector(e);
              return i && i.textContent;
            };
      }
      return t;
    })();
    (d.sortDataParsers = {
      parseInt: function (t) {
        return parseInt(t, 10);
      },
      parseFloat: function (t) {
        return parseFloat(t);
      },
    }),
      (l._sort = function () {
        if (this.options.sortBy) {
          var t = n.makeArray(this.options.sortBy);
          this._getIsSameSortBy(t) ||
            (this.sortHistory = t.concat(this.sortHistory));
          var e = a(this.sortHistory, this.options.sortAscending);
          this.filteredItems.sort(e);
        }
      }),
      (l._getIsSameSortBy = function (t) {
        for (var e = 0; e < t.length; e++)
          if (t[e] != this.sortHistory[e]) return !1;
        return !0;
      }),
      (l._mode = function () {
        var t = this.options.layoutMode,
          e = this.modes[t];
        if (!e) throw new Error('No layout mode: ' + t);
        return (e.options = this.options[t]), e;
      }),
      (l._resetLayout = function () {
        e.prototype._resetLayout.call(this), this._mode()._resetLayout();
      }),
      (l._getItemLayoutPosition = function (t) {
        return this._mode()._getItemLayoutPosition(t);
      }),
      (l._manageStamp = function (t) {
        this._mode()._manageStamp(t);
      }),
      (l._getContainerSize = function () {
        return this._mode()._getContainerSize();
      }),
      (l.needsResizeLayout = function () {
        return this._mode().needsResizeLayout();
      }),
      (l.appended = function (t) {
        var e = this.addItems(t);
        if (e.length) {
          var i = this._filterRevealAdded(e);
          this.filteredItems = this.filteredItems.concat(i);
        }
      }),
      (l.prepended = function (t) {
        var e = this._itemize(t);
        if (e.length) {
          this._resetLayout(), this._manageStamps();
          var i = this._filterRevealAdded(e);
          this.layoutItems(this.filteredItems),
            (this.filteredItems = i.concat(this.filteredItems)),
            (this.items = e.concat(this.items));
        }
      }),
      (l._filterRevealAdded = function (t) {
        var e = this._filter(t);
        return (
          this.hide(e.needHide),
          this.reveal(e.matches),
          this.layoutItems(e.matches, !0),
          e.matches
        );
      }),
      (l.insert = function (t) {
        var e = this.addItems(t);
        if (e.length) {
          var i,
            o,
            n = e.length;
          for (i = 0; i < n; i++)
            (o = e[i]), this.element.appendChild(o.element);
          var s = this._filter(e).matches;
          for (i = 0; i < n; i++) e[i].isLayoutInstant = !0;
          for (this.arrange(), i = 0; i < n; i++) delete e[i].isLayoutInstant;
          this.reveal(s);
        }
      });
    var c = l.remove;
    return (
      (l.remove = function (t) {
        t = n.makeArray(t);
        var e = this.getItems(t);
        c.call(this, t);
        for (var i = e && e.length, o = 0; i && o < i; o++) {
          var s = e[o];
          n.removeFrom(this.filteredItems, s);
        }
      }),
      (l.shuffle = function () {
        for (var t = 0; t < this.items.length; t++) {
          var e = this.items[t];
          e.sortData.random = Math.random();
        }
        (this.options.sortBy = 'random'), this._sort(), this._layout();
      }),
      (l._noTransition = function (t, e) {
        var i = this.options.transitionDuration;
        this.options.transitionDuration = 0;
        var o = t.apply(this, e);
        return (this.options.transitionDuration = i), o;
      }),
      (l.getFilteredItemElements = function () {
        return this.filteredItems.map(function (t) {
          return t.element;
        });
      }),
      d
    );
  });
!(function (t) {
  'use strict';
  'function' == typeof define && define.amd
    ? define(['jquery'], t)
    : 'undefined' != typeof module && module.exports
    ? (module.exports = t(require('jquery')))
    : t(jQuery);
})(function (t) {
  var e = -1,
    o = -1,
    a = function (t) {
      return parseFloat(t) || 0;
    },
    n = function (e) {
      var o = 1,
        n = t(e),
        i = null,
        r = [];
      return (
        n.each(function () {
          var e = t(this),
            n = e.offset().top - a(e.css('margin-top')),
            s = r.length > 0 ? r[r.length - 1] : null;
          null === s
            ? r.push(e)
            : Math.floor(Math.abs(i - n)) <= o
            ? (r[r.length - 1] = s.add(e))
            : r.push(e),
            (i = n);
        }),
        r
      );
    },
    i = function (e) {
      var o = { byRow: !0, property: 'height', target: null, remove: !1 };
      return 'object' == typeof e
        ? t.extend(o, e)
        : ('boolean' == typeof e
            ? (o.byRow = e)
            : 'remove' === e && (o.remove = !0),
          o);
    },
    r = (t.fn.matchHeight = function (e) {
      var o = i(e);
      if (o.remove) {
        var a = this;
        return (
          this.css(o.property, ''),
          t.each(r._groups, function (t, e) {
            e.elements = e.elements.not(a);
          }),
          this
        );
      }
      return this.length <= 1 && !o.target
        ? this
        : (r._groups.push({ elements: this, options: o }),
          r._apply(this, o),
          this);
    });
  (r.version = 'master'),
    (r._groups = []),
    (r._throttle = 80),
    (r._maintainScroll = !1),
    (r._beforeUpdate = null),
    (r._afterUpdate = null),
    (r._rows = n),
    (r._parse = a),
    (r._parseOptions = i),
    (r._apply = function (e, o) {
      var s = i(o),
        h = t(e),
        l = [h],
        c = t(window).scrollTop(),
        p = t('html').outerHeight(!0),
        u = h.parents().filter(':hidden');
      return (
        u.each(function () {
          var e = t(this);
          e.data('style-cache', e.attr('style'));
        }),
        u.css('display', 'block'),
        s.byRow &&
          !s.target &&
          (h.each(function () {
            var e = t(this),
              o = e.css('display');
            'inline-block' !== o &&
              'flex' !== o &&
              'inline-flex' !== o &&
              (o = 'block'),
              e.data('style-cache', e.attr('style')),
              e.css({
                display: o,
                'padding-top': '0',
                'padding-bottom': '0',
                'margin-top': '0',
                'margin-bottom': '0',
                'border-top-width': '0',
                'border-bottom-width': '0',
                height: '100px',
                overflow: 'hidden',
              });
          }),
          (l = n(h)),
          h.each(function () {
            var e = t(this);
            e.attr('style', e.data('style-cache') || '');
          })),
        t.each(l, function (e, o) {
          var n = t(o),
            i = 0;
          if (s.target) i = s.target.outerHeight(!1);
          else {
            if (s.byRow && n.length <= 1) return void n.css(s.property, '');
            n.each(function () {
              var e = t(this),
                o = e.attr('style'),
                a = e.css('display');
              'inline-block' !== a &&
                'flex' !== a &&
                'inline-flex' !== a &&
                (a = 'block');
              var n = { display: a };
              (n[s.property] = ''),
                e.css(n),
                e.outerHeight(!1) > i && (i = e.outerHeight(!1)),
                o ? e.attr('style', o) : e.css('display', '');
            });
          }
          n.each(function () {
            var e = t(this),
              o = 0;
            (s.target && e.is(s.target)) ||
              ('border-box' !== e.css('box-sizing') &&
                ((o +=
                  a(e.css('border-top-width')) +
                  a(e.css('border-bottom-width'))),
                (o += a(e.css('padding-top')) + a(e.css('padding-bottom')))),
              e.css(s.property, i - o + 'px'));
          });
        }),
        u.each(function () {
          var e = t(this);
          e.attr('style', e.data('style-cache') || null);
        }),
        r._maintainScroll &&
          t(window).scrollTop((c / p) * t('html').outerHeight(!0)),
        this
      );
    }),
    (r._applyDataApi = function () {
      var e = {};
      t('[data-match-height], [data-mh]').each(function () {
        var o = t(this),
          a = o.attr('data-mh') || o.attr('data-match-height');
        a in e ? (e[a] = e[a].add(o)) : (e[a] = o);
      }),
        t.each(e, function () {
          this.matchHeight(!0);
        });
    });
  var s = function (e) {
    r._beforeUpdate && r._beforeUpdate(e, r._groups),
      t.each(r._groups, function () {
        r._apply(this.elements, this.options);
      }),
      r._afterUpdate && r._afterUpdate(e, r._groups);
  };
  (r._update = function (a, n) {
    if (n && 'resize' === n.type) {
      var i = t(window).width();
      if (i === e) return;
      e = i;
    }
    a
      ? o === -1 &&
        (o = setTimeout(function () {
          s(n), (o = -1);
        }, r._throttle))
      : s(n);
  }),
    t(r._applyDataApi);
  var h = t.fn.on ? 'on' : 'bind';
  t(window)[h]('load', function (t) {
    r._update(!1, t);
  }),
    t(window)[h]('resize orientationchange', function (t) {
      r._update(!0, t);
    });
});
!(function (e) {
  'function' == typeof define && define.amd
    ? define(['jquery'], e)
    : e(
        'object' == typeof exports
          ? require('jquery')
          : window.jQuery || window.Zepto
      );
})(function (e) {
  var t,
    n,
    i,
    o,
    r,
    a,
    s = 'Close',
    l = 'BeforeClose',
    c = 'AfterClose',
    d = 'BeforeAppend',
    u = 'MarkupParse',
    p = 'Open',
    f = 'Change',
    m = 'mfp',
    g = '.' + m,
    v = 'mfp-ready',
    h = 'mfp-removing',
    y = 'mfp-prevent-close',
    C = function () {},
    w = !!window.jQuery,
    b = e(window),
    I = function (e, n) {
      t.ev.on(m + e + g, n);
    },
    x = function (t, n, i, o) {
      var r = document.createElement('div');
      return (
        (r.className = 'mfp-' + t),
        i && (r.innerHTML = i),
        o ? n && n.appendChild(r) : ((r = e(r)), n && r.appendTo(n)),
        r
      );
    },
    k = function (n, i) {
      t.ev.triggerHandler(m + n, i),
        t.st.callbacks &&
          ((n = n.charAt(0).toLowerCase() + n.slice(1)),
          t.st.callbacks[n] &&
            t.st.callbacks[n].apply(t, e.isArray(i) ? i : [i]));
    },
    T = function (n) {
      return (
        (n === a && t.currTemplate.closeBtn) ||
          ((t.currTemplate.closeBtn = e(
            t.st.closeMarkup.replace('%title%', t.st.tClose)
          )),
          (a = n)),
        t.currTemplate.closeBtn
      );
    },
    _ = function () {
      e.magnificPopup.instance ||
        ((t = new C()), t.init(), (e.magnificPopup.instance = t));
    },
    P = function () {
      var e = document.createElement('p').style,
        t = ['ms', 'O', 'Moz', 'Webkit'];
      if (void 0 !== e.transition) return !0;
      for (; t.length; ) if (t.pop() + 'Transition' in e) return !0;
      return !1;
    };
  (C.prototype = {
    constructor: C,
    init: function () {
      var n = navigator.appVersion;
      (t.isLowIE = t.isIE8 = document.all && !document.addEventListener),
        (t.isAndroid = /android/gi.test(n)),
        (t.isIOS = /iphone|ipad|ipod/gi.test(n)),
        (t.supportsTransition = P()),
        (t.probablyMobile =
          t.isAndroid ||
          t.isIOS ||
          /(Opera Mini)|Kindle|webOS|BlackBerry|(Opera Mobi)|(Windows Phone)|IEMobile/i.test(
            navigator.userAgent
          )),
        (i = e(document)),
        (t.popupsCache = {});
    },
    open: function (n) {
      var o;
      if (n.isObj === !1) {
        (t.items = n.items.toArray()), (t.index = 0);
        var a,
          s = n.items;
        for (o = 0; o < s.length; o++)
          if (((a = s[o]), a.parsed && (a = a.el[0]), a === n.el[0])) {
            t.index = o;
            break;
          }
      } else
        (t.items = e.isArray(n.items) ? n.items : [n.items]),
          (t.index = n.index || 0);
      if (t.isOpen) return void t.updateItemHTML();
      (t.types = []),
        (r = ''),
        n.mainEl && n.mainEl.length ? (t.ev = n.mainEl.eq(0)) : (t.ev = i),
        n.key
          ? (t.popupsCache[n.key] || (t.popupsCache[n.key] = {}),
            (t.currTemplate = t.popupsCache[n.key]))
          : (t.currTemplate = {}),
        (t.st = e.extend(!0, {}, e.magnificPopup.defaults, n)),
        (t.fixedContentPos =
          'auto' === t.st.fixedContentPos
            ? !t.probablyMobile
            : t.st.fixedContentPos),
        t.st.modal &&
          ((t.st.closeOnContentClick = !1),
          (t.st.closeOnBgClick = !1),
          (t.st.showCloseBtn = !1),
          (t.st.enableEscapeKey = !1)),
        t.bgOverlay ||
          ((t.bgOverlay = x('bg').on('click' + g, function () {
            t.close();
          })),
          (t.wrap = x('wrap')
            .attr('tabindex', -1)
            .on('click' + g, function (e) {
              t._checkIfClose(e.target) && t.close();
            })),
          (t.container = x('container', t.wrap))),
        (t.contentContainer = x('content')),
        t.st.preloader &&
          (t.preloader = x('preloader', t.container, t.st.tLoading));
      var l = e.magnificPopup.modules;
      for (o = 0; o < l.length; o++) {
        var c = l[o];
        (c = c.charAt(0).toUpperCase() + c.slice(1)), t['init' + c].call(t);
      }
      k('BeforeOpen'),
        t.st.showCloseBtn &&
          (t.st.closeBtnInside
            ? (I(u, function (e, t, n, i) {
                n.close_replaceWith = T(i.type);
              }),
              (r += ' mfp-close-btn-in'))
            : t.wrap.append(T())),
        t.st.alignTop && (r += ' mfp-align-top'),
        t.fixedContentPos
          ? t.wrap.css({
              overflow: t.st.overflowY,
              overflowX: 'hidden',
              overflowY: t.st.overflowY,
            })
          : t.wrap.css({ top: b.scrollTop(), position: 'absolute' }),
        (t.st.fixedBgPos === !1 ||
          ('auto' === t.st.fixedBgPos && !t.fixedContentPos)) &&
          t.bgOverlay.css({ height: i.height(), position: 'absolute' }),
        t.st.enableEscapeKey &&
          i.on('keyup' + g, function (e) {
            27 === e.keyCode && t.close();
          }),
        b.on('resize' + g, function () {
          t.updateSize();
        }),
        t.st.closeOnContentClick || (r += ' mfp-auto-cursor'),
        r && t.wrap.addClass(r);
      var d = (t.wH = b.height()),
        f = {};
      if (t.fixedContentPos && t._hasScrollBar(d)) {
        var m = t._getScrollbarSize();
        m && (f.marginRight = m);
      }
      t.fixedContentPos &&
        (t.isIE7
          ? e('body, html').css('overflow', 'hidden')
          : (f.overflow = 'hidden'));
      var h = t.st.mainClass;
      return (
        t.isIE7 && (h += ' mfp-ie7'),
        h && t._addClassToMFP(h),
        t.updateItemHTML(),
        k('BuildControls'),
        e('html').css(f),
        t.bgOverlay.add(t.wrap).prependTo(t.st.prependTo || e(document.body)),
        (t._lastFocusedEl = document.activeElement),
        setTimeout(function () {
          t.content
            ? (t._addClassToMFP(v), t._setFocus())
            : t.bgOverlay.addClass(v),
            i.on('focusin' + g, t._onFocusIn);
        }, 16),
        (t.isOpen = !0),
        t.updateSize(d),
        k(p),
        n
      );
    },
    close: function () {
      t.isOpen &&
        (k(l),
        (t.isOpen = !1),
        t.st.removalDelay && !t.isLowIE && t.supportsTransition
          ? (t._addClassToMFP(h),
            setTimeout(function () {
              t._close();
            }, t.st.removalDelay))
          : t._close());
    },
    _close: function () {
      k(s);
      var n = h + ' ' + v + ' ';
      if (
        (t.bgOverlay.detach(),
        t.wrap.detach(),
        t.container.empty(),
        t.st.mainClass && (n += t.st.mainClass + ' '),
        t._removeClassFromMFP(n),
        t.fixedContentPos)
      ) {
        var o = { marginRight: '' };
        t.isIE7 ? e('body, html').css('overflow', '') : (o.overflow = ''),
          e('html').css(o);
      }
      i.off('keyup' + g + ' focusin' + g),
        t.ev.off(g),
        t.wrap.attr('class', 'mfp-wrap').removeAttr('style'),
        t.bgOverlay.attr('class', 'mfp-bg'),
        t.container.attr('class', 'mfp-container'),
        !t.st.showCloseBtn ||
          (t.st.closeBtnInside && t.currTemplate[t.currItem.type] !== !0) ||
          (t.currTemplate.closeBtn && t.currTemplate.closeBtn.detach()),
        t.st.autoFocusLast && t._lastFocusedEl && e(t._lastFocusedEl).focus(),
        (t.currItem = null),
        (t.content = null),
        (t.currTemplate = null),
        (t.prevHeight = 0),
        k(c);
    },
    updateSize: function (e) {
      if (t.isIOS) {
        var n = document.documentElement.clientWidth / window.innerWidth,
          i = window.innerHeight * n;
        t.wrap.css('height', i), (t.wH = i);
      } else t.wH = e || b.height();
      t.fixedContentPos || t.wrap.css('height', t.wH), k('Resize');
    },
    updateItemHTML: function () {
      var n = t.items[t.index];
      t.contentContainer.detach(),
        t.content && t.content.detach(),
        n.parsed || (n = t.parseEl(t.index));
      var i = n.type;
      if (
        (k('BeforeChange', [t.currItem ? t.currItem.type : '', i]),
        (t.currItem = n),
        !t.currTemplate[i])
      ) {
        var r = !!t.st[i] && t.st[i].markup;
        k('FirstMarkupParse', r),
          r ? (t.currTemplate[i] = e(r)) : (t.currTemplate[i] = !0);
      }
      o && o !== n.type && t.container.removeClass('mfp-' + o + '-holder');
      var a = t['get' + i.charAt(0).toUpperCase() + i.slice(1)](
        n,
        t.currTemplate[i]
      );
      t.appendContent(a, i),
        (n.preloaded = !0),
        k(f, n),
        (o = n.type),
        t.container.prepend(t.contentContainer),
        k('AfterChange');
    },
    appendContent: function (e, n) {
      (t.content = e),
        e
          ? t.st.showCloseBtn && t.st.closeBtnInside && t.currTemplate[n] === !0
            ? t.content.find('.mfp-close').length || t.content.append(T())
            : (t.content = e)
          : (t.content = ''),
        k(d),
        t.container.addClass('mfp-' + n + '-holder'),
        t.contentContainer.append(t.content);
    },
    parseEl: function (n) {
      var i,
        o = t.items[n];
      if (
        (o.tagName
          ? (o = { el: e(o) })
          : ((i = o.type), (o = { data: o, src: o.src })),
        o.el)
      ) {
        for (var r = t.types, a = 0; a < r.length; a++)
          if (o.el.hasClass('mfp-' + r[a])) {
            i = r[a];
            break;
          }
        (o.src = o.el.attr('data-mfp-src')),
          o.src || (o.src = o.el.attr('href'));
      }
      return (
        (o.type = i || t.st.type || 'inline'),
        (o.index = n),
        (o.parsed = !0),
        (t.items[n] = o),
        k('ElementParse', o),
        t.items[n]
      );
    },
    addGroup: function (e, n) {
      var i = function (i) {
        (i.mfpEl = this), t._openClick(i, e, n);
      };
      n || (n = {});
      var o = 'click.magnificPopup';
      (n.mainEl = e),
        n.items
          ? ((n.isObj = !0), e.off(o).on(o, i))
          : ((n.isObj = !1),
            n.delegate
              ? e.off(o).on(o, n.delegate, i)
              : ((n.items = e), e.off(o).on(o, i)));
    },
    _openClick: function (n, i, o) {
      var r =
        void 0 !== o.midClick ? o.midClick : e.magnificPopup.defaults.midClick;
      if (
        r ||
        !(2 === n.which || n.ctrlKey || n.metaKey || n.altKey || n.shiftKey)
      ) {
        var a =
          void 0 !== o.disableOn
            ? o.disableOn
            : e.magnificPopup.defaults.disableOn;
        if (a)
          if (e.isFunction(a)) {
            if (!a.call(t)) return !0;
          } else if (b.width() < a) return !0;
        n.type && (n.preventDefault(), t.isOpen && n.stopPropagation()),
          (o.el = e(n.mfpEl)),
          o.delegate && (o.items = i.find(o.delegate)),
          t.open(o);
      }
    },
    updateStatus: function (e, i) {
      if (t.preloader) {
        n !== e && t.container.removeClass('mfp-s-' + n),
          i || 'loading' !== e || (i = t.st.tLoading);
        var o = { status: e, text: i };
        k('UpdateStatus', o),
          (e = o.status),
          (i = o.text),
          t.preloader.html(i),
          t.preloader.find('a').on('click', function (e) {
            e.stopImmediatePropagation();
          }),
          t.container.addClass('mfp-s-' + e),
          (n = e);
      }
    },
    _checkIfClose: function (n) {
      if (!e(n).hasClass(y)) {
        var i = t.st.closeOnContentClick,
          o = t.st.closeOnBgClick;
        if (i && o) return !0;
        if (
          !t.content ||
          e(n).hasClass('mfp-close') ||
          (t.preloader && n === t.preloader[0])
        )
          return !0;
        if (n === t.content[0] || e.contains(t.content[0], n)) {
          if (i) return !0;
        } else if (o && e.contains(document, n)) return !0;
        return !1;
      }
    },
    _addClassToMFP: function (e) {
      t.bgOverlay.addClass(e), t.wrap.addClass(e);
    },
    _removeClassFromMFP: function (e) {
      this.bgOverlay.removeClass(e), t.wrap.removeClass(e);
    },
    _hasScrollBar: function (e) {
      return (
        (t.isIE7 ? i.height() : document.body.scrollHeight) > (e || b.height())
      );
    },
    _setFocus: function () {
      (t.st.focus ? t.content.find(t.st.focus).eq(0) : t.wrap).focus();
    },
    _onFocusIn: function (n) {
      if (n.target !== t.wrap[0] && !e.contains(t.wrap[0], n.target))
        return t._setFocus(), !1;
    },
    _parseMarkup: function (t, n, i) {
      var o;
      i.data && (n = e.extend(i.data, n)),
        k(u, [t, n, i]),
        e.each(n, function (n, i) {
          if (void 0 === i || i === !1) return !0;
          if (((o = n.split('_')), o.length > 1)) {
            var r = t.find(g + '-' + o[0]);
            if (r.length > 0) {
              var a = o[1];
              'replaceWith' === a
                ? r[0] !== i[0] && r.replaceWith(i)
                : 'img' === a
                ? r.is('img')
                  ? r.attr('src', i)
                  : r.replaceWith(
                      e('<img>').attr('src', i).attr('class', r.attr('class'))
                    )
                : r.attr(o[1], i);
            }
          } else t.find(g + '-' + n).html(i);
        });
    },
    _getScrollbarSize: function () {
      if (void 0 === t.scrollbarSize) {
        var e = document.createElement('div');
        (e.style.cssText =
          'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;'),
          document.body.appendChild(e),
          (t.scrollbarSize = e.offsetWidth - e.clientWidth),
          document.body.removeChild(e);
      }
      return t.scrollbarSize;
    },
  }),
    (e.magnificPopup = {
      instance: null,
      proto: C.prototype,
      modules: [],
      open: function (t, n) {
        return (
          _(),
          (t = t ? e.extend(!0, {}, t) : {}),
          (t.isObj = !0),
          (t.index = n || 0),
          this.instance.open(t)
        );
      },
      close: function () {
        return e.magnificPopup.instance && e.magnificPopup.instance.close();
      },
      registerModule: function (t, n) {
        n.options && (e.magnificPopup.defaults[t] = n.options),
          e.extend(this.proto, n.proto),
          this.modules.push(t);
      },
      defaults: {
        disableOn: 0,
        key: null,
        midClick: !1,
        mainClass: '',
        preloader: !0,
        focus: '',
        closeOnContentClick: !1,
        closeOnBgClick: !0,
        closeBtnInside: !0,
        showCloseBtn: !0,
        enableEscapeKey: !0,
        modal: !1,
        alignTop: !1,
        removalDelay: 0,
        prependTo: null,
        fixedContentPos: 'auto',
        fixedBgPos: 'auto',
        overflowY: 'auto',
        closeMarkup:
          '<button title="%title%" type="button" class="mfp-close">&#215;</button>',
        tClose: 'Close (Esc)',
        tLoading: 'Loading...',
        autoFocusLast: !0,
      },
    }),
    (e.fn.magnificPopup = function (n) {
      _();
      var i = e(this);
      if ('string' == typeof n)
        if ('open' === n) {
          var o,
            r = w ? i.data('magnificPopup') : i[0].magnificPopup,
            a = parseInt(arguments[1], 10) || 0;
          r.items
            ? (o = r.items[a])
            : ((o = i), r.delegate && (o = o.find(r.delegate)), (o = o.eq(a))),
            t._openClick({ mfpEl: o }, i, r);
        } else
          t.isOpen && t[n].apply(t, Array.prototype.slice.call(arguments, 1));
      else
        (n = e.extend(!0, {}, n)),
          w ? i.data('magnificPopup', n) : (i[0].magnificPopup = n),
          t.addGroup(i, n);
      return i;
    });
  var S,
    E,
    z,
    O = 'inline',
    M = function () {
      z && (E.after(z.addClass(S)).detach(), (z = null));
    };
  e.magnificPopup.registerModule(O, {
    options: {
      hiddenClass: 'hide',
      markup: '',
      tNotFound: 'Content not found',
    },
    proto: {
      initInline: function () {
        t.types.push(O),
          I(s + '.' + O, function () {
            M();
          });
      },
      getInline: function (n, i) {
        if ((M(), n.src)) {
          var o = t.st.inline,
            r = e(n.src);
          if (r.length) {
            var a = r[0].parentNode;
            a &&
              a.tagName &&
              (E || ((S = o.hiddenClass), (E = x(S)), (S = 'mfp-' + S)),
              (z = r.after(E).detach().removeClass(S))),
              t.updateStatus('ready');
          } else t.updateStatus('error', o.tNotFound), (r = e('<div>'));
          return (n.inlineElement = r), r;
        }
        return t.updateStatus('ready'), t._parseMarkup(i, {}, n), i;
      },
    },
  });
  var B,
    L = 'ajax',
    H = function () {
      B && e(document.body).removeClass(B);
    },
    A = function () {
      H(), t.req && t.req.abort();
    };
  e.magnificPopup.registerModule(L, {
    options: {
      settings: null,
      cursor: 'mfp-ajax-cur',
      tError: '<a href="%url%">The content</a> could not be loaded.',
    },
    proto: {
      initAjax: function () {
        t.types.push(L),
          (B = t.st.ajax.cursor),
          I(s + '.' + L, A),
          I('BeforeChange.' + L, A);
      },
      getAjax: function (n) {
        B && e(document.body).addClass(B), t.updateStatus('loading');
        var i = e.extend(
          {
            url: n.src,
            success: function (i, o, r) {
              var a = { data: i, xhr: r };
              k('ParseAjax', a),
                t.appendContent(e(a.data), L),
                (n.finished = !0),
                H(),
                t._setFocus(),
                setTimeout(function () {
                  t.wrap.addClass(v);
                }, 16),
                t.updateStatus('ready'),
                k('AjaxContentAdded');
            },
            error: function () {
              H(),
                (n.finished = n.loadError = !0),
                t.updateStatus(
                  'error',
                  t.st.ajax.tError.replace('%url%', n.src)
                );
            },
          },
          t.st.ajax.settings
        );
        return (t.req = e.ajax(i)), '';
      },
    },
  });
  var F,
    j = function (n) {
      if (n.data && void 0 !== n.data.title) return n.data.title;
      var i = t.st.image.titleSrc;
      if (i) {
        if (e.isFunction(i)) return i.call(t, n);
        if (n.el) return n.el.attr(i) || '';
      }
      return '';
    };
  e.magnificPopup.registerModule('image', {
    options: {
      markup:
        '<div class="mfp-figure"><div class="mfp-close"></div><figure><div class="mfp-img"></div><figcaption><div class="mfp-bottom-bar"><div class="mfp-title"></div><div class="mfp-counter"></div></div></figcaption></figure></div>',
      cursor: 'mfp-zoom-out-cur',
      titleSrc: 'title',
      verticalFit: !0,
      tError: '<a href="%url%">The image</a> could not be loaded.',
    },
    proto: {
      initImage: function () {
        var n = t.st.image,
          i = '.image';
        t.types.push('image'),
          I(p + i, function () {
            'image' === t.currItem.type &&
              n.cursor &&
              e(document.body).addClass(n.cursor);
          }),
          I(s + i, function () {
            n.cursor && e(document.body).removeClass(n.cursor),
              b.off('resize' + g);
          }),
          I('Resize' + i, t.resizeImage),
          t.isLowIE && I('AfterChange', t.resizeImage);
      },
      resizeImage: function () {
        var e = t.currItem;
        if (e && e.img && t.st.image.verticalFit) {
          var n = 0;
          t.isLowIE &&
            (n =
              parseInt(e.img.css('padding-top'), 10) +
              parseInt(e.img.css('padding-bottom'), 10)),
            e.img.css('max-height', t.wH - n);
        }
      },
      _onImageHasSize: function (e) {
        e.img &&
          ((e.hasSize = !0),
          F && clearInterval(F),
          (e.isCheckingImgSize = !1),
          k('ImageHasSize', e),
          e.imgHidden &&
            (t.content && t.content.removeClass('mfp-loading'),
            (e.imgHidden = !1)));
      },
      findImageSize: function (e) {
        var n = 0,
          i = e.img[0],
          o = function (r) {
            F && clearInterval(F),
              (F = setInterval(function () {
                return i.naturalWidth > 0
                  ? void t._onImageHasSize(e)
                  : (n > 200 && clearInterval(F),
                    n++,
                    void (3 === n
                      ? o(10)
                      : 40 === n
                      ? o(50)
                      : 100 === n && o(500)));
              }, r));
          };
        o(1);
      },
      getImage: function (n, i) {
        var o = 0,
          r = function () {
            n &&
              (n.img[0].complete
                ? (n.img.off('.mfploader'),
                  n === t.currItem &&
                    (t._onImageHasSize(n), t.updateStatus('ready')),
                  (n.hasSize = !0),
                  (n.loaded = !0),
                  k('ImageLoadComplete'))
                : (o++, o < 200 ? setTimeout(r, 100) : a()));
          },
          a = function () {
            n &&
              (n.img.off('.mfploader'),
              n === t.currItem &&
                (t._onImageHasSize(n),
                t.updateStatus('error', s.tError.replace('%url%', n.src))),
              (n.hasSize = !0),
              (n.loaded = !0),
              (n.loadError = !0));
          },
          s = t.st.image,
          l = i.find('.mfp-img');
        if (l.length) {
          var c = document.createElement('img');
          (c.className = 'mfp-img'),
            n.el &&
              n.el.find('img').length &&
              (c.alt = n.el.find('img').attr('alt')),
            (n.img = e(c).on('load.mfploader', r).on('error.mfploader', a)),
            (c.src = n.src),
            l.is('img') && (n.img = n.img.clone()),
            (c = n.img[0]),
            c.naturalWidth > 0 ? (n.hasSize = !0) : c.width || (n.hasSize = !1);
        }
        return (
          t._parseMarkup(i, { title: j(n), img_replaceWith: n.img }, n),
          t.resizeImage(),
          n.hasSize
            ? (F && clearInterval(F),
              n.loadError
                ? (i.addClass('mfp-loading'),
                  t.updateStatus('error', s.tError.replace('%url%', n.src)))
                : (i.removeClass('mfp-loading'), t.updateStatus('ready')),
              i)
            : (t.updateStatus('loading'),
              (n.loading = !0),
              n.hasSize ||
                ((n.imgHidden = !0),
                i.addClass('mfp-loading'),
                t.findImageSize(n)),
              i)
        );
      },
    },
  });
  var N,
    W = function () {
      return (
        void 0 === N &&
          (N = void 0 !== document.createElement('p').style.MozTransform),
        N
      );
    };
  e.magnificPopup.registerModule('zoom', {
    options: {
      enabled: !1,
      easing: 'ease-in-out',
      duration: 300,
      opener: function (e) {
        return e.is('img') ? e : e.find('img');
      },
    },
    proto: {
      initZoom: function () {
        var e,
          n = t.st.zoom,
          i = '.zoom';
        if (n.enabled && t.supportsTransition) {
          var o,
            r,
            a = n.duration,
            c = function (e) {
              var t = e
                  .clone()
                  .removeAttr('style')
                  .removeAttr('class')
                  .addClass('mfp-animated-image'),
                i = 'all ' + n.duration / 1e3 + 's ' + n.easing,
                o = {
                  position: 'fixed',
                  zIndex: 9999,
                  left: 0,
                  top: 0,
                  '-webkit-backface-visibility': 'hidden',
                },
                r = 'transition';
              return (
                (o['-webkit-' + r] = o['-moz-' + r] = o['-o-' + r] = o[r] = i),
                t.css(o),
                t
              );
            },
            d = function () {
              t.content.css('visibility', 'visible');
            };
          I('BuildControls' + i, function () {
            if (t._allowZoom()) {
              if (
                (clearTimeout(o),
                t.content.css('visibility', 'hidden'),
                (e = t._getItemToZoom()),
                !e)
              )
                return void d();
              (r = c(e)),
                r.css(t._getOffset()),
                t.wrap.append(r),
                (o = setTimeout(function () {
                  r.css(t._getOffset(!0)),
                    (o = setTimeout(function () {
                      d(),
                        setTimeout(function () {
                          r.remove(), (e = r = null), k('ZoomAnimationEnded');
                        }, 16);
                    }, a));
                }, 16));
            }
          }),
            I(l + i, function () {
              if (t._allowZoom()) {
                if ((clearTimeout(o), (t.st.removalDelay = a), !e)) {
                  if (((e = t._getItemToZoom()), !e)) return;
                  r = c(e);
                }
                r.css(t._getOffset(!0)),
                  t.wrap.append(r),
                  t.content.css('visibility', 'hidden'),
                  setTimeout(function () {
                    r.css(t._getOffset());
                  }, 16);
              }
            }),
            I(s + i, function () {
              t._allowZoom() && (d(), r && r.remove(), (e = null));
            });
        }
      },
      _allowZoom: function () {
        return 'image' === t.currItem.type;
      },
      _getItemToZoom: function () {
        return !!t.currItem.hasSize && t.currItem.img;
      },
      _getOffset: function (n) {
        var i;
        i = n ? t.currItem.img : t.st.zoom.opener(t.currItem.el || t.currItem);
        var o = i.offset(),
          r = parseInt(i.css('padding-top'), 10),
          a = parseInt(i.css('padding-bottom'), 10);
        o.top -= e(window).scrollTop() - r;
        var s = {
          width: i.width(),
          height: (w ? i.innerHeight() : i[0].offsetHeight) - a - r,
        };
        return (
          W()
            ? (s['-moz-transform'] = s.transform =
                'translate(' + o.left + 'px,' + o.top + 'px)')
            : ((s.left = o.left), (s.top = o.top)),
          s
        );
      },
    },
  });
  var Z = 'iframe',
    q = '//about:blank',
    R = function (e) {
      if (t.currTemplate[Z]) {
        var n = t.currTemplate[Z].find('iframe');
        n.length &&
          (e || (n[0].src = q),
          t.isIE8 && n.css('display', e ? 'block' : 'none'));
      }
    };
  e.magnificPopup.registerModule(Z, {
    options: {
      markup:
        '<div class="mfp-iframe-scaler"><div class="mfp-close"></div><iframe class="mfp-iframe" src="//about:blank" frameborder="0" allowfullscreen></iframe></div>',
      srcAction: 'iframe_src',
      patterns: {
        youtube: {
          index: 'youtube.com',
          id: 'v=',
          src: '//www.youtube.com/embed/%id%?autoplay=1',
        },
        vimeo: {
          index: 'vimeo.com/',
          id: '/',
          src: '//player.vimeo.com/video/%id%?autoplay=1',
        },
        gmaps: { index: '//maps.google.', src: '%id%&output=embed' },
      },
    },
    proto: {
      initIframe: function () {
        t.types.push(Z),
          I('BeforeChange', function (e, t, n) {
            t !== n && (t === Z ? R() : n === Z && R(!0));
          }),
          I(s + '.' + Z, function () {
            R();
          });
      },
      getIframe: function (n, i) {
        var o = n.src,
          r = t.st.iframe;
        e.each(r.patterns, function () {
          if (o.indexOf(this.index) > -1)
            return (
              this.id &&
                (o =
                  'string' == typeof this.id
                    ? o.substr(
                        o.lastIndexOf(this.id) + this.id.length,
                        o.length
                      )
                    : this.id.call(this, o)),
              (o = this.src.replace('%id%', o)),
              !1
            );
        });
        var a = {};
        return (
          r.srcAction && (a[r.srcAction] = o),
          t._parseMarkup(i, a, n),
          t.updateStatus('ready'),
          i
        );
      },
    },
  });
  var K = function (e) {
      var n = t.items.length;
      return e > n - 1 ? e - n : e < 0 ? n + e : e;
    },
    D = function (e, t, n) {
      return e.replace(/%curr%/gi, t + 1).replace(/%total%/gi, n);
    };
  e.magnificPopup.registerModule('gallery', {
    options: {
      enabled: !1,
      arrowMarkup:
        '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
      preload: [0, 2],
      navigateByImgClick: !0,
      arrows: !0,
      tPrev: 'Previous (Left arrow key)',
      tNext: 'Next (Right arrow key)',
      tCounter: '%curr% of %total%',
    },
    proto: {
      initGallery: function () {
        var n = t.st.gallery,
          o = '.mfp-gallery';
        return (
          (t.direction = !0),
          !(!n || !n.enabled) &&
            ((r += ' mfp-gallery'),
            I(p + o, function () {
              n.navigateByImgClick &&
                t.wrap.on('click' + o, '.mfp-img', function () {
                  if (t.items.length > 1) return t.next(), !1;
                }),
                i.on('keydown' + o, function (e) {
                  37 === e.keyCode ? t.prev() : 39 === e.keyCode && t.next();
                });
            }),
            I('UpdateStatus' + o, function (e, n) {
              n.text && (n.text = D(n.text, t.currItem.index, t.items.length));
            }),
            I(u + o, function (e, i, o, r) {
              var a = t.items.length;
              o.counter = a > 1 ? D(n.tCounter, r.index, a) : '';
            }),
            I('BuildControls' + o, function () {
              if (t.items.length > 1 && n.arrows && !t.arrowLeft) {
                var i = n.arrowMarkup,
                  o = (t.arrowLeft = e(
                    i.replace(/%title%/gi, n.tPrev).replace(/%dir%/gi, 'left')
                  ).addClass(y)),
                  r = (t.arrowRight = e(
                    i.replace(/%title%/gi, n.tNext).replace(/%dir%/gi, 'right')
                  ).addClass(y));
                o.click(function () {
                  t.prev();
                }),
                  r.click(function () {
                    t.next();
                  }),
                  t.container.append(o.add(r));
              }
            }),
            I(f + o, function () {
              t._preloadTimeout && clearTimeout(t._preloadTimeout),
                (t._preloadTimeout = setTimeout(function () {
                  t.preloadNearbyImages(), (t._preloadTimeout = null);
                }, 16));
            }),
            void I(s + o, function () {
              i.off(o),
                t.wrap.off('click' + o),
                (t.arrowRight = t.arrowLeft = null);
            }))
        );
      },
      next: function () {
        (t.direction = !0), (t.index = K(t.index + 1)), t.updateItemHTML();
      },
      prev: function () {
        (t.direction = !1), (t.index = K(t.index - 1)), t.updateItemHTML();
      },
      goTo: function (e) {
        (t.direction = e >= t.index), (t.index = e), t.updateItemHTML();
      },
      preloadNearbyImages: function () {
        var e,
          n = t.st.gallery.preload,
          i = Math.min(n[0], t.items.length),
          o = Math.min(n[1], t.items.length);
        for (e = 1; e <= (t.direction ? o : i); e++)
          t._preloadItem(t.index + e);
        for (e = 1; e <= (t.direction ? i : o); e++)
          t._preloadItem(t.index - e);
      },
      _preloadItem: function (n) {
        if (((n = K(n)), !t.items[n].preloaded)) {
          var i = t.items[n];
          i.parsed || (i = t.parseEl(n)),
            k('LazyLoad', i),
            'image' === i.type &&
              (i.img = e('<img class="mfp-img" />')
                .on('load.mfploader', function () {
                  i.hasSize = !0;
                })
                .on('error.mfploader', function () {
                  (i.hasSize = !0), (i.loadError = !0), k('LazyLoadError', i);
                })
                .attr('src', i.src)),
            (i.preloaded = !0);
        }
      },
    },
  });
  var U = 'retina';
  e.magnificPopup.registerModule(U, {
    options: {
      replaceSrc: function (e) {
        return e.src.replace(/\.\w+$/, function (e) {
          return '@2x' + e;
        });
      },
      ratio: 1,
    },
    proto: {
      initRetina: function () {
        if (window.devicePixelRatio > 1) {
          var e = t.st.retina,
            n = e.ratio;
          (n = isNaN(n) ? n() : n),
            n > 1 &&
              (I('ImageHasSize.' + U, function (e, t) {
                t.img.css({
                  'max-width': t.img[0].naturalWidth / n,
                  width: '100%',
                });
              }),
              I('ElementParse.' + U, function (t, i) {
                i.src = e.replaceSrc(i, n);
              }));
        }
      },
    },
  }),
    _();
});
!(function (i) {
  'use strict';
  'function' == typeof define && define.amd
    ? define(['jquery'], i)
    : 'undefined' != typeof exports
    ? (module.exports = i(require('jquery')))
    : i(jQuery);
})(function (i) {
  'use strict';
  var e = window.Slick || {};
  (e = (function () {
    function e(e, o) {
      var s,
        n = this;
      (n.defaults = {
        accessibility: !0,
        adaptiveHeight: !1,
        appendArrows: i(e),
        appendDots: i(e),
        arrows: !0,
        asNavFor: null,
        prevArrow:
          '<button class="slick-prev" aria-label="Previous" type="button">Previous</button>',
        nextArrow:
          '<button class="slick-next" aria-label="Next" type="button">Next</button>',
        autoplay: !1,
        autoplaySpeed: 3e3,
        centerMode: !1,
        centerPadding: '50px',
        cssEase: 'ease',
        customPaging: function (e, t) {
          return i('<button type="button" />').text(t + 1);
        },
        dots: !1,
        dotsClass: 'slick-dots',
        draggable: !0,
        easing: 'linear',
        edgeFriction: 0.35,
        fade: !1,
        focusOnSelect: !1,
        focusOnChange: !1,
        infinite: !0,
        initialSlide: 0,
        lazyLoad: 'ondemand',
        mobileFirst: !1,
        pauseOnHover: !0,
        pauseOnFocus: !0,
        pauseOnDotsHover: !1,
        respondTo: 'window',
        responsive: null,
        rows: 1,
        rtl: !1,
        slide: '',
        slidesPerRow: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        swipe: !0,
        swipeToSlide: !1,
        touchMove: !0,
        touchThreshold: 5,
        useCSS: !0,
        useTransform: !0,
        variableWidth: !1,
        vertical: !1,
        verticalSwiping: !1,
        waitForAnimate: !0,
        zIndex: 1e3,
      }),
        (n.initials = {
          animating: !1,
          dragging: !1,
          autoPlayTimer: null,
          currentDirection: 0,
          currentLeft: null,
          currentSlide: 0,
          direction: 1,
          $dots: null,
          listWidth: null,
          listHeight: null,
          loadIndex: 0,
          $nextArrow: null,
          $prevArrow: null,
          scrolling: !1,
          slideCount: null,
          slideWidth: null,
          $slideTrack: null,
          $slides: null,
          sliding: !1,
          slideOffset: 0,
          swipeLeft: null,
          swiping: !1,
          $list: null,
          touchObject: {},
          transformsEnabled: !1,
          unslicked: !1,
        }),
        i.extend(n, n.initials),
        (n.activeBreakpoint = null),
        (n.animType = null),
        (n.animProp = null),
        (n.breakpoints = []),
        (n.breakpointSettings = []),
        (n.cssTransitions = !1),
        (n.focussed = !1),
        (n.interrupted = !1),
        (n.hidden = 'hidden'),
        (n.paused = !0),
        (n.positionProp = null),
        (n.respondTo = null),
        (n.rowCount = 1),
        (n.shouldClick = !0),
        (n.$slider = i(e)),
        (n.$slidesCache = null),
        (n.transformType = null),
        (n.transitionType = null),
        (n.visibilityChange = 'visibilitychange'),
        (n.windowWidth = 0),
        (n.windowTimer = null),
        (s = i(e).data('slick') || {}),
        (n.options = i.extend({}, n.defaults, o, s)),
        (n.currentSlide = n.options.initialSlide),
        (n.originalSettings = n.options),
        'undefined' != typeof document.mozHidden
          ? ((n.hidden = 'mozHidden'),
            (n.visibilityChange = 'mozvisibilitychange'))
          : 'undefined' != typeof document.webkitHidden &&
            ((n.hidden = 'webkitHidden'),
            (n.visibilityChange = 'webkitvisibilitychange')),
        (n.autoPlay = i.proxy(n.autoPlay, n)),
        (n.autoPlayClear = i.proxy(n.autoPlayClear, n)),
        (n.autoPlayIterator = i.proxy(n.autoPlayIterator, n)),
        (n.changeSlide = i.proxy(n.changeSlide, n)),
        (n.clickHandler = i.proxy(n.clickHandler, n)),
        (n.selectHandler = i.proxy(n.selectHandler, n)),
        (n.setPosition = i.proxy(n.setPosition, n)),
        (n.swipeHandler = i.proxy(n.swipeHandler, n)),
        (n.dragHandler = i.proxy(n.dragHandler, n)),
        (n.keyHandler = i.proxy(n.keyHandler, n)),
        (n.instanceUid = t++),
        (n.htmlExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/),
        n.registerBreakpoints(),
        n.init(!0);
    }
    var t = 0;
    return e;
  })()),
    (e.prototype.activateADA = function () {
      var i = this;
      i.$slideTrack
        .find('.slick-active')
        .attr({ 'aria-hidden': 'false' })
        .find('a, input, button, select')
        .attr({ tabindex: '0' });
    }),
    (e.prototype.addSlide = e.prototype.slickAdd =
      function (e, t, o) {
        var s = this;
        if ('boolean' == typeof t) (o = t), (t = null);
        else if (t < 0 || t >= s.slideCount) return !1;
        s.unload(),
          'number' == typeof t
            ? 0 === t && 0 === s.$slides.length
              ? i(e).appendTo(s.$slideTrack)
              : o
              ? i(e).insertBefore(s.$slides.eq(t))
              : i(e).insertAfter(s.$slides.eq(t))
            : o === !0
            ? i(e).prependTo(s.$slideTrack)
            : i(e).appendTo(s.$slideTrack),
          (s.$slides = s.$slideTrack.children(this.options.slide)),
          s.$slideTrack.children(this.options.slide).detach(),
          s.$slideTrack.append(s.$slides),
          s.$slides.each(function (e, t) {
            i(t).attr('data-slick-index', e);
          }),
          (s.$slidesCache = s.$slides),
          s.reinit();
      }),
    (e.prototype.animateHeight = function () {
      var i = this;
      if (
        1 === i.options.slidesToShow &&
        i.options.adaptiveHeight === !0 &&
        i.options.vertical === !1
      ) {
        var e = i.$slides.eq(i.currentSlide).outerHeight(!0);
        i.$list.animate({ height: e }, i.options.speed);
      }
    }),
    (e.prototype.animateSlide = function (e, t) {
      var o = {},
        s = this;
      s.animateHeight(),
        s.options.rtl === !0 && s.options.vertical === !1 && (e = -e),
        s.transformsEnabled === !1
          ? s.options.vertical === !1
            ? s.$slideTrack.animate(
                { left: e },
                s.options.speed,
                s.options.easing,
                t
              )
            : s.$slideTrack.animate(
                { top: e },
                s.options.speed,
                s.options.easing,
                t
              )
          : s.cssTransitions === !1
          ? (s.options.rtl === !0 && (s.currentLeft = -s.currentLeft),
            i({ animStart: s.currentLeft }).animate(
              { animStart: e },
              {
                duration: s.options.speed,
                easing: s.options.easing,
                step: function (i) {
                  (i = Math.ceil(i)),
                    s.options.vertical === !1
                      ? ((o[s.animType] = 'translate(' + i + 'px, 0px)'),
                        s.$slideTrack.css(o))
                      : ((o[s.animType] = 'translate(0px,' + i + 'px)'),
                        s.$slideTrack.css(o));
                },
                complete: function () {
                  t && t.call();
                },
              }
            ))
          : (s.applyTransition(),
            (e = Math.ceil(e)),
            s.options.vertical === !1
              ? (o[s.animType] = 'translate3d(' + e + 'px, 0px, 0px)')
              : (o[s.animType] = 'translate3d(0px,' + e + 'px, 0px)'),
            s.$slideTrack.css(o),
            t &&
              setTimeout(function () {
                s.disableTransition(), t.call();
              }, s.options.speed));
    }),
    (e.prototype.getNavTarget = function () {
      var e = this,
        t = e.options.asNavFor;
      return t && null !== t && (t = i(t).not(e.$slider)), t;
    }),
    (e.prototype.asNavFor = function (e) {
      var t = this,
        o = t.getNavTarget();
      null !== o &&
        'object' == typeof o &&
        o.each(function () {
          var t = i(this).slick('getSlick');
          t.unslicked || t.slideHandler(e, !0);
        });
    }),
    (e.prototype.applyTransition = function (i) {
      var e = this,
        t = {};
      e.options.fade === !1
        ? (t[e.transitionType] =
            e.transformType + ' ' + e.options.speed + 'ms ' + e.options.cssEase)
        : (t[e.transitionType] =
            'opacity ' + e.options.speed + 'ms ' + e.options.cssEase),
        e.options.fade === !1 ? e.$slideTrack.css(t) : e.$slides.eq(i).css(t);
    }),
    (e.prototype.autoPlay = function () {
      var i = this;
      i.autoPlayClear(),
        i.slideCount > i.options.slidesToShow &&
          (i.autoPlayTimer = setInterval(
            i.autoPlayIterator,
            i.options.autoplaySpeed
          ));
    }),
    (e.prototype.autoPlayClear = function () {
      var i = this;
      i.autoPlayTimer && clearInterval(i.autoPlayTimer);
    }),
    (e.prototype.autoPlayIterator = function () {
      var i = this,
        e = i.currentSlide + i.options.slidesToScroll;
      i.paused ||
        i.interrupted ||
        i.focussed ||
        (i.options.infinite === !1 &&
          (1 === i.direction && i.currentSlide + 1 === i.slideCount - 1
            ? (i.direction = 0)
            : 0 === i.direction &&
              ((e = i.currentSlide - i.options.slidesToScroll),
              i.currentSlide - 1 === 0 && (i.direction = 1))),
        i.slideHandler(e));
    }),
    (e.prototype.buildArrows = function () {
      var e = this;
      e.options.arrows === !0 &&
        ((e.$prevArrow = i(e.options.prevArrow).addClass('slick-arrow')),
        (e.$nextArrow = i(e.options.nextArrow).addClass('slick-arrow')),
        e.slideCount > e.options.slidesToShow
          ? (e.$prevArrow
              .removeClass('slick-hidden')
              .removeAttr('aria-hidden tabindex'),
            e.$nextArrow
              .removeClass('slick-hidden')
              .removeAttr('aria-hidden tabindex'),
            e.htmlExpr.test(e.options.prevArrow) &&
              e.$prevArrow.prependTo(e.options.appendArrows),
            e.htmlExpr.test(e.options.nextArrow) &&
              e.$nextArrow.appendTo(e.options.appendArrows),
            e.options.infinite !== !0 &&
              e.$prevArrow
                .addClass('slick-disabled')
                .attr('aria-disabled', 'true'))
          : e.$prevArrow
              .add(e.$nextArrow)
              .addClass('slick-hidden')
              .attr({ 'aria-disabled': 'true', tabindex: '-1' }));
    }),
    (e.prototype.buildDots = function () {
      var e,
        t,
        o = this;
      if (o.options.dots === !0 && o.slideCount > o.options.slidesToShow) {
        for (
          o.$slider.addClass('slick-dotted'),
            t = i('<ul />').addClass(o.options.dotsClass),
            e = 0;
          e <= o.getDotCount();
          e += 1
        )
          t.append(i('<li />').append(o.options.customPaging.call(this, o, e)));
        (o.$dots = t.appendTo(o.options.appendDots)),
          o.$dots.find('li').first().addClass('slick-active');
      }
    }),
    (e.prototype.buildOut = function () {
      var e = this;
      (e.$slides = e.$slider
        .children(e.options.slide + ':not(.slick-cloned)')
        .addClass('slick-slide')),
        (e.slideCount = e.$slides.length),
        e.$slides.each(function (e, t) {
          i(t)
            .attr('data-slick-index', e)
            .data('originalStyling', i(t).attr('style') || '');
        }),
        e.$slider.addClass('slick-slider'),
        (e.$slideTrack =
          0 === e.slideCount
            ? i('<div class="slick-track"/>').appendTo(e.$slider)
            : e.$slides.wrapAll('<div class="slick-track"/>').parent()),
        (e.$list = e.$slideTrack.wrap('<div class="slick-list"/>').parent()),
        e.$slideTrack.css('opacity', 0),
        (e.options.centerMode !== !0 && e.options.swipeToSlide !== !0) ||
          (e.options.slidesToScroll = 1),
        i('img[data-lazy]', e.$slider).not('[src]').addClass('slick-loading'),
        e.setupInfinite(),
        e.buildArrows(),
        e.buildDots(),
        e.updateDots(),
        e.setSlideClasses(
          'number' == typeof e.currentSlide ? e.currentSlide : 0
        ),
        e.options.draggable === !0 && e.$list.addClass('draggable');
    }),
    (e.prototype.buildRows = function () {
      var i,
        e,
        t,
        o,
        s,
        n,
        r,
        l = this;
      if (
        ((o = document.createDocumentFragment()),
        (n = l.$slider.children()),
        l.options.rows > 0)
      ) {
        for (
          r = l.options.slidesPerRow * l.options.rows,
            s = Math.ceil(n.length / r),
            i = 0;
          i < s;
          i++
        ) {
          var d = document.createElement('div');
          for (e = 0; e < l.options.rows; e++) {
            var a = document.createElement('div');
            for (t = 0; t < l.options.slidesPerRow; t++) {
              var c = i * r + (e * l.options.slidesPerRow + t);
              n.get(c) && a.appendChild(n.get(c));
            }
            d.appendChild(a);
          }
          o.appendChild(d);
        }
        l.$slider.empty().append(o),
          l.$slider
            .children()
            .children()
            .children()
            .css({
              width: 100 / l.options.slidesPerRow + '%',
              display: 'inline-block',
            });
      }
    }),
    (e.prototype.checkResponsive = function (e, t) {
      var o,
        s,
        n,
        r = this,
        l = !1,
        d = r.$slider.width(),
        a = window.innerWidth || i(window).width();
      if (
        ('window' === r.respondTo
          ? (n = a)
          : 'slider' === r.respondTo
          ? (n = d)
          : 'min' === r.respondTo && (n = Math.min(a, d)),
        r.options.responsive &&
          r.options.responsive.length &&
          null !== r.options.responsive)
      ) {
        s = null;
        for (o in r.breakpoints)
          r.breakpoints.hasOwnProperty(o) &&
            (r.originalSettings.mobileFirst === !1
              ? n < r.breakpoints[o] && (s = r.breakpoints[o])
              : n > r.breakpoints[o] && (s = r.breakpoints[o]));
        null !== s
          ? null !== r.activeBreakpoint
            ? (s !== r.activeBreakpoint || t) &&
              ((r.activeBreakpoint = s),
              'unslick' === r.breakpointSettings[s]
                ? r.unslick(s)
                : ((r.options = i.extend(
                    {},
                    r.originalSettings,
                    r.breakpointSettings[s]
                  )),
                  e === !0 && (r.currentSlide = r.options.initialSlide),
                  r.refresh(e)),
              (l = s))
            : ((r.activeBreakpoint = s),
              'unslick' === r.breakpointSettings[s]
                ? r.unslick(s)
                : ((r.options = i.extend(
                    {},
                    r.originalSettings,
                    r.breakpointSettings[s]
                  )),
                  e === !0 && (r.currentSlide = r.options.initialSlide),
                  r.refresh(e)),
              (l = s))
          : null !== r.activeBreakpoint &&
            ((r.activeBreakpoint = null),
            (r.options = r.originalSettings),
            e === !0 && (r.currentSlide = r.options.initialSlide),
            r.refresh(e),
            (l = s)),
          e || l === !1 || r.$slider.trigger('breakpoint', [r, l]);
      }
    }),
    (e.prototype.changeSlide = function (e, t) {
      var o,
        s,
        n,
        r = this,
        l = i(e.currentTarget);
      switch (
        (l.is('a') && e.preventDefault(),
        l.is('li') || (l = l.closest('li')),
        (n = r.slideCount % r.options.slidesToScroll !== 0),
        (o = n
          ? 0
          : (r.slideCount - r.currentSlide) % r.options.slidesToScroll),
        e.data.message)
      ) {
        case 'previous':
          (s = 0 === o ? r.options.slidesToScroll : r.options.slidesToShow - o),
            r.slideCount > r.options.slidesToShow &&
              r.slideHandler(r.currentSlide - s, !1, t);
          break;
        case 'next':
          (s = 0 === o ? r.options.slidesToScroll : o),
            r.slideCount > r.options.slidesToShow &&
              r.slideHandler(r.currentSlide + s, !1, t);
          break;
        case 'index':
          var d =
            0 === e.data.index
              ? 0
              : e.data.index || l.index() * r.options.slidesToScroll;
          r.slideHandler(r.checkNavigable(d), !1, t),
            l.children().trigger('focus');
          break;
        default:
          return;
      }
    }),
    (e.prototype.checkNavigable = function (i) {
      var e,
        t,
        o = this;
      if (((e = o.getNavigableIndexes()), (t = 0), i > e[e.length - 1]))
        i = e[e.length - 1];
      else
        for (var s in e) {
          if (i < e[s]) {
            i = t;
            break;
          }
          t = e[s];
        }
      return i;
    }),
    (e.prototype.cleanUpEvents = function () {
      var e = this;
      e.options.dots &&
        null !== e.$dots &&
        (i('li', e.$dots)
          .off('click.slick', e.changeSlide)
          .off('mouseenter.slick', i.proxy(e.interrupt, e, !0))
          .off('mouseleave.slick', i.proxy(e.interrupt, e, !1)),
        e.options.accessibility === !0 &&
          e.$dots.off('keydown.slick', e.keyHandler)),
        e.$slider.off('focus.slick blur.slick'),
        e.options.arrows === !0 &&
          e.slideCount > e.options.slidesToShow &&
          (e.$prevArrow && e.$prevArrow.off('click.slick', e.changeSlide),
          e.$nextArrow && e.$nextArrow.off('click.slick', e.changeSlide),
          e.options.accessibility === !0 &&
            (e.$prevArrow && e.$prevArrow.off('keydown.slick', e.keyHandler),
            e.$nextArrow && e.$nextArrow.off('keydown.slick', e.keyHandler))),
        e.$list.off('touchstart.slick mousedown.slick', e.swipeHandler),
        e.$list.off('touchmove.slick mousemove.slick', e.swipeHandler),
        e.$list.off('touchend.slick mouseup.slick', e.swipeHandler),
        e.$list.off('touchcancel.slick mouseleave.slick', e.swipeHandler),
        e.$list.off('click.slick', e.clickHandler),
        i(document).off(e.visibilityChange, e.visibility),
        e.cleanUpSlideEvents(),
        e.options.accessibility === !0 &&
          e.$list.off('keydown.slick', e.keyHandler),
        e.options.focusOnSelect === !0 &&
          i(e.$slideTrack).children().off('click.slick', e.selectHandler),
        i(window).off(
          'orientationchange.slick.slick-' + e.instanceUid,
          e.orientationChange
        ),
        i(window).off('resize.slick.slick-' + e.instanceUid, e.resize),
        i('[draggable!=true]', e.$slideTrack).off(
          'dragstart',
          e.preventDefault
        ),
        i(window).off('load.slick.slick-' + e.instanceUid, e.setPosition);
    }),
    (e.prototype.cleanUpSlideEvents = function () {
      var e = this;
      e.$list.off('mouseenter.slick', i.proxy(e.interrupt, e, !0)),
        e.$list.off('mouseleave.slick', i.proxy(e.interrupt, e, !1));
    }),
    (e.prototype.cleanUpRows = function () {
      var i,
        e = this;
      e.options.rows > 0 &&
        ((i = e.$slides.children().children()),
        i.removeAttr('style'),
        e.$slider.empty().append(i));
    }),
    (e.prototype.clickHandler = function (i) {
      var e = this;
      e.shouldClick === !1 &&
        (i.stopImmediatePropagation(), i.stopPropagation(), i.preventDefault());
    }),
    (e.prototype.destroy = function (e) {
      var t = this;
      t.autoPlayClear(),
        (t.touchObject = {}),
        t.cleanUpEvents(),
        i('.slick-cloned', t.$slider).detach(),
        t.$dots && t.$dots.remove(),
        t.$prevArrow &&
          t.$prevArrow.length &&
          (t.$prevArrow
            .removeClass('slick-disabled slick-arrow slick-hidden')
            .removeAttr('aria-hidden aria-disabled tabindex')
            .css('display', ''),
          t.htmlExpr.test(t.options.prevArrow) && t.$prevArrow.remove()),
        t.$nextArrow &&
          t.$nextArrow.length &&
          (t.$nextArrow
            .removeClass('slick-disabled slick-arrow slick-hidden')
            .removeAttr('aria-hidden aria-disabled tabindex')
            .css('display', ''),
          t.htmlExpr.test(t.options.nextArrow) && t.$nextArrow.remove()),
        t.$slides &&
          (t.$slides
            .removeClass(
              'slick-slide slick-active slick-center slick-visible slick-current'
            )
            .removeAttr('aria-hidden')
            .removeAttr('data-slick-index')
            .each(function () {
              i(this).attr('style', i(this).data('originalStyling'));
            }),
          t.$slideTrack.children(this.options.slide).detach(),
          t.$slideTrack.detach(),
          t.$list.detach(),
          t.$slider.append(t.$slides)),
        t.cleanUpRows(),
        t.$slider.removeClass('slick-slider'),
        t.$slider.removeClass('slick-initialized'),
        t.$slider.removeClass('slick-dotted'),
        (t.unslicked = !0),
        e || t.$slider.trigger('destroy', [t]);
    }),
    (e.prototype.disableTransition = function (i) {
      var e = this,
        t = {};
      (t[e.transitionType] = ''),
        e.options.fade === !1 ? e.$slideTrack.css(t) : e.$slides.eq(i).css(t);
    }),
    (e.prototype.fadeSlide = function (i, e) {
      var t = this;
      t.cssTransitions === !1
        ? (t.$slides.eq(i).css({ zIndex: t.options.zIndex }),
          t.$slides
            .eq(i)
            .animate({ opacity: 1 }, t.options.speed, t.options.easing, e))
        : (t.applyTransition(i),
          t.$slides.eq(i).css({ opacity: 1, zIndex: t.options.zIndex }),
          e &&
            setTimeout(function () {
              t.disableTransition(i), e.call();
            }, t.options.speed));
    }),
    (e.prototype.fadeSlideOut = function (i) {
      var e = this;
      e.cssTransitions === !1
        ? e.$slides
            .eq(i)
            .animate(
              { opacity: 0, zIndex: e.options.zIndex - 2 },
              e.options.speed,
              e.options.easing
            )
        : (e.applyTransition(i),
          e.$slides.eq(i).css({ opacity: 0, zIndex: e.options.zIndex - 2 }));
    }),
    (e.prototype.filterSlides = e.prototype.slickFilter =
      function (i) {
        var e = this;
        null !== i &&
          ((e.$slidesCache = e.$slides),
          e.unload(),
          e.$slideTrack.children(this.options.slide).detach(),
          e.$slidesCache.filter(i).appendTo(e.$slideTrack),
          e.reinit());
      }),
    (e.prototype.focusHandler = function () {
      var e = this;
      e.$slider
        .off('focus.slick blur.slick')
        .on('focus.slick blur.slick', '*', function (t) {
          t.stopImmediatePropagation();
          var o = i(this);
          setTimeout(function () {
            e.options.pauseOnFocus &&
              ((e.focussed = o.is(':focus')), e.autoPlay());
          }, 0);
        });
    }),
    (e.prototype.getCurrent = e.prototype.slickCurrentSlide =
      function () {
        var i = this;
        return i.currentSlide;
      }),
    (e.prototype.getDotCount = function () {
      var i = this,
        e = 0,
        t = 0,
        o = 0;
      if (i.options.infinite === !0)
        if (i.slideCount <= i.options.slidesToShow) ++o;
        else
          for (; e < i.slideCount; )
            ++o,
              (e = t + i.options.slidesToScroll),
              (t +=
                i.options.slidesToScroll <= i.options.slidesToShow
                  ? i.options.slidesToScroll
                  : i.options.slidesToShow);
      else if (i.options.centerMode === !0) o = i.slideCount;
      else if (i.options.asNavFor)
        for (; e < i.slideCount; )
          ++o,
            (e = t + i.options.slidesToScroll),
            (t +=
              i.options.slidesToScroll <= i.options.slidesToShow
                ? i.options.slidesToScroll
                : i.options.slidesToShow);
      else
        o =
          1 +
          Math.ceil(
            (i.slideCount - i.options.slidesToShow) / i.options.slidesToScroll
          );
      return o - 1;
    }),
    (e.prototype.getLeft = function (i) {
      var e,
        t,
        o,
        s,
        n = this,
        r = 0;
      return (
        (n.slideOffset = 0),
        (t = n.$slides.first().outerHeight(!0)),
        n.options.infinite === !0
          ? (n.slideCount > n.options.slidesToShow &&
              ((n.slideOffset = n.slideWidth * n.options.slidesToShow * -1),
              (s = -1),
              n.options.vertical === !0 &&
                n.options.centerMode === !0 &&
                (2 === n.options.slidesToShow
                  ? (s = -1.5)
                  : 1 === n.options.slidesToShow && (s = -2)),
              (r = t * n.options.slidesToShow * s)),
            n.slideCount % n.options.slidesToScroll !== 0 &&
              i + n.options.slidesToScroll > n.slideCount &&
              n.slideCount > n.options.slidesToShow &&
              (i > n.slideCount
                ? ((n.slideOffset =
                    (n.options.slidesToShow - (i - n.slideCount)) *
                    n.slideWidth *
                    -1),
                  (r = (n.options.slidesToShow - (i - n.slideCount)) * t * -1))
                : ((n.slideOffset =
                    (n.slideCount % n.options.slidesToScroll) *
                    n.slideWidth *
                    -1),
                  (r = (n.slideCount % n.options.slidesToScroll) * t * -1))))
          : i + n.options.slidesToShow > n.slideCount &&
            ((n.slideOffset =
              (i + n.options.slidesToShow - n.slideCount) * n.slideWidth),
            (r = (i + n.options.slidesToShow - n.slideCount) * t)),
        n.slideCount <= n.options.slidesToShow &&
          ((n.slideOffset = 0), (r = 0)),
        n.options.centerMode === !0 && n.slideCount <= n.options.slidesToShow
          ? (n.slideOffset =
              (n.slideWidth * Math.floor(n.options.slidesToShow)) / 2 -
              (n.slideWidth * n.slideCount) / 2)
          : n.options.centerMode === !0 && n.options.infinite === !0
          ? (n.slideOffset +=
              n.slideWidth * Math.floor(n.options.slidesToShow / 2) -
              n.slideWidth)
          : n.options.centerMode === !0 &&
            ((n.slideOffset = 0),
            (n.slideOffset +=
              n.slideWidth * Math.floor(n.options.slidesToShow / 2))),
        (e =
          n.options.vertical === !1
            ? i * n.slideWidth * -1 + n.slideOffset
            : i * t * -1 + r),
        n.options.variableWidth === !0 &&
          ((o =
            n.slideCount <= n.options.slidesToShow || n.options.infinite === !1
              ? n.$slideTrack.children('.slick-slide').eq(i)
              : n.$slideTrack
                  .children('.slick-slide')
                  .eq(i + n.options.slidesToShow)),
          (e =
            n.options.rtl === !0
              ? o[0]
                ? (n.$slideTrack.width() - o[0].offsetLeft - o.width()) * -1
                : 0
              : o[0]
              ? o[0].offsetLeft * -1
              : 0),
          n.options.centerMode === !0 &&
            ((o =
              n.slideCount <= n.options.slidesToShow ||
              n.options.infinite === !1
                ? n.$slideTrack.children('.slick-slide').eq(i)
                : n.$slideTrack
                    .children('.slick-slide')
                    .eq(i + n.options.slidesToShow + 1)),
            (e =
              n.options.rtl === !0
                ? o[0]
                  ? (n.$slideTrack.width() - o[0].offsetLeft - o.width()) * -1
                  : 0
                : o[0]
                ? o[0].offsetLeft * -1
                : 0),
            (e += (n.$list.width() - o.outerWidth()) / 2))),
        e
      );
    }),
    (e.prototype.getOption = e.prototype.slickGetOption =
      function (i) {
        var e = this;
        return e.options[i];
      }),
    (e.prototype.getNavigableIndexes = function () {
      var i,
        e = this,
        t = 0,
        o = 0,
        s = [];
      for (
        e.options.infinite === !1
          ? (i = e.slideCount)
          : ((t = e.options.slidesToScroll * -1),
            (o = e.options.slidesToScroll * -1),
            (i = 2 * e.slideCount));
        t < i;

      )
        s.push(t),
          (t = o + e.options.slidesToScroll),
          (o +=
            e.options.slidesToScroll <= e.options.slidesToShow
              ? e.options.slidesToScroll
              : e.options.slidesToShow);
      return s;
    }),
    (e.prototype.getSlick = function () {
      return this;
    }),
    (e.prototype.getSlideCount = function () {
      var e,
        t,
        o,
        s = this;
      return (
        (o =
          s.options.centerMode === !0
            ? s.slideWidth * Math.floor(s.options.slidesToShow / 2)
            : 0),
        s.options.swipeToSlide === !0
          ? (s.$slideTrack.find('.slick-slide').each(function (e, n) {
              if (n.offsetLeft - o + i(n).outerWidth() / 2 > s.swipeLeft * -1)
                return (t = n), !1;
            }),
            (e = Math.abs(i(t).attr('data-slick-index') - s.currentSlide) || 1))
          : s.options.slidesToScroll
      );
    }),
    (e.prototype.goTo = e.prototype.slickGoTo =
      function (i, e) {
        var t = this;
        t.changeSlide({ data: { message: 'index', index: parseInt(i) } }, e);
      }),
    (e.prototype.init = function (e) {
      var t = this;
      i(t.$slider).hasClass('slick-initialized') ||
        (i(t.$slider).addClass('slick-initialized'),
        t.buildRows(),
        t.buildOut(),
        t.setProps(),
        t.startLoad(),
        t.loadSlider(),
        t.initializeEvents(),
        t.updateArrows(),
        t.updateDots(),
        t.checkResponsive(!0),
        t.focusHandler()),
        e && t.$slider.trigger('init', [t]),
        t.options.accessibility === !0 && t.initADA(),
        t.options.autoplay && ((t.paused = !1), t.autoPlay());
    }),
    (e.prototype.initADA = function () {
      var e = this,
        t = Math.ceil(e.slideCount / e.options.slidesToShow),
        o = e.getNavigableIndexes().filter(function (i) {
          return i >= 0 && i < e.slideCount;
        });
      e.$slides
        .add(e.$slideTrack.find('.slick-cloned'))
        .attr({ 'aria-hidden': 'true', tabindex: '-1' })
        .find('a, input, button, select')
        .attr({ tabindex: '-1' }),
        null !== e.$dots &&
          (e.$slides
            .not(e.$slideTrack.find('.slick-cloned'))
            .each(function (t) {
              var s = o.indexOf(t);
              if (
                (i(this).attr({
                  role: 'tabpanel',
                  id: 'slick-slide' + e.instanceUid + t,
                  tabindex: -1,
                }),
                s !== -1)
              ) {
                var n = 'slick-slide-control' + e.instanceUid + s;
                i('#' + n).length && i(this).attr({ 'aria-describedby': n });
              }
            }),
          e.$dots
            .attr('role', 'tablist')
            .find('li')
            .each(function (s) {
              var n = o[s];
              i(this).attr({ role: 'presentation' }),
                i(this)
                  .find('button')
                  .first()
                  .attr({
                    role: 'tab',
                    id: 'slick-slide-control' + e.instanceUid + s,
                    'aria-controls': 'slick-slide' + e.instanceUid + n,
                    'aria-label': s + 1 + ' of ' + t,
                    'aria-selected': null,
                    tabindex: '-1',
                  });
            })
            .eq(e.currentSlide)
            .find('button')
            .attr({ 'aria-selected': 'true', tabindex: '0' })
            .end());
      for (var s = e.currentSlide, n = s + e.options.slidesToShow; s < n; s++)
        e.options.focusOnChange
          ? e.$slides.eq(s).attr({ tabindex: '0' })
          : e.$slides.eq(s).removeAttr('tabindex');
      e.activateADA();
    }),
    (e.prototype.initArrowEvents = function () {
      var i = this;
      i.options.arrows === !0 &&
        i.slideCount > i.options.slidesToShow &&
        (i.$prevArrow
          .off('click.slick')
          .on('click.slick', { message: 'previous' }, i.changeSlide),
        i.$nextArrow
          .off('click.slick')
          .on('click.slick', { message: 'next' }, i.changeSlide),
        i.options.accessibility === !0 &&
          (i.$prevArrow.on('keydown.slick', i.keyHandler),
          i.$nextArrow.on('keydown.slick', i.keyHandler)));
    }),
    (e.prototype.initDotEvents = function () {
      var e = this;
      e.options.dots === !0 &&
        e.slideCount > e.options.slidesToShow &&
        (i('li', e.$dots).on(
          'click.slick',
          { message: 'index' },
          e.changeSlide
        ),
        e.options.accessibility === !0 &&
          e.$dots.on('keydown.slick', e.keyHandler)),
        e.options.dots === !0 &&
          e.options.pauseOnDotsHover === !0 &&
          e.slideCount > e.options.slidesToShow &&
          i('li', e.$dots)
            .on('mouseenter.slick', i.proxy(e.interrupt, e, !0))
            .on('mouseleave.slick', i.proxy(e.interrupt, e, !1));
    }),
    (e.prototype.initSlideEvents = function () {
      var e = this;
      e.options.pauseOnHover &&
        (e.$list.on('mouseenter.slick', i.proxy(e.interrupt, e, !0)),
        e.$list.on('mouseleave.slick', i.proxy(e.interrupt, e, !1)));
    }),
    (e.prototype.initializeEvents = function () {
      var e = this;
      e.initArrowEvents(),
        e.initDotEvents(),
        e.initSlideEvents(),
        e.$list.on(
          'touchstart.slick mousedown.slick',
          { action: 'start' },
          e.swipeHandler
        ),
        e.$list.on(
          'touchmove.slick mousemove.slick',
          { action: 'move' },
          e.swipeHandler
        ),
        e.$list.on(
          'touchend.slick mouseup.slick',
          { action: 'end' },
          e.swipeHandler
        ),
        e.$list.on(
          'touchcancel.slick mouseleave.slick',
          { action: 'end' },
          e.swipeHandler
        ),
        e.$list.on('click.slick', e.clickHandler),
        i(document).on(e.visibilityChange, i.proxy(e.visibility, e)),
        e.options.accessibility === !0 &&
          e.$list.on('keydown.slick', e.keyHandler),
        e.options.focusOnSelect === !0 &&
          i(e.$slideTrack).children().on('click.slick', e.selectHandler),
        i(window).on(
          'orientationchange.slick.slick-' + e.instanceUid,
          i.proxy(e.orientationChange, e)
        ),
        i(window).on(
          'resize.slick.slick-' + e.instanceUid,
          i.proxy(e.resize, e)
        ),
        i('[draggable!=true]', e.$slideTrack).on('dragstart', e.preventDefault),
        i(window).on('load.slick.slick-' + e.instanceUid, e.setPosition),
        i(e.setPosition);
    }),
    (e.prototype.initUI = function () {
      var i = this;
      i.options.arrows === !0 &&
        i.slideCount > i.options.slidesToShow &&
        (i.$prevArrow.show(), i.$nextArrow.show()),
        i.options.dots === !0 &&
          i.slideCount > i.options.slidesToShow &&
          i.$dots.show();
    }),
    (e.prototype.keyHandler = function (i) {
      var e = this;
      i.target.tagName.match('TEXTAREA|INPUT|SELECT') ||
        (37 === i.keyCode && e.options.accessibility === !0
          ? e.changeSlide({
              data: { message: e.options.rtl === !0 ? 'next' : 'previous' },
            })
          : 39 === i.keyCode &&
            e.options.accessibility === !0 &&
            e.changeSlide({
              data: { message: e.options.rtl === !0 ? 'previous' : 'next' },
            }));
    }),
    (e.prototype.lazyLoad = function () {
      function e(e) {
        i('img[data-lazy]', e).each(function () {
          var e = i(this),
            t = i(this).attr('data-lazy'),
            o = i(this).attr('data-srcset'),
            s = i(this).attr('data-sizes') || r.$slider.attr('data-sizes'),
            n = document.createElement('img');
          (n.onload = function () {
            e.animate({ opacity: 0 }, 100, function () {
              o && (e.attr('srcset', o), s && e.attr('sizes', s)),
                e.attr('src', t).animate({ opacity: 1 }, 200, function () {
                  e.removeAttr('data-lazy data-srcset data-sizes').removeClass(
                    'slick-loading'
                  );
                }),
                r.$slider.trigger('lazyLoaded', [r, e, t]);
            });
          }),
            (n.onerror = function () {
              e
                .removeAttr('data-lazy')
                .removeClass('slick-loading')
                .addClass('slick-lazyload-error'),
                r.$slider.trigger('lazyLoadError', [r, e, t]);
            }),
            (n.src = t);
        });
      }
      var t,
        o,
        s,
        n,
        r = this;
      if (
        (r.options.centerMode === !0
          ? r.options.infinite === !0
            ? ((s = r.currentSlide + (r.options.slidesToShow / 2 + 1)),
              (n = s + r.options.slidesToShow + 2))
            : ((s = Math.max(
                0,
                r.currentSlide - (r.options.slidesToShow / 2 + 1)
              )),
              (n = 2 + (r.options.slidesToShow / 2 + 1) + r.currentSlide))
          : ((s = r.options.infinite
              ? r.options.slidesToShow + r.currentSlide
              : r.currentSlide),
            (n = Math.ceil(s + r.options.slidesToShow)),
            r.options.fade === !0 && (s > 0 && s--, n <= r.slideCount && n++)),
        (t = r.$slider.find('.slick-slide').slice(s, n)),
        'anticipated' === r.options.lazyLoad)
      )
        for (
          var l = s - 1, d = n, a = r.$slider.find('.slick-slide'), c = 0;
          c < r.options.slidesToScroll;
          c++
        )
          l < 0 && (l = r.slideCount - 1),
            (t = t.add(a.eq(l))),
            (t = t.add(a.eq(d))),
            l--,
            d++;
      e(t),
        r.slideCount <= r.options.slidesToShow
          ? ((o = r.$slider.find('.slick-slide')), e(o))
          : r.currentSlide >= r.slideCount - r.options.slidesToShow
          ? ((o = r.$slider
              .find('.slick-cloned')
              .slice(0, r.options.slidesToShow)),
            e(o))
          : 0 === r.currentSlide &&
            ((o = r.$slider
              .find('.slick-cloned')
              .slice(r.options.slidesToShow * -1)),
            e(o));
    }),
    (e.prototype.loadSlider = function () {
      var i = this;
      i.setPosition(),
        i.$slideTrack.css({ opacity: 1 }),
        i.$slider.removeClass('slick-loading'),
        i.initUI(),
        'progressive' === i.options.lazyLoad && i.progressiveLazyLoad();
    }),
    (e.prototype.next = e.prototype.slickNext =
      function () {
        var i = this;
        i.changeSlide({ data: { message: 'next' } });
      }),
    (e.prototype.orientationChange = function () {
      var i = this;
      i.checkResponsive(), i.setPosition();
    }),
    (e.prototype.pause = e.prototype.slickPause =
      function () {
        var i = this;
        i.autoPlayClear(), (i.paused = !0);
      }),
    (e.prototype.play = e.prototype.slickPlay =
      function () {
        var i = this;
        i.autoPlay(),
          (i.options.autoplay = !0),
          (i.paused = !1),
          (i.focussed = !1),
          (i.interrupted = !1);
      }),
    (e.prototype.postSlide = function (e) {
      var t = this;
      if (
        !t.unslicked &&
        (t.$slider.trigger('afterChange', [t, e]),
        (t.animating = !1),
        t.slideCount > t.options.slidesToShow && t.setPosition(),
        (t.swipeLeft = null),
        t.options.autoplay && t.autoPlay(),
        t.options.accessibility === !0 &&
          (t.initADA(), t.options.focusOnChange))
      ) {
        var o = i(t.$slides.get(t.currentSlide));
        o.attr('tabindex', 0).focus();
      }
    }),
    (e.prototype.prev = e.prototype.slickPrev =
      function () {
        var i = this;
        i.changeSlide({ data: { message: 'previous' } });
      }),
    (e.prototype.preventDefault = function (i) {
      i.preventDefault();
    }),
    (e.prototype.progressiveLazyLoad = function (e) {
      e = e || 1;
      var t,
        o,
        s,
        n,
        r,
        l = this,
        d = i('img[data-lazy]', l.$slider);
      d.length
        ? ((t = d.first()),
          (o = t.attr('data-lazy')),
          (s = t.attr('data-srcset')),
          (n = t.attr('data-sizes') || l.$slider.attr('data-sizes')),
          (r = document.createElement('img')),
          (r.onload = function () {
            s && (t.attr('srcset', s), n && t.attr('sizes', n)),
              t
                .attr('src', o)
                .removeAttr('data-lazy data-srcset data-sizes')
                .removeClass('slick-loading'),
              l.options.adaptiveHeight === !0 && l.setPosition(),
              l.$slider.trigger('lazyLoaded', [l, t, o]),
              l.progressiveLazyLoad();
          }),
          (r.onerror = function () {
            e < 3
              ? setTimeout(function () {
                  l.progressiveLazyLoad(e + 1);
                }, 500)
              : (t
                  .removeAttr('data-lazy')
                  .removeClass('slick-loading')
                  .addClass('slick-lazyload-error'),
                l.$slider.trigger('lazyLoadError', [l, t, o]),
                l.progressiveLazyLoad());
          }),
          (r.src = o))
        : l.$slider.trigger('allImagesLoaded', [l]);
    }),
    (e.prototype.refresh = function (e) {
      var t,
        o,
        s = this;
      (o = s.slideCount - s.options.slidesToShow),
        !s.options.infinite && s.currentSlide > o && (s.currentSlide = o),
        s.slideCount <= s.options.slidesToShow && (s.currentSlide = 0),
        (t = s.currentSlide),
        s.destroy(!0),
        i.extend(s, s.initials, { currentSlide: t }),
        s.init(),
        e || s.changeSlide({ data: { message: 'index', index: t } }, !1);
    }),
    (e.prototype.registerBreakpoints = function () {
      var e,
        t,
        o,
        s = this,
        n = s.options.responsive || null;
      if ('array' === i.type(n) && n.length) {
        s.respondTo = s.options.respondTo || 'window';
        for (e in n)
          if (((o = s.breakpoints.length - 1), n.hasOwnProperty(e))) {
            for (t = n[e].breakpoint; o >= 0; )
              s.breakpoints[o] &&
                s.breakpoints[o] === t &&
                s.breakpoints.splice(o, 1),
                o--;
            s.breakpoints.push(t), (s.breakpointSettings[t] = n[e].settings);
          }
        s.breakpoints.sort(function (i, e) {
          return s.options.mobileFirst ? i - e : e - i;
        });
      }
    }),
    (e.prototype.reinit = function () {
      var e = this;
      (e.$slides = e.$slideTrack
        .children(e.options.slide)
        .addClass('slick-slide')),
        (e.slideCount = e.$slides.length),
        e.currentSlide >= e.slideCount &&
          0 !== e.currentSlide &&
          (e.currentSlide = e.currentSlide - e.options.slidesToScroll),
        e.slideCount <= e.options.slidesToShow && (e.currentSlide = 0),
        e.registerBreakpoints(),
        e.setProps(),
        e.setupInfinite(),
        e.buildArrows(),
        e.updateArrows(),
        e.initArrowEvents(),
        e.buildDots(),
        e.updateDots(),
        e.initDotEvents(),
        e.cleanUpSlideEvents(),
        e.initSlideEvents(),
        e.checkResponsive(!1, !0),
        e.options.focusOnSelect === !0 &&
          i(e.$slideTrack).children().on('click.slick', e.selectHandler),
        e.setSlideClasses(
          'number' == typeof e.currentSlide ? e.currentSlide : 0
        ),
        e.setPosition(),
        e.focusHandler(),
        (e.paused = !e.options.autoplay),
        e.autoPlay(),
        e.$slider.trigger('reInit', [e]);
    }),
    (e.prototype.resize = function () {
      var e = this;
      i(window).width() !== e.windowWidth &&
        (clearTimeout(e.windowDelay),
        (e.windowDelay = window.setTimeout(function () {
          (e.windowWidth = i(window).width()),
            e.checkResponsive(),
            e.unslicked || e.setPosition();
        }, 50)));
    }),
    (e.prototype.removeSlide = e.prototype.slickRemove =
      function (i, e, t) {
        var o = this;
        return (
          'boolean' == typeof i
            ? ((e = i), (i = e === !0 ? 0 : o.slideCount - 1))
            : (i = e === !0 ? --i : i),
          !(o.slideCount < 1 || i < 0 || i > o.slideCount - 1) &&
            (o.unload(),
            t === !0
              ? o.$slideTrack.children().remove()
              : o.$slideTrack.children(this.options.slide).eq(i).remove(),
            (o.$slides = o.$slideTrack.children(this.options.slide)),
            o.$slideTrack.children(this.options.slide).detach(),
            o.$slideTrack.append(o.$slides),
            (o.$slidesCache = o.$slides),
            void o.reinit())
        );
      }),
    (e.prototype.setCSS = function (i) {
      var e,
        t,
        o = this,
        s = {};
      o.options.rtl === !0 && (i = -i),
        (e = 'left' == o.positionProp ? Math.ceil(i) + 'px' : '0px'),
        (t = 'top' == o.positionProp ? Math.ceil(i) + 'px' : '0px'),
        (s[o.positionProp] = i),
        o.transformsEnabled === !1
          ? o.$slideTrack.css(s)
          : ((s = {}),
            o.cssTransitions === !1
              ? ((s[o.animType] = 'translate(' + e + ', ' + t + ')'),
                o.$slideTrack.css(s))
              : ((s[o.animType] = 'translate3d(' + e + ', ' + t + ', 0px)'),
                o.$slideTrack.css(s)));
    }),
    (e.prototype.setDimensions = function () {
      var i = this;
      i.options.vertical === !1
        ? i.options.centerMode === !0 &&
          i.$list.css({ padding: '0px ' + i.options.centerPadding })
        : (i.$list.height(
            i.$slides.first().outerHeight(!0) * i.options.slidesToShow
          ),
          i.options.centerMode === !0 &&
            i.$list.css({ padding: i.options.centerPadding + ' 0px' })),
        (i.listWidth = i.$list.width()),
        (i.listHeight = i.$list.height()),
        i.options.vertical === !1 && i.options.variableWidth === !1
          ? ((i.slideWidth = Math.ceil(i.listWidth / i.options.slidesToShow)),
            i.$slideTrack.width(
              Math.ceil(
                i.slideWidth * i.$slideTrack.children('.slick-slide').length
              )
            ))
          : i.options.variableWidth === !0
          ? i.$slideTrack.width(5e3 * i.slideCount)
          : ((i.slideWidth = Math.ceil(i.listWidth)),
            i.$slideTrack.height(
              Math.ceil(
                i.$slides.first().outerHeight(!0) *
                  i.$slideTrack.children('.slick-slide').length
              )
            ));
      var e = i.$slides.first().outerWidth(!0) - i.$slides.first().width();
      i.options.variableWidth === !1 &&
        i.$slideTrack.children('.slick-slide').width(i.slideWidth - e);
    }),
    (e.prototype.setFade = function () {
      var e,
        t = this;
      t.$slides.each(function (o, s) {
        (e = t.slideWidth * o * -1),
          t.options.rtl === !0
            ? i(s).css({
                position: 'relative',
                right: e,
                top: 0,
                zIndex: t.options.zIndex - 2,
                opacity: 0,
              })
            : i(s).css({
                position: 'relative',
                left: e,
                top: 0,
                zIndex: t.options.zIndex - 2,
                opacity: 0,
              });
      }),
        t.$slides
          .eq(t.currentSlide)
          .css({ zIndex: t.options.zIndex - 1, opacity: 1 });
    }),
    (e.prototype.setHeight = function () {
      var i = this;
      if (
        1 === i.options.slidesToShow &&
        i.options.adaptiveHeight === !0 &&
        i.options.vertical === !1
      ) {
        var e = i.$slides.eq(i.currentSlide).outerHeight(!0);
        i.$list.css('height', e);
      }
    }),
    (e.prototype.setOption = e.prototype.slickSetOption =
      function () {
        var e,
          t,
          o,
          s,
          n,
          r = this,
          l = !1;
        if (
          ('object' === i.type(arguments[0])
            ? ((o = arguments[0]), (l = arguments[1]), (n = 'multiple'))
            : 'string' === i.type(arguments[0]) &&
              ((o = arguments[0]),
              (s = arguments[1]),
              (l = arguments[2]),
              'responsive' === arguments[0] && 'array' === i.type(arguments[1])
                ? (n = 'responsive')
                : 'undefined' != typeof arguments[1] && (n = 'single')),
          'single' === n)
        )
          r.options[o] = s;
        else if ('multiple' === n)
          i.each(o, function (i, e) {
            r.options[i] = e;
          });
        else if ('responsive' === n)
          for (t in s)
            if ('array' !== i.type(r.options.responsive))
              r.options.responsive = [s[t]];
            else {
              for (e = r.options.responsive.length - 1; e >= 0; )
                r.options.responsive[e].breakpoint === s[t].breakpoint &&
                  r.options.responsive.splice(e, 1),
                  e--;
              r.options.responsive.push(s[t]);
            }
        l && (r.unload(), r.reinit());
      }),
    (e.prototype.setPosition = function () {
      var i = this;
      i.setDimensions(),
        i.setHeight(),
        i.options.fade === !1
          ? i.setCSS(i.getLeft(i.currentSlide))
          : i.setFade(),
        i.$slider.trigger('setPosition', [i]);
    }),
    (e.prototype.setProps = function () {
      var i = this,
        e = document.body.style;
      (i.positionProp = i.options.vertical === !0 ? 'top' : 'left'),
        'top' === i.positionProp
          ? i.$slider.addClass('slick-vertical')
          : i.$slider.removeClass('slick-vertical'),
        (void 0 === e.WebkitTransition &&
          void 0 === e.MozTransition &&
          void 0 === e.msTransition) ||
          (i.options.useCSS === !0 && (i.cssTransitions = !0)),
        i.options.fade &&
          ('number' == typeof i.options.zIndex
            ? i.options.zIndex < 3 && (i.options.zIndex = 3)
            : (i.options.zIndex = i.defaults.zIndex)),
        void 0 !== e.OTransform &&
          ((i.animType = 'OTransform'),
          (i.transformType = '-o-transform'),
          (i.transitionType = 'OTransition'),
          void 0 === e.perspectiveProperty &&
            void 0 === e.webkitPerspective &&
            (i.animType = !1)),
        void 0 !== e.MozTransform &&
          ((i.animType = 'MozTransform'),
          (i.transformType = '-moz-transform'),
          (i.transitionType = 'MozTransition'),
          void 0 === e.perspectiveProperty &&
            void 0 === e.MozPerspective &&
            (i.animType = !1)),
        void 0 !== e.webkitTransform &&
          ((i.animType = 'webkitTransform'),
          (i.transformType = '-webkit-transform'),
          (i.transitionType = 'webkitTransition'),
          void 0 === e.perspectiveProperty &&
            void 0 === e.webkitPerspective &&
            (i.animType = !1)),
        void 0 !== e.msTransform &&
          ((i.animType = 'msTransform'),
          (i.transformType = '-ms-transform'),
          (i.transitionType = 'msTransition'),
          void 0 === e.msTransform && (i.animType = !1)),
        void 0 !== e.transform &&
          i.animType !== !1 &&
          ((i.animType = 'transform'),
          (i.transformType = 'transform'),
          (i.transitionType = 'transition')),
        (i.transformsEnabled =
          i.options.useTransform && null !== i.animType && i.animType !== !1);
    }),
    (e.prototype.setSlideClasses = function (i) {
      var e,
        t,
        o,
        s,
        n = this;
      if (
        ((t = n.$slider
          .find('.slick-slide')
          .removeClass('slick-active slick-center slick-current')
          .attr('aria-hidden', 'true')),
        n.$slides.eq(i).addClass('slick-current'),
        n.options.centerMode === !0)
      ) {
        var r = n.options.slidesToShow % 2 === 0 ? 1 : 0;
        (e = Math.floor(n.options.slidesToShow / 2)),
          n.options.infinite === !0 &&
            (i >= e && i <= n.slideCount - 1 - e
              ? n.$slides
                  .slice(i - e + r, i + e + 1)
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false')
              : ((o = n.options.slidesToShow + i),
                t
                  .slice(o - e + 1 + r, o + e + 2)
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false')),
            0 === i
              ? t
                  .eq(t.length - 1 - n.options.slidesToShow)
                  .addClass('slick-center')
              : i === n.slideCount - 1 &&
                t.eq(n.options.slidesToShow).addClass('slick-center')),
          n.$slides.eq(i).addClass('slick-center');
      } else
        i >= 0 && i <= n.slideCount - n.options.slidesToShow
          ? n.$slides
              .slice(i, i + n.options.slidesToShow)
              .addClass('slick-active')
              .attr('aria-hidden', 'false')
          : t.length <= n.options.slidesToShow
          ? t.addClass('slick-active').attr('aria-hidden', 'false')
          : ((s = n.slideCount % n.options.slidesToShow),
            (o = n.options.infinite === !0 ? n.options.slidesToShow + i : i),
            n.options.slidesToShow == n.options.slidesToScroll &&
            n.slideCount - i < n.options.slidesToShow
              ? t
                  .slice(o - (n.options.slidesToShow - s), o + s)
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false')
              : t
                  .slice(o, o + n.options.slidesToShow)
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false'));
      ('ondemand' !== n.options.lazyLoad &&
        'anticipated' !== n.options.lazyLoad) ||
        n.lazyLoad();
    }),
    (e.prototype.setupInfinite = function () {
      var e,
        t,
        o,
        s = this;
      if (
        (s.options.fade === !0 && (s.options.centerMode = !1),
        s.options.infinite === !0 &&
          s.options.fade === !1 &&
          ((t = null), s.slideCount > s.options.slidesToShow))
      ) {
        for (
          o =
            s.options.centerMode === !0
              ? s.options.slidesToShow + 1
              : s.options.slidesToShow,
            e = s.slideCount;
          e > s.slideCount - o;
          e -= 1
        )
          (t = e - 1),
            i(s.$slides[t])
              .clone(!0)
              .attr('id', '')
              .attr('data-slick-index', t - s.slideCount)
              .prependTo(s.$slideTrack)
              .addClass('slick-cloned');
        for (e = 0; e < o + s.slideCount; e += 1)
          (t = e),
            i(s.$slides[t])
              .clone(!0)
              .attr('id', '')
              .attr('data-slick-index', t + s.slideCount)
              .appendTo(s.$slideTrack)
              .addClass('slick-cloned');
        s.$slideTrack
          .find('.slick-cloned')
          .find('[id]')
          .each(function () {
            i(this).attr('id', '');
          });
      }
    }),
    (e.prototype.interrupt = function (i) {
      var e = this;
      i || e.autoPlay(), (e.interrupted = i);
    }),
    (e.prototype.selectHandler = function (e) {
      var t = this,
        o = i(e.target).is('.slick-slide')
          ? i(e.target)
          : i(e.target).parents('.slick-slide'),
        s = parseInt(o.attr('data-slick-index'));
      return (
        s || (s = 0),
        t.slideCount <= t.options.slidesToShow
          ? void t.slideHandler(s, !1, !0)
          : void t.slideHandler(s)
      );
    }),
    (e.prototype.slideHandler = function (i, e, t) {
      var o,
        s,
        n,
        r,
        l,
        d = null,
        a = this;
      if (
        ((e = e || !1),
        !(
          (a.animating === !0 && a.options.waitForAnimate === !0) ||
          (a.options.fade === !0 && a.currentSlide === i)
        ))
      )
        return (
          e === !1 && a.asNavFor(i),
          (o = i),
          (d = a.getLeft(o)),
          (r = a.getLeft(a.currentSlide)),
          (a.currentLeft = null === a.swipeLeft ? r : a.swipeLeft),
          a.options.infinite === !1 &&
          a.options.centerMode === !1 &&
          (i < 0 || i > a.getDotCount() * a.options.slidesToScroll)
            ? void (
                a.options.fade === !1 &&
                ((o = a.currentSlide),
                t !== !0 && a.slideCount > a.options.slidesToShow
                  ? a.animateSlide(r, function () {
                      a.postSlide(o);
                    })
                  : a.postSlide(o))
              )
            : a.options.infinite === !1 &&
              a.options.centerMode === !0 &&
              (i < 0 || i > a.slideCount - a.options.slidesToScroll)
            ? void (
                a.options.fade === !1 &&
                ((o = a.currentSlide),
                t !== !0 && a.slideCount > a.options.slidesToShow
                  ? a.animateSlide(r, function () {
                      a.postSlide(o);
                    })
                  : a.postSlide(o))
              )
            : (a.options.autoplay && clearInterval(a.autoPlayTimer),
              (s =
                o < 0
                  ? a.slideCount % a.options.slidesToScroll !== 0
                    ? a.slideCount - (a.slideCount % a.options.slidesToScroll)
                    : a.slideCount + o
                  : o >= a.slideCount
                  ? a.slideCount % a.options.slidesToScroll !== 0
                    ? 0
                    : o - a.slideCount
                  : o),
              (a.animating = !0),
              a.$slider.trigger('beforeChange', [a, a.currentSlide, s]),
              (n = a.currentSlide),
              (a.currentSlide = s),
              a.setSlideClasses(a.currentSlide),
              a.options.asNavFor &&
                ((l = a.getNavTarget()),
                (l = l.slick('getSlick')),
                l.slideCount <= l.options.slidesToShow &&
                  l.setSlideClasses(a.currentSlide)),
              a.updateDots(),
              a.updateArrows(),
              a.options.fade === !0
                ? (t !== !0
                    ? (a.fadeSlideOut(n),
                      a.fadeSlide(s, function () {
                        a.postSlide(s);
                      }))
                    : a.postSlide(s),
                  void a.animateHeight())
                : void (t !== !0 && a.slideCount > a.options.slidesToShow
                    ? a.animateSlide(d, function () {
                        a.postSlide(s);
                      })
                    : a.postSlide(s)))
        );
    }),
    (e.prototype.startLoad = function () {
      var i = this;
      i.options.arrows === !0 &&
        i.slideCount > i.options.slidesToShow &&
        (i.$prevArrow.hide(), i.$nextArrow.hide()),
        i.options.dots === !0 &&
          i.slideCount > i.options.slidesToShow &&
          i.$dots.hide(),
        i.$slider.addClass('slick-loading');
    }),
    (e.prototype.swipeDirection = function () {
      var i,
        e,
        t,
        o,
        s = this;
      return (
        (i = s.touchObject.startX - s.touchObject.curX),
        (e = s.touchObject.startY - s.touchObject.curY),
        (t = Math.atan2(e, i)),
        (o = Math.round((180 * t) / Math.PI)),
        o < 0 && (o = 360 - Math.abs(o)),
        o <= 45 && o >= 0
          ? s.options.rtl === !1
            ? 'left'
            : 'right'
          : o <= 360 && o >= 315
          ? s.options.rtl === !1
            ? 'left'
            : 'right'
          : o >= 135 && o <= 225
          ? s.options.rtl === !1
            ? 'right'
            : 'left'
          : s.options.verticalSwiping === !0
          ? o >= 35 && o <= 135
            ? 'down'
            : 'up'
          : 'vertical'
      );
    }),
    (e.prototype.swipeEnd = function (i) {
      var e,
        t,
        o = this;
      if (((o.dragging = !1), (o.swiping = !1), o.scrolling))
        return (o.scrolling = !1), !1;
      if (
        ((o.interrupted = !1),
        (o.shouldClick = !(o.touchObject.swipeLength > 10)),
        void 0 === o.touchObject.curX)
      )
        return !1;
      if (
        (o.touchObject.edgeHit === !0 &&
          o.$slider.trigger('edge', [o, o.swipeDirection()]),
        o.touchObject.swipeLength >= o.touchObject.minSwipe)
      ) {
        switch ((t = o.swipeDirection())) {
          case 'left':
          case 'down':
            (e = o.options.swipeToSlide
              ? o.checkNavigable(o.currentSlide + o.getSlideCount())
              : o.currentSlide + o.getSlideCount()),
              (o.currentDirection = 0);
            break;
          case 'right':
          case 'up':
            (e = o.options.swipeToSlide
              ? o.checkNavigable(o.currentSlide - o.getSlideCount())
              : o.currentSlide - o.getSlideCount()),
              (o.currentDirection = 1);
        }
        'vertical' != t &&
          (o.slideHandler(e),
          (o.touchObject = {}),
          o.$slider.trigger('swipe', [o, t]));
      } else
        o.touchObject.startX !== o.touchObject.curX &&
          (o.slideHandler(o.currentSlide), (o.touchObject = {}));
    }),
    (e.prototype.swipeHandler = function (i) {
      var e = this;
      if (
        !(
          e.options.swipe === !1 ||
          ('ontouchend' in document && e.options.swipe === !1) ||
          (e.options.draggable === !1 && i.type.indexOf('mouse') !== -1)
        )
      )
        switch (
          ((e.touchObject.fingerCount =
            i.originalEvent && void 0 !== i.originalEvent.touches
              ? i.originalEvent.touches.length
              : 1),
          (e.touchObject.minSwipe = e.listWidth / e.options.touchThreshold),
          e.options.verticalSwiping === !0 &&
            (e.touchObject.minSwipe = e.listHeight / e.options.touchThreshold),
          i.data.action)
        ) {
          case 'start':
            e.swipeStart(i);
            break;
          case 'move':
            e.swipeMove(i);
            break;
          case 'end':
            e.swipeEnd(i);
        }
    }),
    (e.prototype.swipeMove = function (i) {
      var e,
        t,
        o,
        s,
        n,
        r,
        l = this;
      return (
        (n = void 0 !== i.originalEvent ? i.originalEvent.touches : null),
        !(!l.dragging || l.scrolling || (n && 1 !== n.length)) &&
          ((e = l.getLeft(l.currentSlide)),
          (l.touchObject.curX = void 0 !== n ? n[0].pageX : i.clientX),
          (l.touchObject.curY = void 0 !== n ? n[0].pageY : i.clientY),
          (l.touchObject.swipeLength = Math.round(
            Math.sqrt(Math.pow(l.touchObject.curX - l.touchObject.startX, 2))
          )),
          (r = Math.round(
            Math.sqrt(Math.pow(l.touchObject.curY - l.touchObject.startY, 2))
          )),
          !l.options.verticalSwiping && !l.swiping && r > 4
            ? ((l.scrolling = !0), !1)
            : (l.options.verticalSwiping === !0 &&
                (l.touchObject.swipeLength = r),
              (t = l.swipeDirection()),
              void 0 !== i.originalEvent &&
                l.touchObject.swipeLength > 4 &&
                ((l.swiping = !0), i.preventDefault()),
              (s =
                (l.options.rtl === !1 ? 1 : -1) *
                (l.touchObject.curX > l.touchObject.startX ? 1 : -1)),
              l.options.verticalSwiping === !0 &&
                (s = l.touchObject.curY > l.touchObject.startY ? 1 : -1),
              (o = l.touchObject.swipeLength),
              (l.touchObject.edgeHit = !1),
              l.options.infinite === !1 &&
                ((0 === l.currentSlide && 'right' === t) ||
                  (l.currentSlide >= l.getDotCount() && 'left' === t)) &&
                ((o = l.touchObject.swipeLength * l.options.edgeFriction),
                (l.touchObject.edgeHit = !0)),
              l.options.vertical === !1
                ? (l.swipeLeft = e + o * s)
                : (l.swipeLeft = e + o * (l.$list.height() / l.listWidth) * s),
              l.options.verticalSwiping === !0 && (l.swipeLeft = e + o * s),
              l.options.fade !== !0 &&
                l.options.touchMove !== !1 &&
                (l.animating === !0
                  ? ((l.swipeLeft = null), !1)
                  : void l.setCSS(l.swipeLeft))))
      );
    }),
    (e.prototype.swipeStart = function (i) {
      var e,
        t = this;
      return (
        (t.interrupted = !0),
        1 !== t.touchObject.fingerCount ||
        t.slideCount <= t.options.slidesToShow
          ? ((t.touchObject = {}), !1)
          : (void 0 !== i.originalEvent &&
              void 0 !== i.originalEvent.touches &&
              (e = i.originalEvent.touches[0]),
            (t.touchObject.startX = t.touchObject.curX =
              void 0 !== e ? e.pageX : i.clientX),
            (t.touchObject.startY = t.touchObject.curY =
              void 0 !== e ? e.pageY : i.clientY),
            void (t.dragging = !0))
      );
    }),
    (e.prototype.unfilterSlides = e.prototype.slickUnfilter =
      function () {
        var i = this;
        null !== i.$slidesCache &&
          (i.unload(),
          i.$slideTrack.children(this.options.slide).detach(),
          i.$slidesCache.appendTo(i.$slideTrack),
          i.reinit());
      }),
    (e.prototype.unload = function () {
      var e = this;
      i('.slick-cloned', e.$slider).remove(),
        e.$dots && e.$dots.remove(),
        e.$prevArrow &&
          e.htmlExpr.test(e.options.prevArrow) &&
          e.$prevArrow.remove(),
        e.$nextArrow &&
          e.htmlExpr.test(e.options.nextArrow) &&
          e.$nextArrow.remove(),
        e.$slides
          .removeClass('slick-slide slick-active slick-visible slick-current')
          .attr('aria-hidden', 'true')
          .css('width', '');
    }),
    (e.prototype.unslick = function (i) {
      var e = this;
      e.$slider.trigger('unslick', [e, i]), e.destroy();
    }),
    (e.prototype.updateArrows = function () {
      var i,
        e = this;
      (i = Math.floor(e.options.slidesToShow / 2)),
        e.options.arrows === !0 &&
          e.slideCount > e.options.slidesToShow &&
          !e.options.infinite &&
          (e.$prevArrow
            .removeClass('slick-disabled')
            .attr('aria-disabled', 'false'),
          e.$nextArrow
            .removeClass('slick-disabled')
            .attr('aria-disabled', 'false'),
          0 === e.currentSlide
            ? (e.$prevArrow
                .addClass('slick-disabled')
                .attr('aria-disabled', 'true'),
              e.$nextArrow
                .removeClass('slick-disabled')
                .attr('aria-disabled', 'false'))
            : e.currentSlide >= e.slideCount - e.options.slidesToShow &&
              e.options.centerMode === !1
            ? (e.$nextArrow
                .addClass('slick-disabled')
                .attr('aria-disabled', 'true'),
              e.$prevArrow
                .removeClass('slick-disabled')
                .attr('aria-disabled', 'false'))
            : e.currentSlide >= e.slideCount - 1 &&
              e.options.centerMode === !0 &&
              (e.$nextArrow
                .addClass('slick-disabled')
                .attr('aria-disabled', 'true'),
              e.$prevArrow
                .removeClass('slick-disabled')
                .attr('aria-disabled', 'false')));
    }),
    (e.prototype.updateDots = function () {
      var i = this;
      null !== i.$dots &&
        (i.$dots.find('li').removeClass('slick-active').end(),
        i.$dots
          .find('li')
          .eq(Math.floor(i.currentSlide / i.options.slidesToScroll))
          .addClass('slick-active'));
    }),
    (e.prototype.visibility = function () {
      var i = this;
      i.options.autoplay &&
        (document[i.hidden] ? (i.interrupted = !0) : (i.interrupted = !1));
    }),
    (i.fn.slick = function () {
      var i,
        t,
        o = this,
        s = arguments[0],
        n = Array.prototype.slice.call(arguments, 1),
        r = o.length;
      for (i = 0; i < r; i++)
        if (
          ('object' == typeof s || 'undefined' == typeof s
            ? (o[i].slick = new e(o[i], s))
            : (t = o[i].slick[s].apply(o[i].slick, n)),
          'undefined' != typeof t)
        )
          return t;
      return o;
    });
});
!(function (t, e, n, o) {
  'use strict';
  function i(t, e) {
    var o,
      i,
      a,
      s = [],
      r = 0;
    (t && t.isDefaultPrevented()) ||
      (t.preventDefault(),
      (e = e || {}),
      t && t.data && (e = h(t.data.options, e)),
      (o = e.$target || n(t.currentTarget).trigger('blur')),
      ((a = n.fancybox.getInstance()) && a.$trigger && a.$trigger.is(o)) ||
        (e.selector
          ? (s = n(e.selector))
          : ((i = o.attr('data-fancybox') || ''),
            i
              ? ((s = t.data ? t.data.items : []),
                (s = s.length
                  ? s.filter('[data-fancybox="' + i + '"]')
                  : n('[data-fancybox="' + i + '"]')))
              : (s = [o])),
        (r = n(s).index(o)),
        r < 0 && (r = 0),
        (a = n.fancybox.open(s, e, r)),
        (a.$trigger = o)));
  }
  if (((t.console = t.console || { info: function (t) {} }), n)) {
    if (n.fn.fancybox) return void console.info('fancyBox already initialized');
    var a = {
        closeExisting: !1,
        loop: !1,
        gutter: 50,
        keyboard: !0,
        preventCaptionOverlap: !0,
        arrows: !0,
        infobar: !0,
        smallBtn: 'auto',
        toolbar: 'auto',
        buttons: ['zoom', 'slideShow', 'thumbs', 'close'],
        idleTime: 3,
        protect: !1,
        modal: !1,
        image: { preload: !1 },
        ajax: { settings: { data: { fancybox: !0 } } },
        iframe: {
          tpl: '<iframe id="fancybox-frame{rnd}" name="fancybox-frame{rnd}" class="fancybox-iframe" allowfullscreen="allowfullscreen" allow="autoplay; fullscreen" src=""></iframe>',
          preload: !0,
          css: {},
          attr: { scrolling: 'auto' },
        },
        video: {
          tpl: '<video class="fancybox-video" controls controlsList="nodownload" poster="{{poster}}"><source src="{{src}}" type="{{format}}" />Sorry, your browser doesn\'t support embedded videos, <a href="{{src}}">download</a> and watch with your favorite video player!</video>',
          format: '',
          autoStart: !0,
        },
        defaultType: 'image',
        animationEffect: 'zoom',
        animationDuration: 366,
        zoomOpacity: 'auto',
        transitionEffect: 'fade',
        transitionDuration: 366,
        slideClass: '',
        baseClass: '',
        baseTpl:
          '<div class="fancybox-container" role="dialog" tabindex="-1"><div class="fancybox-bg"></div><div class="fancybox-inner"><div class="fancybox-infobar"><span data-fancybox-index></span>&nbsp;/&nbsp;<span data-fancybox-count></span></div><div class="fancybox-toolbar">{{buttons}}</div><div class="fancybox-navigation">{{arrows}}</div><div class="fancybox-stage"></div><div class="fancybox-caption"><div class="fancybox-caption__body"></div></div></div></div>',
        spinnerTpl: '<div class="fancybox-loading"></div>',
        errorTpl: '<div class="fancybox-error"><p>{{ERROR}}</p></div>',
        btnTpl: {
          download:
            '<a download data-fancybox-download class="fancybox-button fancybox-button--download" title="{{DOWNLOAD}}" href="javascript:;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.62 17.09V19H5.38v-1.91zm-2.97-6.96L17 11.45l-5 4.87-5-4.87 1.36-1.32 2.68 2.64V5h1.92v7.77z"/></svg></a>',
          zoom: '<button data-fancybox-zoom class="fancybox-button fancybox-button--zoom" title="{{ZOOM}}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.7 17.3l-3-3a5.9 5.9 0 0 0-.6-7.6 5.9 5.9 0 0 0-8.4 0 5.9 5.9 0 0 0 0 8.4 5.9 5.9 0 0 0 7.7.7l3 3a1 1 0 0 0 1.3 0c.4-.5.4-1 0-1.5zM8.1 13.8a4 4 0 0 1 0-5.7 4 4 0 0 1 5.7 0 4 4 0 0 1 0 5.7 4 4 0 0 1-5.7 0z"/></svg></button>',
          close:
            '<button data-fancybox-close class="fancybox-button fancybox-button--close" title="{{CLOSE}}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12 10.6L6.6 5.2 5.2 6.6l5.4 5.4-5.4 5.4 1.4 1.4 5.4-5.4 5.4 5.4 1.4-1.4-5.4-5.4 5.4-5.4-1.4-1.4-5.4 5.4z"/></svg></button>',
          arrowLeft:
            '<button data-fancybox-prev class="fancybox-button fancybox-button--arrow_left" title="{{PREV}}"><div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M11.28 15.7l-1.34 1.37L5 12l4.94-5.07 1.34 1.38-2.68 2.72H19v1.94H8.6z"/></svg></div></button>',
          arrowRight:
            '<button data-fancybox-next class="fancybox-button fancybox-button--arrow_right" title="{{NEXT}}"><div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M15.4 12.97l-2.68 2.72 1.34 1.38L19 12l-4.94-5.07-1.34 1.38 2.68 2.72H5v1.94z"/></svg></div></button>',
          smallBtn:
            '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}"><svg xmlns="http://www.w3.org/2000/svg" version="1" viewBox="0 0 24 24"><path d="M13 12l5-5-1-1-5 5-5-5-1 1 5 5-5 5 1 1 5-5 5 5 1-1z"/></svg></button>',
        },
        parentEl: 'body',
        hideScrollbar: !0,
        autoFocus: !0,
        backFocus: !0,
        trapFocus: !0,
        fullScreen: { autoStart: !1 },
        touch: { vertical: !0, momentum: !0 },
        hash: null,
        media: {},
        slideShow: { autoStart: !1, speed: 3e3 },
        thumbs: {
          autoStart: !1,
          hideOnClose: !0,
          parentEl: '.fancybox-container',
          axis: 'y',
        },
        wheel: 'auto',
        onInit: n.noop,
        beforeLoad: n.noop,
        afterLoad: n.noop,
        beforeShow: n.noop,
        afterShow: n.noop,
        beforeClose: n.noop,
        afterClose: n.noop,
        onActivate: n.noop,
        onDeactivate: n.noop,
        clickContent: function (t, e) {
          return 'image' === t.type && 'zoom';
        },
        clickSlide: 'close',
        clickOutside: 'close',
        dblclickContent: !1,
        dblclickSlide: !1,
        dblclickOutside: !1,
        mobile: {
          preventCaptionOverlap: !1,
          idleTime: !1,
          clickContent: function (t, e) {
            return 'image' === t.type && 'toggleControls';
          },
          clickSlide: function (t, e) {
            return 'image' === t.type ? 'toggleControls' : 'close';
          },
          dblclickContent: function (t, e) {
            return 'image' === t.type && 'zoom';
          },
          dblclickSlide: function (t, e) {
            return 'image' === t.type && 'zoom';
          },
        },
        lang: 'en',
        i18n: {
          en: {
            CLOSE: 'Close',
            NEXT: 'Next',
            PREV: 'Previous',
            ERROR:
              'The requested content cannot be loaded. <br/> Please try again later.',
            PLAY_START: 'Start slideshow',
            PLAY_STOP: 'Pause slideshow',
            FULL_SCREEN: 'Full screen',
            THUMBS: 'Thumbnails',
            DOWNLOAD: 'Download',
            SHARE: 'Share',
            ZOOM: 'Zoom',
          },
          de: {
            CLOSE: 'Schlie&szlig;en',
            NEXT: 'Weiter',
            PREV: 'Zur&uuml;ck',
            ERROR:
              'Die angeforderten Daten konnten nicht geladen werden. <br/> Bitte versuchen Sie es sp&auml;ter nochmal.',
            PLAY_START: 'Diaschau starten',
            PLAY_STOP: 'Diaschau beenden',
            FULL_SCREEN: 'Vollbild',
            THUMBS: 'Vorschaubilder',
            DOWNLOAD: 'Herunterladen',
            SHARE: 'Teilen',
            ZOOM: 'Vergr&ouml;&szlig;ern',
          },
        },
      },
      s = n(t),
      r = n(e),
      c = 0,
      l = function (t) {
        return t && t.hasOwnProperty && t instanceof n;
      },
      d = (function () {
        return (
          t.requestAnimationFrame ||
          t.webkitRequestAnimationFrame ||
          t.mozRequestAnimationFrame ||
          t.oRequestAnimationFrame ||
          function (e) {
            return t.setTimeout(e, 1e3 / 60);
          }
        );
      })(),
      u = (function () {
        return (
          t.cancelAnimationFrame ||
          t.webkitCancelAnimationFrame ||
          t.mozCancelAnimationFrame ||
          t.oCancelAnimationFrame ||
          function (e) {
            t.clearTimeout(e);
          }
        );
      })(),
      f = (function () {
        var t,
          n = e.createElement('fakeelement'),
          o = {
            transition: 'transitionend',
            OTransition: 'oTransitionEnd',
            MozTransition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd',
          };
        for (t in o) if (void 0 !== n.style[t]) return o[t];
        return 'transitionend';
      })(),
      p = function (t) {
        return t && t.length && t[0].offsetHeight;
      },
      h = function (t, e) {
        var o = n.extend(!0, {}, t, e);
        return (
          n.each(e, function (t, e) {
            n.isArray(e) && (o[t] = e);
          }),
          o
        );
      },
      g = function (t) {
        var o, i;
        return (
          !(!t || t.ownerDocument !== e) &&
          (n('.fancybox-container').css('pointer-events', 'none'),
          (o = {
            x: t.getBoundingClientRect().left + t.offsetWidth / 2,
            y: t.getBoundingClientRect().top + t.offsetHeight / 2,
          }),
          (i = e.elementFromPoint(o.x, o.y) === t),
          n('.fancybox-container').css('pointer-events', ''),
          i)
        );
      },
      b = function (t, e, o) {
        var i = this;
        (i.opts = h({ index: o }, n.fancybox.defaults)),
          n.isPlainObject(e) && (i.opts = h(i.opts, e)),
          n.fancybox.isMobile && (i.opts = h(i.opts, i.opts.mobile)),
          (i.id = i.opts.id || ++c),
          (i.currIndex = parseInt(i.opts.index, 10) || 0),
          (i.prevIndex = null),
          (i.prevPos = null),
          (i.currPos = 0),
          (i.firstRun = !0),
          (i.group = []),
          (i.slides = {}),
          i.addContent(t),
          i.group.length && i.init();
      };
    n.extend(b.prototype, {
      init: function () {
        var o,
          i,
          a = this,
          s = a.group[a.currIndex],
          r = s.opts;
        r.closeExisting && n.fancybox.close(!0),
          n('body').addClass('fancybox-active'),
          !n.fancybox.getInstance() &&
            !1 !== r.hideScrollbar &&
            !n.fancybox.isMobile &&
            e.body.scrollHeight > t.innerHeight &&
            (n('head').append(
              '<style id="fancybox-style-noscroll" type="text/css">.compensate-for-scrollbar{margin-right:' +
                (t.innerWidth - e.documentElement.clientWidth) +
                'px;}</style>'
            ),
            n('body').addClass('compensate-for-scrollbar')),
          (i = ''),
          n.each(r.buttons, function (t, e) {
            i += r.btnTpl[e] || '';
          }),
          (o = n(
            a.translate(
              a,
              r.baseTpl
                .replace('{{buttons}}', i)
                .replace('{{arrows}}', r.btnTpl.arrowLeft + r.btnTpl.arrowRight)
            )
          )
            .attr('id', 'fancybox-container-' + a.id)
            .addClass(r.baseClass)
            .data('FancyBox', a)
            .appendTo(r.parentEl)),
          (a.$refs = { container: o }),
          [
            'bg',
            'inner',
            'infobar',
            'toolbar',
            'stage',
            'caption',
            'navigation',
          ].forEach(function (t) {
            a.$refs[t] = o.find('.fancybox-' + t);
          }),
          a.trigger('onInit'),
          a.activate(),
          a.jumpTo(a.currIndex);
      },
      translate: function (t, e) {
        var n = t.opts.i18n[t.opts.lang] || t.opts.i18n.en;
        return e.replace(/\{\{(\w+)\}\}/g, function (t, e) {
          return void 0 === n[e] ? t : n[e];
        });
      },
      addContent: function (t) {
        var e,
          o = this,
          i = n.makeArray(t);
        n.each(i, function (t, e) {
          var i,
            a,
            s,
            r,
            c,
            l = {},
            d = {};
          n.isPlainObject(e)
            ? ((l = e), (d = e.opts || e))
            : 'object' === n.type(e) && n(e).length
            ? ((i = n(e)),
              (d = i.data() || {}),
              (d = n.extend(!0, {}, d, d.options)),
              (d.$orig = i),
              (l.src = o.opts.src || d.src || i.attr('href')),
              l.type || l.src || ((l.type = 'inline'), (l.src = e)))
            : (l = { type: 'html', src: e + '' }),
            (l.opts = n.extend(!0, {}, o.opts, d)),
            n.isArray(d.buttons) && (l.opts.buttons = d.buttons),
            n.fancybox.isMobile &&
              l.opts.mobile &&
              (l.opts = h(l.opts, l.opts.mobile)),
            (a = l.type || l.opts.type),
            (r = l.src || ''),
            !a &&
              r &&
              ((s = r.match(/\.(mp4|mov|ogv|webm)((\?|#).*)?$/i))
                ? ((a = 'video'),
                  l.opts.video.format ||
                    (l.opts.video.format =
                      'video/' + ('ogv' === s[1] ? 'ogg' : s[1])))
                : r.match(
                    /(^data:image\/[a-z0-9+\/=]*,)|(\.(jp(e|g|eg)|gif|png|bmp|webp|svg|ico)((\?|#).*)?$)/i
                  )
                ? (a = 'image')
                : r.match(/\.(pdf)((\?|#).*)?$/i)
                ? ((a = 'iframe'),
                  (l = n.extend(!0, l, {
                    contentType: 'pdf',
                    opts: { iframe: { preload: !1 } },
                  })))
                : '#' === r.charAt(0) && (a = 'inline')),
            a ? (l.type = a) : o.trigger('objectNeedsType', l),
            l.contentType ||
              (l.contentType =
                n.inArray(l.type, ['html', 'inline', 'ajax']) > -1
                  ? 'html'
                  : l.type),
            (l.index = o.group.length),
            'auto' == l.opts.smallBtn &&
              (l.opts.smallBtn =
                n.inArray(l.type, ['html', 'inline', 'ajax']) > -1),
            'auto' === l.opts.toolbar && (l.opts.toolbar = !l.opts.smallBtn),
            (l.$thumb = l.opts.$thumb || null),
            l.opts.$trigger &&
              l.index === o.opts.index &&
              ((l.$thumb = l.opts.$trigger.find('img:first')),
              l.$thumb.length && (l.opts.$orig = l.opts.$trigger)),
            (l.$thumb && l.$thumb.length) ||
              !l.opts.$orig ||
              (l.$thumb = l.opts.$orig.find('img:first')),
            l.$thumb && !l.$thumb.length && (l.$thumb = null),
            (l.thumb = l.opts.thumb || (l.$thumb ? l.$thumb[0].src : null)),
            'function' === n.type(l.opts.caption) &&
              (l.opts.caption = l.opts.caption.apply(e, [o, l])),
            'function' === n.type(o.opts.caption) &&
              (l.opts.caption = o.opts.caption.apply(e, [o, l])),
            l.opts.caption instanceof n ||
              (l.opts.caption =
                void 0 === l.opts.caption ? '' : l.opts.caption + ''),
            'ajax' === l.type &&
              ((c = r.split(/\s+/, 2)),
              c.length > 1 &&
                ((l.src = c.shift()), (l.opts.filter = c.shift()))),
            l.opts.modal &&
              (l.opts = n.extend(!0, l.opts, {
                trapFocus: !0,
                infobar: 0,
                toolbar: 0,
                smallBtn: 0,
                keyboard: 0,
                slideShow: 0,
                fullScreen: 0,
                thumbs: 0,
                touch: 0,
                clickContent: !1,
                clickSlide: !1,
                clickOutside: !1,
                dblclickContent: !1,
                dblclickSlide: !1,
                dblclickOutside: !1,
              })),
            o.group.push(l);
        }),
          Object.keys(o.slides).length &&
            (o.updateControls(),
            (e = o.Thumbs) && e.isActive && (e.create(), e.focus()));
      },
      addEvents: function () {
        var e = this;
        e.removeEvents(),
          e.$refs.container
            .on('click.fb-close', '[data-fancybox-close]', function (t) {
              t.stopPropagation(), t.preventDefault(), e.close(t);
            })
            .on(
              'touchstart.fb-prev click.fb-prev',
              '[data-fancybox-prev]',
              function (t) {
                t.stopPropagation(), t.preventDefault(), e.previous();
              }
            )
            .on(
              'touchstart.fb-next click.fb-next',
              '[data-fancybox-next]',
              function (t) {
                t.stopPropagation(), t.preventDefault(), e.next();
              }
            )
            .on('click.fb', '[data-fancybox-zoom]', function (t) {
              e[e.isScaledDown() ? 'scaleToActual' : 'scaleToFit']();
            }),
          s.on('orientationchange.fb resize.fb', function (t) {
            t && t.originalEvent && 'resize' === t.originalEvent.type
              ? (e.requestId && u(e.requestId),
                (e.requestId = d(function () {
                  e.update(t);
                })))
              : (e.current &&
                  'iframe' === e.current.type &&
                  e.$refs.stage.hide(),
                setTimeout(
                  function () {
                    e.$refs.stage.show(), e.update(t);
                  },
                  n.fancybox.isMobile ? 600 : 250
                ));
          }),
          r.on('keydown.fb', function (t) {
            var o = n.fancybox ? n.fancybox.getInstance() : null,
              i = o.current,
              a = t.keyCode || t.which;
            return 9 == a
              ? void (i.opts.trapFocus && e.focus(t))
              : !i.opts.keyboard ||
                t.ctrlKey ||
                t.altKey ||
                t.shiftKey ||
                n(t.target).is('input,textarea,video,audio,select')
              ? void 0
              : 8 === a || 27 === a
              ? (t.preventDefault(), void e.close(t))
              : 37 === a || 38 === a
              ? (t.preventDefault(), void e.previous())
              : 39 === a || 40 === a
              ? (t.preventDefault(), void e.next())
              : void e.trigger('afterKeydown', t, a);
          }),
          e.group[e.currIndex].opts.idleTime &&
            ((e.idleSecondsCounter = 0),
            r.on(
              'mousemove.fb-idle mouseleave.fb-idle mousedown.fb-idle touchstart.fb-idle touchmove.fb-idle scroll.fb-idle keydown.fb-idle',
              function (t) {
                (e.idleSecondsCounter = 0),
                  e.isIdle && e.showControls(),
                  (e.isIdle = !1);
              }
            ),
            (e.idleInterval = t.setInterval(function () {
              ++e.idleSecondsCounter >= e.group[e.currIndex].opts.idleTime &&
                !e.isDragging &&
                ((e.isIdle = !0), (e.idleSecondsCounter = 0), e.hideControls());
            }, 1e3)));
      },
      removeEvents: function () {
        var e = this;
        s.off('orientationchange.fb resize.fb'),
          r.off('keydown.fb .fb-idle'),
          this.$refs.container.off('.fb-close .fb-prev .fb-next'),
          e.idleInterval &&
            (t.clearInterval(e.idleInterval), (e.idleInterval = null));
      },
      previous: function (t) {
        return this.jumpTo(this.currPos - 1, t);
      },
      next: function (t) {
        return this.jumpTo(this.currPos + 1, t);
      },
      jumpTo: function (t, e) {
        var o,
          i,
          a,
          s,
          r,
          c,
          l,
          d,
          u,
          f = this,
          h = f.group.length;
        if (!(f.isDragging || f.isClosing || (f.isAnimating && f.firstRun))) {
          if (
            ((t = parseInt(t, 10)),
            !(a = f.current ? f.current.opts.loop : f.opts.loop) &&
              (t < 0 || t >= h))
          )
            return !1;
          if (
            ((o = f.firstRun = !Object.keys(f.slides).length),
            (r = f.current),
            (f.prevIndex = f.currIndex),
            (f.prevPos = f.currPos),
            (s = f.createSlide(t)),
            h > 1 &&
              ((a || s.index < h - 1) && f.createSlide(t + 1),
              (a || s.index > 0) && f.createSlide(t - 1)),
            (f.current = s),
            (f.currIndex = s.index),
            (f.currPos = s.pos),
            f.trigger('beforeShow', o),
            f.updateControls(),
            (s.forcedDuration = void 0),
            n.isNumeric(e)
              ? (s.forcedDuration = e)
              : (e = s.opts[o ? 'animationDuration' : 'transitionDuration']),
            (e = parseInt(e, 10)),
            (i = f.isMoved(s)),
            s.$slide.addClass('fancybox-slide--current'),
            o)
          )
            return (
              s.opts.animationEffect &&
                e &&
                f.$refs.container.css('transition-duration', e + 'ms'),
              f.$refs.container.addClass('fancybox-is-open').trigger('focus'),
              f.loadSlide(s),
              void f.preload('image')
            );
          (c = n.fancybox.getTranslate(r.$slide)),
            (l = n.fancybox.getTranslate(f.$refs.stage)),
            n.each(f.slides, function (t, e) {
              n.fancybox.stop(e.$slide, !0);
            }),
            r.pos !== s.pos && (r.isComplete = !1),
            r.$slide.removeClass(
              'fancybox-slide--complete fancybox-slide--current'
            ),
            i
              ? ((u = c.left - (r.pos * c.width + r.pos * r.opts.gutter)),
                n.each(f.slides, function (t, o) {
                  o.$slide
                    .removeClass('fancybox-animated')
                    .removeClass(function (t, e) {
                      return (e.match(/(^|\s)fancybox-fx-\S+/g) || []).join(
                        ' '
                      );
                    });
                  var i = o.pos * c.width + o.pos * o.opts.gutter;
                  n.fancybox.setTranslate(o.$slide, {
                    top: 0,
                    left: i - l.left + u,
                  }),
                    o.pos !== s.pos &&
                      o.$slide.addClass(
                        'fancybox-slide--' +
                          (o.pos > s.pos ? 'next' : 'previous')
                      ),
                    p(o.$slide),
                    n.fancybox.animate(
                      o.$slide,
                      {
                        top: 0,
                        left:
                          (o.pos - s.pos) * c.width +
                          (o.pos - s.pos) * o.opts.gutter,
                      },
                      e,
                      function () {
                        o.$slide
                          .css({ transform: '', opacity: '' })
                          .removeClass(
                            'fancybox-slide--next fancybox-slide--previous'
                          ),
                          o.pos === f.currPos && f.complete();
                      }
                    );
                }))
              : e &&
                s.opts.transitionEffect &&
                ((d =
                  'fancybox-animated fancybox-fx-' + s.opts.transitionEffect),
                r.$slide.addClass(
                  'fancybox-slide--' + (r.pos > s.pos ? 'next' : 'previous')
                ),
                n.fancybox.animate(
                  r.$slide,
                  d,
                  e,
                  function () {
                    r.$slide
                      .removeClass(d)
                      .removeClass(
                        'fancybox-slide--next fancybox-slide--previous'
                      );
                  },
                  !1
                )),
            s.isLoaded ? f.revealContent(s) : f.loadSlide(s),
            f.preload('image');
        }
      },
      createSlide: function (t) {
        var e,
          o,
          i = this;
        return (
          (o = t % i.group.length),
          (o = o < 0 ? i.group.length + o : o),
          !i.slides[t] &&
            i.group[o] &&
            ((e = n('<div class="fancybox-slide"></div>').appendTo(
              i.$refs.stage
            )),
            (i.slides[t] = n.extend(!0, {}, i.group[o], {
              pos: t,
              $slide: e,
              isLoaded: !1,
            })),
            i.updateSlide(i.slides[t])),
          i.slides[t]
        );
      },
      scaleToActual: function (t, e, o) {
        var i,
          a,
          s,
          r,
          c,
          l = this,
          d = l.current,
          u = d.$content,
          f = n.fancybox.getTranslate(d.$slide).width,
          p = n.fancybox.getTranslate(d.$slide).height,
          h = d.width,
          g = d.height;
        l.isAnimating ||
          l.isMoved() ||
          !u ||
          'image' != d.type ||
          !d.isLoaded ||
          d.hasError ||
          ((l.isAnimating = !0),
          n.fancybox.stop(u),
          (t = void 0 === t ? 0.5 * f : t),
          (e = void 0 === e ? 0.5 * p : e),
          (i = n.fancybox.getTranslate(u)),
          (i.top -= n.fancybox.getTranslate(d.$slide).top),
          (i.left -= n.fancybox.getTranslate(d.$slide).left),
          (r = h / i.width),
          (c = g / i.height),
          (a = 0.5 * f - 0.5 * h),
          (s = 0.5 * p - 0.5 * g),
          h > f &&
            ((a = i.left * r - (t * r - t)),
            a > 0 && (a = 0),
            a < f - h && (a = f - h)),
          g > p &&
            ((s = i.top * c - (e * c - e)),
            s > 0 && (s = 0),
            s < p - g && (s = p - g)),
          l.updateCursor(h, g),
          n.fancybox.animate(
            u,
            { top: s, left: a, scaleX: r, scaleY: c },
            o || 366,
            function () {
              l.isAnimating = !1;
            }
          ),
          l.SlideShow && l.SlideShow.isActive && l.SlideShow.stop());
      },
      scaleToFit: function (t) {
        var e,
          o = this,
          i = o.current,
          a = i.$content;
        o.isAnimating ||
          o.isMoved() ||
          !a ||
          'image' != i.type ||
          !i.isLoaded ||
          i.hasError ||
          ((o.isAnimating = !0),
          n.fancybox.stop(a),
          (e = o.getFitPos(i)),
          o.updateCursor(e.width, e.height),
          n.fancybox.animate(
            a,
            {
              top: e.top,
              left: e.left,
              scaleX: e.width / a.width(),
              scaleY: e.height / a.height(),
            },
            t || 366,
            function () {
              o.isAnimating = !1;
            }
          ));
      },
      getFitPos: function (t) {
        var e,
          o,
          i,
          a,
          s = this,
          r = t.$content,
          c = t.$slide,
          l = t.width || t.opts.width,
          d = t.height || t.opts.height,
          u = {};
        return (
          !!(t.isLoaded && r && r.length) &&
          ((e = n.fancybox.getTranslate(s.$refs.stage).width),
          (o = n.fancybox.getTranslate(s.$refs.stage).height),
          (e -=
            parseFloat(c.css('paddingLeft')) +
            parseFloat(c.css('paddingRight')) +
            parseFloat(r.css('marginLeft')) +
            parseFloat(r.css('marginRight'))),
          (o -=
            parseFloat(c.css('paddingTop')) +
            parseFloat(c.css('paddingBottom')) +
            parseFloat(r.css('marginTop')) +
            parseFloat(r.css('marginBottom'))),
          (l && d) || ((l = e), (d = o)),
          (i = Math.min(1, e / l, o / d)),
          (l *= i),
          (d *= i),
          l > e - 0.5 && (l = e),
          d > o - 0.5 && (d = o),
          'image' === t.type
            ? ((u.top =
                Math.floor(0.5 * (o - d)) + parseFloat(c.css('paddingTop'))),
              (u.left =
                Math.floor(0.5 * (e - l)) + parseFloat(c.css('paddingLeft'))))
            : 'video' === t.contentType &&
              ((a =
                t.opts.width && t.opts.height ? l / d : t.opts.ratio || 16 / 9),
              d > l / a ? (d = l / a) : l > d * a && (l = d * a)),
          (u.width = l),
          (u.height = d),
          u)
        );
      },
      update: function (t) {
        var e = this;
        n.each(e.slides, function (n, o) {
          e.updateSlide(o, t);
        });
      },
      updateSlide: function (t, e) {
        var o = this,
          i = t && t.$content,
          a = t.width || t.opts.width,
          s = t.height || t.opts.height,
          r = t.$slide;
        o.adjustCaption(t),
          i &&
            (a || s || 'video' === t.contentType) &&
            !t.hasError &&
            (n.fancybox.stop(i),
            n.fancybox.setTranslate(i, o.getFitPos(t)),
            t.pos === o.currPos && ((o.isAnimating = !1), o.updateCursor())),
          o.adjustLayout(t),
          r.length &&
            (r.trigger('refresh'),
            t.pos === o.currPos &&
              o.$refs.toolbar
                .add(o.$refs.navigation.find('.fancybox-button--arrow_right'))
                .toggleClass(
                  'compensate-for-scrollbar',
                  r.get(0).scrollHeight > r.get(0).clientHeight
                )),
          o.trigger('onUpdate', t, e);
      },
      centerSlide: function (t) {
        var e = this,
          o = e.current,
          i = o.$slide;
        !e.isClosing &&
          o &&
          (i.siblings().css({ transform: '', opacity: '' }),
          i
            .parent()
            .children()
            .removeClass('fancybox-slide--previous fancybox-slide--next'),
          n.fancybox.animate(
            i,
            { top: 0, left: 0, opacity: 1 },
            void 0 === t ? 0 : t,
            function () {
              i.css({ transform: '', opacity: '' }),
                o.isComplete || e.complete();
            },
            !1
          ));
      },
      isMoved: function (t) {
        var e,
          o,
          i = t || this.current;
        return (
          !!i &&
          ((o = n.fancybox.getTranslate(this.$refs.stage)),
          (e = n.fancybox.getTranslate(i.$slide)),
          !i.$slide.hasClass('fancybox-animated') &&
            (Math.abs(e.top - o.top) > 0.5 || Math.abs(e.left - o.left) > 0.5))
        );
      },
      updateCursor: function (t, e) {
        var o,
          i,
          a = this,
          s = a.current,
          r = a.$refs.container;
        s &&
          !a.isClosing &&
          a.Guestures &&
          (r.removeClass(
            'fancybox-is-zoomable fancybox-can-zoomIn fancybox-can-zoomOut fancybox-can-swipe fancybox-can-pan'
          ),
          (o = a.canPan(t, e)),
          (i = !!o || a.isZoomable()),
          r.toggleClass('fancybox-is-zoomable', i),
          n('[data-fancybox-zoom]').prop('disabled', !i),
          o
            ? r.addClass('fancybox-can-pan')
            : i &&
              ('zoom' === s.opts.clickContent ||
                (n.isFunction(s.opts.clickContent) &&
                  'zoom' == s.opts.clickContent(s)))
            ? r.addClass('fancybox-can-zoomIn')
            : s.opts.touch &&
              (s.opts.touch.vertical || a.group.length > 1) &&
              'video' !== s.contentType &&
              r.addClass('fancybox-can-swipe'));
      },
      isZoomable: function () {
        var t,
          e = this,
          n = e.current;
        if (n && !e.isClosing && 'image' === n.type && !n.hasError) {
          if (!n.isLoaded) return !0;
          if (
            (t = e.getFitPos(n)) &&
            (n.width > t.width || n.height > t.height)
          )
            return !0;
        }
        return !1;
      },
      isScaledDown: function (t, e) {
        var o = this,
          i = !1,
          a = o.current,
          s = a.$content;
        return (
          void 0 !== t && void 0 !== e
            ? (i = t < a.width && e < a.height)
            : s &&
              ((i = n.fancybox.getTranslate(s)),
              (i = i.width < a.width && i.height < a.height)),
          i
        );
      },
      canPan: function (t, e) {
        var o = this,
          i = o.current,
          a = null,
          s = !1;
        return (
          'image' === i.type &&
            (i.isComplete || (t && e)) &&
            !i.hasError &&
            ((s = o.getFitPos(i)),
            void 0 !== t && void 0 !== e
              ? (a = { width: t, height: e })
              : i.isComplete && (a = n.fancybox.getTranslate(i.$content)),
            a &&
              s &&
              (s =
                Math.abs(a.width - s.width) > 1.5 ||
                Math.abs(a.height - s.height) > 1.5)),
          s
        );
      },
      loadSlide: function (t) {
        var e,
          o,
          i,
          a = this;
        if (!t.isLoading && !t.isLoaded) {
          if (((t.isLoading = !0), !1 === a.trigger('beforeLoad', t)))
            return (t.isLoading = !1), !1;
          switch (
            ((e = t.type),
            (o = t.$slide),
            o.off('refresh').trigger('onReset').addClass(t.opts.slideClass),
            e)
          ) {
            case 'image':
              a.setImage(t);
              break;
            case 'iframe':
              a.setIframe(t);
              break;
            case 'html':
              a.setContent(t, t.src || t.content);
              break;
            case 'video':
              a.setContent(
                t,
                t.opts.video.tpl
                  .replace(/\{\{src\}\}/gi, t.src)
                  .replace(
                    '{{format}}',
                    t.opts.videoFormat || t.opts.video.format || ''
                  )
                  .replace('{{poster}}', t.thumb || '')
              );
              break;
            case 'inline':
              n(t.src).length ? a.setContent(t, n(t.src)) : a.setError(t);
              break;
            case 'ajax':
              a.showLoading(t),
                (i = n.ajax(
                  n.extend({}, t.opts.ajax.settings, {
                    url: t.src,
                    success: function (e, n) {
                      'success' === n && a.setContent(t, e);
                    },
                    error: function (e, n) {
                      e && 'abort' !== n && a.setError(t);
                    },
                  })
                )),
                o.one('onReset', function () {
                  i.abort();
                });
              break;
            default:
              a.setError(t);
          }
          return !0;
        }
      },
      setImage: function (t) {
        var o,
          i = this;
        setTimeout(function () {
          var e = t.$image;
          i.isClosing ||
            !t.isLoading ||
            (e && e.length && e[0].complete) ||
            t.hasError ||
            i.showLoading(t);
        }, 50),
          i.checkSrcset(t),
          (t.$content = n('<div class="fancybox-content"></div>')
            .addClass('fancybox-is-hidden')
            .appendTo(t.$slide.addClass('fancybox-slide--image'))),
          !1 !== t.opts.preload &&
            t.opts.width &&
            t.opts.height &&
            t.thumb &&
            ((t.width = t.opts.width),
            (t.height = t.opts.height),
            (o = e.createElement('img')),
            (o.onerror = function () {
              n(this).remove(), (t.$ghost = null);
            }),
            (o.onload = function () {
              i.afterLoad(t);
            }),
            (t.$ghost = n(o)
              .addClass('fancybox-image')
              .appendTo(t.$content)
              .attr('src', t.thumb))),
          i.setBigImage(t);
      },
      checkSrcset: function (e) {
        var n,
          o,
          i,
          a,
          s = e.opts.srcset || e.opts.image.srcset;
        if (s) {
          (i = t.devicePixelRatio || 1),
            (a = t.innerWidth * i),
            (o = s.split(',').map(function (t) {
              var e = {};
              return (
                t
                  .trim()
                  .split(/\s+/)
                  .forEach(function (t, n) {
                    var o = parseInt(t.substring(0, t.length - 1), 10);
                    return 0 === n
                      ? (e.url = t)
                      : void (
                          o && ((e.value = o), (e.postfix = t[t.length - 1]))
                        );
                  }),
                e
              );
            })),
            o.sort(function (t, e) {
              return t.value - e.value;
            });
          for (var r = 0; r < o.length; r++) {
            var c = o[r];
            if (
              ('w' === c.postfix && c.value >= a) ||
              ('x' === c.postfix && c.value >= i)
            ) {
              n = c;
              break;
            }
          }
          !n && o.length && (n = o[o.length - 1]),
            n &&
              ((e.src = n.url),
              e.width &&
                e.height &&
                'w' == n.postfix &&
                ((e.height = (e.width / e.height) * n.value),
                (e.width = n.value)),
              (e.opts.srcset = s));
        }
      },
      setBigImage: function (t) {
        var o = this,
          i = e.createElement('img'),
          a = n(i);
        (t.$image = a
          .one('error', function () {
            o.setError(t);
          })
          .one('load', function () {
            var e;
            t.$ghost ||
              (o.resolveImageSlideSize(
                t,
                this.naturalWidth,
                this.naturalHeight
              ),
              o.afterLoad(t)),
              o.isClosing ||
                (t.opts.srcset &&
                  ((e = t.opts.sizes),
                  (e && 'auto' !== e) ||
                    (e =
                      (t.width / t.height > 1 && s.width() / s.height() > 1
                        ? '100'
                        : Math.round((t.width / t.height) * 100)) + 'vw'),
                  a.attr('sizes', e).attr('srcset', t.opts.srcset)),
                t.$ghost &&
                  setTimeout(function () {
                    t.$ghost && !o.isClosing && t.$ghost.hide();
                  }, Math.min(300, Math.max(1e3, t.height / 1600))),
                o.hideLoading(t));
          })
          .addClass('fancybox-image')
          .attr('src', t.src)
          .appendTo(t.$content)),
          (i.complete || 'complete' == i.readyState) &&
          a.naturalWidth &&
          a.naturalHeight
            ? a.trigger('load')
            : i.error && a.trigger('error');
      },
      resolveImageSlideSize: function (t, e, n) {
        var o = parseInt(t.opts.width, 10),
          i = parseInt(t.opts.height, 10);
        (t.width = e),
          (t.height = n),
          o > 0 && ((t.width = o), (t.height = Math.floor((o * n) / e))),
          i > 0 && ((t.width = Math.floor((i * e) / n)), (t.height = i));
      },
      setIframe: function (t) {
        var e,
          o = this,
          i = t.opts.iframe,
          a = t.$slide;
        (t.$content = n(
          '<div class="fancybox-content' +
            (i.preload ? ' fancybox-is-hidden' : '') +
            '"></div>'
        )
          .css(i.css)
          .appendTo(a)),
          a.addClass('fancybox-slide--' + t.contentType),
          (t.$iframe = e =
            n(i.tpl.replace(/\{rnd\}/g, new Date().getTime()))
              .attr(i.attr)
              .appendTo(t.$content)),
          i.preload
            ? (o.showLoading(t),
              e.on('load.fb error.fb', function (e) {
                (this.isReady = 1), t.$slide.trigger('refresh'), o.afterLoad(t);
              }),
              a.on('refresh.fb', function () {
                var t,
                  n,
                  o = c.$content,
                  s = i.css.width,
                  r = i.css.height;
                if (1 === e[0].isReady) {
                  try {
                    (t = e.contents()), (n = t.find('body'));
                  } catch (c) {}
                  n &&
                    n.length &&
                    n.children().length &&
                    (a.css('overflow', 'visible'),
                    o.css({
                      width: '100%',
                      'max-width': '100%',
                      height: '9999px',
                    }),
                    void 0 === s &&
                      (s = Math.ceil(
                        Math.max(n[0].clientWidth, n.outerWidth(!0))
                      )),
                    o.css('width', s || '').css('max-width', ''),
                    void 0 === r &&
                      (r = Math.ceil(
                        Math.max(n[0].clientHeight, n.outerHeight(!0))
                      )),
                    o.css('height', r || ''),
                    a.css('overflow', 'auto')),
                    o.removeClass('fancybox-is-hidden');
                }
              }))
            : o.afterLoad(t),
          e.attr('src', t.src),
          a.one('onReset', function () {
            try {
              n(this)
                .find('iframe')
                .hide()
                .unbind()
                .attr('src', '//about:blank');
            } catch (t) {}
            n(this).off('refresh.fb').empty(),
              (t.isLoaded = !1),
              (t.isRevealed = !1);
          });
      },
      setContent: function (t, e) {
        var o = this;
        o.isClosing ||
          (o.hideLoading(t),
          t.$content && n.fancybox.stop(t.$content),
          t.$slide.empty(),
          l(e) && e.parent().length
            ? ((e.hasClass('fancybox-content') ||
                e.parent().hasClass('fancybox-content')) &&
                e.parents('.fancybox-slide').trigger('onReset'),
              (t.$placeholder = n('<div>').hide().insertAfter(e)),
              e.css('display', 'inline-block'))
            : t.hasError ||
              ('string' === n.type(e) &&
                (e = n('<div>').append(n.trim(e)).contents()),
              t.opts.filter && (e = n('<div>').html(e).find(t.opts.filter))),
          t.$slide.one('onReset', function () {
            n(this).find('video,audio').trigger('pause'),
              t.$placeholder &&
                (t.$placeholder
                  .after(e.removeClass('fancybox-content').hide())
                  .remove(),
                (t.$placeholder = null)),
              t.$smallBtn && (t.$smallBtn.remove(), (t.$smallBtn = null)),
              t.hasError ||
                (n(this).empty(), (t.isLoaded = !1), (t.isRevealed = !1));
          }),
          n(e).appendTo(t.$slide),
          n(e).is('video,audio') &&
            (n(e).addClass('fancybox-video'),
            n(e).wrap('<div></div>'),
            (t.contentType = 'video'),
            (t.opts.width = t.opts.width || n(e).attr('width')),
            (t.opts.height = t.opts.height || n(e).attr('height'))),
          (t.$content = t.$slide
            .children()
            .filter('div,form,main,video,audio,article,.fancybox-content')
            .first()),
          t.$content.siblings().hide(),
          t.$content.length ||
            (t.$content = t.$slide.wrapInner('<div></div>').children().first()),
          t.$content.addClass('fancybox-content'),
          t.$slide.addClass('fancybox-slide--' + t.contentType),
          o.afterLoad(t));
      },
      setError: function (t) {
        (t.hasError = !0),
          t.$slide
            .trigger('onReset')
            .removeClass('fancybox-slide--' + t.contentType)
            .addClass('fancybox-slide--error'),
          (t.contentType = 'html'),
          this.setContent(t, this.translate(t, t.opts.errorTpl)),
          t.pos === this.currPos && (this.isAnimating = !1);
      },
      showLoading: function (t) {
        var e = this;
        (t = t || e.current) &&
          !t.$spinner &&
          (t.$spinner = n(e.translate(e, e.opts.spinnerTpl))
            .appendTo(t.$slide)
            .hide()
            .fadeIn('fast'));
      },
      hideLoading: function (t) {
        var e = this;
        (t = t || e.current) &&
          t.$spinner &&
          (t.$spinner.stop().remove(), delete t.$spinner);
      },
      afterLoad: function (t) {
        var e = this;
        e.isClosing ||
          ((t.isLoading = !1),
          (t.isLoaded = !0),
          e.trigger('afterLoad', t),
          e.hideLoading(t),
          !t.opts.smallBtn ||
            (t.$smallBtn && t.$smallBtn.length) ||
            (t.$smallBtn = n(e.translate(t, t.opts.btnTpl.smallBtn)).appendTo(
              t.$content
            )),
          t.opts.protect &&
            t.$content &&
            !t.hasError &&
            (t.$content.on('contextmenu.fb', function (t) {
              return 2 == t.button && t.preventDefault(), !0;
            }),
            'image' === t.type &&
              n('<div class="fancybox-spaceball"></div>').appendTo(t.$content)),
          e.adjustCaption(t),
          e.adjustLayout(t),
          t.pos === e.currPos && e.updateCursor(),
          e.revealContent(t));
      },
      adjustCaption: function (t) {
        var e,
          n = this,
          o = t || n.current,
          i = o.opts.caption,
          a = o.opts.preventCaptionOverlap,
          s = n.$refs.caption,
          r = !1;
        s.toggleClass('fancybox-caption--separate', a),
          a &&
            i &&
            i.length &&
            (o.pos !== n.currPos
              ? ((e = s.clone().appendTo(s.parent())),
                e.children().eq(0).empty().html(i),
                (r = e.outerHeight(!0)),
                e.empty().remove())
              : n.$caption && (r = n.$caption.outerHeight(!0)),
            o.$slide.css('padding-bottom', r || ''));
      },
      adjustLayout: function (t) {
        var e,
          n,
          o,
          i,
          a = this,
          s = t || a.current;
        s.isLoaded &&
          !0 !== s.opts.disableLayoutFix &&
          (s.$content.css('margin-bottom', ''),
          s.$content.outerHeight() > s.$slide.height() + 0.5 &&
            ((o = s.$slide[0].style['padding-bottom']),
            (i = s.$slide.css('padding-bottom')),
            parseFloat(i) > 0 &&
              ((e = s.$slide[0].scrollHeight),
              s.$slide.css('padding-bottom', 0),
              Math.abs(e - s.$slide[0].scrollHeight) < 1 && (n = i),
              s.$slide.css('padding-bottom', o))),
          s.$content.css('margin-bottom', n));
      },
      revealContent: function (t) {
        var e,
          o,
          i,
          a,
          s = this,
          r = t.$slide,
          c = !1,
          l = !1,
          d = s.isMoved(t),
          u = t.isRevealed;
        return (
          (t.isRevealed = !0),
          (e = t.opts[s.firstRun ? 'animationEffect' : 'transitionEffect']),
          (i = t.opts[s.firstRun ? 'animationDuration' : 'transitionDuration']),
          (i = parseInt(
            void 0 === t.forcedDuration ? i : t.forcedDuration,
            10
          )),
          (!d && t.pos === s.currPos && i) || (e = !1),
          'zoom' === e &&
            (t.pos === s.currPos &&
            i &&
            'image' === t.type &&
            !t.hasError &&
            (l = s.getThumbPos(t))
              ? (c = s.getFitPos(t))
              : (e = 'fade')),
          'zoom' === e
            ? ((s.isAnimating = !0),
              (c.scaleX = c.width / l.width),
              (c.scaleY = c.height / l.height),
              (a = t.opts.zoomOpacity),
              'auto' == a &&
                (a = Math.abs(t.width / t.height - l.width / l.height) > 0.1),
              a && ((l.opacity = 0.1), (c.opacity = 1)),
              n.fancybox.setTranslate(
                t.$content.removeClass('fancybox-is-hidden'),
                l
              ),
              p(t.$content),
              void n.fancybox.animate(t.$content, c, i, function () {
                (s.isAnimating = !1), s.complete();
              }))
            : (s.updateSlide(t),
              e
                ? (n.fancybox.stop(r),
                  (o =
                    'fancybox-slide--' +
                    (t.pos >= s.prevPos ? 'next' : 'previous') +
                    ' fancybox-animated fancybox-fx-' +
                    e),
                  r.addClass(o).removeClass('fancybox-slide--current'),
                  t.$content.removeClass('fancybox-is-hidden'),
                  p(r),
                  'image' !== t.type && t.$content.hide().show(0),
                  void n.fancybox.animate(
                    r,
                    'fancybox-slide--current',
                    i,
                    function () {
                      r.removeClass(o).css({ transform: '', opacity: '' }),
                        t.pos === s.currPos && s.complete();
                    },
                    !0
                  ))
                : (t.$content.removeClass('fancybox-is-hidden'),
                  u ||
                    !d ||
                    'image' !== t.type ||
                    t.hasError ||
                    t.$content.hide().fadeIn('fast'),
                  void (t.pos === s.currPos && s.complete())))
        );
      },
      getThumbPos: function (t) {
        var e,
          o,
          i,
          a,
          s,
          r = !1,
          c = t.$thumb;
        return (
          !(!c || !g(c[0])) &&
          ((e = n.fancybox.getTranslate(c)),
          (o = parseFloat(c.css('border-top-width') || 0)),
          (i = parseFloat(c.css('border-right-width') || 0)),
          (a = parseFloat(c.css('border-bottom-width') || 0)),
          (s = parseFloat(c.css('border-left-width') || 0)),
          (r = {
            top: e.top + o,
            left: e.left + s,
            width: e.width - i - s,
            height: e.height - o - a,
            scaleX: 1,
            scaleY: 1,
          }),
          e.width > 0 && e.height > 0 && r)
        );
      },
      complete: function () {
        var t,
          e = this,
          o = e.current,
          i = {};
        !e.isMoved() &&
          o.isLoaded &&
          (o.isComplete ||
            ((o.isComplete = !0),
            o.$slide.siblings().trigger('onReset'),
            e.preload('inline'),
            p(o.$slide),
            o.$slide.addClass('fancybox-slide--complete'),
            n.each(e.slides, function (t, o) {
              o.pos >= e.currPos - 1 && o.pos <= e.currPos + 1
                ? (i[o.pos] = o)
                : o && (n.fancybox.stop(o.$slide), o.$slide.off().remove());
            }),
            (e.slides = i)),
          (e.isAnimating = !1),
          e.updateCursor(),
          e.trigger('afterShow'),
          o.opts.video.autoStart &&
            o.$slide
              .find('video,audio')
              .filter(':visible:first')
              .trigger('play')
              .one('ended', function () {
                Document.exitFullscreen
                  ? Document.exitFullscreen()
                  : this.webkitExitFullscreen && this.webkitExitFullscreen(),
                  e.next();
              }),
          o.opts.autoFocus &&
            'html' === o.contentType &&
            ((t = o.$content.find('input[autofocus]:enabled:visible:first')),
            t.length ? t.trigger('focus') : e.focus(null, !0)),
          o.$slide.scrollTop(0).scrollLeft(0));
      },
      preload: function (t) {
        var e,
          n,
          o = this;
        o.group.length < 2 ||
          ((n = o.slides[o.currPos + 1]),
          (e = o.slides[o.currPos - 1]),
          e && e.type === t && o.loadSlide(e),
          n && n.type === t && o.loadSlide(n));
      },
      focus: function (t, o) {
        var i,
          a,
          s = this,
          r = [
            'a[href]',
            'area[href]',
            'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
            'select:not([disabled]):not([aria-hidden])',
            'textarea:not([disabled]):not([aria-hidden])',
            'button:not([disabled]):not([aria-hidden])',
            'iframe',
            'object',
            'embed',
            'video',
            'audio',
            '[contenteditable]',
            '[tabindex]:not([tabindex^="-"])',
          ].join(',');
        s.isClosing ||
          ((i =
            !t && s.current && s.current.isComplete
              ? s.current.$slide.find(
                  '*:visible' + (o ? ':not(.fancybox-close-small)' : '')
                )
              : s.$refs.container.find('*:visible')),
          (i = i.filter(r).filter(function () {
            return (
              'hidden' !== n(this).css('visibility') &&
              !n(this).hasClass('disabled')
            );
          })),
          i.length
            ? ((a = i.index(e.activeElement)),
              t && t.shiftKey
                ? (a < 0 || 0 == a) &&
                  (t.preventDefault(), i.eq(i.length - 1).trigger('focus'))
                : (a < 0 || a == i.length - 1) &&
                  (t && t.preventDefault(), i.eq(0).trigger('focus')))
            : s.$refs.container.trigger('focus'));
      },
      activate: function () {
        var t = this;
        n('.fancybox-container').each(function () {
          var e = n(this).data('FancyBox');
          e &&
            e.id !== t.id &&
            !e.isClosing &&
            (e.trigger('onDeactivate'), e.removeEvents(), (e.isVisible = !1));
        }),
          (t.isVisible = !0),
          (t.current || t.isIdle) && (t.update(), t.updateControls()),
          t.trigger('onActivate'),
          t.addEvents();
      },
      close: function (t, e) {
        var o,
          i,
          a,
          s,
          r,
          c,
          l,
          u = this,
          f = u.current,
          h = function () {
            u.cleanUp(t);
          };
        return !(
          u.isClosing ||
          ((u.isClosing = !0),
          !1 === u.trigger('beforeClose', t)
            ? ((u.isClosing = !1),
              d(function () {
                u.update();
              }),
              1)
            : (u.removeEvents(),
              (a = f.$content),
              (o = f.opts.animationEffect),
              (i = n.isNumeric(e) ? e : o ? f.opts.animationDuration : 0),
              f.$slide.removeClass(
                'fancybox-slide--complete fancybox-slide--next fancybox-slide--previous fancybox-animated'
              ),
              !0 !== t ? n.fancybox.stop(f.$slide) : (o = !1),
              f.$slide.siblings().trigger('onReset').remove(),
              i &&
                u.$refs.container
                  .removeClass('fancybox-is-open')
                  .addClass('fancybox-is-closing')
                  .css('transition-duration', i + 'ms'),
              u.hideLoading(f),
              u.hideControls(!0),
              u.updateCursor(),
              'zoom' !== o ||
                (a &&
                  i &&
                  'image' === f.type &&
                  !u.isMoved() &&
                  !f.hasError &&
                  (l = u.getThumbPos(f))) ||
                (o = 'fade'),
              'zoom' === o
                ? (n.fancybox.stop(a),
                  (s = n.fancybox.getTranslate(a)),
                  (c = {
                    top: s.top,
                    left: s.left,
                    scaleX: s.width / l.width,
                    scaleY: s.height / l.height,
                    width: l.width,
                    height: l.height,
                  }),
                  (r = f.opts.zoomOpacity),
                  'auto' == r &&
                    (r =
                      Math.abs(f.width / f.height - l.width / l.height) > 0.1),
                  r && (l.opacity = 0),
                  n.fancybox.setTranslate(a, c),
                  p(a),
                  n.fancybox.animate(a, l, i, h),
                  0)
                : (o && i
                    ? n.fancybox.animate(
                        f.$slide
                          .addClass('fancybox-slide--previous')
                          .removeClass('fancybox-slide--current'),
                        'fancybox-animated fancybox-fx-' + o,
                        i,
                        h
                      )
                    : !0 === t
                    ? setTimeout(h, i)
                    : h(),
                  0)))
        );
      },
      cleanUp: function (e) {
        var o,
          i,
          a,
          s = this,
          r = s.current.opts.$orig;
        s.current.$slide.trigger('onReset'),
          s.$refs.container.empty().remove(),
          s.trigger('afterClose', e),
          s.current.opts.backFocus &&
            ((r && r.length && r.is(':visible')) || (r = s.$trigger),
            r &&
              r.length &&
              ((i = t.scrollX),
              (a = t.scrollY),
              r.trigger('focus'),
              n('html, body').scrollTop(a).scrollLeft(i))),
          (s.current = null),
          (o = n.fancybox.getInstance()),
          o
            ? o.activate()
            : (n('body').removeClass(
                'fancybox-active compensate-for-scrollbar'
              ),
              n('#fancybox-style-noscroll').remove());
      },
      trigger: function (t, e) {
        var o,
          i = Array.prototype.slice.call(arguments, 1),
          a = this,
          s = e && e.opts ? e : a.current;
        return (
          s ? i.unshift(s) : (s = a),
          i.unshift(a),
          n.isFunction(s.opts[t]) && (o = s.opts[t].apply(s, i)),
          !1 === o
            ? o
            : void ('afterClose' !== t && a.$refs
                ? a.$refs.container.trigger(t + '.fb', i)
                : r.trigger(t + '.fb', i))
        );
      },
      updateControls: function () {
        var t = this,
          o = t.current,
          i = o.index,
          a = t.$refs.container,
          s = t.$refs.caption,
          r = o.opts.caption;
        o.$slide.trigger('refresh'),
          r && r.length
            ? ((t.$caption = s), s.children().eq(0).html(r))
            : (t.$caption = null),
          t.hasHiddenControls || t.isIdle || t.showControls(),
          a.find('[data-fancybox-count]').html(t.group.length),
          a.find('[data-fancybox-index]').html(i + 1),
          a
            .find('[data-fancybox-prev]')
            .prop('disabled', !o.opts.loop && i <= 0),
          a
            .find('[data-fancybox-next]')
            .prop('disabled', !o.opts.loop && i >= t.group.length - 1),
          'image' === o.type
            ? a
                .find('[data-fancybox-zoom]')
                .show()
                .end()
                .find('[data-fancybox-download]')
                .attr('href', o.opts.image.src || o.src)
                .show()
            : o.opts.toolbar &&
              a.find('[data-fancybox-download],[data-fancybox-zoom]').hide(),
          n(e.activeElement).is(':hidden,[disabled]') &&
            t.$refs.container.trigger('focus');
      },
      hideControls: function (t) {
        var e = this,
          n = ['infobar', 'toolbar', 'nav'];
        (!t && e.current.opts.preventCaptionOverlap) || n.push('caption'),
          this.$refs.container.removeClass(
            n
              .map(function (t) {
                return 'fancybox-show-' + t;
              })
              .join(' ')
          ),
          (this.hasHiddenControls = !0);
      },
      showControls: function () {
        var t = this,
          e = t.current ? t.current.opts : t.opts,
          n = t.$refs.container;
        (t.hasHiddenControls = !1),
          (t.idleSecondsCounter = 0),
          n
            .toggleClass('fancybox-show-toolbar', !(!e.toolbar || !e.buttons))
            .toggleClass(
              'fancybox-show-infobar',
              !!(e.infobar && t.group.length > 1)
            )
            .toggleClass('fancybox-show-caption', !!t.$caption)
            .toggleClass(
              'fancybox-show-nav',
              !!(e.arrows && t.group.length > 1)
            )
            .toggleClass('fancybox-is-modal', !!e.modal);
      },
      toggleControls: function () {
        this.hasHiddenControls ? this.showControls() : this.hideControls();
      },
    }),
      (n.fancybox = {
        version: '3.5.7',
        defaults: a,
        getInstance: function (t) {
          var e = n(
              '.fancybox-container:not(".fancybox-is-closing"):last'
            ).data('FancyBox'),
            o = Array.prototype.slice.call(arguments, 1);
          return (
            e instanceof b &&
            ('string' === n.type(t)
              ? e[t].apply(e, o)
              : 'function' === n.type(t) && t.apply(e, o),
            e)
          );
        },
        open: function (t, e, n) {
          return new b(t, e, n);
        },
        close: function (t) {
          var e = this.getInstance();
          e && (e.close(), !0 === t && this.close(t));
        },
        destroy: function () {
          this.close(!0), r.add('body').off('click.fb-start', '**');
        },
        isMobile:
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          ),
        use3d: (function () {
          var n = e.createElement('div');
          return (
            t.getComputedStyle &&
            t.getComputedStyle(n) &&
            t.getComputedStyle(n).getPropertyValue('transform') &&
            !(e.documentMode && e.documentMode < 11)
          );
        })(),
        getTranslate: function (t) {
          var e;
          return (
            !(!t || !t.length) &&
            ((e = t[0].getBoundingClientRect()),
            {
              top: e.top || 0,
              left: e.left || 0,
              width: e.width,
              height: e.height,
              opacity: parseFloat(t.css('opacity')),
            })
          );
        },
        setTranslate: function (t, e) {
          var n = '',
            o = {};
          if (t && e)
            return (
              (void 0 === e.left && void 0 === e.top) ||
                ((n =
                  (void 0 === e.left ? t.position().left : e.left) +
                  'px, ' +
                  (void 0 === e.top ? t.position().top : e.top) +
                  'px'),
                (n = this.use3d
                  ? 'translate3d(' + n + ', 0px)'
                  : 'translate(' + n + ')')),
              void 0 !== e.scaleX && void 0 !== e.scaleY
                ? (n += ' scale(' + e.scaleX + ', ' + e.scaleY + ')')
                : void 0 !== e.scaleX && (n += ' scaleX(' + e.scaleX + ')'),
              n.length && (o.transform = n),
              void 0 !== e.opacity && (o.opacity = e.opacity),
              void 0 !== e.width && (o.width = e.width),
              void 0 !== e.height && (o.height = e.height),
              t.css(o)
            );
        },
        animate: function (t, e, o, i, a) {
          var s,
            r = this;
          n.isFunction(o) && ((i = o), (o = null)),
            r.stop(t),
            (s = r.getTranslate(t)),
            t.on(f, function (c) {
              (!c ||
                !c.originalEvent ||
                (t.is(c.originalEvent.target) &&
                  'z-index' != c.originalEvent.propertyName)) &&
                (r.stop(t),
                n.isNumeric(o) && t.css('transition-duration', ''),
                n.isPlainObject(e)
                  ? void 0 !== e.scaleX &&
                    void 0 !== e.scaleY &&
                    r.setTranslate(t, {
                      top: e.top,
                      left: e.left,
                      width: s.width * e.scaleX,
                      height: s.height * e.scaleY,
                      scaleX: 1,
                      scaleY: 1,
                    })
                  : !0 !== a && t.removeClass(e),
                n.isFunction(i) && i(c));
            }),
            n.isNumeric(o) && t.css('transition-duration', o + 'ms'),
            n.isPlainObject(e)
              ? (void 0 !== e.scaleX &&
                  void 0 !== e.scaleY &&
                  (delete e.width,
                  delete e.height,
                  t.parent().hasClass('fancybox-slide--image') &&
                    t.parent().addClass('fancybox-is-scaling')),
                n.fancybox.setTranslate(t, e))
              : t.addClass(e),
            t.data(
              'timer',
              setTimeout(function () {
                t.trigger(f);
              }, o + 33)
            );
        },
        stop: function (t, e) {
          t &&
            t.length &&
            (clearTimeout(t.data('timer')),
            e && t.trigger(f),
            t.off(f).css('transition-duration', ''),
            t.parent().removeClass('fancybox-is-scaling'));
        },
      }),
      (n.fn.fancybox = function (t) {
        var e;
        return (
          (t = t || {}),
          (e = t.selector || !1),
          e
            ? n('body')
                .off('click.fb-start', e)
                .on('click.fb-start', e, { options: t }, i)
            : this.off('click.fb-start').on(
                'click.fb-start',
                { items: this, options: t },
                i
              ),
          this
        );
      }),
      r.on('click.fb-start', '[data-fancybox]', i),
      r.on('click.fb-start', '[data-fancybox-trigger]', function (t) {
        n('[data-fancybox="' + n(this).attr('data-fancybox-trigger') + '"]')
          .eq(n(this).attr('data-fancybox-index') || 0)
          .trigger('click.fb-start', { $trigger: n(this) });
      }),
      (function () {
        var t = null;
        r.on('mousedown mouseup focus blur', '.fancybox-button', function (e) {
          switch (e.type) {
            case 'mousedown':
              t = n(this);
              break;
            case 'mouseup':
              t = null;
              break;
            case 'focusin':
              n('.fancybox-button').removeClass('fancybox-focus'),
                n(this).is(t) ||
                  n(this).is('[disabled]') ||
                  n(this).addClass('fancybox-focus');
              break;
            case 'focusout':
              n('.fancybox-button').removeClass('fancybox-focus');
          }
        });
      })();
  }
})(window, document, jQuery),
  (function (t) {
    'use strict';
    var e = {
        youtube: {
          matcher:
            /(youtube\.com|youtu\.be|youtube\-nocookie\.com)\/(watch\?(.*&)?v=|v\/|u\/|embed\/?)?(videoseries\?list=(.*)|[\w-]{11}|\?listType=(.*)&list=(.*))(.*)/i,
          params: {
            autoplay: 1,
            autohide: 1,
            fs: 1,
            rel: 0,
            hd: 1,
            wmode: 'transparent',
            enablejsapi: 1,
            html5: 1,
          },
          paramPlace: 8,
          type: 'iframe',
          url: 'https://www.youtube-nocookie.com/embed/$4',
          thumb: 'https://img.youtube.com/vi/$4/hqdefault.jpg',
        },
        vimeo: {
          matcher: /^.+vimeo.com\/(.*\/)?([\d]+)(.*)?/,
          params: {
            autoplay: 1,
            hd: 1,
            show_title: 1,
            show_byline: 1,
            show_portrait: 0,
            fullscreen: 1,
          },
          paramPlace: 3,
          type: 'iframe',
          url: '//player.vimeo.com/video/$2',
        },
        instagram: {
          matcher: /(instagr\.am|instagram\.com)\/p\/([a-zA-Z0-9_\-]+)\/?/i,
          type: 'image',
          url: '//$1/p/$2/media/?size=l',
        },
        gmap_place: {
          matcher:
            /(maps\.)?google\.([a-z]{2,3}(\.[a-z]{2})?)\/(((maps\/(place\/(.*)\/)?\@(.*),(\d+.?\d+?)z))|(\?ll=))(.*)?/i,
          type: 'iframe',
          url: function (t) {
            return (
              '//maps.google.' +
              t[2] +
              '/?ll=' +
              (t[9]
                ? t[9] +
                  '&z=' +
                  Math.floor(t[10]) +
                  (t[12] ? t[12].replace(/^\//, '&') : '')
                : t[12] + ''
              ).replace(/\?/, '&') +
              '&output=' +
              (t[12] && t[12].indexOf('layer=c') > 0 ? 'svembed' : 'embed')
            );
          },
        },
        gmap_search: {
          matcher:
            /(maps\.)?google\.([a-z]{2,3}(\.[a-z]{2})?)\/(maps\/search\/)(.*)/i,
          type: 'iframe',
          url: function (t) {
            return (
              '//maps.google.' +
              t[2] +
              '/maps?q=' +
              t[5].replace('query=', 'q=').replace('api=1', '') +
              '&output=embed'
            );
          },
        },
      },
      n = function (e, n, o) {
        if (e)
          return (
            (o = o || ''),
            'object' === t.type(o) && (o = t.param(o, !0)),
            t.each(n, function (t, n) {
              e = e.replace('$' + t, n || '');
            }),
            o.length && (e += (e.indexOf('?') > 0 ? '&' : '?') + o),
            e
          );
      };
    t(document).on('objectNeedsType.fb', function (o, i, a) {
      var s,
        r,
        c,
        l,
        d,
        u,
        f,
        p = a.src || '',
        h = !1;
      (s = t.extend(!0, {}, e, a.opts.media)),
        t.each(s, function (e, o) {
          if ((c = p.match(o.matcher))) {
            if (
              ((h = o.type), (f = e), (u = {}), o.paramPlace && c[o.paramPlace])
            ) {
              (d = c[o.paramPlace]),
                '?' == d[0] && (d = d.substring(1)),
                (d = d.split('&'));
              for (var i = 0; i < d.length; ++i) {
                var s = d[i].split('=', 2);
                2 == s.length &&
                  (u[s[0]] = decodeURIComponent(s[1].replace(/\+/g, ' ')));
              }
            }
            return (
              (l = t.extend(!0, {}, o.params, a.opts[e], u)),
              (p =
                'function' === t.type(o.url)
                  ? o.url.call(this, c, l, a)
                  : n(o.url, c, l)),
              (r =
                'function' === t.type(o.thumb)
                  ? o.thumb.call(this, c, l, a)
                  : n(o.thumb, c)),
              'youtube' === e
                ? (p = p.replace(/&t=((\d+)m)?(\d+)s/, function (t, e, n, o) {
                    return (
                      '&start=' +
                      ((n ? 60 * parseInt(n, 10) : 0) + parseInt(o, 10))
                    );
                  }))
                : 'vimeo' === e && (p = p.replace('&%23', '#')),
              !1
            );
          }
        }),
        h
          ? (a.opts.thumb ||
              (a.opts.$thumb && a.opts.$thumb.length) ||
              (a.opts.thumb = r),
            'iframe' === h &&
              (a.opts = t.extend(!0, a.opts, {
                iframe: { preload: !1, attr: { scrolling: 'no' } },
              })),
            t.extend(a, {
              type: h,
              src: p,
              origSrc: a.src,
              contentSource: f,
              contentType:
                'image' === h
                  ? 'image'
                  : 'gmap_place' == f || 'gmap_search' == f
                  ? 'map'
                  : 'video',
            }))
          : p && (a.type = a.opts.defaultType);
    });
    var o = {
      youtube: {
        src: 'https://www.youtube.com/iframe_api',
        class: 'YT',
        loading: !1,
        loaded: !1,
      },
      vimeo: {
        src: 'https://player.vimeo.com/api/player.js',
        class: 'Vimeo',
        loading: !1,
        loaded: !1,
      },
      load: function (t) {
        var e,
          n = this;
        return this[t].loaded
          ? void setTimeout(function () {
              n.done(t);
            })
          : void (
              this[t].loading ||
              ((this[t].loading = !0),
              (e = document.createElement('script')),
              (e.type = 'text/javascript'),
              (e.src = this[t].src),
              'youtube' === t
                ? (window.onYouTubeIframeAPIReady = function () {
                    (n[t].loaded = !0), n.done(t);
                  })
                : (e.onload = function () {
                    (n[t].loaded = !0), n.done(t);
                  }),
              document.body.appendChild(e))
            );
      },
      done: function (e) {
        var n, o, i;
        'youtube' === e && delete window.onYouTubeIframeAPIReady,
          (n = t.fancybox.getInstance()) &&
            ((o = n.current.$content.find('iframe')),
            'youtube' === e && void 0 !== YT && YT
              ? (i = new YT.Player(o.attr('id'), {
                  events: {
                    onStateChange: function (t) {
                      0 == t.data && n.next();
                    },
                  },
                }))
              : 'vimeo' === e &&
                void 0 !== Vimeo &&
                Vimeo &&
                ((i = new Vimeo.Player(o)),
                i.on('ended', function () {
                  n.next();
                })));
      },
    };
    t(document).on({
      'afterShow.fb': function (t, e, n) {
        e.group.length > 1 &&
          ('youtube' === n.contentSource || 'vimeo' === n.contentSource) &&
          o.load(n.contentSource);
      },
    });
  })(jQuery),
  (function (t, e, n) {
    'use strict';
    var o = (function () {
        return (
          t.requestAnimationFrame ||
          t.webkitRequestAnimationFrame ||
          t.mozRequestAnimationFrame ||
          t.oRequestAnimationFrame ||
          function (e) {
            return t.setTimeout(e, 1e3 / 60);
          }
        );
      })(),
      i = (function () {
        return (
          t.cancelAnimationFrame ||
          t.webkitCancelAnimationFrame ||
          t.mozCancelAnimationFrame ||
          t.oCancelAnimationFrame ||
          function (e) {
            t.clearTimeout(e);
          }
        );
      })(),
      a = function (e) {
        var n = [];
        (e = e.originalEvent || e || t.e),
          (e =
            e.touches && e.touches.length
              ? e.touches
              : e.changedTouches && e.changedTouches.length
              ? e.changedTouches
              : [e]);
        for (var o in e)
          e[o].pageX
            ? n.push({ x: e[o].pageX, y: e[o].pageY })
            : e[o].clientX && n.push({ x: e[o].clientX, y: e[o].clientY });
        return n;
      },
      s = function (t, e, n) {
        return e && t
          ? 'x' === n
            ? t.x - e.x
            : 'y' === n
            ? t.y - e.y
            : Math.sqrt(Math.pow(t.x - e.x, 2) + Math.pow(t.y - e.y, 2))
          : 0;
      },
      r = function (t) {
        if (
          t.is(
            'a,area,button,[role="button"],input,label,select,summary,textarea,video,audio,iframe'
          ) ||
          n.isFunction(t.get(0).onclick) ||
          t.data('selectable')
        )
          return !0;
        for (var e = 0, o = t[0].attributes, i = o.length; e < i; e++)
          if ('data-fancybox-' === o[e].nodeName.substr(0, 14)) return !0;
        return !1;
      },
      c = function (e) {
        var n = t.getComputedStyle(e)['overflow-y'],
          o = t.getComputedStyle(e)['overflow-x'],
          i =
            ('scroll' === n || 'auto' === n) && e.scrollHeight > e.clientHeight,
          a = ('scroll' === o || 'auto' === o) && e.scrollWidth > e.clientWidth;
        return i || a;
      },
      l = function (t) {
        for (
          var e = !1;
          !(e = c(t.get(0))) &&
          ((t = t.parent()),
          t.length && !t.hasClass('fancybox-stage') && !t.is('body'));

        );
        return e;
      },
      d = function (t) {
        var e = this;
        (e.instance = t),
          (e.$bg = t.$refs.bg),
          (e.$stage = t.$refs.stage),
          (e.$container = t.$refs.container),
          e.destroy(),
          e.$container.on(
            'touchstart.fb.touch mousedown.fb.touch',
            n.proxy(e, 'ontouchstart')
          );
      };
    (d.prototype.destroy = function () {
      var t = this;
      t.$container.off('.fb.touch'),
        n(e).off('.fb.touch'),
        t.requestId && (i(t.requestId), (t.requestId = null)),
        t.tapped && (clearTimeout(t.tapped), (t.tapped = null));
    }),
      (d.prototype.ontouchstart = function (o) {
        var i = this,
          c = n(o.target),
          d = i.instance,
          u = d.current,
          f = u.$slide,
          p = u.$content,
          h = 'touchstart' == o.type;
        if (
          (h && i.$container.off('mousedown.fb.touch'),
          (!o.originalEvent || 2 != o.originalEvent.button) &&
            f.length &&
            c.length &&
            !r(c) &&
            !r(c.parent()) &&
            (c.is('img') ||
              !(o.originalEvent.clientX > c[0].clientWidth + c.offset().left)))
        ) {
          if (!u || d.isAnimating || u.$slide.hasClass('fancybox-animated'))
            return o.stopPropagation(), void o.preventDefault();
          (i.realPoints = i.startPoints = a(o)),
            i.startPoints.length &&
              (u.touch && o.stopPropagation(),
              (i.startEvent = o),
              (i.canTap = !0),
              (i.$target = c),
              (i.$content = p),
              (i.opts = u.opts.touch),
              (i.isPanning = !1),
              (i.isSwiping = !1),
              (i.isZooming = !1),
              (i.isScrolling = !1),
              (i.canPan = d.canPan()),
              (i.startTime = new Date().getTime()),
              (i.distanceX = i.distanceY = i.distance = 0),
              (i.canvasWidth = Math.round(f[0].clientWidth)),
              (i.canvasHeight = Math.round(f[0].clientHeight)),
              (i.contentLastPos = null),
              (i.contentStartPos = n.fancybox.getTranslate(i.$content) || {
                top: 0,
                left: 0,
              }),
              (i.sliderStartPos = n.fancybox.getTranslate(f)),
              (i.stagePos = n.fancybox.getTranslate(d.$refs.stage)),
              (i.sliderStartPos.top -= i.stagePos.top),
              (i.sliderStartPos.left -= i.stagePos.left),
              (i.contentStartPos.top -= i.stagePos.top),
              (i.contentStartPos.left -= i.stagePos.left),
              n(e)
                .off('.fb.touch')
                .on(
                  h
                    ? 'touchend.fb.touch touchcancel.fb.touch'
                    : 'mouseup.fb.touch mouseleave.fb.touch',
                  n.proxy(i, 'ontouchend')
                )
                .on(
                  h ? 'touchmove.fb.touch' : 'mousemove.fb.touch',
                  n.proxy(i, 'ontouchmove')
                ),
              n.fancybox.isMobile &&
                e.addEventListener('scroll', i.onscroll, !0),
              (((i.opts || i.canPan) &&
                (c.is(i.$stage) || i.$stage.find(c).length)) ||
                (c.is('.fancybox-image') && o.preventDefault(),
                n.fancybox.isMobile &&
                  c.parents('.fancybox-caption').length)) &&
                ((i.isScrollable = l(c) || l(c.parent())),
                (n.fancybox.isMobile && i.isScrollable) || o.preventDefault(),
                (1 === i.startPoints.length || u.hasError) &&
                  (i.canPan
                    ? (n.fancybox.stop(i.$content), (i.isPanning = !0))
                    : (i.isSwiping = !0),
                  i.$container.addClass('fancybox-is-grabbing')),
                2 === i.startPoints.length &&
                  'image' === u.type &&
                  (u.isLoaded || u.$ghost) &&
                  ((i.canTap = !1),
                  (i.isSwiping = !1),
                  (i.isPanning = !1),
                  (i.isZooming = !0),
                  n.fancybox.stop(i.$content),
                  (i.centerPointStartX =
                    0.5 * (i.startPoints[0].x + i.startPoints[1].x) -
                    n(t).scrollLeft()),
                  (i.centerPointStartY =
                    0.5 * (i.startPoints[0].y + i.startPoints[1].y) -
                    n(t).scrollTop()),
                  (i.percentageOfImageAtPinchPointX =
                    (i.centerPointStartX - i.contentStartPos.left) /
                    i.contentStartPos.width),
                  (i.percentageOfImageAtPinchPointY =
                    (i.centerPointStartY - i.contentStartPos.top) /
                    i.contentStartPos.height),
                  (i.startDistanceBetweenFingers = s(
                    i.startPoints[0],
                    i.startPoints[1]
                  )))));
        }
      }),
      (d.prototype.onscroll = function (t) {
        var n = this;
        (n.isScrolling = !0), e.removeEventListener('scroll', n.onscroll, !0);
      }),
      (d.prototype.ontouchmove = function (t) {
        var e = this;
        return void 0 !== t.originalEvent.buttons &&
          0 === t.originalEvent.buttons
          ? void e.ontouchend(t)
          : e.isScrolling
          ? void (e.canTap = !1)
          : ((e.newPoints = a(t)),
            void (
              (e.opts || e.canPan) &&
              e.newPoints.length &&
              e.newPoints.length &&
              ((e.isSwiping && !0 === e.isSwiping) || t.preventDefault(),
              (e.distanceX = s(e.newPoints[0], e.startPoints[0], 'x')),
              (e.distanceY = s(e.newPoints[0], e.startPoints[0], 'y')),
              (e.distance = s(e.newPoints[0], e.startPoints[0])),
              e.distance > 0 &&
                (e.isSwiping
                  ? e.onSwipe(t)
                  : e.isPanning
                  ? e.onPan()
                  : e.isZooming && e.onZoom()))
            ));
      }),
      (d.prototype.onSwipe = function (e) {
        var a,
          s = this,
          r = s.instance,
          c = s.isSwiping,
          l = s.sliderStartPos.left || 0;
        if (!0 !== c)
          'x' == c &&
            (s.distanceX > 0 &&
            (s.instance.group.length < 2 ||
              (0 === s.instance.current.index && !s.instance.current.opts.loop))
              ? (l += Math.pow(s.distanceX, 0.8))
              : s.distanceX < 0 &&
                (s.instance.group.length < 2 ||
                  (s.instance.current.index === s.instance.group.length - 1 &&
                    !s.instance.current.opts.loop))
              ? (l -= Math.pow(-s.distanceX, 0.8))
              : (l += s.distanceX)),
            (s.sliderLastPos = {
              top: 'x' == c ? 0 : s.sliderStartPos.top + s.distanceY,
              left: l,
            }),
            s.requestId && (i(s.requestId), (s.requestId = null)),
            (s.requestId = o(function () {
              s.sliderLastPos &&
                (n.each(s.instance.slides, function (t, e) {
                  var o = e.pos - s.instance.currPos;
                  n.fancybox.setTranslate(e.$slide, {
                    top: s.sliderLastPos.top,
                    left:
                      s.sliderLastPos.left +
                      o * s.canvasWidth +
                      o * e.opts.gutter,
                  });
                }),
                s.$container.addClass('fancybox-is-sliding'));
            }));
        else if (Math.abs(s.distance) > 10) {
          if (
            ((s.canTap = !1),
            r.group.length < 2 && s.opts.vertical
              ? (s.isSwiping = 'y')
              : r.isDragging ||
                !1 === s.opts.vertical ||
                ('auto' === s.opts.vertical && n(t).width() > 800)
              ? (s.isSwiping = 'x')
              : ((a = Math.abs(
                  (180 * Math.atan2(s.distanceY, s.distanceX)) / Math.PI
                )),
                (s.isSwiping = a > 45 && a < 135 ? 'y' : 'x')),
            'y' === s.isSwiping && n.fancybox.isMobile && s.isScrollable)
          )
            return void (s.isScrolling = !0);
          (r.isDragging = s.isSwiping),
            (s.startPoints = s.newPoints),
            n.each(r.slides, function (t, e) {
              var o, i;
              n.fancybox.stop(e.$slide),
                (o = n.fancybox.getTranslate(e.$slide)),
                (i = n.fancybox.getTranslate(r.$refs.stage)),
                e.$slide
                  .css({
                    transform: '',
                    opacity: '',
                    'transition-duration': '',
                  })
                  .removeClass('fancybox-animated')
                  .removeClass(function (t, e) {
                    return (e.match(/(^|\s)fancybox-fx-\S+/g) || []).join(' ');
                  }),
                e.pos === r.current.pos &&
                  ((s.sliderStartPos.top = o.top - i.top),
                  (s.sliderStartPos.left = o.left - i.left)),
                n.fancybox.setTranslate(e.$slide, {
                  top: o.top - i.top,
                  left: o.left - i.left,
                });
            }),
            r.SlideShow && r.SlideShow.isActive && r.SlideShow.stop();
        }
      }),
      (d.prototype.onPan = function () {
        var t = this;
        return s(t.newPoints[0], t.realPoints[0]) <
          (n.fancybox.isMobile ? 10 : 5)
          ? void (t.startPoints = t.newPoints)
          : ((t.canTap = !1),
            (t.contentLastPos = t.limitMovement()),
            t.requestId && i(t.requestId),
            (t.requestId = o(function () {
              n.fancybox.setTranslate(t.$content, t.contentLastPos);
            })),
            void 0);
      }),
      (d.prototype.limitMovement = function () {
        var t,
          e,
          n,
          o,
          i,
          a,
          s = this,
          r = s.canvasWidth,
          c = s.canvasHeight,
          l = s.distanceX,
          d = s.distanceY,
          u = s.contentStartPos,
          f = u.left,
          p = u.top,
          h = u.width,
          g = u.height;
        return (
          (i = h > r ? f + l : f),
          (a = p + d),
          (t = Math.max(0, 0.5 * r - 0.5 * h)),
          (e = Math.max(0, 0.5 * c - 0.5 * g)),
          (n = Math.min(r - h, 0.5 * r - 0.5 * h)),
          (o = Math.min(c - g, 0.5 * c - 0.5 * g)),
          l > 0 && i > t && (i = t - 1 + Math.pow(-t + f + l, 0.8) || 0),
          l < 0 && i < n && (i = n + 1 - Math.pow(n - f - l, 0.8) || 0),
          d > 0 && a > e && (a = e - 1 + Math.pow(-e + p + d, 0.8) || 0),
          d < 0 && a < o && (a = o + 1 - Math.pow(o - p - d, 0.8) || 0),
          { top: a, left: i }
        );
      }),
      (d.prototype.limitPosition = function (t, e, n, o) {
        var i = this,
          a = i.canvasWidth,
          s = i.canvasHeight;
        return (
          n > a
            ? ((t = t > 0 ? 0 : t), (t = t < a - n ? a - n : t))
            : (t = Math.max(0, a / 2 - n / 2)),
          o > s
            ? ((e = e > 0 ? 0 : e), (e = e < s - o ? s - o : e))
            : (e = Math.max(0, s / 2 - o / 2)),
          { top: e, left: t }
        );
      }),
      (d.prototype.onZoom = function () {
        var e = this,
          a = e.contentStartPos,
          r = a.width,
          c = a.height,
          l = a.left,
          d = a.top,
          u = s(e.newPoints[0], e.newPoints[1]),
          f = u / e.startDistanceBetweenFingers,
          p = Math.floor(r * f),
          h = Math.floor(c * f),
          g = (r - p) * e.percentageOfImageAtPinchPointX,
          b = (c - h) * e.percentageOfImageAtPinchPointY,
          m = (e.newPoints[0].x + e.newPoints[1].x) / 2 - n(t).scrollLeft(),
          v = (e.newPoints[0].y + e.newPoints[1].y) / 2 - n(t).scrollTop(),
          y = m - e.centerPointStartX,
          x = v - e.centerPointStartY,
          w = l + (g + y),
          $ = d + (b + x),
          S = { top: $, left: w, scaleX: f, scaleY: f };
        (e.canTap = !1),
          (e.newWidth = p),
          (e.newHeight = h),
          (e.contentLastPos = S),
          e.requestId && i(e.requestId),
          (e.requestId = o(function () {
            n.fancybox.setTranslate(e.$content, e.contentLastPos);
          }));
      }),
      (d.prototype.ontouchend = function (t) {
        var o = this,
          s = o.isSwiping,
          r = o.isPanning,
          c = o.isZooming,
          l = o.isScrolling;
        return (
          (o.endPoints = a(t)),
          (o.dMs = Math.max(new Date().getTime() - o.startTime, 1)),
          o.$container.removeClass('fancybox-is-grabbing'),
          n(e).off('.fb.touch'),
          e.removeEventListener('scroll', o.onscroll, !0),
          o.requestId && (i(o.requestId), (o.requestId = null)),
          (o.isSwiping = !1),
          (o.isPanning = !1),
          (o.isZooming = !1),
          (o.isScrolling = !1),
          (o.instance.isDragging = !1),
          o.canTap
            ? o.onTap(t)
            : ((o.speed = 100),
              (o.velocityX = (o.distanceX / o.dMs) * 0.5),
              (o.velocityY = (o.distanceY / o.dMs) * 0.5),
              r ? o.endPanning() : c ? o.endZooming() : o.endSwiping(s, l),
              void 0)
        );
      }),
      (d.prototype.endSwiping = function (t, e) {
        var o = this,
          i = !1,
          a = o.instance.group.length,
          s = Math.abs(o.distanceX),
          r = 'x' == t && a > 1 && ((o.dMs > 130 && s > 10) || s > 50);
        (o.sliderLastPos = null),
          'y' == t && !e && Math.abs(o.distanceY) > 50
            ? (n.fancybox.animate(
                o.instance.current.$slide,
                {
                  top: o.sliderStartPos.top + o.distanceY + 150 * o.velocityY,
                  opacity: 0,
                },
                200
              ),
              (i = o.instance.close(!0, 250)))
            : r && o.distanceX > 0
            ? (i = o.instance.previous(300))
            : r && o.distanceX < 0 && (i = o.instance.next(300)),
          !1 !== i || ('x' != t && 'y' != t) || o.instance.centerSlide(200),
          o.$container.removeClass('fancybox-is-sliding');
      }),
      (d.prototype.endPanning = function () {
        var t,
          e,
          o,
          i = this;
        i.contentLastPos &&
          (!1 === i.opts.momentum || i.dMs > 350
            ? ((t = i.contentLastPos.left), (e = i.contentLastPos.top))
            : ((t = i.contentLastPos.left + 500 * i.velocityX),
              (e = i.contentLastPos.top + 500 * i.velocityY)),
          (o = i.limitPosition(
            t,
            e,
            i.contentStartPos.width,
            i.contentStartPos.height
          )),
          (o.width = i.contentStartPos.width),
          (o.height = i.contentStartPos.height),
          n.fancybox.animate(i.$content, o, 366));
      }),
      (d.prototype.endZooming = function () {
        var t,
          e,
          o,
          i,
          a = this,
          s = a.instance.current,
          r = a.newWidth,
          c = a.newHeight;
        a.contentLastPos &&
          ((t = a.contentLastPos.left),
          (e = a.contentLastPos.top),
          (i = { top: e, left: t, width: r, height: c, scaleX: 1, scaleY: 1 }),
          n.fancybox.setTranslate(a.$content, i),
          r < a.canvasWidth && c < a.canvasHeight
            ? a.instance.scaleToFit(150)
            : r > s.width || c > s.height
            ? a.instance.scaleToActual(
                a.centerPointStartX,
                a.centerPointStartY,
                150
              )
            : ((o = a.limitPosition(t, e, r, c)),
              n.fancybox.animate(a.$content, o, 150)));
      }),
      (d.prototype.onTap = function (e) {
        var o,
          i = this,
          s = n(e.target),
          r = i.instance,
          c = r.current,
          l = (e && a(e)) || i.startPoints,
          d = l[0] ? l[0].x - n(t).scrollLeft() - i.stagePos.left : 0,
          u = l[0] ? l[0].y - n(t).scrollTop() - i.stagePos.top : 0,
          f = function (t) {
            var o = c.opts[t];
            if ((n.isFunction(o) && (o = o.apply(r, [c, e])), o))
              switch (o) {
                case 'close':
                  r.close(i.startEvent);
                  break;
                case 'toggleControls':
                  r.toggleControls();
                  break;
                case 'next':
                  r.next();
                  break;
                case 'nextOrClose':
                  r.group.length > 1 ? r.next() : r.close(i.startEvent);
                  break;
                case 'zoom':
                  'image' == c.type &&
                    (c.isLoaded || c.$ghost) &&
                    (r.canPan()
                      ? r.scaleToFit()
                      : r.isScaledDown()
                      ? r.scaleToActual(d, u)
                      : r.group.length < 2 && r.close(i.startEvent));
              }
          };
        if (
          (!e.originalEvent || 2 != e.originalEvent.button) &&
          (s.is('img') || !(d > s[0].clientWidth + s.offset().left))
        ) {
          if (
            s.is(
              '.fancybox-bg,.fancybox-inner,.fancybox-outer,.fancybox-container'
            )
          )
            o = 'Outside';
          else if (s.is('.fancybox-slide')) o = 'Slide';
          else {
            if (
              !r.current.$content ||
              !r.current.$content.find(s).addBack().filter(s).length
            )
              return;
            o = 'Content';
          }
          if (i.tapped) {
            if (
              (clearTimeout(i.tapped),
              (i.tapped = null),
              Math.abs(d - i.tapX) > 50 || Math.abs(u - i.tapY) > 50)
            )
              return this;
            f('dblclick' + o);
          } else
            (i.tapX = d),
              (i.tapY = u),
              c.opts['dblclick' + o] &&
              c.opts['dblclick' + o] !== c.opts['click' + o]
                ? (i.tapped = setTimeout(function () {
                    (i.tapped = null), r.isAnimating || f('click' + o);
                  }, 500))
                : f('click' + o);
          return this;
        }
      }),
      n(e)
        .on('onActivate.fb', function (t, e) {
          e && !e.Guestures && (e.Guestures = new d(e));
        })
        .on('beforeClose.fb', function (t, e) {
          e && e.Guestures && e.Guestures.destroy();
        });
  })(window, document, jQuery),
  (function (t, e) {
    'use strict';
    e.extend(!0, e.fancybox.defaults, {
      btnTpl: {
        slideShow:
          '<button data-fancybox-play class="fancybox-button fancybox-button--play" title="{{PLAY_START}}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M6.5 5.4v13.2l11-6.6z"/></svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8.33 5.75h2.2v12.5h-2.2V5.75zm5.15 0h2.2v12.5h-2.2V5.75z"/></svg></button>',
      },
      slideShow: { autoStart: !1, speed: 3e3, progress: !0 },
    });
    var n = function (t) {
      (this.instance = t), this.init();
    };
    e.extend(n.prototype, {
      timer: null,
      isActive: !1,
      $button: null,
      init: function () {
        var t = this,
          n = t.instance,
          o = n.group[n.currIndex].opts.slideShow;
        (t.$button = n.$refs.toolbar
          .find('[data-fancybox-play]')
          .on('click', function () {
            t.toggle();
          })),
          n.group.length < 2 || !o
            ? t.$button.hide()
            : o.progress &&
              (t.$progress = e(
                '<div class="fancybox-progress"></div>'
              ).appendTo(n.$refs.inner));
      },
      set: function (t) {
        var n = this,
          o = n.instance,
          i = o.current;
        i && (!0 === t || i.opts.loop || o.currIndex < o.group.length - 1)
          ? n.isActive &&
            'video' !== i.contentType &&
            (n.$progress &&
              e.fancybox.animate(
                n.$progress.show(),
                { scaleX: 1 },
                i.opts.slideShow.speed
              ),
            (n.timer = setTimeout(function () {
              o.current.opts.loop || o.current.index != o.group.length - 1
                ? o.next()
                : o.jumpTo(0);
            }, i.opts.slideShow.speed)))
          : (n.stop(), (o.idleSecondsCounter = 0), o.showControls());
      },
      clear: function () {
        var t = this;
        clearTimeout(t.timer),
          (t.timer = null),
          t.$progress && t.$progress.removeAttr('style').hide();
      },
      start: function () {
        var t = this,
          e = t.instance.current;
        e &&
          (t.$button
            .attr(
              'title',
              (e.opts.i18n[e.opts.lang] || e.opts.i18n.en).PLAY_STOP
            )
            .removeClass('fancybox-button--play')
            .addClass('fancybox-button--pause'),
          (t.isActive = !0),
          e.isComplete && t.set(!0),
          t.instance.trigger('onSlideShowChange', !0));
      },
      stop: function () {
        var t = this,
          e = t.instance.current;
        t.clear(),
          t.$button
            .attr(
              'title',
              (e.opts.i18n[e.opts.lang] || e.opts.i18n.en).PLAY_START
            )
            .removeClass('fancybox-button--pause')
            .addClass('fancybox-button--play'),
          (t.isActive = !1),
          t.instance.trigger('onSlideShowChange', !1),
          t.$progress && t.$progress.removeAttr('style').hide();
      },
      toggle: function () {
        var t = this;
        t.isActive ? t.stop() : t.start();
      },
    }),
      e(t).on({
        'onInit.fb': function (t, e) {
          e && !e.SlideShow && (e.SlideShow = new n(e));
        },
        'beforeShow.fb': function (t, e, n, o) {
          var i = e && e.SlideShow;
          o
            ? i && n.opts.slideShow.autoStart && i.start()
            : i && i.isActive && i.clear();
        },
        'afterShow.fb': function (t, e, n) {
          var o = e && e.SlideShow;
          o && o.isActive && o.set();
        },
        'afterKeydown.fb': function (n, o, i, a, s) {
          var r = o && o.SlideShow;
          !r ||
            !i.opts.slideShow ||
            (80 !== s && 32 !== s) ||
            e(t.activeElement).is('button,a,input') ||
            (a.preventDefault(), r.toggle());
        },
        'beforeClose.fb onDeactivate.fb': function (t, e) {
          var n = e && e.SlideShow;
          n && n.stop();
        },
      }),
      e(t).on('visibilitychange', function () {
        var n = e.fancybox.getInstance(),
          o = n && n.SlideShow;
        o && o.isActive && (t.hidden ? o.clear() : o.set());
      });
  })(document, jQuery),
  (function (t, e) {
    'use strict';
    var n = (function () {
      for (
        var e = [
            [
              'requestFullscreen',
              'exitFullscreen',
              'fullscreenElement',
              'fullscreenEnabled',
              'fullscreenchange',
              'fullscreenerror',
            ],
            [
              'webkitRequestFullscreen',
              'webkitExitFullscreen',
              'webkitFullscreenElement',
              'webkitFullscreenEnabled',
              'webkitfullscreenchange',
              'webkitfullscreenerror',
            ],
            [
              'webkitRequestFullScreen',
              'webkitCancelFullScreen',
              'webkitCurrentFullScreenElement',
              'webkitCancelFullScreen',
              'webkitfullscreenchange',
              'webkitfullscreenerror',
            ],
            [
              'mozRequestFullScreen',
              'mozCancelFullScreen',
              'mozFullScreenElement',
              'mozFullScreenEnabled',
              'mozfullscreenchange',
              'mozfullscreenerror',
            ],
            [
              'msRequestFullscreen',
              'msExitFullscreen',
              'msFullscreenElement',
              'msFullscreenEnabled',
              'MSFullscreenChange',
              'MSFullscreenError',
            ],
          ],
          n = {},
          o = 0;
        o < e.length;
        o++
      ) {
        var i = e[o];
        if (i && i[1] in t) {
          for (var a = 0; a < i.length; a++) n[e[0][a]] = i[a];
          return n;
        }
      }
      return !1;
    })();
    if (n) {
      var o = {
        request: function (e) {
          (e = e || t.documentElement),
            e[n.requestFullscreen](e.ALLOW_KEYBOARD_INPUT);
        },
        exit: function () {
          t[n.exitFullscreen]();
        },
        toggle: function (e) {
          (e = e || t.documentElement),
            this.isFullscreen() ? this.exit() : this.request(e);
        },
        isFullscreen: function () {
          return Boolean(t[n.fullscreenElement]);
        },
        enabled: function () {
          return Boolean(t[n.fullscreenEnabled]);
        },
      };
      e.extend(!0, e.fancybox.defaults, {
        btnTpl: {
          fullScreen:
            '<button data-fancybox-fullscreen class="fancybox-button fancybox-button--fsenter" title="{{FULL_SCREEN}}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/></svg><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 16h3v3h2v-5H5zm3-8H5v2h5V5H8zm6 11h2v-3h3v-2h-5zm2-11V5h-2v5h5V8z"/></svg></button>',
        },
        fullScreen: { autoStart: !1 },
      }),
        e(t).on(n.fullscreenchange, function () {
          var t = o.isFullscreen(),
            n = e.fancybox.getInstance();
          n &&
            (n.current &&
              'image' === n.current.type &&
              n.isAnimating &&
              ((n.isAnimating = !1),
              n.update(!0, !0, 0),
              n.isComplete || n.complete()),
            n.trigger('onFullscreenChange', t),
            n.$refs.container.toggleClass('fancybox-is-fullscreen', t),
            n.$refs.toolbar
              .find('[data-fancybox-fullscreen]')
              .toggleClass('fancybox-button--fsenter', !t)
              .toggleClass('fancybox-button--fsexit', t));
        });
    }
    e(t).on({
      'onInit.fb': function (t, e) {
        var i;
        return n
          ? void (e && e.group[e.currIndex].opts.fullScreen
              ? ((i = e.$refs.container),
                i.on(
                  'click.fb-fullscreen',
                  '[data-fancybox-fullscreen]',
                  function (t) {
                    t.stopPropagation(), t.preventDefault(), o.toggle();
                  }
                ),
                e.opts.fullScreen &&
                  !0 === e.opts.fullScreen.autoStart &&
                  o.request(),
                (e.FullScreen = o))
              : e && e.$refs.toolbar.find('[data-fancybox-fullscreen]').hide())
          : void e.$refs.toolbar.find('[data-fancybox-fullscreen]').remove();
      },
      'afterKeydown.fb': function (t, e, n, o, i) {
        e &&
          e.FullScreen &&
          70 === i &&
          (o.preventDefault(), e.FullScreen.toggle());
      },
      'beforeClose.fb': function (t, e) {
        e &&
          e.FullScreen &&
          e.$refs.container.hasClass('fancybox-is-fullscreen') &&
          o.exit();
      },
    });
  })(document, jQuery),
  (function (t, e) {
    'use strict';
    var n = 'fancybox-thumbs';
    e.fancybox.defaults = e.extend(
      !0,
      {
        btnTpl: {
          thumbs:
            '<button data-fancybox-thumbs class="fancybox-button fancybox-button--thumbs" title="{{THUMBS}}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M14.59 14.59h3.76v3.76h-3.76v-3.76zm-4.47 0h3.76v3.76h-3.76v-3.76zm-4.47 0h3.76v3.76H5.65v-3.76zm8.94-4.47h3.76v3.76h-3.76v-3.76zm-4.47 0h3.76v3.76h-3.76v-3.76zm-4.47 0h3.76v3.76H5.65v-3.76zm8.94-4.47h3.76v3.76h-3.76V5.65zm-4.47 0h3.76v3.76h-3.76V5.65zm-4.47 0h3.76v3.76H5.65V5.65z"/></svg></button>',
        },
        thumbs: {
          autoStart: !1,
          hideOnClose: !0,
          parentEl: '.fancybox-container',
          axis: 'y',
        },
      },
      e.fancybox.defaults
    );
    var o = function (t) {
      this.init(t);
    };
    e.extend(o.prototype, {
      $button: null,
      $grid: null,
      $list: null,
      isVisible: !1,
      isActive: !1,
      init: function (t) {
        var e = this,
          n = t.group,
          o = 0;
        (e.instance = t),
          (e.opts = n[t.currIndex].opts.thumbs),
          (t.Thumbs = e),
          (e.$button = t.$refs.toolbar.find('[data-fancybox-thumbs]'));
        for (
          var i = 0, a = n.length;
          i < a && (n[i].thumb && o++, !(o > 1));
          i++
        );
        o > 1 && e.opts
          ? (e.$button.removeAttr('style').on('click', function () {
              e.toggle();
            }),
            (e.isActive = !0))
          : e.$button.hide();
      },
      create: function () {
        var t,
          o = this,
          i = o.instance,
          a = o.opts.parentEl,
          s = [];
        o.$grid ||
          ((o.$grid = e(
            '<div class="' + n + ' ' + n + '-' + o.opts.axis + '"></div>'
          ).appendTo(i.$refs.container.find(a).addBack().filter(a))),
          o.$grid.on('click', 'a', function () {
            i.jumpTo(e(this).attr('data-index'));
          })),
          o.$list ||
            (o.$list = e('<div class="' + n + '__list">').appendTo(o.$grid)),
          e.each(i.group, function (e, n) {
            (t = n.thumb),
              t || 'image' !== n.type || (t = n.src),
              s.push(
                '<a href="javascript:;" tabindex="0" data-index="' +
                  e +
                  '"' +
                  (t && t.length
                    ? ' style="background-image:url(' + t + ')"'
                    : 'class="fancybox-thumbs-missing"') +
                  '></a>'
              );
          }),
          (o.$list[0].innerHTML = s.join('')),
          'x' === o.opts.axis &&
            o.$list.width(
              parseInt(o.$grid.css('padding-right'), 10) +
                i.group.length * o.$list.children().eq(0).outerWidth(!0)
            );
      },
      focus: function (t) {
        var e,
          n,
          o = this,
          i = o.$list,
          a = o.$grid;
        o.instance.current &&
          ((e = i
            .children()
            .removeClass('fancybox-thumbs-active')
            .filter('[data-index="' + o.instance.current.index + '"]')
            .addClass('fancybox-thumbs-active')),
          (n = e.position()),
          'y' === o.opts.axis &&
          (n.top < 0 || n.top > i.height() - e.outerHeight())
            ? i.stop().animate({ scrollTop: i.scrollTop() + n.top }, t)
            : 'x' === o.opts.axis &&
              (n.left < a.scrollLeft() ||
                n.left > a.scrollLeft() + (a.width() - e.outerWidth())) &&
              i.parent().stop().animate({ scrollLeft: n.left }, t));
      },
      update: function () {
        var t = this;
        t.instance.$refs.container.toggleClass(
          'fancybox-show-thumbs',
          this.isVisible
        ),
          t.isVisible
            ? (t.$grid || t.create(),
              t.instance.trigger('onThumbsShow'),
              t.focus(0))
            : t.$grid && t.instance.trigger('onThumbsHide'),
          t.instance.update();
      },
      hide: function () {
        (this.isVisible = !1), this.update();
      },
      show: function () {
        (this.isVisible = !0), this.update();
      },
      toggle: function () {
        (this.isVisible = !this.isVisible), this.update();
      },
    }),
      e(t).on({
        'onInit.fb': function (t, e) {
          var n;
          e &&
            !e.Thumbs &&
            ((n = new o(e)), n.isActive && !0 === n.opts.autoStart && n.show());
        },
        'beforeShow.fb': function (t, e, n, o) {
          var i = e && e.Thumbs;
          i && i.isVisible && i.focus(o ? 0 : 250);
        },
        'afterKeydown.fb': function (t, e, n, o, i) {
          var a = e && e.Thumbs;
          a && a.isActive && 71 === i && (o.preventDefault(), a.toggle());
        },
        'beforeClose.fb': function (t, e) {
          var n = e && e.Thumbs;
          n && n.isVisible && !1 !== n.opts.hideOnClose && n.$grid.hide();
        },
      });
  })(document, jQuery),
  (function (t, e) {
    'use strict';
    function n(t) {
      var e = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;',
        '/': '&#x2F;',
        '`': '&#x60;',
        '=': '&#x3D;',
      };
      return String(t).replace(/[&<>"'`=\/]/g, function (t) {
        return e[t];
      });
    }
    e.extend(!0, e.fancybox.defaults, {
      btnTpl: {
        share:
          '<button data-fancybox-share class="fancybox-button fancybox-button--share" title="{{SHARE}}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M2.55 19c1.4-8.4 9.1-9.8 11.9-9.8V5l7 7-7 6.3v-3.5c-2.8 0-10.5 2.1-11.9 4.2z"/></svg></button>',
      },
      share: {
        url: function (t, e) {
          return (
            (!t.currentHash &&
              'inline' !== e.type &&
              'html' !== e.type &&
              (e.origSrc || e.src)) ||
            window.location
          );
        },
        tpl: '<div class="fancybox-share"><h1>{{SHARE}}</h1><p><a class="fancybox-share__button fancybox-share__button--fb" href="https://www.facebook.com/sharer/sharer.php?u={{url}}"><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m287 456v-299c0-21 6-35 35-35h38v-63c-7-1-29-3-55-3-54 0-91 33-91 94v306m143-254h-205v72h196" /></svg><span>Facebook</span></a><a class="fancybox-share__button fancybox-share__button--tw" href="https://twitter.com/intent/tweet?url={{url}}&text={{descr}}"><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m456 133c-14 7-31 11-47 13 17-10 30-27 37-46-15 10-34 16-52 20-61-62-157-7-141 75-68-3-129-35-169-85-22 37-11 86 26 109-13 0-26-4-37-9 0 39 28 72 65 80-12 3-25 4-37 2 10 33 41 57 77 57-42 30-77 38-122 34 170 111 378-32 359-208 16-11 30-25 41-42z" /></svg><span>Twitter</span></a><a class="fancybox-share__button fancybox-share__button--pt" href="https://www.pinterest.com/pin/create/button/?url={{url}}&description={{descr}}&media={{media}}"><svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m265 56c-109 0-164 78-164 144 0 39 15 74 47 87 5 2 10 0 12-5l4-19c2-6 1-8-3-13-9-11-15-25-15-45 0-58 43-110 113-110 62 0 96 38 96 88 0 67-30 122-73 122-24 0-42-19-36-44 6-29 20-60 20-81 0-19-10-35-31-35-25 0-44 26-44 60 0 21 7 36 7 36l-30 125c-8 37-1 83 0 87 0 3 4 4 5 2 2-3 32-39 42-75l16-64c8 16 31 29 56 29 74 0 124-67 124-157 0-69-58-132-146-132z" fill="#fff"/></svg><span>Pinterest</span></a></p><p><input class="fancybox-share__input" type="text" value="{{url_raw}}" onclick="select()" /></p></div>',
      },
    }),
      e(t).on('click', '[data-fancybox-share]', function () {
        var t,
          o,
          i = e.fancybox.getInstance(),
          a = i.current || null;
        a &&
          ('function' === e.type(a.opts.share.url) &&
            (t = a.opts.share.url.apply(a, [i, a])),
          (o = a.opts.share.tpl
            .replace(
              /\{\{media\}\}/g,
              'image' === a.type ? encodeURIComponent(a.src) : ''
            )
            .replace(/\{\{url\}\}/g, encodeURIComponent(t))
            .replace(/\{\{url_raw\}\}/g, n(t))
            .replace(
              /\{\{descr\}\}/g,
              i.$caption ? encodeURIComponent(i.$caption.text()) : ''
            )),
          e.fancybox.open({
            src: i.translate(i, o),
            type: 'html',
            opts: {
              touch: !1,
              animationEffect: !1,
              afterLoad: function (t, e) {
                i.$refs.container.one('beforeClose.fb', function () {
                  t.close(null, 0);
                }),
                  e.$content.find('.fancybox-share__button').click(function () {
                    return (
                      window.open(this.href, 'Share', 'width=550, height=450'),
                      !1
                    );
                  });
              },
              mobile: { autoFocus: !1 },
            },
          }));
      });
  })(document, jQuery),
  (function (t, e, n) {
    'use strict';
    function o() {
      var e = t.location.hash.substr(1),
        n = e.split('-'),
        o =
          n.length > 1 && /^\+?\d+$/.test(n[n.length - 1])
            ? parseInt(n.pop(-1), 10) || 1
            : 1,
        i = n.join('-');
      return { hash: e, index: o < 1 ? 1 : o, gallery: i };
    }
    function i(t) {
      '' !== t.gallery &&
        n("[data-fancybox='" + n.escapeSelector(t.gallery) + "']")
          .eq(t.index - 1)
          .focus()
          .trigger('click.fb-start');
    }
    function a(t) {
      var e, n;
      return (
        !!t &&
        ((e = t.current ? t.current.opts : t.opts),
        '' !==
          (n =
            e.hash ||
            (e.$orig
              ? e.$orig.data('fancybox') || e.$orig.data('fancybox-trigger')
              : '')) && n)
      );
    }
    n.escapeSelector ||
      (n.escapeSelector = function (t) {
        return (t + '').replace(
          /([\0-\x1f\x7f]|^-?\d)|^-$|[^\x80-\uFFFF\w-]/g,
          function (t, e) {
            return e
              ? '\0' === t
                ? '�'
                : t.slice(0, -1) +
                  '\\' +
                  t.charCodeAt(t.length - 1).toString(16) +
                  ' '
              : '\\' + t;
          }
        );
      }),
      n(function () {
        !1 !== n.fancybox.defaults.hash &&
          (n(e).on({
            'onInit.fb': function (t, e) {
              var n, i;
              !1 !== e.group[e.currIndex].opts.hash &&
                ((n = o()),
                (i = a(e)) &&
                  n.gallery &&
                  i == n.gallery &&
                  (e.currIndex = n.index - 1));
            },
            'beforeShow.fb': function (n, o, i, s) {
              var r;
              i &&
                !1 !== i.opts.hash &&
                (r = a(o)) &&
                ((o.currentHash =
                  r + (o.group.length > 1 ? '-' + (i.index + 1) : '')),
                t.location.hash !== '#' + o.currentHash &&
                  (s && !o.origHash && (o.origHash = t.location.hash),
                  o.hashTimer && clearTimeout(o.hashTimer),
                  (o.hashTimer = setTimeout(function () {
                    'replaceState' in t.history
                      ? (t.history[s ? 'pushState' : 'replaceState'](
                          {},
                          e.title,
                          t.location.pathname +
                            t.location.search +
                            '#' +
                            o.currentHash
                        ),
                        s && (o.hasCreatedHistory = !0))
                      : (t.location.hash = o.currentHash),
                      (o.hashTimer = null);
                  }, 300))));
            },
            'beforeClose.fb': function (n, o, i) {
              i &&
                !1 !== i.opts.hash &&
                (clearTimeout(o.hashTimer),
                o.currentHash && o.hasCreatedHistory
                  ? t.history.back()
                  : o.currentHash &&
                    ('replaceState' in t.history
                      ? t.history.replaceState(
                          {},
                          e.title,
                          t.location.pathname +
                            t.location.search +
                            (o.origHash || '')
                        )
                      : (t.location.hash = o.origHash)),
                (o.currentHash = null));
            },
          }),
          n(t).on('hashchange.fb', function () {
            var t = o(),
              e = null;
            n.each(n('.fancybox-container').get().reverse(), function (t, o) {
              var i = n(o).data('FancyBox');
              if (i && i.currentHash) return (e = i), !1;
            }),
              e
                ? e.currentHash === t.gallery + '-' + t.index ||
                  (1 === t.index && e.currentHash == t.gallery) ||
                  ((e.currentHash = null), e.close())
                : '' !== t.gallery && i(t);
          }),
          setTimeout(function () {
            n.fancybox.getInstance() || i(o());
          }, 50));
      });
  })(window, document, jQuery),
  (function (t, e) {
    'use strict';
    var n = new Date().getTime();
    e(t).on({
      'onInit.fb': function (t, e, o) {
        e.$refs.stage.on(
          'mousewheel DOMMouseScroll wheel MozMousePixelScroll',
          function (t) {
            var o = e.current,
              i = new Date().getTime();
            e.group.length < 2 ||
              !1 === o.opts.wheel ||
              ('auto' === o.opts.wheel && 'image' !== o.type) ||
              (t.preventDefault(),
              t.stopPropagation(),
              o.$slide.hasClass('fancybox-animated') ||
                ((t = t.originalEvent || t),
                i - n < 250 ||
                  ((n = i),
                  e[
                    (-t.deltaY || -t.deltaX || t.wheelDelta || -t.detail) < 0
                      ? 'next'
                      : 'previous'
                  ]())));
          }
        );
      },
    });
  })(document, jQuery);
!(function (e, t) {
  'object' == typeof exports && 'object' == typeof module
    ? (module.exports = t())
    : 'function' == typeof define && define.amd
    ? define([], t)
    : 'object' == typeof exports
    ? (exports.AOS = t())
    : (e.AOS = t());
})(this, function () {
  return (function (e) {
    function t(o) {
      if (n[o]) return n[o].exports;
      var i = (n[o] = { exports: {}, id: o, loaded: !1 });
      return e[o].call(i.exports, i, i.exports, t), (i.loaded = !0), i.exports;
    }
    var n = {};
    return (t.m = e), (t.c = n), (t.p = 'dist/'), t(0);
  })([
    function (e, t, n) {
      'use strict';
      function o(e) {
        return e && e.__esModule ? e : { default: e };
      }
      var i =
          Object.assign ||
          function (e) {
            for (var t = 1; t < arguments.length; t++) {
              var n = arguments[t];
              for (var o in n)
                Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
            }
            return e;
          },
        r = n(1),
        a = (o(r), n(6)),
        u = o(a),
        c = n(7),
        s = o(c),
        f = n(8),
        d = o(f),
        l = n(9),
        p = o(l),
        m = n(10),
        b = o(m),
        v = n(11),
        y = o(v),
        g = n(14),
        h = o(g),
        w = [],
        k = !1,
        x = {
          offset: 120,
          delay: 0,
          easing: 'ease',
          duration: 400,
          disable: !1,
          once: !1,
          startEvent: 'DOMContentLoaded',
          throttleDelay: 99,
          debounceDelay: 50,
          disableMutationObserver: !1,
        },
        j = function () {
          var e =
            arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
          if ((e && (k = !0), k))
            return (
              (w = (0, y['default'])(w, x)), (0, b['default'])(w, x.once), w
            );
        },
        O = function () {
          (w = (0, h['default'])()), j();
        },
        M = function () {
          w.forEach(function (e, t) {
            e.node.removeAttribute('data-aos'),
              e.node.removeAttribute('data-aos-easing'),
              e.node.removeAttribute('data-aos-duration'),
              e.node.removeAttribute('data-aos-delay');
          });
        },
        S = function (e) {
          return (
            e === !0 ||
            ('mobile' === e && p['default'].mobile()) ||
            ('phone' === e && p['default'].phone()) ||
            ('tablet' === e && p['default'].tablet()) ||
            ('function' == typeof e && e() === !0)
          );
        },
        _ = function (e) {
          (x = i(x, e)), (w = (0, h['default'])());
          var t = document.all && !window.atob;
          return S(x.disable) || t
            ? M()
            : (x.disableMutationObserver ||
                d['default'].isSupported() ||
                (console.info(
                  '\n      aos: MutationObserver is not supported on this browser,\n      code mutations observing has been disabled.\n      You may have to call "refreshHard()" by yourself.\n    '
                ),
                (x.disableMutationObserver = !0)),
              document
                .querySelector('body')
                .setAttribute('data-aos-easing', x.easing),
              document
                .querySelector('body')
                .setAttribute('data-aos-duration', x.duration),
              document
                .querySelector('body')
                .setAttribute('data-aos-delay', x.delay),
              'DOMContentLoaded' === x.startEvent &&
              ['complete', 'interactive'].indexOf(document.readyState) > -1
                ? j(!0)
                : 'load' === x.startEvent
                ? window.addEventListener(x.startEvent, function () {
                    j(!0);
                  })
                : document.addEventListener(x.startEvent, function () {
                    j(!0);
                  }),
              window.addEventListener(
                'resize',
                (0, s['default'])(j, x.debounceDelay, !0)
              ),
              window.addEventListener(
                'orientationchange',
                (0, s['default'])(j, x.debounceDelay, !0)
              ),
              window.addEventListener(
                'scroll',
                (0, u['default'])(function () {
                  (0, b['default'])(w, x.once);
                }, x.throttleDelay)
              ),
              x.disableMutationObserver || d['default'].ready('[data-aos]', O),
              w);
        };
      e.exports = { init: _, refresh: j, refreshHard: O };
    },
    function (e, t) {},
    ,
    ,
    ,
    ,
    function (e, t) {
      (function (t) {
        'use strict';
        function n(e, t, n) {
          function o(t) {
            var n = b,
              o = v;
            return (b = v = void 0), (k = t), (g = e.apply(o, n));
          }
          function r(e) {
            return (k = e), (h = setTimeout(f, t)), M ? o(e) : g;
          }
          function a(e) {
            var n = e - w,
              o = e - k,
              i = t - n;
            return S ? j(i, y - o) : i;
          }
          function c(e) {
            var n = e - w,
              o = e - k;
            return void 0 === w || n >= t || n < 0 || (S && o >= y);
          }
          function f() {
            var e = O();
            return c(e) ? d(e) : void (h = setTimeout(f, a(e)));
          }
          function d(e) {
            return (h = void 0), _ && b ? o(e) : ((b = v = void 0), g);
          }
          function l() {
            void 0 !== h && clearTimeout(h), (k = 0), (b = w = v = h = void 0);
          }
          function p() {
            return void 0 === h ? g : d(O());
          }
          function m() {
            var e = O(),
              n = c(e);
            if (((b = arguments), (v = this), (w = e), n)) {
              if (void 0 === h) return r(w);
              if (S) return (h = setTimeout(f, t)), o(w);
            }
            return void 0 === h && (h = setTimeout(f, t)), g;
          }
          var b,
            v,
            y,
            g,
            h,
            w,
            k = 0,
            M = !1,
            S = !1,
            _ = !0;
          if ('function' != typeof e) throw new TypeError(s);
          return (
            (t = u(t) || 0),
            i(n) &&
              ((M = !!n.leading),
              (S = 'maxWait' in n),
              (y = S ? x(u(n.maxWait) || 0, t) : y),
              (_ = 'trailing' in n ? !!n.trailing : _)),
            (m.cancel = l),
            (m.flush = p),
            m
          );
        }
        function o(e, t, o) {
          var r = !0,
            a = !0;
          if ('function' != typeof e) throw new TypeError(s);
          return (
            i(o) &&
              ((r = 'leading' in o ? !!o.leading : r),
              (a = 'trailing' in o ? !!o.trailing : a)),
            n(e, t, { leading: r, maxWait: t, trailing: a })
          );
        }
        function i(e) {
          var t = 'undefined' == typeof e ? 'undefined' : c(e);
          return !!e && ('object' == t || 'function' == t);
        }
        function r(e) {
          return (
            !!e && 'object' == ('undefined' == typeof e ? 'undefined' : c(e))
          );
        }
        function a(e) {
          return (
            'symbol' == ('undefined' == typeof e ? 'undefined' : c(e)) ||
            (r(e) && k.call(e) == d)
          );
        }
        function u(e) {
          if ('number' == typeof e) return e;
          if (a(e)) return f;
          if (i(e)) {
            var t = 'function' == typeof e.valueOf ? e.valueOf() : e;
            e = i(t) ? t + '' : t;
          }
          if ('string' != typeof e) return 0 === e ? e : +e;
          e = e.replace(l, '');
          var n = m.test(e);
          return n || b.test(e) ? v(e.slice(2), n ? 2 : 8) : p.test(e) ? f : +e;
        }
        var c =
            'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
              ? function (e) {
                  return typeof e;
                }
              : function (e) {
                  return e &&
                    'function' == typeof Symbol &&
                    e.constructor === Symbol &&
                    e !== Symbol.prototype
                    ? 'symbol'
                    : typeof e;
                },
          s = 'Expected a function',
          f = NaN,
          d = '[object Symbol]',
          l = /^\s+|\s+$/g,
          p = /^[-+]0x[0-9a-f]+$/i,
          m = /^0b[01]+$/i,
          b = /^0o[0-7]+$/i,
          v = parseInt,
          y =
            'object' == ('undefined' == typeof t ? 'undefined' : c(t)) &&
            t &&
            t.Object === Object &&
            t,
          g =
            'object' == ('undefined' == typeof self ? 'undefined' : c(self)) &&
            self &&
            self.Object === Object &&
            self,
          h = y || g || Function('return this')(),
          w = Object.prototype,
          k = w.toString,
          x = Math.max,
          j = Math.min,
          O = function () {
            return h.Date.now();
          };
        e.exports = o;
      }).call(
        t,
        (function () {
          return this;
        })()
      );
    },
    function (e, t) {
      (function (t) {
        'use strict';
        function n(e, t, n) {
          function i(t) {
            var n = b,
              o = v;
            return (b = v = void 0), (O = t), (g = e.apply(o, n));
          }
          function r(e) {
            return (O = e), (h = setTimeout(f, t)), M ? i(e) : g;
          }
          function u(e) {
            var n = e - w,
              o = e - O,
              i = t - n;
            return S ? x(i, y - o) : i;
          }
          function s(e) {
            var n = e - w,
              o = e - O;
            return void 0 === w || n >= t || n < 0 || (S && o >= y);
          }
          function f() {
            var e = j();
            return s(e) ? d(e) : void (h = setTimeout(f, u(e)));
          }
          function d(e) {
            return (h = void 0), _ && b ? i(e) : ((b = v = void 0), g);
          }
          function l() {
            void 0 !== h && clearTimeout(h), (O = 0), (b = w = v = h = void 0);
          }
          function p() {
            return void 0 === h ? g : d(j());
          }
          function m() {
            var e = j(),
              n = s(e);
            if (((b = arguments), (v = this), (w = e), n)) {
              if (void 0 === h) return r(w);
              if (S) return (h = setTimeout(f, t)), i(w);
            }
            return void 0 === h && (h = setTimeout(f, t)), g;
          }
          var b,
            v,
            y,
            g,
            h,
            w,
            O = 0,
            M = !1,
            S = !1,
            _ = !0;
          if ('function' != typeof e) throw new TypeError(c);
          return (
            (t = a(t) || 0),
            o(n) &&
              ((M = !!n.leading),
              (S = 'maxWait' in n),
              (y = S ? k(a(n.maxWait) || 0, t) : y),
              (_ = 'trailing' in n ? !!n.trailing : _)),
            (m.cancel = l),
            (m.flush = p),
            m
          );
        }
        function o(e) {
          var t = 'undefined' == typeof e ? 'undefined' : u(e);
          return !!e && ('object' == t || 'function' == t);
        }
        function i(e) {
          return (
            !!e && 'object' == ('undefined' == typeof e ? 'undefined' : u(e))
          );
        }
        function r(e) {
          return (
            'symbol' == ('undefined' == typeof e ? 'undefined' : u(e)) ||
            (i(e) && w.call(e) == f)
          );
        }
        function a(e) {
          if ('number' == typeof e) return e;
          if (r(e)) return s;
          if (o(e)) {
            var t = 'function' == typeof e.valueOf ? e.valueOf() : e;
            e = o(t) ? t + '' : t;
          }
          if ('string' != typeof e) return 0 === e ? e : +e;
          e = e.replace(d, '');
          var n = p.test(e);
          return n || m.test(e) ? b(e.slice(2), n ? 2 : 8) : l.test(e) ? s : +e;
        }
        var u =
            'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
              ? function (e) {
                  return typeof e;
                }
              : function (e) {
                  return e &&
                    'function' == typeof Symbol &&
                    e.constructor === Symbol &&
                    e !== Symbol.prototype
                    ? 'symbol'
                    : typeof e;
                },
          c = 'Expected a function',
          s = NaN,
          f = '[object Symbol]',
          d = /^\s+|\s+$/g,
          l = /^[-+]0x[0-9a-f]+$/i,
          p = /^0b[01]+$/i,
          m = /^0o[0-7]+$/i,
          b = parseInt,
          v =
            'object' == ('undefined' == typeof t ? 'undefined' : u(t)) &&
            t &&
            t.Object === Object &&
            t,
          y =
            'object' == ('undefined' == typeof self ? 'undefined' : u(self)) &&
            self &&
            self.Object === Object &&
            self,
          g = v || y || Function('return this')(),
          h = Object.prototype,
          w = h.toString,
          k = Math.max,
          x = Math.min,
          j = function () {
            return g.Date.now();
          };
        e.exports = n;
      }).call(
        t,
        (function () {
          return this;
        })()
      );
    },
    function (e, t) {
      'use strict';
      function n(e) {
        var t = void 0,
          o = void 0,
          i = void 0;
        for (t = 0; t < e.length; t += 1) {
          if (((o = e[t]), o.dataset && o.dataset.aos)) return !0;
          if ((i = o.children && n(o.children))) return !0;
        }
        return !1;
      }
      function o() {
        return (
          window.MutationObserver ||
          window.WebKitMutationObserver ||
          window.MozMutationObserver
        );
      }
      function i() {
        return !!o();
      }
      function r(e, t) {
        var n = window.document,
          i = o(),
          r = new i(a);
        (u = t),
          r.observe(n.documentElement, {
            childList: !0,
            subtree: !0,
            removedNodes: !0,
          });
      }
      function a(e) {
        e &&
          e.forEach(function (e) {
            var t = Array.prototype.slice.call(e.addedNodes),
              o = Array.prototype.slice.call(e.removedNodes),
              i = t.concat(o);
            if (n(i)) return u();
          });
      }
      Object.defineProperty(t, '__esModule', { value: !0 });
      var u = function () {};
      t['default'] = { isSupported: i, ready: r };
    },
    function (e, t) {
      'use strict';
      function n(e, t) {
        if (!(e instanceof t))
          throw new TypeError('Cannot call a class as a function');
      }
      function o() {
        return navigator.userAgent || navigator.vendor || window.opera || '';
      }
      Object.defineProperty(t, '__esModule', { value: !0 });
      var i = (function () {
          function e(e, t) {
            for (var n = 0; n < t.length; n++) {
              var o = t[n];
              (o.enumerable = o.enumerable || !1),
                (o.configurable = !0),
                'value' in o && (o.writable = !0),
                Object.defineProperty(e, o.key, o);
            }
          }
          return function (t, n, o) {
            return n && e(t.prototype, n), o && e(t, o), t;
          };
        })(),
        r =
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i,
        a =
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i,
        u =
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i,
        c =
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i,
        s = (function () {
          function e() {
            n(this, e);
          }
          return (
            i(e, [
              {
                key: 'phone',
                value: function () {
                  var e = o();
                  return !(!r.test(e) && !a.test(e.substr(0, 4)));
                },
              },
              {
                key: 'mobile',
                value: function () {
                  var e = o();
                  return !(!u.test(e) && !c.test(e.substr(0, 4)));
                },
              },
              {
                key: 'tablet',
                value: function () {
                  return this.mobile() && !this.phone();
                },
              },
            ]),
            e
          );
        })();
      t['default'] = new s();
    },
    function (e, t) {
      'use strict';
      Object.defineProperty(t, '__esModule', { value: !0 });
      var n = function (e, t, n) {
          var o = e.node.getAttribute('data-aos-once');
          t > e.position
            ? e.node.classList.add('aos-animate')
            : 'undefined' != typeof o &&
              ('false' === o || (!n && 'true' !== o)) &&
              e.node.classList.remove('aos-animate');
        },
        o = function (e, t) {
          var o = window.pageYOffset,
            i = window.innerHeight;
          e.forEach(function (e, r) {
            n(e, i + o, t);
          });
        };
      t['default'] = o;
    },
    function (e, t, n) {
      'use strict';
      function o(e) {
        return e && e.__esModule ? e : { default: e };
      }
      Object.defineProperty(t, '__esModule', { value: !0 });
      var i = n(12),
        r = o(i),
        a = function (e, t) {
          return (
            e.forEach(function (e, n) {
              e.node.classList.add('aos-init'),
                (e.position = (0, r['default'])(e.node, t.offset));
            }),
            e
          );
        };
      t['default'] = a;
    },
    function (e, t, n) {
      'use strict';
      function o(e) {
        return e && e.__esModule ? e : { default: e };
      }
      Object.defineProperty(t, '__esModule', { value: !0 });
      var i = n(13),
        r = o(i),
        a = function (e, t) {
          var n = 0,
            o = 0,
            i = window.innerHeight,
            a = {
              offset: e.getAttribute('data-aos-offset'),
              anchor: e.getAttribute('data-aos-anchor'),
              anchorPlacement: e.getAttribute('data-aos-anchor-placement'),
            };
          switch (
            (a.offset && !isNaN(a.offset) && (o = parseInt(a.offset)),
            a.anchor &&
              document.querySelectorAll(a.anchor) &&
              (e = document.querySelectorAll(a.anchor)[0]),
            (n = (0, r['default'])(e).top),
            a.anchorPlacement)
          ) {
            case 'top-bottom':
              break;
            case 'center-bottom':
              n += e.offsetHeight / 2;
              break;
            case 'bottom-bottom':
              n += e.offsetHeight;
              break;
            case 'top-center':
              n += i / 2;
              break;
            case 'bottom-center':
              n += i / 2 + e.offsetHeight;
              break;
            case 'center-center':
              n += i / 2 + e.offsetHeight / 2;
              break;
            case 'top-top':
              n += i;
              break;
            case 'bottom-top':
              n += e.offsetHeight + i;
              break;
            case 'center-top':
              n += e.offsetHeight / 2 + i;
          }
          return a.anchorPlacement || a.offset || isNaN(t) || (o = t), n + o;
        };
      t['default'] = a;
    },
    function (e, t) {
      'use strict';
      Object.defineProperty(t, '__esModule', { value: !0 });
      var n = function (e) {
        for (
          var t = 0, n = 0;
          e && !isNaN(e.offsetLeft) && !isNaN(e.offsetTop);

        )
          (t += e.offsetLeft - ('BODY' != e.tagName ? e.scrollLeft : 0)),
            (n += e.offsetTop - ('BODY' != e.tagName ? e.scrollTop : 0)),
            (e = e.offsetParent);
        return { top: n, left: t };
      };
      t['default'] = n;
    },
    function (e, t) {
      'use strict';
      Object.defineProperty(t, '__esModule', { value: !0 });
      var n = function (e) {
        return (
          (e = e || document.querySelectorAll('[data-aos]')),
          Array.prototype.map.call(e, function (e) {
            return { node: e };
          })
        );
      };
      t['default'] = n;
    },
  ]);
});
!(function (e) {
  'function' == typeof define && define.amd ? define(['jquery'], e) : e(jQuery);
})(function (e) {
  function t(t, o, n) {
    var i = o.hash.slice(1),
      a = document.getElementById(i) || document.getElementsByName(i)[0];
    if (a) {
      t && t.preventDefault();
      var l = e(n.target);
      if (
        !(
          (n.lock && l.is(':animated')) ||
          (n.onBefore && n.onBefore(t, a, l) === !1)
        )
      ) {
        if ((n.stop && l.stop(!0), n.hash)) {
          var r = a.id === i ? 'id' : 'name',
            s = e('<a> </a>')
              .attr(r, i)
              .css({
                position: 'absolute',
                top: e(window).scrollTop(),
                left: e(window).scrollLeft(),
              });
          (a[r] = ''),
            e('body').prepend(s),
            (location.hash = o.hash),
            s.remove(),
            (a[r] = i);
        }
        l.scrollTo(a, n).trigger('notify.serialScroll', [a]);
      }
    }
  }
  var o = location.href.replace(/#.*/, ''),
    n = (e.localScroll = function (t) {
      e('body').localScroll(t);
    });
  return (
    (n.defaults = {
      duration: 1e3,
      axis: 'y',
      event: 'click',
      stop: !0,
      target: window,
      autoscroll: !0,
    }),
    (e.fn.localScroll = function (i) {
      function a() {
        return (
          !!this.href &&
          !!this.hash &&
          this.href.replace(this.hash, '') === o &&
          (!i.filter || e(this).is(i.filter))
        );
      }
      return (
        (i = e.extend({}, n.defaults, i)),
        i.autoscroll &&
          i.hash &&
          location.hash &&
          (i.target && window.scrollTo(0, 0), t(0, location, i)),
        i.lazy
          ? this.on(i.event, 'a,area', function (e) {
              a.call(this) && t(e, this, i);
            })
          : this.find('a,area')
              .filter(a)
              .bind(i.event, function (e) {
                t(e, this, i);
              })
              .end()
              .end()
      );
    }),
    (n.hash = function () {}),
    n
  );
});
!(function (e) {
  'use strict';
  'function' == typeof define && define.amd
    ? define(['jquery'], e)
    : 'undefined' != typeof module && module.exports
    ? (module.exports = e(require('jquery')))
    : e(jQuery);
})(function (e) {
  'use strict';
  function t(t) {
    return (
      !t.nodeName ||
      e.inArray(t.nodeName.toLowerCase(), [
        'iframe',
        '#document',
        'html',
        'body',
      ]) !== -1
    );
  }
  function o(t) {
    return e.isFunction(t) || e.isPlainObject(t) ? t : { top: t, left: t };
  }
  var n = (e.scrollTo = function (t, o, n) {
    return e(window).scrollTo(t, o, n);
  });
  return (
    (n.defaults = { axis: 'xy', duration: 0, limit: !0 }),
    (e.fn.scrollTo = function (r, i, s) {
      'object' == typeof i && ((s = i), (i = 0)),
        'function' == typeof s && (s = { onAfter: s }),
        'max' === r && (r = 9e9),
        (s = e.extend({}, n.defaults, s)),
        (i = i || s.duration);
      var a = s.queue && s.axis.length > 1;
      return (
        a && (i /= 2),
        (s.offset = o(s.offset)),
        (s.over = o(s.over)),
        this.each(function () {
          function f(t) {
            var o = e.extend({}, s, {
              queue: !0,
              duration: i,
              complete:
                t &&
                function () {
                  t.call(l, m, s);
                },
            });
            d.animate(p, o);
          }
          if (null !== r) {
            var u,
              c = t(this),
              l = c ? this.contentWindow || window : this,
              d = e(l),
              m = r,
              p = {};
            switch (typeof m) {
              case 'number':
              case 'string':
                if (/^([+-]=?)?\d+(\.\d+)?(px|%)?$/.test(m)) {
                  m = o(m);
                  break;
                }
                m = c ? e(m) : e(m, l);
              case 'object':
                if (0 === m.length) return;
                (m.is || m.style) && (u = (m = e(m)).offset());
            }
            var h = (e.isFunction(s.offset) && s.offset(l, m)) || s.offset;
            e.each(s.axis.split(''), function (e, t) {
              var o = 'x' === t ? 'Left' : 'Top',
                r = o.toLowerCase(),
                i = 'scroll' + o,
                x = d[i](),
                v = n.max(l, t);
              if (u)
                (p[i] = u[r] + (c ? 0 : x - d.offset()[r])),
                  s.margin &&
                    ((p[i] -= parseInt(m.css('margin' + o), 10) || 0),
                    (p[i] -= parseInt(m.css('border' + o + 'Width'), 10) || 0)),
                  (p[i] += h[r] || 0),
                  s.over[r] &&
                    (p[i] += m['x' === t ? 'width' : 'height']() * s.over[r]);
              else {
                var w = m[r];
                p[i] =
                  w.slice && '%' === w.slice(-1)
                    ? (parseFloat(w) / 100) * v
                    : w;
              }
              s.limit &&
                /^\d+$/.test(p[i]) &&
                (p[i] = p[i] <= 0 ? 0 : Math.min(p[i], v)),
                !e &&
                  s.axis.length > 1 &&
                  (x === p[i] ? (p = {}) : a && (f(s.onAfterFirst), (p = {})));
            }),
              f(s.onAfter);
          }
        })
      );
    }),
    (n.max = function (o, n) {
      var r = 'x' === n ? 'Width' : 'Height',
        i = 'scroll' + r;
      if (!t(o)) return o[i] - e(o)[r.toLowerCase()]();
      var s = 'client' + r,
        a = o.ownerDocument || o.document,
        f = a.documentElement,
        u = a.body;
      return Math.max(f[i], u[i]) - Math.min(f[s], u[s]);
    }),
    (e.Tween.propHooks.scrollLeft = e.Tween.propHooks.scrollTop =
      {
        get: function (t) {
          return e(t.elem)[t.prop]();
        },
        set: function (t) {
          var o = this.get(t);
          if (t.options.interrupt && t._last && t._last !== o)
            return e(t.elem).stop();
          var n = Math.round(t.now);
          o !== n && (e(t.elem)[t.prop](n), (t._last = this.get(t)));
        },
      }),
    n
  );
});
!(function ($) {
  function scroll() {
    if ($('body').hasClass('animated')) {
      var e = $(window).width(),
        t = '',
        i = '',
        n = '';
      if (1 == i || (e > 767 && 1 == n)) {
        // $('.hidden-item').bind('inview', function (e, t, i, n) {
        //   t
        //     ? $('#header').removeClass('fixed')
        //     : $('.navigation').hasClass('active') ||
        //       $('#header').addClass('fixed');
        // });
      } else {
        // var o = $('#header').offset().top;
        // $(window).scroll(function (e) {
        //   var t = $(window).scrollTop();
        //   t > o && !$('.navigation').hasClass('active')
        //     ? $('#header').addClass('fixed')
        //     : t < o && $('#header').removeClass('fixed');
        // });
      }
      (1 == t && 1 != i) ||
        1 == n ||
        ($('html').hasClass('no-touch') && skrollr.init());
    }
    if ($('body').hasClass('suites')) {
      var e = $(window).width(),
        t = '',
        i = '',
        n = '';
      (1 == t && 1 != i) ||
        1 == n ||
        ($('html').hasClass('no-touch') && skrollr.init());
    }
  }
  function select2() {
    $('select').select2();
  }
  function matchHeightInit() {
    $('.pillar').matchHeight(),
      $('.packages-block h3.item-title').matchHeight(),
      $('.packages-block p.item-intro').matchHeight();
  }
  function announceBar() {
    var e = $('.announcementBar').outerHeight(),
      t = $('#header').outerHeight(),
      i = $('.header-content').outerHeight();
    if ($('.announcementBar').hasClass('active')) {
      $('body').addClass('announce'),
        $('main').addClass('announce'),
        $('#header').addClass('announce'),
        $(this).css('height', e),
        $('.header-content').css('margin-top', e),
        $('#header.fixed').css('height', i + e),
        $('#main-visual').css('top', e),
        $('.no-main-visual .holder').css('margin-top', t);
      var n = $(window).height(),
        o = $(window).width();
      if (o > 767) var r = e + 0.68 * n;
      else var r = e + 0.54 * n - 66;
      $('body#page_home.announce .holder').css('margin-top', r);
    } else
      $(this).css('height', '0'),
        $('.header-content').css('margin-top', '0'),
        $('#header').css('height', i),
        $('#header').removeClass('announce'),
        $('#main-visual').css('top', '0'),
        $('body.no-main-visual .holder').css('margin-top', t);
  }
  $(document).ready(function () {
    function getParameterByName(e) {
      var t = RegExp('[?&]' + e + '=([^&]*)').exec(window.location.search);
      return t && decodeURIComponent(t[1].replace(/\+/g, ' '));
    }
    function escapeHtml(e) {
      'use strict';
      return e.replace(/[\"&'\/<>]/g, function (e) {
        return {
          '"': '&quot;',
          '&': '&amp;',
          "'": '&#39;',
          '/': '&#47;',
          '<': '&lt;',
          '>': '&gt;',
        }[e];
      });
    }
    function formatMoney(e, t, i, n, o) {
      (e = e || 0),
        (t = isNaN((t = Math.abs(t))) ? 2 : t),
        (i = void 0 !== i ? i : '$'),
        (n = n || ','),
        (o = o || '.');
      var r = e < 0 ? '-' : '',
        a = parseInt((e = Math.abs(+e || 0).toFixed(t)), 10) + '',
        s = (s = a.length) > 3 ? s % 3 : 0;
      return (
        (returnVal =
          i +
          r +
          (s ? a.substr(0, s) + n : '') +
          a.substr(s).replace(/(\d{3})(?=\d)/g, '$1' + n) +
          (t
            ? o +
              Math.abs(e - a)
                .toFixed(t)
                .slice(2)
            : '')),
        'Infinity' != returnVal && '$NaN.N' != returnVal ? returnVal : '$0.00'
      );
    }
    function getElementsByClass(e, t, i) {
      var n = [];
      (t = t || document), (i = i || '*');
      var o = t.getElementsByTagName && t.getElementsByTagName(i);
      if (!o) return null;
      for (
        var r = o.length, a = new RegExp('(^|\\s)' + e + '(\\s|$)'), s = 0;
        r--;

      )
        a.test(o[r].className) && (n[s++] = o[r]);
      return n;
    }
    function getClasses(e) {
      return e.className ? e.className.trim().split(/\s+/) : [];
    }
    function addClass(e, t) {
      var i = getClasses(e);
      i.indexOf(t) == -1 && (i.push(t), (e.className = i.join(' ')));
    }
    function removeClass(e, t) {
      var i = getClasses(e),
        n = i.indexOf(t);
      n != -1 && (i.splice(n, 1), (e.className = i.join(' ')));
    }
    function hasClass(e, t) {
      return getClasses(e).indexOf(t) != -1;
    }
    function setClass(e, t) {
      var i = document.getElementById(e);
      i && (i.className = t);
    }
    function setStyleDef(e, t, i) {
      var n = document.getElementById(e);
      n && (n.style[t] = i);
    }
    function condor_getStyle(e, t) {
      var i = '';
      return (
        document.defaultView && document.defaultView.getComputedStyle
          ? (i = document.defaultView
              .getComputedStyle(e, '')
              .getPropertyValue(t))
          : e.currentStyle &&
            ((t = t.replace(/-(w)/g, function (e, t) {
              return t.toUpperCase();
            })),
            (i = e.currentStyle[t])),
        i
      );
    }
    function getPageX() {
      var e;
      return (
        self.pageYOffset
          ? (e = self.pageXOffset)
          : document.documentElement && document.documentElement.scrollTop
          ? (e = document.documentElement.scrollLeft)
          : document.body && (e = document.body.scrollLeft),
        e
      );
    }
    function getPageY() {
      var e;
      return (
        self.pageYOffset
          ? (e = self.pageYOffset)
          : document.documentElement && document.documentElement.scrollTop
          ? (e = document.documentElement.scrollTop)
          : document.body && (e = document.body.scrollTop),
        e
      );
    }
    function condor_get_absolute_offset(e) {
      var t = (curtop = 0);
      if (e.offsetParent)
        for (t = e.offsetLeft, curtop = e.offsetTop; (e = e.offsetParent); )
          (t += e.offsetLeft), (curtop += e.offsetTop);
      return [t, curtop];
    }
    function getX(e) {
      for (var t = 0, i = e; i; i = i.offsetParent) t += i.offsetLeft;
      for (i = e.parentNode; i && i != document.body; i = i.parentNode)
        i.scrollLeft && (t -= i.scrollLeft);
      return t;
    }
    function getY(e) {
      for (var t = 0, i = e; i; i = i.offsetParent) t += i.offsetTop;
      for (i = e.parentNode; i && i != document.body; i = i.parentNode)
        i.scrollTop && (t -= i.scrollTop);
      return t;
    }
    function id(e) {
      return document.getElementById(e);
    }
    function setInputValue(e, t) {
      id(e).value = t;
    }
    function toggleDisplay(e) {
      var t = document.getElementById(e);
      t.style.display = 'block' == t.style.display ? 'none' : 'block';
    }
    function condor_simulateClick(e) {
      'string' == typeof e && (e = document.getElementById(e)),
        e && e.onclick && e.onclick();
    }
    function mediaManagerStep_imageSwitchPreview() {
      (dojo.byId('imageSwitchPreviewScreen').style.visibility = 'visible'),
        (dojo.byId('imageSwitchPreviewImageHolder').innerHTML =
          '<img src="' +
          imageSwitchTargetImage.editSrc +
          '?refresh=' +
          Math.random() +
          '" style="height:' +
          imageSwitchTargetImage.editHeight +
          'px;width:' +
          imageSwitchTargetImage.editWidth +
          'px;" />');
    }
    function mediaManagerStep_imageCrop() {
      dojo.byId('imageCropScreen').style.visibility = 'visible';
    }
    function showCropsScreen() {
      var e = dijit.byId('imageCropsList');
      e && (e.destroy(), (e = null));
      var t = condor.popSocketDialog(
        'imageCropsList',
        'Edit Image Cropped Versions',
        'imageSwitch',
        'imageCropsList',
        {
          src: imageSwitchTargetImage.src,
          width: parseInt(imageSwitchTargetImage.width),
          height: parseInt(imageSwitchTargetImage.height),
          parentSrc: imageSwitchTargetImage.parentSrc,
          parentWidth: parseInt(imageSwitchTargetImage.parentWidth),
          parentHeight: parseInt(imageSwitchTargetImage.parentHeight),
          alt: imageSwitchTargetImage.alt,
        },
        760,
        500
      );
      dojo.connect(t, 'onLoad', function () {
        dojo.query('.showInViewMode').style('display', 'none'),
          dojo.query('.showInEditMode').style('display', 'block');
      }),
        dojo.connect(t, 'onCancel', function () {
          dojo.xhrPost({
            url: prepSocketCall('imageSwitch', 'imageUrlIsStale'),
            method: 'POST',
            handleAs: 'json',
            content: { imageUrl: baseImageObj.src },
            load: function (e) {
              if ('success' == e.result) {
                if (1 == e.isStale) {
                  var t = new condor.general.PopupMessage({
                    message: 'Updating...',
                  });
                  baseImageObj.refresh(), t.close();
                }
              } else
                alert(
                  "Error encountered when trying to refresh the image. (Socket responded with '" +
                    e.message +
                    "'.)"
                );
            },
            error: function (e, t) {
              alert(
                "Error encountered when trying to refresh the image.  (Socket didn't respond.)"
              );
            },
          });
        });
    }
    function imageSwitch(e, t) {
      (imageSwitchTargetMethod = t), (imageSwitchTargetImage = e);
      var i = !1;
      if (
        '' != imageSwitchTargetImage.parentSrc &&
        'true' == imageSwitchTargetImage.multiEdit
      ) {
        (imageSwitchTargetImage.editWidth = parseInt(
          imageSwitchTargetImage.parentWidth
        )),
          (imageSwitchTargetImage.editHeight = parseInt(
            imageSwitchTargetImage.parentHeight
          )),
          (imageSwitchTargetImage.editSrc = imageSwitchTargetImage.parentSrc),
          (imageSwitchTargetImage.thumbWidth = imageSwitchTargetImage.width),
          (imageSwitchTargetImage.thumbHeight = imageSwitchTargetImage.height),
          (imageSwitchTargetImage.createThumb = 1);
        var n = !!dijit.byId('imageCropsList');
        n || 'true' != imageSwitchTargetImage.hasMaster || (i = !0),
          (baseImageObj = e);
      } else (imageSwitchTargetImage.width = parseInt(e.containerNode.style.width)), (imageSwitchTargetImage.height = parseInt(e.containerNode.style.height)), (imageSwitchTargetImage.editWidth = parseInt(imageSwitchTargetImage.width)), (imageSwitchTargetImage.editHeight = parseInt(imageSwitchTargetImage.height)), (imageSwitchTargetImage.editSrc = imageSwitchTargetImage.src), (imageSwitchTargetImage.thumbWidth = 0), (imageSwitchTargetImage.thumbHeight = 0), (imageSwitchTargetImage.createThumb = 0);
      if (
        (imageSwitchTargetImage.editHeight > 500
          ? ((flagEnableIn = !0),
            (document.getElementById('zoomInOut').style.display = 'block'),
            (document.getElementById('imageSwitchSliderBox').style.display =
              'none'))
          : ((flagEnableIn = !1),
            (flagEnableOut = !1),
            (document.getElementById('zoomInOut').style.display = 'none'),
            (document.getElementById('imageSwitchSliderBox').style.display =
              'block')),
        i)
      )
        this.showCropsScreen();
      else {
        showMediaManager(),
          mediaManagerStep('imageSwitchPreview'),
          dojo.connect(mediaManagerBox, 'minimizeWindow', function () {
            imageSwitchCancel();
          }),
          (mediaManagerOnLoadFiles = function (e) {
            for (var t = 0; t < e.length; t++)
              (preview = e[t]), imageSwitchCheckPreview(preview);
          }),
          (mediaManagerOnAddPreview = function (e) {
            imageSwitchCheckPreview(e);
          }),
          (mediaManagerOnSelectPreview = function (e) {
            var t = e.getAttribute('itemType');
            'image' == t &&
              mediaManagerAddOption(
                'Select Image',
                condor.condorBaseHREF +
                  'modules/mediaManager/images/button.goNext.jpg',
                'zoomScaler=0;oH=imageSwitchTargetImage.editHeight;oW=imageSwitchTargetImage.editWidth;imageSwitchBeginCrop()'
              );
          }),
          mediaManagerSelectedPreview &&
            mediaManagerSelectPreview(mediaManagerSelectedPreview),
          dijit.byId('imageSwitchSlider').setValue(0);
        var o =
          (new dojo.dnd.Moveable('imageCropControlBox', {
            handle: 'imageSwitchContolBoxHeader',
          }),
          new dojo.dnd.Moveable('imageCropImageMouseHandler'));
        dojo.connect(o, 'onMoveStart', function (e) {
          (dojo.byId('imageCropImageHolder').style.display = 'none'),
            (o.node.className = 'showImageCropImageMouseHandler'),
            (dojo.byId('imageCropBlockTop').className =
              'imageSwitchBlockImageMoving'),
            (dojo.byId('imageCropBlockLeft').className =
              'imageSwitchBlockImageMoving'),
            (dojo.byId('imageCropBlockRight').className =
              'imageSwitchBlockImageMoving'),
            (dojo.byId('imageCropBlockBottom').className =
              'imageSwitchBlockImageMoving');
        }),
          dojo.connect(o, 'onMoveStop', function (e) {
            (o.node.className = 'hideImageCropImageMouseHandler'),
              (dojo.byId('imageCropImageHolder').style.display = 'block'),
              imageSwitchSetImageToMouseHandler(),
              imageSwitchKeepInBox(),
              (dojo.byId('imageCropBlockTop').className =
                'imageSwitchBlockImageStatic'),
              (dojo.byId('imageCropBlockLeft').className =
                'imageSwitchBlockImageStatic'),
              (dojo.byId('imageCropBlockRight').className =
                'imageSwitchBlockImageStatic'),
              (dojo.byId('imageCropBlockBottom').className =
                'imageSwitchBlockImageStatic');
          });
      }
    }
    function imageSwitchEditAltText(e) {
      condor.popTaskWindow(
        'editAltText',
        'Edit alt text',
        'imageSwitch',
        'editItem',
        { item: e },
        800,
        400
      );
    }
    function imageSwitchSetImageToMouseHandler() {
      var e = dojo.byId('imageCropImageMouseHandler');
      (imageSwitchEditImage.style.left = e.offsetLeft + 'px'),
        (imageSwitchEditImage.style.top = e.offsetTop + 'px');
    }
    function imageSwitchBeginCrop() {
      mediaManagerStep('imageCrop'),
        (imageSwitchSourceImage.src =
          mediaManagerSelectedPreview.getAttribute('imageSrc')),
        (imageSwitchSourceImage.height =
          mediaManagerSelectedPreview.getAttribute('imageHeight')),
        (imageSwitchSourceImage.width =
          mediaManagerSelectedPreview.getAttribute('imageWidth')),
        (imageSwitchTargetImage.editHeight =
          imageSwitchTargetImage.editHeight || imageSwitchSourceImage.height),
        (imageSwitchTargetImage.editWidth =
          imageSwitchTargetImage.editWidth || imageSwitchSourceImage.width),
        (dojo.byId('imageCropImageHolder').innerHTML =
          '<img id="imageSwitchEditImage" src="' +
          imageSwitchSourceImage.src +
          '" />'),
        (dojo.byId('imageCropImageMouseHandler').innerHTML =
          '<img src="' +
          imageSwitchSourceImage.src +
          '" width="100%" height="100%" />'),
        (imageSwitchEditImage = dojo.byId('imageSwitchEditImage')),
        imageSwitchDrawCropBox(),
        imageSwitchScaleImage(0);
      var e = dojo.byId('imageCropBox');
      (imageSwitchEditImage.style.top =
        imageSwitchEditImage.offsetTop +
        (e.offsetTop - imageSwitchEditImage.offsetTop) / 2 +
        'px'),
        (imageSwitchEditImage.style.left =
          imageSwitchEditImage.offsetLeft +
          (e.offsetLeft - imageSwitchEditImage.offsetLeft) / 2 +
          'px'),
        imageSwitchSetMouseHandler(),
        (dojo.byId('imageSwitchAltTextApprove').style.display = 'block'),
        (dojo.byId('imageSwitchApprove').style.display = 'none'),
        (dojo.byId('imageReviewAltText').style.display = 'none');
    }
    function imageSwitchScaleImage(e) {
      if (0 != imageSwitchEditImage) {
        imageSwitchCurrentScale = e;
        var t = imageSwitchEditImage.height,
          i = imageSwitchEditImage.width,
          n = imageSwitchSourceImage.width / imageSwitchTargetImage.editWidth,
          o = imageSwitchSourceImage.height / imageSwitchTargetImage.editHeight,
          r = n < o ? n : o;
        (imageSwitchEditImage.style.width =
          Math.round(
            imageSwitchSourceImage.width -
              ((imageSwitchSourceImage.width -
                imageSwitchSourceImage.width / r) *
                (100 - e)) /
                100
          ) + 'px'),
          (imageSwitchEditImage.style.height =
            Math.round(
              imageSwitchSourceImage.height -
                ((imageSwitchSourceImage.height -
                  imageSwitchSourceImage.height / r) *
                  (100 - e)) /
                  100
            ) + 'px'),
          (imageSwitchEditImage.width = Math.round(
            imageSwitchSourceImage.width -
              ((imageSwitchSourceImage.width -
                imageSwitchSourceImage.width / r) *
                (100 - e)) /
                100
          )),
          (imageSwitchEditImage.height = Math.round(
            imageSwitchSourceImage.height -
              ((imageSwitchSourceImage.height -
                imageSwitchSourceImage.height / r) *
                (100 - e)) /
                100
          )),
          (imageSwitchEditImage.style.top =
            imageSwitchEditImage.offsetTop +
            (t - imageSwitchEditImage.height) / 2 +
            'px'),
          (imageSwitchEditImage.style.left =
            imageSwitchEditImage.offsetLeft +
            (i - imageSwitchEditImage.width) / 2 +
            'px'),
          imageSwitchKeepInBox();
      }
    }
    function imageSwitchSetMouseHandler() {
      var e = dojo.byId('imageCropImageMouseHandler');
      (e.style.left = imageSwitchEditImage.offsetLeft + 'px'),
        (e.style.top = imageSwitchEditImage.offsetTop + 'px'),
        (e.style.width = imageSwitchEditImage.offsetWidth + 'px'),
        (e.style.height = imageSwitchEditImage.offsetHeight + 'px');
    }
    function imageSwitchMove() {
      if (imageSwitchCurrentMoveDirection) {
        switch (imageSwitchCurrentMoveDirection) {
          case 'up':
            imageSwitchEditImage.style.top =
              imageSwitchEditImage.offsetTop +
              imageSwitchCurrentMovePixels +
              'px';
            break;
          case 'down':
            imageSwitchEditImage.style.top =
              imageSwitchEditImage.offsetTop -
              imageSwitchCurrentMovePixels +
              'px';
            break;
          case 'left':
            imageSwitchEditImage.style.left =
              imageSwitchEditImage.offsetLeft +
              imageSwitchCurrentMovePixels +
              'px';
            break;
          case 'right':
            imageSwitchEditImage.style.left =
              imageSwitchEditImage.offsetLeft -
              imageSwitchCurrentMovePixels +
              'px';
        }
        imageSwitchKeepInBox(),
          setTimeout('imageSwitchMove()', 40),
          (imageSwitchCurrentMovePixels += 3);
      }
    }
    function imageSwitchStartMove(e) {
      (imageSwitchCurrentMoveDirection = e),
        setTimeout('imageSwitchMove()', 40),
        (imageSwitchCurrentMovePixels = 1);
    }
    function imageSwitchEndMove(e) {
      imageSwitchCurrentMoveDirection = !1;
    }
    function imageSwitchKeepInBox() {
      var e = dojo.byId('imageCropBox');
      imageSwitchEditImage.offsetLeft > e.offsetLeft &&
        (imageSwitchEditImage.style.left = e.offsetLeft + 'px'),
        imageSwitchEditImage.offsetTop > e.offsetTop &&
          (imageSwitchEditImage.style.top = e.offsetTop + 'px');
      var t =
          imageSwitchEditImage.offsetLeft + imageSwitchEditImage.offsetWidth,
        i = e.offsetWidth + e.offsetLeft;
      t < i &&
        (imageSwitchEditImage.style.left =
          imageSwitchEditImage.offsetLeft + i - t + 'px');
      var n =
          imageSwitchEditImage.offsetTop + imageSwitchEditImage.offsetHeight,
        o = e.offsetHeight + e.offsetTop;
      n < o &&
        (imageSwitchEditImage.style.top =
          imageSwitchEditImage.offsetTop + o - n + 'px'),
        imageSwitchSetMouseHandler();
    }
    function imageSwitchDrawCropBox() {
      var e = dojo.byId('imageCropScreen'),
        t = dojo.byId('imageCropBlockTop'),
        i = dojo.byId('imageCropBlockLeft'),
        n = dojo.byId('imageCropBlockRight'),
        o = dojo.byId('imageCropBlockBottom'),
        r = dojo.byId('imageCropBox');
      (i.style.width = ''),
        (i.style.height = ''),
        (n.style.width = ''),
        (n.style.height = ''),
        (t.style.height = ''),
        (t.style.width = ''),
        (t.style.left = ''),
        (o.style.height = ''),
        (o.style.width = ''),
        (o.style.left = ''),
        (r.style.height = ''),
        (r.style.width = ''),
        (r.style.left = ''),
        (r.style.top = ''),
        zoomScaler > 0
          ? (i.style.width = '4px')
          : (i.style.width =
              Math.floor(
                (e.offsetWidth - imageSwitchTargetImage.editWidth) / 2
              ) + 'px'),
        (i.style.height = e.offsetHeight + 'px'),
        (n.style.width =
          e.offsetWidth -
          imageSwitchTargetImage.editWidth -
          i.offsetWidth +
          'px'),
        (n.style.height = e.offsetHeight + 'px'),
        (t.style.height =
          Math.floor((e.offsetHeight - imageSwitchTargetImage.editHeight) / 2) +
          'px'),
        (t.style.width = imageSwitchTargetImage.editWidth + 'px'),
        (t.style.left = i.offsetWidth + 'px'),
        (o.style.height =
          e.offsetHeight -
          imageSwitchTargetImage.editHeight -
          t.offsetHeight +
          'px'),
        (o.style.width = imageSwitchTargetImage.editWidth + 'px'),
        (o.style.left = i.offsetWidth + 'px'),
        (r.style.height = imageSwitchTargetImage.editHeight - 2 + 'px'),
        (r.style.width = imageSwitchTargetImage.editWidth - 2 + 'px'),
        (r.style.left = i.offsetWidth + 'px'),
        (r.style.top = t.offsetHeight + 'px');
    }
    function imageSwitchCheckPreview(e) {
      var t = e.getAttribute('itemType');
      'folder' == t ||
        ('image' == t
          ? e.getAttribute('imageHeight') < imageSwitchTargetImage.editHeight ||
            e.getAttribute('imageWidth') < imageSwitchTargetImage.editWidth
            ? (e.className = 'mediaManagerPreviewDisabled')
            : (e.className = 'mediaManagerPreviewNormal')
          : (e.className = 'mediaManagerPreviewDisabled'));
    }
    function imageSwitchCancel() {
      if ('function' == typeof imageSwitchCancelCustomFunction)
        imageSwitchCancelCustomFunction();
      else {
        with (mediaManagerBox.domNode.style) left = '-1900px';
        (zoomScaler = 0), mediaManagerBox.hide();
      }
    }
    function imageSwitchApplyCrop() {
      if (imageSwitchAfterCropCustomFunction)
        imageSwitchAfterCropCustomFunction();
      else {
        hideMediaManager();
        var e = new condor.general.PopupMessage({ message: 'Saving...' });
      }
      zoomScaler > 0 &&
        (imageSwitchTargetImage.editHeight < 500 && zoomIn(),
        (zoomScaler = 0),
        (flagEnableIn = !1));
      var t = new Object();
      dojo.xhrPost({
        url: prepSocketCall('imageSwitch', 'imageCropAndScale', t),
        content: {
          method: 'crop',
          newWidth: imageSwitchTargetImage.editWidth,
          newHeight: imageSwitchTargetImage.editHeight,
          oldWidth: imageSwitchSourceImage.width,
          oldHeight: imageSwitchSourceImage.height,
          thumbWidth: imageSwitchTargetImage.width,
          thumbHeight: imageSwitchTargetImage.height,
          createThumb: imageSwitchTargetImage.createThumb,
          sourceImage: imageSwitchSourceImage.src,
          targetImage: imageSwitchTargetImage.editSrc,
          targetThumbImage: imageSwitchTargetImage.src,
          offsetTop:
            dojo.byId('imageCropBlockTop').offsetHeight -
            imageSwitchEditImage.offsetTop,
          offsetLeft:
            dojo.byId('imageCropBlockLeft').offsetWidth -
            imageSwitchEditImage.offsetLeft,
          oldWidthChanged: imageSwitchEditImage.width,
          oldHeightChanged: imageSwitchEditImage.height,
          imageAltText: imageSwitchTargetImage.alt,
          allLangAltText: JSON.stringify(imageAltTexts),
        },
        handleAs: 'json',
        load: function (e) {
          1 == e.success
            ? ((imageSwitchTargetImage.hasMaster = 'true'),
              imageSwitchTargetImage.refresh(),
              '' != imageSwitchTargetImage.parentSrc &&
                'true' == imageSwitchTargetImage.multiEdit &&
                showCropsScreen())
            : alert('There was an error cropping the image');
        },
        error: function (e, t) {
          alert(
            'There was an error and the image could not be cropped properly.'
          );
        },
        handle: function () {
          e && e.close(), condor.refreshPendingItemsCounters();
        },
      });
    }
    function imageSwitchApprove(e) {
      e || (e = imageSwitchTargetImage);
      var t = e.src,
        i = null;
      dojo.xhrPost({
        url: prepSocketCall('imageSwitch', 'approve'),
        content: { imageUrl: t, thumbUrl: i },
        handleAs: 'json',
        load: function (t) {
          'success' == t.result
            ? (new condor.general.PopupMessage({
                message: 'Image approved!',
                autoClose: 1e3,
              }),
              hideMediaManager(),
              e.refresh(),
              condor.refreshPendingItemsCounters())
            : alert(
                "There was an error approving the image. (Socket responded with '" +
                  t.message +
                  "'.)"
              );
        },
        error: function (e, t) {
          alert(
            "There was an error approving the image. (Socket didn't respond.)"
          );
        },
      });
    }
    function imageSwitchReject(e) {
      var t = e.src,
        i = null;
      dojo.xhrPost({
        url: prepSocketCall('imageSwitch', 'reject'),
        content: { imageUrl: t, thumbUrl: i },
        handleAs: 'json',
        load: function (t) {
          'success' == t.result
            ? (e.refresh(),
              new condor.general.PopupMessage({
                message: 'Image reverted',
                autoClose: 1e3,
              }),
              condor.refreshPendingItemsCounters())
            : alert(
                "There was an error rejecting the image. (Socket responded with '" +
                  t.message +
                  "'.)"
              );
        },
        error: function (e, t) {
          alert('There was a general error rejecting the image');
        },
      });
    }
    function selectOriginalMediaFile(e) {
      dojo.xhrGet({
        url: prepSocketCall('imageSwitch', 'getOriginalMediaPath'),
        content: { imagePath: e },
        handleAs: 'json',
        load: function (e) {
          'success' == e.result
            ? (mediaManagerSelectedFile = e.mediaFile.relativeMediaPath)
            : console.log(
                'There was an error looking for image origin: ' + e.message
              );
        },
        error: function (e, t) {
          console.log('There was a general error looking for image origin');
        },
      });
    }
    function openImagesAltTextEditDialog(e) {
      if (altTextRequired) {
        var t = dojo.byId('imagesList');
        t && dijit.byId('imagesList').destroy();
        var i = condor.openDialog(
          'imagesList',
          'Images List',
          'imageSwitch',
          'getPageImagesList',
          e
        );
        dojo.connect(i, 'onLoad', i, function (e) {
          expandAllTextBlocks(), getImagesAltTexts(), (imageListDialogObj = i);
          var t = dojo.byId('imagesList');
          dojo.place(
            '<div id="textOkOnly"><span class="dijitReset dijitInline dijitButtonNode"><span class="dijitReset dijitInline dijitButtonText" onclick="saveImagesAltTextsPopup()">OK</span></span></div>',
            t
          ),
            (t.style.height = '550px');
        });
      } else {
        var i = condor.openDialog(
          'imagesList',
          'Images List',
          'imageSwitch',
          'getPageImagesList',
          e
        );
        dojo.connect(i, 'onLoad', i, function (e) {
          expandAllTextBlocks();
        });
      }
    }
    function openImagesListDialog(e) {
      condor.openDialog(
        'imagesList',
        'Images List',
        'imageSwitch',
        'imagesList',
        e
      );
    }
    function openCropImagesAltTextEditDialog() {
      (altTextRequired = !0),
        openImagesAltTextEditDialog({
          imageSwitchId: imageSwitchTargetImage.imageSwitchId,
        });
    }
    function getImagesAltTexts() {
      (imageAltTexts = []),
        dojo
          .query('.textListBodyText > span > span[ class ^= textSwitchHolder ]')
          .forEach(function (e) {
            var t = e.parentNode.parentNode.parentNode,
              i = e.parentNode.parentNode.parentNode.parentNode,
              n = dojo.getAttr(e, 'lang'),
              o = '';
            dojo.hasClass(t, 'empty')
              ? imageAltTexts.push({ langCode: n, altText: o })
              : ((o = e.innerHTML),
                (o = o.replace(/^\s+|\s+$/gm, '')),
                imageAltTexts.push({ langCode: n, altText: o })),
              imageSwitchTargetImage.lang == n &&
                (imageSwitchTargetImage.alt = o),
              dojo
                .query('> div[ class = header ] > img[ class = image ]', i)
                .forEach(function (e) {
                  dojo.attr(e, 'src', imageSwitchSourceImage.src);
                });
          });
    }
    function checkImagesAltTexts() {
      var e = !0;
      return (
        dojo.forEach(imageAltTexts, function (t, i) {
          var n = t.altText;
          (n = n.replace(/^\s+|\s+$/gm, '')), '' != n && (e = !1);
        }),
        1 != condor.disableReqAltText && e
      );
    }
    function updateImageAltTextsinArray(e, t) {
      dojo.forEach(imageAltTexts, function (i, n) {
        i.langCode == e && (imageAltTexts[n].altText = t),
          imageSwitchTargetImage.lang == e && (imageSwitchTargetImage.alt = t);
      });
    }
    function saveImagesAltTextsPopup() {
      checkImagesAltTexts()
        ? ((altTextRequired = !0),
          alert('Provide alt text for at least one language'))
        : (imageListDialogObj.onCancel(),
          (imageListDialogObj = null),
          (altTextRequired = !1),
          (dojo.byId('imageSwitchAltTextApprove').style.display = 'none'),
          (dojo.byId('imageSwitchApprove').style.display = 'block'),
          (dojo.byId('imageReviewAltText').style.display = 'block'));
    }
    function zoomOut() {
      if (!flagEnableIn) return !1;
      if (
        imageSwitchTargetImage.editHeight > 500 ||
        imageSwitchTargetImage.editWidth > 930
      ) {
        zoomScaler = (460 / imageSwitchTargetImage.editHeight) * 100;
        var e =
          imageSwitchTargetImage.editWidth / imageSwitchTargetImage.editHeight;
        (imageSwitchTargetImage.editHeight = 460),
          (imageSwitchTargetImage.editWidth =
            e * imageSwitchTargetImage.editHeight);
      }
      var t = (imageSwitchEditImage.offsetLeft * zoomScaler * -1) / 100,
        i = (imageSwitchEditImage.offsetTop * zoomScaler * -1) / 100;
      imageSwitchBeginCrop(),
        (dojo.byId('imageCropBox').style.left =
          t + imageSwitchEditImage.offsetLeft + 'px'),
        (dojo.byId('imageCropBox').style.top =
          i + imageSwitchEditImage.offsetTop + 'px'),
        (flagEnableOut = !0),
        (flagEnableIn = !1),
        (document.getElementById('imageSwitchChooseOther').style.display =
          'none');
    }
    function zoomIn() {
      if (!flagEnableOut) return !1;
      (imageSwitchTargetImage.editHeight = oH),
        (imageSwitchTargetImage.editWidth = oW);
      var e =
          (100 *
            (dojo.byId('imageCropBox').offsetLeft -
              imageSwitchEditImage.offsetLeft)) /
          zoomScaler,
        t =
          (100 *
            (dojo.byId('imageCropBox').offsetTop -
              imageSwitchEditImage.offsetTop)) /
          zoomScaler;
      imageSwitchBeginCrop(),
        (dojo.byId('imageCropImageMouseHandler').style.left =
          parseInt(e * -1) + 'px'),
        (dojo.byId('imageSwitchEditImage').style.left =
          parseInt(e * -1) + 'px'),
        (dojo.byId('imageCropImageMouseHandler').style.top =
          parseInt(t * -1) + 'px'),
        (dojo.byId('imageSwitchEditImage').style.top = parseInt(t * -1) + 'px'),
        (flagEnableOut = !1),
        (flagEnableIn = !0),
        (document.getElementById('imageSwitchChooseOther').style.display =
          'block');
    }
    function toNum(e) {
      return (e < 10 ? '0' : '') + e;
    }
    function readMore() {
      var e = jQuery('#main-intro').prop('scrollHeight'),
        t = jQuery('#main-intro'),
        i = jQuery('.main-content-wrapper .read-more .more'),
        n = jQuery('.main-content-wrapper .read-more .less');
      t.attr('data-height', e),
        e > 75
          ? t.addClass('more-content')
          : (t.removeClass('more-content'),
            jQuery('.overview .main-content-wrapper .read-more')
              .not('.overview .main-content-wrapper .read-more.extraLinks')
              .hide()),
        i.click(function () {
          t.addClass('open'), i.addClass('hide'), n.removeClass('hide');
        }),
        n.click(function () {
          t.removeClass('open'),
            n.addClass('hide'),
            i.removeClass('hide'),
            jQuery('html, body').animate({ scrollTop: 0 }, 'slow');
        });
    }
    function accordions() {
      var e = jQuery('.accordion-title'),
        t = jQuery('.accordion-block');
      t.each(function () {
        var e = $(this).prop('scrollHeight');
        $(this).attr('data-height', e),
          $(this).hasClass('active') && $(this).css('height', e);
      }),
        e.click(function () {
          var i = $(this).data('cat');
          if ($(this).hasClass('active'))
            $(this).removeClass('opened active').addClass('closed'),
              t
                .removeClass('opened active')
                .addClass('closed')
                .css('height', 0);
          else {
            var n = jQuery('.accordion-block.' + i).data('height');
            e.removeClass('opened active').addClass('closed'),
              $(this).removeClass('closed').addClass('opened active'),
              t
                .removeClass('opened active')
                .addClass('closed')
                .css('height', 0),
              jQuery('.accordion-block.' + i)
                .removeClass('closed')
                .addClass('opened active')
                .css('height', n);
          }
          var o = jQuery('#module-content').offset().top;
          jQuery('html, body').animate({ scrollTop: o - 75 }, 'slow');
        });
    }
    $('[data-toggle]').on('click', function (e) {
      e.preventDefault(),
        $(this).toggleClass('active'),
        $('#' + $(this).data('toggle')).toggleClass('active'),
        $('#' + $(this).data('toggle'))
          .find('input')
          .first()
          .focus(),
        $('body').toggleClass($(this).data('toggle') + '-active');
    }),
      $('[data-close]').on('click', function (e) {
        e.preventDefault(),
          $(this).removeClass('active'),
          $('#' + $(this).data('toggle')).removeClass('active'),
          $('body').removeClass($(this).data('toggle') + '-active');
      }),
      $('[data-scroll-element]').on('click', function (e) {
        e.preventDefault(),
          $('html, body').animate(
            {
              scrollTop:
                $('#' + $(this).data('scroll-element')).offset().top -
                $(this).data('scroll-offset'),
            },
            500
          );
      }),
      $('[data-scroll-to]').on('click', function (e) {
        e.preventDefault(),
          $('html, body').animate(
            {
              scrollTop:
                $($(this).attr('href')).offset().top -
                $(this).data('scroll-offset'),
            },
            500
          );
      }),
      $('[data-top]').on('click', function (e) {
        e.preventDefault(), $('html, body').animate({ scrollTop: 0 }, 500);
      }),
      $('body').on(
        'click',
        'a[href*="' + window.location.hostname + '"], a[href^="/"]',
        function (e) {
          '' == $(this).attr('data-filter') &&
            '' == $(this).attr('data-load-more') &&
            ($('main, section.subscribe, footer').fadeOut(300),
            $('body').addClass('loading'));
        }
      ),
      $('[data-accordian]').on('click', 'div.title', function (e) {
        e.preventDefault();
        var t = $(this).parent().parent();
        $(this).parent().hasClass('active')
          ? $(this).parent().removeClass('active')
          : (t.data('collapse') && t.find('li').removeClass('active'),
            $(this).parent().toggleClass('active'));
      }),
      $('iframe[src*=youtube]').each(function () {
        $(this).attr(
          'src',
          $(this).attr('src') +
            '?modestbranding=1&autohide=1&showinfo=0&controls=0'
        ),
          $(this).attr('scrolling', 'no');
      }),
      $('iframe[src*=vimeo]').each(function () {
        $(this).attr('src', $(this).attr('src') + '?badge=0&byline=0&title=0'),
          $(this).attr('scrolling', 'no');
      });
    var carousel = $('[data-carousel]');
    carousel.owlCarousel({
      itemsScaleUp: !0,
      navText: ['', ''],
      smartSpeed: 500,
      animateOut: carousel.data('transitionStyle')
        ? carousel.data('transitionStyle')
        : 'fadeOut',
      items: 1,
      loop: !carousel.data('loop') || carousel.data('loop'),
      margin: carousel.data('margin') ? carousel.data('margin') : 0,
      nav: !!carousel.data('show-direction') && carousel.data('show-direction'),
      pagination: !0,
      autoplay: !!carousel.data('autoplay') && carousel.data('autoplay'),
      autoplaySpeed: carousel.data('speed') ? carousel.data('speed') : 1e3,
      mouseDrag: !!carousel.data('mousedrag') && carousel.data('mousedrag'),
      touchDrag: !carousel.data('touchdrag') || carousel.data('touchdrag'),
      pullDrag: !!carousel.data('pulldrag') && carousel.data('pulldrag'),
      slideBy: carousel.data('items') ? carousel.data('items') : 3,
      dots: !carousel.data('show-dots') || carousel.data('show-dots'),
      navSpeed: carousel.data('speed') ? carousel.data('speed') : 1e3,
      autoplayTimeout: carousel.data('timeout')
        ? carousel.data('timeout')
        : 1e4,
      autoplayHoverPause:
        !carousel.data('pausehover') || carousel.data('pausehover'),
      singleItem: !!carousel.data('single') && carousel.data('single'),
      autoHeight: !!carousel.data('autoheight') && carousel.data('autoheight'),
      autoWidth: !!carousel.data('autowidth') && carousel.data('autowidth'),
      startPosition: carousel.data('position') ? carousel.data('position') : 0,
      center: !!carousel.data('center') && carousel.data('center'),
      responsive: {
        0: {
          items: carousel.data('items-mobile')
            ? carousel.data('items-mobile')
            : 1,
        },
        768: {
          items: carousel.data('items-tablet')
            ? carousel.data('items-tablet')
            : 1,
        },
        1024: { items: carousel.data('items') ? carousel.data('items') : 1 },
      },
      onInitialized: function () {
        setTimeout(function () {
          carousel.addClass('active');
        }, 600),
          $('[data-carousel-next]').on('click', function (e) {
            carousel.trigger('next.owl.carousel', [
              carousel.data('speed') ? carousel.data('speed') : 1e3,
            ]);
          }),
          $('[data-carousel-prev]').on('click', function (e) {
            carousel.trigger('prev.owl.carousel', [
              carousel.data('speed') ? carousel.data('speed') : 1e3,
            ]);
          }),
          carousel.on('changed.owl.carousel', function (e) {
            var t = e.page.index + 1;
            0 == t && (t = 1),
              t < 10
                ? $('[data-slide]').text('0' + t)
                : $('[data-slide]').text(t);
          });
      },
    });
    var refresh = !1;
    carousel.on('change.owl.carousel', function (e) {
      $('.owl-item.active').removeClass('show'),
        $('[data-slider-placeholder]').fadeOut(600),
        refresh ||
          setTimeout(function () {
            (refresh = !0),
              $('.owl-item.active').first().addClass('show'),
              $('[data-slider-placeholder]')
                .html(
                  $('.owl-item.active')
                    .first()
                    .find('[data-slider-content]')
                    .clone()
                )
                .fadeIn(600);
          }, 600),
        setTimeout(function () {
          refresh = !1;
        }, 600);
    }),
      $(window).resize(function () {
        carousel.trigger('change.owl.carousel');
      }),
      ($pagination = !1),
      $('[data-pagination]').on('click', 'a', function (e) {
        e.preventDefault(),
          $pagination ||
            (($pagination = !0),
            $(this)
              .addClass('active')
              .text($('[data-pagination]').data('loading')),
            $.ajax({
              type: 'GET',
              url:
                $(this).attr('href') +
                '#' +
                $('[data-pagination]').data('pagination'),
              dataType: 'html',
              success: function (e) {
                (result = $(e).find('[data-posts] [data-item]')),
                  (nextlink = $(e).find('[data-pagination] a').attr('href')),
                  $('[data-posts]').append(result).isotope('appended', result),
                  $('[data-pagination] a')
                    .removeClass('active')
                    .text($('[data-pagination]').data('text')),
                  void 0 != nextlink
                    ? $('[data-pagination] a').attr('href', nextlink)
                    : $('[data-pagination]').remove(),
                  ($pagination = !1);
              },
            }));
      }),
      $('[data-pagination] a').length || $('[data-pagination]').remove(),
      (String.prototype.trim =
        String.prototype.trim ||
        function () {
          return this.replace(/^\s+/, '').replace(/\s+$/, '');
        }),
      (Array.prototype.indexOf =
        Array.prototype.indexOf ||
        function (e, t) {
          for (var i = t || 0; i < this.length; i++) if (this[i] == e) return i;
          return -1;
        }),
      (Array.prototype.push =
        Array.prototype.push ||
        function () {
          for (var e = 0; e < arguments.length; e++)
            this[this.length] = arguments[e];
          return this.length;
        }),
      (Array.prototype.splice =
        Array.prototype.splice ||
        function (e, t) {
          for (
            var i = this.slice(0, e),
              n = this.slice(e, e + t),
              o = this.slice(e + t),
              r = [],
              a = 2;
            a < arguments.length;
            a++
          )
            r[a - 2] = arguments[a];
          this.length = 0;
          for (var s = this.concat(i, r, o), a = 0; a < s.length; a++)
            this[a] = s[a];
          return n;
        }),
      (Array.prototype.remove =
        Array.prototype.remove ||
        function (e) {
          var t = this.indexOf(e);
          t != -1 && this.splice(t, 1);
        }),
      document.getElementsByTagName ||
        (document.all &&
          (document.getElementsByTagName = function (e) {
            return document.all.tags(e.toUpperCase());
          }));
    var HTTP = {};
    (HTTP._factories = [
      function () {
        return new XMLHttpRequest();
      },
      function () {
        return new ActiveXObject('Msxml2.XMLHTTP');
      },
      function () {
        return new ActiveXObject('Microsoft.XMLHTTP');
      },
    ]),
      (HTTP._factory = null),
      (HTTP.newRequest = function () {
        if (HTTP._factory) return HTTP._factory();
        for (var e = 0; e < HTTP._factories.length; ++e)
          try {
            var t = HTTP._factories[e],
              i = t();
            if (i) return (HTTP._factory = t), i;
          } catch (n) {
            continue;
          }
        (HTTP._factory = function () {
          throw new Error('XMLHttpRequest not supported');
        }),
          HTTP._factory();
      }),
      (HTTP.getText = function (e, t) {
        var i = HTTP.newRequest();
        (i.onreadystatechange = function () {
          4 == i.readyState && 200 == i.status && t(i.responseText);
        }),
          i.open('GET', e),
          i.send(null);
      }),
      (HTTP.getXML = function (e, t) {
        var i = HTTP.newRequest();
        (i.onreadystatechange = function () {
          4 == i.readyState && 200 == i.status && t(i.responseXML);
        }),
          i.open('GET', e),
          i.send(null);
      }),
      window.condor || (condor = new Object()),
      (condor.popPage = function (e, t, i, n, o, r) {
        (t = t ? t.replace(/[^a-zA-Z0-9_]/, '_') : 'Popup_Window'),
          (o =
            o ||
            'menubar=yes,toolbar=no,location=yes,directories=no,status=yes,scrollbars=yes,resizable=yes'),
          (i = i || 400),
          (n = n || 600),
          (r = r
            ? r
            : 'left=' +
              (screen.width - i) / 2 +
              ',top=' +
              (screen.height - n) / 3);
        var a = o + ',width=' + i + ',height=' + n + ',' + r;
        return window.open(e, t, a);
      }),
      (condor.getUrlFromForm = function (e) {
        for (var t = new Array(), i = 0; i < e.elements.length; i++)
          e.elements[i].name.length > 0 &&
            t.push(
              e.elements[i].name + '=' + encodeURIComponent(e.elements[i].value)
            );
        var n = t.join('&'),
          o = e.getAttribute('action'),
          r = o.split(/\?/);
        return (o = r[0]), r[1] && (n = n + '&' + r[1]), o + '?' + n;
      }),
      (condor.addFormFieldsFromQueryString = function (e, t) {
        for (
          var i = t.split(/\?/)[1] ? t.split(/\?/)[1] : t,
            n = i.split(/&/),
            o = 0;
          o < n.length;
          o++
        ) {
          var r = n[o].split(/=/),
            a = r.shift(),
            s = r.length > 0 ? decodeURIComponent(r.join('=')) : '',
            l = document.createElement('input');
          l.setAttribute('type', 'hidden'),
            l.setAttribute('name', a),
            l.setAttribute('value', s),
            e.appendChild(l);
        }
      }),
      (condor.getFixedUpGoogleAnalyticsTrackingUrl = function (e, t) {
        var i = e.indexOf('__utmz='),
          n = i >= 0 ? i + 7 : e.length,
          o = e.substring(0, n),
          r = n + e.substring(n).indexOf('&'),
          a = r >= 0 ? e.substring(r) : e.length,
          s = e.substring(n, r);
        switch (t) {
          case 'urchin':
            var l = encodeURIComponent(s);
            (l = l.replace(/%28/g, '(')), (l = l.replace(/%29/g, ')'));
          case 'ga':
          default:
            var l = s;
        }
        return o + l + a;
      }),
      (condor.finalizeTrackingAndGo = function (e) {
        if (window.__utmLinker) {
          var t = condor.getUrlFromForm(e);
          t = condor.getFixedUpGoogleAnalyticsTrackingUrl(t, 'urchin');
        } else if (window.pageTracker && pageTracker._getLinkerUrl) {
          var t = pageTracker._getLinkerUrl(condor.getUrlFromForm(e));
          t = condor.getFixedUpGoogleAnalyticsTrackingUrl(t, 'ga');
        }
        return !!t && ((t = t.replace(/%2F/g, '/')), (window.location = t), !0);
      }),
      (condor.utmLink = function (e, t) {
        if (
          ((t = 'undefined' == typeof t || null == t || t), e && !e.utmLinked)
        ) {
          var i = !1;
          return (
            e.submit
              ? window.__utmLinkPost &&
                (__utmLinkPost(e),
                t
                  ? (this.addFormFieldsFromQueryString(
                      e,
                      'engineurl=' +
                        e.action.replace(/\?/, '&') +
                        '&gav=1urchin'
                    ),
                    (e.action =
                      condor.condorBaseHREF +
                      'modules/ihotelier/reservations.gateway.php'))
                  : (this.addFormFieldsFromQueryString(e, e.action),
                    (i = condor.finalizeTrackingAndGo(e))),
                (e.utmLinked = !0))
              : e.href &&
                window.__utmLinker &&
                ((_udl = new Array()),
                __utmLinker(e.href),
                (e.href = _udl.href),
                (_udl = _ubd.location),
                (e.utmLinked = !0)),
            i
          );
        }
      }),
      (condor.trackLink = function (e, t) {
        if (
          ((t = 'undefined' == typeof t || null == t || t), window.__utmLinker)
        )
          return condor.utmLink(e, t);
        if (
          window.pageTracker &&
          pageTracker._getLinkerUrl &&
          e &&
          !e.trackLinked
        ) {
          var i = !1;
          if (e.submit)
            if (t) {
              var n = pageTracker._getLinkerUrl(e.action);
              this.addFormFieldsFromQueryString(
                e,
                'engineurl=' + n.replace(/\?/, '&') + '&gav=2ga'
              ),
                (e.action =
                  condor.condorBaseHREF +
                  'modules/ihotelier/reservations.gateway.php');
            } else i = condor.finalizeTrackingAndGo(e);
          else e.href && (e.href = pageTracker._getLinkerUrl(e.href));
          return (e.trackLinked = !0), i;
        }
      }),
      (condor.addTracking = function (e, t, i) {
        if (!condor.trackingAdded) {
          (condor.trackingAdded = !0),
            (i = 'undefined' == typeof i || null == i || i),
            e || (e = 'by_class'),
            'by_class' != e || t
              ? 'by_url' != e || t || (t = 'ihotelier.com')
              : (t = 'tracking');
          for (
            var n, o = document.getElementsByTagName('a'), r = o.length;
            r--;

          )
            (n = o[r]),
              (('by_class' == e && hasClass(n, t + '-link')) ||
                ('by_url' == e && n.href.match(new RegExp(t, 'i')))) &&
                (n.onfocus && (n.onfocusOrig = n.onfocus),
                (n.onfocus = function () {
                  return (
                    condor.trackLink(this, i),
                    !this.onfocusOrig || this.onfocusOrig(this)
                  );
                }),
                n.onclick && (n.onclickOrig = n.onclick),
                (n.onclick = function () {
                  return (
                    condor.trackLink(this, i),
                    !this.onclickOrig || this.onclickOrig(this)
                  );
                }));
          for (
            var n, a = document.getElementsByTagName('form'), r = a.length;
            r--;

          )
            (n = a[r]),
              (('by_class' == e && hasClass(n, t + '-form')) ||
                ('by_url' == e && n.action.match(new RegExp(t, 'i')))) &&
                (n.onsubmit && (n.onsubmitOrig = n.onsubmit),
                (n.onsubmit = function () {
                  var e = !this.onsubmitOrig || this.onsubmitOrig(this);
                  return !condor.trackLink(this, i) && e;
                }));
        }
      }),
      (condor.passUTMCookies = function (e) {
        var t = document.createElement('iframe');
        (t.style.display = 'none'),
          document.body.appendChild(t),
          (_udl = new Array()),
          __utmLinker(e),
          (t.src = _udl.href),
          (_udl = _ubd.location);
      }),
      window.console ||
        ((console = new Object()),
        (console.error = function () {}),
        (console.warn = function () {}),
        (console.log = function () {})),
      (prepCall = function (e, t, i, n) {
        var o = document.URL,
          r = o.lastIndexOf('/') + 1,
          a = o.lastIndexOf(','),
          s = o.lastIndexOf('#');
        s > -1 && (o = o.substr(0, s));
        var l = o.substr(0, r),
          c = o.substr(a > -1 ? a + 1 : r),
          d = o.lastIndexOf('?') > -1 ? '&' : '?';
        if (
          ((d += e + 'Module=' + t + '&' + e + 'Name=' + i),
          'object' == typeof n)
        )
          for (name in n) d += '&' + name + '=' + escape(n[name]);
        return l + c + d;
      }),
      (prepSocketCall = function (e, t, i) {
        return prepCall('socket', e, t, i);
      }),
      (prepTaskCall = function (e, t, i) {
        return prepCall('task', e, t, i);
      });
    var baseImageObj,
      imageSwitchTargetImage = !1,
      imageSwitchTargetMethod = !1,
      imageSwitchSourceImage = new Object(),
      imageSwitchEditImage = !1,
      imageSwitchCurrentScale = 0,
      imageSwitchCurrentMoveDirection = !1,
      imageSwitchCurrentMovePixels = 1,
      imageSwitchCancelCustomFunction = null,
      imageSwitchAfterCropCustomFunction = null,
      imageAltTexts = [],
      altTextRequired = !1,
      imageListDialogObj,
      oH,
      oW,
      zoomScaler = 0,
      flagEnableOut,
      flagEnableIn = !1;
    !(function (e, t) {
      'use strict';
      'object' == typeof module && 'object' == typeof module.exports
        ? (module.exports = e.document
            ? t(e, !0)
            : function (e) {
                if (!e.document)
                  throw new Error('jQuery requires a window with a document');
                return t(e);
              })
        : t(e);
    })('undefined' != typeof window ? window : this, function (e, t) {
      'use strict';
      function i(e, t) {
        t = t || te;
        var i = t.createElement('script');
        (i.text = e), t.head.appendChild(i).parentNode.removeChild(i);
      }
      function n(e) {
        var t = !!e && 'length' in e && e.length,
          i = he.type(e);
        return (
          'function' !== i &&
          !he.isWindow(e) &&
          ('array' === i ||
            0 === t ||
            ('number' == typeof t && t > 0 && t - 1 in e))
        );
      }
      function o(e, t, i) {
        return he.isFunction(t)
          ? he.grep(e, function (e, n) {
              return !!t.call(e, n, e) !== i;
            })
          : t.nodeType
          ? he.grep(e, function (e) {
              return (e === t) !== i;
            })
          : 'string' != typeof t
          ? he.grep(e, function (e) {
              return ae.call(t, e) > -1 !== i;
            })
          : Te.test(t)
          ? he.filter(t, e, i)
          : ((t = he.filter(t, e)),
            he.grep(e, function (e) {
              return ae.call(t, e) > -1 !== i && 1 === e.nodeType;
            }));
      }
      function r(e, t) {
        for (; (e = e[t]) && 1 !== e.nodeType; );
        return e;
      }
      function a(e) {
        var t = {};
        return (
          he.each(e.match(Me) || [], function (e, i) {
            t[i] = !0;
          }),
          t
        );
      }
      function s(e) {
        return e;
      }
      function l(e) {
        throw e;
      }
      function c(e, t, i) {
        var n;
        try {
          e && he.isFunction((n = e.promise))
            ? n.call(e).done(t).fail(i)
            : e && he.isFunction((n = e.then))
            ? n.call(e, t, i)
            : t.call(void 0, e);
        } catch (e) {
          i.call(void 0, e);
        }
      }
      function d() {
        te.removeEventListener('DOMContentLoaded', d),
          e.removeEventListener('load', d),
          he.ready();
      }
      function u() {
        this.expando = he.expando + u.uid++;
      }
      function p(e) {
        return (
          'true' === e ||
          ('false' !== e &&
            ('null' === e
              ? null
              : e === +e + ''
              ? +e
              : Pe.test(e)
              ? JSON.parse(e)
              : e))
        );
      }
      function f(e, t, i) {
        var n;
        if (void 0 === i && 1 === e.nodeType)
          if (
            ((n = 'data-' + t.replace(Le, '-$&').toLowerCase()),
            (i = e.getAttribute(n)),
            'string' == typeof i)
          ) {
            try {
              i = p(i);
            } catch (o) {}
            Oe.set(e, t, i);
          } else i = void 0;
        return i;
      }
      function h(e, t, i, n) {
        var o,
          r = 1,
          a = 20,
          s = n
            ? function () {
                return n.cur();
              }
            : function () {
                return he.css(e, t, '');
              },
          l = s(),
          c = (i && i[3]) || (he.cssNumber[t] ? '' : 'px'),
          d = (he.cssNumber[t] || ('px' !== c && +l)) && qe.exec(he.css(e, t));
        if (d && d[3] !== c) {
          (c = c || d[3]), (i = i || []), (d = +l || 1);
          do (r = r || '.5'), (d /= r), he.style(e, t, d + c);
          while (r !== (r = s() / l) && 1 !== r && --a);
        }
        return (
          i &&
            ((d = +d || +l || 0),
            (o = i[1] ? d + (i[1] + 1) * i[2] : +i[2]),
            n && ((n.unit = c), (n.start = d), (n.end = o))),
          o
        );
      }
      function g(e) {
        var t,
          i = e.ownerDocument,
          n = e.nodeName,
          o = ze[n];
        return o
          ? o
          : ((t = i.body.appendChild(i.createElement(n))),
            (o = he.css(t, 'display')),
            t.parentNode.removeChild(t),
            'none' === o && (o = 'block'),
            (ze[n] = o),
            o);
      }
      function m(e, t) {
        for (var i, n, o = [], r = 0, a = e.length; r < a; r++)
          (n = e[r]),
            n.style &&
              ((i = n.style.display),
              t
                ? ('none' === i &&
                    ((o[r] = He.get(n, 'display') || null),
                    o[r] || (n.style.display = '')),
                  '' === n.style.display && We(n) && (o[r] = g(n)))
                : 'none' !== i && ((o[r] = 'none'), He.set(n, 'display', i)));
        for (r = 0; r < a; r++) null != o[r] && (e[r].style.display = o[r]);
        return e;
      }
      function v(e, t) {
        var i;
        return (
          (i =
            'undefined' != typeof e.getElementsByTagName
              ? e.getElementsByTagName(t || '*')
              : 'undefined' != typeof e.querySelectorAll
              ? e.querySelectorAll(t || '*')
              : []),
          void 0 === t || (t && he.nodeName(e, t)) ? he.merge([e], i) : i
        );
      }
      function y(e, t) {
        for (var i = 0, n = e.length; i < n; i++)
          He.set(e[i], 'globalEval', !t || He.get(t[i], 'globalEval'));
      }
      function w(e, t, i, n, o) {
        for (
          var r,
            a,
            s,
            l,
            c,
            d,
            u = t.createDocumentFragment(),
            p = [],
            f = 0,
            h = e.length;
          f < h;
          f++
        )
          if (((r = e[f]), r || 0 === r))
            if ('object' === he.type(r)) he.merge(p, r.nodeType ? [r] : r);
            else if (Xe.test(r)) {
              for (
                a = a || u.appendChild(t.createElement('div')),
                  s = (Ue.exec(r) || ['', ''])[1].toLowerCase(),
                  l = Ke[s] || Ke._default,
                  a.innerHTML = l[1] + he.htmlPrefilter(r) + l[2],
                  d = l[0];
                d--;

              )
                a = a.lastChild;
              he.merge(p, a.childNodes),
                (a = u.firstChild),
                (a.textContent = '');
            } else p.push(t.createTextNode(r));
        for (u.textContent = '', f = 0; (r = p[f++]); )
          if (n && he.inArray(r, n) > -1) o && o.push(r);
          else if (
            ((c = he.contains(r.ownerDocument, r)),
            (a = v(u.appendChild(r), 'script')),
            c && y(a),
            i)
          )
            for (d = 0; (r = a[d++]); ) Qe.test(r.type || '') && i.push(r);
        return u;
      }
      function b() {
        return !0;
      }
      function k() {
        return !1;
      }
      function S() {
        try {
          return te.activeElement;
        } catch (e) {}
      }
      function x(e, t, i, n, o, r) {
        var a, s;
        if ('object' == typeof t) {
          'string' != typeof i && ((n = n || i), (i = void 0));
          for (s in t) x(e, s, i, n, t[s], r);
          return e;
        }
        if (
          (null == n && null == o
            ? ((o = i), (n = i = void 0))
            : null == o &&
              ('string' == typeof i
                ? ((o = n), (n = void 0))
                : ((o = n), (n = i), (i = void 0))),
          o === !1)
        )
          o = k;
        else if (!o) return e;
        return (
          1 === r &&
            ((a = o),
            (o = function (e) {
              return he().off(e), a.apply(this, arguments);
            }),
            (o.guid = a.guid || (a.guid = he.guid++))),
          e.each(function () {
            he.event.add(this, t, o, n, i);
          })
        );
      }
      function T(e, t) {
        return he.nodeName(e, 'table') &&
          he.nodeName(11 !== t.nodeType ? t : t.firstChild, 'tr')
          ? e.getElementsByTagName('tbody')[0] || e
          : e;
      }
      function C(e) {
        return (e.type = (null !== e.getAttribute('type')) + '/' + e.type), e;
      }
      function D(e) {
        var t = nt.exec(e.type);
        return t ? (e.type = t[1]) : e.removeAttribute('type'), e;
      }
      function I(e, t) {
        var i, n, o, r, a, s, l, c;
        if (1 === t.nodeType) {
          if (
            He.hasData(e) &&
            ((r = He.access(e)), (a = He.set(t, r)), (c = r.events))
          ) {
            delete a.handle, (a.events = {});
            for (o in c)
              for (i = 0, n = c[o].length; i < n; i++)
                he.event.add(t, o, c[o][i]);
          }
          Oe.hasData(e) &&
            ((s = Oe.access(e)), (l = he.extend({}, s)), Oe.set(t, l));
        }
      }
      function _(e, t) {
        var i = t.nodeName.toLowerCase();
        'input' === i && Ye.test(e.type)
          ? (t.checked = e.checked)
          : ('input' !== i && 'textarea' !== i) ||
            (t.defaultValue = e.defaultValue);
      }
      function $(e, t, n, o) {
        t = oe.apply([], t);
        var r,
          a,
          s,
          l,
          c,
          d,
          u = 0,
          p = e.length,
          f = p - 1,
          h = t[0],
          g = he.isFunction(h);
        if (
          g ||
          (p > 1 && 'string' == typeof h && !pe.checkClone && it.test(h))
        )
          return e.each(function (i) {
            var r = e.eq(i);
            g && (t[0] = h.call(this, i, r.html())), $(r, t, n, o);
          });
        if (
          p &&
          ((r = w(t, e[0].ownerDocument, !1, e, o)),
          (a = r.firstChild),
          1 === r.childNodes.length && (r = a),
          a || o)
        ) {
          for (s = he.map(v(r, 'script'), C), l = s.length; u < p; u++)
            (c = r),
              u !== f &&
                ((c = he.clone(c, !0, !0)), l && he.merge(s, v(c, 'script'))),
              n.call(e[u], c, u);
          if (l)
            for (
              d = s[s.length - 1].ownerDocument, he.map(s, D), u = 0;
              u < l;
              u++
            )
              (c = s[u]),
                Qe.test(c.type || '') &&
                  !He.access(c, 'globalEval') &&
                  he.contains(d, c) &&
                  (c.src
                    ? he._evalUrl && he._evalUrl(c.src)
                    : i(c.textContent.replace(ot, ''), d));
        }
        return e;
      }
      function M(e, t, i) {
        for (var n, o = t ? he.filter(t, e) : e, r = 0; null != (n = o[r]); r++)
          i || 1 !== n.nodeType || he.cleanData(v(n)),
            n.parentNode &&
              (i && he.contains(n.ownerDocument, n) && y(v(n, 'script')),
              n.parentNode.removeChild(n));
        return e;
      }
      function E(e, t, i) {
        var n,
          o,
          r,
          a,
          s = e.style;
        return (
          (i = i || st(e)),
          i &&
            ((a = i.getPropertyValue(t) || i[t]),
            '' !== a || he.contains(e.ownerDocument, e) || (a = he.style(e, t)),
            !pe.pixelMarginRight() &&
              at.test(a) &&
              rt.test(t) &&
              ((n = s.width),
              (o = s.minWidth),
              (r = s.maxWidth),
              (s.minWidth = s.maxWidth = s.width = a),
              (a = i.width),
              (s.width = n),
              (s.minWidth = o),
              (s.maxWidth = r))),
          void 0 !== a ? a + '' : a
        );
      }
      function j(e, t) {
        return {
          get: function () {
            return e()
              ? void delete this.get
              : (this.get = t).apply(this, arguments);
          },
        };
      }
      function A(e) {
        if (e in pt) return e;
        for (var t = e[0].toUpperCase() + e.slice(1), i = ut.length; i--; )
          if (((e = ut[i] + t), e in pt)) return e;
      }
      function N(e, t, i) {
        var n = qe.exec(t);
        return n ? Math.max(0, n[2] - (i || 0)) + (n[3] || 'px') : t;
      }
      function H(e, t, i, n, o) {
        var r,
          a = 0;
        for (
          r = i === (n ? 'border' : 'content') ? 4 : 'width' === t ? 1 : 0;
          r < 4;
          r += 2
        )
          'margin' === i && (a += he.css(e, i + Be[r], !0, o)),
            n
              ? ('content' === i && (a -= he.css(e, 'padding' + Be[r], !0, o)),
                'margin' !== i &&
                  (a -= he.css(e, 'border' + Be[r] + 'Width', !0, o)))
              : ((a += he.css(e, 'padding' + Be[r], !0, o)),
                'padding' !== i &&
                  (a += he.css(e, 'border' + Be[r] + 'Width', !0, o)));
        return a;
      }
      function O(e, t, i) {
        var n,
          o = !0,
          r = st(e),
          a = 'border-box' === he.css(e, 'boxSizing', !1, r);
        if (
          (e.getClientRects().length && (n = e.getBoundingClientRect()[t]),
          n <= 0 || null == n)
        ) {
          if (
            ((n = E(e, t, r)),
            (n < 0 || null == n) && (n = e.style[t]),
            at.test(n))
          )
            return n;
          (o = a && (pe.boxSizingReliable() || n === e.style[t])),
            (n = parseFloat(n) || 0);
        }
        return n + H(e, t, i || (a ? 'border' : 'content'), o, r) + 'px';
      }
      function P(e, t, i, n, o) {
        return new P.prototype.init(e, t, i, n, o);
      }
      function L() {
        ht && (e.requestAnimationFrame(L), he.fx.tick());
      }
      function F() {
        return (
          e.setTimeout(function () {
            ft = void 0;
          }),
          (ft = he.now())
        );
      }
      function q(e, t) {
        var i,
          n = 0,
          o = { height: e };
        for (t = t ? 1 : 0; n < 4; n += 2 - t)
          (i = Be[n]), (o['margin' + i] = o['padding' + i] = e);
        return t && (o.opacity = o.width = e), o;
      }
      function B(e, t, i) {
        for (
          var n,
            o = (z.tweeners[t] || []).concat(z.tweeners['*']),
            r = 0,
            a = o.length;
          r < a;
          r++
        )
          if ((n = o[r].call(i, t, e))) return n;
      }
      function W(e, t, i) {
        var n,
          o,
          r,
          a,
          s,
          l,
          c,
          d,
          u = 'width' in t || 'height' in t,
          p = this,
          f = {},
          h = e.style,
          g = e.nodeType && We(e),
          v = He.get(e, 'fxshow');
        i.queue ||
          ((a = he._queueHooks(e, 'fx')),
          null == a.unqueued &&
            ((a.unqueued = 0),
            (s = a.empty.fire),
            (a.empty.fire = function () {
              a.unqueued || s();
            })),
          a.unqueued++,
          p.always(function () {
            p.always(function () {
              a.unqueued--, he.queue(e, 'fx').length || a.empty.fire();
            });
          }));
        for (n in t)
          if (((o = t[n]), gt.test(o))) {
            if (
              (delete t[n],
              (r = r || 'toggle' === o),
              o === (g ? 'hide' : 'show'))
            ) {
              if ('show' !== o || !v || void 0 === v[n]) continue;
              g = !0;
            }
            f[n] = (v && v[n]) || he.style(e, n);
          }
        if (((l = !he.isEmptyObject(t)), l || !he.isEmptyObject(f))) {
          u &&
            1 === e.nodeType &&
            ((i.overflow = [h.overflow, h.overflowX, h.overflowY]),
            (c = v && v.display),
            null == c && (c = He.get(e, 'display')),
            (d = he.css(e, 'display')),
            'none' === d &&
              (c
                ? (d = c)
                : (m([e], !0),
                  (c = e.style.display || c),
                  (d = he.css(e, 'display')),
                  m([e]))),
            ('inline' === d || ('inline-block' === d && null != c)) &&
              'none' === he.css(e, 'float') &&
              (l ||
                (p.done(function () {
                  h.display = c;
                }),
                null == c && ((d = h.display), (c = 'none' === d ? '' : d))),
              (h.display = 'inline-block'))),
            i.overflow &&
              ((h.overflow = 'hidden'),
              p.always(function () {
                (h.overflow = i.overflow[0]),
                  (h.overflowX = i.overflow[1]),
                  (h.overflowY = i.overflow[2]);
              })),
            (l = !1);
          for (n in f)
            l ||
              (v
                ? 'hidden' in v && (g = v.hidden)
                : (v = He.access(e, 'fxshow', { display: c })),
              r && (v.hidden = !g),
              g && m([e], !0),
              p.done(function () {
                g || m([e]), He.remove(e, 'fxshow');
                for (n in f) he.style(e, n, f[n]);
              })),
              (l = B(g ? v[n] : 0, n, p)),
              n in v ||
                ((v[n] = l.start), g && ((l.end = l.start), (l.start = 0)));
        }
      }
      function R(e, t) {
        var i, n, o, r, a;
        for (i in e)
          if (
            ((n = he.camelCase(i)),
            (o = t[n]),
            (r = e[i]),
            he.isArray(r) && ((o = r[1]), (r = e[i] = r[0])),
            i !== n && ((e[n] = r), delete e[i]),
            (a = he.cssHooks[n]),
            a && 'expand' in a)
          ) {
            (r = a.expand(r)), delete e[n];
            for (i in r) i in e || ((e[i] = r[i]), (t[i] = o));
          } else t[n] = o;
      }
      function z(e, t, i) {
        var n,
          o,
          r = 0,
          a = z.prefilters.length,
          s = he.Deferred().always(function () {
            delete l.elem;
          }),
          l = function () {
            if (o) return !1;
            for (
              var t = ft || F(),
                i = Math.max(0, c.startTime + c.duration - t),
                n = i / c.duration || 0,
                r = 1 - n,
                a = 0,
                l = c.tweens.length;
              a < l;
              a++
            )
              c.tweens[a].run(r);
            return (
              s.notifyWith(e, [c, r, i]),
              r < 1 && l ? i : (s.resolveWith(e, [c]), !1)
            );
          },
          c = s.promise({
            elem: e,
            props: he.extend({}, t),
            opts: he.extend(
              !0,
              { specialEasing: {}, easing: he.easing._default },
              i
            ),
            originalProperties: t,
            originalOptions: i,
            startTime: ft || F(),
            duration: i.duration,
            tweens: [],
            createTween: function (t, i) {
              var n = he.Tween(
                e,
                c.opts,
                t,
                i,
                c.opts.specialEasing[t] || c.opts.easing
              );
              return c.tweens.push(n), n;
            },
            stop: function (t) {
              var i = 0,
                n = t ? c.tweens.length : 0;
              if (o) return this;
              for (o = !0; i < n; i++) c.tweens[i].run(1);
              return (
                t
                  ? (s.notifyWith(e, [c, 1, 0]), s.resolveWith(e, [c, t]))
                  : s.rejectWith(e, [c, t]),
                this
              );
            },
          }),
          d = c.props;
        for (R(d, c.opts.specialEasing); r < a; r++)
          if ((n = z.prefilters[r].call(c, e, d, c.opts)))
            return (
              he.isFunction(n.stop) &&
                (he._queueHooks(c.elem, c.opts.queue).stop = he.proxy(
                  n.stop,
                  n
                )),
              n
            );
        return (
          he.map(d, B, c),
          he.isFunction(c.opts.start) && c.opts.start.call(e, c),
          he.fx.timer(he.extend(l, { elem: e, anim: c, queue: c.opts.queue })),
          c
            .progress(c.opts.progress)
            .done(c.opts.done, c.opts.complete)
            .fail(c.opts.fail)
            .always(c.opts.always)
        );
      }
      function Y(e) {
        var t = e.match(Me) || [];
        return t.join(' ');
      }
      function U(e) {
        return (e.getAttribute && e.getAttribute('class')) || '';
      }
      function Q(e, t, i, n) {
        var o;
        if (he.isArray(t))
          he.each(t, function (t, o) {
            i || Dt.test(e)
              ? n(e, o)
              : Q(
                  e + '[' + ('object' == typeof o && null != o ? t : '') + ']',
                  o,
                  i,
                  n
                );
          });
        else if (i || 'object' !== he.type(t)) n(e, t);
        else for (o in t) Q(e + '[' + o + ']', t[o], i, n);
      }
      function K(e) {
        return function (t, i) {
          'string' != typeof t && ((i = t), (t = '*'));
          var n,
            o = 0,
            r = t.toLowerCase().match(Me) || [];
          if (he.isFunction(i))
            for (; (n = r[o++]); )
              '+' === n[0]
                ? ((n = n.slice(1) || '*'), (e[n] = e[n] || []).unshift(i))
                : (e[n] = e[n] || []).push(i);
        };
      }
      function X(e, t, i, n) {
        function o(s) {
          var l;
          return (
            (r[s] = !0),
            he.each(e[s] || [], function (e, s) {
              var c = s(t, i, n);
              return 'string' != typeof c || a || r[c]
                ? a
                  ? !(l = c)
                  : void 0
                : (t.dataTypes.unshift(c), o(c), !1);
            }),
            l
          );
        }
        var r = {},
          a = e === Lt;
        return o(t.dataTypes[0]) || (!r['*'] && o('*'));
      }
      function V(e, t) {
        var i,
          n,
          o = he.ajaxSettings.flatOptions || {};
        for (i in t) void 0 !== t[i] && ((o[i] ? e : n || (n = {}))[i] = t[i]);
        return n && he.extend(!0, e, n), e;
      }
      function G(e, t, i) {
        for (var n, o, r, a, s = e.contents, l = e.dataTypes; '*' === l[0]; )
          l.shift(),
            void 0 === n &&
              (n = e.mimeType || t.getResponseHeader('Content-Type'));
        if (n)
          for (o in s)
            if (s[o] && s[o].test(n)) {
              l.unshift(o);
              break;
            }
        if (l[0] in i) r = l[0];
        else {
          for (o in i) {
            if (!l[0] || e.converters[o + ' ' + l[0]]) {
              r = o;
              break;
            }
            a || (a = o);
          }
          r = r || a;
        }
        if (r) return r !== l[0] && l.unshift(r), i[r];
      }
      function J(e, t, i, n) {
        var o,
          r,
          a,
          s,
          l,
          c = {},
          d = e.dataTypes.slice();
        if (d[1]) for (a in e.converters) c[a.toLowerCase()] = e.converters[a];
        for (r = d.shift(); r; )
          if (
            (e.responseFields[r] && (i[e.responseFields[r]] = t),
            !l && n && e.dataFilter && (t = e.dataFilter(t, e.dataType)),
            (l = r),
            (r = d.shift()))
          )
            if ('*' === r) r = l;
            else if ('*' !== l && l !== r) {
              if (((a = c[l + ' ' + r] || c['* ' + r]), !a))
                for (o in c)
                  if (
                    ((s = o.split(' ')),
                    s[1] === r && (a = c[l + ' ' + s[0]] || c['* ' + s[0]]))
                  ) {
                    a === !0
                      ? (a = c[o])
                      : c[o] !== !0 && ((r = s[0]), d.unshift(s[1]));
                    break;
                  }
              if (a !== !0)
                if (a && e['throws']) t = a(t);
                else
                  try {
                    t = a(t);
                  } catch (u) {
                    return {
                      state: 'parsererror',
                      error: a ? u : 'No conversion from ' + l + ' to ' + r,
                    };
                  }
            }
        return { state: 'success', data: t };
      }
      function Z(e) {
        return he.isWindow(e) ? e : 9 === e.nodeType && e.defaultView;
      }
      var ee = [],
        te = e.document,
        ie = Object.getPrototypeOf,
        ne = ee.slice,
        oe = ee.concat,
        re = ee.push,
        ae = ee.indexOf,
        se = {},
        le = se.toString,
        ce = se.hasOwnProperty,
        de = ce.toString,
        ue = de.call(Object),
        pe = {},
        fe = '3.1.1',
        he = function (e, t) {
          return new he.fn.init(e, t);
        },
        ge = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g,
        me = /^-ms-/,
        ve = /-([a-z])/g,
        ye = function (e, t) {
          return t.toUpperCase();
        };
      (he.fn = he.prototype =
        {
          jquery: fe,
          constructor: he,
          length: 0,
          toArray: function () {
            return ne.call(this);
          },
          get: function (e) {
            return null == e
              ? ne.call(this)
              : e < 0
              ? this[e + this.length]
              : this[e];
          },
          pushStack: function (e) {
            var t = he.merge(this.constructor(), e);
            return (t.prevObject = this), t;
          },
          each: function (e) {
            return he.each(this, e);
          },
          map: function (e) {
            return this.pushStack(
              he.map(this, function (t, i) {
                return e.call(t, i, t);
              })
            );
          },
          slice: function () {
            return this.pushStack(ne.apply(this, arguments));
          },
          first: function () {
            return this.eq(0);
          },
          last: function () {
            return this.eq(-1);
          },
          eq: function (e) {
            var t = this.length,
              i = +e + (e < 0 ? t : 0);
            return this.pushStack(i >= 0 && i < t ? [this[i]] : []);
          },
          end: function () {
            return this.prevObject || this.constructor();
          },
          push: re,
          sort: ee.sort,
          splice: ee.splice,
        }),
        (he.extend = he.fn.extend =
          function () {
            var e,
              t,
              i,
              n,
              o,
              r,
              a = arguments[0] || {},
              s = 1,
              l = arguments.length,
              c = !1;
            for (
              'boolean' == typeof a && ((c = a), (a = arguments[s] || {}), s++),
                'object' == typeof a || he.isFunction(a) || (a = {}),
                s === l && ((a = this), s--);
              s < l;
              s++
            )
              if (null != (e = arguments[s]))
                for (t in e)
                  (i = a[t]),
                    (n = e[t]),
                    a !== n &&
                      (c && n && (he.isPlainObject(n) || (o = he.isArray(n)))
                        ? (o
                            ? ((o = !1), (r = i && he.isArray(i) ? i : []))
                            : (r = i && he.isPlainObject(i) ? i : {}),
                          (a[t] = he.extend(c, r, n)))
                        : void 0 !== n && (a[t] = n));
            return a;
          }),
        he.extend({
          expando: 'jQuery' + (fe + Math.random()).replace(/\D/g, ''),
          isReady: !0,
          error: function (e) {
            throw new Error(e);
          },
          noop: function () {},
          isFunction: function (e) {
            return 'function' === he.type(e);
          },
          isArray: Array.isArray,
          isWindow: function (e) {
            return null != e && e === e.window;
          },
          isNumeric: function (e) {
            var t = he.type(e);
            return (
              ('number' === t || 'string' === t) && !isNaN(e - parseFloat(e))
            );
          },
          isPlainObject: function (e) {
            var t, i;
            return !(
              !e ||
              '[object Object]' !== le.call(e) ||
              ((t = ie(e)) &&
                ((i = ce.call(t, 'constructor') && t.constructor),
                'function' != typeof i || de.call(i) !== ue))
            );
          },
          isEmptyObject: function (e) {
            var t;
            for (t in e) return !1;
            return !0;
          },
          type: function (e) {
            return null == e
              ? e + ''
              : 'object' == typeof e || 'function' == typeof e
              ? se[le.call(e)] || 'object'
              : typeof e;
          },
          globalEval: function (e) {
            i(e);
          },
          camelCase: function (e) {
            return e.replace(me, 'ms-').replace(ve, ye);
          },
          nodeName: function (e, t) {
            return e.nodeName && e.nodeName.toLowerCase() === t.toLowerCase();
          },
          each: function (e, t) {
            var i,
              o = 0;
            if (n(e))
              for (i = e.length; o < i && t.call(e[o], o, e[o]) !== !1; o++);
            else for (o in e) if (t.call(e[o], o, e[o]) === !1) break;
            return e;
          },
          trim: function (e) {
            return null == e ? '' : (e + '').replace(ge, '');
          },
          makeArray: function (e, t) {
            var i = t || [];
            return (
              null != e &&
                (n(Object(e))
                  ? he.merge(i, 'string' == typeof e ? [e] : e)
                  : re.call(i, e)),
              i
            );
          },
          inArray: function (e, t, i) {
            return null == t ? -1 : ae.call(t, e, i);
          },
          merge: function (e, t) {
            for (var i = +t.length, n = 0, o = e.length; n < i; n++)
              e[o++] = t[n];
            return (e.length = o), e;
          },
          grep: function (e, t, i) {
            for (var n, o = [], r = 0, a = e.length, s = !i; r < a; r++)
              (n = !t(e[r], r)), n !== s && o.push(e[r]);
            return o;
          },
          map: function (e, t, i) {
            var o,
              r,
              a = 0,
              s = [];
            if (n(e))
              for (o = e.length; a < o; a++)
                (r = t(e[a], a, i)), null != r && s.push(r);
            else for (a in e) (r = t(e[a], a, i)), null != r && s.push(r);
            return oe.apply([], s);
          },
          guid: 1,
          proxy: function (e, t) {
            var i, n, o;
            if (
              ('string' == typeof t && ((i = e[t]), (t = e), (e = i)),
              he.isFunction(e))
            )
              return (
                (n = ne.call(arguments, 2)),
                (o = function () {
                  return e.apply(t || this, n.concat(ne.call(arguments)));
                }),
                (o.guid = e.guid = e.guid || he.guid++),
                o
              );
          },
          now: Date.now,
          support: pe,
        }),
        'function' == typeof Symbol &&
          (he.fn[Symbol.iterator] = ee[Symbol.iterator]),
        he.each(
          'Boolean Number String Function Array Date RegExp Object Error Symbol'.split(
            ' '
          ),
          function (e, t) {
            se['[object ' + t + ']'] = t.toLowerCase();
          }
        );
      var we = (function (e) {
        function t(e, t, i, n) {
          var o,
            r,
            a,
            s,
            l,
            c,
            d,
            p = t && t.ownerDocument,
            h = t ? t.nodeType : 9;
          if (
            ((i = i || []),
            'string' != typeof e || !e || (1 !== h && 9 !== h && 11 !== h))
          )
            return i;
          if (
            !n &&
            ((t ? t.ownerDocument || t : B) !== A && j(t), (t = t || A), H)
          ) {
            if (11 !== h && (l = ve.exec(e)))
              if ((o = l[1])) {
                if (9 === h) {
                  if (!(a = t.getElementById(o))) return i;
                  if (a.id === o) return i.push(a), i;
                } else if (
                  p &&
                  (a = p.getElementById(o)) &&
                  F(t, a) &&
                  a.id === o
                )
                  return i.push(a), i;
              } else {
                if (l[2]) return J.apply(i, t.getElementsByTagName(e)), i;
                if (
                  (o = l[3]) &&
                  S.getElementsByClassName &&
                  t.getElementsByClassName
                )
                  return J.apply(i, t.getElementsByClassName(o)), i;
              }
            if (S.qsa && !U[e + ' '] && (!O || !O.test(e))) {
              if (1 !== h) (p = t), (d = e);
              else if ('object' !== t.nodeName.toLowerCase()) {
                for (
                  (s = t.getAttribute('id'))
                    ? (s = s.replace(ke, Se))
                    : t.setAttribute('id', (s = q)),
                    c = D(e),
                    r = c.length;
                  r--;

                )
                  c[r] = '#' + s + ' ' + f(c[r]);
                (d = c.join(',')), (p = (ye.test(e) && u(t.parentNode)) || t);
              }
              if (d)
                try {
                  return J.apply(i, p.querySelectorAll(d)), i;
                } catch (g) {
                } finally {
                  s === q && t.removeAttribute('id');
                }
            }
          }
          return _(e.replace(se, '$1'), t, i, n);
        }
        function i() {
          function e(i, n) {
            return (
              t.push(i + ' ') > x.cacheLength && delete e[t.shift()],
              (e[i + ' '] = n)
            );
          }
          var t = [];
          return e;
        }
        function n(e) {
          return (e[q] = !0), e;
        }
        function o(e) {
          var t = A.createElement('fieldset');
          try {
            return !!e(t);
          } catch (i) {
            return !1;
          } finally {
            t.parentNode && t.parentNode.removeChild(t), (t = null);
          }
        }
        function r(e, t) {
          for (var i = e.split('|'), n = i.length; n--; )
            x.attrHandle[i[n]] = t;
        }
        function a(e, t) {
          var i = t && e,
            n =
              i &&
              1 === e.nodeType &&
              1 === t.nodeType &&
              e.sourceIndex - t.sourceIndex;
          if (n) return n;
          if (i) for (; (i = i.nextSibling); ) if (i === t) return -1;
          return e ? 1 : -1;
        }
        function s(e) {
          return function (t) {
            var i = t.nodeName.toLowerCase();
            return 'input' === i && t.type === e;
          };
        }
        function l(e) {
          return function (t) {
            var i = t.nodeName.toLowerCase();
            return ('input' === i || 'button' === i) && t.type === e;
          };
        }
        function c(e) {
          return function (t) {
            return 'form' in t
              ? t.parentNode && t.disabled === !1
                ? 'label' in t
                  ? 'label' in t.parentNode
                    ? t.parentNode.disabled === e
                    : t.disabled === e
                  : t.isDisabled === e || (t.isDisabled !== !e && Te(t) === e)
                : t.disabled === e
              : 'label' in t && t.disabled === e;
          };
        }
        function d(e) {
          return n(function (t) {
            return (
              (t = +t),
              n(function (i, n) {
                for (var o, r = e([], i.length, t), a = r.length; a--; )
                  i[(o = r[a])] && (i[o] = !(n[o] = i[o]));
              })
            );
          });
        }
        function u(e) {
          return e && 'undefined' != typeof e.getElementsByTagName && e;
        }
        function p() {}
        function f(e) {
          for (var t = 0, i = e.length, n = ''; t < i; t++) n += e[t].value;
          return n;
        }
        function h(e, t, i) {
          var n = t.dir,
            o = t.next,
            r = o || n,
            a = i && 'parentNode' === r,
            s = R++;
          return t.first
            ? function (t, i, o) {
                for (; (t = t[n]); )
                  if (1 === t.nodeType || a) return e(t, i, o);
                return !1;
              }
            : function (t, i, l) {
                var c,
                  d,
                  u,
                  p = [W, s];
                if (l) {
                  for (; (t = t[n]); )
                    if ((1 === t.nodeType || a) && e(t, i, l)) return !0;
                } else
                  for (; (t = t[n]); )
                    if (1 === t.nodeType || a)
                      if (
                        ((u = t[q] || (t[q] = {})),
                        (d = u[t.uniqueID] || (u[t.uniqueID] = {})),
                        o && o === t.nodeName.toLowerCase())
                      )
                        t = t[n] || t;
                      else {
                        if ((c = d[r]) && c[0] === W && c[1] === s)
                          return (p[2] = c[2]);
                        if (((d[r] = p), (p[2] = e(t, i, l)))) return !0;
                      }
                return !1;
              };
        }
        function g(e) {
          return e.length > 1
            ? function (t, i, n) {
                for (var o = e.length; o--; ) if (!e[o](t, i, n)) return !1;
                return !0;
              }
            : e[0];
        }
        function m(e, i, n) {
          for (var o = 0, r = i.length; o < r; o++) t(e, i[o], n);
          return n;
        }
        function v(e, t, i, n, o) {
          for (var r, a = [], s = 0, l = e.length, c = null != t; s < l; s++)
            (r = e[s]) && ((i && !i(r, n, o)) || (a.push(r), c && t.push(s)));
          return a;
        }
        function y(e, t, i, o, r, a) {
          return (
            o && !o[q] && (o = y(o)),
            r && !r[q] && (r = y(r, a)),
            n(function (n, a, s, l) {
              var c,
                d,
                u,
                p = [],
                f = [],
                h = a.length,
                g = n || m(t || '*', s.nodeType ? [s] : s, []),
                y = !e || (!n && t) ? g : v(g, p, e, s, l),
                w = i ? (r || (n ? e : h || o) ? [] : a) : y;
              if ((i && i(y, w, s, l), o))
                for (c = v(w, f), o(c, [], s, l), d = c.length; d--; )
                  (u = c[d]) && (w[f[d]] = !(y[f[d]] = u));
              if (n) {
                if (r || e) {
                  if (r) {
                    for (c = [], d = w.length; d--; )
                      (u = w[d]) && c.push((y[d] = u));
                    r(null, (w = []), c, l);
                  }
                  for (d = w.length; d--; )
                    (u = w[d]) &&
                      (c = r ? ee(n, u) : p[d]) > -1 &&
                      (n[c] = !(a[c] = u));
                }
              } else (w = v(w === a ? w.splice(h, w.length) : w)), r ? r(null, a, w, l) : J.apply(a, w);
            })
          );
        }
        function w(e) {
          for (
            var t,
              i,
              n,
              o = e.length,
              r = x.relative[e[0].type],
              a = r || x.relative[' '],
              s = r ? 1 : 0,
              l = h(
                function (e) {
                  return e === t;
                },
                a,
                !0
              ),
              c = h(
                function (e) {
                  return ee(t, e) > -1;
                },
                a,
                !0
              ),
              d = [
                function (e, i, n) {
                  var o =
                    (!r && (n || i !== $)) ||
                    ((t = i).nodeType ? l(e, i, n) : c(e, i, n));
                  return (t = null), o;
                },
              ];
            s < o;
            s++
          )
            if ((i = x.relative[e[s].type])) d = [h(g(d), i)];
            else {
              if (((i = x.filter[e[s].type].apply(null, e[s].matches)), i[q])) {
                for (n = ++s; n < o && !x.relative[e[n].type]; n++);
                return y(
                  s > 1 && g(d),
                  s > 1 &&
                    f(
                      e
                        .slice(0, s - 1)
                        .concat({ value: ' ' === e[s - 2].type ? '*' : '' })
                    ).replace(se, '$1'),
                  i,
                  s < n && w(e.slice(s, n)),
                  n < o && w((e = e.slice(n))),
                  n < o && f(e)
                );
              }
              d.push(i);
            }
          return g(d);
        }
        function b(e, i) {
          var o = i.length > 0,
            r = e.length > 0,
            a = function (n, a, s, l, c) {
              var d,
                u,
                p,
                f = 0,
                h = '0',
                g = n && [],
                m = [],
                y = $,
                w = n || (r && x.find.TAG('*', c)),
                b = (W += null == y ? 1 : Math.random() || 0.1),
                k = w.length;
              for (
                c && ($ = a === A || a || c);
                h !== k && null != (d = w[h]);
                h++
              ) {
                if (r && d) {
                  for (
                    u = 0, a || d.ownerDocument === A || (j(d), (s = !H));
                    (p = e[u++]);

                  )
                    if (p(d, a || A, s)) {
                      l.push(d);
                      break;
                    }
                  c && (W = b);
                }
                o && ((d = !p && d) && f--, n && g.push(d));
              }
              if (((f += h), o && h !== f)) {
                for (u = 0; (p = i[u++]); ) p(g, m, a, s);
                if (n) {
                  if (f > 0) for (; h--; ) g[h] || m[h] || (m[h] = V.call(l));
                  m = v(m);
                }
                J.apply(l, m),
                  c &&
                    !n &&
                    m.length > 0 &&
                    f + i.length > 1 &&
                    t.uniqueSort(l);
              }
              return c && ((W = b), ($ = y)), g;
            };
          return o ? n(a) : a;
        }
        var k,
          S,
          x,
          T,
          C,
          D,
          I,
          _,
          $,
          M,
          E,
          j,
          A,
          N,
          H,
          O,
          P,
          L,
          F,
          q = 'sizzle' + 1 * new Date(),
          B = e.document,
          W = 0,
          R = 0,
          z = i(),
          Y = i(),
          U = i(),
          Q = function (e, t) {
            return e === t && (E = !0), 0;
          },
          K = {}.hasOwnProperty,
          X = [],
          V = X.pop,
          G = X.push,
          J = X.push,
          Z = X.slice,
          ee = function (e, t) {
            for (var i = 0, n = e.length; i < n; i++) if (e[i] === t) return i;
            return -1;
          },
          te =
            'checked|selected|async|autofocus|autoplay|controls|defer|disabled|hidden|ismap|loop|multiple|open|readonly|required|scoped',
          ie = '[\\x20\\t\\r\\n\\f]',
          ne = '(?:\\\\.|[\\w-]|[^\0-\\xa0])+',
          oe =
            '\\[' +
            ie +
            '*(' +
            ne +
            ')(?:' +
            ie +
            '*([*^$|!~]?=)' +
            ie +
            '*(?:\'((?:\\\\.|[^\\\\\'])*)\'|"((?:\\\\.|[^\\\\"])*)"|(' +
            ne +
            '))|)' +
            ie +
            '*\\]',
          re =
            ':(' +
            ne +
            ')(?:\\(((\'((?:\\\\.|[^\\\\\'])*)\'|"((?:\\\\.|[^\\\\"])*)")|((?:\\\\.|[^\\\\()[\\]]|' +
            oe +
            ')*)|.*)\\)|)',
          ae = new RegExp(ie + '+', 'g'),
          se = new RegExp(
            '^' + ie + '+|((?:^|[^\\\\])(?:\\\\.)*)' + ie + '+$',
            'g'
          ),
          le = new RegExp('^' + ie + '*,' + ie + '*'),
          ce = new RegExp('^' + ie + '*([>+~]|' + ie + ')' + ie + '*'),
          de = new RegExp('=' + ie + '*([^\\]\'"]*?)' + ie + '*\\]', 'g'),
          ue = new RegExp(re),
          pe = new RegExp('^' + ne + '$'),
          fe = {
            ID: new RegExp('^#(' + ne + ')'),
            CLASS: new RegExp('^\\.(' + ne + ')'),
            TAG: new RegExp('^(' + ne + '|[*])'),
            ATTR: new RegExp('^' + oe),
            PSEUDO: new RegExp('^' + re),
            CHILD: new RegExp(
              '^:(only|first|last|nth|nth-last)-(child|of-type)(?:\\(' +
                ie +
                '*(even|odd|(([+-]|)(\\d*)n|)' +
                ie +
                '*(?:([+-]|)' +
                ie +
                '*(\\d+)|))' +
                ie +
                '*\\)|)',
              'i'
            ),
            bool: new RegExp('^(?:' + te + ')$', 'i'),
            needsContext: new RegExp(
              '^' +
                ie +
                '*[>+~]|:(even|odd|eq|gt|lt|nth|first|last)(?:\\(' +
                ie +
                '*((?:-\\d)?\\d*)' +
                ie +
                '*\\)|)(?=[^-]|$)',
              'i'
            ),
          },
          he = /^(?:input|select|textarea|button)$/i,
          ge = /^h\d$/i,
          me = /^[^{]+\{\s*\[native \w/,
          ve = /^(?:#([\w-]+)|(\w+)|\.([\w-]+))$/,
          ye = /[+~]/,
          we = new RegExp(
            '\\\\([\\da-f]{1,6}' + ie + '?|(' + ie + ')|.)',
            'ig'
          ),
          be = function (e, t, i) {
            var n = '0x' + t - 65536;
            return n !== n || i
              ? t
              : n < 0
              ? String.fromCharCode(n + 65536)
              : String.fromCharCode((n >> 10) | 55296, (1023 & n) | 56320);
          },
          ke = /([\0-\x1f\x7f]|^-?\d)|^-$|[^\0-\x1f\x7f-\uFFFF\w-]/g,
          Se = function (e, t) {
            return t
              ? '\0' === e
                ? '�'
                : e.slice(0, -1) +
                  '\\' +
                  e.charCodeAt(e.length - 1).toString(16) +
                  ' '
              : '\\' + e;
          },
          xe = function () {
            j();
          },
          Te = h(
            function (e) {
              return e.disabled === !0 && ('form' in e || 'label' in e);
            },
            { dir: 'parentNode', next: 'legend' }
          );
        try {
          J.apply((X = Z.call(B.childNodes)), B.childNodes),
            X[B.childNodes.length].nodeType;
        } catch (Ce) {
          J = {
            apply: X.length
              ? function (e, t) {
                  G.apply(e, Z.call(t));
                }
              : function (e, t) {
                  for (var i = e.length, n = 0; (e[i++] = t[n++]); );
                  e.length = i - 1;
                },
          };
        }
        (S = t.support = {}),
          (C = t.isXML =
            function (e) {
              var t = e && (e.ownerDocument || e).documentElement;
              return !!t && 'HTML' !== t.nodeName;
            }),
          (j = t.setDocument =
            function (e) {
              var t,
                i,
                n = e ? e.ownerDocument || e : B;
              return n !== A && 9 === n.nodeType && n.documentElement
                ? ((A = n),
                  (N = A.documentElement),
                  (H = !C(A)),
                  B !== A &&
                    (i = A.defaultView) &&
                    i.top !== i &&
                    (i.addEventListener
                      ? i.addEventListener('unload', xe, !1)
                      : i.attachEvent && i.attachEvent('onunload', xe)),
                  (S.attributes = o(function (e) {
                    return (e.className = 'i'), !e.getAttribute('className');
                  })),
                  (S.getElementsByTagName = o(function (e) {
                    return (
                      e.appendChild(A.createComment('')),
                      !e.getElementsByTagName('*').length
                    );
                  })),
                  (S.getElementsByClassName = me.test(
                    A.getElementsByClassName
                  )),
                  (S.getById = o(function (e) {
                    return (
                      (N.appendChild(e).id = q),
                      !A.getElementsByName || !A.getElementsByName(q).length
                    );
                  })),
                  S.getById
                    ? ((x.filter.ID = function (e) {
                        var t = e.replace(we, be);
                        return function (e) {
                          return e.getAttribute('id') === t;
                        };
                      }),
                      (x.find.ID = function (e, t) {
                        if ('undefined' != typeof t.getElementById && H) {
                          var i = t.getElementById(e);
                          return i ? [i] : [];
                        }
                      }))
                    : ((x.filter.ID = function (e) {
                        var t = e.replace(we, be);
                        return function (e) {
                          var i =
                            'undefined' != typeof e.getAttributeNode &&
                            e.getAttributeNode('id');
                          return i && i.value === t;
                        };
                      }),
                      (x.find.ID = function (e, t) {
                        if ('undefined' != typeof t.getElementById && H) {
                          var i,
                            n,
                            o,
                            r = t.getElementById(e);
                          if (r) {
                            if (
                              ((i = r.getAttributeNode('id')),
                              i && i.value === e)
                            )
                              return [r];
                            for (
                              o = t.getElementsByName(e), n = 0;
                              (r = o[n++]);

                            )
                              if (
                                ((i = r.getAttributeNode('id')),
                                i && i.value === e)
                              )
                                return [r];
                          }
                          return [];
                        }
                      })),
                  (x.find.TAG = S.getElementsByTagName
                    ? function (e, t) {
                        return 'undefined' != typeof t.getElementsByTagName
                          ? t.getElementsByTagName(e)
                          : S.qsa
                          ? t.querySelectorAll(e)
                          : void 0;
                      }
                    : function (e, t) {
                        var i,
                          n = [],
                          o = 0,
                          r = t.getElementsByTagName(e);
                        if ('*' === e) {
                          for (; (i = r[o++]); ) 1 === i.nodeType && n.push(i);
                          return n;
                        }
                        return r;
                      }),
                  (x.find.CLASS =
                    S.getElementsByClassName &&
                    function (e, t) {
                      if ('undefined' != typeof t.getElementsByClassName && H)
                        return t.getElementsByClassName(e);
                    }),
                  (P = []),
                  (O = []),
                  (S.qsa = me.test(A.querySelectorAll)) &&
                    (o(function (e) {
                      (N.appendChild(e).innerHTML =
                        "<a id='" +
                        q +
                        "'></a><select id='" +
                        q +
                        "-\r\\' msallowcapture=''><option selected=''></option></select>"),
                        e.querySelectorAll("[msallowcapture^='']").length &&
                          O.push('[*^$]=' + ie + '*(?:\'\'|"")'),
                        e.querySelectorAll('[selected]').length ||
                          O.push('\\[' + ie + '*(?:value|' + te + ')'),
                        e.querySelectorAll('[id~=' + q + '-]').length ||
                          O.push('~='),
                        e.querySelectorAll(':checked').length ||
                          O.push(':checked'),
                        e.querySelectorAll('a#' + q + '+*').length ||
                          O.push('.#.+[+~]');
                    }),
                    o(function (e) {
                      e.innerHTML =
                        "<a href='' disabled='disabled'></a><select disabled='disabled'><option/></select>";
                      var t = A.createElement('input');
                      t.setAttribute('type', 'hidden'),
                        e.appendChild(t).setAttribute('name', 'D'),
                        e.querySelectorAll('[name=d]').length &&
                          O.push('name' + ie + '*[*^$|!~]?='),
                        2 !== e.querySelectorAll(':enabled').length &&
                          O.push(':enabled', ':disabled'),
                        (N.appendChild(e).disabled = !0),
                        2 !== e.querySelectorAll(':disabled').length &&
                          O.push(':enabled', ':disabled'),
                        e.querySelectorAll('*,:x'),
                        O.push(',.*:');
                    })),
                  (S.matchesSelector = me.test(
                    (L =
                      N.matches ||
                      N.webkitMatchesSelector ||
                      N.mozMatchesSelector ||
                      N.oMatchesSelector ||
                      N.msMatchesSelector)
                  )) &&
                    o(function (e) {
                      (S.disconnectedMatch = L.call(e, '*')),
                        L.call(e, "[s!='']:x"),
                        P.push('!=', re);
                    }),
                  (O = O.length && new RegExp(O.join('|'))),
                  (P = P.length && new RegExp(P.join('|'))),
                  (t = me.test(N.compareDocumentPosition)),
                  (F =
                    t || me.test(N.contains)
                      ? function (e, t) {
                          var i = 9 === e.nodeType ? e.documentElement : e,
                            n = t && t.parentNode;
                          return (
                            e === n ||
                            !(
                              !n ||
                              1 !== n.nodeType ||
                              !(i.contains
                                ? i.contains(n)
                                : e.compareDocumentPosition &&
                                  16 & e.compareDocumentPosition(n))
                            )
                          );
                        }
                      : function (e, t) {
                          if (t)
                            for (; (t = t.parentNode); ) if (t === e) return !0;
                          return !1;
                        }),
                  (Q = t
                    ? function (e, t) {
                        if (e === t) return (E = !0), 0;
                        var i =
                          !e.compareDocumentPosition -
                          !t.compareDocumentPosition;
                        return i
                          ? i
                          : ((i =
                              (e.ownerDocument || e) === (t.ownerDocument || t)
                                ? e.compareDocumentPosition(t)
                                : 1),
                            1 & i ||
                            (!S.sortDetached &&
                              t.compareDocumentPosition(e) === i)
                              ? e === A || (e.ownerDocument === B && F(B, e))
                                ? -1
                                : t === A || (t.ownerDocument === B && F(B, t))
                                ? 1
                                : M
                                ? ee(M, e) - ee(M, t)
                                : 0
                              : 4 & i
                              ? -1
                              : 1);
                      }
                    : function (e, t) {
                        if (e === t) return (E = !0), 0;
                        var i,
                          n = 0,
                          o = e.parentNode,
                          r = t.parentNode,
                          s = [e],
                          l = [t];
                        if (!o || !r)
                          return e === A
                            ? -1
                            : t === A
                            ? 1
                            : o
                            ? -1
                            : r
                            ? 1
                            : M
                            ? ee(M, e) - ee(M, t)
                            : 0;
                        if (o === r) return a(e, t);
                        for (i = e; (i = i.parentNode); ) s.unshift(i);
                        for (i = t; (i = i.parentNode); ) l.unshift(i);
                        for (; s[n] === l[n]; ) n++;
                        return n
                          ? a(s[n], l[n])
                          : s[n] === B
                          ? -1
                          : l[n] === B
                          ? 1
                          : 0;
                      }),
                  A)
                : A;
            }),
          (t.matches = function (e, i) {
            return t(e, null, null, i);
          }),
          (t.matchesSelector = function (e, i) {
            if (
              ((e.ownerDocument || e) !== A && j(e),
              (i = i.replace(de, "='$1']")),
              S.matchesSelector &&
                H &&
                !U[i + ' '] &&
                (!P || !P.test(i)) &&
                (!O || !O.test(i)))
            )
              try {
                var n = L.call(e, i);
                if (
                  n ||
                  S.disconnectedMatch ||
                  (e.document && 11 !== e.document.nodeType)
                )
                  return n;
              } catch (o) {}
            return t(i, A, null, [e]).length > 0;
          }),
          (t.contains = function (e, t) {
            return (e.ownerDocument || e) !== A && j(e), F(e, t);
          }),
          (t.attr = function (e, t) {
            (e.ownerDocument || e) !== A && j(e);
            var i = x.attrHandle[t.toLowerCase()],
              n =
                i && K.call(x.attrHandle, t.toLowerCase())
                  ? i(e, t, !H)
                  : void 0;
            return void 0 !== n
              ? n
              : S.attributes || !H
              ? e.getAttribute(t)
              : (n = e.getAttributeNode(t)) && n.specified
              ? n.value
              : null;
          }),
          (t.escape = function (e) {
            return (e + '').replace(ke, Se);
          }),
          (t.error = function (e) {
            throw new Error('Syntax error, unrecognized expression: ' + e);
          }),
          (t.uniqueSort = function (e) {
            var t,
              i = [],
              n = 0,
              o = 0;
            if (
              ((E = !S.detectDuplicates),
              (M = !S.sortStable && e.slice(0)),
              e.sort(Q),
              E)
            ) {
              for (; (t = e[o++]); ) t === e[o] && (n = i.push(o));
              for (; n--; ) e.splice(i[n], 1);
            }
            return (M = null), e;
          }),
          (T = t.getText =
            function (e) {
              var t,
                i = '',
                n = 0,
                o = e.nodeType;
              if (o) {
                if (1 === o || 9 === o || 11 === o) {
                  if ('string' == typeof e.textContent) return e.textContent;
                  for (e = e.firstChild; e; e = e.nextSibling) i += T(e);
                } else if (3 === o || 4 === o) return e.nodeValue;
              } else for (; (t = e[n++]); ) i += T(t);
              return i;
            }),
          (x = t.selectors =
            {
              cacheLength: 50,
              createPseudo: n,
              match: fe,
              attrHandle: {},
              find: {},
              relative: {
                '>': { dir: 'parentNode', first: !0 },
                ' ': { dir: 'parentNode' },
                '+': { dir: 'previousSibling', first: !0 },
                '~': { dir: 'previousSibling' },
              },
              preFilter: {
                ATTR: function (e) {
                  return (
                    (e[1] = e[1].replace(we, be)),
                    (e[3] = (e[3] || e[4] || e[5] || '').replace(we, be)),
                    '~=' === e[2] && (e[3] = ' ' + e[3] + ' '),
                    e.slice(0, 4)
                  );
                },
                CHILD: function (e) {
                  return (
                    (e[1] = e[1].toLowerCase()),
                    'nth' === e[1].slice(0, 3)
                      ? (e[3] || t.error(e[0]),
                        (e[4] = +(e[4]
                          ? e[5] + (e[6] || 1)
                          : 2 * ('even' === e[3] || 'odd' === e[3]))),
                        (e[5] = +(e[7] + e[8] || 'odd' === e[3])))
                      : e[3] && t.error(e[0]),
                    e
                  );
                },
                PSEUDO: function (e) {
                  var t,
                    i = !e[6] && e[2];
                  return fe.CHILD.test(e[0])
                    ? null
                    : (e[3]
                        ? (e[2] = e[4] || e[5] || '')
                        : i &&
                          ue.test(i) &&
                          (t = D(i, !0)) &&
                          (t = i.indexOf(')', i.length - t) - i.length) &&
                          ((e[0] = e[0].slice(0, t)), (e[2] = i.slice(0, t))),
                      e.slice(0, 3));
                },
              },
              filter: {
                TAG: function (e) {
                  var t = e.replace(we, be).toLowerCase();
                  return '*' === e
                    ? function () {
                        return !0;
                      }
                    : function (e) {
                        return e.nodeName && e.nodeName.toLowerCase() === t;
                      };
                },
                CLASS: function (e) {
                  var t = z[e + ' '];
                  return (
                    t ||
                    ((t = new RegExp(
                      '(^|' + ie + ')' + e + '(' + ie + '|$)'
                    )) &&
                      z(e, function (e) {
                        return t.test(
                          ('string' == typeof e.className && e.className) ||
                            ('undefined' != typeof e.getAttribute &&
                              e.getAttribute('class')) ||
                            ''
                        );
                      }))
                  );
                },
                ATTR: function (e, i, n) {
                  return function (o) {
                    var r = t.attr(o, e);
                    return null == r
                      ? '!=' === i
                      : !i ||
                          ((r += ''),
                          '=' === i
                            ? r === n
                            : '!=' === i
                            ? r !== n
                            : '^=' === i
                            ? n && 0 === r.indexOf(n)
                            : '*=' === i
                            ? n && r.indexOf(n) > -1
                            : '$=' === i
                            ? n && r.slice(-n.length) === n
                            : '~=' === i
                            ? (' ' + r.replace(ae, ' ') + ' ').indexOf(n) > -1
                            : '|=' === i &&
                              (r === n ||
                                r.slice(0, n.length + 1) === n + '-'));
                  };
                },
                CHILD: function (e, t, i, n, o) {
                  var r = 'nth' !== e.slice(0, 3),
                    a = 'last' !== e.slice(-4),
                    s = 'of-type' === t;
                  return 1 === n && 0 === o
                    ? function (e) {
                        return !!e.parentNode;
                      }
                    : function (t, i, l) {
                        var c,
                          d,
                          u,
                          p,
                          f,
                          h,
                          g = r !== a ? 'nextSibling' : 'previousSibling',
                          m = t.parentNode,
                          v = s && t.nodeName.toLowerCase(),
                          y = !l && !s,
                          w = !1;
                        if (m) {
                          if (r) {
                            for (; g; ) {
                              for (p = t; (p = p[g]); )
                                if (
                                  s
                                    ? p.nodeName.toLowerCase() === v
                                    : 1 === p.nodeType
                                )
                                  return !1;
                              h = g = 'only' === e && !h && 'nextSibling';
                            }
                            return !0;
                          }
                          if (
                            ((h = [a ? m.firstChild : m.lastChild]), a && y)
                          ) {
                            for (
                              p = m,
                                u = p[q] || (p[q] = {}),
                                d = u[p.uniqueID] || (u[p.uniqueID] = {}),
                                c = d[e] || [],
                                f = c[0] === W && c[1],
                                w = f && c[2],
                                p = f && m.childNodes[f];
                              (p =
                                (++f && p && p[g]) || (w = f = 0) || h.pop());

                            )
                              if (1 === p.nodeType && ++w && p === t) {
                                d[e] = [W, f, w];
                                break;
                              }
                          } else if (
                            (y &&
                              ((p = t),
                              (u = p[q] || (p[q] = {})),
                              (d = u[p.uniqueID] || (u[p.uniqueID] = {})),
                              (c = d[e] || []),
                              (f = c[0] === W && c[1]),
                              (w = f)),
                            w === !1)
                          )
                            for (
                              ;
                              (p =
                                (++f && p && p[g]) || (w = f = 0) || h.pop()) &&
                              ((s
                                ? p.nodeName.toLowerCase() !== v
                                : 1 !== p.nodeType) ||
                                !++w ||
                                (y &&
                                  ((u = p[q] || (p[q] = {})),
                                  (d = u[p.uniqueID] || (u[p.uniqueID] = {})),
                                  (d[e] = [W, w])),
                                p !== t));

                            );
                          return (
                            (w -= o), w === n || (w % n === 0 && w / n >= 0)
                          );
                        }
                      };
                },
                PSEUDO: function (e, i) {
                  var o,
                    r =
                      x.pseudos[e] ||
                      x.setFilters[e.toLowerCase()] ||
                      t.error('unsupported pseudo: ' + e);
                  return r[q]
                    ? r(i)
                    : r.length > 1
                    ? ((o = [e, e, '', i]),
                      x.setFilters.hasOwnProperty(e.toLowerCase())
                        ? n(function (e, t) {
                            for (var n, o = r(e, i), a = o.length; a--; )
                              (n = ee(e, o[a])), (e[n] = !(t[n] = o[a]));
                          })
                        : function (e) {
                            return r(e, 0, o);
                          })
                    : r;
                },
              },
              pseudos: {
                not: n(function (e) {
                  var t = [],
                    i = [],
                    o = I(e.replace(se, '$1'));
                  return o[q]
                    ? n(function (e, t, i, n) {
                        for (var r, a = o(e, null, n, []), s = e.length; s--; )
                          (r = a[s]) && (e[s] = !(t[s] = r));
                      })
                    : function (e, n, r) {
                        return (
                          (t[0] = e), o(t, null, r, i), (t[0] = null), !i.pop()
                        );
                      };
                }),
                has: n(function (e) {
                  return function (i) {
                    return t(e, i).length > 0;
                  };
                }),
                contains: n(function (e) {
                  return (
                    (e = e.replace(we, be)),
                    function (t) {
                      return (
                        (t.textContent || t.innerText || T(t)).indexOf(e) > -1
                      );
                    }
                  );
                }),
                lang: n(function (e) {
                  return (
                    pe.test(e || '') || t.error('unsupported lang: ' + e),
                    (e = e.replace(we, be).toLowerCase()),
                    function (t) {
                      var i;
                      do
                        if (
                          (i = H
                            ? t.lang
                            : t.getAttribute('xml:lang') ||
                              t.getAttribute('lang'))
                        )
                          return (
                            (i = i.toLowerCase()),
                            i === e || 0 === i.indexOf(e + '-')
                          );
                      while ((t = t.parentNode) && 1 === t.nodeType);
                      return !1;
                    }
                  );
                }),
                target: function (t) {
                  var i = e.location && e.location.hash;
                  return i && i.slice(1) === t.id;
                },
                root: function (e) {
                  return e === N;
                },
                focus: function (e) {
                  return (
                    e === A.activeElement &&
                    (!A.hasFocus || A.hasFocus()) &&
                    !!(e.type || e.href || ~e.tabIndex)
                  );
                },
                enabled: c(!1),
                disabled: c(!0),
                checked: function (e) {
                  var t = e.nodeName.toLowerCase();
                  return (
                    ('input' === t && !!e.checked) ||
                    ('option' === t && !!e.selected)
                  );
                },
                selected: function (e) {
                  return (
                    e.parentNode && e.parentNode.selectedIndex,
                    e.selected === !0
                  );
                },
                empty: function (e) {
                  for (e = e.firstChild; e; e = e.nextSibling)
                    if (e.nodeType < 6) return !1;
                  return !0;
                },
                parent: function (e) {
                  return !x.pseudos.empty(e);
                },
                header: function (e) {
                  return ge.test(e.nodeName);
                },
                input: function (e) {
                  return he.test(e.nodeName);
                },
                button: function (e) {
                  var t = e.nodeName.toLowerCase();
                  return (
                    ('input' === t && 'button' === e.type) || 'button' === t
                  );
                },
                text: function (e) {
                  var t;
                  return (
                    'input' === e.nodeName.toLowerCase() &&
                    'text' === e.type &&
                    (null == (t = e.getAttribute('type')) ||
                      'text' === t.toLowerCase())
                  );
                },
                first: d(function () {
                  return [0];
                }),
                last: d(function (e, t) {
                  return [t - 1];
                }),
                eq: d(function (e, t, i) {
                  return [i < 0 ? i + t : i];
                }),
                even: d(function (e, t) {
                  for (var i = 0; i < t; i += 2) e.push(i);
                  return e;
                }),
                odd: d(function (e, t) {
                  for (var i = 1; i < t; i += 2) e.push(i);
                  return e;
                }),
                lt: d(function (e, t, i) {
                  for (var n = i < 0 ? i + t : i; --n >= 0; ) e.push(n);
                  return e;
                }),
                gt: d(function (e, t, i) {
                  for (var n = i < 0 ? i + t : i; ++n < t; ) e.push(n);
                  return e;
                }),
              },
            }),
          (x.pseudos.nth = x.pseudos.eq);
        for (k in {
          radio: !0,
          checkbox: !0,
          file: !0,
          password: !0,
          image: !0,
        })
          x.pseudos[k] = s(k);
        for (k in { submit: !0, reset: !0 }) x.pseudos[k] = l(k);
        return (
          (p.prototype = x.filters = x.pseudos),
          (x.setFilters = new p()),
          (D = t.tokenize =
            function (e, i) {
              var n,
                o,
                r,
                a,
                s,
                l,
                c,
                d = Y[e + ' '];
              if (d) return i ? 0 : d.slice(0);
              for (s = e, l = [], c = x.preFilter; s; ) {
                (n && !(o = le.exec(s))) ||
                  (o && (s = s.slice(o[0].length) || s), l.push((r = []))),
                  (n = !1),
                  (o = ce.exec(s)) &&
                    ((n = o.shift()),
                    r.push({ value: n, type: o[0].replace(se, ' ') }),
                    (s = s.slice(n.length)));
                for (a in x.filter)
                  !(o = fe[a].exec(s)) ||
                    (c[a] && !(o = c[a](o))) ||
                    ((n = o.shift()),
                    r.push({ value: n, type: a, matches: o }),
                    (s = s.slice(n.length)));
                if (!n) break;
              }
              return i ? s.length : s ? t.error(e) : Y(e, l).slice(0);
            }),
          (I = t.compile =
            function (e, t) {
              var i,
                n = [],
                o = [],
                r = U[e + ' '];
              if (!r) {
                for (t || (t = D(e)), i = t.length; i--; )
                  (r = w(t[i])), r[q] ? n.push(r) : o.push(r);
                (r = U(e, b(o, n))), (r.selector = e);
              }
              return r;
            }),
          (_ = t.select =
            function (e, t, i, n) {
              var o,
                r,
                a,
                s,
                l,
                c = 'function' == typeof e && e,
                d = !n && D((e = c.selector || e));
              if (((i = i || []), 1 === d.length)) {
                if (
                  ((r = d[0] = d[0].slice(0)),
                  r.length > 2 &&
                    'ID' === (a = r[0]).type &&
                    9 === t.nodeType &&
                    H &&
                    x.relative[r[1].type])
                ) {
                  if (
                    ((t = (x.find.ID(a.matches[0].replace(we, be), t) ||
                      [])[0]),
                    !t)
                  )
                    return i;
                  c && (t = t.parentNode),
                    (e = e.slice(r.shift().value.length));
                }
                for (
                  o = fe.needsContext.test(e) ? 0 : r.length;
                  o-- && ((a = r[o]), !x.relative[(s = a.type)]);

                )
                  if (
                    (l = x.find[s]) &&
                    (n = l(
                      a.matches[0].replace(we, be),
                      (ye.test(r[0].type) && u(t.parentNode)) || t
                    ))
                  ) {
                    if ((r.splice(o, 1), (e = n.length && f(r)), !e))
                      return J.apply(i, n), i;
                    break;
                  }
              }
              return (
                (c || I(e, d))(
                  n,
                  t,
                  !H,
                  i,
                  !t || (ye.test(e) && u(t.parentNode)) || t
                ),
                i
              );
            }),
          (S.sortStable = q.split('').sort(Q).join('') === q),
          (S.detectDuplicates = !!E),
          j(),
          (S.sortDetached = o(function (e) {
            return 1 & e.compareDocumentPosition(A.createElement('fieldset'));
          })),
          o(function (e) {
            return (
              (e.innerHTML = "<a href='#'></a>"),
              '#' === e.firstChild.getAttribute('href')
            );
          }) ||
            r('type|href|height|width', function (e, t, i) {
              if (!i)
                return e.getAttribute(t, 'type' === t.toLowerCase() ? 1 : 2);
            }),
          (S.attributes &&
            o(function (e) {
              return (
                (e.innerHTML = '<input/>'),
                e.firstChild.setAttribute('value', ''),
                '' === e.firstChild.getAttribute('value')
              );
            })) ||
            r('value', function (e, t, i) {
              if (!i && 'input' === e.nodeName.toLowerCase())
                return e.defaultValue;
            }),
          o(function (e) {
            return null == e.getAttribute('disabled');
          }) ||
            r(te, function (e, t, i) {
              var n;
              if (!i)
                return e[t] === !0
                  ? t.toLowerCase()
                  : (n = e.getAttributeNode(t)) && n.specified
                  ? n.value
                  : null;
            }),
          t
        );
      })(e);
      (he.find = we),
        (he.expr = we.selectors),
        (he.expr[':'] = he.expr.pseudos),
        (he.uniqueSort = he.unique = we.uniqueSort),
        (he.text = we.getText),
        (he.isXMLDoc = we.isXML),
        (he.contains = we.contains),
        (he.escapeSelector = we.escape);
      var be = function (e, t, i) {
          for (var n = [], o = void 0 !== i; (e = e[t]) && 9 !== e.nodeType; )
            if (1 === e.nodeType) {
              if (o && he(e).is(i)) break;
              n.push(e);
            }
          return n;
        },
        ke = function (e, t) {
          for (var i = []; e; e = e.nextSibling)
            1 === e.nodeType && e !== t && i.push(e);
          return i;
        },
        Se = he.expr.match.needsContext,
        xe = /^<([a-z][^\/\0>:\x20\t\r\n\f]*)[\x20\t\r\n\f]*\/?>(?:<\/\1>|)$/i,
        Te = /^.[^:#\[\.,]*$/;
      (he.filter = function (e, t, i) {
        var n = t[0];
        return (
          i && (e = ':not(' + e + ')'),
          1 === t.length && 1 === n.nodeType
            ? he.find.matchesSelector(n, e)
              ? [n]
              : []
            : he.find.matches(
                e,
                he.grep(t, function (e) {
                  return 1 === e.nodeType;
                })
              )
        );
      }),
        he.fn.extend({
          find: function (e) {
            var t,
              i,
              n = this.length,
              o = this;
            if ('string' != typeof e)
              return this.pushStack(
                he(e).filter(function () {
                  for (t = 0; t < n; t++)
                    if (he.contains(o[t], this)) return !0;
                })
              );
            for (i = this.pushStack([]), t = 0; t < n; t++) he.find(e, o[t], i);
            return n > 1 ? he.uniqueSort(i) : i;
          },
          filter: function (e) {
            return this.pushStack(o(this, e || [], !1));
          },
          not: function (e) {
            return this.pushStack(o(this, e || [], !0));
          },
          is: function (e) {
            return !!o(
              this,
              'string' == typeof e && Se.test(e) ? he(e) : e || [],
              !1
            ).length;
          },
        });
      var Ce,
        De = /^(?:\s*(<[\w\W]+>)[^>]*|#([\w-]+))$/,
        Ie = (he.fn.init = function (e, t, i) {
          var n, o;
          if (!e) return this;
          if (((i = i || Ce), 'string' == typeof e)) {
            if (
              ((n =
                '<' === e[0] && '>' === e[e.length - 1] && e.length >= 3
                  ? [null, e, null]
                  : De.exec(e)),
              !n || (!n[1] && t))
            )
              return !t || t.jquery
                ? (t || i).find(e)
                : this.constructor(t).find(e);
            if (n[1]) {
              if (
                ((t = t instanceof he ? t[0] : t),
                he.merge(
                  this,
                  he.parseHTML(
                    n[1],
                    t && t.nodeType ? t.ownerDocument || t : te,
                    !0
                  )
                ),
                xe.test(n[1]) && he.isPlainObject(t))
              )
                for (n in t)
                  he.isFunction(this[n]) ? this[n](t[n]) : this.attr(n, t[n]);
              return this;
            }
            return (
              (o = te.getElementById(n[2])),
              o && ((this[0] = o), (this.length = 1)),
              this
            );
          }
          return e.nodeType
            ? ((this[0] = e), (this.length = 1), this)
            : he.isFunction(e)
            ? void 0 !== i.ready
              ? i.ready(e)
              : e(he)
            : he.makeArray(e, this);
        });
      (Ie.prototype = he.fn), (Ce = he(te));
      var _e = /^(?:parents|prev(?:Until|All))/,
        $e = { children: !0, contents: !0, next: !0, prev: !0 };
      he.fn.extend({
        has: function (e) {
          var t = he(e, this),
            i = t.length;
          return this.filter(function () {
            for (var e = 0; e < i; e++) if (he.contains(this, t[e])) return !0;
          });
        },
        closest: function (e, t) {
          var i,
            n = 0,
            o = this.length,
            r = [],
            a = 'string' != typeof e && he(e);
          if (!Se.test(e))
            for (; n < o; n++)
              for (i = this[n]; i && i !== t; i = i.parentNode)
                if (
                  i.nodeType < 11 &&
                  (a
                    ? a.index(i) > -1
                    : 1 === i.nodeType && he.find.matchesSelector(i, e))
                ) {
                  r.push(i);
                  break;
                }
          return this.pushStack(r.length > 1 ? he.uniqueSort(r) : r);
        },
        index: function (e) {
          return e
            ? 'string' == typeof e
              ? ae.call(he(e), this[0])
              : ae.call(this, e.jquery ? e[0] : e)
            : this[0] && this[0].parentNode
            ? this.first().prevAll().length
            : -1;
        },
        add: function (e, t) {
          return this.pushStack(he.uniqueSort(he.merge(this.get(), he(e, t))));
        },
        addBack: function (e) {
          return this.add(
            null == e ? this.prevObject : this.prevObject.filter(e)
          );
        },
      }),
        he.each(
          {
            parent: function (e) {
              var t = e.parentNode;
              return t && 11 !== t.nodeType ? t : null;
            },
            parents: function (e) {
              return be(e, 'parentNode');
            },
            parentsUntil: function (e, t, i) {
              return be(e, 'parentNode', i);
            },
            next: function (e) {
              return r(e, 'nextSibling');
            },
            prev: function (e) {
              return r(e, 'previousSibling');
            },
            nextAll: function (e) {
              return be(e, 'nextSibling');
            },
            prevAll: function (e) {
              return be(e, 'previousSibling');
            },
            nextUntil: function (e, t, i) {
              return be(e, 'nextSibling', i);
            },
            prevUntil: function (e, t, i) {
              return be(e, 'previousSibling', i);
            },
            siblings: function (e) {
              return ke((e.parentNode || {}).firstChild, e);
            },
            children: function (e) {
              return ke(e.firstChild);
            },
            contents: function (e) {
              return e.contentDocument || he.merge([], e.childNodes);
            },
          },
          function (e, t) {
            he.fn[e] = function (i, n) {
              var o = he.map(this, t, i);
              return (
                'Until' !== e.slice(-5) && (n = i),
                n && 'string' == typeof n && (o = he.filter(n, o)),
                this.length > 1 &&
                  ($e[e] || he.uniqueSort(o), _e.test(e) && o.reverse()),
                this.pushStack(o)
              );
            };
          }
        );
      var Me = /[^\x20\t\r\n\f]+/g;
      (he.Callbacks = function (e) {
        e = 'string' == typeof e ? a(e) : he.extend({}, e);
        var t,
          i,
          n,
          o,
          r = [],
          s = [],
          l = -1,
          c = function () {
            for (o = e.once, n = t = !0; s.length; l = -1)
              for (i = s.shift(); ++l < r.length; )
                r[l].apply(i[0], i[1]) === !1 &&
                  e.stopOnFalse &&
                  ((l = r.length), (i = !1));
            e.memory || (i = !1), (t = !1), o && (r = i ? [] : '');
          },
          d = {
            add: function () {
              return (
                r &&
                  (i && !t && ((l = r.length - 1), s.push(i)),
                  (function n(t) {
                    he.each(t, function (t, i) {
                      he.isFunction(i)
                        ? (e.unique && d.has(i)) || r.push(i)
                        : i && i.length && 'string' !== he.type(i) && n(i);
                    });
                  })(arguments),
                  i && !t && c()),
                this
              );
            },
            remove: function () {
              return (
                he.each(arguments, function (e, t) {
                  for (var i; (i = he.inArray(t, r, i)) > -1; )
                    r.splice(i, 1), i <= l && l--;
                }),
                this
              );
            },
            has: function (e) {
              return e ? he.inArray(e, r) > -1 : r.length > 0;
            },
            empty: function () {
              return r && (r = []), this;
            },
            disable: function () {
              return (o = s = []), (r = i = ''), this;
            },
            disabled: function () {
              return !r;
            },
            lock: function () {
              return (o = s = []), i || t || (r = i = ''), this;
            },
            locked: function () {
              return !!o;
            },
            fireWith: function (e, i) {
              return (
                o ||
                  ((i = i || []),
                  (i = [e, i.slice ? i.slice() : i]),
                  s.push(i),
                  t || c()),
                this
              );
            },
            fire: function () {
              return d.fireWith(this, arguments), this;
            },
            fired: function () {
              return !!n;
            },
          };
        return d;
      }),
        he.extend({
          Deferred: function (t) {
            var i = [
                [
                  'notify',
                  'progress',
                  he.Callbacks('memory'),
                  he.Callbacks('memory'),
                  2,
                ],
                [
                  'resolve',
                  'done',
                  he.Callbacks('once memory'),
                  he.Callbacks('once memory'),
                  0,
                  'resolved',
                ],
                [
                  'reject',
                  'fail',
                  he.Callbacks('once memory'),
                  he.Callbacks('once memory'),
                  1,
                  'rejected',
                ],
              ],
              n = 'pending',
              o = {
                state: function () {
                  return n;
                },
                always: function () {
                  return r.done(arguments).fail(arguments), this;
                },
                catch: function (e) {
                  return o.then(null, e);
                },
                pipe: function () {
                  var e = arguments;
                  return he
                    .Deferred(function (t) {
                      he.each(i, function (i, n) {
                        var o = he.isFunction(e[n[4]]) && e[n[4]];
                        r[n[1]](function () {
                          var e = o && o.apply(this, arguments);
                          e && he.isFunction(e.promise)
                            ? e
                                .promise()
                                .progress(t.notify)
                                .done(t.resolve)
                                .fail(t.reject)
                            : t[n[0] + 'With'](this, o ? [e] : arguments);
                        });
                      }),
                        (e = null);
                    })
                    .promise();
                },
                then: function (t, n, o) {
                  function r(t, i, n, o) {
                    return function () {
                      var c = this,
                        d = arguments,
                        u = function () {
                          var e, u;
                          if (!(t < a)) {
                            if (((e = n.apply(c, d)), e === i.promise()))
                              throw new TypeError('Thenable self-resolution');
                            (u =
                              e &&
                              ('object' == typeof e ||
                                'function' == typeof e) &&
                              e.then),
                              he.isFunction(u)
                                ? o
                                  ? u.call(e, r(a, i, s, o), r(a, i, l, o))
                                  : (a++,
                                    u.call(
                                      e,
                                      r(a, i, s, o),
                                      r(a, i, l, o),
                                      r(a, i, s, i.notifyWith)
                                    ))
                                : (n !== s && ((c = void 0), (d = [e])),
                                  (o || i.resolveWith)(c, d));
                          }
                        },
                        p = o
                          ? u
                          : function () {
                              try {
                                u();
                              } catch (e) {
                                he.Deferred.exceptionHook &&
                                  he.Deferred.exceptionHook(e, p.stackTrace),
                                  t + 1 >= a &&
                                    (n !== l && ((c = void 0), (d = [e])),
                                    i.rejectWith(c, d));
                              }
                            };
                      t
                        ? p()
                        : (he.Deferred.getStackHook &&
                            (p.stackTrace = he.Deferred.getStackHook()),
                          e.setTimeout(p));
                    };
                  }
                  var a = 0;
                  return he
                    .Deferred(function (e) {
                      i[0][3].add(
                        r(0, e, he.isFunction(o) ? o : s, e.notifyWith)
                      ),
                        i[1][3].add(r(0, e, he.isFunction(t) ? t : s)),
                        i[2][3].add(r(0, e, he.isFunction(n) ? n : l));
                    })
                    .promise();
                },
                promise: function (e) {
                  return null != e ? he.extend(e, o) : o;
                },
              },
              r = {};
            return (
              he.each(i, function (e, t) {
                var a = t[2],
                  s = t[5];
                (o[t[1]] = a.add),
                  s &&
                    a.add(
                      function () {
                        n = s;
                      },
                      i[3 - e][2].disable,
                      i[0][2].lock
                    ),
                  a.add(t[3].fire),
                  (r[t[0]] = function () {
                    return (
                      r[t[0] + 'With'](this === r ? void 0 : this, arguments),
                      this
                    );
                  }),
                  (r[t[0] + 'With'] = a.fireWith);
              }),
              o.promise(r),
              t && t.call(r, r),
              r
            );
          },
          when: function (e) {
            var t = arguments.length,
              i = t,
              n = Array(i),
              o = ne.call(arguments),
              r = he.Deferred(),
              a = function (e) {
                return function (i) {
                  (n[e] = this),
                    (o[e] = arguments.length > 1 ? ne.call(arguments) : i),
                    --t || r.resolveWith(n, o);
                };
              };
            if (
              t <= 1 &&
              (c(e, r.done(a(i)).resolve, r.reject),
              'pending' === r.state() || he.isFunction(o[i] && o[i].then))
            )
              return r.then();
            for (; i--; ) c(o[i], a(i), r.reject);
            return r.promise();
          },
        });
      var Ee = /^(Eval|Internal|Range|Reference|Syntax|Type|URI)Error$/;
      (he.Deferred.exceptionHook = function (t, i) {
        e.console &&
          e.console.warn &&
          t &&
          Ee.test(t.name) &&
          e.console.warn('jQuery.Deferred exception: ' + t.message, t.stack, i);
      }),
        (he.readyException = function (t) {
          e.setTimeout(function () {
            throw t;
          });
        });
      var je = he.Deferred();
      (he.fn.ready = function (e) {
        return (
          je.then(e)['catch'](function (e) {
            he.readyException(e);
          }),
          this
        );
      }),
        he.extend({
          isReady: !1,
          readyWait: 1,
          holdReady: function (e) {
            e ? he.readyWait++ : he.ready(!0);
          },
          ready: function (e) {
            (e === !0 ? --he.readyWait : he.isReady) ||
              ((he.isReady = !0),
              (e !== !0 && --he.readyWait > 0) || je.resolveWith(te, [he]));
          },
        }),
        (he.ready.then = je.then),
        'complete' === te.readyState ||
        ('loading' !== te.readyState && !te.documentElement.doScroll)
          ? e.setTimeout(he.ready)
          : (te.addEventListener('DOMContentLoaded', d),
            e.addEventListener('load', d));
      var Ae = function (e, t, i, n, o, r, a) {
          var s = 0,
            l = e.length,
            c = null == i;
          if ('object' === he.type(i)) {
            o = !0;
            for (s in i) Ae(e, t, s, i[s], !0, r, a);
          } else if (
            void 0 !== n &&
            ((o = !0),
            he.isFunction(n) || (a = !0),
            c &&
              (a
                ? (t.call(e, n), (t = null))
                : ((c = t),
                  (t = function (e, t, i) {
                    return c.call(he(e), i);
                  }))),
            t)
          )
            for (; s < l; s++) t(e[s], i, a ? n : n.call(e[s], s, t(e[s], i)));
          return o ? e : c ? t.call(e) : l ? t(e[0], i) : r;
        },
        Ne = function (e) {
          return 1 === e.nodeType || 9 === e.nodeType || !+e.nodeType;
        };
      (u.uid = 1),
        (u.prototype = {
          cache: function (e) {
            var t = e[this.expando];
            return (
              t ||
                ((t = {}),
                Ne(e) &&
                  (e.nodeType
                    ? (e[this.expando] = t)
                    : Object.defineProperty(e, this.expando, {
                        value: t,
                        configurable: !0,
                      }))),
              t
            );
          },
          set: function (e, t, i) {
            var n,
              o = this.cache(e);
            if ('string' == typeof t) o[he.camelCase(t)] = i;
            else for (n in t) o[he.camelCase(n)] = t[n];
            return o;
          },
          get: function (e, t) {
            return void 0 === t
              ? this.cache(e)
              : e[this.expando] && e[this.expando][he.camelCase(t)];
          },
          access: function (e, t, i) {
            return void 0 === t || (t && 'string' == typeof t && void 0 === i)
              ? this.get(e, t)
              : (this.set(e, t, i), void 0 !== i ? i : t);
          },
          remove: function (e, t) {
            var i,
              n = e[this.expando];
            if (void 0 !== n) {
              if (void 0 !== t) {
                he.isArray(t)
                  ? (t = t.map(he.camelCase))
                  : ((t = he.camelCase(t)),
                    (t = t in n ? [t] : t.match(Me) || [])),
                  (i = t.length);
                for (; i--; ) delete n[t[i]];
              }
              (void 0 === t || he.isEmptyObject(n)) &&
                (e.nodeType
                  ? (e[this.expando] = void 0)
                  : delete e[this.expando]);
            }
          },
          hasData: function (e) {
            var t = e[this.expando];
            return void 0 !== t && !he.isEmptyObject(t);
          },
        });
      var He = new u(),
        Oe = new u(),
        Pe = /^(?:\{[\w\W]*\}|\[[\w\W]*\])$/,
        Le = /[A-Z]/g;
      he.extend({
        hasData: function (e) {
          return Oe.hasData(e) || He.hasData(e);
        },
        data: function (e, t, i) {
          return Oe.access(e, t, i);
        },
        removeData: function (e, t) {
          Oe.remove(e, t);
        },
        _data: function (e, t, i) {
          return He.access(e, t, i);
        },
        _removeData: function (e, t) {
          He.remove(e, t);
        },
      }),
        he.fn.extend({
          data: function (e, t) {
            var i,
              n,
              o,
              r = this[0],
              a = r && r.attributes;
            if (void 0 === e) {
              if (
                this.length &&
                ((o = Oe.get(r)),
                1 === r.nodeType && !He.get(r, 'hasDataAttrs'))
              ) {
                for (i = a.length; i--; )
                  a[i] &&
                    ((n = a[i].name),
                    0 === n.indexOf('data-') &&
                      ((n = he.camelCase(n.slice(5))), f(r, n, o[n])));
                He.set(r, 'hasDataAttrs', !0);
              }
              return o;
            }
            return 'object' == typeof e
              ? this.each(function () {
                  Oe.set(this, e);
                })
              : Ae(
                  this,
                  function (t) {
                    var i;
                    if (r && void 0 === t) {
                      if (((i = Oe.get(r, e)), void 0 !== i)) return i;
                      if (((i = f(r, e)), void 0 !== i)) return i;
                    } else
                      this.each(function () {
                        Oe.set(this, e, t);
                      });
                  },
                  null,
                  t,
                  arguments.length > 1,
                  null,
                  !0
                );
          },
          removeData: function (e) {
            return this.each(function () {
              Oe.remove(this, e);
            });
          },
        }),
        he.extend({
          queue: function (e, t, i) {
            var n;
            if (e)
              return (
                (t = (t || 'fx') + 'queue'),
                (n = He.get(e, t)),
                i &&
                  (!n || he.isArray(i)
                    ? (n = He.access(e, t, he.makeArray(i)))
                    : n.push(i)),
                n || []
              );
          },
          dequeue: function (e, t) {
            t = t || 'fx';
            var i = he.queue(e, t),
              n = i.length,
              o = i.shift(),
              r = he._queueHooks(e, t),
              a = function () {
                he.dequeue(e, t);
              };
            'inprogress' === o && ((o = i.shift()), n--),
              o &&
                ('fx' === t && i.unshift('inprogress'),
                delete r.stop,
                o.call(e, a, r)),
              !n && r && r.empty.fire();
          },
          _queueHooks: function (e, t) {
            var i = t + 'queueHooks';
            return (
              He.get(e, i) ||
              He.access(e, i, {
                empty: he.Callbacks('once memory').add(function () {
                  He.remove(e, [t + 'queue', i]);
                }),
              })
            );
          },
        }),
        he.fn.extend({
          queue: function (e, t) {
            var i = 2;
            return (
              'string' != typeof e && ((t = e), (e = 'fx'), i--),
              arguments.length < i
                ? he.queue(this[0], e)
                : void 0 === t
                ? this
                : this.each(function () {
                    var i = he.queue(this, e, t);
                    he._queueHooks(this, e),
                      'fx' === e &&
                        'inprogress' !== i[0] &&
                        he.dequeue(this, e);
                  })
            );
          },
          dequeue: function (e) {
            return this.each(function () {
              he.dequeue(this, e);
            });
          },
          clearQueue: function (e) {
            return this.queue(e || 'fx', []);
          },
          promise: function (e, t) {
            var i,
              n = 1,
              o = he.Deferred(),
              r = this,
              a = this.length,
              s = function () {
                --n || o.resolveWith(r, [r]);
              };
            for (
              'string' != typeof e && ((t = e), (e = void 0)), e = e || 'fx';
              a--;

            )
              (i = He.get(r[a], e + 'queueHooks')),
                i && i.empty && (n++, i.empty.add(s));
            return s(), o.promise(t);
          },
        });
      var Fe = /[+-]?(?:\d*\.|)\d+(?:[eE][+-]?\d+|)/.source,
        qe = new RegExp('^(?:([+-])=|)(' + Fe + ')([a-z%]*)$', 'i'),
        Be = ['Top', 'Right', 'Bottom', 'Left'],
        We = function (e, t) {
          return (
            (e = t || e),
            'none' === e.style.display ||
              ('' === e.style.display &&
                he.contains(e.ownerDocument, e) &&
                'none' === he.css(e, 'display'))
          );
        },
        Re = function (e, t, i, n) {
          var o,
            r,
            a = {};
          for (r in t) (a[r] = e.style[r]), (e.style[r] = t[r]);
          o = i.apply(e, n || []);
          for (r in t) e.style[r] = a[r];
          return o;
        },
        ze = {};
      he.fn.extend({
        show: function () {
          return m(this, !0);
        },
        hide: function () {
          return m(this);
        },
        toggle: function (e) {
          return 'boolean' == typeof e
            ? e
              ? this.show()
              : this.hide()
            : this.each(function () {
                We(this) ? he(this).show() : he(this).hide();
              });
        },
      });
      var Ye = /^(?:checkbox|radio)$/i,
        Ue = /<([a-z][^\/\0>\x20\t\r\n\f]+)/i,
        Qe = /^$|\/(?:java|ecma)script/i,
        Ke = {
          option: [1, "<select multiple='multiple'>", '</select>'],
          thead: [1, '<table>', '</table>'],
          col: [2, '<table><colgroup>', '</colgroup></table>'],
          tr: [2, '<table><tbody>', '</tbody></table>'],
          td: [3, '<table><tbody><tr>', '</tr></tbody></table>'],
          _default: [0, '', ''],
        };
      (Ke.optgroup = Ke.option),
        (Ke.tbody = Ke.tfoot = Ke.colgroup = Ke.caption = Ke.thead),
        (Ke.th = Ke.td);
      var Xe = /<|&#?\w+;/;
      !(function () {
        var e = te.createDocumentFragment(),
          t = e.appendChild(te.createElement('div')),
          i = te.createElement('input');
        i.setAttribute('type', 'radio'),
          i.setAttribute('checked', 'checked'),
          i.setAttribute('name', 't'),
          t.appendChild(i),
          (pe.checkClone = t.cloneNode(!0).cloneNode(!0).lastChild.checked),
          (t.innerHTML = '<textarea>x</textarea>'),
          (pe.noCloneChecked = !!t.cloneNode(!0).lastChild.defaultValue);
      })();
      var Ve = te.documentElement,
        Ge = /^key/,
        Je = /^(?:mouse|pointer|contextmenu|drag|drop)|click/,
        Ze = /^([^.]*)(?:\.(.+)|)/;
      (he.event = {
        global: {},
        add: function (e, t, i, n, o) {
          var r,
            a,
            s,
            l,
            c,
            d,
            u,
            p,
            f,
            h,
            g,
            m = He.get(e);
          if (m)
            for (
              i.handler && ((r = i), (i = r.handler), (o = r.selector)),
                o && he.find.matchesSelector(Ve, o),
                i.guid || (i.guid = he.guid++),
                (l = m.events) || (l = m.events = {}),
                (a = m.handle) ||
                  (a = m.handle =
                    function (t) {
                      return 'undefined' != typeof he &&
                        he.event.triggered !== t.type
                        ? he.event.dispatch.apply(e, arguments)
                        : void 0;
                    }),
                t = (t || '').match(Me) || [''],
                c = t.length;
              c--;

            )
              (s = Ze.exec(t[c]) || []),
                (f = g = s[1]),
                (h = (s[2] || '').split('.').sort()),
                f &&
                  ((u = he.event.special[f] || {}),
                  (f = (o ? u.delegateType : u.bindType) || f),
                  (u = he.event.special[f] || {}),
                  (d = he.extend(
                    {
                      type: f,
                      origType: g,
                      data: n,
                      handler: i,
                      guid: i.guid,
                      selector: o,
                      needsContext: o && he.expr.match.needsContext.test(o),
                      namespace: h.join('.'),
                    },
                    r
                  )),
                  (p = l[f]) ||
                    ((p = l[f] = []),
                    (p.delegateCount = 0),
                    (u.setup && u.setup.call(e, n, h, a) !== !1) ||
                      (e.addEventListener && e.addEventListener(f, a))),
                  u.add &&
                    (u.add.call(e, d),
                    d.handler.guid || (d.handler.guid = i.guid)),
                  o ? p.splice(p.delegateCount++, 0, d) : p.push(d),
                  (he.event.global[f] = !0));
        },
        remove: function (e, t, i, n, o) {
          var r,
            a,
            s,
            l,
            c,
            d,
            u,
            p,
            f,
            h,
            g,
            m = He.hasData(e) && He.get(e);
          if (m && (l = m.events)) {
            for (t = (t || '').match(Me) || [''], c = t.length; c--; )
              if (
                ((s = Ze.exec(t[c]) || []),
                (f = g = s[1]),
                (h = (s[2] || '').split('.').sort()),
                f)
              ) {
                for (
                  u = he.event.special[f] || {},
                    f = (n ? u.delegateType : u.bindType) || f,
                    p = l[f] || [],
                    s =
                      s[2] &&
                      new RegExp(
                        '(^|\\.)' + h.join('\\.(?:.*\\.|)') + '(\\.|$)'
                      ),
                    a = r = p.length;
                  r--;

                )
                  (d = p[r]),
                    (!o && g !== d.origType) ||
                      (i && i.guid !== d.guid) ||
                      (s && !s.test(d.namespace)) ||
                      (n && n !== d.selector && ('**' !== n || !d.selector)) ||
                      (p.splice(r, 1),
                      d.selector && p.delegateCount--,
                      u.remove && u.remove.call(e, d));
                a &&
                  !p.length &&
                  ((u.teardown && u.teardown.call(e, h, m.handle) !== !1) ||
                    he.removeEvent(e, f, m.handle),
                  delete l[f]);
              } else for (f in l) he.event.remove(e, f + t[c], i, n, !0);
            he.isEmptyObject(l) && He.remove(e, 'handle events');
          }
        },
        dispatch: function (e) {
          var t,
            i,
            n,
            o,
            r,
            a,
            s = he.event.fix(e),
            l = new Array(arguments.length),
            c = (He.get(this, 'events') || {})[s.type] || [],
            d = he.event.special[s.type] || {};
          for (l[0] = s, t = 1; t < arguments.length; t++) l[t] = arguments[t];
          if (
            ((s.delegateTarget = this),
            !d.preDispatch || d.preDispatch.call(this, s) !== !1)
          ) {
            for (
              a = he.event.handlers.call(this, s, c), t = 0;
              (o = a[t++]) && !s.isPropagationStopped();

            )
              for (
                s.currentTarget = o.elem, i = 0;
                (r = o.handlers[i++]) && !s.isImmediatePropagationStopped();

              )
                (s.rnamespace && !s.rnamespace.test(r.namespace)) ||
                  ((s.handleObj = r),
                  (s.data = r.data),
                  (n = (
                    (he.event.special[r.origType] || {}).handle || r.handler
                  ).apply(o.elem, l)),
                  void 0 !== n &&
                    (s.result = n) === !1 &&
                    (s.preventDefault(), s.stopPropagation()));
            return d.postDispatch && d.postDispatch.call(this, s), s.result;
          }
        },
        handlers: function (e, t) {
          var i,
            n,
            o,
            r,
            a,
            s = [],
            l = t.delegateCount,
            c = e.target;
          if (l && c.nodeType && !('click' === e.type && e.button >= 1))
            for (; c !== this; c = c.parentNode || this)
              if (
                1 === c.nodeType &&
                ('click' !== e.type || c.disabled !== !0)
              ) {
                for (r = [], a = {}, i = 0; i < l; i++)
                  (n = t[i]),
                    (o = n.selector + ' '),
                    void 0 === a[o] &&
                      (a[o] = n.needsContext
                        ? he(o, this).index(c) > -1
                        : he.find(o, this, null, [c]).length),
                    a[o] && r.push(n);
                r.length && s.push({ elem: c, handlers: r });
              }
          return (
            (c = this),
            l < t.length && s.push({ elem: c, handlers: t.slice(l) }),
            s
          );
        },
        addProp: function (e, t) {
          Object.defineProperty(he.Event.prototype, e, {
            enumerable: !0,
            configurable: !0,
            get: he.isFunction(t)
              ? function () {
                  if (this.originalEvent) return t(this.originalEvent);
                }
              : function () {
                  if (this.originalEvent) return this.originalEvent[e];
                },
            set: function (t) {
              Object.defineProperty(this, e, {
                enumerable: !0,
                configurable: !0,
                writable: !0,
                value: t,
              });
            },
          });
        },
        fix: function (e) {
          return e[he.expando] ? e : new he.Event(e);
        },
        special: {
          load: { noBubble: !0 },
          focus: {
            trigger: function () {
              if (this !== S() && this.focus) return this.focus(), !1;
            },
            delegateType: 'focusin',
          },
          blur: {
            trigger: function () {
              if (this === S() && this.blur) return this.blur(), !1;
            },
            delegateType: 'focusout',
          },
          click: {
            trigger: function () {
              if (
                'checkbox' === this.type &&
                this.click &&
                he.nodeName(this, 'input')
              )
                return this.click(), !1;
            },
            _default: function (e) {
              return he.nodeName(e.target, 'a');
            },
          },
          beforeunload: {
            postDispatch: function (e) {
              void 0 !== e.result &&
                e.originalEvent &&
                (e.originalEvent.returnValue = e.result);
            },
          },
        },
      }),
        (he.removeEvent = function (e, t, i) {
          e.removeEventListener && e.removeEventListener(t, i);
        }),
        (he.Event = function (e, t) {
          return this instanceof he.Event
            ? (e && e.type
                ? ((this.originalEvent = e),
                  (this.type = e.type),
                  (this.isDefaultPrevented =
                    e.defaultPrevented ||
                    (void 0 === e.defaultPrevented && e.returnValue === !1)
                      ? b
                      : k),
                  (this.target =
                    e.target && 3 === e.target.nodeType
                      ? e.target.parentNode
                      : e.target),
                  (this.currentTarget = e.currentTarget),
                  (this.relatedTarget = e.relatedTarget))
                : (this.type = e),
              t && he.extend(this, t),
              (this.timeStamp = (e && e.timeStamp) || he.now()),
              void (this[he.expando] = !0))
            : new he.Event(e, t);
        }),
        (he.Event.prototype = {
          constructor: he.Event,
          isDefaultPrevented: k,
          isPropagationStopped: k,
          isImmediatePropagationStopped: k,
          isSimulated: !1,
          preventDefault: function () {
            var e = this.originalEvent;
            (this.isDefaultPrevented = b),
              e && !this.isSimulated && e.preventDefault();
          },
          stopPropagation: function () {
            var e = this.originalEvent;
            (this.isPropagationStopped = b),
              e && !this.isSimulated && e.stopPropagation();
          },
          stopImmediatePropagation: function () {
            var e = this.originalEvent;
            (this.isImmediatePropagationStopped = b),
              e && !this.isSimulated && e.stopImmediatePropagation(),
              this.stopPropagation();
          },
        }),
        he.each(
          {
            altKey: !0,
            bubbles: !0,
            cancelable: !0,
            changedTouches: !0,
            ctrlKey: !0,
            detail: !0,
            eventPhase: !0,
            metaKey: !0,
            pageX: !0,
            pageY: !0,
            shiftKey: !0,
            view: !0,
            char: !0,
            charCode: !0,
            key: !0,
            keyCode: !0,
            button: !0,
            buttons: !0,
            clientX: !0,
            clientY: !0,
            offsetX: !0,
            offsetY: !0,
            pointerId: !0,
            pointerType: !0,
            screenX: !0,
            screenY: !0,
            targetTouches: !0,
            toElement: !0,
            touches: !0,
            which: function (e) {
              var t = e.button;
              return null == e.which && Ge.test(e.type)
                ? null != e.charCode
                  ? e.charCode
                  : e.keyCode
                : !e.which && void 0 !== t && Je.test(e.type)
                ? 1 & t
                  ? 1
                  : 2 & t
                  ? 3
                  : 4 & t
                  ? 2
                  : 0
                : e.which;
            },
          },
          he.event.addProp
        ),
        he.each(
          {
            mouseenter: 'mouseover',
            mouseleave: 'mouseout',
            pointerenter: 'pointerover',
            pointerleave: 'pointerout',
          },
          function (e, t) {
            he.event.special[e] = {
              delegateType: t,
              bindType: t,
              handle: function (e) {
                var i,
                  n = this,
                  o = e.relatedTarget,
                  r = e.handleObj;
                return (
                  (o && (o === n || he.contains(n, o))) ||
                    ((e.type = r.origType),
                    (i = r.handler.apply(this, arguments)),
                    (e.type = t)),
                  i
                );
              },
            };
          }
        ),
        he.fn.extend({
          on: function (e, t, i, n) {
            return x(this, e, t, i, n);
          },
          one: function (e, t, i, n) {
            return x(this, e, t, i, n, 1);
          },
          off: function (e, t, i) {
            var n, o;
            if (e && e.preventDefault && e.handleObj)
              return (
                (n = e.handleObj),
                he(e.delegateTarget).off(
                  n.namespace ? n.origType + '.' + n.namespace : n.origType,
                  n.selector,
                  n.handler
                ),
                this
              );
            if ('object' == typeof e) {
              for (o in e) this.off(o, t, e[o]);
              return this;
            }
            return (
              (t !== !1 && 'function' != typeof t) || ((i = t), (t = void 0)),
              i === !1 && (i = k),
              this.each(function () {
                he.event.remove(this, e, i, t);
              })
            );
          },
        });
      var et =
          /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi,
        tt = /<script|<style|<link/i,
        it = /checked\s*(?:[^=]|=\s*.checked.)/i,
        nt = /^true\/(.*)/,
        ot = /^\s*<!(?:\[CDATA\[|--)|(?:\]\]|--)>\s*$/g;
      he.extend({
        htmlPrefilter: function (e) {
          return e.replace(et, '<$1></$2>');
        },
        clone: function (e, t, i) {
          var n,
            o,
            r,
            a,
            s = e.cloneNode(!0),
            l = he.contains(e.ownerDocument, e);
          if (
            !(
              pe.noCloneChecked ||
              (1 !== e.nodeType && 11 !== e.nodeType) ||
              he.isXMLDoc(e)
            )
          )
            for (a = v(s), r = v(e), n = 0, o = r.length; n < o; n++)
              _(r[n], a[n]);
          if (t)
            if (i)
              for (
                r = r || v(e), a = a || v(s), n = 0, o = r.length;
                n < o;
                n++
              )
                I(r[n], a[n]);
            else I(e, s);
          return (
            (a = v(s, 'script')), a.length > 0 && y(a, !l && v(e, 'script')), s
          );
        },
        cleanData: function (e) {
          for (
            var t, i, n, o = he.event.special, r = 0;
            void 0 !== (i = e[r]);
            r++
          )
            if (Ne(i)) {
              if ((t = i[He.expando])) {
                if (t.events)
                  for (n in t.events)
                    o[n]
                      ? he.event.remove(i, n)
                      : he.removeEvent(i, n, t.handle);
                i[He.expando] = void 0;
              }
              i[Oe.expando] && (i[Oe.expando] = void 0);
            }
        },
      }),
        he.fn.extend({
          detach: function (e) {
            return M(this, e, !0);
          },
          remove: function (e) {
            return M(this, e);
          },
          text: function (e) {
            return Ae(
              this,
              function (e) {
                return void 0 === e
                  ? he.text(this)
                  : this.empty().each(function () {
                      (1 !== this.nodeType &&
                        11 !== this.nodeType &&
                        9 !== this.nodeType) ||
                        (this.textContent = e);
                    });
              },
              null,
              e,
              arguments.length
            );
          },
          append: function () {
            return $(this, arguments, function (e) {
              if (
                1 === this.nodeType ||
                11 === this.nodeType ||
                9 === this.nodeType
              ) {
                var t = T(this, e);
                t.appendChild(e);
              }
            });
          },
          prepend: function () {
            return $(this, arguments, function (e) {
              if (
                1 === this.nodeType ||
                11 === this.nodeType ||
                9 === this.nodeType
              ) {
                var t = T(this, e);
                t.insertBefore(e, t.firstChild);
              }
            });
          },
          before: function () {
            return $(this, arguments, function (e) {
              this.parentNode && this.parentNode.insertBefore(e, this);
            });
          },
          after: function () {
            return $(this, arguments, function (e) {
              this.parentNode &&
                this.parentNode.insertBefore(e, this.nextSibling);
            });
          },
          empty: function () {
            for (var e, t = 0; null != (e = this[t]); t++)
              1 === e.nodeType &&
                (he.cleanData(v(e, !1)), (e.textContent = ''));
            return this;
          },
          clone: function (e, t) {
            return (
              (e = null != e && e),
              (t = null == t ? e : t),
              this.map(function () {
                return he.clone(this, e, t);
              })
            );
          },
          html: function (e) {
            return Ae(
              this,
              function (e) {
                var t = this[0] || {},
                  i = 0,
                  n = this.length;
                if (void 0 === e && 1 === t.nodeType) return t.innerHTML;
                if (
                  'string' == typeof e &&
                  !tt.test(e) &&
                  !Ke[(Ue.exec(e) || ['', ''])[1].toLowerCase()]
                ) {
                  e = he.htmlPrefilter(e);
                  try {
                    for (; i < n; i++)
                      (t = this[i] || {}),
                        1 === t.nodeType &&
                          (he.cleanData(v(t, !1)), (t.innerHTML = e));
                    t = 0;
                  } catch (o) {}
                }
                t && this.empty().append(e);
              },
              null,
              e,
              arguments.length
            );
          },
          replaceWith: function () {
            var e = [];
            return $(
              this,
              arguments,
              function (t) {
                var i = this.parentNode;
                he.inArray(this, e) < 0 &&
                  (he.cleanData(v(this)), i && i.replaceChild(t, this));
              },
              e
            );
          },
        }),
        he.each(
          {
            appendTo: 'append',
            prependTo: 'prepend',
            insertBefore: 'before',
            insertAfter: 'after',
            replaceAll: 'replaceWith',
          },
          function (e, t) {
            he.fn[e] = function (e) {
              for (
                var i, n = [], o = he(e), r = o.length - 1, a = 0;
                a <= r;
                a++
              )
                (i = a === r ? this : this.clone(!0)),
                  he(o[a])[t](i),
                  re.apply(n, i.get());
              return this.pushStack(n);
            };
          }
        );
      var rt = /^margin/,
        at = new RegExp('^(' + Fe + ')(?!px)[a-z%]+$', 'i'),
        st = function (t) {
          var i = t.ownerDocument.defaultView;
          return (i && i.opener) || (i = e), i.getComputedStyle(t);
        };
      !(function () {
        function t() {
          if (s) {
            (s.style.cssText =
              'box-sizing:border-box;position:relative;display:block;margin:auto;border:1px;padding:1px;top:1%;width:50%'),
              (s.innerHTML = ''),
              Ve.appendChild(a);
            var t = e.getComputedStyle(s);
            (i = '1%' !== t.top),
              (r = '2px' === t.marginLeft),
              (n = '4px' === t.width),
              (s.style.marginRight = '50%'),
              (o = '4px' === t.marginRight),
              Ve.removeChild(a),
              (s = null);
          }
        }
        var i,
          n,
          o,
          r,
          a = te.createElement('div'),
          s = te.createElement('div');
        s.style &&
          ((s.style.backgroundClip = 'content-box'),
          (s.cloneNode(!0).style.backgroundClip = ''),
          (pe.clearCloneStyle = 'content-box' === s.style.backgroundClip),
          (a.style.cssText =
            'border:0;width:8px;height:0;top:0;left:-9999px;padding:0;margin-top:1px;position:absolute'),
          a.appendChild(s),
          he.extend(pe, {
            pixelPosition: function () {
              return t(), i;
            },
            boxSizingReliable: function () {
              return t(), n;
            },
            pixelMarginRight: function () {
              return t(), o;
            },
            reliableMarginLeft: function () {
              return t(), r;
            },
          }));
      })();
      var lt = /^(none|table(?!-c[ea]).+)/,
        ct = { position: 'absolute', visibility: 'hidden', display: 'block' },
        dt = { letterSpacing: '0', fontWeight: '400' },
        ut = ['Webkit', 'Moz', 'ms'],
        pt = te.createElement('div').style;
      he.extend({
        cssHooks: {
          opacity: {
            get: function (e, t) {
              if (t) {
                var i = E(e, 'opacity');
                return '' === i ? '1' : i;
              }
            },
          },
        },
        cssNumber: {
          animationIterationCount: !0,
          columnCount: !0,
          fillOpacity: !0,
          flexGrow: !0,
          flexShrink: !0,
          fontWeight: !0,
          lineHeight: !0,
          opacity: !0,
          order: !0,
          orphans: !0,
          widows: !0,
          zIndex: !0,
          zoom: !0,
        },
        cssProps: { float: 'cssFloat' },
        style: function (e, t, i, n) {
          if (e && 3 !== e.nodeType && 8 !== e.nodeType && e.style) {
            var o,
              r,
              a,
              s = he.camelCase(t),
              l = e.style;
            return (
              (t = he.cssProps[s] || (he.cssProps[s] = A(s) || s)),
              (a = he.cssHooks[t] || he.cssHooks[s]),
              void 0 === i
                ? a && 'get' in a && void 0 !== (o = a.get(e, !1, n))
                  ? o
                  : l[t]
                : ((r = typeof i),
                  'string' === r &&
                    (o = qe.exec(i)) &&
                    o[1] &&
                    ((i = h(e, t, o)), (r = 'number')),
                  void (
                    null != i &&
                    i === i &&
                    ('number' === r &&
                      (i += (o && o[3]) || (he.cssNumber[s] ? '' : 'px')),
                    pe.clearCloneStyle ||
                      '' !== i ||
                      0 !== t.indexOf('background') ||
                      (l[t] = 'inherit'),
                    (a && 'set' in a && void 0 === (i = a.set(e, i, n))) ||
                      (l[t] = i))
                  ))
            );
          }
        },
        css: function (e, t, i, n) {
          var o,
            r,
            a,
            s = he.camelCase(t);
          return (
            (t = he.cssProps[s] || (he.cssProps[s] = A(s) || s)),
            (a = he.cssHooks[t] || he.cssHooks[s]),
            a && 'get' in a && (o = a.get(e, !0, i)),
            void 0 === o && (o = E(e, t, n)),
            'normal' === o && t in dt && (o = dt[t]),
            '' === i || i
              ? ((r = parseFloat(o)), i === !0 || isFinite(r) ? r || 0 : o)
              : o
          );
        },
      }),
        he.each(['height', 'width'], function (e, t) {
          he.cssHooks[t] = {
            get: function (e, i, n) {
              if (i)
                return !lt.test(he.css(e, 'display')) ||
                  (e.getClientRects().length && e.getBoundingClientRect().width)
                  ? O(e, t, n)
                  : Re(e, ct, function () {
                      return O(e, t, n);
                    });
            },
            set: function (e, i, n) {
              var o,
                r = n && st(e),
                a =
                  n &&
                  H(e, t, n, 'border-box' === he.css(e, 'boxSizing', !1, r), r);
              return (
                a &&
                  (o = qe.exec(i)) &&
                  'px' !== (o[3] || 'px') &&
                  ((e.style[t] = i), (i = he.css(e, t))),
                N(e, i, a)
              );
            },
          };
        }),
        (he.cssHooks.marginLeft = j(pe.reliableMarginLeft, function (e, t) {
          if (t)
            return (
              (parseFloat(E(e, 'marginLeft')) ||
                e.getBoundingClientRect().left -
                  Re(e, { marginLeft: 0 }, function () {
                    return e.getBoundingClientRect().left;
                  })) + 'px'
            );
        })),
        he.each({ margin: '', padding: '', border: 'Width' }, function (e, t) {
          (he.cssHooks[e + t] = {
            expand: function (i) {
              for (
                var n = 0,
                  o = {},
                  r = 'string' == typeof i ? i.split(' ') : [i];
                n < 4;
                n++
              )
                o[e + Be[n] + t] = r[n] || r[n - 2] || r[0];
              return o;
            },
          }),
            rt.test(e) || (he.cssHooks[e + t].set = N);
        }),
        he.fn.extend({
          css: function (e, t) {
            return Ae(
              this,
              function (e, t, i) {
                var n,
                  o,
                  r = {},
                  a = 0;
                if (he.isArray(t)) {
                  for (n = st(e), o = t.length; a < o; a++)
                    r[t[a]] = he.css(e, t[a], !1, n);
                  return r;
                }
                return void 0 !== i ? he.style(e, t, i) : he.css(e, t);
              },
              e,
              t,
              arguments.length > 1
            );
          },
        }),
        (he.Tween = P),
        (P.prototype = {
          constructor: P,
          init: function (e, t, i, n, o, r) {
            (this.elem = e),
              (this.prop = i),
              (this.easing = o || he.easing._default),
              (this.options = t),
              (this.start = this.now = this.cur()),
              (this.end = n),
              (this.unit = r || (he.cssNumber[i] ? '' : 'px'));
          },
          cur: function () {
            var e = P.propHooks[this.prop];
            return e && e.get ? e.get(this) : P.propHooks._default.get(this);
          },
          run: function (e) {
            var t,
              i = P.propHooks[this.prop];
            return (
              this.options.duration
                ? (this.pos = t =
                    he.easing[this.easing](
                      e,
                      this.options.duration * e,
                      0,
                      1,
                      this.options.duration
                    ))
                : (this.pos = t = e),
              (this.now = (this.end - this.start) * t + this.start),
              this.options.step &&
                this.options.step.call(this.elem, this.now, this),
              i && i.set ? i.set(this) : P.propHooks._default.set(this),
              this
            );
          },
        }),
        (P.prototype.init.prototype = P.prototype),
        (P.propHooks = {
          _default: {
            get: function (e) {
              var t;
              return 1 !== e.elem.nodeType ||
                (null != e.elem[e.prop] && null == e.elem.style[e.prop])
                ? e.elem[e.prop]
                : ((t = he.css(e.elem, e.prop, '')), t && 'auto' !== t ? t : 0);
            },
            set: function (e) {
              he.fx.step[e.prop]
                ? he.fx.step[e.prop](e)
                : 1 !== e.elem.nodeType ||
                  (null == e.elem.style[he.cssProps[e.prop]] &&
                    !he.cssHooks[e.prop])
                ? (e.elem[e.prop] = e.now)
                : he.style(e.elem, e.prop, e.now + e.unit);
            },
          },
        }),
        (P.propHooks.scrollTop = P.propHooks.scrollLeft =
          {
            set: function (e) {
              e.elem.nodeType && e.elem.parentNode && (e.elem[e.prop] = e.now);
            },
          }),
        (he.easing = {
          linear: function (e) {
            return e;
          },
          swing: function (e) {
            return 0.5 - Math.cos(e * Math.PI) / 2;
          },
          _default: 'swing',
        }),
        (he.fx = P.prototype.init),
        (he.fx.step = {});
      var ft,
        ht,
        gt = /^(?:toggle|show|hide)$/,
        mt = /queueHooks$/;
      (he.Animation = he.extend(z, {
        tweeners: {
          '*': [
            function (e, t) {
              var i = this.createTween(e, t);
              return h(i.elem, e, qe.exec(t), i), i;
            },
          ],
        },
        tweener: function (e, t) {
          he.isFunction(e) ? ((t = e), (e = ['*'])) : (e = e.match(Me));
          for (var i, n = 0, o = e.length; n < o; n++)
            (i = e[n]),
              (z.tweeners[i] = z.tweeners[i] || []),
              z.tweeners[i].unshift(t);
        },
        prefilters: [W],
        prefilter: function (e, t) {
          t ? z.prefilters.unshift(e) : z.prefilters.push(e);
        },
      })),
        (he.speed = function (e, t, i) {
          var n =
            e && 'object' == typeof e
              ? he.extend({}, e)
              : {
                  complete: i || (!i && t) || (he.isFunction(e) && e),
                  duration: e,
                  easing: (i && t) || (t && !he.isFunction(t) && t),
                };
          return (
            he.fx.off || te.hidden
              ? (n.duration = 0)
              : 'number' != typeof n.duration &&
                (n.duration in he.fx.speeds
                  ? (n.duration = he.fx.speeds[n.duration])
                  : (n.duration = he.fx.speeds._default)),
            (null != n.queue && n.queue !== !0) || (n.queue = 'fx'),
            (n.old = n.complete),
            (n.complete = function () {
              he.isFunction(n.old) && n.old.call(this),
                n.queue && he.dequeue(this, n.queue);
            }),
            n
          );
        }),
        he.fn.extend({
          fadeTo: function (e, t, i, n) {
            return this.filter(We)
              .css('opacity', 0)
              .show()
              .end()
              .animate({ opacity: t }, e, i, n);
          },
          animate: function (e, t, i, n) {
            var o = he.isEmptyObject(e),
              r = he.speed(t, i, n),
              a = function () {
                var t = z(this, he.extend({}, e), r);
                (o || He.get(this, 'finish')) && t.stop(!0);
              };
            return (
              (a.finish = a),
              o || r.queue === !1 ? this.each(a) : this.queue(r.queue, a)
            );
          },
          stop: function (e, t, i) {
            var n = function (e) {
              var t = e.stop;
              delete e.stop, t(i);
            };
            return (
              'string' != typeof e && ((i = t), (t = e), (e = void 0)),
              t && e !== !1 && this.queue(e || 'fx', []),
              this.each(function () {
                var t = !0,
                  o = null != e && e + 'queueHooks',
                  r = he.timers,
                  a = He.get(this);
                if (o) a[o] && a[o].stop && n(a[o]);
                else for (o in a) a[o] && a[o].stop && mt.test(o) && n(a[o]);
                for (o = r.length; o--; )
                  r[o].elem !== this ||
                    (null != e && r[o].queue !== e) ||
                    (r[o].anim.stop(i), (t = !1), r.splice(o, 1));
                (!t && i) || he.dequeue(this, e);
              })
            );
          },
          finish: function (e) {
            return (
              e !== !1 && (e = e || 'fx'),
              this.each(function () {
                var t,
                  i = He.get(this),
                  n = i[e + 'queue'],
                  o = i[e + 'queueHooks'],
                  r = he.timers,
                  a = n ? n.length : 0;
                for (
                  i.finish = !0,
                    he.queue(this, e, []),
                    o && o.stop && o.stop.call(this, !0),
                    t = r.length;
                  t--;

                )
                  r[t].elem === this &&
                    r[t].queue === e &&
                    (r[t].anim.stop(!0), r.splice(t, 1));
                for (t = 0; t < a; t++)
                  n[t] && n[t].finish && n[t].finish.call(this);
                delete i.finish;
              })
            );
          },
        }),
        he.each(['toggle', 'show', 'hide'], function (e, t) {
          var i = he.fn[t];
          he.fn[t] = function (e, n, o) {
            return null == e || 'boolean' == typeof e
              ? i.apply(this, arguments)
              : this.animate(q(t, !0), e, n, o);
          };
        }),
        he.each(
          {
            slideDown: q('show'),
            slideUp: q('hide'),
            slideToggle: q('toggle'),
            fadeIn: { opacity: 'show' },
            fadeOut: { opacity: 'hide' },
            fadeToggle: { opacity: 'toggle' },
          },
          function (e, t) {
            he.fn[e] = function (e, i, n) {
              return this.animate(t, e, i, n);
            };
          }
        ),
        (he.timers = []),
        (he.fx.tick = function () {
          var e,
            t = 0,
            i = he.timers;
          for (ft = he.now(); t < i.length; t++)
            (e = i[t]), e() || i[t] !== e || i.splice(t--, 1);
          i.length || he.fx.stop(), (ft = void 0);
        }),
        (he.fx.timer = function (e) {
          he.timers.push(e), e() ? he.fx.start() : he.timers.pop();
        }),
        (he.fx.interval = 13),
        (he.fx.start = function () {
          ht ||
            (ht = e.requestAnimationFrame
              ? e.requestAnimationFrame(L)
              : e.setInterval(he.fx.tick, he.fx.interval));
        }),
        (he.fx.stop = function () {
          e.cancelAnimationFrame
            ? e.cancelAnimationFrame(ht)
            : e.clearInterval(ht),
            (ht = null);
        }),
        (he.fx.speeds = { slow: 600, fast: 200, _default: 400 }),
        (he.fn.delay = function (t, i) {
          return (
            (t = he.fx ? he.fx.speeds[t] || t : t),
            (i = i || 'fx'),
            this.queue(i, function (i, n) {
              var o = e.setTimeout(i, t);
              n.stop = function () {
                e.clearTimeout(o);
              };
            })
          );
        }),
        (function () {
          var e = te.createElement('input'),
            t = te.createElement('select'),
            i = t.appendChild(te.createElement('option'));
          (e.type = 'checkbox'),
            (pe.checkOn = '' !== e.value),
            (pe.optSelected = i.selected),
            (e = te.createElement('input')),
            (e.value = 't'),
            (e.type = 'radio'),
            (pe.radioValue = 't' === e.value);
        })();
      var vt,
        yt = he.expr.attrHandle;
      he.fn.extend({
        attr: function (e, t) {
          return Ae(this, he.attr, e, t, arguments.length > 1);
        },
        removeAttr: function (e) {
          return this.each(function () {
            he.removeAttr(this, e);
          });
        },
      }),
        he.extend({
          attr: function (e, t, i) {
            var n,
              o,
              r = e.nodeType;
            if (3 !== r && 8 !== r && 2 !== r)
              return 'undefined' == typeof e.getAttribute
                ? he.prop(e, t, i)
                : ((1 === r && he.isXMLDoc(e)) ||
                    (o =
                      he.attrHooks[t.toLowerCase()] ||
                      (he.expr.match.bool.test(t) ? vt : void 0)),
                  void 0 !== i
                    ? null === i
                      ? void he.removeAttr(e, t)
                      : o && 'set' in o && void 0 !== (n = o.set(e, i, t))
                      ? n
                      : (e.setAttribute(t, i + ''), i)
                    : o && 'get' in o && null !== (n = o.get(e, t))
                    ? n
                    : ((n = he.find.attr(e, t)), null == n ? void 0 : n));
          },
          attrHooks: {
            type: {
              set: function (e, t) {
                if (
                  !pe.radioValue &&
                  'radio' === t &&
                  he.nodeName(e, 'input')
                ) {
                  var i = e.value;
                  return e.setAttribute('type', t), i && (e.value = i), t;
                }
              },
            },
          },
          removeAttr: function (e, t) {
            var i,
              n = 0,
              o = t && t.match(Me);
            if (o && 1 === e.nodeType)
              for (; (i = o[n++]); ) e.removeAttribute(i);
          },
        }),
        (vt = {
          set: function (e, t, i) {
            return t === !1 ? he.removeAttr(e, i) : e.setAttribute(i, i), i;
          },
        }),
        he.each(he.expr.match.bool.source.match(/\w+/g), function (e, t) {
          var i = yt[t] || he.find.attr;
          yt[t] = function (e, t, n) {
            var o,
              r,
              a = t.toLowerCase();
            return (
              n ||
                ((r = yt[a]),
                (yt[a] = o),
                (o = null != i(e, t, n) ? a : null),
                (yt[a] = r)),
              o
            );
          };
        });
      var wt = /^(?:input|select|textarea|button)$/i,
        bt = /^(?:a|area)$/i;
      he.fn.extend({
        prop: function (e, t) {
          return Ae(this, he.prop, e, t, arguments.length > 1);
        },
        removeProp: function (e) {
          return this.each(function () {
            delete this[he.propFix[e] || e];
          });
        },
      }),
        he.extend({
          prop: function (e, t, i) {
            var n,
              o,
              r = e.nodeType;
            if (3 !== r && 8 !== r && 2 !== r)
              return (
                (1 === r && he.isXMLDoc(e)) ||
                  ((t = he.propFix[t] || t), (o = he.propHooks[t])),
                void 0 !== i
                  ? o && 'set' in o && void 0 !== (n = o.set(e, i, t))
                    ? n
                    : (e[t] = i)
                  : o && 'get' in o && null !== (n = o.get(e, t))
                  ? n
                  : e[t]
              );
          },
          propHooks: {
            tabIndex: {
              get: function (e) {
                var t = he.find.attr(e, 'tabindex');
                return t
                  ? parseInt(t, 10)
                  : wt.test(e.nodeName) || (bt.test(e.nodeName) && e.href)
                  ? 0
                  : -1;
              },
            },
          },
          propFix: { for: 'htmlFor', class: 'className' },
        }),
        pe.optSelected ||
          (he.propHooks.selected = {
            get: function (e) {
              var t = e.parentNode;
              return t && t.parentNode && t.parentNode.selectedIndex, null;
            },
            set: function (e) {
              var t = e.parentNode;
              t &&
                (t.selectedIndex, t.parentNode && t.parentNode.selectedIndex);
            },
          }),
        he.each(
          [
            'tabIndex',
            'readOnly',
            'maxLength',
            'cellSpacing',
            'cellPadding',
            'rowSpan',
            'colSpan',
            'useMap',
            'frameBorder',
            'contentEditable',
          ],
          function () {
            he.propFix[this.toLowerCase()] = this;
          }
        ),
        he.fn.extend({
          addClass: function (e) {
            var t,
              i,
              n,
              o,
              r,
              a,
              s,
              l = 0;
            if (he.isFunction(e))
              return this.each(function (t) {
                he(this).addClass(e.call(this, t, U(this)));
              });
            if ('string' == typeof e && e)
              for (t = e.match(Me) || []; (i = this[l++]); )
                if (((o = U(i)), (n = 1 === i.nodeType && ' ' + Y(o) + ' '))) {
                  for (a = 0; (r = t[a++]); )
                    n.indexOf(' ' + r + ' ') < 0 && (n += r + ' ');
                  (s = Y(n)), o !== s && i.setAttribute('class', s);
                }
            return this;
          },
          removeClass: function (e) {
            var t,
              i,
              n,
              o,
              r,
              a,
              s,
              l = 0;
            if (he.isFunction(e))
              return this.each(function (t) {
                he(this).removeClass(e.call(this, t, U(this)));
              });
            if (!arguments.length) return this.attr('class', '');
            if ('string' == typeof e && e)
              for (t = e.match(Me) || []; (i = this[l++]); )
                if (((o = U(i)), (n = 1 === i.nodeType && ' ' + Y(o) + ' '))) {
                  for (a = 0; (r = t[a++]); )
                    for (; n.indexOf(' ' + r + ' ') > -1; )
                      n = n.replace(' ' + r + ' ', ' ');
                  (s = Y(n)), o !== s && i.setAttribute('class', s);
                }
            return this;
          },
          toggleClass: function (e, t) {
            var i = typeof e;
            return 'boolean' == typeof t && 'string' === i
              ? t
                ? this.addClass(e)
                : this.removeClass(e)
              : he.isFunction(e)
              ? this.each(function (i) {
                  he(this).toggleClass(e.call(this, i, U(this), t), t);
                })
              : this.each(function () {
                  var t, n, o, r;
                  if ('string' === i)
                    for (
                      n = 0, o = he(this), r = e.match(Me) || [];
                      (t = r[n++]);

                    )
                      o.hasClass(t) ? o.removeClass(t) : o.addClass(t);
                  else
                    (void 0 !== e && 'boolean' !== i) ||
                      ((t = U(this)),
                      t && He.set(this, '__className__', t),
                      this.setAttribute &&
                        this.setAttribute(
                          'class',
                          t || e === !1
                            ? ''
                            : He.get(this, '__className__') || ''
                        ));
                });
          },
          hasClass: function (e) {
            var t,
              i,
              n = 0;
            for (t = ' ' + e + ' '; (i = this[n++]); )
              if (1 === i.nodeType && (' ' + Y(U(i)) + ' ').indexOf(t) > -1)
                return !0;
            return !1;
          },
        });
      var kt = /\r/g;
      he.fn.extend({
        val: function (e) {
          var t,
            i,
            n,
            o = this[0];
          return arguments.length
            ? ((n = he.isFunction(e)),
              this.each(function (i) {
                var o;
                1 === this.nodeType &&
                  ((o = n ? e.call(this, i, he(this).val()) : e),
                  null == o
                    ? (o = '')
                    : 'number' == typeof o
                    ? (o += '')
                    : he.isArray(o) &&
                      (o = he.map(o, function (e) {
                        return null == e ? '' : e + '';
                      })),
                  (t =
                    he.valHooks[this.type] ||
                    he.valHooks[this.nodeName.toLowerCase()]),
                  (t && 'set' in t && void 0 !== t.set(this, o, 'value')) ||
                    (this.value = o));
              }))
            : o
            ? ((t =
                he.valHooks[o.type] || he.valHooks[o.nodeName.toLowerCase()]),
              t && 'get' in t && void 0 !== (i = t.get(o, 'value'))
                ? i
                : ((i = o.value),
                  'string' == typeof i
                    ? i.replace(kt, '')
                    : null == i
                    ? ''
                    : i))
            : void 0;
        },
      }),
        he.extend({
          valHooks: {
            option: {
              get: function (e) {
                var t = he.find.attr(e, 'value');
                return null != t ? t : Y(he.text(e));
              },
            },
            select: {
              get: function (e) {
                var t,
                  i,
                  n,
                  o = e.options,
                  r = e.selectedIndex,
                  a = 'select-one' === e.type,
                  s = a ? null : [],
                  l = a ? r + 1 : o.length;
                for (n = r < 0 ? l : a ? r : 0; n < l; n++)
                  if (
                    ((i = o[n]),
                    (i.selected || n === r) &&
                      !i.disabled &&
                      (!i.parentNode.disabled ||
                        !he.nodeName(i.parentNode, 'optgroup')))
                  ) {
                    if (((t = he(i).val()), a)) return t;
                    s.push(t);
                  }
                return s;
              },
              set: function (e, t) {
                for (
                  var i, n, o = e.options, r = he.makeArray(t), a = o.length;
                  a--;

                )
                  (n = o[a]),
                    (n.selected =
                      he.inArray(he.valHooks.option.get(n), r) > -1) &&
                      (i = !0);
                return i || (e.selectedIndex = -1), r;
              },
            },
          },
        }),
        he.each(['radio', 'checkbox'], function () {
          (he.valHooks[this] = {
            set: function (e, t) {
              if (he.isArray(t))
                return (e.checked = he.inArray(he(e).val(), t) > -1);
            },
          }),
            pe.checkOn ||
              (he.valHooks[this].get = function (e) {
                return null === e.getAttribute('value') ? 'on' : e.value;
              });
        });
      var St = /^(?:focusinfocus|focusoutblur)$/;
      he.extend(he.event, {
        trigger: function (t, i, n, o) {
          var r,
            a,
            s,
            l,
            c,
            d,
            u,
            p = [n || te],
            f = ce.call(t, 'type') ? t.type : t,
            h = ce.call(t, 'namespace') ? t.namespace.split('.') : [];
          if (
            ((a = s = n = n || te),
            3 !== n.nodeType &&
              8 !== n.nodeType &&
              !St.test(f + he.event.triggered) &&
              (f.indexOf('.') > -1 &&
                ((h = f.split('.')), (f = h.shift()), h.sort()),
              (c = f.indexOf(':') < 0 && 'on' + f),
              (t = t[he.expando]
                ? t
                : new he.Event(f, 'object' == typeof t && t)),
              (t.isTrigger = o ? 2 : 3),
              (t.namespace = h.join('.')),
              (t.rnamespace = t.namespace
                ? new RegExp('(^|\\.)' + h.join('\\.(?:.*\\.|)') + '(\\.|$)')
                : null),
              (t.result = void 0),
              t.target || (t.target = n),
              (i = null == i ? [t] : he.makeArray(i, [t])),
              (u = he.event.special[f] || {}),
              o || !u.trigger || u.trigger.apply(n, i) !== !1))
          ) {
            if (!o && !u.noBubble && !he.isWindow(n)) {
              for (
                l = u.delegateType || f, St.test(l + f) || (a = a.parentNode);
                a;
                a = a.parentNode
              )
                p.push(a), (s = a);
              s === (n.ownerDocument || te) &&
                p.push(s.defaultView || s.parentWindow || e);
            }
            for (r = 0; (a = p[r++]) && !t.isPropagationStopped(); )
              (t.type = r > 1 ? l : u.bindType || f),
                (d =
                  (He.get(a, 'events') || {})[t.type] && He.get(a, 'handle')),
                d && d.apply(a, i),
                (d = c && a[c]),
                d &&
                  d.apply &&
                  Ne(a) &&
                  ((t.result = d.apply(a, i)),
                  t.result === !1 && t.preventDefault());
            return (
              (t.type = f),
              o ||
                t.isDefaultPrevented() ||
                (u._default && u._default.apply(p.pop(), i) !== !1) ||
                !Ne(n) ||
                (c &&
                  he.isFunction(n[f]) &&
                  !he.isWindow(n) &&
                  ((s = n[c]),
                  s && (n[c] = null),
                  (he.event.triggered = f),
                  n[f](),
                  (he.event.triggered = void 0),
                  s && (n[c] = s))),
              t.result
            );
          }
        },
        simulate: function (e, t, i) {
          var n = he.extend(new he.Event(), i, { type: e, isSimulated: !0 });
          he.event.trigger(n, null, t);
        },
      }),
        he.fn.extend({
          trigger: function (e, t) {
            return this.each(function () {
              he.event.trigger(e, t, this);
            });
          },
          triggerHandler: function (e, t) {
            var i = this[0];
            if (i) return he.event.trigger(e, t, i, !0);
          },
        }),
        he.each(
          'blur focus focusin focusout resize scroll click dblclick mousedown mouseup mousemove mouseover mouseout mouseenter mouseleave change select submit keydown keypress keyup contextmenu'.split(
            ' '
          ),
          function (e, t) {
            he.fn[t] = function (e, i) {
              return arguments.length > 0
                ? this.on(t, null, e, i)
                : this.trigger(t);
            };
          }
        ),
        he.fn.extend({
          hover: function (e, t) {
            return this.mouseenter(e).mouseleave(t || e);
          },
        }),
        (pe.focusin = 'onfocusin' in e),
        pe.focusin ||
          he.each({ focus: 'focusin', blur: 'focusout' }, function (e, t) {
            var i = function (e) {
              he.event.simulate(t, e.target, he.event.fix(e));
            };
            he.event.special[t] = {
              setup: function () {
                var n = this.ownerDocument || this,
                  o = He.access(n, t);
                o || n.addEventListener(e, i, !0),
                  He.access(n, t, (o || 0) + 1);
              },
              teardown: function () {
                var n = this.ownerDocument || this,
                  o = He.access(n, t) - 1;
                o
                  ? He.access(n, t, o)
                  : (n.removeEventListener(e, i, !0), He.remove(n, t));
              },
            };
          });
      var xt = e.location,
        Tt = he.now(),
        Ct = /\?/;
      he.parseXML = function (t) {
        var i;
        if (!t || 'string' != typeof t) return null;
        try {
          i = new e.DOMParser().parseFromString(t, 'text/xml');
        } catch (n) {
          i = void 0;
        }
        return (
          (i && !i.getElementsByTagName('parsererror').length) ||
            he.error('Invalid XML: ' + t),
          i
        );
      };
      var Dt = /\[\]$/,
        It = /\r?\n/g,
        _t = /^(?:submit|button|image|reset|file)$/i,
        $t = /^(?:input|select|textarea|keygen)/i;
      (he.param = function (e, t) {
        var i,
          n = [],
          o = function (e, t) {
            var i = he.isFunction(t) ? t() : t;
            n[n.length] =
              encodeURIComponent(e) +
              '=' +
              encodeURIComponent(null == i ? '' : i);
          };
        if (he.isArray(e) || (e.jquery && !he.isPlainObject(e)))
          he.each(e, function () {
            o(this.name, this.value);
          });
        else for (i in e) Q(i, e[i], t, o);
        return n.join('&');
      }),
        he.fn.extend({
          serialize: function () {
            return he.param(this.serializeArray());
          },
          serializeArray: function () {
            return this.map(function () {
              var e = he.prop(this, 'elements');
              return e ? he.makeArray(e) : this;
            })
              .filter(function () {
                var e = this.type;
                return (
                  this.name &&
                  !he(this).is(':disabled') &&
                  $t.test(this.nodeName) &&
                  !_t.test(e) &&
                  (this.checked || !Ye.test(e))
                );
              })
              .map(function (e, t) {
                var i = he(this).val();
                return null == i
                  ? null
                  : he.isArray(i)
                  ? he.map(i, function (e) {
                      return { name: t.name, value: e.replace(It, '\r\n') };
                    })
                  : { name: t.name, value: i.replace(It, '\r\n') };
              })
              .get();
          },
        });
      var Mt = /%20/g,
        Et = /#.*$/,
        jt = /([?&])_=[^&]*/,
        At = /^(.*?):[ \t]*([^\r\n]*)$/gm,
        Nt = /^(?:about|app|app-storage|.+-extension|file|res|widget):$/,
        Ht = /^(?:GET|HEAD)$/,
        Ot = /^\/\//,
        Pt = {},
        Lt = {},
        Ft = '*/'.concat('*'),
        qt = te.createElement('a');
      (qt.href = xt.href),
        he.extend({
          active: 0,
          lastModified: {},
          etag: {},
          ajaxSettings: {
            url: xt.href,
            type: 'GET',
            isLocal: Nt.test(xt.protocol),
            global: !0,
            processData: !0,
            async: !0,
            contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
            accepts: {
              '*': Ft,
              text: 'text/plain',
              html: 'text/html',
              xml: 'application/xml, text/xml',
              json: 'application/json, text/javascript',
            },
            contents: { xml: /\bxml\b/, html: /\bhtml/, json: /\bjson\b/ },
            responseFields: {
              xml: 'responseXML',
              text: 'responseText',
              json: 'responseJSON',
            },
            converters: {
              '* text': String,
              'text html': !0,
              'text json': JSON.parse,
              'text xml': he.parseXML,
            },
            flatOptions: { url: !0, context: !0 },
          },
          ajaxSetup: function (e, t) {
            return t ? V(V(e, he.ajaxSettings), t) : V(he.ajaxSettings, e);
          },
          ajaxPrefilter: K(Pt),
          ajaxTransport: K(Lt),
          ajax: function (t, i) {
            function n(t, i, n, s) {
              var c,
                p,
                f,
                b,
                k,
                S = i;
              d ||
                ((d = !0),
                l && e.clearTimeout(l),
                (o = void 0),
                (a = s || ''),
                (x.readyState = t > 0 ? 4 : 0),
                (c = (t >= 200 && t < 300) || 304 === t),
                n && (b = G(h, x, n)),
                (b = J(h, b, x, c)),
                c
                  ? (h.ifModified &&
                      ((k = x.getResponseHeader('Last-Modified')),
                      k && (he.lastModified[r] = k),
                      (k = x.getResponseHeader('etag')),
                      k && (he.etag[r] = k)),
                    204 === t || 'HEAD' === h.type
                      ? (S = 'nocontent')
                      : 304 === t
                      ? (S = 'notmodified')
                      : ((S = b.state), (p = b.data), (f = b.error), (c = !f)))
                  : ((f = S), (!t && S) || ((S = 'error'), t < 0 && (t = 0))),
                (x.status = t),
                (x.statusText = (i || S) + ''),
                c ? v.resolveWith(g, [p, S, x]) : v.rejectWith(g, [x, S, f]),
                x.statusCode(w),
                (w = void 0),
                u &&
                  m.trigger(c ? 'ajaxSuccess' : 'ajaxError', [x, h, c ? p : f]),
                y.fireWith(g, [x, S]),
                u &&
                  (m.trigger('ajaxComplete', [x, h]),
                  --he.active || he.event.trigger('ajaxStop')));
            }
            'object' == typeof t && ((i = t), (t = void 0)), (i = i || {});
            var o,
              r,
              a,
              s,
              l,
              c,
              d,
              u,
              p,
              f,
              h = he.ajaxSetup({}, i),
              g = h.context || h,
              m = h.context && (g.nodeType || g.jquery) ? he(g) : he.event,
              v = he.Deferred(),
              y = he.Callbacks('once memory'),
              w = h.statusCode || {},
              b = {},
              k = {},
              S = 'canceled',
              x = {
                readyState: 0,
                getResponseHeader: function (e) {
                  var t;
                  if (d) {
                    if (!s)
                      for (s = {}; (t = At.exec(a)); )
                        s[t[1].toLowerCase()] = t[2];
                    t = s[e.toLowerCase()];
                  }
                  return null == t ? null : t;
                },
                getAllResponseHeaders: function () {
                  return d ? a : null;
                },
                setRequestHeader: function (e, t) {
                  return (
                    null == d &&
                      ((e = k[e.toLowerCase()] = k[e.toLowerCase()] || e),
                      (b[e] = t)),
                    this
                  );
                },
                overrideMimeType: function (e) {
                  return null == d && (h.mimeType = e), this;
                },
                statusCode: function (e) {
                  var t;
                  if (e)
                    if (d) x.always(e[x.status]);
                    else for (t in e) w[t] = [w[t], e[t]];
                  return this;
                },
                abort: function (e) {
                  var t = e || S;
                  return o && o.abort(t), n(0, t), this;
                },
              };
            if (
              (v.promise(x),
              (h.url = ((t || h.url || xt.href) + '').replace(
                Ot,
                xt.protocol + '//'
              )),
              (h.type = i.method || i.type || h.method || h.type),
              (h.dataTypes = (h.dataType || '*').toLowerCase().match(Me) || [
                '',
              ]),
              null == h.crossDomain)
            ) {
              c = te.createElement('a');
              try {
                (c.href = h.url),
                  (c.href = c.href),
                  (h.crossDomain =
                    qt.protocol + '//' + qt.host != c.protocol + '//' + c.host);
              } catch (T) {
                h.crossDomain = !0;
              }
            }
            if (
              (h.data &&
                h.processData &&
                'string' != typeof h.data &&
                (h.data = he.param(h.data, h.traditional)),
              X(Pt, h, i, x),
              d)
            )
              return x;
            (u = he.event && h.global),
              u && 0 === he.active++ && he.event.trigger('ajaxStart'),
              (h.type = h.type.toUpperCase()),
              (h.hasContent = !Ht.test(h.type)),
              (r = h.url.replace(Et, '')),
              h.hasContent
                ? h.data &&
                  h.processData &&
                  0 ===
                    (h.contentType || '').indexOf(
                      'application/x-www-form-urlencoded'
                    ) &&
                  (h.data = h.data.replace(Mt, '+'))
                : ((f = h.url.slice(r.length)),
                  h.data &&
                    ((r += (Ct.test(r) ? '&' : '?') + h.data), delete h.data),
                  h.cache === !1 &&
                    ((r = r.replace(jt, '$1')),
                    (f = (Ct.test(r) ? '&' : '?') + '_=' + Tt++ + f)),
                  (h.url = r + f)),
              h.ifModified &&
                (he.lastModified[r] &&
                  x.setRequestHeader('If-Modified-Since', he.lastModified[r]),
                he.etag[r] && x.setRequestHeader('If-None-Match', he.etag[r])),
              ((h.data && h.hasContent && h.contentType !== !1) ||
                i.contentType) &&
                x.setRequestHeader('Content-Type', h.contentType),
              x.setRequestHeader(
                'Accept',
                h.dataTypes[0] && h.accepts[h.dataTypes[0]]
                  ? h.accepts[h.dataTypes[0]] +
                      ('*' !== h.dataTypes[0] ? ', ' + Ft + '; q=0.01' : '')
                  : h.accepts['*']
              );
            for (p in h.headers) x.setRequestHeader(p, h.headers[p]);
            if (h.beforeSend && (h.beforeSend.call(g, x, h) === !1 || d))
              return x.abort();
            if (
              ((S = 'abort'),
              y.add(h.complete),
              x.done(h.success),
              x.fail(h.error),
              (o = X(Lt, h, i, x)))
            ) {
              if (((x.readyState = 1), u && m.trigger('ajaxSend', [x, h]), d))
                return x;
              h.async &&
                h.timeout > 0 &&
                (l = e.setTimeout(function () {
                  x.abort('timeout');
                }, h.timeout));
              try {
                (d = !1), o.send(b, n);
              } catch (T) {
                if (d) throw T;
                n(-1, T);
              }
            } else n(-1, 'No Transport');
            return x;
          },
          getJSON: function (e, t, i) {
            return he.get(e, t, i, 'json');
          },
          getScript: function (e, t) {
            return he.get(e, void 0, t, 'script');
          },
        }),
        he.each(['get', 'post'], function (e, t) {
          he[t] = function (e, i, n, o) {
            return (
              he.isFunction(i) && ((o = o || n), (n = i), (i = void 0)),
              he.ajax(
                he.extend(
                  { url: e, type: t, dataType: o, data: i, success: n },
                  he.isPlainObject(e) && e
                )
              )
            );
          };
        }),
        (he._evalUrl = function (e) {
          return he.ajax({
            url: e,
            type: 'GET',
            dataType: 'script',
            cache: !0,
            async: !1,
            global: !1,
            throws: !0,
          });
        }),
        he.fn.extend({
          wrapAll: function (e) {
            var t;
            return (
              this[0] &&
                (he.isFunction(e) && (e = e.call(this[0])),
                (t = he(e, this[0].ownerDocument).eq(0).clone(!0)),
                this[0].parentNode && t.insertBefore(this[0]),
                t
                  .map(function () {
                    for (var e = this; e.firstElementChild; )
                      e = e.firstElementChild;
                    return e;
                  })
                  .append(this)),
              this
            );
          },
          wrapInner: function (e) {
            return he.isFunction(e)
              ? this.each(function (t) {
                  he(this).wrapInner(e.call(this, t));
                })
              : this.each(function () {
                  var t = he(this),
                    i = t.contents();
                  i.length ? i.wrapAll(e) : t.append(e);
                });
          },
          wrap: function (e) {
            var t = he.isFunction(e);
            return this.each(function (i) {
              he(this).wrapAll(t ? e.call(this, i) : e);
            });
          },
          unwrap: function (e) {
            return (
              this.parent(e)
                .not('body')
                .each(function () {
                  he(this).replaceWith(this.childNodes);
                }),
              this
            );
          },
        }),
        (he.expr.pseudos.hidden = function (e) {
          return !he.expr.pseudos.visible(e);
        }),
        (he.expr.pseudos.visible = function (e) {
          return !!(
            e.offsetWidth ||
            e.offsetHeight ||
            e.getClientRects().length
          );
        }),
        (he.ajaxSettings.xhr = function () {
          try {
            return new e.XMLHttpRequest();
          } catch (t) {}
        });
      var Bt = { 0: 200, 1223: 204 },
        Wt = he.ajaxSettings.xhr();
      (pe.cors = !!Wt && 'withCredentials' in Wt),
        (pe.ajax = Wt = !!Wt),
        he.ajaxTransport(function (t) {
          var i, n;
          if (pe.cors || (Wt && !t.crossDomain))
            return {
              send: function (o, r) {
                var a,
                  s = t.xhr();
                if (
                  (s.open(t.type, t.url, t.async, t.username, t.password),
                  t.xhrFields)
                )
                  for (a in t.xhrFields) s[a] = t.xhrFields[a];
                t.mimeType &&
                  s.overrideMimeType &&
                  s.overrideMimeType(t.mimeType),
                  t.crossDomain ||
                    o['X-Requested-With'] ||
                    (o['X-Requested-With'] = 'XMLHttpRequest');
                for (a in o) s.setRequestHeader(a, o[a]);
                (i = function (e) {
                  return function () {
                    i &&
                      ((i =
                        n =
                        s.onload =
                        s.onerror =
                        s.onabort =
                        s.onreadystatechange =
                          null),
                      'abort' === e
                        ? s.abort()
                        : 'error' === e
                        ? 'number' != typeof s.status
                          ? r(0, 'error')
                          : r(s.status, s.statusText)
                        : r(
                            Bt[s.status] || s.status,
                            s.statusText,
                            'text' !== (s.responseType || 'text') ||
                              'string' != typeof s.responseText
                              ? { binary: s.response }
                              : { text: s.responseText },
                            s.getAllResponseHeaders()
                          ));
                  };
                }),
                  (s.onload = i()),
                  (n = s.onerror = i('error')),
                  void 0 !== s.onabort
                    ? (s.onabort = n)
                    : (s.onreadystatechange = function () {
                        4 === s.readyState &&
                          e.setTimeout(function () {
                            i && n();
                          });
                      }),
                  (i = i('abort'));
                try {
                  s.send((t.hasContent && t.data) || null);
                } catch (l) {
                  if (i) throw l;
                }
              },
              abort: function () {
                i && i();
              },
            };
        }),
        he.ajaxPrefilter(function (e) {
          e.crossDomain && (e.contents.script = !1);
        }),
        he.ajaxSetup({
          accepts: {
            script:
              'text/javascript, application/javascript, application/ecmascript, application/x-ecmascript',
          },
          contents: { script: /\b(?:java|ecma)script\b/ },
          converters: {
            'text script': function (e) {
              return he.globalEval(e), e;
            },
          },
        }),
        he.ajaxPrefilter('script', function (e) {
          void 0 === e.cache && (e.cache = !1),
            e.crossDomain && (e.type = 'GET');
        }),
        he.ajaxTransport('script', function (e) {
          if (e.crossDomain) {
            var t, i;
            return {
              send: function (n, o) {
                (t = he('<script>')
                  .prop({ charset: e.scriptCharset, src: e.url })
                  .on(
                    'load error',
                    (i = function (e) {
                      t.remove(),
                        (i = null),
                        e && o('error' === e.type ? 404 : 200, e.type);
                    })
                  )),
                  te.head.appendChild(t[0]);
              },
              abort: function () {
                i && i();
              },
            };
          }
        });
      var Rt = [],
        zt = /(=)\?(?=&|$)|\?\?/;
      he.ajaxSetup({
        jsonp: 'callback',
        jsonpCallback: function () {
          var e = Rt.pop() || he.expando + '_' + Tt++;
          return (this[e] = !0), e;
        },
      }),
        he.ajaxPrefilter('json jsonp', function (t, i, n) {
          var o,
            r,
            a,
            s =
              t.jsonp !== !1 &&
              (zt.test(t.url)
                ? 'url'
                : 'string' == typeof t.data &&
                  0 ===
                    (t.contentType || '').indexOf(
                      'application/x-www-form-urlencoded'
                    ) &&
                  zt.test(t.data) &&
                  'data');
          if (s || 'jsonp' === t.dataTypes[0])
            return (
              (o = t.jsonpCallback =
                he.isFunction(t.jsonpCallback)
                  ? t.jsonpCallback()
                  : t.jsonpCallback),
              s
                ? (t[s] = t[s].replace(zt, '$1' + o))
                : t.jsonp !== !1 &&
                  (t.url += (Ct.test(t.url) ? '&' : '?') + t.jsonp + '=' + o),
              (t.converters['script json'] = function () {
                return a || he.error(o + ' was not called'), a[0];
              }),
              (t.dataTypes[0] = 'json'),
              (r = e[o]),
              (e[o] = function () {
                a = arguments;
              }),
              n.always(function () {
                void 0 === r ? he(e).removeProp(o) : (e[o] = r),
                  t[o] && ((t.jsonpCallback = i.jsonpCallback), Rt.push(o)),
                  a && he.isFunction(r) && r(a[0]),
                  (a = r = void 0);
              }),
              'script'
            );
        }),
        (pe.createHTMLDocument = (function () {
          var e = te.implementation.createHTMLDocument('').body;
          return (
            (e.innerHTML = '<form></form><form></form>'),
            2 === e.childNodes.length
          );
        })()),
        (he.parseHTML = function (e, t, i) {
          if ('string' != typeof e) return [];
          'boolean' == typeof t && ((i = t), (t = !1));
          var n, o, r;
          return (
            t ||
              (pe.createHTMLDocument
                ? ((t = te.implementation.createHTMLDocument('')),
                  (n = t.createElement('base')),
                  (n.href = te.location.href),
                  t.head.appendChild(n))
                : (t = te)),
            (o = xe.exec(e)),
            (r = !i && []),
            o
              ? [t.createElement(o[1])]
              : ((o = w([e], t, r)),
                r && r.length && he(r).remove(),
                he.merge([], o.childNodes))
          );
        }),
        (he.fn.load = function (e, t, i) {
          var n,
            o,
            r,
            a = this,
            s = e.indexOf(' ');
          return (
            s > -1 && ((n = Y(e.slice(s))), (e = e.slice(0, s))),
            he.isFunction(t)
              ? ((i = t), (t = void 0))
              : t && 'object' == typeof t && (o = 'POST'),
            a.length > 0 &&
              he
                .ajax({ url: e, type: o || 'GET', dataType: 'html', data: t })
                .done(function (e) {
                  (r = arguments),
                    a.html(n ? he('<div>').append(he.parseHTML(e)).find(n) : e);
                })
                .always(
                  i &&
                    function (e, t) {
                      a.each(function () {
                        i.apply(this, r || [e.responseText, t, e]);
                      });
                    }
                ),
            this
          );
        }),
        he.each(
          [
            'ajaxStart',
            'ajaxStop',
            'ajaxComplete',
            'ajaxError',
            'ajaxSuccess',
            'ajaxSend',
          ],
          function (e, t) {
            he.fn[t] = function (e) {
              return this.on(t, e);
            };
          }
        ),
        (he.expr.pseudos.animated = function (e) {
          return he.grep(he.timers, function (t) {
            return e === t.elem;
          }).length;
        }),
        (he.offset = {
          setOffset: function (e, t, i) {
            var n,
              o,
              r,
              a,
              s,
              l,
              c,
              d = he.css(e, 'position'),
              u = he(e),
              p = {};
            'static' === d && (e.style.position = 'relative'),
              (s = u.offset()),
              (r = he.css(e, 'top')),
              (l = he.css(e, 'left')),
              (c =
                ('absolute' === d || 'fixed' === d) &&
                (r + l).indexOf('auto') > -1),
              c
                ? ((n = u.position()), (a = n.top), (o = n.left))
                : ((a = parseFloat(r) || 0), (o = parseFloat(l) || 0)),
              he.isFunction(t) && (t = t.call(e, i, he.extend({}, s))),
              null != t.top && (p.top = t.top - s.top + a),
              null != t.left && (p.left = t.left - s.left + o),
              'using' in t ? t.using.call(e, p) : u.css(p);
          },
        }),
        he.fn.extend({
          offset: function (e) {
            if (arguments.length)
              return void 0 === e
                ? this
                : this.each(function (t) {
                    he.offset.setOffset(this, e, t);
                  });
            var t,
              i,
              n,
              o,
              r = this[0];
            return r
              ? r.getClientRects().length
                ? ((n = r.getBoundingClientRect()),
                  n.width || n.height
                    ? ((o = r.ownerDocument),
                      (i = Z(o)),
                      (t = o.documentElement),
                      {
                        top: n.top + i.pageYOffset - t.clientTop,
                        left: n.left + i.pageXOffset - t.clientLeft,
                      })
                    : n)
                : { top: 0, left: 0 }
              : void 0;
          },
          position: function () {
            if (this[0]) {
              var e,
                t,
                i = this[0],
                n = { top: 0, left: 0 };
              return (
                'fixed' === he.css(i, 'position')
                  ? (t = i.getBoundingClientRect())
                  : ((e = this.offsetParent()),
                    (t = this.offset()),
                    he.nodeName(e[0], 'html') || (n = e.offset()),
                    (n = {
                      top: n.top + he.css(e[0], 'borderTopWidth', !0),
                      left: n.left + he.css(e[0], 'borderLeftWidth', !0),
                    })),
                {
                  top: t.top - n.top - he.css(i, 'marginTop', !0),
                  left: t.left - n.left - he.css(i, 'marginLeft', !0),
                }
              );
            }
          },
          offsetParent: function () {
            return this.map(function () {
              for (
                var e = this.offsetParent;
                e && 'static' === he.css(e, 'position');

              )
                e = e.offsetParent;
              return e || Ve;
            });
          },
        }),
        he.each(
          { scrollLeft: 'pageXOffset', scrollTop: 'pageYOffset' },
          function (e, t) {
            var i = 'pageYOffset' === t;
            he.fn[e] = function (n) {
              return Ae(
                this,
                function (e, n, o) {
                  var r = Z(e);
                  return void 0 === o
                    ? r
                      ? r[t]
                      : e[n]
                    : void (r
                        ? r.scrollTo(
                            i ? r.pageXOffset : o,
                            i ? o : r.pageYOffset
                          )
                        : (e[n] = o));
                },
                e,
                n,
                arguments.length
              );
            };
          }
        ),
        he.each(['top', 'left'], function (e, t) {
          he.cssHooks[t] = j(pe.pixelPosition, function (e, i) {
            if (i)
              return (i = E(e, t)), at.test(i) ? he(e).position()[t] + 'px' : i;
          });
        }),
        he.each({ Height: 'height', Width: 'width' }, function (e, t) {
          he.each(
            { padding: 'inner' + e, content: t, '': 'outer' + e },
            function (i, n) {
              he.fn[n] = function (o, r) {
                var a = arguments.length && (i || 'boolean' != typeof o),
                  s = i || (o === !0 || r === !0 ? 'margin' : 'border');
                return Ae(
                  this,
                  function (t, i, o) {
                    var r;
                    return he.isWindow(t)
                      ? 0 === n.indexOf('outer')
                        ? t['inner' + e]
                        : t.document.documentElement['client' + e]
                      : 9 === t.nodeType
                      ? ((r = t.documentElement),
                        Math.max(
                          t.body['scroll' + e],
                          r['scroll' + e],
                          t.body['offset' + e],
                          r['offset' + e],
                          r['client' + e]
                        ))
                      : void 0 === o
                      ? he.css(t, i, s)
                      : he.style(t, i, o, s);
                  },
                  t,
                  a ? o : void 0,
                  a
                );
              };
            }
          );
        }),
        he.fn.extend({
          bind: function (e, t, i) {
            return this.on(e, null, t, i);
          },
          unbind: function (e, t) {
            return this.off(e, null, t);
          },
          delegate: function (e, t, i, n) {
            return this.on(t, e, i, n);
          },
          undelegate: function (e, t, i) {
            return 1 === arguments.length
              ? this.off(e, '**')
              : this.off(t, e || '**', i);
          },
        }),
        (he.parseJSON = JSON.parse),
        'function' == typeof define &&
          define.amd &&
          define('jquery', [], function () {
            return he;
          });
      var Yt = e.jQuery,
        Ut = e.$;
      return (
        (he.noConflict = function (t) {
          return (
            e.$ === he && (e.$ = Ut),
            t && e.jQuery === he && (e.jQuery = Yt),
            he
          );
        }),
        t || (e.jQuery = e.$ = he),
        he
      );
    }),
      (function (e) {
        e(jQuery);
      })(function (e) {
        function t(e) {
          for (var t, i; e.length && e[0] !== document; ) {
            if (
              ((t = e.css('position')),
              ('absolute' === t || 'relative' === t || 'fixed' === t) &&
                ((i = parseInt(e.css('zIndex'), 10)), !isNaN(i) && 0 !== i))
            )
              return i;
            e = e.parent();
          }
          return 0;
        }
        function i() {
          (this._curInst = null),
            (this._keyEvent = !1),
            (this._disabledInputs = []),
            (this._datepickerShowing = !1),
            (this._inDialog = !1),
            (this._mainDivId = 'ui-datepicker-div'),
            (this._inlineClass = 'ui-datepicker-inline'),
            (this._appendClass = 'ui-datepicker-append'),
            (this._triggerClass = 'ui-datepicker-trigger'),
            (this._dialogClass = 'ui-datepicker-dialog'),
            (this._disableClass = 'ui-datepicker-disabled'),
            (this._unselectableClass = 'ui-datepicker-unselectable'),
            (this._currentClass = 'ui-datepicker-current-day'),
            (this._dayOverClass = 'ui-datepicker-days-cell-over'),
            (this.regional = []),
            (this.regional[''] = {
              closeText: 'Done',
              prevText: 'Prev',
              nextText: 'Next',
              currentText: 'Today',
              monthNames: [
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ],
              monthNamesShort: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              dayNames: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
              ],
              dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
              dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
              weekHeader: 'Wk',
              dateFormat: 'mm/dd/yy',
              firstDay: 0,
              isRTL: !1,
              showMonthAfterYear: !1,
              yearSuffix: '',
            }),
            (this._defaults = {
              showOn: 'focus',
              showAnim: 'fadeIn',
              showOptions: {},
              defaultDate: null,
              appendText: '',
              buttonText: '...',
              buttonImage: '',
              buttonImageOnly: !1,
              hideIfNoPrevNext: !1,
              navigationAsDateFormat: !1,
              gotoCurrent: !1,
              changeMonth: !1,
              changeYear: !1,
              yearRange: 'c-10:c+10',
              showOtherMonths: !1,
              selectOtherMonths: !1,
              showWeek: !1,
              calculateWeek: this.iso8601Week,
              shortYearCutoff: '+10',
              minDate: null,
              maxDate: null,
              duration: 'fast',
              beforeShowDay: null,
              beforeShow: null,
              onSelect: null,
              onChangeMonthYear: null,
              onClose: null,
              numberOfMonths: 1,
              showCurrentAtPos: 0,
              stepMonths: 1,
              stepBigMonths: 12,
              altField: '',
              altFormat: '',
              constrainInput: !0,
              showButtonPanel: !1,
              autoSize: !1,
              disabled: !1,
            }),
            e.extend(this._defaults, this.regional['']),
            (this.regional.en = e.extend(!0, {}, this.regional[''])),
            (this.regional['en-US'] = e.extend(!0, {}, this.regional.en)),
            (this.dpDiv = n(
              e(
                "<div id='" +
                  this._mainDivId +
                  "' class='ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all'></div>"
              )
            ));
        }
        function n(t) {
          var i =
            'button, .ui-datepicker-prev, .ui-datepicker-next, .ui-datepicker-calendar td a';
          return t
            .on('mouseout', i, function () {
              e(this).removeClass('ui-state-hover'),
                -1 !== this.className.indexOf('ui-datepicker-prev') &&
                  e(this).removeClass('ui-datepicker-prev-hover'),
                -1 !== this.className.indexOf('ui-datepicker-next') &&
                  e(this).removeClass('ui-datepicker-next-hover');
            })
            .on('mouseover', i, o);
        }
        function o() {
          e.datepicker._isDisabledDatepicker(
            a.inline ? a.dpDiv.parent()[0] : a.input[0]
          ) ||
            (e(this)
              .parents('.ui-datepicker-calendar')
              .find('a')
              .removeClass('ui-state-hover'),
            e(this).addClass('ui-state-hover'),
            -1 !== this.className.indexOf('ui-datepicker-prev') &&
              e(this).addClass('ui-datepicker-prev-hover'),
            -1 !== this.className.indexOf('ui-datepicker-next') &&
              e(this).addClass('ui-datepicker-next-hover'));
        }
        function r(t, i) {
          e.extend(t, i);
          for (var n in i) null == i[n] && (t[n] = i[n]);
          return t;
        }
        (e.ui = e.ui || {}),
          (e.ui.version = '1.12.1'),
          (e.ui.keyCode = {
            BACKSPACE: 8,
            COMMA: 188,
            DELETE: 46,
            DOWN: 40,
            END: 35,
            ENTER: 13,
            ESCAPE: 27,
            HOME: 36,
            LEFT: 37,
            PAGE_DOWN: 34,
            PAGE_UP: 33,
            PERIOD: 190,
            RIGHT: 39,
            SPACE: 32,
            TAB: 9,
            UP: 38,
          }),
          e.extend(e.ui, { datepicker: { version: '1.12.1' } });
        var a;
        e.extend(i.prototype, {
          markerClassName: 'hasDatepicker',
          maxRows: 4,
          _widgetDatepicker: function () {
            return this.dpDiv;
          },
          setDefaults: function (e) {
            return r(this._defaults, e || {}), this;
          },
          _attachDatepicker: function (t, i) {
            var n, o, r;
            (n = t.nodeName.toLowerCase()),
              (o = 'div' === n || 'span' === n),
              t.id || ((this.uuid += 1), (t.id = 'dp' + this.uuid)),
              (r = this._newInst(e(t), o)),
              (r.settings = e.extend({}, i || {})),
              'input' === n
                ? this._connectDatepicker(t, r)
                : o && this._inlineDatepicker(t, r);
          },
          _newInst: function (t, i) {
            var o = t[0].id.replace(/([^A-Za-z0-9_\-])/g, '\\\\$1');
            return {
              id: o,
              input: t,
              selectedDay: 0,
              selectedMonth: 0,
              selectedYear: 0,
              drawMonth: 0,
              drawYear: 0,
              inline: i,
              dpDiv: i
                ? n(
                    e(
                      "<div class='" +
                        this._inlineClass +
                        " ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all'></div>"
                    )
                  )
                : this.dpDiv,
            };
          },
          _connectDatepicker: function (t, i) {
            var n = e(t);
            (i.append = e([])),
              (i.trigger = e([])),
              n.hasClass(this.markerClassName) ||
                (this._attachments(n, i),
                n
                  .addClass(this.markerClassName)
                  .on('keydown', this._doKeyDown)
                  .on('keypress', this._doKeyPress)
                  .on('keyup', this._doKeyUp),
                this._autoSize(i),
                e.data(t, 'datepicker', i),
                i.settings.disabled && this._disableDatepicker(t));
          },
          _attachments: function (t, i) {
            var n,
              o,
              r,
              a = this._get(i, 'appendText'),
              s = this._get(i, 'isRTL');
            i.append && i.append.remove(),
              a &&
                ((i.append = e(
                  "<span class='" + this._appendClass + "'>" + a + '</span>'
                )),
                t[s ? 'before' : 'after'](i.append)),
              t.off('focus', this._showDatepicker),
              i.trigger && i.trigger.remove(),
              (n = this._get(i, 'showOn')),
              ('focus' === n || 'both' === n) &&
                t.on('focus', this._showDatepicker),
              ('button' === n || 'both' === n) &&
                ((o = this._get(i, 'buttonText')),
                (r = this._get(i, 'buttonImage')),
                (i.trigger = e(
                  this._get(i, 'buttonImageOnly')
                    ? e('<img/>')
                        .addClass(this._triggerClass)
                        .attr({ src: r, alt: o, title: o })
                    : e("<button type='button'></button>")
                        .addClass(this._triggerClass)
                        .html(
                          r ? e('<img/>').attr({ src: r, alt: o, title: o }) : o
                        )
                )),
                t[s ? 'before' : 'after'](i.trigger),
                i.trigger.on('click', function () {
                  return (
                    e.datepicker._datepickerShowing &&
                    e.datepicker._lastInput === t[0]
                      ? e.datepicker._hideDatepicker()
                      : e.datepicker._datepickerShowing &&
                        e.datepicker._lastInput !== t[0]
                      ? (e.datepicker._hideDatepicker(),
                        e.datepicker._showDatepicker(t[0]))
                      : e.datepicker._showDatepicker(t[0]),
                    !1
                  );
                }));
          },
          _autoSize: function (e) {
            if (this._get(e, 'autoSize') && !e.inline) {
              var t,
                i,
                n,
                o,
                r = new Date(2009, 11, 20),
                a = this._get(e, 'dateFormat');
              a.match(/[DM]/) &&
                ((t = function (e) {
                  for (i = 0, n = 0, o = 0; e.length > o; o++)
                    e[o].length > i && ((i = e[o].length), (n = o));
                  return n;
                }),
                r.setMonth(
                  t(
                    this._get(
                      e,
                      a.match(/MM/) ? 'monthNames' : 'monthNamesShort'
                    )
                  )
                ),
                r.setDate(
                  t(
                    this._get(e, a.match(/DD/) ? 'dayNames' : 'dayNamesShort')
                  ) +
                    20 -
                    r.getDay()
                )),
                e.input.attr('size', this._formatDate(e, r).length);
            }
          },
          _inlineDatepicker: function (t, i) {
            var n = e(t);
            n.hasClass(this.markerClassName) ||
              (n.addClass(this.markerClassName).append(i.dpDiv),
              e.data(t, 'datepicker', i),
              this._setDate(i, this._getDefaultDate(i), !0),
              this._updateDatepicker(i),
              this._updateAlternate(i),
              i.settings.disabled && this._disableDatepicker(t),
              i.dpDiv.css('display', 'block'));
          },
          _dialogDatepicker: function (t, i, n, o, a) {
            var s,
              l,
              c,
              d,
              u,
              p = this._dialogInst;
            return (
              p ||
                ((this.uuid += 1),
                (s = 'dp' + this.uuid),
                (this._dialogInput = e(
                  "<input type='text' id='" +
                    s +
                    "' style='position: absolute; top: -100px; width: 0px;'/>"
                )),
                this._dialogInput.on('keydown', this._doKeyDown),
                e('body').append(this._dialogInput),
                (p = this._dialogInst = this._newInst(this._dialogInput, !1)),
                (p.settings = {}),
                e.data(this._dialogInput[0], 'datepicker', p)),
              r(p.settings, o || {}),
              (i = i && i.constructor === Date ? this._formatDate(p, i) : i),
              this._dialogInput.val(i),
              (this._pos = a ? (a.length ? a : [a.pageX, a.pageY]) : null),
              this._pos ||
                ((l = document.documentElement.clientWidth),
                (c = document.documentElement.clientHeight),
                (d =
                  document.documentElement.scrollLeft ||
                  document.body.scrollLeft),
                (u =
                  document.documentElement.scrollTop ||
                  document.body.scrollTop),
                (this._pos = [l / 2 - 100 + d, c / 2 - 150 + u])),
              this._dialogInput
                .css('left', this._pos[0] + 20 + 'px')
                .css('top', this._pos[1] + 'px'),
              (p.settings.onSelect = n),
              (this._inDialog = !0),
              this.dpDiv.addClass(this._dialogClass),
              this._showDatepicker(this._dialogInput[0]),
              e.blockUI && e.blockUI(this.dpDiv),
              e.data(this._dialogInput[0], 'datepicker', p),
              this
            );
          },
          _destroyDatepicker: function (t) {
            var i,
              n = e(t),
              o = e.data(t, 'datepicker');
            n.hasClass(this.markerClassName) &&
              ((i = t.nodeName.toLowerCase()),
              e.removeData(t, 'datepicker'),
              'input' === i
                ? (o.append.remove(),
                  o.trigger.remove(),
                  n
                    .removeClass(this.markerClassName)
                    .off('focus', this._showDatepicker)
                    .off('keydown', this._doKeyDown)
                    .off('keypress', this._doKeyPress)
                    .off('keyup', this._doKeyUp))
                : ('div' === i || 'span' === i) &&
                  n.removeClass(this.markerClassName).empty(),
              a === o && (a = null));
          },
          _enableDatepicker: function (t) {
            var i,
              n,
              o = e(t),
              r = e.data(t, 'datepicker');
            o.hasClass(this.markerClassName) &&
              ((i = t.nodeName.toLowerCase()),
              'input' === i
                ? ((t.disabled = !1),
                  r.trigger
                    .filter('button')
                    .each(function () {
                      this.disabled = !1;
                    })
                    .end()
                    .filter('img')
                    .css({ opacity: '1.0', cursor: '' }))
                : ('div' === i || 'span' === i) &&
                  ((n = o.children('.' + this._inlineClass)),
                  n.children().removeClass('ui-state-disabled'),
                  n
                    .find(
                      'select.ui-datepicker-month, select.ui-datepicker-year'
                    )
                    .prop('disabled', !1)),
              (this._disabledInputs = e.map(this._disabledInputs, function (e) {
                return e === t ? null : e;
              })));
          },
          _disableDatepicker: function (t) {
            var i,
              n,
              o = e(t),
              r = e.data(t, 'datepicker');
            o.hasClass(this.markerClassName) &&
              ((i = t.nodeName.toLowerCase()),
              'input' === i
                ? ((t.disabled = !0),
                  r.trigger
                    .filter('button')
                    .each(function () {
                      this.disabled = !0;
                    })
                    .end()
                    .filter('img')
                    .css({ opacity: '0.5', cursor: 'default' }))
                : ('div' === i || 'span' === i) &&
                  ((n = o.children('.' + this._inlineClass)),
                  n.children().addClass('ui-state-disabled'),
                  n
                    .find(
                      'select.ui-datepicker-month, select.ui-datepicker-year'
                    )
                    .prop('disabled', !0)),
              (this._disabledInputs = e.map(this._disabledInputs, function (e) {
                return e === t ? null : e;
              })),
              (this._disabledInputs[this._disabledInputs.length] = t));
          },
          _isDisabledDatepicker: function (e) {
            if (!e) return !1;
            for (var t = 0; this._disabledInputs.length > t; t++)
              if (this._disabledInputs[t] === e) return !0;
            return !1;
          },
          _getInst: function (t) {
            try {
              return e.data(t, 'datepicker');
            } catch (i) {
              throw 'Missing instance data for this datepicker';
            }
          },
          _optionDatepicker: function (t, i, n) {
            var o,
              a,
              s,
              l,
              c = this._getInst(t);
            return 2 === arguments.length && 'string' == typeof i
              ? 'defaults' === i
                ? e.extend({}, e.datepicker._defaults)
                : c
                ? 'all' === i
                  ? e.extend({}, c.settings)
                  : this._get(c, i)
                : null
              : ((o = i || {}),
                'string' == typeof i && ((o = {}), (o[i] = n)),
                void (
                  c &&
                  (this._curInst === c && this._hideDatepicker(),
                  (a = this._getDateDatepicker(t, !0)),
                  (s = this._getMinMaxDate(c, 'min')),
                  (l = this._getMinMaxDate(c, 'max')),
                  r(c.settings, o),
                  null !== s &&
                    void 0 !== o.dateFormat &&
                    void 0 === o.minDate &&
                    (c.settings.minDate = this._formatDate(c, s)),
                  null !== l &&
                    void 0 !== o.dateFormat &&
                    void 0 === o.maxDate &&
                    (c.settings.maxDate = this._formatDate(c, l)),
                  'disabled' in o &&
                    (o.disabled
                      ? this._disableDatepicker(t)
                      : this._enableDatepicker(t)),
                  this._attachments(e(t), c),
                  this._autoSize(c),
                  this._setDate(c, a),
                  this._updateAlternate(c),
                  this._updateDatepicker(c))
                ));
          },
          _changeDatepicker: function (e, t, i) {
            this._optionDatepicker(e, t, i);
          },
          _refreshDatepicker: function (e) {
            var t = this._getInst(e);
            t && this._updateDatepicker(t);
          },
          _setDateDatepicker: function (e, t) {
            var i = this._getInst(e);
            i &&
              (this._setDate(i, t),
              this._updateDatepicker(i),
              this._updateAlternate(i));
          },
          _getDateDatepicker: function (e, t) {
            var i = this._getInst(e);
            return (
              i && !i.inline && this._setDateFromField(i, t),
              i ? this._getDate(i) : null
            );
          },
          _doKeyDown: function (t) {
            var i,
              n,
              o,
              r = e.datepicker._getInst(t.target),
              a = !0,
              s = r.dpDiv.is('.ui-datepicker-rtl');
            if (((r._keyEvent = !0), e.datepicker._datepickerShowing))
              switch (t.keyCode) {
                case 9:
                  e.datepicker._hideDatepicker(), (a = !1);
                  break;
                case 13:
                  return (
                    (o = e(
                      'td.' +
                        e.datepicker._dayOverClass +
                        ':not(.' +
                        e.datepicker._currentClass +
                        ')',
                      r.dpDiv
                    )),
                    o[0] &&
                      e.datepicker._selectDay(
                        t.target,
                        r.selectedMonth,
                        r.selectedYear,
                        o[0]
                      ),
                    (i = e.datepicker._get(r, 'onSelect')),
                    i
                      ? ((n = e.datepicker._formatDate(r)),
                        i.apply(r.input ? r.input[0] : null, [n, r]))
                      : e.datepicker._hideDatepicker(),
                    !1
                  );
                case 27:
                  e.datepicker._hideDatepicker();
                  break;
                case 33:
                  e.datepicker._adjustDate(
                    t.target,
                    t.ctrlKey
                      ? -e.datepicker._get(r, 'stepBigMonths')
                      : -e.datepicker._get(r, 'stepMonths'),
                    'M'
                  );
                  break;
                case 34:
                  e.datepicker._adjustDate(
                    t.target,
                    t.ctrlKey
                      ? +e.datepicker._get(r, 'stepBigMonths')
                      : +e.datepicker._get(r, 'stepMonths'),
                    'M'
                  );
                  break;
                case 35:
                  (t.ctrlKey || t.metaKey) && e.datepicker._clearDate(t.target),
                    (a = t.ctrlKey || t.metaKey);
                  break;
                case 36:
                  (t.ctrlKey || t.metaKey) && e.datepicker._gotoToday(t.target),
                    (a = t.ctrlKey || t.metaKey);
                  break;
                case 37:
                  (t.ctrlKey || t.metaKey) &&
                    e.datepicker._adjustDate(t.target, s ? 1 : -1, 'D'),
                    (a = t.ctrlKey || t.metaKey),
                    t.originalEvent.altKey &&
                      e.datepicker._adjustDate(
                        t.target,
                        t.ctrlKey
                          ? -e.datepicker._get(r, 'stepBigMonths')
                          : -e.datepicker._get(r, 'stepMonths'),
                        'M'
                      );
                  break;
                case 38:
                  (t.ctrlKey || t.metaKey) &&
                    e.datepicker._adjustDate(t.target, -7, 'D'),
                    (a = t.ctrlKey || t.metaKey);
                  break;
                case 39:
                  (t.ctrlKey || t.metaKey) &&
                    e.datepicker._adjustDate(t.target, s ? -1 : 1, 'D'),
                    (a = t.ctrlKey || t.metaKey),
                    t.originalEvent.altKey &&
                      e.datepicker._adjustDate(
                        t.target,
                        t.ctrlKey
                          ? +e.datepicker._get(r, 'stepBigMonths')
                          : +e.datepicker._get(r, 'stepMonths'),
                        'M'
                      );
                  break;
                case 40:
                  (t.ctrlKey || t.metaKey) &&
                    e.datepicker._adjustDate(t.target, 7, 'D'),
                    (a = t.ctrlKey || t.metaKey);
                  break;
                default:
                  a = !1;
              }
            else
              36 === t.keyCode && t.ctrlKey
                ? e.datepicker._showDatepicker(this)
                : (a = !1);
            a && (t.preventDefault(), t.stopPropagation());
          },
          _doKeyPress: function (t) {
            var i,
              n,
              o = e.datepicker._getInst(t.target);
            return e.datepicker._get(o, 'constrainInput')
              ? ((i = e.datepicker._possibleChars(
                  e.datepicker._get(o, 'dateFormat')
                )),
                (n = String.fromCharCode(
                  null == t.charCode ? t.keyCode : t.charCode
                )),
                t.ctrlKey || t.metaKey || ' ' > n || !i || i.indexOf(n) > -1)
              : void 0;
          },
          _doKeyUp: function (t) {
            var i,
              n = e.datepicker._getInst(t.target);
            if (n.input.val() !== n.lastVal)
              try {
                (i = e.datepicker.parseDate(
                  e.datepicker._get(n, 'dateFormat'),
                  n.input ? n.input.val() : null,
                  e.datepicker._getFormatConfig(n)
                )),
                  i &&
                    (e.datepicker._setDateFromField(n),
                    e.datepicker._updateAlternate(n),
                    e.datepicker._updateDatepicker(n));
              } catch (o) {}
            return !0;
          },
          _showDatepicker: function (i) {
            if (
              ((i = i.target || i),
              'input' !== i.nodeName.toLowerCase() &&
                (i = e('input', i.parentNode)[0]),
              !e.datepicker._isDisabledDatepicker(i) &&
                e.datepicker._lastInput !== i)
            ) {
              var n, o, a, s, l, c, d;
              (n = e.datepicker._getInst(i)),
                e.datepicker._curInst &&
                  e.datepicker._curInst !== n &&
                  (e.datepicker._curInst.dpDiv.stop(!0, !0),
                  n &&
                    e.datepicker._datepickerShowing &&
                    e.datepicker._hideDatepicker(
                      e.datepicker._curInst.input[0]
                    )),
                (o = e.datepicker._get(n, 'beforeShow')),
                (a = o ? o.apply(i, [i, n]) : {}),
                a !== !1 &&
                  (r(n.settings, a),
                  (n.lastVal = null),
                  (e.datepicker._lastInput = i),
                  e.datepicker._setDateFromField(n),
                  e.datepicker._inDialog && (i.value = ''),
                  e.datepicker._pos ||
                    ((e.datepicker._pos = e.datepicker._findPos(i)),
                    (e.datepicker._pos[1] += i.offsetHeight)),
                  (s = !1),
                  e(i)
                    .parents()
                    .each(function () {
                      return (s |= 'fixed' === e(this).css('position')), !s;
                    }),
                  (l = {
                    left: e.datepicker._pos[0],
                    top: e.datepicker._pos[1],
                  }),
                  (e.datepicker._pos = null),
                  n.dpDiv.empty(),
                  n.dpDiv.css({
                    position: 'absolute',
                    display: 'block',
                    top: '-1000px',
                  }),
                  e.datepicker._updateDatepicker(n),
                  (l = e.datepicker._checkOffset(n, l, s)),
                  n.dpDiv.css({
                    position:
                      e.datepicker._inDialog && e.blockUI
                        ? 'static'
                        : s
                        ? 'fixed'
                        : 'absolute',
                    display: 'none',
                    left: l.left + 'px',
                    top: l.top + 'px',
                  }),
                  n.inline ||
                    ((c = e.datepicker._get(n, 'showAnim')),
                    (d = e.datepicker._get(n, 'duration')),
                    n.dpDiv.css('z-index', t(e(i)) + 1),
                    (e.datepicker._datepickerShowing = !0),
                    e.effects && e.effects.effect[c]
                      ? n.dpDiv.show(c, e.datepicker._get(n, 'showOptions'), d)
                      : n.dpDiv[c || 'show'](c ? d : null),
                    e.datepicker._shouldFocusInput(n) &&
                      n.input.trigger('focus'),
                    (e.datepicker._curInst = n)));
            }
          },
          _updateDatepicker: function (t) {
            (this.maxRows = 4),
              (a = t),
              t.dpDiv.empty().append(this._generateHTML(t)),
              this._attachHandlers(t);
            var i,
              n = this._getNumberOfMonths(t),
              r = n[1],
              s = 17,
              l = t.dpDiv.find('.' + this._dayOverClass + ' a');
            l.length > 0 && o.apply(l.get(0)),
              t.dpDiv
                .removeClass(
                  'ui-datepicker-multi-2 ui-datepicker-multi-3 ui-datepicker-multi-4'
                )
                .width(''),
              r > 1 &&
                t.dpDiv
                  .addClass('ui-datepicker-multi-' + r)
                  .css('width', s * r + 'em'),
              t.dpDiv[(1 !== n[0] || 1 !== n[1] ? 'add' : 'remove') + 'Class'](
                'ui-datepicker-multi'
              ),
              t.dpDiv[(this._get(t, 'isRTL') ? 'add' : 'remove') + 'Class'](
                'ui-datepicker-rtl'
              ),
              t === e.datepicker._curInst &&
                e.datepicker._datepickerShowing &&
                e.datepicker._shouldFocusInput(t) &&
                t.input.trigger('focus'),
              t.yearshtml &&
                ((i = t.yearshtml),
                setTimeout(function () {
                  i === t.yearshtml &&
                    t.yearshtml &&
                    t.dpDiv
                      .find('select.ui-datepicker-year:first')
                      .replaceWith(t.yearshtml),
                    (i = t.yearshtml = null);
                }, 0));
          },
          _shouldFocusInput: function (e) {
            return (
              e.input &&
              e.input.is(':visible') &&
              !e.input.is(':disabled') &&
              !e.input.is(':focus')
            );
          },
          _checkOffset: function (t, i, n) {
            var o = t.dpDiv.outerWidth(),
              r = t.dpDiv.outerHeight(),
              a = t.input ? t.input.outerWidth() : 0,
              s = t.input ? t.input.outerHeight() : 0,
              l =
                document.documentElement.clientWidth +
                (n ? 0 : e(document).scrollLeft()),
              c =
                document.documentElement.clientHeight +
                (n ? 0 : e(document).scrollTop());
            return (
              (i.left -= this._get(t, 'isRTL') ? o - a : 0),
              (i.left -=
                n && i.left === t.input.offset().left
                  ? e(document).scrollLeft()
                  : 0),
              (i.top -=
                n && i.top === t.input.offset().top + s
                  ? e(document).scrollTop()
                  : 0),
              (i.left -= Math.min(
                i.left,
                i.left + o > l && l > o ? Math.abs(i.left + o - l) : 0
              )),
              (i.top -= Math.min(
                i.top,
                i.top + r > c && c > r ? Math.abs(r + s) : 0
              )),
              i
            );
          },
          _findPos: function (t) {
            for (
              var i, n = this._getInst(t), o = this._get(n, 'isRTL');
              t &&
              ('hidden' === t.type ||
                1 !== t.nodeType ||
                e.expr.filters.hidden(t));

            )
              t = t[o ? 'previousSibling' : 'nextSibling'];
            return (i = e(t).offset()), [i.left, i.top];
          },
          _hideDatepicker: function (t) {
            var i,
              n,
              o,
              r,
              a = this._curInst;
            !a ||
              (t && a !== e.data(t, 'datepicker')) ||
              (this._datepickerShowing &&
                ((i = this._get(a, 'showAnim')),
                (n = this._get(a, 'duration')),
                (o = function () {
                  e.datepicker._tidyDialog(a);
                }),
                e.effects && (e.effects.effect[i] || e.effects[i])
                  ? a.dpDiv.hide(i, e.datepicker._get(a, 'showOptions'), n, o)
                  : a.dpDiv[
                      'slideDown' === i
                        ? 'slideUp'
                        : 'fadeIn' === i
                        ? 'fadeOut'
                        : 'hide'
                    ](i ? n : null, o),
                i || o(),
                (this._datepickerShowing = !1),
                (r = this._get(a, 'onClose')),
                r &&
                  r.apply(a.input ? a.input[0] : null, [
                    a.input ? a.input.val() : '',
                    a,
                  ]),
                (this._lastInput = null),
                this._inDialog &&
                  (this._dialogInput.css({
                    position: 'absolute',
                    left: '0',
                    top: '-100px',
                  }),
                  e.blockUI && (e.unblockUI(), e('body').append(this.dpDiv))),
                (this._inDialog = !1)));
          },
          _tidyDialog: function (e) {
            e.dpDiv
              .removeClass(this._dialogClass)
              .off('.ui-datepicker-calendar');
          },
          _checkExternalClick: function (t) {
            if (e.datepicker._curInst) {
              var i = e(t.target),
                n = e.datepicker._getInst(i[0]);
              ((i[0].id !== e.datepicker._mainDivId &&
                0 === i.parents('#' + e.datepicker._mainDivId).length &&
                !i.hasClass(e.datepicker.markerClassName) &&
                !i.closest('.' + e.datepicker._triggerClass).length &&
                e.datepicker._datepickerShowing &&
                (!e.datepicker._inDialog || !e.blockUI)) ||
                (i.hasClass(e.datepicker.markerClassName) &&
                  e.datepicker._curInst !== n)) &&
                e.datepicker._hideDatepicker();
            }
          },
          _adjustDate: function (t, i, n) {
            var o = e(t),
              r = this._getInst(o[0]);
            this._isDisabledDatepicker(o[0]) ||
              (this._adjustInstDate(
                r,
                i + ('M' === n ? this._get(r, 'showCurrentAtPos') : 0),
                n
              ),
              this._updateDatepicker(r));
          },
          _gotoToday: function (t) {
            var i,
              n = e(t),
              o = this._getInst(n[0]);
            this._get(o, 'gotoCurrent') && o.currentDay
              ? ((o.selectedDay = o.currentDay),
                (o.drawMonth = o.selectedMonth = o.currentMonth),
                (o.drawYear = o.selectedYear = o.currentYear))
              : ((i = new Date()),
                (o.selectedDay = i.getDate()),
                (o.drawMonth = o.selectedMonth = i.getMonth()),
                (o.drawYear = o.selectedYear = i.getFullYear())),
              this._notifyChange(o),
              this._adjustDate(n);
          },
          _selectMonthYear: function (t, i, n) {
            var o = e(t),
              r = this._getInst(o[0]);
            (r['selected' + ('M' === n ? 'Month' : 'Year')] = r[
              'draw' + ('M' === n ? 'Month' : 'Year')
            ] =
              parseInt(i.options[i.selectedIndex].value, 10)),
              this._notifyChange(r),
              this._adjustDate(o);
          },
          _selectDay: function (t, i, n, o) {
            var r,
              a = e(t);
            e(o).hasClass(this._unselectableClass) ||
              this._isDisabledDatepicker(a[0]) ||
              ((r = this._getInst(a[0])),
              (r.selectedDay = r.currentDay = e('a', o).html()),
              (r.selectedMonth = r.currentMonth = i),
              (r.selectedYear = r.currentYear = n),
              this._selectDate(
                t,
                this._formatDate(r, r.currentDay, r.currentMonth, r.currentYear)
              ));
          },
          _clearDate: function (t) {
            var i = e(t);
            this._selectDate(i, '');
          },
          _selectDate: function (t, i) {
            var n,
              o = e(t),
              r = this._getInst(o[0]);
            (i = null != i ? i : this._formatDate(r)),
              r.input && r.input.val(i),
              this._updateAlternate(r),
              (n = this._get(r, 'onSelect')),
              n
                ? n.apply(r.input ? r.input[0] : null, [i, r])
                : r.input && r.input.trigger('change'),
              r.inline
                ? this._updateDatepicker(r)
                : (this._hideDatepicker(),
                  (this._lastInput = r.input[0]),
                  'object' != typeof r.input[0] && r.input.trigger('focus'),
                  (this._lastInput = null));
          },
          _updateAlternate: function (t) {
            var i,
              n,
              o,
              r = this._get(t, 'altField');
            r &&
              ((i = this._get(t, 'altFormat') || this._get(t, 'dateFormat')),
              (n = this._getDate(t)),
              (o = this.formatDate(i, n, this._getFormatConfig(t))),
              e(r).val(o));
          },
          noWeekends: function (e) {
            var t = e.getDay();
            return [t > 0 && 6 > t, ''];
          },
          iso8601Week: function (e) {
            var t,
              i = new Date(e.getTime());
            return (
              i.setDate(i.getDate() + 4 - (i.getDay() || 7)),
              (t = i.getTime()),
              i.setMonth(0),
              i.setDate(1),
              Math.floor(Math.round((t - i) / 864e5) / 7) + 1
            );
          },
          parseDate: function (t, i, n) {
            if (null == t || null == i) throw 'Invalid arguments';
            if (((i = 'object' == typeof i ? '' + i : i + ''), '' === i))
              return null;
            var o,
              r,
              a,
              s,
              l = 0,
              c =
                (n ? n.shortYearCutoff : null) ||
                this._defaults.shortYearCutoff,
              d =
                'string' != typeof c
                  ? c
                  : (new Date().getFullYear() % 100) + parseInt(c, 10),
              u = (n ? n.dayNamesShort : null) || this._defaults.dayNamesShort,
              p = (n ? n.dayNames : null) || this._defaults.dayNames,
              f =
                (n ? n.monthNamesShort : null) ||
                this._defaults.monthNamesShort,
              h = (n ? n.monthNames : null) || this._defaults.monthNames,
              g = -1,
              m = -1,
              v = -1,
              y = -1,
              w = !1,
              b = function (e) {
                var i = t.length > o + 1 && t.charAt(o + 1) === e;
                return i && o++, i;
              },
              k = function (e) {
                var t = b(e),
                  n =
                    '@' === e
                      ? 14
                      : '!' === e
                      ? 20
                      : 'y' === e && t
                      ? 4
                      : 'o' === e
                      ? 3
                      : 2,
                  o = 'y' === e ? n : 1,
                  r = RegExp('^\\d{' + o + ',' + n + '}'),
                  a = i.substring(l).match(r);
                if (!a) throw 'Missing number at position ' + l;
                return (l += a[0].length), parseInt(a[0], 10);
              },
              S = function (t, n, o) {
                var r = -1,
                  a = e
                    .map(b(t) ? o : n, function (e, t) {
                      return [[t, e]];
                    })
                    .sort(function (e, t) {
                      return -(e[1].length - t[1].length);
                    });
                if (
                  (e.each(a, function (e, t) {
                    var n = t[1];
                    return i.substr(l, n.length).toLowerCase() ===
                      n.toLowerCase()
                      ? ((r = t[0]), (l += n.length), !1)
                      : void 0;
                  }),
                  -1 !== r)
                )
                  return r + 1;
                throw 'Unknown name at position ' + l;
              },
              x = function () {
                if (i.charAt(l) !== t.charAt(o))
                  throw 'Unexpected literal at position ' + l;
                l++;
              };
            for (o = 0; t.length > o; o++)
              if (w) "'" !== t.charAt(o) || b("'") ? x() : (w = !1);
              else
                switch (t.charAt(o)) {
                  case 'd':
                    v = k('d');
                    break;
                  case 'D':
                    S('D', u, p);
                    break;
                  case 'o':
                    y = k('o');
                    break;
                  case 'm':
                    m = k('m');
                    break;
                  case 'M':
                    m = S('M', f, h);
                    break;
                  case 'y':
                    g = k('y');
                    break;
                  case '@':
                    (s = new Date(k('@'))),
                      (g = s.getFullYear()),
                      (m = s.getMonth() + 1),
                      (v = s.getDate());
                    break;
                  case '!':
                    (s = new Date((k('!') - this._ticksTo1970) / 1e4)),
                      (g = s.getFullYear()),
                      (m = s.getMonth() + 1),
                      (v = s.getDate());
                    break;
                  case "'":
                    b("'") ? x() : (w = !0);
                    break;
                  default:
                    x();
                }
            if (i.length > l && ((a = i.substr(l)), !/^\s+/.test(a)))
              throw 'Extra/unparsed characters found in date: ' + a;
            if (
              (-1 === g
                ? (g = new Date().getFullYear())
                : 100 > g &&
                  (g +=
                    new Date().getFullYear() -
                    (new Date().getFullYear() % 100) +
                    (d >= g ? 0 : -100)),
              y > -1)
            )
              for (
                m = 1, v = y;
                (r = this._getDaysInMonth(g, m - 1)), !(r >= v);

              )
                m++, (v -= r);
            if (
              ((s = this._daylightSavingAdjust(new Date(g, m - 1, v))),
              s.getFullYear() !== g ||
                s.getMonth() + 1 !== m ||
                s.getDate() !== v)
            )
              throw 'Invalid date';
            return s;
          },
          ATOM: 'yy-mm-dd',
          COOKIE: 'D, dd M yy',
          ISO_8601: 'yy-mm-dd',
          RFC_822: 'D, d M y',
          RFC_850: 'DD, dd-M-y',
          RFC_1036: 'D, d M y',
          RFC_1123: 'D, d M yy',
          RFC_2822: 'D, d M yy',
          RSS: 'D, d M y',
          TICKS: '!',
          TIMESTAMP: '@',
          W3C: 'yy-mm-dd',
          _ticksTo1970:
            864e9 *
            (718685 + Math.floor(492.5) - Math.floor(19.7) + Math.floor(4.925)),
          formatDate: function (e, t, i) {
            if (!t) return '';
            var n,
              o = (i ? i.dayNamesShort : null) || this._defaults.dayNamesShort,
              r = (i ? i.dayNames : null) || this._defaults.dayNames,
              a =
                (i ? i.monthNamesShort : null) ||
                this._defaults.monthNamesShort,
              s = (i ? i.monthNames : null) || this._defaults.monthNames,
              l = function (t) {
                var i = e.length > n + 1 && e.charAt(n + 1) === t;
                return i && n++, i;
              },
              c = function (e, t, i) {
                var n = '' + t;
                if (l(e)) for (; i > n.length; ) n = '0' + n;
                return n;
              },
              d = function (e, t, i, n) {
                return l(e) ? n[t] : i[t];
              },
              u = '',
              p = !1;
            if (t)
              for (n = 0; e.length > n; n++)
                if (p)
                  "'" !== e.charAt(n) || l("'") ? (u += e.charAt(n)) : (p = !1);
                else
                  switch (e.charAt(n)) {
                    case 'd':
                      u += c('d', t.getDate(), 2);
                      break;
                    case 'D':
                      u += d('D', t.getDay(), o, r);
                      break;
                    case 'o':
                      u += c(
                        'o',
                        Math.round(
                          (new Date(
                            t.getFullYear(),
                            t.getMonth(),
                            t.getDate()
                          ).getTime() -
                            new Date(t.getFullYear(), 0, 0).getTime()) /
                            864e5
                        ),
                        3
                      );
                      break;
                    case 'm':
                      u += c('m', t.getMonth() + 1, 2);
                      break;
                    case 'M':
                      u += d('M', t.getMonth(), a, s);
                      break;
                    case 'y':
                      u += l('y')
                        ? t.getFullYear()
                        : (10 > t.getFullYear() % 100 ? '0' : '') +
                          (t.getFullYear() % 100);
                      break;
                    case '@':
                      u += t.getTime();
                      break;
                    case '!':
                      u += 1e4 * t.getTime() + this._ticksTo1970;
                      break;
                    case "'":
                      l("'") ? (u += "'") : (p = !0);
                      break;
                    default:
                      u += e.charAt(n);
                  }
            return u;
          },
          _possibleChars: function (e) {
            var t,
              i = '',
              n = !1,
              o = function (i) {
                var n = e.length > t + 1 && e.charAt(t + 1) === i;
                return n && t++, n;
              };
            for (t = 0; e.length > t; t++)
              if (n)
                "'" !== e.charAt(t) || o("'") ? (i += e.charAt(t)) : (n = !1);
              else
                switch (e.charAt(t)) {
                  case 'd':
                  case 'm':
                  case 'y':
                  case '@':
                    i += '0123456789';
                    break;
                  case 'D':
                  case 'M':
                    return null;
                  case "'":
                    o("'") ? (i += "'") : (n = !0);
                    break;
                  default:
                    i += e.charAt(t);
                }
            return i;
          },
          _get: function (e, t) {
            return void 0 !== e.settings[t] ? e.settings[t] : this._defaults[t];
          },
          _setDateFromField: function (e, t) {
            if (e.input.val() !== e.lastVal) {
              var i = this._get(e, 'dateFormat'),
                n = (e.lastVal = e.input ? e.input.val() : null),
                o = this._getDefaultDate(e),
                r = o,
                a = this._getFormatConfig(e);
              try {
                r = this.parseDate(i, n, a) || o;
              } catch (s) {
                n = t ? '' : n;
              }
              (e.selectedDay = r.getDate()),
                (e.drawMonth = e.selectedMonth = r.getMonth()),
                (e.drawYear = e.selectedYear = r.getFullYear()),
                (e.currentDay = n ? r.getDate() : 0),
                (e.currentMonth = n ? r.getMonth() : 0),
                (e.currentYear = n ? r.getFullYear() : 0),
                this._adjustInstDate(e);
            }
          },
          _getDefaultDate: function (e) {
            return this._restrictMinMax(
              e,
              this._determineDate(e, this._get(e, 'defaultDate'), new Date())
            );
          },
          _determineDate: function (t, i, n) {
            var o = function (e) {
                var t = new Date();
                return t.setDate(t.getDate() + e), t;
              },
              r = function (i) {
                try {
                  return e.datepicker.parseDate(
                    e.datepicker._get(t, 'dateFormat'),
                    i,
                    e.datepicker._getFormatConfig(t)
                  );
                } catch (n) {}
                for (
                  var o =
                      (i.toLowerCase().match(/^c/)
                        ? e.datepicker._getDate(t)
                        : null) || new Date(),
                    r = o.getFullYear(),
                    a = o.getMonth(),
                    s = o.getDate(),
                    l = /([+\-]?[0-9]+)\s*(d|D|w|W|m|M|y|Y)?/g,
                    c = l.exec(i);
                  c;

                ) {
                  switch (c[2] || 'd') {
                    case 'd':
                    case 'D':
                      s += parseInt(c[1], 10);
                      break;
                    case 'w':
                    case 'W':
                      s += 7 * parseInt(c[1], 10);
                      break;
                    case 'm':
                    case 'M':
                      (a += parseInt(c[1], 10)),
                        (s = Math.min(s, e.datepicker._getDaysInMonth(r, a)));
                      break;
                    case 'y':
                    case 'Y':
                      (r += parseInt(c[1], 10)),
                        (s = Math.min(s, e.datepicker._getDaysInMonth(r, a)));
                  }
                  c = l.exec(i);
                }
                return new Date(r, a, s);
              },
              a =
                null == i || '' === i
                  ? n
                  : 'string' == typeof i
                  ? r(i)
                  : 'number' == typeof i
                  ? isNaN(i)
                    ? n
                    : o(i)
                  : new Date(i.getTime());
            return (
              (a = a && 'Invalid Date' == '' + a ? n : a),
              a &&
                (a.setHours(0),
                a.setMinutes(0),
                a.setSeconds(0),
                a.setMilliseconds(0)),
              this._daylightSavingAdjust(a)
            );
          },
          _daylightSavingAdjust: function (e) {
            return e
              ? (e.setHours(e.getHours() > 12 ? e.getHours() + 2 : 0), e)
              : null;
          },
          _setDate: function (e, t, i) {
            var n = !t,
              o = e.selectedMonth,
              r = e.selectedYear,
              a = this._restrictMinMax(
                e,
                this._determineDate(e, t, new Date())
              );
            (e.selectedDay = e.currentDay = a.getDate()),
              (e.drawMonth = e.selectedMonth = e.currentMonth = a.getMonth()),
              (e.drawYear = e.selectedYear = e.currentYear = a.getFullYear()),
              (o === e.selectedMonth && r === e.selectedYear) ||
                i ||
                this._notifyChange(e),
              this._adjustInstDate(e),
              e.input && e.input.val(n ? '' : this._formatDate(e));
          },
          _getDate: function (e) {
            var t =
              !e.currentYear || (e.input && '' === e.input.val())
                ? null
                : this._daylightSavingAdjust(
                    new Date(e.currentYear, e.currentMonth, e.currentDay)
                  );
            return t;
          },
          _attachHandlers: function (t) {
            var i = this._get(t, 'stepMonths'),
              n = '#' + t.id.replace(/\\\\/g, '\\');
            t.dpDiv.find('[data-handler]').map(function () {
              var t = {
                prev: function () {
                  e.datepicker._adjustDate(n, -i, 'M');
                },
                next: function () {
                  e.datepicker._adjustDate(n, +i, 'M');
                },
                hide: function () {
                  e.datepicker._hideDatepicker();
                },
                today: function () {
                  e.datepicker._gotoToday(n);
                },
                selectDay: function () {
                  return (
                    e.datepicker._selectDay(
                      n,
                      +this.getAttribute('data-month'),
                      +this.getAttribute('data-year'),
                      this
                    ),
                    !1
                  );
                },
                selectMonth: function () {
                  return e.datepicker._selectMonthYear(n, this, 'M'), !1;
                },
                selectYear: function () {
                  return e.datepicker._selectMonthYear(n, this, 'Y'), !1;
                },
              };
              e(this).on(
                this.getAttribute('data-event'),
                t[this.getAttribute('data-handler')]
              );
            });
          },
          _generateHTML: function (e) {
            var t,
              i,
              n,
              o,
              r,
              a,
              s,
              l,
              c,
              d,
              u,
              p,
              f,
              h,
              g,
              m,
              v,
              y,
              w,
              b,
              k,
              S,
              x,
              T,
              C,
              D,
              I,
              _,
              $,
              M,
              E,
              j,
              A,
              N,
              H,
              O,
              P,
              L,
              F,
              q = new Date(),
              B = this._daylightSavingAdjust(
                new Date(q.getFullYear(), q.getMonth(), q.getDate())
              ),
              W = this._get(e, 'isRTL'),
              R = this._get(e, 'showButtonPanel'),
              z = this._get(e, 'hideIfNoPrevNext'),
              Y = this._get(e, 'navigationAsDateFormat'),
              U = this._getNumberOfMonths(e),
              Q = this._get(e, 'showCurrentAtPos'),
              K = this._get(e, 'stepMonths'),
              X = 1 !== U[0] || 1 !== U[1],
              V = this._daylightSavingAdjust(
                e.currentDay
                  ? new Date(e.currentYear, e.currentMonth, e.currentDay)
                  : new Date(9999, 9, 9)
              ),
              G = this._getMinMaxDate(e, 'min'),
              J = this._getMinMaxDate(e, 'max'),
              Z = e.drawMonth - Q,
              ee = e.drawYear;
            if ((0 > Z && ((Z += 12), ee--), J))
              for (
                t = this._daylightSavingAdjust(
                  new Date(
                    J.getFullYear(),
                    J.getMonth() - U[0] * U[1] + 1,
                    J.getDate()
                  )
                ),
                  t = G && G > t ? G : t;
                this._daylightSavingAdjust(new Date(ee, Z, 1)) > t;

              )
                Z--, 0 > Z && ((Z = 11), ee--);
            for (
              e.drawMonth = Z,
                e.drawYear = ee,
                i = this._get(e, 'prevText'),
                i = Y
                  ? this.formatDate(
                      i,
                      this._daylightSavingAdjust(new Date(ee, Z - K, 1)),
                      this._getFormatConfig(e)
                    )
                  : i,
                n = this._canAdjustMonth(e, -1, ee, Z)
                  ? "<a class='ui-datepicker-prev ui-corner-all' data-handler='prev' data-event='click' title='" +
                    i +
                    "'><span class='ui-icon ui-icon-circle-triangle-" +
                    (W ? 'e' : 'w') +
                    "'>" +
                    i +
                    '</span></a>'
                  : z
                  ? ''
                  : "<a class='ui-datepicker-prev ui-corner-all ui-state-disabled' title='" +
                    i +
                    "'><span class='ui-icon ui-icon-circle-triangle-" +
                    (W ? 'e' : 'w') +
                    "'>" +
                    i +
                    '</span></a>',
                o = this._get(e, 'nextText'),
                o = Y
                  ? this.formatDate(
                      o,
                      this._daylightSavingAdjust(new Date(ee, Z + K, 1)),
                      this._getFormatConfig(e)
                    )
                  : o,
                r = this._canAdjustMonth(e, 1, ee, Z)
                  ? "<a class='ui-datepicker-next ui-corner-all' data-handler='next' data-event='click' title='" +
                    o +
                    "'><span class='ui-icon ui-icon-circle-triangle-" +
                    (W ? 'w' : 'e') +
                    "'>" +
                    o +
                    '</span></a>'
                  : z
                  ? ''
                  : "<a class='ui-datepicker-next ui-corner-all ui-state-disabled' title='" +
                    o +
                    "'><span class='ui-icon ui-icon-circle-triangle-" +
                    (W ? 'w' : 'e') +
                    "'>" +
                    o +
                    '</span></a>',
                a = this._get(e, 'currentText'),
                s = this._get(e, 'gotoCurrent') && e.currentDay ? V : B,
                a = Y ? this.formatDate(a, s, this._getFormatConfig(e)) : a,
                l = e.inline
                  ? ''
                  : "<button type='button' class='ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all' data-handler='hide' data-event='click'>" +
                    this._get(e, 'closeText') +
                    '</button>',
                c = R
                  ? "<div class='ui-datepicker-buttonpane ui-widget-content'>" +
                    (W ? l : '') +
                    (this._isInRange(e, s)
                      ? "<button type='button' class='ui-datepicker-current ui-state-default ui-priority-secondary ui-corner-all' data-handler='today' data-event='click'>" +
                        a +
                        '</button>'
                      : '') +
                    (W ? '' : l) +
                    '</div>'
                  : '',
                d = parseInt(this._get(e, 'firstDay'), 10),
                d = isNaN(d) ? 0 : d,
                u = this._get(e, 'showWeek'),
                p = this._get(e, 'dayNames'),
                f = this._get(e, 'dayNamesMin'),
                h = this._get(e, 'monthNames'),
                g = this._get(e, 'monthNamesShort'),
                m = this._get(e, 'beforeShowDay'),
                v = this._get(e, 'showOtherMonths'),
                y = this._get(e, 'selectOtherMonths'),
                w = this._getDefaultDate(e),
                b = '',
                S = 0;
              U[0] > S;
              S++
            ) {
              for (x = '', this.maxRows = 4, T = 0; U[1] > T; T++) {
                if (
                  ((C = this._daylightSavingAdjust(
                    new Date(ee, Z, e.selectedDay)
                  )),
                  (D = ' ui-corner-all'),
                  (I = ''),
                  X)
                ) {
                  if (((I += "<div class='ui-datepicker-group"), U[1] > 1))
                    switch (T) {
                      case 0:
                        (I += ' ui-datepicker-group-first'),
                          (D = ' ui-corner-' + (W ? 'right' : 'left'));
                        break;
                      case U[1] - 1:
                        (I += ' ui-datepicker-group-last'),
                          (D = ' ui-corner-' + (W ? 'left' : 'right'));
                        break;
                      default:
                        (I += ' ui-datepicker-group-middle'), (D = '');
                    }
                  I += "'>";
                }
                for (
                  I +=
                    "<div class='ui-datepicker-header ui-widget-header ui-helper-clearfix" +
                    D +
                    "'>" +
                    (/all|left/.test(D) && 0 === S ? (W ? r : n) : '') +
                    (/all|right/.test(D) && 0 === S ? (W ? n : r) : '') +
                    this._generateMonthYearHeader(
                      e,
                      Z,
                      ee,
                      G,
                      J,
                      S > 0 || T > 0,
                      h,
                      g
                    ) +
                    "</div><table class='ui-datepicker-calendar'><thead><tr>",
                    _ = u
                      ? "<th class='ui-datepicker-week-col'>" +
                        this._get(e, 'weekHeader') +
                        '</th>'
                      : '',
                    k = 0;
                  7 > k;
                  k++
                )
                  ($ = (k + d) % 7),
                    (_ +=
                      "<th scope='col'" +
                      ((k + d + 6) % 7 >= 5
                        ? " class='ui-datepicker-week-end'"
                        : '') +
                      "><span title='" +
                      p[$] +
                      "'>" +
                      f[$] +
                      '</span></th>');
                for (
                  I += _ + '</tr></thead><tbody>',
                    M = this._getDaysInMonth(ee, Z),
                    ee === e.selectedYear &&
                      Z === e.selectedMonth &&
                      (e.selectedDay = Math.min(e.selectedDay, M)),
                    E = (this._getFirstDayOfMonth(ee, Z) - d + 7) % 7,
                    j = Math.ceil((E + M) / 7),
                    A = X && this.maxRows > j ? this.maxRows : j,
                    this.maxRows = A,
                    N = this._daylightSavingAdjust(new Date(ee, Z, 1 - E)),
                    H = 0;
                  A > H;
                  H++
                ) {
                  for (
                    I += '<tr>',
                      O = u
                        ? "<td class='ui-datepicker-week-col'>" +
                          this._get(e, 'calculateWeek')(N) +
                          '</td>'
                        : '',
                      k = 0;
                    7 > k;
                    k++
                  )
                    (P = m
                      ? m.apply(e.input ? e.input[0] : null, [N])
                      : [!0, '']),
                      (L = N.getMonth() !== Z),
                      (F = (L && !y) || !P[0] || (G && G > N) || (J && N > J)),
                      (O +=
                        "<td class='" +
                        ((k + d + 6) % 7 >= 5
                          ? ' ui-datepicker-week-end'
                          : '') +
                        (L ? ' ui-datepicker-other-month' : '') +
                        ((N.getTime() === C.getTime() &&
                          Z === e.selectedMonth &&
                          e._keyEvent) ||
                        (w.getTime() === N.getTime() &&
                          w.getTime() === C.getTime())
                          ? ' ' + this._dayOverClass
                          : '') +
                        (F
                          ? ' ' + this._unselectableClass + ' ui-state-disabled'
                          : '') +
                        (L && !v
                          ? ''
                          : ' ' +
                            P[1] +
                            (N.getTime() === V.getTime()
                              ? ' ' + this._currentClass
                              : '') +
                            (N.getTime() === B.getTime()
                              ? ' ui-datepicker-today'
                              : '')) +
                        "'" +
                        ((L && !v) || !P[2]
                          ? ''
                          : " title='" + P[2].replace(/'/g, '&#39;') + "'") +
                        (F
                          ? ''
                          : " data-handler='selectDay' data-event='click' data-month='" +
                            N.getMonth() +
                            "' data-year='" +
                            N.getFullYear() +
                            "'") +
                        '>' +
                        (L && !v
                          ? '&#xa0;'
                          : F
                          ? "<span class='ui-state-default'>" +
                            N.getDate() +
                            '</span>'
                          : "<a class='ui-state-default" +
                            (N.getTime() === B.getTime()
                              ? ' ui-state-highlight'
                              : '') +
                            (N.getTime() === V.getTime()
                              ? ' ui-state-active'
                              : '') +
                            (L ? ' ui-priority-secondary' : '') +
                            "' href='#'>" +
                            N.getDate() +
                            '</a>') +
                        '</td>'),
                      N.setDate(N.getDate() + 1),
                      (N = this._daylightSavingAdjust(N));
                  I += O + '</tr>';
                }
                Z++,
                  Z > 11 && ((Z = 0), ee++),
                  (I +=
                    '</tbody></table>' +
                    (X
                      ? '</div>' +
                        (U[0] > 0 && T === U[1] - 1
                          ? "<div class='ui-datepicker-row-break'></div>"
                          : '')
                      : '')),
                  (x += I);
              }
              b += x;
            }
            return (b += c), (e._keyEvent = !1), b;
          },
          _generateMonthYearHeader: function (e, t, i, n, o, r, a, s) {
            var l,
              c,
              d,
              u,
              p,
              f,
              h,
              g,
              m = this._get(e, 'changeMonth'),
              v = this._get(e, 'changeYear'),
              y = this._get(e, 'showMonthAfterYear'),
              w = "<div class='ui-datepicker-title'>",
              b = '';
            if (r || !m)
              b += "<span class='ui-datepicker-month'>" + a[t] + '</span>';
            else {
              for (
                l = n && n.getFullYear() === i,
                  c = o && o.getFullYear() === i,
                  b +=
                    "<select class='ui-datepicker-month' data-handler='selectMonth' data-event='change'>",
                  d = 0;
                12 > d;
                d++
              )
                (!l || d >= n.getMonth()) &&
                  (!c || o.getMonth() >= d) &&
                  (b +=
                    "<option value='" +
                    d +
                    "'" +
                    (d === t ? " selected='selected'" : '') +
                    '>' +
                    s[d] +
                    '</option>');
              b += '</select>';
            }
            if ((y || (w += b + (!r && m && v ? '' : '&#xa0;')), !e.yearshtml))
              if (((e.yearshtml = ''), r || !v))
                w += "<span class='ui-datepicker-year'>" + i + '</span>';
              else {
                for (
                  u = this._get(e, 'yearRange').split(':'),
                    p = new Date().getFullYear(),
                    f = function (e) {
                      var t = e.match(/c[+\-].*/)
                        ? i + parseInt(e.substring(1), 10)
                        : e.match(/[+\-].*/)
                        ? p + parseInt(e, 10)
                        : parseInt(e, 10);
                      return isNaN(t) ? p : t;
                    },
                    h = f(u[0]),
                    g = Math.max(h, f(u[1] || '')),
                    h = n ? Math.max(h, n.getFullYear()) : h,
                    g = o ? Math.min(g, o.getFullYear()) : g,
                    e.yearshtml +=
                      "<select class='ui-datepicker-year' data-handler='selectYear' data-event='change'>";
                  g >= h;
                  h++
                )
                  e.yearshtml +=
                    "<option value='" +
                    h +
                    "'" +
                    (h === i ? " selected='selected'" : '') +
                    '>' +
                    h +
                    '</option>';
                (e.yearshtml += '</select>'),
                  (w += e.yearshtml),
                  (e.yearshtml = null);
              }
            return (
              (w += this._get(e, 'yearSuffix')),
              y && (w += (!r && m && v ? '' : '&#xa0;') + b),
              (w += '</div>')
            );
          },
          _adjustInstDate: function (e, t, i) {
            var n = e.selectedYear + ('Y' === i ? t : 0),
              o = e.selectedMonth + ('M' === i ? t : 0),
              r =
                Math.min(e.selectedDay, this._getDaysInMonth(n, o)) +
                ('D' === i ? t : 0),
              a = this._restrictMinMax(
                e,
                this._daylightSavingAdjust(new Date(n, o, r))
              );
            (e.selectedDay = a.getDate()),
              (e.drawMonth = e.selectedMonth = a.getMonth()),
              (e.drawYear = e.selectedYear = a.getFullYear()),
              ('M' === i || 'Y' === i) && this._notifyChange(e);
          },
          _restrictMinMax: function (e, t) {
            var i = this._getMinMaxDate(e, 'min'),
              n = this._getMinMaxDate(e, 'max'),
              o = i && i > t ? i : t;
            return n && o > n ? n : o;
          },
          _notifyChange: function (e) {
            var t = this._get(e, 'onChangeMonthYear');
            t &&
              t.apply(e.input ? e.input[0] : null, [
                e.selectedYear,
                e.selectedMonth + 1,
                e,
              ]);
          },
          _getNumberOfMonths: function (e) {
            var t = this._get(e, 'numberOfMonths');
            return null == t ? [1, 1] : 'number' == typeof t ? [1, t] : t;
          },
          _getMinMaxDate: function (e, t) {
            return this._determineDate(e, this._get(e, t + 'Date'), null);
          },
          _getDaysInMonth: function (e, t) {
            return (
              32 - this._daylightSavingAdjust(new Date(e, t, 32)).getDate()
            );
          },
          _getFirstDayOfMonth: function (e, t) {
            return new Date(e, t, 1).getDay();
          },
          _canAdjustMonth: function (e, t, i, n) {
            var o = this._getNumberOfMonths(e),
              r = this._daylightSavingAdjust(
                new Date(i, n + (0 > t ? t : o[0] * o[1]), 1)
              );
            return (
              0 > t &&
                r.setDate(this._getDaysInMonth(r.getFullYear(), r.getMonth())),
              this._isInRange(e, r)
            );
          },
          _isInRange: function (e, t) {
            var i,
              n,
              o = this._getMinMaxDate(e, 'min'),
              r = this._getMinMaxDate(e, 'max'),
              a = null,
              s = null,
              l = this._get(e, 'yearRange');
            return (
              l &&
                ((i = l.split(':')),
                (n = new Date().getFullYear()),
                (a = parseInt(i[0], 10)),
                (s = parseInt(i[1], 10)),
                i[0].match(/[+\-].*/) && (a += n),
                i[1].match(/[+\-].*/) && (s += n)),
              (!o || t.getTime() >= o.getTime()) &&
                (!r || t.getTime() <= r.getTime()) &&
                (!a || t.getFullYear() >= a) &&
                (!s || s >= t.getFullYear())
            );
          },
          _getFormatConfig: function (e) {
            var t = this._get(e, 'shortYearCutoff');
            return (
              (t =
                'string' != typeof t
                  ? t
                  : (new Date().getFullYear() % 100) + parseInt(t, 10)),
              {
                shortYearCutoff: t,
                dayNamesShort: this._get(e, 'dayNamesShort'),
                dayNames: this._get(e, 'dayNames'),
                monthNamesShort: this._get(e, 'monthNamesShort'),
                monthNames: this._get(e, 'monthNames'),
              }
            );
          },
          _formatDate: function (e, t, i, n) {
            t ||
              ((e.currentDay = e.selectedDay),
              (e.currentMonth = e.selectedMonth),
              (e.currentYear = e.selectedYear));
            var o = t
              ? 'object' == typeof t
                ? t
                : this._daylightSavingAdjust(new Date(n, i, t))
              : this._daylightSavingAdjust(
                  new Date(e.currentYear, e.currentMonth, e.currentDay)
                );
            return this.formatDate(
              this._get(e, 'dateFormat'),
              o,
              this._getFormatConfig(e)
            );
          },
        }),
          (e.fn.datepicker = function (t) {
            if (!this.length) return this;
            e.datepicker.initialized ||
              (e(document).on('mousedown', e.datepicker._checkExternalClick),
              (e.datepicker.initialized = !0)),
              0 === e('#' + e.datepicker._mainDivId).length &&
                e('body').append(e.datepicker.dpDiv);
            var i = Array.prototype.slice.call(arguments, 1);
            return 'string' != typeof t ||
              ('isDisabled' !== t && 'getDate' !== t && 'widget' !== t)
              ? 'option' === t &&
                2 === arguments.length &&
                'string' == typeof arguments[1]
                ? e.datepicker['_' + t + 'Datepicker'].apply(
                    e.datepicker,
                    [this[0]].concat(i)
                  )
                : this.each(function () {
                    'string' == typeof t
                      ? e.datepicker['_' + t + 'Datepicker'].apply(
                          e.datepicker,
                          [this].concat(i)
                        )
                      : e.datepicker._attachDatepicker(this, t);
                  })
              : e.datepicker['_' + t + 'Datepicker'].apply(
                  e.datepicker,
                  [this[0]].concat(i)
                );
          }),
          (e.datepicker = new i()),
          (e.datepicker.initialized = !1),
          (e.datepicker.uuid = new Date().getTime()),
          (e.datepicker.version = '1.12.1'),
          e.datepicker;
        var s = 'ui-effects-',
          l = 'ui-effects-style',
          c = 'ui-effects-animated',
          d = e;
        (e.effects = { effect: {} }),
          (function (e, t) {
            function i(e, t, i) {
              var n = u[t.type] || {};
              return null == e
                ? i || !t.def
                  ? null
                  : t.def
                : ((e = n.floor ? ~~e : parseFloat(e)),
                  isNaN(e)
                    ? t.def
                    : n.mod
                    ? (e + n.mod) % n.mod
                    : 0 > e
                    ? 0
                    : e > n.max
                    ? n.max
                    : e);
            }
            function n(i) {
              var n = c(),
                o = (n._rgba = []);
              return (
                (i = i.toLowerCase()),
                h(l, function (e, r) {
                  var a,
                    s = r.re.exec(i),
                    l = s && r.parse(s),
                    c = r.space || 'rgba';
                  return l
                    ? ((a = n[c](l)),
                      (n[d[c].cache] = a[d[c].cache]),
                      (o = n._rgba = a._rgba),
                      !1)
                    : t;
                }),
                o.length
                  ? ('0,0,0,0' === o.join() && e.extend(o, r.transparent), n)
                  : r[i]
              );
            }
            function o(e, t, i) {
              return (
                (i = (i + 1) % 1),
                1 > 6 * i
                  ? e + 6 * (t - e) * i
                  : 1 > 2 * i
                  ? t
                  : 2 > 3 * i
                  ? e + 6 * (t - e) * (2 / 3 - i)
                  : e
              );
            }
            var r,
              a =
                'backgroundColor borderBottomColor borderLeftColor borderRightColor borderTopColor color columnRuleColor outlineColor textDecorationColor textEmphasisColor',
              s = /^([\-+])=\s*(\d+\.?\d*)/,
              l = [
                {
                  re: /rgba?\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*(?:,\s*(\d?(?:\.\d+)?)\s*)?\)/,
                  parse: function (e) {
                    return [e[1], e[2], e[3], e[4]];
                  },
                },
                {
                  re: /rgba?\(\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*(?:,\s*(\d?(?:\.\d+)?)\s*)?\)/,
                  parse: function (e) {
                    return [2.55 * e[1], 2.55 * e[2], 2.55 * e[3], e[4]];
                  },
                },
                {
                  re: /#([a-f0-9]{2})([a-f0-9]{2})([a-f0-9]{2})/,
                  parse: function (e) {
                    return [
                      parseInt(e[1], 16),
                      parseInt(e[2], 16),
                      parseInt(e[3], 16),
                    ];
                  },
                },
                {
                  re: /#([a-f0-9])([a-f0-9])([a-f0-9])/,
                  parse: function (e) {
                    return [
                      parseInt(e[1] + e[1], 16),
                      parseInt(e[2] + e[2], 16),
                      parseInt(e[3] + e[3], 16),
                    ];
                  },
                },
                {
                  re: /hsla?\(\s*(\d+(?:\.\d+)?)\s*,\s*(\d+(?:\.\d+)?)\%\s*,\s*(\d+(?:\.\d+)?)\%\s*(?:,\s*(\d?(?:\.\d+)?)\s*)?\)/,
                  space: 'hsla',
                  parse: function (e) {
                    return [e[1], e[2] / 100, e[3] / 100, e[4]];
                  },
                },
              ],
              c = (e.Color = function (t, i, n, o) {
                return new e.Color.fn.parse(t, i, n, o);
              }),
              d = {
                rgba: {
                  props: {
                    red: { idx: 0, type: 'byte' },
                    green: { idx: 1, type: 'byte' },
                    blue: { idx: 2, type: 'byte' },
                  },
                },
                hsla: {
                  props: {
                    hue: { idx: 0, type: 'degrees' },
                    saturation: { idx: 1, type: 'percent' },
                    lightness: { idx: 2, type: 'percent' },
                  },
                },
              },
              u = {
                byte: { floor: !0, max: 255 },
                percent: { max: 1 },
                degrees: { mod: 360, floor: !0 },
              },
              p = (c.support = {}),
              f = e('<p>')[0],
              h = e.each;
            (f.style.cssText = 'background-color:rgba(1,1,1,.5)'),
              (p.rgba = f.style.backgroundColor.indexOf('rgba') > -1),
              h(d, function (e, t) {
                (t.cache = '_' + e),
                  (t.props.alpha = { idx: 3, type: 'percent', def: 1 });
              }),
              (c.fn = e.extend(c.prototype, {
                parse: function (o, a, s, l) {
                  if (o === t)
                    return (this._rgba = [null, null, null, null]), this;
                  (o.jquery || o.nodeType) && ((o = e(o).css(a)), (a = t));
                  var u = this,
                    p = e.type(o),
                    f = (this._rgba = []);
                  return (
                    a !== t && ((o = [o, a, s, l]), (p = 'array')),
                    'string' === p
                      ? this.parse(n(o) || r._default)
                      : 'array' === p
                      ? (h(d.rgba.props, function (e, t) {
                          f[t.idx] = i(o[t.idx], t);
                        }),
                        this)
                      : 'object' === p
                      ? (o instanceof c
                          ? h(d, function (e, t) {
                              o[t.cache] && (u[t.cache] = o[t.cache].slice());
                            })
                          : h(d, function (t, n) {
                              var r = n.cache;
                              h(n.props, function (e, t) {
                                if (!u[r] && n.to) {
                                  if ('alpha' === e || null == o[e]) return;
                                  u[r] = n.to(u._rgba);
                                }
                                u[r][t.idx] = i(o[e], t, !0);
                              }),
                                u[r] &&
                                  0 > e.inArray(null, u[r].slice(0, 3)) &&
                                  ((u[r][3] = 1),
                                  n.from && (u._rgba = n.from(u[r])));
                            }),
                        this)
                      : t
                  );
                },
                is: function (e) {
                  var i = c(e),
                    n = !0,
                    o = this;
                  return (
                    h(d, function (e, r) {
                      var a,
                        s = i[r.cache];
                      return (
                        s &&
                          ((a = o[r.cache] || (r.to && r.to(o._rgba)) || []),
                          h(r.props, function (e, i) {
                            return null != s[i.idx]
                              ? (n = s[i.idx] === a[i.idx])
                              : t;
                          })),
                        n
                      );
                    }),
                    n
                  );
                },
                _space: function () {
                  var e = [],
                    t = this;
                  return (
                    h(d, function (i, n) {
                      t[n.cache] && e.push(i);
                    }),
                    e.pop()
                  );
                },
                transition: function (e, t) {
                  var n = c(e),
                    o = n._space(),
                    r = d[o],
                    a = 0 === this.alpha() ? c('transparent') : this,
                    s = a[r.cache] || r.to(a._rgba),
                    l = s.slice();
                  return (
                    (n = n[r.cache]),
                    h(r.props, function (e, o) {
                      var r = o.idx,
                        a = s[r],
                        c = n[r],
                        d = u[o.type] || {};
                      null !== c &&
                        (null === a
                          ? (l[r] = c)
                          : (d.mod &&
                              (c - a > d.mod / 2
                                ? (a += d.mod)
                                : a - c > d.mod / 2 && (a -= d.mod)),
                            (l[r] = i((c - a) * t + a, o))));
                    }),
                    this[o](l)
                  );
                },
                blend: function (t) {
                  if (1 === this._rgba[3]) return this;
                  var i = this._rgba.slice(),
                    n = i.pop(),
                    o = c(t)._rgba;
                  return c(
                    e.map(i, function (e, t) {
                      return (1 - n) * o[t] + n * e;
                    })
                  );
                },
                toRgbaString: function () {
                  var t = 'rgba(',
                    i = e.map(this._rgba, function (e, t) {
                      return null == e ? (t > 2 ? 1 : 0) : e;
                    });
                  return (
                    1 === i[3] && (i.pop(), (t = 'rgb(')), t + i.join() + ')'
                  );
                },
                toHslaString: function () {
                  var t = 'hsla(',
                    i = e.map(this.hsla(), function (e, t) {
                      return (
                        null == e && (e = t > 2 ? 1 : 0),
                        t && 3 > t && (e = Math.round(100 * e) + '%'),
                        e
                      );
                    });
                  return (
                    1 === i[3] && (i.pop(), (t = 'hsl(')), t + i.join() + ')'
                  );
                },
                toHexString: function (t) {
                  var i = this._rgba.slice(),
                    n = i.pop();
                  return (
                    t && i.push(~~(255 * n)),
                    '#' +
                      e
                        .map(i, function (e) {
                          return (
                            (e = (e || 0).toString(16)),
                            1 === e.length ? '0' + e : e
                          );
                        })
                        .join('')
                  );
                },
                toString: function () {
                  return 0 === this._rgba[3]
                    ? 'transparent'
                    : this.toRgbaString();
                },
              })),
              (c.fn.parse.prototype = c.fn),
              (d.hsla.to = function (e) {
                if (null == e[0] || null == e[1] || null == e[2])
                  return [null, null, null, e[3]];
                var t,
                  i,
                  n = e[0] / 255,
                  o = e[1] / 255,
                  r = e[2] / 255,
                  a = e[3],
                  s = Math.max(n, o, r),
                  l = Math.min(n, o, r),
                  c = s - l,
                  d = s + l,
                  u = 0.5 * d;
                return (
                  (t =
                    l === s
                      ? 0
                      : n === s
                      ? (60 * (o - r)) / c + 360
                      : o === s
                      ? (60 * (r - n)) / c + 120
                      : (60 * (n - o)) / c + 240),
                  (i = 0 === c ? 0 : 0.5 >= u ? c / d : c / (2 - d)),
                  [Math.round(t) % 360, i, u, null == a ? 1 : a]
                );
              }),
              (d.hsla.from = function (e) {
                if (null == e[0] || null == e[1] || null == e[2])
                  return [null, null, null, e[3]];
                var t = e[0] / 360,
                  i = e[1],
                  n = e[2],
                  r = e[3],
                  a = 0.5 >= n ? n * (1 + i) : n + i - n * i,
                  s = 2 * n - a;
                return [
                  Math.round(255 * o(s, a, t + 1 / 3)),
                  Math.round(255 * o(s, a, t)),
                  Math.round(255 * o(s, a, t - 1 / 3)),
                  r,
                ];
              }),
              h(d, function (n, o) {
                var r = o.props,
                  a = o.cache,
                  l = o.to,
                  d = o.from;
                (c.fn[n] = function (n) {
                  if ((l && !this[a] && (this[a] = l(this._rgba)), n === t))
                    return this[a].slice();
                  var o,
                    s = e.type(n),
                    u = 'array' === s || 'object' === s ? n : arguments,
                    p = this[a].slice();
                  return (
                    h(r, function (e, t) {
                      var n = u['object' === s ? e : t.idx];
                      null == n && (n = p[t.idx]), (p[t.idx] = i(n, t));
                    }),
                    d ? ((o = c(d(p))), (o[a] = p), o) : c(p)
                  );
                }),
                  h(r, function (t, i) {
                    c.fn[t] ||
                      (c.fn[t] = function (o) {
                        var r,
                          a = e.type(o),
                          l =
                            'alpha' === t ? (this._hsla ? 'hsla' : 'rgba') : n,
                          c = this[l](),
                          d = c[i.idx];
                        return 'undefined' === a
                          ? d
                          : ('function' === a &&
                              ((o = o.call(this, d)), (a = e.type(o))),
                            null == o && i.empty
                              ? this
                              : ('string' === a &&
                                  ((r = s.exec(o)),
                                  r &&
                                    (o =
                                      d +
                                      parseFloat(r[2]) *
                                        ('+' === r[1] ? 1 : -1))),
                                (c[i.idx] = o),
                                this[l](c)));
                      });
                  });
              }),
              (c.hook = function (t) {
                var i = t.split(' ');
                h(i, function (t, i) {
                  (e.cssHooks[i] = {
                    set: function (t, o) {
                      var r,
                        a,
                        s = '';
                      if (
                        'transparent' !== o &&
                        ('string' !== e.type(o) || (r = n(o)))
                      ) {
                        if (((o = c(r || o)), !p.rgba && 1 !== o._rgba[3])) {
                          for (
                            a = 'backgroundColor' === i ? t.parentNode : t;
                            ('' === s || 'transparent' === s) && a && a.style;

                          )
                            try {
                              (s = e.css(a, 'backgroundColor')),
                                (a = a.parentNode);
                            } catch (l) {}
                          o = o.blend(
                            s && 'transparent' !== s ? s : '_default'
                          );
                        }
                        o = o.toRgbaString();
                      }
                      try {
                        t.style[i] = o;
                      } catch (l) {}
                    },
                  }),
                    (e.fx.step[i] = function (t) {
                      t.colorInit ||
                        ((t.start = c(t.elem, i)),
                        (t.end = c(t.end)),
                        (t.colorInit = !0)),
                        e.cssHooks[i].set(
                          t.elem,
                          t.start.transition(t.end, t.pos)
                        );
                    });
                });
              }),
              c.hook(a),
              (e.cssHooks.borderColor = {
                expand: function (e) {
                  var t = {};
                  return (
                    h(['Top', 'Right', 'Bottom', 'Left'], function (i, n) {
                      t['border' + n + 'Color'] = e;
                    }),
                    t
                  );
                },
              }),
              (r = e.Color.names =
                {
                  aqua: '#00ffff',
                  black: '#000000',
                  blue: '#0000ff',
                  fuchsia: '#ff00ff',
                  gray: '#808080',
                  green: '#008000',
                  lime: '#00ff00',
                  maroon: '#800000',
                  navy: '#000080',
                  olive: '#808000',
                  purple: '#800080',
                  red: '#ff0000',
                  silver: '#c0c0c0',
                  teal: '#008080',
                  white: '#ffffff',
                  yellow: '#ffff00',
                  transparent: [null, null, null, 0],
                  _default: '#ffffff',
                });
          })(d),
          (function () {
            function t(t) {
              var i,
                n,
                o = t.ownerDocument.defaultView
                  ? t.ownerDocument.defaultView.getComputedStyle(t, null)
                  : t.currentStyle,
                r = {};
              if (o && o.length && o[0] && o[o[0]])
                for (n = o.length; n--; )
                  (i = o[n]),
                    'string' == typeof o[i] && (r[e.camelCase(i)] = o[i]);
              else for (i in o) 'string' == typeof o[i] && (r[i] = o[i]);
              return r;
            }
            function i(t, i) {
              var n,
                r,
                a = {};
              for (n in i)
                (r = i[n]),
                  t[n] !== r &&
                    (o[n] ||
                      ((e.fx.step[n] || !isNaN(parseFloat(r))) && (a[n] = r)));
              return a;
            }
            var n = ['add', 'remove', 'toggle'],
              o = {
                border: 1,
                borderBottom: 1,
                borderColor: 1,
                borderLeft: 1,
                borderRight: 1,
                borderTop: 1,
                borderWidth: 1,
                margin: 1,
                padding: 1,
              };
            e.each(
              [
                'borderLeftStyle',
                'borderRightStyle',
                'borderBottomStyle',
                'borderTopStyle',
              ],
              function (t, i) {
                e.fx.step[i] = function (e) {
                  (('none' !== e.end && !e.setAttr) ||
                    (1 === e.pos && !e.setAttr)) &&
                    (d.style(e.elem, i, e.end), (e.setAttr = !0));
                };
              }
            ),
              e.fn.addBack ||
                (e.fn.addBack = function (e) {
                  return this.add(
                    null == e ? this.prevObject : this.prevObject.filter(e)
                  );
                }),
              (e.effects.animateClass = function (o, r, a, s) {
                var l = e.speed(r, a, s);
                return this.queue(function () {
                  var r,
                    a = e(this),
                    s = a.attr('class') || '',
                    c = l.children ? a.find('*').addBack() : a;
                  (c = c.map(function () {
                    var i = e(this);
                    return { el: i, start: t(this) };
                  })),
                    (r = function () {
                      e.each(n, function (e, t) {
                        o[t] && a[t + 'Class'](o[t]);
                      });
                    }),
                    r(),
                    (c = c.map(function () {
                      return (
                        (this.end = t(this.el[0])),
                        (this.diff = i(this.start, this.end)),
                        this
                      );
                    })),
                    a.attr('class', s),
                    (c = c.map(function () {
                      var t = this,
                        i = e.Deferred(),
                        n = e.extend({}, l, {
                          queue: !1,
                          complete: function () {
                            i.resolve(t);
                          },
                        });
                      return this.el.animate(this.diff, n), i.promise();
                    })),
                    e.when.apply(e, c.get()).done(function () {
                      r(),
                        e.each(arguments, function () {
                          var t = this.el;
                          e.each(this.diff, function (e) {
                            t.css(e, '');
                          });
                        }),
                        l.complete.call(a[0]);
                    });
                });
              }),
              e.fn.extend({
                addClass: (function (t) {
                  return function (i, n, o, r) {
                    return n
                      ? e.effects.animateClass.call(this, { add: i }, n, o, r)
                      : t.apply(this, arguments);
                  };
                })(e.fn.addClass),
                removeClass: (function (t) {
                  return function (i, n, o, r) {
                    return arguments.length > 1
                      ? e.effects.animateClass.call(
                          this,
                          { remove: i },
                          n,
                          o,
                          r
                        )
                      : t.apply(this, arguments);
                  };
                })(e.fn.removeClass),
                toggleClass: (function (t) {
                  return function (i, n, o, r, a) {
                    return 'boolean' == typeof n || void 0 === n
                      ? o
                        ? e.effects.animateClass.call(
                            this,
                            n ? { add: i } : { remove: i },
                            o,
                            r,
                            a
                          )
                        : t.apply(this, arguments)
                      : e.effects.animateClass.call(
                          this,
                          { toggle: i },
                          n,
                          o,
                          r
                        );
                  };
                })(e.fn.toggleClass),
                switchClass: function (t, i, n, o, r) {
                  return e.effects.animateClass.call(
                    this,
                    { add: i, remove: t },
                    n,
                    o,
                    r
                  );
                },
              });
          })(),
          (function () {
            function t(t, i, n, o) {
              return (
                e.isPlainObject(t) && ((i = t), (t = t.effect)),
                (t = { effect: t }),
                null == i && (i = {}),
                e.isFunction(i) && ((o = i), (n = null), (i = {})),
                ('number' == typeof i || e.fx.speeds[i]) &&
                  ((o = n), (n = i), (i = {})),
                e.isFunction(n) && ((o = n), (n = null)),
                i && e.extend(t, i),
                (n = n || i.duration),
                (t.duration = e.fx.off
                  ? 0
                  : 'number' == typeof n
                  ? n
                  : n in e.fx.speeds
                  ? e.fx.speeds[n]
                  : e.fx.speeds._default),
                (t.complete = o || i.complete),
                t
              );
            }
            function i(t) {
              return (
                !(t && 'number' != typeof t && !e.fx.speeds[t]) ||
                ('string' == typeof t && !e.effects.effect[t]) ||
                !!e.isFunction(t) ||
                ('object' == typeof t && !t.effect)
              );
            }
            function n(e, t) {
              var i = t.outerWidth(),
                n = t.outerHeight(),
                o =
                  /^rect\((-?\d*\.?\d*px|-?\d+%|auto),?\s*(-?\d*\.?\d*px|-?\d+%|auto),?\s*(-?\d*\.?\d*px|-?\d+%|auto),?\s*(-?\d*\.?\d*px|-?\d+%|auto)\)$/,
                r = o.exec(e) || ['', 0, i, n, 0];
              return {
                top: parseFloat(r[1]) || 0,
                right: 'auto' === r[2] ? i : parseFloat(r[2]),
                bottom: 'auto' === r[3] ? n : parseFloat(r[3]),
                left: parseFloat(r[4]) || 0,
              };
            }
            e.expr &&
              e.expr.filters &&
              e.expr.filters.animated &&
              (e.expr.filters.animated = (function (t) {
                return function (i) {
                  return !!e(i).data(c) || t(i);
                };
              })(e.expr.filters.animated)),
              e.uiBackCompat !== !1 &&
                e.extend(e.effects, {
                  save: function (e, t) {
                    for (var i = 0, n = t.length; n > i; i++)
                      null !== t[i] && e.data(s + t[i], e[0].style[t[i]]);
                  },
                  restore: function (e, t) {
                    for (var i, n = 0, o = t.length; o > n; n++)
                      null !== t[n] && ((i = e.data(s + t[n])), e.css(t[n], i));
                  },
                  setMode: function (e, t) {
                    return (
                      'toggle' === t && (t = e.is(':hidden') ? 'show' : 'hide'),
                      t
                    );
                  },
                  createWrapper: function (t) {
                    if (t.parent().is('.ui-effects-wrapper')) return t.parent();
                    var i = {
                        width: t.outerWidth(!0),
                        height: t.outerHeight(!0),
                        float: t.css('float'),
                      },
                      n = e('<div></div>')
                        .addClass('ui-effects-wrapper')
                        .css({
                          fontSize: '100%',
                          background: 'transparent',
                          border: 'none',
                          margin: 0,
                          padding: 0,
                        }),
                      o = { width: t.width(), height: t.height() },
                      r = document.activeElement;
                    try {
                      r.id;
                    } catch (a) {
                      r = document.body;
                    }
                    return (
                      t.wrap(n),
                      (t[0] === r || e.contains(t[0], r)) &&
                        e(r).trigger('focus'),
                      (n = t.parent()),
                      'static' === t.css('position')
                        ? (n.css({ position: 'relative' }),
                          t.css({ position: 'relative' }))
                        : (e.extend(i, {
                            position: t.css('position'),
                            zIndex: t.css('z-index'),
                          }),
                          e.each(
                            ['top', 'left', 'bottom', 'right'],
                            function (e, n) {
                              (i[n] = t.css(n)),
                                isNaN(parseInt(i[n], 10)) && (i[n] = 'auto');
                            }
                          ),
                          t.css({
                            position: 'relative',
                            top: 0,
                            left: 0,
                            right: 'auto',
                            bottom: 'auto',
                          })),
                      t.css(o),
                      n.css(i).show()
                    );
                  },
                  removeWrapper: function (t) {
                    var i = document.activeElement;
                    return (
                      t.parent().is('.ui-effects-wrapper') &&
                        (t.parent().replaceWith(t),
                        (t[0] === i || e.contains(t[0], i)) &&
                          e(i).trigger('focus')),
                      t
                    );
                  },
                }),
              e.extend(e.effects, {
                version: '1.12.1',
                define: function (t, i, n) {
                  return (
                    n || ((n = i), (i = 'effect')),
                    (e.effects.effect[t] = n),
                    (e.effects.effect[t].mode = i),
                    n
                  );
                },
                scaledDimensions: function (e, t, i) {
                  if (0 === t)
                    return {
                      height: 0,
                      width: 0,
                      outerHeight: 0,
                      outerWidth: 0,
                    };
                  var n = 'horizontal' !== i ? (t || 100) / 100 : 1,
                    o = 'vertical' !== i ? (t || 100) / 100 : 1;
                  return {
                    height: e.height() * o,
                    width: e.width() * n,
                    outerHeight: e.outerHeight() * o,
                    outerWidth: e.outerWidth() * n,
                  };
                },
                clipToBox: function (e) {
                  return {
                    width: e.clip.right - e.clip.left,
                    height: e.clip.bottom - e.clip.top,
                    left: e.clip.left,
                    top: e.clip.top,
                  };
                },
                unshift: function (e, t, i) {
                  var n = e.queue();
                  t > 1 && n.splice.apply(n, [1, 0].concat(n.splice(t, i))),
                    e.dequeue();
                },
                saveStyle: function (e) {
                  e.data(l, e[0].style.cssText);
                },
                restoreStyle: function (e) {
                  (e[0].style.cssText = e.data(l) || ''), e.removeData(l);
                },
                mode: function (e, t) {
                  var i = e.is(':hidden');
                  return (
                    'toggle' === t && (t = i ? 'show' : 'hide'),
                    (i ? 'hide' === t : 'show' === t) && (t = 'none'),
                    t
                  );
                },
                getBaseline: function (e, t) {
                  var i, n;
                  switch (e[0]) {
                    case 'top':
                      i = 0;
                      break;
                    case 'middle':
                      i = 0.5;
                      break;
                    case 'bottom':
                      i = 1;
                      break;
                    default:
                      i = e[0] / t.height;
                  }
                  switch (e[1]) {
                    case 'left':
                      n = 0;
                      break;
                    case 'center':
                      n = 0.5;
                      break;
                    case 'right':
                      n = 1;
                      break;
                    default:
                      n = e[1] / t.width;
                  }
                  return { x: n, y: i };
                },
                createPlaceholder: function (t) {
                  var i,
                    n = t.css('position'),
                    o = t.position();
                  return (
                    t
                      .css({
                        marginTop: t.css('marginTop'),
                        marginBottom: t.css('marginBottom'),
                        marginLeft: t.css('marginLeft'),
                        marginRight: t.css('marginRight'),
                      })
                      .outerWidth(t.outerWidth())
                      .outerHeight(t.outerHeight()),
                    /^(static|relative)/.test(n) &&
                      ((n = 'absolute'),
                      (i = e('<' + t[0].nodeName + '>')
                        .insertAfter(t)
                        .css({
                          display: /^(inline|ruby)/.test(t.css('display'))
                            ? 'inline-block'
                            : 'block',
                          visibility: 'hidden',
                          marginTop: t.css('marginTop'),
                          marginBottom: t.css('marginBottom'),
                          marginLeft: t.css('marginLeft'),
                          marginRight: t.css('marginRight'),
                          float: t.css('float'),
                        })
                        .outerWidth(t.outerWidth())
                        .outerHeight(t.outerHeight())
                        .addClass('ui-effects-placeholder')),
                      t.data(s + 'placeholder', i)),
                    t.css({ position: n, left: o.left, top: o.top }),
                    i
                  );
                },
                removePlaceholder: function (e) {
                  var t = s + 'placeholder',
                    i = e.data(t);
                  i && (i.remove(), e.removeData(t));
                },
                cleanUp: function (t) {
                  e.effects.restoreStyle(t), e.effects.removePlaceholder(t);
                },
                setTransition: function (t, i, n, o) {
                  return (
                    (o = o || {}),
                    e.each(i, function (e, i) {
                      var r = t.cssUnit(i);
                      r[0] > 0 && (o[i] = r[0] * n + r[1]);
                    }),
                    o
                  );
                },
              }),
              e.fn.extend({
                effect: function () {
                  function i(t) {
                    function i() {
                      s.removeData(c),
                        e.effects.cleanUp(s),
                        'hide' === n.mode && s.hide(),
                        a();
                    }
                    function a() {
                      e.isFunction(l) && l.call(s[0]), e.isFunction(t) && t();
                    }
                    var s = e(this);
                    (n.mode = u.shift()),
                      e.uiBackCompat === !1 || r
                        ? 'none' === n.mode
                          ? (s[d](), a())
                          : o.call(s[0], n, i)
                        : (s.is(':hidden') ? 'hide' === d : 'show' === d)
                        ? (s[d](), a())
                        : o.call(s[0], n, a);
                  }
                  var n = t.apply(this, arguments),
                    o = e.effects.effect[n.effect],
                    r = o.mode,
                    a = n.queue,
                    s = a || 'fx',
                    l = n.complete,
                    d = n.mode,
                    u = [],
                    p = function (t) {
                      var i = e(this),
                        n = e.effects.mode(i, d) || r;
                      i.data(c, !0),
                        u.push(n),
                        r &&
                          ('show' === n || (n === r && 'hide' === n)) &&
                          i.show(),
                        (r && 'none' === n) || e.effects.saveStyle(i),
                        e.isFunction(t) && t();
                    };
                  return e.fx.off || !o
                    ? d
                      ? this[d](n.duration, l)
                      : this.each(function () {
                          l && l.call(this);
                        })
                    : a === !1
                    ? this.each(p).each(i)
                    : this.queue(s, p).queue(s, i);
                },
                show: (function (e) {
                  return function (n) {
                    if (i(n)) return e.apply(this, arguments);
                    var o = t.apply(this, arguments);
                    return (o.mode = 'show'), this.effect.call(this, o);
                  };
                })(e.fn.show),
                hide: (function (e) {
                  return function (n) {
                    if (i(n)) return e.apply(this, arguments);
                    var o = t.apply(this, arguments);
                    return (o.mode = 'hide'), this.effect.call(this, o);
                  };
                })(e.fn.hide),
                toggle: (function (e) {
                  return function (n) {
                    if (i(n) || 'boolean' == typeof n)
                      return e.apply(this, arguments);
                    var o = t.apply(this, arguments);
                    return (o.mode = 'toggle'), this.effect.call(this, o);
                  };
                })(e.fn.toggle),
                cssUnit: function (t) {
                  var i = this.css(t),
                    n = [];
                  return (
                    e.each(['em', 'px', '%', 'pt'], function (e, t) {
                      i.indexOf(t) > 0 && (n = [parseFloat(i), t]);
                    }),
                    n
                  );
                },
                cssClip: function (e) {
                  return e
                    ? this.css(
                        'clip',
                        'rect(' +
                          e.top +
                          'px ' +
                          e.right +
                          'px ' +
                          e.bottom +
                          'px ' +
                          e.left +
                          'px)'
                      )
                    : n(this.css('clip'), this);
                },
                transfer: function (t, i) {
                  var n = e(this),
                    o = e(t.to),
                    r = 'fixed' === o.css('position'),
                    a = e('body'),
                    s = r ? a.scrollTop() : 0,
                    l = r ? a.scrollLeft() : 0,
                    c = o.offset(),
                    d = {
                      top: c.top - s,
                      left: c.left - l,
                      height: o.innerHeight(),
                      width: o.innerWidth(),
                    },
                    u = n.offset(),
                    p = e("<div class='ui-effects-transfer'></div>")
                      .appendTo('body')
                      .addClass(t.className)
                      .css({
                        top: u.top - s,
                        left: u.left - l,
                        height: n.innerHeight(),
                        width: n.innerWidth(),
                        position: r ? 'fixed' : 'absolute',
                      })
                      .animate(d, t.duration, t.easing, function () {
                        p.remove(), e.isFunction(i) && i();
                      });
                },
              }),
              (e.fx.step.clip = function (t) {
                t.clipInit ||
                  ((t.start = e(t.elem).cssClip()),
                  'string' == typeof t.end && (t.end = n(t.end, t.elem)),
                  (t.clipInit = !0)),
                  e(t.elem).cssClip({
                    top: t.pos * (t.end.top - t.start.top) + t.start.top,
                    right:
                      t.pos * (t.end.right - t.start.right) + t.start.right,
                    bottom:
                      t.pos * (t.end.bottom - t.start.bottom) + t.start.bottom,
                    left: t.pos * (t.end.left - t.start.left) + t.start.left,
                  });
              });
          })(),
          (function () {
            var t = {};
            e.each(
              ['Quad', 'Cubic', 'Quart', 'Quint', 'Expo'],
              function (e, i) {
                t[i] = function (t) {
                  return Math.pow(t, e + 2);
                };
              }
            ),
              e.extend(t, {
                Sine: function (e) {
                  return 1 - Math.cos((e * Math.PI) / 2);
                },
                Circ: function (e) {
                  return 1 - Math.sqrt(1 - e * e);
                },
                Elastic: function (e) {
                  return 0 === e || 1 === e
                    ? e
                    : -Math.pow(2, 8 * (e - 1)) *
                        Math.sin(((80 * (e - 1) - 7.5) * Math.PI) / 15);
                },
                Back: function (e) {
                  return e * e * (3 * e - 2);
                },
                Bounce: function (e) {
                  for (var t, i = 4; ((t = Math.pow(2, --i)) - 1) / 11 > e; );
                  return (
                    1 / Math.pow(4, 3 - i) -
                    7.5625 * Math.pow((3 * t - 2) / 22 - e, 2)
                  );
                },
              }),
              e.each(t, function (t, i) {
                (e.easing['easeIn' + t] = i),
                  (e.easing['easeOut' + t] = function (e) {
                    return 1 - i(1 - e);
                  }),
                  (e.easing['easeInOut' + t] = function (e) {
                    return 0.5 > e ? i(2 * e) / 2 : 1 - i(-2 * e + 2) / 2;
                  });
              });
          })();
        var u = e.effects;
        e.effects.define('blind', 'hide', function (t, i) {
          var n = {
              up: ['bottom', 'top'],
              vertical: ['bottom', 'top'],
              down: ['top', 'bottom'],
              left: ['right', 'left'],
              horizontal: ['right', 'left'],
              right: ['left', 'right'],
            },
            o = e(this),
            r = t.direction || 'up',
            a = o.cssClip(),
            s = { clip: e.extend({}, a) },
            l = e.effects.createPlaceholder(o);
          (s.clip[n[r][0]] = s.clip[n[r][1]]),
            'show' === t.mode &&
              (o.cssClip(s.clip),
              l && l.css(e.effects.clipToBox(s)),
              (s.clip = a)),
            l && l.animate(e.effects.clipToBox(s), t.duration, t.easing),
            o.animate(s, {
              queue: !1,
              duration: t.duration,
              easing: t.easing,
              complete: i,
            });
        }),
          e.effects.define('bounce', function (t, i) {
            var n,
              o,
              r,
              a = e(this),
              s = t.mode,
              l = 'hide' === s,
              c = 'show' === s,
              d = t.direction || 'up',
              u = t.distance,
              p = t.times || 5,
              f = 2 * p + (c || l ? 1 : 0),
              h = t.duration / f,
              g = t.easing,
              m = 'up' === d || 'down' === d ? 'top' : 'left',
              v = 'up' === d || 'left' === d,
              y = 0,
              w = a.queue().length;
            for (
              e.effects.createPlaceholder(a),
                r = a.css(m),
                u || (u = a['top' === m ? 'outerHeight' : 'outerWidth']() / 3),
                c &&
                  ((o = { opacity: 1 }),
                  (o[m] = r),
                  a
                    .css('opacity', 0)
                    .css(m, v ? 2 * -u : 2 * u)
                    .animate(o, h, g)),
                l && (u /= Math.pow(2, p - 1)),
                o = {},
                o[m] = r;
              p > y;
              y++
            )
              (n = {}),
                (n[m] = (v ? '-=' : '+=') + u),
                a.animate(n, h, g).animate(o, h, g),
                (u = l ? 2 * u : u / 2);
            l &&
              ((n = { opacity: 0 }),
              (n[m] = (v ? '-=' : '+=') + u),
              a.animate(n, h, g)),
              a.queue(i),
              e.effects.unshift(a, w, f + 1);
          }),
          e.effects.define('clip', 'hide', function (t, i) {
            var n,
              o = {},
              r = e(this),
              a = t.direction || 'vertical',
              s = 'both' === a,
              l = s || 'horizontal' === a,
              c = s || 'vertical' === a;
            (n = r.cssClip()),
              (o.clip = {
                top: c ? (n.bottom - n.top) / 2 : n.top,
                right: l ? (n.right - n.left) / 2 : n.right,
                bottom: c ? (n.bottom - n.top) / 2 : n.bottom,
                left: l ? (n.right - n.left) / 2 : n.left,
              }),
              e.effects.createPlaceholder(r),
              'show' === t.mode && (r.cssClip(o.clip), (o.clip = n)),
              r.animate(o, {
                queue: !1,
                duration: t.duration,
                easing: t.easing,
                complete: i,
              });
          }),
          e.effects.define('drop', 'hide', function (t, i) {
            var n,
              o = e(this),
              r = t.mode,
              a = 'show' === r,
              s = t.direction || 'left',
              l = 'up' === s || 'down' === s ? 'top' : 'left',
              c = 'up' === s || 'left' === s ? '-=' : '+=',
              d = '+=' === c ? '-=' : '+=',
              u = { opacity: 0 };
            e.effects.createPlaceholder(o),
              (n =
                t.distance ||
                o['top' === l ? 'outerHeight' : 'outerWidth'](!0) / 2),
              (u[l] = c + n),
              a && (o.css(u), (u[l] = d + n), (u.opacity = 1)),
              o.animate(u, {
                queue: !1,
                duration: t.duration,
                easing: t.easing,
                complete: i,
              });
          }),
          e.effects.define('explode', 'hide', function (t, i) {
            function n() {
              w.push(this), w.length === u * p && o();
            }
            function o() {
              f.css({ visibility: 'visible' }), e(w).remove(), i();
            }
            var r,
              a,
              s,
              l,
              c,
              d,
              u = t.pieces ? Math.round(Math.sqrt(t.pieces)) : 3,
              p = u,
              f = e(this),
              h = t.mode,
              g = 'show' === h,
              m = f.show().css('visibility', 'hidden').offset(),
              v = Math.ceil(f.outerWidth() / p),
              y = Math.ceil(f.outerHeight() / u),
              w = [];
            for (r = 0; u > r; r++)
              for (l = m.top + r * y, d = r - (u - 1) / 2, a = 0; p > a; a++)
                (s = m.left + a * v),
                  (c = a - (p - 1) / 2),
                  f
                    .clone()
                    .appendTo('body')
                    .wrap('<div></div>')
                    .css({
                      position: 'absolute',
                      visibility: 'visible',
                      left: -a * v,
                      top: -r * y,
                    })
                    .parent()
                    .addClass('ui-effects-explode')
                    .css({
                      position: 'absolute',
                      overflow: 'hidden',
                      width: v,
                      height: y,
                      left: s + (g ? c * v : 0),
                      top: l + (g ? d * y : 0),
                      opacity: g ? 0 : 1,
                    })
                    .animate(
                      {
                        left: s + (g ? 0 : c * v),
                        top: l + (g ? 0 : d * y),
                        opacity: g ? 1 : 0,
                      },
                      t.duration || 500,
                      t.easing,
                      n
                    );
          }),
          e.effects.define('fade', 'toggle', function (t, i) {
            var n = 'show' === t.mode;
            e(this)
              .css('opacity', n ? 0 : 1)
              .animate(
                { opacity: n ? 1 : 0 },
                {
                  queue: !1,
                  duration: t.duration,
                  easing: t.easing,
                  complete: i,
                }
              );
          }),
          e.effects.define('fold', 'hide', function (t, i) {
            var n = e(this),
              o = t.mode,
              r = 'show' === o,
              a = 'hide' === o,
              s = t.size || 15,
              l = /([0-9]+)%/.exec(s),
              c = !!t.horizFirst,
              d = c ? ['right', 'bottom'] : ['bottom', 'right'],
              u = t.duration / 2,
              p = e.effects.createPlaceholder(n),
              f = n.cssClip(),
              h = { clip: e.extend({}, f) },
              g = { clip: e.extend({}, f) },
              m = [f[d[0]], f[d[1]]],
              v = n.queue().length;
            l && (s = (parseInt(l[1], 10) / 100) * m[a ? 0 : 1]),
              (h.clip[d[0]] = s),
              (g.clip[d[0]] = s),
              (g.clip[d[1]] = 0),
              r &&
                (n.cssClip(g.clip),
                p && p.css(e.effects.clipToBox(g)),
                (g.clip = f)),
              n
                .queue(function (i) {
                  p &&
                    p
                      .animate(e.effects.clipToBox(h), u, t.easing)
                      .animate(e.effects.clipToBox(g), u, t.easing),
                    i();
                })
                .animate(h, u, t.easing)
                .animate(g, u, t.easing)
                .queue(i),
              e.effects.unshift(n, v, 4);
          }),
          e.effects.define('highlight', 'show', function (t, i) {
            var n = e(this),
              o = { backgroundColor: n.css('backgroundColor') };
            'hide' === t.mode && (o.opacity = 0),
              e.effects.saveStyle(n),
              n
                .css({
                  backgroundImage: 'none',
                  backgroundColor: t.color || '#ffff99',
                })
                .animate(o, {
                  queue: !1,
                  duration: t.duration,
                  easing: t.easing,
                  complete: i,
                });
          }),
          e.effects.define('size', function (t, i) {
            var n,
              o,
              r,
              a = e(this),
              s = ['fontSize'],
              l = [
                'borderTopWidth',
                'borderBottomWidth',
                'paddingTop',
                'paddingBottom',
              ],
              c = [
                'borderLeftWidth',
                'borderRightWidth',
                'paddingLeft',
                'paddingRight',
              ],
              d = t.mode,
              u = 'effect' !== d,
              p = t.scale || 'both',
              f = t.origin || ['middle', 'center'],
              h = a.css('position'),
              g = a.position(),
              m = e.effects.scaledDimensions(a),
              v = t.from || m,
              y = t.to || e.effects.scaledDimensions(a, 0);
            e.effects.createPlaceholder(a),
              'show' === d && ((r = v), (v = y), (y = r)),
              (o = {
                from: { y: v.height / m.height, x: v.width / m.width },
                to: { y: y.height / m.height, x: y.width / m.width },
              }),
              ('box' === p || 'both' === p) &&
                (o.from.y !== o.to.y &&
                  ((v = e.effects.setTransition(a, l, o.from.y, v)),
                  (y = e.effects.setTransition(a, l, o.to.y, y))),
                o.from.x !== o.to.x &&
                  ((v = e.effects.setTransition(a, c, o.from.x, v)),
                  (y = e.effects.setTransition(a, c, o.to.x, y)))),
              ('content' === p || 'both' === p) &&
                o.from.y !== o.to.y &&
                ((v = e.effects.setTransition(a, s, o.from.y, v)),
                (y = e.effects.setTransition(a, s, o.to.y, y))),
              f &&
                ((n = e.effects.getBaseline(f, m)),
                (v.top = (m.outerHeight - v.outerHeight) * n.y + g.top),
                (v.left = (m.outerWidth - v.outerWidth) * n.x + g.left),
                (y.top = (m.outerHeight - y.outerHeight) * n.y + g.top),
                (y.left = (m.outerWidth - y.outerWidth) * n.x + g.left)),
              a.css(v),
              ('content' === p || 'both' === p) &&
                ((l = l.concat(['marginTop', 'marginBottom']).concat(s)),
                (c = c.concat(['marginLeft', 'marginRight'])),
                a.find('*[width]').each(function () {
                  var i = e(this),
                    n = e.effects.scaledDimensions(i),
                    r = {
                      height: n.height * o.from.y,
                      width: n.width * o.from.x,
                      outerHeight: n.outerHeight * o.from.y,
                      outerWidth: n.outerWidth * o.from.x,
                    },
                    a = {
                      height: n.height * o.to.y,
                      width: n.width * o.to.x,
                      outerHeight: n.height * o.to.y,
                      outerWidth: n.width * o.to.x,
                    };
                  o.from.y !== o.to.y &&
                    ((r = e.effects.setTransition(i, l, o.from.y, r)),
                    (a = e.effects.setTransition(i, l, o.to.y, a))),
                    o.from.x !== o.to.x &&
                      ((r = e.effects.setTransition(i, c, o.from.x, r)),
                      (a = e.effects.setTransition(i, c, o.to.x, a))),
                    u && e.effects.saveStyle(i),
                    i.css(r),
                    i.animate(a, t.duration, t.easing, function () {
                      u && e.effects.restoreStyle(i);
                    });
                })),
              a.animate(y, {
                queue: !1,
                duration: t.duration,
                easing: t.easing,
                complete: function () {
                  var t = a.offset();
                  0 === y.opacity && a.css('opacity', v.opacity),
                    u ||
                      (a
                        .css('position', 'static' === h ? 'relative' : h)
                        .offset(t),
                      e.effects.saveStyle(a)),
                    i();
                },
              });
          }),
          e.effects.define('scale', function (t, i) {
            var n = e(this),
              o = t.mode,
              r =
                parseInt(t.percent, 10) ||
                (0 === parseInt(t.percent, 10) ? 0 : 'effect' !== o ? 0 : 100),
              a = e.extend(
                !0,
                {
                  from: e.effects.scaledDimensions(n),
                  to: e.effects.scaledDimensions(n, r, t.direction || 'both'),
                  origin: t.origin || ['middle', 'center'],
                },
                t
              );
            t.fade && ((a.from.opacity = 1), (a.to.opacity = 0)),
              e.effects.effect.size.call(this, a, i);
          }),
          e.effects.define('puff', 'hide', function (t, i) {
            var n = e.extend(!0, {}, t, {
              fade: !0,
              percent: parseInt(t.percent, 10) || 150,
            });
            e.effects.effect.scale.call(this, n, i);
          }),
          e.effects.define('pulsate', 'show', function (t, i) {
            var n = e(this),
              o = t.mode,
              r = 'show' === o,
              a = 'hide' === o,
              s = r || a,
              l = 2 * (t.times || 5) + (s ? 1 : 0),
              c = t.duration / l,
              d = 0,
              u = 1,
              p = n.queue().length;
            for (
              (r || !n.is(':visible')) && (n.css('opacity', 0).show(), (d = 1));
              l > u;
              u++
            )
              n.animate({ opacity: d }, c, t.easing), (d = 1 - d);
            n.animate({ opacity: d }, c, t.easing),
              n.queue(i),
              e.effects.unshift(n, p, l + 1);
          }),
          e.effects.define('shake', function (t, i) {
            var n = 1,
              o = e(this),
              r = t.direction || 'left',
              a = t.distance || 20,
              s = t.times || 3,
              l = 2 * s + 1,
              c = Math.round(t.duration / l),
              d = 'up' === r || 'down' === r ? 'top' : 'left',
              u = 'up' === r || 'left' === r,
              p = {},
              f = {},
              h = {},
              g = o.queue().length;
            for (
              e.effects.createPlaceholder(o),
                p[d] = (u ? '-=' : '+=') + a,
                f[d] = (u ? '+=' : '-=') + 2 * a,
                h[d] = (u ? '-=' : '+=') + 2 * a,
                o.animate(p, c, t.easing);
              s > n;
              n++
            )
              o.animate(f, c, t.easing).animate(h, c, t.easing);
            o
              .animate(f, c, t.easing)
              .animate(p, c / 2, t.easing)
              .queue(i),
              e.effects.unshift(o, g, l + 1);
          }),
          e.effects.define('slide', 'show', function (t, i) {
            var n,
              o,
              r = e(this),
              a = {
                up: ['bottom', 'top'],
                down: ['top', 'bottom'],
                left: ['right', 'left'],
                right: ['left', 'right'],
              },
              s = t.mode,
              l = t.direction || 'left',
              c = 'up' === l || 'down' === l ? 'top' : 'left',
              d = 'up' === l || 'left' === l,
              u =
                t.distance || r['top' === c ? 'outerHeight' : 'outerWidth'](!0),
              p = {};
            e.effects.createPlaceholder(r),
              (n = r.cssClip()),
              (o = r.position()[c]),
              (p[c] = (d ? -1 : 1) * u + o),
              (p.clip = r.cssClip()),
              (p.clip[a[l][1]] = p.clip[a[l][0]]),
              'show' === s &&
                (r.cssClip(p.clip), r.css(c, p[c]), (p.clip = n), (p[c] = o)),
              r.animate(p, {
                queue: !1,
                duration: t.duration,
                easing: t.easing,
                complete: i,
              });
          });
        var u;
        e.uiBackCompat !== !1 &&
          (u = e.effects.define('transfer', function (t, i) {
            e(this).transfer(t, i);
          }));
      }),
      !(function (e, t, i) {
        function n(e, t) {
          return typeof e === t;
        }
        function o() {
          var e, t, i, o, r, a, s;
          for (var l in w)
            if (w.hasOwnProperty(l)) {
              if (
                ((e = []),
                (t = w[l]),
                t.name &&
                  (e.push(t.name.toLowerCase()),
                  t.options && t.options.aliases && t.options.aliases.length))
              )
                for (i = 0; i < t.options.aliases.length; i++)
                  e.push(t.options.aliases[i].toLowerCase());
              for (
                o = n(t.fn, 'function') ? t.fn() : t.fn, r = 0;
                r < e.length;
                r++
              )
                (a = e[r]),
                  (s = a.split('.')),
                  1 === s.length
                    ? (k[s[0]] = o)
                    : (!k[s[0]] ||
                        k[s[0]] instanceof Boolean ||
                        (k[s[0]] = new Boolean(k[s[0]])),
                      (k[s[0]][s[1]] = o)),
                  y.push((o ? '' : 'no-') + s.join('-'));
            }
        }
        function r(e) {
          var t = x.className,
            i = k._config.classPrefix || '';
          if ((T && (t = t.baseVal), k._config.enableJSClass)) {
            var n = new RegExp('(^|\\s)' + i + 'no-js(\\s|$)');
            t = t.replace(n, '$1' + i + 'js$2');
          }
          k._config.enableClasses &&
            ((t += ' ' + i + e.join(' ' + i)),
            T ? (x.className.baseVal = t) : (x.className = t));
        }
        function a() {
          return 'function' != typeof t.createElement
            ? t.createElement(arguments[0])
            : T
            ? t.createElementNS.call(
                t,
                'http://www.w3.org/2000/svg',
                arguments[0]
              )
            : t.createElement.apply(t, arguments);
        }
        function s(e, t) {
          return !!~('' + e).indexOf(t);
        }
        function l(e) {
          return e
            .replace(/([a-z])-([a-z])/g, function (e, t, i) {
              return t + i.toUpperCase();
            })
            .replace(/^-/, '');
        }
        function c() {
          var e = t.body;
          return e || ((e = a(T ? 'svg' : 'body')), (e.fake = !0)), e;
        }
        function d(e, i, n, o) {
          var r,
            s,
            l,
            d,
            u = 'modernizr',
            p = a('div'),
            f = c();
          if (parseInt(n, 10))
            for (; n--; )
              (l = a('div')), (l.id = o ? o[n] : u + (n + 1)), p.appendChild(l);
          return (
            (r = a('style')),
            (r.type = 'text/css'),
            (r.id = 's' + u),
            (f.fake ? f : p).appendChild(r),
            f.appendChild(p),
            r.styleSheet
              ? (r.styleSheet.cssText = e)
              : r.appendChild(t.createTextNode(e)),
            (p.id = u),
            f.fake &&
              ((f.style.background = ''),
              (f.style.overflow = 'hidden'),
              (d = x.style.overflow),
              (x.style.overflow = 'hidden'),
              x.appendChild(f)),
            (s = i(p, e)),
            f.fake
              ? (f.parentNode.removeChild(f),
                (x.style.overflow = d),
                x.offsetHeight)
              : p.parentNode.removeChild(p),
            !!s
          );
        }
        function u(e, t) {
          return function () {
            return e.apply(t, arguments);
          };
        }
        function p(e, t, i) {
          var o;
          for (var r in e)
            if (e[r] in t)
              return i === !1
                ? e[r]
                : ((o = t[e[r]]), n(o, 'function') ? u(o, i || t) : o);
          return !1;
        }
        function f(e) {
          return e
            .replace(/([A-Z])/g, function (e, t) {
              return '-' + t.toLowerCase();
            })
            .replace(/^ms-/, '-ms-');
        }
        function h(t, n) {
          var o = t.length;
          if ('CSS' in e && 'supports' in e.CSS) {
            for (; o--; ) if (e.CSS.supports(f(t[o]), n)) return !0;
            return !1;
          }
          if ('CSSSupportsRule' in e) {
            for (var r = []; o--; ) r.push('(' + f(t[o]) + ':' + n + ')');
            return (
              (r = r.join(' or ')),
              d(
                '@supports (' + r + ') { #modernizr { position: absolute; } }',
                function (e) {
                  return 'absolute' == getComputedStyle(e, null).position;
                }
              )
            );
          }
          return i;
        }
        function g(e, t, o, r) {
          function c() {
            u && (delete A.style, delete A.modElem);
          }
          if (((r = !n(r, 'undefined') && r), !n(o, 'undefined'))) {
            var d = h(e, o);
            if (!n(d, 'undefined')) return d;
          }
          for (
            var u, p, f, g, m, v = ['modernizr', 'tspan', 'samp'];
            !A.style && v.length;

          )
            (u = !0), (A.modElem = a(v.shift())), (A.style = A.modElem.style);
          for (f = e.length, p = 0; f > p; p++)
            if (
              ((g = e[p]),
              (m = A.style[g]),
              s(g, '-') && (g = l(g)),
              A.style[g] !== i)
            ) {
              if (r || n(o, 'undefined')) return c(), 'pfx' != t || g;
              try {
                A.style[g] = o;
              } catch (y) {}
              if (A.style[g] != m) return c(), 'pfx' != t || g;
            }
          return c(), !1;
        }
        function m(e, t, i, o, r) {
          var a = e.charAt(0).toUpperCase() + e.slice(1),
            s = (e + ' ' + $.join(a + ' ') + a).split(' ');
          return n(t, 'string') || n(t, 'undefined')
            ? g(s, t, o, r)
            : ((s = (e + ' ' + D.join(a + ' ') + a).split(' ')), p(s, t, i));
        }
        function v(e, t, n) {
          return m(e, i, i, t, n);
        }
        var y = [],
          w = [],
          b = {
            _version: '3.3.1',
            _config: {
              classPrefix: '',
              enableClasses: !0,
              enableJSClass: !0,
              usePrefixes: !0,
            },
            _q: [],
            on: function (e, t) {
              var i = this;
              setTimeout(function () {
                t(i[e]);
              }, 0);
            },
            addTest: function (e, t, i) {
              w.push({ name: e, fn: t, options: i });
            },
            addAsyncTest: function (e) {
              w.push({ name: null, fn: e });
            },
          },
          k = function () {};
        (k.prototype = b),
          (k = new k()),
          k.addTest(
            'svg',
            !!t.createElementNS &&
              !!t.createElementNS('http://www.w3.org/2000/svg', 'svg')
                .createSVGRect
          ),
          k.addTest('history', function () {
            var t = navigator.userAgent;
            return (
              ((-1 === t.indexOf('Android 2.') &&
                -1 === t.indexOf('Android 4.0')) ||
                -1 === t.indexOf('Mobile Safari') ||
                -1 !== t.indexOf('Chrome') ||
                -1 !== t.indexOf('Windows Phone')) &&
              e.history &&
              'pushState' in e.history
            );
          });
        var S = b._config.usePrefixes
          ? ' -webkit- -moz- -o- -ms- '.split(' ')
          : ['', ''];
        b._prefixes = S;
        var x = t.documentElement,
          T = 'svg' === x.nodeName.toLowerCase();
        T ||
          !(function (e, t) {
            function i(e, t) {
              var i = e.createElement('p'),
                n = e.getElementsByTagName('head')[0] || e.documentElement;
              return (
                (i.innerHTML = 'x<style>' + t + '</style>'),
                n.insertBefore(i.lastChild, n.firstChild)
              );
            }
            function n() {
              var e = w.elements;
              return 'string' == typeof e ? e.split(' ') : e;
            }
            function o(e, t) {
              var i = w.elements;
              'string' != typeof i && (i = i.join(' ')),
                'string' != typeof e && (e = e.join(' ')),
                (w.elements = i + ' ' + e),
                c(t);
            }
            function r(e) {
              var t = y[e[m]];
              return t || ((t = {}), v++, (e[m] = v), (y[v] = t)), t;
            }
            function a(e, i, n) {
              if ((i || (i = t), u)) return i.createElement(e);
              n || (n = r(i));
              var o;
              return (
                (o = n.cache[e]
                  ? n.cache[e].cloneNode()
                  : g.test(e)
                  ? (n.cache[e] = n.createElem(e)).cloneNode()
                  : n.createElem(e)),
                !o.canHaveChildren || h.test(e) || o.tagUrn
                  ? o
                  : n.frag.appendChild(o)
              );
            }
            function s(e, i) {
              if ((e || (e = t), u)) return e.createDocumentFragment();
              i = i || r(e);
              for (
                var o = i.frag.cloneNode(), a = 0, s = n(), l = s.length;
                l > a;
                a++
              )
                o.createElement(s[a]);
              return o;
            }
            function l(e, t) {
              t.cache ||
                ((t.cache = {}),
                (t.createElem = e.createElement),
                (t.createFrag = e.createDocumentFragment),
                (t.frag = t.createFrag())),
                (e.createElement = function (i) {
                  return w.shivMethods ? a(i, e, t) : t.createElem(i);
                }),
                (e.createDocumentFragment = Function(
                  'h,f',
                  'return function(){var n=f.cloneNode(),c=n.createElement;h.shivMethods&&(' +
                    n()
                      .join()
                      .replace(/[\w\-:]+/g, function (e) {
                        return (
                          t.createElem(e),
                          t.frag.createElement(e),
                          'c("' + e + '")'
                        );
                      }) +
                    ');return n}'
                )(w, t.frag));
            }
            function c(e) {
              e || (e = t);
              var n = r(e);
              return (
                !w.shivCSS ||
                  d ||
                  n.hasCSS ||
                  (n.hasCSS = !!i(
                    e,
                    'article,aside,dialog,figcaption,figure,footer,header,hgroup,main,nav,section{display:block}mark{background:#FF0;color:#000}template{display:none}'
                  )),
                u || l(e, n),
                e
              );
            }
            var d,
              u,
              p = '3.7.3',
              f = e.html5 || {},
              h =
                /^<|^(?:button|map|select|textarea|object|iframe|option|optgroup)$/i,
              g =
                /^(?:a|b|code|div|fieldset|h1|h2|h3|h4|h5|h6|i|label|li|ol|p|q|span|strong|style|table|tbody|td|th|tr|ul)$/i,
              m = '_html5shiv',
              v = 0,
              y = {};
            !(function () {
              try {
                var e = t.createElement('a');
                (e.innerHTML = '<xyz></xyz>'),
                  (d = 'hidden' in e),
                  (u =
                    1 == e.childNodes.length ||
                    (function () {
                      t.createElement('a');
                      var e = t.createDocumentFragment();
                      return (
                        'undefined' == typeof e.cloneNode ||
                        'undefined' == typeof e.createDocumentFragment ||
                        'undefined' == typeof e.createElement
                      );
                    })());
              } catch (i) {
                (d = !0), (u = !0);
              }
            })();
            var w = {
              elements:
                f.elements ||
                'abbr article aside audio bdi canvas data datalist details dialog figcaption figure footer header hgroup main mark meter nav output picture progress section summary template time video',
              version: p,
              shivCSS: f.shivCSS !== !1,
              supportsUnknownElements: u,
              shivMethods: f.shivMethods !== !1,
              type: 'default',
              shivDocument: c,
              createElement: a,
              createDocumentFragment: s,
              addElements: o,
            };
            (e.html5 = w),
              c(t),
              'object' == typeof module &&
                module.exports &&
                (module.exports = w);
          })('undefined' != typeof e ? e : this, t);
        var C = 'Moz O ms Webkit',
          D = b._config.usePrefixes ? C.toLowerCase().split(' ') : [];
        b._domPrefixes = D;
        var I = (function () {
          function e(e, t) {
            var o;
            return (
              !!e &&
              ((t && 'string' != typeof t) || (t = a(t || 'div')),
              (e = 'on' + e),
              (o = e in t),
              !o &&
                n &&
                (t.setAttribute || (t = a('div')),
                t.setAttribute(e, ''),
                (o = 'function' == typeof t[e]),
                t[e] !== i && (t[e] = i),
                t.removeAttribute(e)),
              o)
            );
          }
          var n = !('onblur' in t.documentElement);
          return e;
        })();
        (b.hasEvent = I),
          k.addTest('opacity', function () {
            var e = a('a').style;
            return (
              (e.cssText = S.join('opacity:.55;')), /^0.55$/.test(e.opacity)
            );
          }),
          k.addTest('csspositionsticky', function () {
            var e = 'position:',
              t = 'sticky',
              i = a('a'),
              n = i.style;
            return (
              (n.cssText = e + S.join(t + ';' + e).slice(0, -e.length)),
              -1 !== n.position.indexOf(t)
            );
          }),
          k.addTest('rgba', function () {
            var e = a('a').style;
            return (
              (e.cssText = 'background-color:rgba(150,255,150,.5)'),
              ('' + e.backgroundColor).indexOf('rgba') > -1
            );
          }),
          k.addTest('inlinesvg', function () {
            var e = a('div');
            return (
              (e.innerHTML = '<svg/>'),
              'http://www.w3.org/2000/svg' ==
                ('undefined' != typeof SVGRect &&
                  e.firstChild &&
                  e.firstChild.namespaceURI)
            );
          });
        var _ = {}.toString;
        k.addTest('svgclippaths', function () {
          return (
            !!t.createElementNS &&
            /SVGClipPath/.test(
              _.call(
                t.createElementNS('http://www.w3.org/2000/svg', 'clipPath')
              )
            )
          );
        });
        var $ = b._config.usePrefixes ? C.split(' ') : [];
        b._cssomPrefixes = $;
        var M = (b.testStyles = d),
          E = (function () {
            var e = navigator.userAgent,
              t = e.match(/applewebkit\/([0-9]+)/gi) && parseFloat(RegExp.$1),
              i = e.match(/w(eb)?osbrowser/gi),
              n =
                e.match(/windows phone/gi) &&
                e.match(/iemobile\/([0-9])+/gi) &&
                parseFloat(RegExp.$1) >= 9,
              o = 533 > t && e.match(/android/gi);
            return i || o || n;
          })();
        E
          ? k.addTest('fontface', !1)
          : M(
              '@font-face {font-family:"font";src:url("https://")}',
              function (e, i) {
                var n = t.getElementById('smodernizr'),
                  o = n.sheet || n.styleSheet,
                  r = o
                    ? o.cssRules && o.cssRules[0]
                      ? o.cssRules[0].cssText
                      : o.cssText || ''
                    : '',
                  a = /src/i.test(r) && 0 === r.indexOf(i.split(' ')[0]);
                k.addTest('fontface', a);
              }
            ),
          M(
            '#modernizr{font:0/0 a}#modernizr:after{content:":)";visibility:hidden;font:7px/1 a}',
            function (e) {
              k.addTest('generatedcontent', e.offsetHeight >= 7);
            }
          );
        var j = { elem: a('modernizr') };
        k._q.push(function () {
          delete j.elem;
        });
        var A = { style: j.elem.style };
        k._q.unshift(function () {
          delete A.style;
        });
        var N = (b.testProp = function (e, t, n) {
          return g([e], i, t, n);
        });
        k.addTest('textshadow', N('textShadow', '1px 1px')),
          (b.testAllProps = m),
          (b.testAllProps = v),
          k.addTest('cssanimations', v('animationName', 'a', !0)),
          k.addTest('borderradius', v('borderRadius', '0px', !0)),
          k.addTest('boxshadow', v('boxShadow', '1px 1px', !0)),
          k.addTest('csstransitions', v('transition', 'all', !0));
        var H = 'CSS' in e && 'supports' in e.CSS,
          O = 'supportsCSS' in e;
        k.addTest('supports', H || O),
          o(),
          r(y),
          delete b.addTest,
          delete b.addAsyncTest;
        for (var P = 0; P < k._q.length; P++) k._q[P]();
        e.Modernizr = k;
      })(window, document);
    var fancyboxIEPngLocationFix = {
      pathToFind: new RegExp('fancybox/'),
      selectorSet:
        ".fancybox-ie [id^='fancybox-bg'],.fancybox-ie6 [id^='fancybox-'], #fancybox-loading.fancybox-ie6 div",
      element: null,
      currentFilter: null,
      fix: function (e) {
        var t = this;
        jQuery(t.selectorSet).each(function () {
          (t.element = jQuery(this)),
            (t.currentFilter = t.element.css('filter')),
            t.element.css('filter', t.currentFilter.replace(t.pathToFind, e));
        });
      },
    };
    if (
      (!(function (e) {
        'use strict';
        e(jQuery);
      })(function (e) {
        'use strict';
        var t = window.Slick || {};
        (t = (function () {
          function t(t, n) {
            var o,
              r = this;
            (r.defaults = {
              accessibility: !0,
              adaptiveHeight: !1,
              appendArrows: e(t),
              appendDots: e(t),
              arrows: !0,
              asNavFor: null,
              prevArrow:
                '<button type="button" data-role="none" class="slick-prev" aria-label="Previous" tabindex="0" role="button">Previous</button>',
              nextArrow:
                '<button type="button" data-role="none" class="slick-next" aria-label="Next" tabindex="0" role="button">Next</button>',
              autoplay: !1,
              autoplaySpeed: 3e3,
              centerMode: !1,
              centerPadding: '50px',
              cssEase: 'ease',
              customPaging: function (e, t) {
                return (
                  '<button type="button" data-role="none" role="button" aria-required="false" tabindex="0">' +
                  toNum(t + 1) +
                  '</button>'
                );
              },
              dots: !1,
              dotsClass: 'slick-dots',
              draggable: !0,
              easing: 'linear',
              edgeFriction: 0.35,
              fade: !1,
              focusOnSelect: !1,
              infinite: !0,
              initialSlide: 0,
              lazyLoad: 'ondemand',
              mobileFirst: !1,
              pauseOnHover: !0,
              pauseOnDotsHover: !1,
              respondTo: 'window',
              responsive: null,
              rows: 1,
              rtl: !1,
              slide: '',
              slidesPerRow: 1,
              slidesToShow: 1,
              slidesToScroll: 1,
              speed: 500,
              swipe: !0,
              swipeToSlide: !1,
              touchMove: !0,
              touchThreshold: 5,
              useCSS: !0,
              useTransform: !1,
              variableWidth: !1,
              vertical: !1,
              verticalSwiping: !1,
              waitForAnimate: !0,
              zIndex: 1e3,
            }),
              (r.initials = {
                animating: !1,
                dragging: !1,
                autoPlayTimer: null,
                currentDirection: 0,
                currentLeft: null,
                currentSlide: 0,
                direction: 1,
                $dots: null,
                listWidth: null,
                listHeight: null,
                loadIndex: 0,
                $nextArrow: null,
                $prevArrow: null,
                slideCount: null,
                slideWidth: null,
                $slideTrack: null,
                $slides: null,
                sliding: !1,
                slideOffset: 0,
                swipeLeft: null,
                $list: null,
                touchObject: {},
                transformsEnabled: !1,
                unslicked: !1,
              }),
              e.extend(r, r.initials),
              (r.activeBreakpoint = null),
              (r.animType = null),
              (r.animProp = null),
              (r.breakpoints = []),
              (r.breakpointSettings = []),
              (r.cssTransitions = !1),
              (r.hidden = 'hidden'),
              (r.paused = !1),
              (r.positionProp = null),
              (r.respondTo = null),
              (r.rowCount = 1),
              (r.shouldClick = !0),
              (r.$slider = e(t)),
              (r.$slidesCache = null),
              (r.transformType = null),
              (r.transitionType = null),
              (r.visibilityChange = 'visibilitychange'),
              (r.windowWidth = 0),
              (r.windowTimer = null),
              (o = e(t).data('slick') || {}),
              (r.options = e.extend({}, r.defaults, o, n)),
              (r.currentSlide = r.options.initialSlide),
              (r.originalSettings = r.options),
              'undefined' != typeof document.mozHidden
                ? ((r.hidden = 'mozHidden'),
                  (r.visibilityChange = 'mozvisibilitychange'))
                : 'undefined' != typeof document.webkitHidden &&
                  ((r.hidden = 'webkitHidden'),
                  (r.visibilityChange = 'webkitvisibilitychange')),
              (r.autoPlay = e.proxy(r.autoPlay, r)),
              (r.autoPlayClear = e.proxy(r.autoPlayClear, r)),
              (r.changeSlide = e.proxy(r.changeSlide, r)),
              (r.clickHandler = e.proxy(r.clickHandler, r)),
              (r.selectHandler = e.proxy(r.selectHandler, r)),
              (r.setPosition = e.proxy(r.setPosition, r)),
              (r.swipeHandler = e.proxy(r.swipeHandler, r)),
              (r.dragHandler = e.proxy(r.dragHandler, r)),
              (r.keyHandler = e.proxy(r.keyHandler, r)),
              (r.autoPlayIterator = e.proxy(r.autoPlayIterator, r)),
              (r.instanceUid = i++),
              (r.htmlExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/),
              r.registerBreakpoints(),
              r.init(!0),
              r.checkResponsive(!0);
          }
          var i = 0;
          return t;
        })()),
          (t.prototype.addSlide = t.prototype.slickAdd =
            function (t, i, n) {
              var o = this;
              if ('boolean' == typeof i) (n = i), (i = null);
              else if (0 > i || i >= o.slideCount) return !1;
              o.unload(),
                'number' == typeof i
                  ? 0 === i && 0 === o.$slides.length
                    ? e(t).appendTo(o.$slideTrack)
                    : n
                    ? e(t).insertBefore(o.$slides.eq(i))
                    : e(t).insertAfter(o.$slides.eq(i))
                  : n === !0
                  ? e(t).prependTo(o.$slideTrack)
                  : e(t).appendTo(o.$slideTrack),
                (o.$slides = o.$slideTrack.children(this.options.slide)),
                o.$slideTrack.children(this.options.slide).detach(),
                o.$slideTrack.append(o.$slides),
                o.$slides.each(function (t, i) {
                  e(i).attr('data-slick-index', t);
                }),
                (o.$slidesCache = o.$slides),
                o.reinit();
            }),
          (t.prototype.animateHeight = function () {
            var e = this;
            if (
              1 === e.options.slidesToShow &&
              e.options.adaptiveHeight === !0 &&
              e.options.vertical === !1
            ) {
              var t = e.$slides.eq(e.currentSlide).outerHeight(!0);
              e.$list.animate({ height: t }, e.options.speed);
            }
          }),
          (t.prototype.animateSlide = function (t, i) {
            var n = {},
              o = this;
            o.animateHeight(),
              o.options.rtl === !0 && o.options.vertical === !1 && (t = -t),
              o.transformsEnabled === !1
                ? o.options.vertical === !1
                  ? o.$slideTrack.animate(
                      { left: t },
                      o.options.speed,
                      o.options.easing,
                      i
                    )
                  : o.$slideTrack.animate(
                      { top: t },
                      o.options.speed,
                      o.options.easing,
                      i
                    )
                : o.cssTransitions === !1
                ? (o.options.rtl === !0 && (o.currentLeft = -o.currentLeft),
                  e({ animStart: o.currentLeft }).animate(
                    { animStart: t },
                    {
                      duration: o.options.speed,
                      easing: o.options.easing,
                      step: function (e) {
                        (e = Math.ceil(e)),
                          o.options.vertical === !1
                            ? ((n[o.animType] = 'translate(' + e + 'px, 0px)'),
                              o.$slideTrack.css(n))
                            : ((n[o.animType] = 'translate(0px,' + e + 'px)'),
                              o.$slideTrack.css(n));
                      },
                      complete: function () {
                        i && i.call();
                      },
                    }
                  ))
                : (o.applyTransition(),
                  (t = Math.ceil(t)),
                  o.options.vertical === !1
                    ? (n[o.animType] = 'translate3d(' + t + 'px, 0px, 0px)')
                    : (n[o.animType] = 'translate3d(0px,' + t + 'px, 0px)'),
                  o.$slideTrack.css(n),
                  i &&
                    setTimeout(function () {
                      o.disableTransition(), i.call();
                    }, o.options.speed));
          }),
          (t.prototype.asNavFor = function (t) {
            var i = this,
              n = i.options.asNavFor;
            n && null !== n && (n = e(n).not(i.$slider)),
              null !== n &&
                'object' == typeof n &&
                n.each(function () {
                  var i = e(this).slick('getSlick');
                  i.unslicked || i.slideHandler(t, !0);
                });
          }),
          (t.prototype.applyTransition = function (e) {
            var t = this,
              i = {};
            t.options.fade === !1
              ? (i[t.transitionType] =
                  t.transformType +
                  ' ' +
                  t.options.speed +
                  'ms ' +
                  t.options.cssEase)
              : (i[t.transitionType] =
                  'opacity ' + t.options.speed + 'ms ' + t.options.cssEase),
              t.options.fade === !1
                ? t.$slideTrack.css(i)
                : t.$slides.eq(e).css(i);
          }),
          (t.prototype.autoPlay = function () {
            var e = this;
            e.autoPlayTimer && clearInterval(e.autoPlayTimer),
              e.slideCount > e.options.slidesToShow &&
                e.paused !== !0 &&
                (e.autoPlayTimer = setInterval(
                  e.autoPlayIterator,
                  e.options.autoplaySpeed
                ));
          }),
          (t.prototype.autoPlayClear = function () {
            var e = this;
            e.autoPlayTimer && clearInterval(e.autoPlayTimer);
          }),
          (t.prototype.autoPlayIterator = function () {
            var e = this;
            e.options.infinite === !1
              ? 1 === e.direction
                ? (e.currentSlide + 1 === e.slideCount - 1 && (e.direction = 0),
                  e.slideHandler(e.currentSlide + e.options.slidesToScroll))
                : (e.currentSlide - 1 === 0 && (e.direction = 1),
                  e.slideHandler(e.currentSlide - e.options.slidesToScroll))
              : e.slideHandler(e.currentSlide + e.options.slidesToScroll);
          }),
          (t.prototype.buildArrows = function () {
            var t = this;
            t.options.arrows === !0 &&
              ((t.$prevArrow = e(t.options.prevArrow).addClass('slick-arrow')),
              (t.$nextArrow = e(t.options.nextArrow).addClass('slick-arrow')),
              t.slideCount > t.options.slidesToShow
                ? (t.$prevArrow
                    .removeClass('slick-hidden')
                    .removeAttr('aria-hidden tabindex'),
                  t.$nextArrow
                    .removeClass('slick-hidden')
                    .removeAttr('aria-hidden tabindex'),
                  t.htmlExpr.test(t.options.prevArrow) &&
                    t.$prevArrow.prependTo(t.options.appendArrows),
                  t.htmlExpr.test(t.options.nextArrow) &&
                    t.$nextArrow.appendTo(t.options.appendArrows),
                  t.options.infinite !== !0 &&
                    t.$prevArrow
                      .addClass('slick-disabled')
                      .attr('aria-disabled', 'true'))
                : t.$prevArrow
                    .add(t.$nextArrow)
                    .addClass('slick-hidden')
                    .attr({ 'aria-disabled': 'true', tabindex: '-1' }));
          }),
          (t.prototype.buildDots = function () {
            var t,
              i,
              n = this;
            if (
              n.options.dots === !0 &&
              n.slideCount > n.options.slidesToShow
            ) {
              for (
                i = '<ul class="' + n.options.dotsClass + '">', t = 0;
                t <= n.getDotCount();
                t += 1
              )
                i += '<li>' + n.options.customPaging.call(this, n, t) + '</li>';
              (i += '</ul>'),
                (n.$dots = e(i).appendTo(n.options.appendDots)),
                n.$dots
                  .find('li')
                  .first()
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false');
            }
          }),
          (t.prototype.buildOut = function () {
            var t = this;
            (t.$slides = t.$slider
              .children(t.options.slide + ':not(.slick-cloned)')
              .addClass('slick-slide')),
              (t.slideCount = t.$slides.length),
              t.$slides.each(function (t, i) {
                e(i)
                  .attr('data-slick-index', t)
                  .data('originalStyling', e(i).attr('style') || '');
              }),
              t.$slider.addClass('slick-slider'),
              (t.$slideTrack =
                0 === t.slideCount
                  ? e('<div class="slick-track"/>').appendTo(t.$slider)
                  : t.$slides.wrapAll('<div class="slick-track"/>').parent()),
              (t.$list = t.$slideTrack
                .wrap('<div aria-live="polite" class="slick-list"/>')
                .parent()),
              t.$slideTrack.css('opacity', 0),
              (t.options.centerMode === !0 || t.options.swipeToSlide === !0) &&
                (t.options.slidesToScroll = 1),
              e('img[data-lazy]', t.$slider)
                .not('[src]')
                .addClass('slick-loading'),
              t.setupInfinite(),
              t.buildArrows(),
              t.buildDots(),
              t.updateDots(),
              t.setSlideClasses(
                'number' == typeof t.currentSlide ? t.currentSlide : 0
              ),
              t.options.draggable === !0 && t.$list.addClass('draggable');
          }),
          (t.prototype.buildRows = function () {
            var e,
              t,
              i,
              n,
              o,
              r,
              a,
              s = this;
            if (
              ((n = document.createDocumentFragment()),
              (r = s.$slider.children()),
              s.options.rows > 1)
            ) {
              for (
                a = s.options.slidesPerRow * s.options.rows,
                  o = Math.ceil(r.length / a),
                  e = 0;
                o > e;
                e++
              ) {
                var l = document.createElement('div');
                for (t = 0; t < s.options.rows; t++) {
                  var c = document.createElement('div');
                  for (i = 0; i < s.options.slidesPerRow; i++) {
                    var d = e * a + (t * s.options.slidesPerRow + i);
                    r.get(d) && c.appendChild(r.get(d));
                  }
                  l.appendChild(c);
                }
                n.appendChild(l);
              }
              s.$slider.html(n),
                s.$slider
                  .children()
                  .children()
                  .children()
                  .css({
                    width: 100 / s.options.slidesPerRow + '%',
                    display: 'inline-block',
                  });
            }
          }),
          (t.prototype.checkResponsive = function (t, i) {
            var n,
              o,
              r,
              a = this,
              s = !1,
              l = a.$slider.width(),
              c = window.innerWidth || e(window).width();
            if (
              ('window' === a.respondTo
                ? (r = c)
                : 'slider' === a.respondTo
                ? (r = l)
                : 'min' === a.respondTo && (r = Math.min(c, l)),
              a.options.responsive &&
                a.options.responsive.length &&
                null !== a.options.responsive)
            ) {
              o = null;
              for (n in a.breakpoints)
                a.breakpoints.hasOwnProperty(n) &&
                  (a.originalSettings.mobileFirst === !1
                    ? r < a.breakpoints[n] && (o = a.breakpoints[n])
                    : r > a.breakpoints[n] && (o = a.breakpoints[n]));
              null !== o
                ? null !== a.activeBreakpoint
                  ? (o !== a.activeBreakpoint || i) &&
                    ((a.activeBreakpoint = o),
                    'unslick' === a.breakpointSettings[o]
                      ? a.unslick(o)
                      : ((a.options = e.extend(
                          {},
                          a.originalSettings,
                          a.breakpointSettings[o]
                        )),
                        t === !0 && (a.currentSlide = a.options.initialSlide),
                        a.refresh(t)),
                    (s = o))
                  : ((a.activeBreakpoint = o),
                    'unslick' === a.breakpointSettings[o]
                      ? a.unslick(o)
                      : ((a.options = e.extend(
                          {},
                          a.originalSettings,
                          a.breakpointSettings[o]
                        )),
                        t === !0 && (a.currentSlide = a.options.initialSlide),
                        a.refresh(t)),
                    (s = o))
                : null !== a.activeBreakpoint &&
                  ((a.activeBreakpoint = null),
                  (a.options = a.originalSettings),
                  t === !0 && (a.currentSlide = a.options.initialSlide),
                  a.refresh(t),
                  (s = o)),
                t || s === !1 || a.$slider.trigger('breakpoint', [a, s]);
            }
          }),
          (t.prototype.changeSlide = function (t, i) {
            var n,
              o,
              r,
              a = this,
              s = e(t.target);
            switch (
              (s.is('a') && t.preventDefault(),
              s.is('li') || (s = s.closest('li')),
              (r = a.slideCount % a.options.slidesToScroll !== 0),
              (n = r
                ? 0
                : (a.slideCount - a.currentSlide) % a.options.slidesToScroll),
              t.data.message)
            ) {
              case 'previous':
                (o =
                  0 === n
                    ? a.options.slidesToScroll
                    : a.options.slidesToShow - n),
                  a.slideCount > a.options.slidesToShow &&
                    a.slideHandler(a.currentSlide - o, !1, i);
                break;
              case 'next':
                (o = 0 === n ? a.options.slidesToScroll : n),
                  a.slideCount > a.options.slidesToShow &&
                    a.slideHandler(a.currentSlide + o, !1, i);
                break;
              case 'index':
                var l =
                  0 === t.data.index
                    ? 0
                    : t.data.index || s.index() * a.options.slidesToScroll;
                a.slideHandler(a.checkNavigable(l), !1, i),
                  s.children().trigger('focus');
                break;
              default:
                return;
            }
          }),
          (t.prototype.checkNavigable = function (e) {
            var t,
              i,
              n = this;
            if (((t = n.getNavigableIndexes()), (i = 0), e > t[t.length - 1]))
              e = t[t.length - 1];
            else
              for (var o in t) {
                if (e < t[o]) {
                  e = i;
                  break;
                }
                i = t[o];
              }
            return e;
          }),
          (t.prototype.cleanUpEvents = function () {
            var t = this;
            t.options.dots &&
              null !== t.$dots &&
              (e('li', t.$dots).off('click.slick', t.changeSlide),
              t.options.pauseOnDotsHover === !0 &&
                t.options.autoplay === !0 &&
                e('li', t.$dots)
                  .off('mouseenter.slick', e.proxy(t.setPaused, t, !0))
                  .off('mouseleave.slick', e.proxy(t.setPaused, t, !1))),
              t.options.arrows === !0 &&
                t.slideCount > t.options.slidesToShow &&
                (t.$prevArrow && t.$prevArrow.off('click.slick', t.changeSlide),
                t.$nextArrow && t.$nextArrow.off('click.slick', t.changeSlide)),
              t.$list.off('touchstart.slick mousedown.slick', t.swipeHandler),
              t.$list.off('touchmove.slick mousemove.slick', t.swipeHandler),
              t.$list.off('touchend.slick mouseup.slick', t.swipeHandler),
              t.$list.off('touchcancel.slick mouseleave.slick', t.swipeHandler),
              t.$list.off('click.slick', t.clickHandler),
              e(document).off(t.visibilityChange, t.visibility),
              t.$list.off('mouseenter.slick', e.proxy(t.setPaused, t, !0)),
              t.$list.off('mouseleave.slick', e.proxy(t.setPaused, t, !1)),
              t.options.accessibility === !0 &&
                t.$list.off('keydown.slick', t.keyHandler),
              t.options.focusOnSelect === !0 &&
                e(t.$slideTrack).children().off('click.slick', t.selectHandler),
              e(window).off(
                'orientationchange.slick.slick-' + t.instanceUid,
                t.orientationChange
              ),
              e(window).off('resize.slick.slick-' + t.instanceUid, t.resize),
              e('[draggable!=true]', t.$slideTrack).off(
                'dragstart',
                t.preventDefault
              ),
              e(window).off('load.slick.slick-' + t.instanceUid, t.setPosition),
              e(document).off(
                'ready.slick.slick-' + t.instanceUid,
                t.setPosition
              );
          }),
          (t.prototype.cleanUpRows = function () {
            var e,
              t = this;
            t.options.rows > 1 &&
              ((e = t.$slides.children().children()),
              e.removeAttr('style'),
              t.$slider.html(e));
          }),
          (t.prototype.clickHandler = function (e) {
            var t = this;
            t.shouldClick === !1 &&
              (e.stopImmediatePropagation(),
              e.stopPropagation(),
              e.preventDefault());
          }),
          (t.prototype.destroy = function (t) {
            var i = this;
            i.autoPlayClear(),
              (i.touchObject = {}),
              i.cleanUpEvents(),
              e('.slick-cloned', i.$slider).detach(),
              i.$dots && i.$dots.remove(),
              i.$prevArrow &&
                i.$prevArrow.length &&
                (i.$prevArrow
                  .removeClass('slick-disabled slick-arrow slick-hidden')
                  .removeAttr('aria-hidden aria-disabled tabindex')
                  .css('display', ''),
                i.htmlExpr.test(i.options.prevArrow) && i.$prevArrow.remove()),
              i.$nextArrow &&
                i.$nextArrow.length &&
                (i.$nextArrow
                  .removeClass('slick-disabled slick-arrow slick-hidden')
                  .removeAttr('aria-hidden aria-disabled tabindex')
                  .css('display', ''),
                i.htmlExpr.test(i.options.nextArrow) && i.$nextArrow.remove()),
              i.$slides &&
                (i.$slides
                  .removeClass(
                    'slick-slide slick-active slick-center slick-visible slick-current'
                  )
                  .removeAttr('aria-hidden')
                  .removeAttr('data-slick-index')
                  .each(function () {
                    e(this).attr('style', e(this).data('originalStyling'));
                  }),
                i.$slideTrack.children(this.options.slide).detach(),
                i.$slideTrack.detach(),
                i.$list.detach(),
                i.$slider.append(i.$slides)),
              i.cleanUpRows(),
              i.$slider.removeClass('slick-slider'),
              i.$slider.removeClass('slick-initialized'),
              (i.unslicked = !0),
              t || i.$slider.trigger('destroy', [i]);
          }),
          (t.prototype.disableTransition = function (e) {
            var t = this,
              i = {};
            (i[t.transitionType] = ''),
              t.options.fade === !1
                ? t.$slideTrack.css(i)
                : t.$slides.eq(e).css(i);
          }),
          (t.prototype.fadeSlide = function (e, t) {
            var i = this;
            i.cssTransitions === !1
              ? (i.$slides.eq(e).css({ zIndex: i.options.zIndex }),
                i.$slides
                  .eq(e)
                  .animate(
                    { opacity: 1 },
                    i.options.speed,
                    i.options.easing,
                    t
                  ))
              : (i.applyTransition(e),
                i.$slides.eq(e).css({ opacity: 1, zIndex: i.options.zIndex }),
                t &&
                  setTimeout(function () {
                    i.disableTransition(e), t.call();
                  }, i.options.speed));
          }),
          (t.prototype.fadeSlideOut = function (e) {
            var t = this;
            t.cssTransitions === !1
              ? t.$slides
                  .eq(e)
                  .animate(
                    { opacity: 0, zIndex: t.options.zIndex - 2 },
                    t.options.speed,
                    t.options.easing
                  )
              : (t.applyTransition(e),
                t.$slides
                  .eq(e)
                  .css({ opacity: 0, zIndex: t.options.zIndex - 2 }));
          }),
          (t.prototype.filterSlides = t.prototype.slickFilter =
            function (e) {
              var t = this;
              null !== e &&
                ((t.$slidesCache = t.$slides),
                t.unload(),
                t.$slideTrack.children(this.options.slide).detach(),
                t.$slidesCache.filter(e).appendTo(t.$slideTrack),
                t.reinit());
            }),
          (t.prototype.getCurrent = t.prototype.slickCurrentSlide =
            function () {
              var e = this;
              return e.currentSlide;
            }),
          (t.prototype.getDotCount = function () {
            var e = this,
              t = 0,
              i = 0,
              n = 0;
            if (e.options.infinite === !0)
              for (; t < e.slideCount; )
                ++n,
                  (t = i + e.options.slidesToScroll),
                  (i +=
                    e.options.slidesToScroll <= e.options.slidesToShow
                      ? e.options.slidesToScroll
                      : e.options.slidesToShow);
            else if (e.options.centerMode === !0) n = e.slideCount;
            else
              for (; t < e.slideCount; )
                ++n,
                  (t = i + e.options.slidesToScroll),
                  (i +=
                    e.options.slidesToScroll <= e.options.slidesToShow
                      ? e.options.slidesToScroll
                      : e.options.slidesToShow);
            return n - 1;
          }),
          (t.prototype.getLeft = function (e) {
            var t,
              i,
              n,
              o = this,
              r = 0;
            return (
              (o.slideOffset = 0),
              (i = o.$slides.first().outerHeight(!0)),
              o.options.infinite === !0
                ? (o.slideCount > o.options.slidesToShow &&
                    ((o.slideOffset =
                      o.slideWidth * o.options.slidesToShow * -1),
                    (r = i * o.options.slidesToShow * -1)),
                  o.slideCount % o.options.slidesToScroll !== 0 &&
                    e + o.options.slidesToScroll > o.slideCount &&
                    o.slideCount > o.options.slidesToShow &&
                    (e > o.slideCount
                      ? ((o.slideOffset =
                          (o.options.slidesToShow - (e - o.slideCount)) *
                          o.slideWidth *
                          -1),
                        (r =
                          (o.options.slidesToShow - (e - o.slideCount)) *
                          i *
                          -1))
                      : ((o.slideOffset =
                          (o.slideCount % o.options.slidesToScroll) *
                          o.slideWidth *
                          -1),
                        (r =
                          (o.slideCount % o.options.slidesToScroll) * i * -1))))
                : e + o.options.slidesToShow > o.slideCount &&
                  ((o.slideOffset =
                    (e + o.options.slidesToShow - o.slideCount) * o.slideWidth),
                  (r = (e + o.options.slidesToShow - o.slideCount) * i)),
              o.slideCount <= o.options.slidesToShow &&
                ((o.slideOffset = 0), (r = 0)),
              o.options.centerMode === !0 && o.options.infinite === !0
                ? (o.slideOffset +=
                    o.slideWidth * Math.floor(o.options.slidesToShow / 2) -
                    o.slideWidth)
                : o.options.centerMode === !0 &&
                  ((o.slideOffset = 0),
                  (o.slideOffset +=
                    o.slideWidth * Math.floor(o.options.slidesToShow / 2))),
              (t =
                o.options.vertical === !1
                  ? e * o.slideWidth * -1 + o.slideOffset
                  : e * i * -1 + r),
              o.options.variableWidth === !0 &&
                ((n =
                  o.slideCount <= o.options.slidesToShow ||
                  o.options.infinite === !1
                    ? o.$slideTrack.children('.slick-slide').eq(e)
                    : o.$slideTrack
                        .children('.slick-slide')
                        .eq(e + o.options.slidesToShow)),
                (t =
                  o.options.rtl === !0
                    ? n[0]
                      ? -1 *
                        (o.$slideTrack.width() - n[0].offsetLeft - n.width())
                      : 0
                    : n[0]
                    ? -1 * n[0].offsetLeft
                    : 0),
                o.options.centerMode === !0 &&
                  ((n =
                    o.slideCount <= o.options.slidesToShow ||
                    o.options.infinite === !1
                      ? o.$slideTrack.children('.slick-slide').eq(e)
                      : o.$slideTrack
                          .children('.slick-slide')
                          .eq(e + o.options.slidesToShow + 1)),
                  (t =
                    o.options.rtl === !0
                      ? n[0]
                        ? -1 *
                          (o.$slideTrack.width() - n[0].offsetLeft - n.width())
                        : 0
                      : n[0]
                      ? -1 * n[0].offsetLeft
                      : 0),
                  (t += (o.$list.width() - n.outerWidth()) / 2))),
              t
            );
          }),
          (t.prototype.getOption = t.prototype.slickGetOption =
            function (e) {
              var t = this;
              return t.options[e];
            }),
          (t.prototype.getNavigableIndexes = function () {
            var e,
              t = this,
              i = 0,
              n = 0,
              o = [];
            for (
              t.options.infinite === !1
                ? (e = t.slideCount)
                : ((i = -1 * t.options.slidesToScroll),
                  (n = -1 * t.options.slidesToScroll),
                  (e = 2 * t.slideCount));
              e > i;

            )
              o.push(i),
                (i = n + t.options.slidesToScroll),
                (n +=
                  t.options.slidesToScroll <= t.options.slidesToShow
                    ? t.options.slidesToScroll
                    : t.options.slidesToShow);
            return o;
          }),
          (t.prototype.getSlick = function () {
            return this;
          }),
          (t.prototype.getSlideCount = function () {
            var t,
              i,
              n,
              o = this;
            return (
              (n =
                o.options.centerMode === !0
                  ? o.slideWidth * Math.floor(o.options.slidesToShow / 2)
                  : 0),
              o.options.swipeToSlide === !0
                ? (o.$slideTrack.find('.slick-slide').each(function (t, r) {
                    return r.offsetLeft - n + e(r).outerWidth() / 2 >
                      -1 * o.swipeLeft
                      ? ((i = r), !1)
                      : void 0;
                  }),
                  (t =
                    Math.abs(e(i).attr('data-slick-index') - o.currentSlide) ||
                    1))
                : o.options.slidesToScroll
            );
          }),
          (t.prototype.goTo = t.prototype.slickGoTo =
            function (e, t) {
              var i = this;
              i.changeSlide(
                { data: { message: 'index', index: parseInt(e) } },
                t
              );
            }),
          (t.prototype.init = function (t) {
            var i = this;
            e(i.$slider).hasClass('slick-initialized') ||
              (e(i.$slider).addClass('slick-initialized'),
              i.buildRows(),
              i.buildOut(),
              i.setProps(),
              i.startLoad(),
              i.loadSlider(),
              i.initializeEvents(),
              i.updateArrows(),
              i.updateDots()),
              t && i.$slider.trigger('init', [i]),
              i.options.accessibility === !0 && i.initADA();
          }),
          (t.prototype.initArrowEvents = function () {
            var e = this;
            e.options.arrows === !0 &&
              e.slideCount > e.options.slidesToShow &&
              (e.$prevArrow.on(
                'click.slick',
                { message: 'previous' },
                e.changeSlide
              ),
              e.$nextArrow.on(
                'click.slick',
                { message: 'next' },
                e.changeSlide
              ));
          }),
          (t.prototype.initDotEvents = function () {
            var t = this;
            t.options.dots === !0 &&
              t.slideCount > t.options.slidesToShow &&
              e('li', t.$dots).on(
                'click.slick',
                { message: 'index' },
                t.changeSlide
              ),
              t.options.dots === !0 &&
                t.options.pauseOnDotsHover === !0 &&
                t.options.autoplay === !0 &&
                e('li', t.$dots)
                  .on('mouseenter.slick', e.proxy(t.setPaused, t, !0))
                  .on('mouseleave.slick', e.proxy(t.setPaused, t, !1));
          }),
          (t.prototype.initializeEvents = function () {
            var t = this;
            t.initArrowEvents(),
              t.initDotEvents(),
              t.$list.on(
                'touchstart.slick mousedown.slick',
                { action: 'start' },
                t.swipeHandler
              ),
              t.$list.on(
                'touchmove.slick mousemove.slick',
                { action: 'move' },
                t.swipeHandler
              ),
              t.$list.on(
                'touchend.slick mouseup.slick',
                { action: 'end' },
                t.swipeHandler
              ),
              t.$list.on(
                'touchcancel.slick mouseleave.slick',
                { action: 'end' },
                t.swipeHandler
              ),
              t.$list.on('click.slick', t.clickHandler),
              e(document).on(t.visibilityChange, e.proxy(t.visibility, t)),
              t.$list.on('mouseenter.slick', e.proxy(t.setPaused, t, !0)),
              t.$list.on('mouseleave.slick', e.proxy(t.setPaused, t, !1)),
              t.options.accessibility === !0 &&
                t.$list.on('keydown.slick', t.keyHandler),
              t.options.focusOnSelect === !0 &&
                e(t.$slideTrack).children().on('click.slick', t.selectHandler),
              e(window).on(
                'orientationchange.slick.slick-' + t.instanceUid,
                e.proxy(t.orientationChange, t)
              ),
              e(window).on(
                'resize.slick.slick-' + t.instanceUid,
                e.proxy(t.resize, t)
              ),
              e('[draggable!=true]', t.$slideTrack).on(
                'dragstart',
                t.preventDefault
              ),
              e(window).on('load.slick.slick-' + t.instanceUid, t.setPosition),
              e(document).on(
                'ready.slick.slick-' + t.instanceUid,
                t.setPosition
              );
          }),
          (t.prototype.initUI = function () {
            var e = this;
            e.options.arrows === !0 &&
              e.slideCount > e.options.slidesToShow &&
              (e.$prevArrow.show(), e.$nextArrow.show()),
              e.options.dots === !0 &&
                e.slideCount > e.options.slidesToShow &&
                e.$dots.show(),
              e.options.autoplay === !0 && e.autoPlay();
          }),
          (t.prototype.keyHandler = function (e) {
            var t = this;
            e.target.tagName.match('TEXTAREA|INPUT|SELECT') ||
              (37 === e.keyCode && t.options.accessibility === !0
                ? t.changeSlide({ data: { message: 'previous' } })
                : 39 === e.keyCode &&
                  t.options.accessibility === !0 &&
                  t.changeSlide({ data: { message: 'next' } }));
          }),
          (t.prototype.lazyLoad = function () {
            function t(t) {
              e('img[data-lazy]', t).each(function () {
                var t = e(this),
                  i = e(this).attr('data-lazy'),
                  n = document.createElement('img');
                (n.onload = function () {
                  t.animate({ opacity: 0 }, 100, function () {
                    t.attr('src', i).animate({ opacity: 1 }, 200, function () {
                      t.removeAttr('data-lazy').removeClass('slick-loading');
                    });
                  });
                }),
                  (n.src = i);
              });
            }
            var i,
              n,
              o,
              r,
              a = this;
            a.options.centerMode === !0
              ? a.options.infinite === !0
                ? ((o = a.currentSlide + (a.options.slidesToShow / 2 + 1)),
                  (r = o + a.options.slidesToShow + 2))
                : ((o = Math.max(
                    0,
                    a.currentSlide - (a.options.slidesToShow / 2 + 1)
                  )),
                  (r = 2 + (a.options.slidesToShow / 2 + 1) + a.currentSlide))
              : ((o = a.options.infinite
                  ? a.options.slidesToShow + a.currentSlide
                  : a.currentSlide),
                (r = o + a.options.slidesToShow),
                a.options.fade === !0 &&
                  (o > 0 && o--, r <= a.slideCount && r++)),
              (i = a.$slider.find('.slick-slide').slice(o, r)),
              t(i),
              a.slideCount <= a.options.slidesToShow
                ? ((n = a.$slider.find('.slick-slide')), t(n))
                : a.currentSlide >= a.slideCount - a.options.slidesToShow
                ? ((n = a.$slider
                    .find('.slick-cloned')
                    .slice(0, a.options.slidesToShow)),
                  t(n))
                : 0 === a.currentSlide &&
                  ((n = a.$slider
                    .find('.slick-cloned')
                    .slice(-1 * a.options.slidesToShow)),
                  t(n));
          }),
          (t.prototype.loadSlider = function () {
            var e = this;
            e.setPosition(),
              e.$slideTrack.css({ opacity: 1 }),
              e.$slider.removeClass('slick-loading'),
              e.initUI(),
              'progressive' === e.options.lazyLoad && e.progressiveLazyLoad();
          }),
          (t.prototype.next = t.prototype.slickNext =
            function () {
              var e = this;
              e.changeSlide({ data: { message: 'next' } });
            }),
          (t.prototype.orientationChange = function () {
            var e = this;
            e.checkResponsive(), e.setPosition();
          }),
          (t.prototype.pause = t.prototype.slickPause =
            function () {
              var e = this;
              e.autoPlayClear(), (e.paused = !0);
            }),
          (t.prototype.play = t.prototype.slickPlay =
            function () {
              var e = this;
              (e.paused = !1), e.autoPlay();
            }),
          (t.prototype.postSlide = function (e) {
            var t = this;
            t.$slider.trigger('afterChange', [t, e]),
              (t.animating = !1),
              t.setPosition(),
              (t.swipeLeft = null),
              t.options.autoplay === !0 && t.paused === !1 && t.autoPlay(),
              t.options.accessibility === !0 && t.initADA();
          }),
          (t.prototype.prev = t.prototype.slickPrev =
            function () {
              var e = this;
              e.changeSlide({ data: { message: 'previous' } });
            }),
          (t.prototype.preventDefault = function (e) {
            e.preventDefault();
          }),
          (t.prototype.progressiveLazyLoad = function () {
            var t,
              i,
              n = this;
            (t = e('img[data-lazy]', n.$slider).length),
              t > 0 &&
                ((i = e('img[data-lazy]', n.$slider).first()),
                i.attr('src', null),
                i
                  .attr('src', i.attr('data-lazy'))
                  .removeClass('slick-loading')
                  .load(function () {
                    i.removeAttr('data-lazy'),
                      n.progressiveLazyLoad(),
                      n.options.adaptiveHeight === !0 && n.setPosition();
                  })
                  .error(function () {
                    i.removeAttr('data-lazy'), n.progressiveLazyLoad();
                  }));
          }),
          (t.prototype.refresh = function (t) {
            var i,
              n,
              o = this;
            (n = o.slideCount - o.options.slidesToShow),
              o.options.infinite ||
                (o.slideCount <= o.options.slidesToShow
                  ? (o.currentSlide = 0)
                  : o.currentSlide > n && (o.currentSlide = n)),
              (i = o.currentSlide),
              o.destroy(!0),
              e.extend(o, o.initials, { currentSlide: i }),
              o.init(),
              t || o.changeSlide({ data: { message: 'index', index: i } }, !1);
          }),
          (t.prototype.registerBreakpoints = function () {
            var t,
              i,
              n,
              o = this,
              r = o.options.responsive || null;
            if ('array' === e.type(r) && r.length) {
              o.respondTo = o.options.respondTo || 'window';
              for (t in r)
                if (
                  ((n = o.breakpoints.length - 1),
                  (i = r[t].breakpoint),
                  r.hasOwnProperty(t))
                ) {
                  for (; n >= 0; )
                    o.breakpoints[n] &&
                      o.breakpoints[n] === i &&
                      o.breakpoints.splice(n, 1),
                      n--;
                  o.breakpoints.push(i),
                    (o.breakpointSettings[i] = r[t].settings);
                }
              o.breakpoints.sort(function (e, t) {
                return o.options.mobileFirst ? e - t : t - e;
              });
            }
          }),
          (t.prototype.reinit = function () {
            var t = this;
            (t.$slides = t.$slideTrack
              .children(t.options.slide)
              .addClass('slick-slide')),
              (t.slideCount = t.$slides.length),
              t.currentSlide >= t.slideCount &&
                0 !== t.currentSlide &&
                (t.currentSlide = t.currentSlide - t.options.slidesToScroll),
              t.slideCount <= t.options.slidesToShow && (t.currentSlide = 0),
              t.registerBreakpoints(),
              t.setProps(),
              t.setupInfinite(),
              t.buildArrows(),
              t.updateArrows(),
              t.initArrowEvents(),
              t.buildDots(),
              t.updateDots(),
              t.initDotEvents(),
              t.checkResponsive(!1, !0),
              t.options.focusOnSelect === !0 &&
                e(t.$slideTrack).children().on('click.slick', t.selectHandler),
              t.setSlideClasses(0),
              t.setPosition(),
              t.$slider.trigger('reInit', [t]),
              t.options.autoplay === !0 && t.focusHandler();
          }),
          (t.prototype.resize = function () {
            var t = this;
            e(window).width() !== t.windowWidth &&
              (clearTimeout(t.windowDelay),
              (t.windowDelay = window.setTimeout(function () {
                (t.windowWidth = e(window).width()),
                  t.checkResponsive(),
                  t.unslicked || t.setPosition();
              }, 50)));
          }),
          (t.prototype.removeSlide = t.prototype.slickRemove =
            function (e, t, i) {
              var n = this;
              return (
                'boolean' == typeof e
                  ? ((t = e), (e = t === !0 ? 0 : n.slideCount - 1))
                  : (e = t === !0 ? --e : e),
                !(n.slideCount < 1 || 0 > e || e > n.slideCount - 1) &&
                  (n.unload(),
                  i === !0
                    ? n.$slideTrack.children().remove()
                    : n.$slideTrack.children(this.options.slide).eq(e).remove(),
                  (n.$slides = n.$slideTrack.children(this.options.slide)),
                  n.$slideTrack.children(this.options.slide).detach(),
                  n.$slideTrack.append(n.$slides),
                  (n.$slidesCache = n.$slides),
                  void n.reinit())
              );
            }),
          (t.prototype.setCSS = function (e) {
            var t,
              i,
              n = this,
              o = {};
            n.options.rtl === !0 && (e = -e),
              (t = 'left' == n.positionProp ? Math.ceil(e) + 'px' : '0px'),
              (i = 'top' == n.positionProp ? Math.ceil(e) + 'px' : '0px'),
              (o[n.positionProp] = e),
              n.transformsEnabled === !1
                ? n.$slideTrack.css(o)
                : ((o = {}),
                  n.cssTransitions === !1
                    ? ((o[n.animType] = 'translate(' + t + ', ' + i + ')'),
                      n.$slideTrack.css(o))
                    : ((o[n.animType] =
                        'translate3d(' + t + ', ' + i + ', 0px)'),
                      n.$slideTrack.css(o)));
          }),
          (t.prototype.setDimensions = function () {
            var e = this;
            e.options.vertical === !1
              ? e.options.centerMode === !0 &&
                e.$list.css({ padding: '0px ' + e.options.centerPadding })
              : (e.$list.height(
                  e.$slides.first().outerHeight(!0) * e.options.slidesToShow
                ),
                e.options.centerMode === !0 &&
                  e.$list.css({ padding: e.options.centerPadding + ' 0px' })),
              (e.listWidth = e.$list.width()),
              (e.listHeight = e.$list.height()),
              e.options.vertical === !1 && e.options.variableWidth === !1
                ? ((e.slideWidth = Math.ceil(
                    e.listWidth / e.options.slidesToShow
                  )),
                  e.$slideTrack.width(
                    Math.ceil(
                      e.slideWidth *
                        e.$slideTrack.children('.slick-slide').length
                    )
                  ))
                : e.options.variableWidth === !0
                ? e.$slideTrack.width(5e3 * e.slideCount)
                : ((e.slideWidth = Math.ceil(e.listWidth)),
                  e.$slideTrack.height(
                    Math.ceil(
                      e.$slides.first().outerHeight(!0) *
                        e.$slideTrack.children('.slick-slide').length
                    )
                  ));
            var t =
              e.$slides.first().outerWidth(!0) - e.$slides.first().width();
            e.options.variableWidth === !1 &&
              e.$slideTrack.children('.slick-slide').width(e.slideWidth - t);
          }),
          (t.prototype.setFade = function () {
            var t,
              i = this;
            i.$slides.each(function (n, o) {
              (t = i.slideWidth * n * -1),
                i.options.rtl === !0
                  ? e(o).css({
                      position: 'relative',
                      right: t,
                      top: 0,
                      zIndex: i.options.zIndex - 2,
                      opacity: 0,
                    })
                  : e(o).css({
                      position: 'relative',
                      left: t,
                      top: 0,
                      zIndex: i.options.zIndex - 2,
                      opacity: 0,
                    });
            }),
              i.$slides
                .eq(i.currentSlide)
                .css({ zIndex: i.options.zIndex - 1, opacity: 1 });
          }),
          (t.prototype.setHeight = function () {
            var e = this;
            if (
              1 === e.options.slidesToShow &&
              e.options.adaptiveHeight === !0 &&
              e.options.vertical === !1
            ) {
              var t = e.$slides.eq(e.currentSlide).outerHeight(!0);
              e.$list.css('height', t);
            }
          }),
          (t.prototype.setOption = t.prototype.slickSetOption =
            function (t, i, n) {
              var o,
                r,
                a = this;
              if ('responsive' === t && 'array' === e.type(i))
                for (r in i)
                  if ('array' !== e.type(a.options.responsive))
                    a.options.responsive = [i[r]];
                  else {
                    for (o = a.options.responsive.length - 1; o >= 0; )
                      a.options.responsive[o].breakpoint === i[r].breakpoint &&
                        a.options.responsive.splice(o, 1),
                        o--;
                    a.options.responsive.push(i[r]);
                  }
              else a.options[t] = i;
              n === !0 && (a.unload(), a.reinit());
            }),
          (t.prototype.setPosition = function () {
            var e = this;
            e.setDimensions(),
              e.setHeight(),
              e.options.fade === !1
                ? e.setCSS(e.getLeft(e.currentSlide))
                : e.setFade(),
              e.$slider.trigger('setPosition', [e]);
          }),
          (t.prototype.setProps = function () {
            var e = this,
              t = document.body.style;
            (e.positionProp = e.options.vertical === !0 ? 'top' : 'left'),
              'top' === e.positionProp
                ? e.$slider.addClass('slick-vertical')
                : e.$slider.removeClass('slick-vertical'),
              (void 0 !== t.WebkitTransition ||
                void 0 !== t.MozTransition ||
                void 0 !== t.msTransition) &&
                e.options.useCSS === !0 &&
                (e.cssTransitions = !0),
              e.options.fade &&
                ('number' == typeof e.options.zIndex
                  ? e.options.zIndex < 3 && (e.options.zIndex = 3)
                  : (e.options.zIndex = e.defaults.zIndex)),
              void 0 !== t.OTransform &&
                ((e.animType = 'OTransform'),
                (e.transformType = '-o-transform'),
                (e.transitionType = 'OTransition'),
                void 0 === t.perspectiveProperty &&
                  void 0 === t.webkitPerspective &&
                  (e.animType = !1)),
              void 0 !== t.MozTransform &&
                ((e.animType = 'MozTransform'),
                (e.transformType = '-moz-transform'),
                (e.transitionType = 'MozTransition'),
                void 0 === t.perspectiveProperty &&
                  void 0 === t.MozPerspective &&
                  (e.animType = !1)),
              void 0 !== t.webkitTransform &&
                ((e.animType = 'webkitTransform'),
                (e.transformType = '-webkit-transform'),
                (e.transitionType = 'webkitTransition'),
                void 0 === t.perspectiveProperty &&
                  void 0 === t.webkitPerspective &&
                  (e.animType = !1)),
              void 0 !== t.msTransform &&
                ((e.animType = 'msTransform'),
                (e.transformType = '-ms-transform'),
                (e.transitionType = 'msTransition'),
                void 0 === t.msTransform && (e.animType = !1)),
              void 0 !== t.transform &&
                e.animType !== !1 &&
                ((e.animType = 'transform'),
                (e.transformType = 'transform'),
                (e.transitionType = 'transition')),
              (e.transformsEnabled =
                e.options.useTransform &&
                null !== e.animType &&
                e.animType !== !1);
          }),
          (t.prototype.setSlideClasses = function (e) {
            var t,
              i,
              n,
              o,
              r = this;
            (i = r.$slider
              .find('.slick-slide')
              .removeClass('slick-active slick-center slick-current')
              .attr('aria-hidden', 'true')),
              r.$slides.eq(e).addClass('slick-current'),
              r.options.centerMode === !0
                ? ((t = Math.floor(r.options.slidesToShow / 2)),
                  r.options.infinite === !0 &&
                    (e >= t && e <= r.slideCount - 1 - t
                      ? r.$slides
                          .slice(e - t, e + t + 1)
                          .addClass('slick-active')
                          .attr('aria-hidden', 'false')
                      : ((n = r.options.slidesToShow + e),
                        i
                          .slice(n - t + 1, n + t + 2)
                          .addClass('slick-active')
                          .attr('aria-hidden', 'false')),
                    0 === e
                      ? i
                          .eq(i.length - 1 - r.options.slidesToShow)
                          .addClass('slick-center')
                      : e === r.slideCount - 1 &&
                        i.eq(r.options.slidesToShow).addClass('slick-center')),
                  r.$slides.eq(e).addClass('slick-center'))
                : e >= 0 && e <= r.slideCount - r.options.slidesToShow
                ? r.$slides
                    .slice(e, e + r.options.slidesToShow)
                    .addClass('slick-active')
                    .attr('aria-hidden', 'false')
                : i.length <= r.options.slidesToShow
                ? i.addClass('slick-active').attr('aria-hidden', 'false')
                : ((o = r.slideCount % r.options.slidesToShow),
                  (n =
                    r.options.infinite === !0 ? r.options.slidesToShow + e : e),
                  r.options.slidesToShow == r.options.slidesToScroll &&
                  r.slideCount - e < r.options.slidesToShow
                    ? i
                        .slice(n - (r.options.slidesToShow - o), n + o)
                        .addClass('slick-active')
                        .attr('aria-hidden', 'false')
                    : i
                        .slice(n, n + r.options.slidesToShow)
                        .addClass('slick-active')
                        .attr('aria-hidden', 'false')),
              'ondemand' === r.options.lazyLoad && r.lazyLoad();
          }),
          (t.prototype.setupInfinite = function () {
            var t,
              i,
              n,
              o = this;
            if (
              (o.options.fade === !0 && (o.options.centerMode = !1),
              o.options.infinite === !0 &&
                o.options.fade === !1 &&
                ((i = null), o.slideCount > o.options.slidesToShow))
            ) {
              for (
                n =
                  o.options.centerMode === !0
                    ? o.options.slidesToShow + 1
                    : o.options.slidesToShow,
                  t = o.slideCount;
                t > o.slideCount - n;
                t -= 1
              )
                (i = t - 1),
                  e(o.$slides[i])
                    .clone(!0)
                    .attr('id', '')
                    .attr('data-slick-index', i - o.slideCount)
                    .prependTo(o.$slideTrack)
                    .addClass('slick-cloned');
              for (t = 0; n > t; t += 1)
                (i = t),
                  e(o.$slides[i])
                    .clone(!0)
                    .attr('id', '')
                    .attr('data-slick-index', i + o.slideCount)
                    .appendTo(o.$slideTrack)
                    .addClass('slick-cloned');
              o.$slideTrack
                .find('.slick-cloned')
                .find('[id]')
                .each(function () {
                  e(this).attr('id', '');
                });
            }
          }),
          (t.prototype.setPaused = function (e) {
            var t = this;
            t.options.autoplay === !0 &&
              t.options.pauseOnHover === !0 &&
              ((t.paused = e), e ? t.autoPlayClear() : t.autoPlay());
          }),
          (t.prototype.selectHandler = function (t) {
            var i = this,
              n = e(t.target).is('.slick-slide')
                ? e(t.target)
                : e(t.target).parents('.slick-slide'),
              o = parseInt(n.attr('data-slick-index'));
            return (
              o || (o = 0),
              i.slideCount <= i.options.slidesToShow
                ? (i.setSlideClasses(o), void i.asNavFor(o))
                : void i.slideHandler(o)
            );
          }),
          (t.prototype.slideHandler = function (e, t, i) {
            var n,
              o,
              r,
              a,
              s = null,
              l = this;
            return (
              (t = t || !1),
              (l.animating === !0 && l.options.waitForAnimate === !0) ||
              (l.options.fade === !0 && l.currentSlide === e) ||
              l.slideCount <= l.options.slidesToShow
                ? void 0
                : (t === !1 && l.asNavFor(e),
                  (n = e),
                  (s = l.getLeft(n)),
                  (a = l.getLeft(l.currentSlide)),
                  (l.currentLeft = null === l.swipeLeft ? a : l.swipeLeft),
                  l.options.infinite === !1 &&
                  l.options.centerMode === !1 &&
                  (0 > e || e > l.getDotCount() * l.options.slidesToScroll)
                    ? void (
                        l.options.fade === !1 &&
                        ((n = l.currentSlide),
                        i !== !0
                          ? l.animateSlide(a, function () {
                              l.postSlide(n);
                            })
                          : l.postSlide(n))
                      )
                    : l.options.infinite === !1 &&
                      l.options.centerMode === !0 &&
                      (0 > e || e > l.slideCount - l.options.slidesToScroll)
                    ? void (
                        l.options.fade === !1 &&
                        ((n = l.currentSlide),
                        i !== !0
                          ? l.animateSlide(a, function () {
                              l.postSlide(n);
                            })
                          : l.postSlide(n))
                      )
                    : (l.options.autoplay === !0 &&
                        clearInterval(l.autoPlayTimer),
                      (o =
                        0 > n
                          ? l.slideCount % l.options.slidesToScroll !== 0
                            ? l.slideCount -
                              (l.slideCount % l.options.slidesToScroll)
                            : l.slideCount + n
                          : n >= l.slideCount
                          ? l.slideCount % l.options.slidesToScroll !== 0
                            ? 0
                            : n - l.slideCount
                          : n),
                      (l.animating = !0),
                      l.$slider.trigger('beforeChange', [l, l.currentSlide, o]),
                      (r = l.currentSlide),
                      (l.currentSlide = o),
                      l.setSlideClasses(l.currentSlide),
                      l.updateDots(),
                      l.updateArrows(),
                      l.options.fade === !0
                        ? (i !== !0
                            ? (l.fadeSlideOut(r),
                              l.fadeSlide(o, function () {
                                l.postSlide(o);
                              }))
                            : l.postSlide(o),
                          void l.animateHeight())
                        : void (i !== !0
                            ? l.animateSlide(s, function () {
                                l.postSlide(o);
                              })
                            : l.postSlide(o))))
            );
          }),
          (t.prototype.startLoad = function () {
            var e = this;
            e.options.arrows === !0 &&
              e.slideCount > e.options.slidesToShow &&
              (e.$prevArrow.hide(), e.$nextArrow.hide()),
              e.options.dots === !0 &&
                e.slideCount > e.options.slidesToShow &&
                e.$dots.hide(),
              e.$slider.addClass('slick-loading');
          }),
          (t.prototype.swipeDirection = function () {
            var e,
              t,
              i,
              n,
              o = this;
            return (
              (e = o.touchObject.startX - o.touchObject.curX),
              (t = o.touchObject.startY - o.touchObject.curY),
              (i = Math.atan2(t, e)),
              (n = Math.round((180 * i) / Math.PI)),
              0 > n && (n = 360 - Math.abs(n)),
              45 >= n && n >= 0
                ? o.options.rtl === !1
                  ? 'left'
                  : 'right'
                : 360 >= n && n >= 315
                ? o.options.rtl === !1
                  ? 'left'
                  : 'right'
                : n >= 135 && 225 >= n
                ? o.options.rtl === !1
                  ? 'right'
                  : 'left'
                : o.options.verticalSwiping === !0
                ? n >= 35 && 135 >= n
                  ? 'left'
                  : 'right'
                : 'vertical'
            );
          }),
          (t.prototype.swipeEnd = function (e) {
            var t,
              i = this;
            if (
              ((i.dragging = !1),
              (i.shouldClick = !(i.touchObject.swipeLength > 10)),
              void 0 === i.touchObject.curX)
            )
              return !1;
            if (
              (i.touchObject.edgeHit === !0 &&
                i.$slider.trigger('edge', [i, i.swipeDirection()]),
              i.touchObject.swipeLength >= i.touchObject.minSwipe)
            )
              switch (i.swipeDirection()) {
                case 'left':
                  (t = i.options.swipeToSlide
                    ? i.checkNavigable(i.currentSlide + i.getSlideCount())
                    : i.currentSlide + i.getSlideCount()),
                    i.slideHandler(t),
                    (i.currentDirection = 0),
                    (i.touchObject = {}),
                    i.$slider.trigger('swipe', [i, 'left']);
                  break;
                case 'right':
                  (t = i.options.swipeToSlide
                    ? i.checkNavigable(i.currentSlide - i.getSlideCount())
                    : i.currentSlide - i.getSlideCount()),
                    i.slideHandler(t),
                    (i.currentDirection = 1),
                    (i.touchObject = {}),
                    i.$slider.trigger('swipe', [i, 'right']);
              }
            else
              i.touchObject.startX !== i.touchObject.curX &&
                (i.slideHandler(i.currentSlide), (i.touchObject = {}));
          }),
          (t.prototype.swipeHandler = function (e) {
            var t = this;
            if (
              !(
                t.options.swipe === !1 ||
                ('ontouchend' in document && t.options.swipe === !1) ||
                (t.options.draggable === !1 && -1 !== e.type.indexOf('mouse'))
              )
            )
              switch (
                ((t.touchObject.fingerCount =
                  e.originalEvent && void 0 !== e.originalEvent.touches
                    ? e.originalEvent.touches.length
                    : 1),
                (t.touchObject.minSwipe =
                  t.listWidth / t.options.touchThreshold),
                t.options.verticalSwiping === !0 &&
                  (t.touchObject.minSwipe =
                    t.listHeight / t.options.touchThreshold),
                e.data.action)
              ) {
                case 'start':
                  t.swipeStart(e);
                  break;
                case 'move':
                  t.swipeMove(e);
                  break;
                case 'end':
                  t.swipeEnd(e);
              }
          }),
          (t.prototype.swipeMove = function (e) {
            var t,
              i,
              n,
              o,
              r,
              a = this;
            return (
              (r = void 0 !== e.originalEvent ? e.originalEvent.touches : null),
              !(!a.dragging || (r && 1 !== r.length)) &&
                ((t = a.getLeft(a.currentSlide)),
                (a.touchObject.curX = void 0 !== r ? r[0].pageX : e.clientX),
                (a.touchObject.curY = void 0 !== r ? r[0].pageY : e.clientY),
                (a.touchObject.swipeLength = Math.round(
                  Math.sqrt(
                    Math.pow(a.touchObject.curX - a.touchObject.startX, 2)
                  )
                )),
                a.options.verticalSwiping === !0 &&
                  (a.touchObject.swipeLength = Math.round(
                    Math.sqrt(
                      Math.pow(a.touchObject.curY - a.touchObject.startY, 2)
                    )
                  )),
                (i = a.swipeDirection()),
                'vertical' !== i
                  ? (void 0 !== e.originalEvent &&
                      a.touchObject.swipeLength > 4 &&
                      e.preventDefault(),
                    (o =
                      (a.options.rtl === !1 ? 1 : -1) *
                      (a.touchObject.curX > a.touchObject.startX ? 1 : -1)),
                    a.options.verticalSwiping === !0 &&
                      (o = a.touchObject.curY > a.touchObject.startY ? 1 : -1),
                    (n = a.touchObject.swipeLength),
                    (a.touchObject.edgeHit = !1),
                    a.options.infinite === !1 &&
                      ((0 === a.currentSlide && 'right' === i) ||
                        (a.currentSlide >= a.getDotCount() && 'left' === i)) &&
                      ((n = a.touchObject.swipeLength * a.options.edgeFriction),
                      (a.touchObject.edgeHit = !0)),
                    a.options.vertical === !1
                      ? (a.swipeLeft = t + n * o)
                      : (a.swipeLeft =
                          t + n * (a.$list.height() / a.listWidth) * o),
                    a.options.verticalSwiping === !0 &&
                      (a.swipeLeft = t + n * o),
                    a.options.fade !== !0 &&
                      a.options.touchMove !== !1 &&
                      (a.animating === !0
                        ? ((a.swipeLeft = null), !1)
                        : void a.setCSS(a.swipeLeft)))
                  : void 0)
            );
          }),
          (t.prototype.swipeStart = function (e) {
            var t,
              i = this;
            return 1 !== i.touchObject.fingerCount ||
              i.slideCount <= i.options.slidesToShow
              ? ((i.touchObject = {}), !1)
              : (void 0 !== e.originalEvent &&
                  void 0 !== e.originalEvent.touches &&
                  (t = e.originalEvent.touches[0]),
                (i.touchObject.startX = i.touchObject.curX =
                  void 0 !== t ? t.pageX : e.clientX),
                (i.touchObject.startY = i.touchObject.curY =
                  void 0 !== t ? t.pageY : e.clientY),
                void (i.dragging = !0));
          }),
          (t.prototype.unfilterSlides = t.prototype.slickUnfilter =
            function () {
              var e = this;
              null !== e.$slidesCache &&
                (e.unload(),
                e.$slideTrack.children(this.options.slide).detach(),
                e.$slidesCache.appendTo(e.$slideTrack),
                e.reinit());
            }),
          (t.prototype.unload = function () {
            var t = this;
            e('.slick-cloned', t.$slider).remove(),
              t.$dots && t.$dots.remove(),
              t.$prevArrow &&
                t.htmlExpr.test(t.options.prevArrow) &&
                t.$prevArrow.remove(),
              t.$nextArrow &&
                t.htmlExpr.test(t.options.nextArrow) &&
                t.$nextArrow.remove(),
              t.$slides
                .removeClass(
                  'slick-slide slick-active slick-visible slick-current'
                )
                .attr('aria-hidden', 'true')
                .css('width', '');
          }),
          (t.prototype.unslick = function (e) {
            var t = this;
            t.$slider.trigger('unslick', [t, e]), t.destroy();
          }),
          (t.prototype.updateArrows = function () {
            var e,
              t = this;
            (e = Math.floor(t.options.slidesToShow / 2)),
              t.options.arrows === !0 &&
                t.slideCount > t.options.slidesToShow &&
                !t.options.infinite &&
                (t.$prevArrow
                  .removeClass('slick-disabled')
                  .attr('aria-disabled', 'false'),
                t.$nextArrow
                  .removeClass('slick-disabled')
                  .attr('aria-disabled', 'false'),
                0 === t.currentSlide
                  ? (t.$prevArrow
                      .addClass('slick-disabled')
                      .attr('aria-disabled', 'true'),
                    t.$nextArrow
                      .removeClass('slick-disabled')
                      .attr('aria-disabled', 'false'))
                  : t.currentSlide >= t.slideCount - t.options.slidesToShow &&
                    t.options.centerMode === !1
                  ? (t.$nextArrow
                      .addClass('slick-disabled')
                      .attr('aria-disabled', 'true'),
                    t.$prevArrow
                      .removeClass('slick-disabled')
                      .attr('aria-disabled', 'false'))
                  : t.currentSlide >= t.slideCount - 1 &&
                    t.options.centerMode === !0 &&
                    (t.$nextArrow
                      .addClass('slick-disabled')
                      .attr('aria-disabled', 'true'),
                    t.$prevArrow
                      .removeClass('slick-disabled')
                      .attr('aria-disabled', 'false')));
          }),
          (t.prototype.updateDots = function () {
            var e = this;
            null !== e.$dots &&
              (e.$dots
                .find('li')
                .removeClass('slick-active')
                .attr('aria-hidden', 'true'),
              e.$dots
                .find('li')
                .eq(Math.floor(e.currentSlide / e.options.slidesToScroll))
                .addClass('slick-active')
                .attr('aria-hidden', 'false'));
          }),
          (t.prototype.visibility = function () {
            var e = this;
            document[e.hidden]
              ? ((e.paused = !0), e.autoPlayClear())
              : e.options.autoplay === !0 && ((e.paused = !1), e.autoPlay());
          }),
          (t.prototype.initADA = function () {
            var t = this;
            t.$slides
              .add(t.$slideTrack.find('.slick-cloned'))
              .attr({ 'aria-hidden': 'true', tabindex: '-1' })
              .find('a, input, button, select')
              .attr({ tabindex: '-1' }),
              t.$slideTrack.attr('role', 'listbox'),
              t.$slides
                .not(t.$slideTrack.find('.slick-cloned'))
                .each(function (i) {
                  e(this).attr({
                    role: 'option',
                    'aria-describedby': 'slick-slide' + t.instanceUid + i,
                  });
                }),
              null !== t.$dots &&
                t.$dots
                  .attr('role', 'tablist')
                  .find('li')
                  .each(function (i) {
                    e(this).attr({
                      role: 'presentation',
                      'aria-selected': 'false',
                      'aria-controls': 'navigation' + t.instanceUid + i,
                      id: 'slick-slide' + t.instanceUid + i,
                    });
                  })
                  .first()
                  .attr('aria-selected', 'true')
                  .end()
                  .find('button')
                  .attr('role', 'button')
                  .end()
                  .closest('div')
                  .attr('role', 'toolbar'),
              t.activateADA();
          }),
          (t.prototype.activateADA = function () {
            var e = this;
            e.$slideTrack
              .find('.slick-active')
              .attr({ 'aria-hidden': 'false' })
              .find('a, input, button, select')
              .attr({ tabindex: '0' });
          }),
          (t.prototype.focusHandler = function () {
            var t = this;
            t.$slider.on('focus.slick blur.slick', '*', function (i) {
              i.stopImmediatePropagation();
              var n = e(this);
              setTimeout(function () {
                t.isPlay &&
                  (n.is(':focus')
                    ? (t.autoPlayClear(), (t.paused = !0))
                    : ((t.paused = !1), t.autoPlay()));
              }, 0);
            });
          }),
          (e.fn.slick = function () {
            var e,
              i,
              n = this,
              o = arguments[0],
              r = Array.prototype.slice.call(arguments, 1),
              a = n.length;
            for (e = 0; a > e; e++)
              if (
                ('object' == typeof o || 'undefined' == typeof o
                  ? (n[e].slick = new t(n[e], o))
                  : (i = n[e].slick[o].apply(n[e].slick, r)),
                'undefined' != typeof i)
              )
                return i;
            return n;
          });
      }),
      !(function (e) {
        function t() {
          var t,
            i,
            n = { height: c.innerHeight, width: c.innerWidth };
          return (
            n.height ||
              ((t = l.compatMode),
              (t || !e.support.boxModel) &&
                ((i = 'CSS1Compat' === t ? d : l.body),
                (n = { height: i.clientHeight, width: i.clientWidth }))),
            n
          );
        }
        function i() {
          return {
            top: c.pageYOffset || d.scrollTop || l.body.scrollTop,
            left: c.pageXOffset || d.scrollLeft || l.body.scrollLeft,
          };
        }
        function n() {
          var n,
            a = e(),
            l = 0;
          if (
            (e.each(s, function (e, t) {
              var i = t.data.selector,
                n = t.$element;
              a = a.add(i ? n.find(i) : n);
            }),
            (n = a.length))
          )
            for (o = o || t(), r = r || i(); n > l; l++)
              if (e.contains(d, a[l])) {
                var c,
                  u,
                  p,
                  f = e(a[l]),
                  h = { height: f.height(), width: f.width() },
                  g = f.offset(),
                  m = f.data('inview');
                if (!r || !o) return;
                g.top + h.height > r.top &&
                g.top < r.top + o.height &&
                g.left + h.width > r.left &&
                g.left < r.left + o.width
                  ? ((c =
                      r.left > g.left
                        ? 'right'
                        : r.left + o.width < g.left + h.width
                        ? 'left'
                        : 'both'),
                    (u =
                      r.top > g.top
                        ? 'bottom'
                        : r.top + o.height < g.top + h.height
                        ? 'top'
                        : 'both'),
                    (p = c + '-' + u),
                    (m && m === p) ||
                      f.data('inview', p).trigger('inview', [!0, c, u]))
                  : m && f.data('inview', !1).trigger('inview', [!1]);
              }
        }
        var o,
          r,
          a,
          s = {},
          l = document,
          c = window,
          d = l.documentElement,
          u = e.expando;
        (e.event.special.inview = {
          add: function (t) {
            (s[t.guid + '-' + this[u]] = { data: t, $element: e(this) }),
              a || e.isEmptyObject(s) || (a = setInterval(n, 250));
          },
          remove: function (t) {
            try {
              delete s[t.guid + '-' + this[u]];
            } catch (i) {}
            e.isEmptyObject(s) && (clearInterval(a), (a = null));
          },
        }),
          e(c).bind('scroll resize scrollstop', function () {
            o = r = null;
          }),
          !d.addEventListener &&
            d.attachEvent &&
            d.attachEvent('onfocusin', function () {
              r = null;
            });
      })(jQuery),
      !(function (e, t, i) {
        'use strict';
        function n(i) {
          if (
            ((o = t.documentElement),
            (r = t.body),
            Y(),
            (se = this),
            (i = i || {}),
            (pe = i.constants || {}),
            i.easing)
          )
            for (var n in i.easing) K[n] = i.easing[n];
          (we = i.edgeStrategy || 'set'),
            (de = {
              beforerender: i.beforerender,
              render: i.render,
              keyframe: i.keyframe,
            }),
            (ue = i.forceHeight !== !1),
            ue && (Oe = i.scale || 1),
            (fe = i.mobileDeceleration || T),
            (ge = i.smoothScrolling !== !1),
            (me = i.smoothScrollingDuration || D),
            (ve = { targetTop: se.getScrollTop() }),
            (ze = (
              i.mobileCheck ||
              function () {
                return /Android|iPhone|iPad|iPod|BlackBerry/i.test(
                  navigator.userAgent || navigator.vendor || e.opera
                );
              }
            )()),
            ze
              ? ((ce = t.getElementById(i.skrollrBody || C)),
                ce && ae(),
                X(),
                Me(o, [y, k], [w]))
              : Me(o, [y, b], [w]),
            se.refresh(),
            Se(e, 'resize orientationchange', function () {
              var e = o.clientWidth,
                t = o.clientHeight;
              (t !== Be || e !== qe) && ((Be = t), (qe = e), (We = !0));
            });
          var a = U();
          return (
            (function s() {
              J(), (ke = a(s));
            })(),
            se
          );
        }
        var o,
          r,
          a = {
            get: function () {
              return se;
            },
            init: function (e) {
              return se || new n(e);
            },
            VERSION: '0.6.30',
          },
          s = Object.prototype.hasOwnProperty,
          l = e.Math,
          c = e.getComputedStyle,
          d = 'touchstart',
          u = 'touchmove',
          p = 'touchcancel',
          f = 'touchend',
          h = 'skrollable',
          g = h + '-before',
          m = h + '-between',
          v = h + '-after',
          y = 'skrollr',
          w = 'no-' + y,
          b = y + '-desktop',
          k = y + '-mobile',
          S = 'linear',
          x = 1e3,
          T = 0.004,
          C = 'skrollr-body',
          D = 200,
          I = 'start',
          _ = 'end',
          $ = 'center',
          M = 'bottom',
          E = '___skrollable_id',
          j = /^(?:input|textarea|button|select)$/i,
          A = /^\s+|\s+$/g,
          N =
            /^data(?:-(_\w+))?(?:-?(-?\d*\.?\d+p?))?(?:-?(start|end|top|center|bottom))?(?:-?(top|center|bottom))?$/,
          H = /\s*(@?[\w\-\[\]]+)\s*:\s*(.+?)\s*(?:;|$)/gi,
          O = /^(@?[a-z\-]+)\[(\w+)\]$/,
          P = /-([a-z0-9_])/g,
          L = function (e, t) {
            return t.toUpperCase();
          },
          F = /[\-+]?[\d]*\.?[\d]+/g,
          q = /\{\?\}/g,
          B = /rgba?\(\s*-?\d+\s*,\s*-?\d+\s*,\s*-?\d+/g,
          W = /[a-z\-]+-gradient/g,
          R = '',
          z = '',
          Y = function () {
            var e = /^(?:O|Moz|webkit|ms)|(?:-(?:o|moz|webkit|ms)-)/;
            if (c) {
              var t = c(r, null);
              for (var i in t)
                if ((R = i.match(e) || (+i == i && t[i].match(e)))) break;
              if (!R) return void (R = z = '');
              (R = R[0]),
                '-' === R.slice(0, 1)
                  ? ((z = R),
                    (R = {
                      '-webkit-': 'webkit',
                      '-moz-': 'Moz',
                      '-ms-': 'ms',
                      '-o-': 'O',
                    }[R]))
                  : (z = '-' + R.toLowerCase() + '-');
            }
          },
          U = function () {
            var t =
                e.requestAnimationFrame ||
                e[R.toLowerCase() + 'RequestAnimationFrame'],
              i = Ae();
            return (
              (ze || !t) &&
                (t = function (t) {
                  var n = Ae() - i,
                    o = l.max(0, 1e3 / 60 - n);
                  return e.setTimeout(function () {
                    (i = Ae()), t();
                  }, o);
                }),
              t
            );
          },
          Q = function () {
            var t =
              e.cancelAnimationFrame ||
              e[R.toLowerCase() + 'CancelAnimationFrame'];
            return (
              (ze || !t) &&
                (t = function (t) {
                  return e.clearTimeout(t);
                }),
              t
            );
          },
          K = {
            begin: function () {
              return 0;
            },
            end: function () {
              return 1;
            },
            linear: function (e) {
              return e;
            },
            quadratic: function (e) {
              return e * e;
            },
            cubic: function (e) {
              return e * e * e;
            },
            swing: function (e) {
              return -l.cos(e * l.PI) / 2 + 0.5;
            },
            sqrt: function (e) {
              return l.sqrt(e);
            },
            outCubic: function (e) {
              return l.pow(e - 1, 3) + 1;
            },
            bounce: function (e) {
              var t;
              if (0.5083 >= e) t = 3;
              else if (0.8489 >= e) t = 9;
              else if (0.96208 >= e) t = 27;
              else {
                if (!(0.99981 >= e)) return 1;
                t = 91;
              }
              return 1 - l.abs((3 * l.cos(e * t * 1.028)) / t);
            },
          };
        (n.prototype.refresh = function (e) {
          var n,
            o,
            r = !1;
          for (
            e === i
              ? ((r = !0),
                (le = []),
                (Re = 0),
                (e = t.getElementsByTagName('*')))
              : e.length === i && (e = [e]),
              n = 0,
              o = e.length;
            o > n;
            n++
          ) {
            var a = e[n],
              s = a,
              l = [],
              c = ge,
              d = we,
              u = !1;
            if ((r && E in a && delete a[E], a.attributes)) {
              for (var p = 0, f = a.attributes.length; f > p; p++) {
                var g = a.attributes[p];
                if ('data-anchor-target' !== g.name)
                  if ('data-smooth-scrolling' !== g.name)
                    if ('data-edge-strategy' !== g.name)
                      if ('data-emit-events' !== g.name) {
                        var m = g.name.match(N);
                        if (null !== m) {
                          var v = {
                            props: g.value,
                            element: a,
                            eventType: g.name.replace(P, L),
                          };
                          l.push(v);
                          var y = m[1];
                          y && (v.constant = y.substr(1));
                          var w = m[2];
                          /p$/.test(w)
                            ? ((v.isPercentage = !0),
                              (v.offset = (0 | w.slice(0, -1)) / 100))
                            : (v.offset = 0 | w);
                          var b = m[3],
                            k = m[4] || b;
                          b && b !== I && b !== _
                            ? ((v.mode = 'relative'), (v.anchors = [b, k]))
                            : ((v.mode = 'absolute'),
                              b === _
                                ? (v.isEnd = !0)
                                : v.isPercentage || (v.offset = v.offset * Oe));
                        }
                      } else u = !0;
                    else d = g.value;
                  else c = 'off' !== g.value;
                else if (((s = t.querySelector(g.value)), null === s))
                  throw 'Unable to find anchor target "' + g.value + '"';
              }
              if (l.length) {
                var S, x, T;
                !r && E in a
                  ? ((T = a[E]), (S = le[T].styleAttr), (x = le[T].classAttr))
                  : ((T = a[E] = Re++), (S = a.style.cssText), (x = $e(a))),
                  (le[T] = {
                    element: a,
                    styleAttr: S,
                    classAttr: x,
                    anchorTarget: s,
                    keyFrames: l,
                    smoothScrolling: c,
                    edgeStrategy: d,
                    emitEvents: u,
                    lastFrameIndex: -1,
                  }),
                  Me(a, [h], []);
              }
            }
          }
          for (De(), n = 0, o = e.length; o > n; n++) {
            var C = le[e[n][E]];
            C !== i && (Z(C), te(C));
          }
          return se;
        }),
          (n.prototype.relativeToAbsolute = function (e, t, i) {
            var n = o.clientHeight,
              r = e.getBoundingClientRect(),
              a = r.top,
              s = r.bottom - r.top;
            return (
              t === M ? (a -= n) : t === $ && (a -= n / 2),
              i === M ? (a += s) : i === $ && (a += s / 2),
              (a += se.getScrollTop()),
              (a + 0.5) | 0
            );
          }),
          (n.prototype.animateTo = function (e, t) {
            t = t || {};
            var n = Ae(),
              o = se.getScrollTop(),
              r = t.duration === i ? x : t.duration;
            return (
              (he = {
                startTop: o,
                topDiff: e - o,
                targetTop: e,
                duration: r,
                startTime: n,
                endTime: n + r,
                easing: K[t.easing || S],
                done: t.done,
              }),
              he.topDiff || (he.done && he.done.call(se, !1), (he = i)),
              se
            );
          }),
          (n.prototype.stopAnimateTo = function () {
            he && he.done && he.done.call(se, !0), (he = i);
          }),
          (n.prototype.isAnimatingTo = function () {
            return !!he;
          }),
          (n.prototype.isMobile = function () {
            return ze;
          }),
          (n.prototype.setScrollTop = function (t, i) {
            return (
              (ye = i === !0),
              ze ? (Ye = l.min(l.max(t, 0), He)) : e.scrollTo(0, t),
              se
            );
          }),
          (n.prototype.getScrollTop = function () {
            return ze ? Ye : e.pageYOffset || o.scrollTop || r.scrollTop || 0;
          }),
          (n.prototype.getMaxScrollTop = function () {
            return He;
          }),
          (n.prototype.on = function (e, t) {
            return (de[e] = t), se;
          }),
          (n.prototype.off = function (e) {
            return delete de[e], se;
          }),
          (n.prototype.destroy = function () {
            var e = Q();
            e(ke), Te(), Me(o, [w], [y, b, k]);
            for (var t = 0, n = le.length; n > t; t++) re(le[t].element);
            (o.style.overflow = r.style.overflow = ''),
              (o.style.height = r.style.height = ''),
              ce && a.setStyle(ce, 'transform', 'none'),
              (se = i),
              (ce = i),
              (de = i),
              (ue = i),
              (He = 0),
              (Oe = 1),
              (pe = i),
              (fe = i),
              (Pe = 'down'),
              (Le = -1),
              (qe = 0),
              (Be = 0),
              (We = !1),
              (he = i),
              (ge = i),
              (me = i),
              (ve = i),
              (ye = i),
              (Re = 0),
              (we = i),
              (ze = !1),
              (Ye = 0),
              (be = i);
          });
        var X = function () {
            var n, a, s, c, h, g, m, v, y, w, b, k;
            Se(o, [d, u, p, f].join(' '), function (e) {
              var o = e.changedTouches[0];
              for (c = e.target; 3 === c.nodeType; ) c = c.parentNode;
              switch (
                ((h = o.clientY),
                (g = o.clientX),
                (w = e.timeStamp),
                j.test(c.tagName) || e.preventDefault(),
                e.type)
              ) {
                case d:
                  n && n.blur(),
                    se.stopAnimateTo(),
                    (n = c),
                    (a = m = h),
                    (s = g),
                    (y = w);
                  break;
                case u:
                  j.test(c.tagName) &&
                    t.activeElement !== c &&
                    e.preventDefault(),
                    (v = h - m),
                    (k = w - b),
                    se.setScrollTop(Ye - v, !0),
                    (m = h),
                    (b = w);
                  break;
                default:
                case p:
                case f:
                  var r = a - h,
                    S = s - g,
                    x = S * S + r * r;
                  if (49 > x) {
                    if (!j.test(n.tagName)) {
                      n.focus();
                      var T = t.createEvent('MouseEvents');
                      T.initMouseEvent(
                        'click',
                        !0,
                        !0,
                        e.view,
                        1,
                        o.screenX,
                        o.screenY,
                        o.clientX,
                        o.clientY,
                        e.ctrlKey,
                        e.altKey,
                        e.shiftKey,
                        e.metaKey,
                        0,
                        null
                      ),
                        n.dispatchEvent(T);
                    }
                    return;
                  }
                  n = i;
                  var C = v / k;
                  C = l.max(l.min(C, 3), -3);
                  var D = l.abs(C / fe),
                    I = C * D + 0.5 * fe * D * D,
                    _ = se.getScrollTop() - I,
                    $ = 0;
                  _ > He
                    ? (($ = (He - _) / I), (_ = He))
                    : 0 > _ && (($ = -_ / I), (_ = 0)),
                    (D *= 1 - $),
                    se.animateTo((_ + 0.5) | 0, {
                      easing: 'outCubic',
                      duration: D,
                    });
              }
            }),
              e.scrollTo(0, 0),
              (o.style.overflow = r.style.overflow = 'hidden');
          },
          V = function () {
            var e,
              t,
              i,
              n,
              r,
              a,
              s,
              c,
              d,
              u,
              p,
              f = o.clientHeight,
              h = Ie();
            for (c = 0, d = le.length; d > c; c++)
              for (
                e = le[c],
                  t = e.element,
                  i = e.anchorTarget,
                  n = e.keyFrames,
                  r = 0,
                  a = n.length;
                a > r;
                r++
              )
                (s = n[r]),
                  (u = s.offset),
                  (p = h[s.constant] || 0),
                  (s.frame = u),
                  s.isPercentage && ((u *= f), (s.frame = u)),
                  'relative' === s.mode &&
                    (re(t),
                    (s.frame =
                      se.relativeToAbsolute(i, s.anchors[0], s.anchors[1]) - u),
                    re(t, !0)),
                  (s.frame += p),
                  ue && !s.isEnd && s.frame > He && (He = s.frame);
            for (He = l.max(He, _e()), c = 0, d = le.length; d > c; c++) {
              for (e = le[c], n = e.keyFrames, r = 0, a = n.length; a > r; r++)
                (s = n[r]),
                  (p = h[s.constant] || 0),
                  s.isEnd && (s.frame = He - s.offset + p);
              e.keyFrames.sort(Ne);
            }
          },
          G = function (e, t) {
            for (var i = 0, n = le.length; n > i; i++) {
              var o,
                r,
                l = le[i],
                c = l.element,
                d = l.smoothScrolling ? e : t,
                u = l.keyFrames,
                p = u.length,
                f = u[0],
                y = u[u.length - 1],
                w = d < f.frame,
                b = d > y.frame,
                k = w ? f : y,
                S = l.emitEvents,
                x = l.lastFrameIndex;
              if (w || b) {
                if ((w && -1 === l.edge) || (b && 1 === l.edge)) continue;
                switch (
                  (w
                    ? (Me(c, [g], [v, m]),
                      S &&
                        x > -1 &&
                        (Ce(c, f.eventType, Pe), (l.lastFrameIndex = -1)))
                    : (Me(c, [v], [g, m]),
                      S &&
                        p > x &&
                        (Ce(c, y.eventType, Pe), (l.lastFrameIndex = p))),
                  (l.edge = w ? -1 : 1),
                  l.edgeStrategy)
                ) {
                  case 'reset':
                    re(c);
                    continue;
                  case 'ease':
                    d = k.frame;
                    break;
                  default:
                  case 'set':
                    var T = k.props;
                    for (o in T)
                      s.call(T, o) &&
                        ((r = oe(T[o].value)),
                        0 === o.indexOf('@')
                          ? c.setAttribute(o.substr(1), r)
                          : a.setStyle(c, o, r));
                    continue;
                }
              } else 0 !== l.edge && (Me(c, [h, m], [g, v]), (l.edge = 0));
              for (var C = 0; p - 1 > C; C++)
                if (d >= u[C].frame && d <= u[C + 1].frame) {
                  var D = u[C],
                    I = u[C + 1];
                  for (o in D.props)
                    if (s.call(D.props, o)) {
                      var _ = (d - D.frame) / (I.frame - D.frame);
                      (_ = D.props[o].easing(_)),
                        (r = ne(D.props[o].value, I.props[o].value, _)),
                        (r = oe(r)),
                        0 === o.indexOf('@')
                          ? c.setAttribute(o.substr(1), r)
                          : a.setStyle(c, o, r);
                    }
                  S &&
                    x !== C &&
                    ('down' === Pe
                      ? Ce(c, D.eventType, Pe)
                      : Ce(c, I.eventType, Pe),
                    (l.lastFrameIndex = C));
                  break;
                }
            }
          },
          J = function () {
            We && ((We = !1), De());
            var e,
              t,
              n = se.getScrollTop(),
              o = Ae();
            if (he)
              o >= he.endTime
                ? ((n = he.targetTop), (e = he.done), (he = i))
                : ((t = he.easing((o - he.startTime) / he.duration)),
                  (n = (he.startTop + t * he.topDiff) | 0)),
                se.setScrollTop(n, !0);
            else if (!ye) {
              var r = ve.targetTop - n;
              r &&
                (ve = {
                  startTop: Le,
                  topDiff: n - Le,
                  targetTop: n,
                  startTime: Fe,
                  endTime: Fe + me,
                }),
                o <= ve.endTime &&
                  ((t = K.sqrt((o - ve.startTime) / me)),
                  (n = (ve.startTop + t * ve.topDiff) | 0));
            }
            if (ye || Le !== n) {
              (Pe = n > Le ? 'down' : Le > n ? 'up' : Pe), (ye = !1);
              var s = { curTop: n, lastTop: Le, maxTop: He, direction: Pe },
                l = de.beforerender && de.beforerender.call(se, s);
              l !== !1 &&
                (G(n, se.getScrollTop()),
                ze &&
                  ce &&
                  a.setStyle(
                    ce,
                    'transform',
                    'translate(0, ' + -Ye + 'px) ' + be
                  ),
                (Le = n),
                de.render && de.render.call(se, s)),
                e && e.call(se, !1);
            }
            Fe = o;
          },
          Z = function (e) {
            for (var t = 0, i = e.keyFrames.length; i > t; t++) {
              for (
                var n, o, r, a, s = e.keyFrames[t], l = {};
                null !== (a = H.exec(s.props));

              )
                (r = a[1]),
                  (o = a[2]),
                  (n = r.match(O)),
                  null !== n ? ((r = n[1]), (n = n[2])) : (n = S),
                  (o = o.indexOf('!') ? ee(o) : [o.slice(1)]),
                  (l[r] = { value: o, easing: K[n] });
              s.props = l;
            }
          },
          ee = function (e) {
            var t = [];
            return (
              (B.lastIndex = 0),
              (e = e.replace(B, function (e) {
                return e.replace(F, function (e) {
                  return (e / 255) * 100 + '%';
                });
              })),
              z &&
                ((W.lastIndex = 0),
                (e = e.replace(W, function (e) {
                  return z + e;
                }))),
              (e = e.replace(F, function (e) {
                return t.push(+e), '{?}';
              })),
              t.unshift(e),
              t
            );
          },
          te = function (e) {
            var t,
              i,
              n = {};
            for (t = 0, i = e.keyFrames.length; i > t; t++)
              ie(e.keyFrames[t], n);
            for (n = {}, t = e.keyFrames.length - 1; t >= 0; t--)
              ie(e.keyFrames[t], n);
          },
          ie = function (e, t) {
            var i;
            for (i in t) s.call(e.props, i) || (e.props[i] = t[i]);
            for (i in e.props) t[i] = e.props[i];
          },
          ne = function (e, t, i) {
            var n,
              o = e.length;
            if (o !== t.length)
              throw (
                'Can\'t interpolate between "' + e[0] + '" and "' + t[0] + '"'
              );
            var r = [e[0]];
            for (n = 1; o > n; n++) r[n] = e[n] + (t[n] - e[n]) * i;
            return r;
          },
          oe = function (e) {
            var t = 1;
            return (
              (q.lastIndex = 0),
              e[0].replace(q, function () {
                return e[t++];
              })
            );
          },
          re = function (e, t) {
            e = [].concat(e);
            for (var i, n, o = 0, r = e.length; r > o; o++)
              (n = e[o]),
                (i = le[n[E]]),
                i &&
                  (t
                    ? ((n.style.cssText = i.dirtyStyleAttr),
                      Me(n, i.dirtyClassAttr))
                    : ((i.dirtyStyleAttr = n.style.cssText),
                      (i.dirtyClassAttr = $e(n)),
                      (n.style.cssText = i.styleAttr),
                      Me(n, i.classAttr)));
          },
          ae = function () {
            (be = 'translateZ(0)'), a.setStyle(ce, 'transform', be);
            var e = c(ce),
              t = e.getPropertyValue('transform'),
              i = e.getPropertyValue(z + 'transform'),
              n = (t && 'none' !== t) || (i && 'none' !== i);
            n || (be = '');
          };
        a.setStyle = function (e, t, i) {
          var n = e.style;
          if (((t = t.replace(P, L).replace('-', '')), 'zIndex' === t))
            isNaN(i) ? (n[t] = i) : (n[t] = '' + (0 | i));
          else if ('float' === t) n.styleFloat = n.cssFloat = i;
          else
            try {
              R && (n[R + t.slice(0, 1).toUpperCase() + t.slice(1)] = i),
                (n[t] = i);
            } catch (o) {}
        };
        var se,
          le,
          ce,
          de,
          ue,
          pe,
          fe,
          he,
          ge,
          me,
          ve,
          ye,
          we,
          be,
          ke,
          Se = (a.addEvent = function (t, i, n) {
            var o = function (t) {
              return (
                (t = t || e.event),
                t.target || (t.target = t.srcElement),
                t.preventDefault ||
                  (t.preventDefault = function () {
                    (t.returnValue = !1), (t.defaultPrevented = !0);
                  }),
                n.call(this, t)
              );
            };
            i = i.split(' ');
            for (var r, a = 0, s = i.length; s > a; a++)
              (r = i[a]),
                t.addEventListener
                  ? t.addEventListener(r, n, !1)
                  : t.attachEvent('on' + r, o),
                Ue.push({ element: t, name: r, listener: n });
          }),
          xe = (a.removeEvent = function (e, t, i) {
            t = t.split(' ');
            for (var n = 0, o = t.length; o > n; n++)
              e.removeEventListener
                ? e.removeEventListener(t[n], i, !1)
                : e.detachEvent('on' + t[n], i);
          }),
          Te = function () {
            for (var e, t = 0, i = Ue.length; i > t; t++)
              (e = Ue[t]), xe(e.element, e.name, e.listener);
            Ue = [];
          },
          Ce = function (e, t, i) {
            de.keyframe && de.keyframe.call(se, e, t, i);
          },
          De = function () {
            var e = se.getScrollTop();
            (He = 0),
              ue && !ze && (r.style.height = ''),
              V(),
              ue && !ze && (r.style.height = He + o.clientHeight + 'px'),
              ze
                ? se.setScrollTop(l.min(se.getScrollTop(), He))
                : se.setScrollTop(e, !0),
              (ye = !0);
          },
          Ie = function () {
            var e,
              t,
              i = o.clientHeight,
              n = {};
            for (e in pe)
              (t = pe[e]),
                'function' == typeof t
                  ? (t = t.call(se))
                  : /p$/.test(t) && (t = (t.slice(0, -1) / 100) * i),
                (n[e] = t);
            return n;
          },
          _e = function () {
            var e,
              t = 0;
            return (
              ce && (t = l.max(ce.offsetHeight, ce.scrollHeight)),
              (e = l.max(
                t,
                r.scrollHeight,
                r.offsetHeight,
                o.scrollHeight,
                o.offsetHeight,
                o.clientHeight
              )),
              e - o.clientHeight
            );
          },
          $e = function (t) {
            var i = 'className';
            return (
              e.SVGElement &&
                t instanceof e.SVGElement &&
                ((t = t[i]), (i = 'baseVal')),
              t[i]
            );
          },
          Me = function (t, n, o) {
            var r = 'className';
            if (
              (e.SVGElement &&
                t instanceof e.SVGElement &&
                ((t = t[r]), (r = 'baseVal')),
              o === i)
            )
              return void (t[r] = n);
            for (var a = t[r], s = 0, l = o.length; l > s; s++)
              a = je(a).replace(je(o[s]), ' ');
            a = Ee(a);
            for (var c = 0, d = n.length; d > c; c++)
              -1 === je(a).indexOf(je(n[c])) && (a += ' ' + n[c]);
            t[r] = Ee(a);
          },
          Ee = function (e) {
            return e.replace(A, '');
          },
          je = function (e) {
            return ' ' + e + ' ';
          },
          Ae =
            Date.now ||
            function () {
              return +new Date();
            },
          Ne = function (e, t) {
            return e.frame - t.frame;
          },
          He = 0,
          Oe = 1,
          Pe = 'down',
          Le = -1,
          Fe = Ae(),
          qe = 0,
          Be = 0,
          We = !1,
          Re = 0,
          ze = !1,
          Ye = 0,
          Ue = [];
        'function' == typeof define && define.amd
          ? define([], function () {
              return a;
            })
          : 'undefined' != typeof module && module.exports
          ? (module.exports = a)
          : (e.skrollr = a);
      })(window, document),
      jQuery(document).ready(function () {
        jQuery('.gallery-wrapper .slideshow.set-slick').slick();
        var e = jQuery(window).width(),
          t = jQuery('.gallery-large.gallery-1').parent();
        jQuery('.gallery-wrapper .slideshow.set-slick').slick('slickFilter', t);
        var i = $('.down-arrow'),
          n = jQuery(window).height() - 45;
        i.bind('click', function () {
          jQuery('html, body').animate({ scrollTop: n }, 1200);
        });
        var o = $('.filter-item'),
          r = $('.view-option');
        o.click(function () {
          if (!$(this).hasClass('active')) {
            o.removeClass('active'), $(this).addClass('active');
            var e = $(this).data('item');
            $('.gallery-item.' + e).hasClass('loaded') ||
              $('.gallery-item.' + e + ' img').each(function () {
                var e = $(this).data('src');
                $(this).attr('src', e);
              }),
              $('.gallery-item').removeClass('active').hide(),
              $('.gallery-item.' + e)
                .addClass('active loaded')
                .fadeIn('slow'),
              jQuery('.gallery-wrapper .slideshow.set-slick').slick(
                'slickUnfilter'
              );
            var t = jQuery('.gallery-large.' + e).parent();
            jQuery('.gallery-wrapper .slideshow.set-slick').slick(
              'slickFilter',
              t
            );
            var i = jQuery('.gallery-item.' + e + ' .gallery-thumb').length;
            jQuery('.all-slides').text(i),
              jQuery('.current-slide-number').text(1),
              jQuery('.gallery-wrapper .slideshow.set-slick').slick(
                'slickGoTo',
                0
              );
          }
        }),
          r.click(function () {
            if (!$(this).hasClass('active')) {
              var e = $(this).data('type');
              r.removeClass('active'),
                $(this).addClass('active'),
                'grid' == e
                  ? ($('.gallery-wrapper.full-view').hide(),
                    $('.gallery-wrapper.grid-view').fadeIn('slow'))
                  : ($('.gallery-wrapper.grid-view').hide(),
                    $('.gallery-wrapper.full-view').fadeIn('slow'),
                    jQuery('.gallery-wrapper .slideshow.set-slick').slick(
                      'slickGoTo',
                      0
                    ));
            }
          }),
          jQuery('.gallery-thumb').click(function () {
            $('.view-option.grid').removeClass('active'),
              $('.view-option.full').addClass('active');
            var e = $(this).data('slide');
            jQuery('.gallery-wrapper .slideshow.set-slick').slick(
              'slickGoTo',
              e
            ),
              $('.gallery-wrapper.grid-view').hide(),
              $('.gallery-wrapper.full-view').fadeIn('slow');
          }),
          jQuery('.gallery-wrapper .slideshow.set-slick').on(
            'beforeChange',
            function (e, t, i, n) {
              var o = n + 1;
              jQuery('.current-slide-number').text(o);
            }
          );
        var a = jQuery('.item-nav-wrapper .main-title').attr('data-cat');
        jQuery(
          '.rooms-category-wrapper.filter-block .filter-tab.' + a
        ).addClass('active');
        var s = jQuery('.filter-tab').not('.media'),
          l = jQuery('.items-wrapper'),
          c = jQuery('#module-content');
        s.click(function () {
          if (!jQuery(this).hasClass('active')) {
            s.removeClass('active'), jQuery(this).addClass('active');
            var e = jQuery(this).data('key');
            l.removeClass('active').hide(),
              jQuery('.items-wrapper.' + e)
                .addClass('active')
                .fadeIn('slow');
            var t = c.attr('class').split(' ');
            c.removeClass(t[1]).addClass(e);
          }
        }),
          jQuery('.filter-tab.media').click(function () {
            jQuery('.popup-block').show();
          }),
          jQuery('.popup-close, .popup-btn').click(function () {
            jQuery('.popup-block').fadeOut('slow');
          }),
          jQuery(
            '.media-contacts a[href*="tel:"], .address p a[href*="tel:"]'
          ).addClass('no-link'),
          readMore(),
          e < 768 &&
            (accordions(),
            jQuery(
              '.attractions .slideshow, #mod_pukkaVenues .slideshow'
            ).slick({
              autoplay: !0,
              arrows: !1,
              pauseOnHover: !1,
              fade: !0,
              speed: 1e3,
              autoplaySpeed: 3e3,
              cssEase: 'ease-in-out',
              useTransform: !0,
              dots: !0,
            }),
            jQuery('.filter-block').click(function () {
              $(this).toggleClass('open');
            }),
            jQuery('.go-to').click(function () {
              jQuery('html, body').animate({ scrollTop: 0 }, 'slow');
            })),
          jQuery('.offer-img-rotator').slick({
            autoplay: !0,
            arrows: !1,
            pauseOnHover: !1,
            fade: !0,
            speed: 1e3,
            autoplaySpeed: 3e3,
            cssEase: 'ease-in-out',
            useTransform: !0,
            dots: !0,
          });
      }),
      jQuery(window).on('resize', function () {}),
      jQuery(window).on('load', function () {}),
      jQuery(window).resize(function () {
        $('#main-visual .slideshow').slick('resize');
      }),
      jQuery(window).on('orientationchange', function (e) {}),
      $('[data-blocks]'))
    ) {
      var expanding = $('[data-blocks]'),
        cols = expanding.data('cols-desktop');
      (cols =
        $(window).width() < 768
          ? expanding.data('cols-mobile')
          : $(window).width() < 1024
          ? expanding.data('cols-tablet')
          : expanding.data('cols-desktop')),
        expanding.on('click', '[data-expand]', function (e) {
          e.preventDefault();
          var t = $('[data-blocks]'),
            i = t.find('[data-item]'),
            n = $(this).closest('[data-item]'),
            o = n.index(),
            r = t.find('[data-item]').length,
            a = Math.ceil((o + 1) / cols),
            s = a * cols - 1;
          s > r - 1 && (s = r - 1),
            '1' == n.attr('data-open')
              ? (t
                  .find('> [data-expanded]')
                  .slideUp(t.data('transition'), function () {
                    t.find('> [data-expanded]').remove();
                  }),
                n.attr('data-open', '0'),
                t.attr('data-open', '0'))
              : '1' != t.attr('data-open')
              ? ((block = t
                  .find('[data-item]')
                  .eq(o)
                  .find('[data-expanded]')
                  .clone()),
                block.insertAfter('[data-item]:eq(' + s + ')').hide(),
                t.find('> [data-expanded]').slideDown(t.data('transition')),
                n.attr('data-open', '1'),
                t.attr('data-open', '1'))
              : t
                  .find('> [data-expanded]')
                  .slideUp(t.data('transition'), function () {
                    t.find('> [data-expanded]').remove(),
                      i.attr('data-open', '0'),
                      n.attr('data-open', '1'),
                      (block = t
                        .find('[data-item]')
                        .eq(o - 1)
                        .find('[data-expanded]')
                        .clone()),
                      block.insertAfter('[data-item]:eq(' + s + ')').hide(),
                      t
                        .find('> [data-expanded]')
                        .slideDown(t.data('transition'));
                  });
        }),
        expanding.on('click', '[data-close]', function (e) {
          e.preventDefault(),
            expanding
              .find('[data-item][data-open="1"] [data-expand]')
              .trigger('click');
        });
    }
    $(window).resize(function () {
      cols =
        $(window).width() < 768
          ? expanding.data('cols-mobile')
          : $(window).width() < 1024
          ? expanding.data('cols-tablet')
          : expanding.data('cols-desktop');
    });
    var isotope = $('[data-isotope]');
    isotope.isotope({
      itemSelector: '.post',
      layoutMode: 'masonry',
      percentPosition: !0,
      masonry: { gutter: '[data-gutter]' },
    }),
      $('[data-filter] a').on('click', function (e) {
        e.preventDefault();
        var t = window.location.pathname.split('/');
        'projects' == t[1] || 'exchanges' == t[1]
          ? ($(this).parent().parent().find('a').removeClass('active'),
            $(this).addClass('active'),
            isotope.isotope({
              filter:
                '*' == $(this).data('filter')
                  ? '*'
                  : '.' + $(this).data('filter'),
            }),
            '*' == $(this).data('filter') &&
              ($('[data-filter]').removeClass('active'),
              $('[data-filter]').parent().removeClass('active')))
          : (window.location.href = '/' + t[1] + 's#' + $(this).attr('href'));
      }),
      window.location.hash &&
        setTimeout(function () {
          $(
            '[data-filter] a[href="' +
              window.location.hash.replace('#', '') +
              '"]'
          ).trigger('click');
        }, 800),
      setTimeout(function () {
        $('[data-isotope]').addClass('active');
      }, 1200),
      $(window).width() < 768 &&
        $('.parallax-image-holder .main').removeAttr(
          'data-smooth-scrolling data-anchor-target data-bottom-top data-top-bottom'
        ),
      select2(),
      matchHeightInit(),
      announceBar(),
      $.localScroll({ duration: 800, offset: -140 }),
      $('.category-select-mobile select')
        .change(function () {
          $(this)
            .find('option:selected')
            .each(function () {
              var e = $(this).attr('value');
              e
                ? ($('.category-wrapper')
                    .not('.' + e)
                    .removeClass('active'),
                  $('.' + e).addClass('active'))
                : $('.category-wrapper').removeClass('active');
            });
        })
        .change(),
      $('a.hideAnnouncement').on('click', function (e) {
        e.preventDefault(),
          $(this).parent('.announcementBar').removeClass('active'),
          $('main').removeClass('announce'),
          $('#header').removeClass('announce'),
          $('body').removeClass('announce'),
          announceBar();
      }),
      $(window).width() > 767 &&
        $(window).scroll(function () {
          var e = Math.floor($(window).scrollTop() / 7);
          $('.no-main-visual .holder').css({
            backgroundPosition: '0 -' + e + 'px',
          });
        });
    var lastScrollTop = 0;
    if (
      ($(window).scroll(function (e) {
        // var t = $(this).scrollTop();
        // t > lastScrollTop
        //   ? $('#header').removeClass('scrolledUp')
        //   : $('#header').addClass('scrolledUp'),
        //   (lastScrollTop = t);
      }),
      $().fancybox)
    ) {
      var fancyBoxConf = { speedIn: 500, speedOut: 500 };
      $(
        '#mod_pukkaGalleries .picture a, #mod_pukkaPictures .picture a'
      ).fancybox(fancyBoxConf),
        $('#mod_pukkaNews .item-preview .fancy').fancybox({
          arrows: !0,
          iframe: { scrolling: 'auto', preload: !0 },
        });
    }
    $('#navigation').on('click', function (e) {
      $(this).hasClass('active')
        ? ($(this).removeClass('active'), $('body').removeClass('active-menu'))
        : ($(this).addClass('active'), $('body').addClass('active-menu'));
    }),
      $('.slideshow.precinctSlider').slick({
        autoplay: !0,
        arrows: !1,
        pauseOnHover: !1,
        fade: !0,
        speed: 1e3,
        autoplaySpeed: 3e3,
        cssEase: 'ease-in-out',
        useTransform: !0,
        dots: !0,
        responsive: [{ breakpoint: 767, settings: { arrows: !1 } }],
      }),
      $('.slideshow.precinctSlider').slick({
        autoplay: !0,
        arrows: !1,
        pauseOnHover: !1,
        fade: !0,
        speed: 1e3,
        autoplaySpeed: 3e3,
        cssEase: 'ease-in-out',
        useTransform: !0,
        dots: !0,
        responsive: [{ breakpoint: 767, settings: { arrows: !1 } }],
      }),
      $('.slideshow.slideBlock').slick({
        autoplay: !0,
        arrows: !0,
        pauseOnHover: !1,
        fade: !0,
        speed: 1e3,
        autoplaySpeed: 3e3,
        cssEase: 'ease-in-out',
        useTransform: !0,
        dots: !1,
        responsive: [{ breakpoint: 767, settings: { arrows: !1 } }],
      }),
      $('.charity-carousel').slick({
        autoplay: !0,
        autoplaySpeed: 3e3,
        speed: 1e3,
        infinite: !0,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: !1,
        dots: !0,
        responsive: [
          {
            breakpoint: 769,
            settings: { arrows: !1, slidesToShow: 2, slidesToScroll: 2 },
          },
          {
            breakpoint: 768,
            settings: { arrows: !1, slidesToShow: 1, slidesToScroll: 1 },
          },
        ],
      }),
      $('.news-carousel').slick({
        autoplay: !0,
        autoplaySpeed: 3e3,
        speed: 1e3,
        infinite: !0,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: !1,
        dots: !0,
        responsive: [
          {
            breakpoint: 769,
            settings: { arrows: !1, slidesToShow: 2, slidesToScroll: 2 },
          },
          {
            breakpoint: 768,
            settings: { arrows: !1, slidesToShow: 1, slidesToScroll: 1 },
          },
        ],
      }),
      $('.carousel.logos').slick({
        autoplay: !0,
        autoplaySpeed: 3e3,
        speed: 1e3,
        infinite: !0,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: !0,
        dots: !1,
        responsive: [
          { breakpoint: 769, settings: { slidesToShow: 3, slidesToScroll: 1 } },
          { breakpoint: 640, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
      }),
      $('.carousel.large-logos').slick({
        autoplay: !0,
        autoplaySpeed: 3e3,
        speed: 1e3,
        infinite: !0,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: !0,
        dots: !1,
        responsive: [
          { breakpoint: 769, settings: { slidesToShow: 2, slidesToScroll: 1 } },
          { breakpoint: 640, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
      }),
      $('.eat-awards').slick({
        autoplay: !0,
        autoplaySpeed: 3e3,
        speed: 1e3,
        infinite: !0,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: !0,
        dots: !1,
        responsive: [
          { breakpoint: 769, settings: { slidesToShow: 3, slidesToScroll: 1 } },
          { breakpoint: 640, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
      }),
      $('.slideshow.slideBlock').slick({
        autoplay: !0,
        arrows: !0,
        pauseOnHover: !1,
        fade: !0,
        speed: 1e3,
        autoplaySpeed: 3e3,
        cssEase: 'ease-in-out',
        useTransform: !0,
        dots: !1,
        responsive: [{ breakpoint: 767, settings: { arrows: !1 } }],
      }),
      $('.slider-for').slick({
        autoplay: !0,
        pauseOnHover: !1,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: !1,
        dots: !1,
        fade: !0,
        speed: 1e3,
        autoplaySpeed: 3e3,
        cssEase: 'ease-in-out',
        asNavFor: '.slider-nav',
      }),
      $('.slider-nav').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: !1,
        arrows: !1,
        centerMode: !0,
        focusOnSelect: !0,
        responsive: [
          { breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 } },
          { breakpoint: 640, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
      });
  }),
    $(window).on('load', function () {
      if (
        (announceBar(),
        $('body').addClass('loaded'),
        $('.menu-wrapper > ul').addClass('shortcuts'),
        $('.menu-wrapper ul.sub-menu').addClass('menuLevel2'),
        scroll(),
        $('li.filter-tab.media').text('media kit'),
        'page_home' == $('body').attr('id'))
      ) {
        var e = $('.coverflow-video video').attr('data-src'),
          t = $('.coverflow-video video').attr('data-mobile-src');
        $(window).width() < 641
          ? $('.coverflow-video video').append(
              '<source type="video/mp4" src="' + t + '">'
            )
          : $('.coverflow-video video').append(
              '<source type="video/mp4" src="' + e + '">'
            ),
          $('.coverflow-video video').attr('autoplay', ''),
          $('.coverflow-video video').attr('playsinline', '');
      }
    }),
    $(window).on('scroll', function () {
      announceBar();
    }),
    $(window).on('resize', function () {
      scroll(), announceBar();
    });
})(window.jQuery);
