// Room Page Slider - Show Images on load
$(".slider-for").on("init", function (event, slick) {
  var loadedSlides = $(".slider-for").find(".slideImageContainer");
  for (i = 0; i < loadedSlides.length; i++) {
    loadedSlides[i].style.display = "inherit";
  }
});

// Precinct Page Slider - Show Images on load
$(".precinctSlider").on("init", function (event, slick) {
  var loadedSlides = $(".precinctSlider").find(".slideImageContainer");
  for (i = 0; i < loadedSlides.length; i++) {
    loadedSlides[i].style.display = "inherit";
  }
});

// Precinct Page Slider - Show Images on load
window.addEventListener("load", function (event, slick) {
  const body = document.querySelector("#page_home");
  if(body) {
    body.style.height = "unset";
  }
});
